import { IonCol, IonItem, IonLabel, IonList, IonSpinner, IonToast, IonToggle } from '@ionic/react'
import axios from 'axios'
import { useEffect, useState } from 'react'

export default function ManageNotifications({ customer_id }) {
  const [textNotificationsEnabled, setTextNotificationsEnabled] = useState(false)
  const [emailNotificationsEnabled, setEmailNotificationsEnabled] = useState(false)
  const [customer, setCustomer] = useState()
  const [customerTemp, setCustomerTemp] = useState()
  const [updatingTextNotifications, setUpdatingTextNotifications] = useState(false)
  const [updatingEmailNotifications, setUpdatingEmailNotifications] = useState(false)
  const [loading, setLoading] = useState(true)
  const [toast, setToast] = useState({ isOpen: false, message: '', color: '' })

  const updateCustomerNotifications = async (field, value) => {
    let customerData = { ...customerTemp }
    const notificationSettings = {
      ...customerData.notificationSettings,
      [field]: value,
    }
    setCustomerTemp(customerData)
    customerData['notificationSettings'] = notificationSettings

    try {
      let response = await axios.patch(`/customer_v2?id=${customer_id}`, customerData)
      if (response.status === 200) {
        setToast({ isOpen: true, message: `${field} updated successfully!`, color: 'success' })
      }
    } catch (error) {
      setToast({ isOpen: true, message: 'Error updating notification settings.', color: 'danger' })
    } finally {
      field === 'textNotificationsEnabled' && setUpdatingTextNotifications(false)
      field === 'emailNotificationsEnabled' && setUpdatingEmailNotifications(false)
    }
  }
  const getCustomer = async () => {
    setLoading(true) // Start loading state
    try {
      let response = await axios.get(`/customer_v2?id=${customer_id}`)

      // Set customer data
      setCustomer(response.data)
      setCustomerTemp(response.data)

      // Handle notification settings
      const notificationSettings = response?.data?.notificationSettings
      setTextNotificationsEnabled(notificationSettings?.textNotificationsEnabled)
      setEmailNotificationsEnabled(notificationSettings?.emailNotificationsEnabled)
    } catch (error) {
      console.error('Error fetching customer data:', error) // Log the error for debugging
    } finally {
      // Ensure loading is set to false in the finally block
      setLoading(false)
    }
  }

  useEffect(() => {
    getCustomer()
  }, [])
  useEffect(() => {
    getCustomer()
  }, [customer_id])

  const toggleTextNotifications = (value) => {
    if (textNotificationsEnabled !== value) {
      setTextNotificationsEnabled(value)
      setUpdatingTextNotifications(true)
      updateCustomerNotifications('textNotificationsEnabled', value)
    }
  }
  const toggleEmailNotifications = (value) => {
    if (emailNotificationsEnabled !== value) {
      setEmailNotificationsEnabled(value)
      setUpdatingEmailNotifications(true)
      updateCustomerNotifications('emailNotificationsEnabled', value)
    }
  }

  return (
    <div style={{ padding: '1rem' }}>
      <h3 style={{ fontWeight: 'bold' }}>Notification Settings</h3>
      {loading && (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <IonSpinner name='dots' />
        </div>
      )}
      {!loading && (
        <>
          <IonList mode='ios' inset={true} style={{ margin: '1rem 0' }}>
            <IonItem>
              <IonCol size='9'>
                <IonLabel>Text Notifications</IonLabel>
              </IonCol>
              <IonCol size='3'>
                {updatingTextNotifications && (
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <IonSpinner name='lines' />
                  </div>
                )}
                {!updatingTextNotifications && (
                  <IonToggle
                    justify='space-between'
                    size='small'
                    checked={textNotificationsEnabled}
                    onIonChange={(e) => {
                      toggleTextNotifications(e.detail.checked)
                    }}
                  />
                )}
              </IonCol>
            </IonItem>
          </IonList>

          <IonList mode='ios' inset={true} style={{ margin: '1rem 0' }}>
            <IonItem>
              <IonCol size='9'>
                <IonLabel>Email Notifications</IonLabel>
              </IonCol>
              <IonCol size='3'>
                {updatingEmailNotifications && (
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <IonSpinner name='lines' />
                  </div>
                )}
                {!updatingEmailNotifications && (
                  <IonToggle
                    justify='space-between'
                    size='small'
                    checked={emailNotificationsEnabled}
                    onIonChange={(e) => {
                      toggleEmailNotifications(e.detail.checked)
                    }}
                  />
                )}
              </IonCol>
            </IonItem>
          </IonList>
        </>
      )}
      <IonToast
        position='top'
        isOpen={toast.isOpen}
        onDidDismiss={() => setToast({ ...toast, isOpen: false })}
        message={toast.message}
        color={toast.color}
        duration={2000}
      />
    </div>
  )
}
