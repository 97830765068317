import { IonRadioGroup, IonRadio, IonItem, IonLabel, IonIcon } from '@ionic/react';
import { businessOutline, personOutline, storefrontOutline, earthOutline } from 'ionicons/icons';
import { colors } from '../../theme/colors';

export default function CorporateRelationshipStep({ formData, updateFormData }) {
  const relationshipOptions = [
    {
      value: 'corporate',
      label: 'Corporate Store',
      description: 'This is a store that is majority owned by the corporate entity.',
      icon: businessOutline
    },
    {
      value: 'franchise',
      label: 'Franchise',
      description: 'This is a store that is a franchise of the corporate entity.',
      icon: storefrontOutline
    },
    {
      value: 'other',
      label: 'Other',
      description: 'This is a store that is not a corporate store or a franchise, but has rights to operate under this business.',
      icon: earthOutline
    }
  ];

  return (
    <div style={{ padding: 24 }}>
      <IonRadioGroup value={formData.relationshipToBusiness} onIonChange={(e) => updateFormData('relationshipToBusiness', e.detail.value)}>
        {relationshipOptions.map((option) => (
          <IonItem
            key={option.value}
            lines="none"
            button
            detail={false}
            onClick={() => updateFormData('relationshipToBusiness', option.value)}
            style={{
              border: formData.relationshipToBusiness === option.value ? `2px solid ${colors.primaryBlue}` : '1px solid #dcdcdc',
              borderRadius: '10px',
              marginBottom: '1rem',
              backgroundColor: formData.relationshipToBusiness === option.value ? 'red' : 'white',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%'
            }}
          >
            <IonLabel style={{ padding: '1rem' }}>
              <h2 style={{ fontWeight: 'bold', margin: 0 }}>{option.label}</h2>
              <p style={{ margin: 0, color: '#6c757d' }}>{option.description}</p>
            </IonLabel>
            <IonIcon icon={option.icon} style={{ 
              fontSize: '24px',
              color: formData.relationshipToBusiness === option.value ? `${colors.primaryBlue}` : 'gray'
            }} />
          </IonItem>
        ))}
      </IonRadioGroup>
    </div>
  );
}
