import { useLocation } from 'react-router'
import ListView from './listView'

export default function Location() {
  const location = useLocation()

  // Access the data passed through history.push
  const { openCreatePopUp } = location.state || {}

  return <ListView path='/' openCreatePopUp={openCreatePopUp} />
}
