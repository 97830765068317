import { IonButton, IonCol, IonContent, IonItem, IonLabel, IonList, IonPage, IonSpinner, IonToast, IonToggle } from '@ionic/react'
import React, { useContext, useEffect, useState } from 'react'
import CommonHeader from '../commonComponents/CommonHeader'
import StandardContainer from '../commonComponents/StandardContainer'
import FeedbackRating from '../Feedback/FeedbackRating'
import { AuthContext } from '../auth'
import axios from 'axios'
import GetLocationDataBasedOnLocationId from '../Utils/GetLocationDataBasedOnLocationId'

export default function Deflectionsettings() {
  const [rating, setRating] = useState(0)
  const { businessData, locationId, locationData, getLocations } = useContext(AuthContext)

  const [dataChanged, setDataChanged] = useState(false)
  const [deflectBadReviews, setDeflectBadReviews] = useState(false)
  const [updatingSettings, setUpdatingSettings] = useState(false)
  const [toast, setToast] = useState({
    isOpen: false,
    message: '',
    color: 'success', // default color
  })

  const toggleDeflectBadReviews = (value) => {
    setDataChanged(true)
    setDeflectBadReviews(value)
  }
  const handleRatingChange = (newRating) => {
    setDataChanged(true)
    setRating(newRating)
  }
  const updateDeflectionSettings = async () => {
    setUpdatingSettings(true)
    const settingsInput = {
      businessId: businessData?._id,
      deflectionSettings: {
        badReviewDeflectionEnabled: deflectBadReviews,
        deflections: rating,
      },
    }
    try {
      const response = await axios.patch(`/location_v2?id=${locationId}`, settingsInput)
      if (response.status == 200) {
        setToast({ isOpen: true, message: 'Settings updated successfully.', color: 'success' })

        setUpdatingSettings(false)
        setDataChanged(false)
        getLocations(businessData?._id)
      }
    } catch (error) {
      setToast({ isOpen: true, message: 'Error updating settings. Please try again.', color: 'danger' })

      setDataChanged(false)
      setUpdatingSettings(false)
    }
  }
  useEffect(() => {
    const selectedlocation = GetLocationDataBasedOnLocationId(locationData, locationId)
    setRating(selectedlocation?.deflectionSettings?.deflections || 0)
    setDeflectBadReviews(selectedlocation?.deflectionSettings?.badReviewDeflectionEnabled || false)
    setTimeout(() => {
      setDataChanged(false)
    }, 100)
  }, [])

  return (
    <IonPage id='main-content'>
      <CommonHeader title={'Bad Review Deflection Settings'} backIcon={true}></CommonHeader>
      <IonContent>
        <StandardContainer>
          <div style={{ padding: '1rem' }}>
            <h1>Deflection Settings</h1>
            <IonList inset={true} style={{ margin: '1rem 0' }} mode='ios'>
              <IonItem>
                <IonCol size='9'>
                  <IonLabel>Deflect Bad Reviews</IonLabel>
                </IonCol>
                <IonCol size='3' style={{ display: 'flex', justifyContent: 'end' }}>
                  <IonToggle
                    justify='space-between'
                    size='small'
                    checked={deflectBadReviews}
                    onIonChange={(e) => {
                      toggleDeflectBadReviews(e.detail.checked)
                    }}
                  />
                </IonCol>
              </IonItem>
            </IonList>
            {deflectBadReviews && (
              <IonList inset={true} style={{ margin: '1rem 0' }} mode='ios'>
                <IonItem>
                  <h6 style={{ fontSize: '14px' }}>
                    Select which review ratings should be deflected and captured internally. Scores not deflected will be forwarded to your review sites
                    <FeedbackRating rating={rating} onRatingChange={handleRatingChange} />
                  </h6>
                </IonItem>
              </IonList>
            )}
            <IonButton disabled={!rating || !dataChanged} onClick={() => updateDeflectionSettings()} expand='full'>
              {updatingSettings && <IonSpinner name='lines' />}
              Save
            </IonButton>
          </div>
        </StandardContainer>
        <IonToast
          position='top'
          isOpen={toast.isOpen}
          message={toast.message}
          duration={2000}
          color={toast.color}
          onDidDismiss={() => setToast({ ...toast, isOpen: false })}
        />
      </IonContent>
    </IonPage>
  )
}
