import React from 'react'
import { setTimeFromDateStrForDate } from '../../utils'
import { calculateEventStyles } from './helpers'
import { IonLabel } from '@ionic/react'
import { fontWeights } from '../../theme/typography'

export default function Breaks({ breaks, selectedDate, hour, person, index, organisedEventsByName, locationStartTime }) {
  // Check if breaks is populated with data
  const hourStart = new Date(hour)
  const hourEnd = new Date(hour)
  hourEnd.setMinutes(hourEnd.getMinutes() + 59)

  return breaks?.map((breakEvent, breakIndex) => {
    const startTime = setTimeFromDateStrForDate(selectedDate, breakEvent.startTime)
    const employeeInBreak = `${breakEvent?.employee?.[0]?.firstName} ${breakEvent?.employee?.[0]?.lastName}`

    // Check if breakEvent's start time is within the current hour
    if (startTime >= hourStart && startTime < hourEnd) {
      const endTime = setTimeFromDateStrForDate(selectedDate, breakEvent.endTime)
      breakEvent.startTimeText = breakEvent.startTime
      breakEvent.endTimeText = breakEvent.endTime
      breakEvent.isBreak = true
      return person == employeeInBreak ? (
        <td
          className='break-event'
          key={breakIndex}
          style={{
            ...calculateEventStyles(breakEvent, person, index, organisedEventsByName, locationStartTime),
          }}
        >
          <IonLabel style={{ fontWeight: fontWeights.weightBold, color: '#6938EF', margin: 2 }}>
            Break ({breakEvent.startTime} - {breakEvent.endTime})
          </IonLabel>
        </td>
      ) : null
    }

    return null
  })
}
