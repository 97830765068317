// AppointmentStatus.js
import { IonIcon } from '@ionic/react'
import { hourglassOutline, checkmarkCircleOutline, skullOutline } from 'ionicons/icons'
import { styles } from './Styles/AppointmentStatusStyles'

export default function AppointmentStatus({ appointment }) {
  const calculateElapsedTime = (startTime, currentTime) => {
    const elapsedTimeInMs = currentTime - startTime

    if (elapsedTimeInMs <= 0) {
      return 'Just arrived'
    }

    const elapsedSeconds = Math.floor(elapsedTimeInMs / 1000)
    const elapsedMinutes = Math.floor(elapsedTimeInMs / (1000 * 60))
    const elapsedHours = Math.floor(elapsedTimeInMs / (1000 * 60 * 60))
    const elapsedDays = Math.floor(elapsedTimeInMs / (1000 * 60 * 60 * 24))

    if (elapsedDays > 0) {
      return elapsedDays === 1 ? 'A day ' : `${elapsedDays} days `
    } else if (elapsedHours > 0) {
      return elapsedHours === 1 ? 'An hour ' : `${elapsedHours} hours `
    } else if (elapsedMinutes > 0) {
      return elapsedMinutes === 1 ? 'A minute ' : `${elapsedMinutes} minutes `
    } else {
      return elapsedSeconds === 1 ? 'A second ' : `${elapsedSeconds} seconds `
    }
  }
  const elapsedTime = calculateElapsedTime(appointment?.startTime, new Date().getTime())

  return (
    <>
      {appointment?.status === 'Scheduled' && appointment?.startTime < new Date().getTime() && (
        <div style={styles.statusContainer}>
          <div style={styles.innerContainer}>
            <div style={styles.iconContainer}>
              <IonIcon style={{ color: styles.colors.red }} icon={hourglassOutline} />
            </div>
            <div style={{ ...styles.statusText, color: styles.colors.red }}>{elapsedTime !== 'Just arrived' ? `${elapsedTime} past start` : elapsedTime} </div>
          </div>
        </div>
      )}
      {appointment?.status === 'Arrived' && (
        <div style={styles.statusContainer}>
          <div style={styles.innerContainer}>
            <div style={styles.iconContainer}>
              <IonIcon style={{ color: styles.colors.orange }} icon={hourglassOutline} />
            </div>
            <div style={{ ...styles.statusText, color: styles.colors.orange }}>
              Arrived : Waiting {calculateElapsedTime(appointment?.timing?.stateTimestamps?.arrived, new Date().getTime())}{' '}
            </div>
          </div>
        </div>
      )}
      {appointment?.status === 'In Progress' && (
        <div style={styles.statusContainer}>
          <div style={styles.innerContainer}>
            <div style={styles.iconContainer}>
              <div className='dot' style={styles.dot} />
            </div>
            <div style={{ ...styles.statusText, color: styles.colors.blue }}>In Progress</div>
          </div>
        </div>
      )}
      {appointment?.status === 'Complete' && (
        <div style={styles.statusContainer}>
          <div style={styles.innerContainer}>
            <div style={styles.iconContainer}>
              <IonIcon style={{ color: styles.colors.green }} icon={checkmarkCircleOutline} />
            </div>
            <div style={{ ...styles.statusText, color: styles.colors.green }}>Appointment Complete</div>
          </div>
        </div>
      )}
      {appointment?.status === 'No Show' && (
        <div style={styles.statusContainer}>
          <div style={styles.innerContainer}>
            <div style={styles.iconContainer}>
              <IonIcon style={{ color: styles.colors.red }} icon={skullOutline} />
            </div>
            <div style={{ ...styles.statusText, color: styles.colors.red }}>No Show</div>
          </div>
        </div>
      )}
      {appointment?.status === 'Canceled' && (
        <div style={styles.statusContainer}>
          <div style={styles.innerContainer}>
            <div style={styles.iconContainer}>
              <IonIcon style={{ color: styles.colors.black45 }} icon={skullOutline} />
            </div>
            <div style={{ ...styles.statusText, color: styles.colors.black45 }}>Appointment Canceled</div>
          </div>
        </div>
      )}
    </>
  )
}
