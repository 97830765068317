// walkinAppointmentsStyles.js
export const styles = {
  headerStyle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: '#ffe5b4',
    color: '#e16d00',
    marginLeft: '3.5rem',
    position: 'sticky',
    top: 0,
    zIndex: 9999,
    borderLeft: '1px solid #c8c8c8',
    cursor: 'pointer',
  },

  modalContainerStyle: {
    width: '100%',
    height: '100%',
    paddingTop: 17,
    paddingBottom: 32,
    paddingLeft: 24,
    paddingRight: 24,
    background: 'white',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 10,
    display: 'inline-flex',
  },

  walkinItemStyle: {
    width: '100%',
    padding: 6,
    background: 'rgba(255, 255, 255, 0.60)',
    borderRadius: 4,
    border: '1px #B0B0B0 solid',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: 8,
    display: 'inline-flex',
  },

  walkinDetailStyle: {
    alignSelf: 'stretch',
    height: 80,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: 8,
    display: 'flex',
  },

  walkinHeaderStyle: {
    width: '100%',
    height: 52,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    display: 'flex',
  },

  walkinNameStyle: {
    alignSelf: 'stretch',
    color: '#222222',
    fontSize: 18,
    fontFamily: 'Figtree',
    fontWeight: '600',
    lineHeight: '28px',
    wordWrap: 'break-word',
  },

  walkinServiceStyle: {
    alignSelf: 'stretch',
    color: '#525252',
    fontSize: 16,
    fontFamily: 'Figtree',
    fontWeight: '500',
    lineHeight: '24px',
    wordWrap: 'break-word',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    textWrap: 'nowrap',
  },

  infoContainerStyle: {
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: 8,
    display: 'inline-flex',
  },

  infoItemStyle: {
    opacity: 0.6,
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 4,
    display: 'flex',
  },

  infoDividerStyle: {
    width: 20,
    height: 0,
    transform: 'rotate(90deg)',
    transformOrigin: '6px 4px',
    border: '1px #DADADA solid',
  },

  waitingStyle: {
    color: '#CC6900',
    fontSize: 14,
    fontFamily: 'Figtree',
    fontWeight: '500',
    lineHeight: '20px',
    wordWrap: 'break-word',
  },
}
