import { useState, useEffect } from 'react'
import axios from 'axios'
import List from './list'
import { IonCard, IonLabel } from '@ionic/react'

export default function DetailsServicesView({ businessId, locationId, employee, updateEmployee }) {
  const [locationServices, setLocationServices] = useState()
  useEffect(() => {
    getServicesList()
  }, [])

  const getServicesList = async () => {
    let locQuery = locationId ? `&locationId=${locationId}` : ''
    try {
      const response = await axios.get(`/service/getList?businessId=${businessId}${locQuery}`)
      setLocationServices(response.data) // Update state with the services list
    } catch (error) {
      console.error('Error fetching services list:', error) // Log the error for debugging
      // Optionally, you could set an error state here to handle it in the UI
    } finally {
    }
  }

  function updateServices(value) {
    updateEmployee({ ...employee, ...{ services: value } })
  }
  function updateServicesDurationInfo(value) {
    updateEmployee({ ...employee, ...{ servicesInfo: value } })
  }

  return (
    <IonCard style={{ padding: 20 }}>
      <IonLabel style={{ fontSize: 16, marginBottom: 20 }}>Services {employee.firstName} Provides</IonLabel>

      <List
        locationServices={locationServices}
        updateServices={updateServices}
        updateServicesDurationInfo={updateServicesDurationInfo}
        employeeServices={employee.services}
        employeeServicesInfo={employee.servicesInfo}
      />
    </IonCard>
  )
}
