// styles.js

export const styles = {
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 24,
    height: 24,
    position: 'relative',
  },
  statusText: {
    fontSize: 16,
    fontFamily: 'Figtree',
    fontWeight: '600',
    lineHeight: '24px',
    wordWrap: 'break-word',
  },
  statusContainer: {
    justifyContent: 'flex-end',
    alignItems: 'center',
    display: 'inline-flex',
  },
  innerContainer: {
    alignSelf: 'stretch',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 8,
    display: 'inline-flex',
  },
  dot: {
    width: 20,
    height: 20,
    borderRadius: '50%',
    background: 'radial-gradient(circle, #004eff 10%, rgba(0, 0, 0, 0) 70%)',
  },
  colors: {
    red: 'red',
    orange: 'orange',
    blue: '#004eff',
    green: 'green',
    black45: '#737373',
  },
}
