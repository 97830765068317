import { IonCard, IonCol, IonGrid, IonLabel, IonRow, IonSpinner } from '@ionic/react'
import { useEffect, useState } from 'react'
import { ResponsiveFunnel } from '@nivo/funnel'
import { getMonthRange } from '../Utils/GetDates'
import GetPickerMonths from '../Utils/GetPickerMonths'
import Picker from '../commonComponents/Picker'
import axios from 'axios'

export default function ConversionFunnel({ refId, updateTitle }) {
  // Initialize state variables and constants
  const pickerItems = GetPickerMonths()
  const [dataLoading, setDataLoading] = useState(true)
  let initialNumOfMonths = 6
  const [selectedOption, setSelectedOption] = useState(initialNumOfMonths - 1)
  const [data, setData] = useState(false)
  const [columnHeaders, setColumnHeaders] = useState([])
  const [funnelData, setFunnelData] = useState(false)
  // Function to fetch data based on reference ID, start date, end date, and number of months
  const getDataBasedOnRefId = async (startDate, endDate, noOfMonths) => {
    try {
      // Make API call to get report data
      const response = await axios.get(
        `/customerAcquisition/reports/conversionfunnel?fromDate=${startDate}&toDate=${endDate}&referenceId=${refId}`,
      )

      // Set loading state to false and process response data if status is 200
      setDataLoading(false)
      if (response.status === 200) {
        updateTitle(response?.data?.referenceName)
        const { stepFallout, overallFallout, referenceName, ...filteredData } = response.data
        // Ensure appt2 and appt3 exist in filteredData with the value 0
        //if it doesnot have then add value 0to it and add it
        if (!filteredData.hasOwnProperty('appt2')) {
          filteredData.appt2 = 0
        }

        if (!filteredData.hasOwnProperty('appt3')) {
          filteredData.appt3 = 0
        }
        const transformedData = Object.entries(filteredData).map(([key, value]) => {
          let label = ''
          // Define labels based on keys (you can adjust these labels according to your requirement)
          switch (key) {
            case 'scheduled':
              label = 'Scheduled'
              break
            case 'appt1':
              label = 'Appt1'
              break
            case 'appt2':
              label = 'Appt2'
              break
            case 'appt3':
              label = 'Appt3'
              break
            // Add more cases if needed for other keys
            default:
              break
          }

          return {
            id: `step_${key}`,
            value: value, // or Number(value) if needed
            label: label,
          }
        })

        setFunnelData(transformedData)
        setData(response.data)
        const columnHeaders = Object.keys(response.data['overallFallout'])
        setColumnHeaders(columnHeaders)
      }
    } catch (error) {
      // Handle errors if any
    }
  }

  // Function to handle the selected index change
  const handleSelectedIndex = indexValue => {
    const data = getMonthRange(indexValue)

    const selectedValue = pickerItems.find((item, index) => index === indexValue)
    if (selectedOption !== selectedValue.text) {
      setDataLoading(true)
      setSelectedOption(selectedValue.text)
      getDataBasedOnRefId(data.startDate, data.endDate, indexValue)
    }
  }

  // Initial data fetching on component mount
  useEffect(() => {
    const data = getMonthRange(initialNumOfMonths)
    getDataBasedOnRefId(data.startDate, data.endDate, initialNumOfMonths)
  }, [])
  return (
    <>
      <div style={{ padding: '0 1rem' }}>
        <h2>{data.referenceName}</h2>
      </div>

      <IonCard style={{ padding: '1rem' }}>
        <IonGrid style={{ margin: 0, padding: 0 }}>
          <IonRow style={{ margin: 0, padding: 0 }}>
            <IonCol style={{ margin: 0, padding: 0 }}>
              <h6 style={{ margin: 0, color: 'black', fontWeight: 'bold' }}>Conversion Funnel</h6>
            </IonCol>
          </IonRow>
        </IonGrid>
        <div style={{ margin: '0.5rem 0' }}>
          <Picker
            options={pickerItems}
            selectedItemIndex={initialNumOfMonths}
            selectedItem={selectedOption}
            handleSelectedIndex={handleSelectedIndex}
          />
        </div>
        {dataLoading && <IonSpinner name='dots' />}
        {!dataLoading && (
          <>
            <div style={{ height: '150px' }}>
              {}
              {funnelData && funnelData.length > 0 && (
                <ResponsiveFunnel
                  data={funnelData}
                  direction='horizontal'
                  margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
                  colors={{ scheme: 'blues' }}
                  borderWidth={20}
                  labelColor={{
                    from: 'color',
                    modifiers: [['darker', 3]],
                  }}
                  beforeSeparatorLength={0}
                  beforeSeparatorOffset={20}
                  afterSeparatorLength={0}
                  afterSeparatorOffset={20}
                  currentPartSizeExtension={10}
                  currentBorderWidth={40}
                  motionConfig='wobbly'
                />
              )}
            </div>
            <div>
              <IonRow style={{ display: 'flex', alignItems: 'center' }}>
                <IonCol
                  style={{
                    fontWeight: 'bold',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    minWidth: '120px',
                  }}
                >
                  Schedule
                </IonCol>
                {columnHeaders.map(header => (
                  <IonCol
                    style={{
                      fontWeight: 'bold',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      minWidth: 'fit-content',
                      width: 'fit-content',
                    }}
                    key={header}
                  >
                    {header}
                  </IonCol>
                ))}
              </IonRow>
              <IonGrid>
                {['stepFallout', 'overallFallout'].map((item, index) => {
                  return (
                    <>
                      <IonRow key={index} style={{ display: 'flex', alignItems: 'center' }}>
                        <IonCol
                          style={{
                            color: 'grey',
                            fontWeight: 'bold',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            minWidth: '120px',
                          }}
                        >
                          <IonLabel>
                            {item == 'stepFallout' ? 'Step Fallout' : 'Overall Fallout'}
                          </IonLabel>
                        </IonCol>
                        {columnHeaders.map(header => (
                          <IonCol
                            style={{
                              fontWeight: 'bold',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              minWidth: 'fit-content',
                              width: 'fit-content',
                            }}
                            key={`${item}-${header}`}
                          >
                            {data[item]?.[header] !== null
                              ? `${Number(data[item][header]).toFixed(2).replace(/\.00$/, '')}%`
                              : '-'}
                          </IonCol>
                        ))}
                      </IonRow>
                    </>
                  )
                })}
              </IonGrid>
            </div>
          </>
        )}
      </IonCard>
    </>
  )
}
