import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonModal,
  IonTitle,
  IonToolbar,
  IonItem,
  IonLabel,
  IonInput,
  IonGrid,
  IonRow,
  IonCol,
} from '@ionic/react'
import { useState } from 'react'
import PhoneNumberTextField from '../commonComponents/phoneNumberTextField' // Assuming this is already converted to Ionic

export default function EditPaneModal({ customer, onValueChange, emailValidationMessage, editPaneOpen, closeEditPane, save }) {
  const [localCustomer, setLocalCustomer] = useState(customer)

  const handleSave = () => {
    // Saving the data and closing the popup
    save(localCustomer)
    setTimeout(() => {
      closeEditPane()
    }, 200)
  }

  const handleInputChange = (field, value) => {
    setLocalCustomer({ ...localCustomer, [field]: value })
    onValueChange(field, value)
  }
  const handlePhoneNumberChange = (value) => {
    handleInputChange('phoneNumber', value)
  }

  return (
    <IonModal isOpen={editPaneOpen}>
      <IonHeader>
        <IonToolbar color='white' style={{ backgroundColor: 'white', color: 'black' }}>
          <IonTitle>Update Customer Details</IonTitle>
          <IonButtons slot='end'>
            <IonButton onClick={closeEditPane}>Close</IonButton>
            {!emailValidationMessage && (
              <IonButton color='primary' onClick={handleSave}>
                Save
              </IonButton>
            )}
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className='regularBackground'>
        <IonGrid padding={2}>
          <IonRow style={{ width: '100%' }}>
            <IonCol size='6'>
              <IonItem>
                <IonLabel position='stacked'>First Name</IonLabel>
                <IonInput value={localCustomer.firstName} onIonInput={(e) => handleInputChange('firstName', e.detail.value)} />
              </IonItem>
            </IonCol>
            <IonCol size='6'>
              <IonItem>
                <IonLabel position='stacked'>Last Name</IonLabel>
                <IonInput value={localCustomer.lastName} onIonInput={(e) => handleInputChange('lastName', e.detail.value)} />
              </IonItem>
            </IonCol>
            <IonRow style={{ marginLeft: 20, width: '100%' }}>
              <IonLabel position='stacked'>Email</IonLabel>
              <IonInput
                value={localCustomer.email}
                onIonInput={(e) => handleInputChange('email', e.detail.value)}
                error={!!emailValidationMessage}
                helperText={emailValidationMessage ? emailValidationMessage : ' '}
              />
            </IonRow>

            <PhoneNumberTextField value={localCustomer.phoneNumber} onChange={(e) => handlePhoneNumberChange(e.detail.value)} />
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonModal>
  )
}
