import { useState } from 'react'
import { IonButton, IonCol, IonContent, IonGrid, IonIcon, IonItem, IonLabel, IonList, IonRow } from '@ionic/react'
import styles from '../Styles/FinalizePaymentAndTipStyles'
import { checkmarkCircleOutline, chevronDown, chevronUp } from 'ionicons/icons'
import { fontSizes, fontWeights } from '../../../theme/typography'
import { colors } from '../../../theme/colors'
import ServicesProductsSummary from './ServicesProductsSummary'
import { formatDateWithWeekDay } from '../../../utils'

export default function PaymentSuccess({ cardData, payment, closeModals, appointment }) {
  const [showDetails, setShowDetails] = useState(false)

  const toggleDetails = () => {
    setShowDetails(!showDetails)
  }

  return (
    <IonContent>
      <IonGrid style={styles.successGrid}>
        <IonGrid style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
          <IonRow style={styles.successRow}>
            <IonIcon style={{ height: '4rem', width: '4rem', color: 'green' }} icon={checkmarkCircleOutline} />
          </IonRow>
          <IonRow style={styles.successRow}>
            <IonLabel style={{ fontSize: fontSizes.size30, fontWeight: fontWeights.weightBold }}>Payment Sent</IonLabel>
          </IonRow>
          <IonRow style={styles.successRow}>
            <IonLabel style={{ fontSize: fontSizes.size16, color: colors.black70 }}>We appreciate your business. Please come back soon.</IonLabel>
          </IonRow>
          <IonRow style={styles.totalPriceRow}>
            <IonLabel style={{ ...styles.dollarSign, fontSize: fontSizes.size20 }}>$</IonLabel>
            <IonLabel style={{ fontSize: fontSizes.size30 }}>{payment?.totalPrice?.toFixed(2)}</IonLabel>
          </IonRow>
        </IonGrid>
        <IonList>
          <IonItem>
            <IonLabel slot='start'>Payment Method </IonLabel>
            <IonLabel slot='end' style={{ fontWeight: fontWeights.weightBold }}>
              {cardData?.cardDetails?.cardBrand} - {cardData?.cardDetails?.cardLast4}
            </IonLabel>
          </IonItem>
          <IonItem>
            <IonLabel slot='start'>Date Sent</IonLabel>
            <IonLabel slot='end' style={{ fontWeight: fontWeights.weightBold }}>
              {formatDateWithWeekDay(new Date())}
            </IonLabel>
          </IonItem>
        </IonList>
      </IonGrid>
      <IonGrid style={{ margin: 0, padding: 30 }}>
        <h4 style={{ fontWeight: fontWeights.weightBold }}>RECEIPT</h4>
        <IonGrid style={{ borderRadius: 10, backgroundColor: colors.black5, padding: 10 }}>
          <IonRow style={{ fontWeight: fontWeights.weightBold }}>
            <IonCol>Amount Paid:</IonCol>
            <IonCol style={{ textAlign: 'end' }}>${payment?.totalPrice?.toFixed(2)}</IonCol>
          </IonRow>
          <IonRow style={{ marginTop: 20, display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
            <IonLabel
              color={'primary'}
              style={{ fontWeight: fontWeights.weightBold, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
              onClick={toggleDetails}
            >
              {showDetails ? 'Hide Details' : 'Show Details'}
              <IonIcon icon={showDetails ? chevronUp : chevronDown} />
            </IonLabel>
          </IonRow>
          {showDetails && <ServicesProductsSummary hideTotal={true} appointment={appointment} />}
        </IonGrid>
        <IonRow style={{ marginTop: 20 }}>
          <IonButton style={{ width: '100%' }} expand='full' shape='round' onClick={closeModals}>
            Done
          </IonButton>
        </IonRow>
      </IonGrid>
    </IonContent>
  )
}
