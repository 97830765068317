import { IonCol, IonItem, IonList, IonRow } from '@ionic/react'
import { ResponsiveFunnel } from '@nivo/funnel'

export default function ReviewConversionFunnel({ conversionFunnelData, funnelChartData }) {
  return (
    <IonList mode='ios' inset={true}>
      <h5 style={{ margin: '1rem 0 0 1rem' }}>Review Conversion Funnel</h5>
      {funnelChartData && (
        <>
          <div style={{ height: '200px' }}>
            <ResponsiveFunnel
              data={funnelChartData}
              direction='horizontal'
              margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
              colors={{ scheme: 'blues' }}
              borderWidth={20}
              labelColor={{
                from: 'color',
                modifiers: [['darker', 3]],
              }}
              beforeSeparatorLength={0}
              beforeSeparatorOffset={20}
              afterSeparatorLength={0}
              afterSeparatorOffset={20}
              currentPartSizeExtension={10}
              currentBorderWidth={40}
              motionConfig='wobbly'
            />
          </div>
          <IonRow style={{ marginBottom: '1rem', display: 'flex', alignItems: 'center' }}>
            {['Sent', 'Clicked', 'Submitted'].map(header => (
              <IonCol
                style={{
                  fontWeight: 'bold',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  minWidth: 'fit-content',
                  width: 'fit-content',
                }}
                key={header}
              >
                {header}
              </IonCol>
            ))}
          </IonRow>
          <IonItem
            style={{
              borderTop: '1px solid grey',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                width: '100%',
                marginBottom: '1rem',
              }}
            >
              <h1 style={{ fontWeight: 'bold' }}>
                {conversionFunnelData?.deflectedFeedbackPercentage}%
              </h1>
              <h6 style={{ margin: 0, fontWeight: 'bold' }}>Deflected</h6>
            </div>
          </IonItem>
        </>
      )}
    </IonList>
  )
}
