import { useState } from 'react'
import CustomInput from '../../../SetupBusiness/CustomInput'

const CodeForm = () => {
  const [code, setCode] = useState('')

  const handleCode = (index, value) => setCode(() => {})

  return (
    <>
      <p>Enter your 6 digit invite code to get started</p>
      <CustomInput label={'Invite Code*'} value={code} placeholder='0 0 0 0 0 0' handleInput={handleCode} />
    </>
  )
}

export default CodeForm
