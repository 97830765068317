import { useEffect, useState } from 'react'
import { IonCard, IonChip, IonContent, IonLabel, IonPage, IonSpinner } from '@ionic/react'
import axios from 'axios'
import CommonHeader from '../commonComponents/CommonHeader'
import StandardContainer from '../commonComponents/StandardContainer'
import Chart from 'react-apexcharts'

const AppointmentsScheduledHeatmap = ({ businessId }) => {
  const options = {
    chart: {
      type: 'heatmap',
      toolbar: false,
    },
    dataLabels: {
      enabled: false,
    },
    colors: ['#008FFB'],
    xaxis: {
      type: 'category',
      categories: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    },
    yaxis: {
      title: {
        text: 'Time',
      },
    },
  }
  const [data, setData] = useState([])
  const [date, setDate] = useState({
    startDate: new Date(new Date().setDate(new Date().getDate() - 7)),
    endDate: new Date(),
  })
  const [showAppointments, setShowAppointments] = useState(true)
  const [showWalkins, setShowWalkins] = useState(true)
  const [selectedTimeframe, setSelectedTimeframe] = useState('7days')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    getData()
  }, [date, businessId, showWalkins, showAppointments])
  const transformData = (inputData) => {
    return inputData.map((timeSlot) => ({
      name: timeSlot.id,
      data: timeSlot.data,
    }))
  }
  async function getData() {
    setLoading(true)
    if (date.startDate && date.endDate) {
      try {
        const d = await axios.get(`/appointment_v2/analyticsScheduledHeatmap`, {
          params: {
            businessId: businessId,
            startDate: date?.startDate?.toISOString(),
            endDate: date?.endDate?.toISOString(),
            showWalkins,
            showAppointments,
          },
        })

        const transformedData = transformData(d.data)

        setData(transformedData)
      } catch (error) {
        console.error('Failed to load data.', error) // Log the error for debugging
      }
      setLoading(false)
    }
  }

  const handleLast7DaysClick = () => {
    setSelectedTimeframe('7days')
    setDate({
      startDate: new Date(new Date().setDate(new Date().getDate() - 7)),
      endDate: new Date(),
    })
  }

  const handleLast30DaysClick = () => {
    setSelectedTimeframe('30days')
    setDate({
      startDate: new Date(new Date().setDate(new Date().getDate() - 30)),
      endDate: new Date(),
    })
  }

  const handleLastYearClick = () => {
    setSelectedTimeframe('year')
    setDate({
      startDate: new Date(new Date().setFullYear(new Date().getFullYear() - 1)),
      endDate: new Date(),
    })
  }

  return (
    <IonPage>
      <CommonHeader title={'When Did Customers Schedule?'} backIcon={true} />
      <IonContent>
        <StandardContainer>
          <IonCard>
            
            <div>
              <div style={{ margin: 14 }}>
                <h4>When Did Customers Schedule?</h4>
              </div>
              <div style={{ margin: 10 }}>
                <IonChip outline={selectedTimeframe !== '7days'} onClick={handleLast7DaysClick} color='primary'>
                  <IonLabel>Last 7 days</IonLabel>
                </IonChip>
                <IonChip outline={selectedTimeframe !== '30days'} onClick={handleLast30DaysClick} color='primary'>
                  <IonLabel>Last 30 days</IonLabel>
                </IonChip>
                <IonChip outline={selectedTimeframe !== 'year'} onClick={handleLastYearClick} color='primary'>
                  <IonLabel>Last 12 mo</IonLabel>
                </IonChip>
              </div>
              <div style={{ margin: 10, marginTop: -10 }}>
                <IonChip
                  outline={!showAppointments}
                  onClick={() => {
                    setShowAppointments(!showAppointments)
                  }}
                  color='primary'
                >
                  <IonLabel>Scheduled</IonLabel>
                </IonChip>
                <IonChip
                  outline={!showWalkins}
                  onClick={() => {
                    setShowWalkins(!showWalkins)
                  }}
                  color='primary'
                >
                  <IonLabel>Walkins</IonLabel>
                </IonChip>
              </div>
              {loading ? <IonSpinner name='dots'/> : null}
              {data.length > 0 ? (
                <div style={{ height: 500 }}>
                  <Chart options={options} series={data} type='heatmap' height={500} />

                  {/* <ResponsiveHeatMap
                    data={data}
                    margin={{ top: 40, right: 20, bottom: 40, left: 60 }}
                    axisTop={{
                      tickSize: 5,
                      tickPadding: 5,
                      tickRotation: -90,
                      legend: '',
                      legendOffset: 46,
                    }}
                    axisLeft={{
                      tickSize: 5,
                      tickPadding: 5,
                      tickRotation: 0,
                      legend: 'Appointents',
                      legendPosition: 'middle',
                      legendOffset: -72,
                    }}
                    colors={{
                      type: 'sequential',
                      scheme: 'blues',
                      minValue: 0,
                    }}
                    borderRadius={5}
                    borderWidth={2}
                    borderColor='#ffffff'
                    enableLabels={false}
                    emptyColor='#f7fafc'
                    //  legends={[
                    //      {
                    //          anchor: 'bottom',
                    //          translateX: 0,
                    //          translateY: 30,
                    //          length: 400,
                    //          thickness: 8,
                    //          direction: 'row',
                    //          tickPosition: 'after',
                    //          tickSize: 3,
                    //          tickSpacing: 4,
                    //          tickOverlap: false,

                    //          title: ' →',
                    //          titleAlign: 'start',
                    //          titleOffset: 4
                    //      }
                    //  ]}
                  /> */}
                </div>
              ) : null}
            </div>
          </IonCard>
        </StandardContainer>
      </IonContent>
    </IonPage>
  )
}

export default AppointmentsScheduledHeatmap
