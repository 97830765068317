import React from 'react';
import axios from 'axios';
import { IonButton } from '@ionic/react';

function StripeOnboardingButton ({ locationId, title }) {
  const handleOnboarding = async () => {
    try {
      const response = await axios.get(`/location_v2/stripeAccountLink?locationId=${locationId}`);

      console.log(response.data)

      if (response.data.stripeOnboardingLink) {
        // Redirect the user to the Stripe onboarding page
        window.location.href = response.data.stripeOnboardingLink;
      } else {
        console.error('Error during onboarding:');
        alert('Failed to get onboarding URL');
      }
    } catch (error) {
      console.error('Error during onboarding:', error);
      alert('An error occurred during onboarding');
    }
  };

  return (
    <div>
      <IonButton onClick={handleOnboarding} color="primary">
      {title ? title : "Setup Billing"}
      </IonButton>
    </div>
  );
}

export default StripeOnboardingButton;
