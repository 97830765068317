import React, { useState, useEffect } from 'react';
import { IonContent, IonSpinner } from '@ionic/react';

const loadingContainerStyle = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh', // Full viewport height
  backgroundColor: 'white',
};

const LoadingScreen = () => {
  const [showSpinner, setShowSpinner] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowSpinner(true);
    }, 3000); // Show the Ion Spinner after 3 seconds

    return () => clearTimeout(timer); // Clear the timer if the component unmounts
  }, []);

  return (
    <IonContent>
      <div style={loadingContainerStyle}>
        <img style={{ height: 50 }} alt='ClubCuts' src='/assets/logos/CLUBCUTS_TEXT_RIGHT.png' />
        {showSpinner && <IonSpinner name="dots" />}
      </div>
    </IonContent>
  );
};

export default LoadingScreen;
