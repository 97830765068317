import { IonCol, IonItem, IonLabel, IonList, IonRow, IonSpinner } from '@ionic/react'
import { cancelSubscriptionOptions } from '../../StaticData/StaticData'
import commonStyles from '../../theme/commonstyles.module.css'
import { getFormattedDateFromDateObject } from '../../utils'
export default function CancelledSubscriptionsResultList({ loading, cancelledList }) {
  if (loading) {
    return (
      <IonRow className={commonStyles.flexCenter}>
        <IonSpinner name='dots' />
      </IonRow>
    )
  }
  return cancelledList && cancelledList.length > 0 ? (
    <IonList inset={true} mode='ios'>
      {cancelledList &&
        cancelledList?.map((item, index) => {
          const reasonItem = cancelSubscriptionOptions.find(
            (subscriptionItem, subscriptionIndex) =>
              subscriptionItem?.value === item?.cancellationReason,
          )
          return (
            <IonItem routerLink={`/core/cancelledSubscription/${item._id}`} detail={true}>
              <IonRow style={{ margin: '1rem 0', width: '100%' }}>
                <IonCol size='8'>
                  <IonLabel style={{ fontWeight: 'bold' }}>
                    {item.customer?.[0]?.firstName} {item.customer?.[0]?.lastName}
                  </IonLabel>
                  {}
                  <h6 style={{ margin: '5px 0', color: 'black' }}>
                    {item?.customer?.firstName} {item?.customer?.lastName}
                  </h6>
                  <h6 style={{ fontSize: '0.8rem', margin: '5px 0', color: 'black' }}>
                    {item?.endDate && (
                      <span>Cancelled: {getFormattedDateFromDateObject(item?.endDate)}</span>
                    )}
                  </h6>
                  <h6 style={{ fontSize: '0.8rem', margin: '5px  0', color: 'grey' }}>
                    {reasonItem?.label === 'Other' ? (
                      <span>
                        Reason: {reasonItem.label}{' '}
                        {item.writtenFeedback ? ' - ' + item.writtenFeedback : ''}
                      </span>
                    ) : (
                      <span>{reasonItem?.label}</span>
                    )}
                  </h6>
                </IonCol>
                <IonCol
                  size='4'
                  style={{
                    fontSize: '0.9rem',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <IonLabel>
                    $ {item.price} {item.period}
                  </IonLabel>
                </IonCol>
              </IonRow>
            </IonItem>
          )
        })}
    </IonList>
  ) : null
}
