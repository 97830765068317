import React from 'react';
import axios from 'axios';
import { IonButton } from '@ionic/react';

function StripeDashboardLinkButton ({ locationId, title }) {
  const handleOnboarding = async () => {
    try {
      const response = await axios.get(`/location_v2/stripeDashboardLink?locationId=${locationId}`);

      console.log(response.data)

      if (response.data.dashboardLink) {
        // Redirect the user to the Stripe onboarding page
        window.location.href = response.data.dashboardLink;
      } else {
        console.error('Error during dashboard link:');
        alert('Failed to get dashboard URL');
      }
    } catch (error) {
      console.error('Error during dashboard link:', error);
      alert('An error occurred during dashboard link');
    }
  };

  return (
    <div>
      <IonButton onClick={handleOnboarding} color="secondary">
      {title ? title : "Stripe Dashboard"}
      </IonButton>
    </div>
  );
}

export default StripeDashboardLinkButton;
