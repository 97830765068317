import { useState, useContext, useEffect } from 'react'
import { IonButton, IonModal, IonHeader, IonContent, IonToolbar, IonTitle, IonItem, IonLabel, IonTextarea, IonSpinner, IonToggle, IonButtons, IonList, IonAlert } from '@ionic/react'
import axios from 'axios'
import 'react-quill/dist/quill.snow.css' // import the styles
import { AuthContext } from '../auth'
import StandardContainer from '../commonComponents/StandardContainer'


function AdvancedSettingsModal({ isOpen, setOpen, customer, fetchCustomer }) {
  const { businessData, userData } = useContext(AuthContext)
  const [isSaving, setSaving] = useState(false)
  const [isBlocked, setIsBlocked] = useState(customer?.isBlocked)
  const [isUpdating, setIsUpdating] = useState(false)
  const [showSpinner, setShowSpinner] = useState(false)
  const [locations, setLocations] = useState([])
  const [showLocationModal, setShowLocationModal] = useState(false)
  const [showConfirmAlert, setShowConfirmAlert] = useState(false)
  const [selectedLocationForAdd, setSelectedLocationForAdd] = useState(null)

  useEffect(() => {
    fetchLocations()
  }, [])

  const fetchLocations = async () => {
    try {
      const response = await axios.get(`/location_v2/publicGetListByBusinessId?businessId=${businessData._id}`)
      setLocations(response.data)
    } catch (error) {
      console.error('Error fetching locations:', error)
    }
  }

  const handleBlockToggle = async () => {
    setIsUpdating(true)
    setTimeout(() => setShowSpinner(true), 500)
    try {
      await axios.post('/customer_v2/block', {
        customerId: customer._id,
        isBlocked: !isBlocked
      })
      setIsBlocked(!isBlocked)
      fetchCustomer()
    } catch (error) {
      console.error('Error updating customer block status:', error)
    } finally {
      setIsUpdating(false)
      setShowSpinner(false)
    }
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleAddLocation = (location) => {
    setSelectedLocationForAdd(location)
    setShowConfirmAlert(true)
  }

  const confirmAddLocation = async () => {
    try {
      await axios.post(`/customer_v2/addLocation?customerId=${customer._id}&locationId=${selectedLocationForAdd._id}`)
      fetchCustomer()
      setShowLocationModal(false)
      setShowConfirmAlert(false)
    } catch (error) {
      console.error('Error adding location:', error)
    }
  }

  return (
    <IonModal isOpen={isOpen} trigger='open-modal'>
      <IonHeader>
        <IonToolbar color='black' style={{ backgroundColor: 'white', color: 'black' }}>
          <IonButtons slot="start">
            <IonButton onClick={handleClose}>Close</IonButton>
          </IonButtons>
          <IonTitle style={{ color: 'black' }}>Advanced Settings</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="regularBackground">
        <StandardContainer medium={true}>
          <h6 style={{ marginLeft: 16, marginTop: 24, marginBottom: 16 }}>Block Customer</h6>
          <p style={{ margin: 16, color: 'grey' }}>This feature will block a customer from being able to book appointments.</p>
          <IonList inset={true}>
            <IonItem>
              <IonLabel>Block Customer</IonLabel>
              {isUpdating && showSpinner ? (
                <IonSpinner name="dots" slot="end" />
              ) : (
                <IonToggle checked={isBlocked} onIonChange={handleBlockToggle} disabled={isUpdating} slot="end" />
              )}
            </IonItem>
          </IonList>
         

          <h6 style={{ marginLeft: 16, marginTop: 24, marginBottom: 16 }}>Customer Locations</h6>
          <IonList inset={true}>
            {customer?.locations?.map(loc => (
              <IonItem key={loc._id}>
                <IonLabel>{locations.find(l => l._id === loc._id)?.name || 'Unknown Location'}</IonLabel>
              </IonItem>
            ))}
          </IonList>
          <IonButton fill="clear" onClick={() => setShowLocationModal(true)} style={{ marginTop: -20 }}>+ Add Location</IonButton>
        </StandardContainer>
      </IonContent>

      <IonModal isOpen={showLocationModal} onDidDismiss={() => setShowLocationModal(false)}>
        <IonHeader>
          <IonToolbar>
            <IonTitle>Add Location</IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={() => setShowLocationModal(false)}>Close</IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonList>
            {locations.map(location => (
              <IonItem key={location._id} disabled={customer?.locations?.some(loc => loc._id === location._id)}>
                <IonLabel>{location.name}</IonLabel>
                {!customer?.locations?.some(loc => loc._id === location._id) && (
                  <IonButton slot="end" onClick={() => handleAddLocation(location)}>Add</IonButton>
                )}
              </IonItem>
            ))}
          </IonList>
        </IonContent>
      </IonModal>

      <IonAlert
        isOpen={showConfirmAlert}
        onDidDismiss={() => setShowConfirmAlert(false)}
        header="Confirm"
        message={`Are you sure you want to add ${selectedLocationForAdd?.name} to this customer's locations?`}
        buttons={[
          {
            text: 'Cancel',
            role: 'cancel',
          },
          {
            text: 'Confirm',
            handler: confirmAddLocation,
          },
        ]}
      />
    </IonModal>
  )
}

export default AdvancedSettingsModal
