import { IonButton, IonCol, IonGrid, IonItem, IonLabel, IonList, IonRow, IonSpinner, IonToast } from '@ionic/react'
import { useContext, useEffect, useState } from 'react'
import StandardContainer from '../commonComponents/StandardContainer'
import { AuthContext } from '../auth'
import axios from 'axios'
import StandardCenterCenter from '../commonComponents/StandardCenterCenter'

export default function EmailRemarketingList({ status }) {
  const { businessData } = useContext(AuthContext)
  const [emailList, setEmailList] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [toast, setToast] = useState({ isOpen: false, message: '', color: '' })
  const showToastMessage = (message, color) => {
    setToast({ isOpen: true, message, color })
  }
  const fetchEmailList = async () => {
    try {
      setIsLoading(true)
      const response = await axios.get(`/emailRemarketing/${status}List`)
      setEmailList(response.data)
    } catch (error) {
      showToastMessage('Error fetching email list: ' + error.message, 'danger')
      console.error('Error fetching email list:', error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchEmailList()
  }, [businessData, status])

  const processList = async () => {
    const isConfirmed = window.confirm('Are you sure you want to process the list?')
    if (isConfirmed) {
      try {
        await axios.post('/emailRemarketing/runScheduler')
        fetchEmailList()
        showToastMessage('List processed successfully!', 'success')
      } catch (error) {
        showToastMessage('Error processing list: ' + error.message, 'danger')
      }
    }
  }

  const sendAll = async () => {
    const isConfirmed = window.confirm('Are you sure you want to send to everyone?')
    if (isConfirmed) {
      try {
        await axios.post('/emailRemarketing/sendEmails')
        fetchEmailList()
        showToastMessage('Emails sent successfully!', 'success')
      } catch (error) {
        showToastMessage('Error sending emails: ' + error.message, 'danger')
      }
    }
  }

  const handleSendEmail = async (id) => {
    const isConfirmed = window.confirm('Are you sure you want to send this email?')
    if (isConfirmed) {
      try {
        await axios.post('/emailRemarketing/sendOne', { id })
        fetchEmailList()
        showToastMessage('Email sent successfully!', 'success')
      } catch (error) {
        showToastMessage('Failed to send email: ' + error.message, 'danger')
      }
    }
  }

  const handleDeleteEmail = async (id) => {
    const isConfirmed = window.confirm('Are you sure you want to delete this email?')
    if (isConfirmed) {
      try {
        await axios.post('/emailRemarketing/remove', { id })
        fetchEmailList()
        showToastMessage('Email deleted successfully!', 'success')
      } catch (error) {
        showToastMessage('Failed to delete email: ' + error.message, 'danger')
      }
    }
  }

  return (
    <StandardContainer>
      <StandardCenterCenter>
        <IonGrid>
          <IonRow>
            <IonCol>
              <IonButton onClick={processList}>Process List</IonButton>
            </IonCol>
            <IonCol>
              <IonButton color={'danger'} onClick={sendAll}>
                Send All
              </IonButton>
            </IonCol>
            <IonCol>
              <IonButton color={'light'} onClick={fetchEmailList}>
                Refresh List
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </StandardCenterCenter>
      {isLoading ? <IonSpinner name='crescent' /> : null}

      <p style={{ color: 'grey', fontSize: 12, marginLeft: 20 }}>{emailList?.length} emails</p>

      <IonList mode='ios' inset={true}>
        {emailList.map((email) => (
          <IonItem key={email._id}>
            <IonLabel>
              <h2 style={{ fontWeight: '500' }}>
                {email.customer.firstName} {email.customer.lastName} - {email.customer.email}
              </h2>
              <p>
                {email.lastAppointmentSelectedDate ? (
                  <>
                    Last Appointment: {email.lastAppointmentSelectedDate} - {email.daysSinceLastAppointment.toFixed(0)} days ago
                  </>
                ) : (
                  'No Last Appointment'
                )}
              </p>
              {email.lastEmailSentDays ? (
                <p>
                  Last Sent: {email.lastEmailSentDate} ({email.lastEmailSentDays})
                </p>
              ) : (
                <>{email.status === 'sent' ? <p>{new Date(email.sentTime).toString()}</p> : <p>No Previous Emails</p>}</>
              )}
            </IonLabel>
            {email.status === 'sent' ? null : (
              <>
                <IonButton onClick={() => handleSendEmail(email._id)} color='success'>
                  Send
                </IonButton>
                <IonButton onClick={() => handleDeleteEmail(email._id)} color='danger'>
                  Delete
                </IonButton>
              </>
            )}
          </IonItem>
        ))}
      </IonList>

      <IonToast isOpen={toast.isOpen} onDidDismiss={() => setToast({ ...toast, isOpen: false })} message={toast.message} color={toast.color} duration={2000} />
    </StandardContainer>
  )
}
