import { IonItem, IonLabel, IonList } from '@ionic/react'

export default function ResultsList({ discounts = [] }) {
  return (
    <>
      <h6 style={{ color: 'grey', marginLeft: 20 }}>Discounts</h6>
      <IonList inset={true} mode='ios'>
        {discounts?.map((row) => (
          <IonItem button detail={true} routerLink={`/core/discounts/${row._id}`} routerDirection='forward' key={row._id}>
            <IonLabel>
              <h4 style={{ fontWeight: '600', color: 'black', fontSize: 18 }}>{`${row.name}`}</h4>
              <DiscountType row={row} />
            </IonLabel>
          </IonItem>
        ))}
      </IonList>
    </>
  )
}

function DiscountType({ row }) {
  if (row?.discountType == 'percentage') {
    return <p>{row?.discountValue}% off</p>
  } else if (row?.discountType == 'fixed') {
    return <p>${row?.discountValue} off</p>
  } else {
    return null
  }
}
