import { IonButton, IonButtons, IonContent, IonModal, IonSpinner } from '@ionic/react'
import React from 'react'

export default function ConfirmationModal({ isOpen, message, cancel, loading, save, saveText }) {
  return (
    <IonContent>
      <div
        style={{
          padding: '1rem',
        }}
      >
        <h2 style={{ textAlign: 'center' }}>{message}</h2>
        <IonButtons
          slot='end'
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '1rem',
          }}
        >
          <IonButton onClick={() => cancel()} style={{ color: 'grey' }}>
            Cancel
          </IonButton>
          {loading && <IonSpinner name='lines' />}
          {!loading && (
            <IonButton color={'primary'} onClick={save}>
              {saveText}
            </IonButton>
          )}
        </IonButtons>
      </div>
    </IonContent>
  )
}
