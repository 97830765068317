const styles = {
  button: {
    flex: '1 1 0',
    height: 52,
    background: '#222222',
    borderRadius: 60,
    overflow: 'hidden',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 6,
    display: 'flex',
  },
  modalContent: {
    padding: '2rem',
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
  },
  chargeContainer: {
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 16,
    display: 'inline-flex',
  },
  chargeHeader: {
    alignSelf: 'stretch',
    height: 79,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 12,
    display: 'flex',
  },
  chargeTitle: {
    alignSelf: 'stretch',
    height: 39,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: 10,
    display: 'flex',
  },
  titleText: {
    width: 263,
    height: 39,
    color: '#222222',
    fontSize: 32,
    fontFamily: 'Figtree',
    fontWeight: 600,
    lineHeight: '38.4px',
    wordWrap: 'break-word',
  },
  subTitle: {
    alignSelf: 'stretch',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 10,
    display: 'inline-flex',
  },
  subTitleText: {
    flex: '1 1 0',
    color: '#222222',
    fontSize: 18,
    fontFamily: 'Figtree',
    fontWeight: 400,
    lineHeight: '28px',
    wordWrap: 'break-word',
  },
  radioGroup: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    display: 'flex',
  },
  optionContainer: (custom) => ({
    height: custom ? 'auto' : 114,
    paddingTop: 16,
    paddingBottom: 16,
    borderBottom: '1px #EBEBEB solid',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: 12,
    display: 'flex',
  }),
  optionInnerContainer: {
    alignSelf: 'stretch',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: 12,
    display: 'inline-flex',
  },
  optionTextContainer: {
    flex: '1 1 0',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: 6,
    display: 'inline-flex',
  },
  optionTextHeader: {
    flex: '1 1 0',
    color: '#222222',
    fontSize: 18,
    fontFamily: 'Figtree',
    fontWeight: 600,
    lineHeight: '28px',
    wordWrap: 'break-word',
  },
  optionDescription: {
    alignSelf: 'stretch',
  },
  descriptionText: {
    color: '#222222',
    fontSize: 16,
    fontFamily: 'Figtree',
    fontWeight: 400,
    lineHeight: '24px',
    wordWrap: 'break-word',
  },
  amountText: {
    color: '#222222',
    fontSize: 16,
    fontFamily: 'Figtree',
    fontWeight: 700,
    lineHeight: '24px',
    wordWrap: 'break-word',
  },
  customInput: {
    marginTop: '10px',
    padding: '5px',
    fontSize: '16px',
    width: '100%',
  },
  errorLabel: {
    margin: '1rem',
    color: 'red',
    fontSize: '12px',
  },
  ionRadio: {
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: 10,
    display: 'flex',
  },
  chargeButton: {
    width: 342,
    height: 48,
    background: 'radial-gradient(50.91% 25.97% at 50.91% 21.92%, #005BCC 0%, #006AF5 53%, #0068DE 100%)',
    borderRadius: 60,
    overflow: 'hidden',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 6,
    display: 'inline-flex',
  },
  buttonInner: {
    paddingLeft: 2,
    paddingRight: 2,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  buttonText: {
    color: 'white',
    fontSize: 18,
    fontFamily: 'Figtree',
    fontWeight: 600,
    lineHeight: '28px',
    wordWrap: 'break-word',
  },
}

export default styles
