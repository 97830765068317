import React, { useState } from 'react'
import { IonButton, IonIcon, IonLabel, IonCard, IonCardContent, IonModal, IonContent, IonGrid, IonRow, IonCol } from '@ionic/react'
import { chevronDownOutline, chevronUpOutline, trashBinOutline } from 'ionicons/icons'
import { AccessControl } from '../AccessControl'
import ConfirmationModal from '../commonComponents/ConfirmationModal'

export default function AdvancedDetails({ deleteProduct }) {
  const [isAdvancedOpen, setIsAdvancedOpen] = useState(false) // Manage collapsible state
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false) // Manage delete confirmation modal state

  const toggleAdvancedSection = () => {
    setIsAdvancedOpen((prevState) => !prevState)
  }

  const handleDeleteConfirm = () => {
    deleteProduct() // Call the delete product function
    setIsDeleteModalOpen(false) // Close the modal after deletion
  }

  return (
    <>
      <IonCard style={{ marginTop: 20, border: '1px solid #ccc', borderRadius: 8 }}>
        <IonCardContent>
          <IonLabel
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              cursor: 'pointer',
              fontWeight: 'bold',
              fontSize: '16px',
            }}
            onClick={toggleAdvancedSection}
          >
            Advanced
            <IonIcon icon={isAdvancedOpen ? chevronUpOutline : chevronDownOutline} />
          </IonLabel>

          {isAdvancedOpen && (
            <div style={{ marginTop: 20 }}>
              <AccessControl componentName={'deleteProduct'}>
                <IonButton expand='block' color='danger' onClick={() => setIsDeleteModalOpen(true)}>
                  Delete
                </IonButton>
              </AccessControl>
            </div>
          )}
        </IonCardContent>
      </IonCard>

      {/* Delete Confirmation Modal */}
      <IonModal isOpen={isDeleteModalOpen} onDidDismiss={() => setIsDeleteModalOpen(false)}>
        <ConfirmationModal
          saveText={'Delete'}
          save={handleDeleteConfirm}
          cancel={() => setIsDeleteModalOpen(false)}
          message='Are you sure you want to delete this product'
        />
      </IonModal>
    </>
  )
}
