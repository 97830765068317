export const colors = {
  primaryBlue: '#3880ff',
  primaryBlue50: '#3880ff8a',
  primaryBlue90: '#3880ff14',
  darkBlue: '#3880ff',
  green: '#00B050',
  green50: '#50c878e6',
  purple: '#7030A0',
  grey: '#A5A8A9',
  orangeDeep:'#7d4602',
  orange: '#FF892C',
  orange90: '#ff892c85',
  black: '#222222',
  white: '#ffffff',
  lightGrey: '#d6d6d6',
  errorText: '#d32f2f',
  regularBackground: '#F0F5F8',
  black100: 'rgba(0, 0, 0, 1)',
  black95: 'rgba(0, 0, 0, 0.95)',
  black90: 'rgba(0, 0, 0, 0.9)',
  black85: 'rgba(0, 0, 0, 0.85)',
  black80: 'rgba(0, 0, 0, 0.8)',
  black75: 'rgba(0, 0, 0, 0.75)',
  black70: 'rgba(0, 0, 0, 0.7)',
  black65: 'rgba(0, 0, 0, 0.65)',
  black60: 'rgba(0, 0, 0, 0.6)',
  black55: 'rgba(0, 0, 0, 0.55)',
  black50: 'rgba(0, 0, 0, 0.5)',
  black45: 'rgba(0, 0, 0, 0.45)',
  black40: 'rgba(0, 0, 0, 0.4)',
  black35: 'rgba(0, 0, 0, 0.35)',
  black30: 'rgba(0, 0, 0, 0.3)',
  black25: 'rgba(0, 0, 0, 0.25)',
  black20: 'rgba(0, 0, 0, 0.2)',
  black15: 'rgba(0, 0, 0, 0.15)',
  black10: 'rgba(0, 0, 0, 0.1)',
  black5: 'rgba(0, 0, 0, 0.05)',
}
