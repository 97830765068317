import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonRow,
} from '@ionic/react'
import { add, checkmarkCircleOutline, pricetagOutline } from 'ionicons/icons'
import { useEffect, useState } from 'react'
import CommonHeader from '../commonComponents/CommonHeader'
import StandardContainer from '../commonComponents/StandardContainer'
import axios from 'axios'

export default function ChooseDiscountAndCoupon({
  selectedCoupon,
  coupons,
  customerId,
  updateCoupon,
}) {
  const [couponModalOpen, setCouponModalOpen] = useState(false)
  const openCouponModal = () => {
    setCouponModalOpen(true)
  }
  const closeCouponModal = () => {
    setCouponModalOpen(false)
  }
  const handleSelectedCoupon = value => {
    updateCoupon(value)
  }
  const saveCoupon = () => {
    setCouponModalOpen(false)
  }

  return (
    <>
      {!selectedCoupon && (
        <IonButton onClick={openCouponModal} style={{ fontSize: 14, marginLeft: 10 }} fill='clear'>
          <IonIcon icon={add} /> Add Coupon
        </IonButton>
      )}
      <IonModal isOpen={couponModalOpen}>
        <CommonHeader
          title={'Choose Coupon'}
          closeButton={true}
          closeModal={closeCouponModal}
          saveDisabled={!selectedCoupon}
          handleSaveButton={saveCoupon}
          saveButton={true}
        ></CommonHeader>
        <IonContent className='regularBackground'>
          <StandardContainer>
            <IonGrid>
              {}
              {coupons && coupons.length > 0 && (
                <IonList mode='ios' inset={true}>
                  {coupons.map((discount, index) => {
                    return (
                      <IonItem
                        style={{
                          width: '100%',
                          minWidth: '100%',
                        }}
                        onClick={() => handleSelectedCoupon(discount)}
                      >
                        <IonRow
                          style={{
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                          }}
                        >
                          <IonCol style={{ display: 'flex', alignItems: 'center' }}>
                            <h6>{discount.name}</h6>
                            <h6 style={{ marginLeft: '0.5rem', fontSize: '12px', color: 'grey' }}>
                              {discount.discountType == 'fixed'
                                ? `($${discount.discountValue})`
                                : `(${discount.discountValue}%)`}
                            </h6>
                          </IonCol>
                          <IonCol size='auto'>
                            {selectedCoupon &&
                              selectedCoupon.stripeCouponId === discount.stripeCouponId && (
                                <IonIcon color='primary' icon={checkmarkCircleOutline} />
                              )}
                          </IonCol>
                        </IonRow>
                      </IonItem>
                    )
                  })}
                </IonList>
              )}
            </IonGrid>
          </StandardContainer>
        </IonContent>
      </IonModal>
    </>
  )
}
