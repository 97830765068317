import { IonBackButton, IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonPage, IonProgressBar, IonSearchbar, IonTitle, IonToolbar } from '@ionic/react'
import { addCircle } from 'ionicons/icons'

export default function DetailsHeader({ cancel, save, title, valueChanged, additionalToolbar, headerItem }) {
  return (
    <IonHeader>
      <IonToolbar color='black' style={{ backgroundColor: 'white', color: 'black' }}>
        <IonButtons slot='start' style={{ color: 'black' }}>
          <IonBackButton />
        </IonButtons>
        <IonTitle style={{ color: 'black' }}>{title}</IonTitle>
        {headerItem ? headerItem : null}
        {valueChanged ? (
          <>
            <IonButtons slot='end'>
              <IonButton onClick={cancel} style={{ color: 'black' }}>
                Cancel
              </IonButton>
            </IonButtons>
            <IonButtons slot='end'>
              <IonButton color='primary' onClick={save}>
                Save
              </IonButton>
            </IonButtons>
          </>
        ) : null}
      </IonToolbar>
      {additionalToolbar ? (
        <IonToolbar style={{ background: 'white' }} color={'default'}>
          {additionalToolbar}
        </IonToolbar>
      ) : null}
    </IonHeader>
  )
}
