import { IonCard, IonCol, IonContent, IonGrid, IonLabel, IonPage, IonRow, IonSpinner } from '@ionic/react'
import { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../auth'
import axios from 'axios'
import { useLocation } from 'react-router'
import Header from '../commonComponents/StandardHeader'
import StandardContainer from '../commonComponents/StandardContainer'
import CommonHeader from '../commonComponents/CommonHeader'

export default function NotificationReport() {
  const [loading, setLoading] = useState(true)
  const { businessData, locationId } = useContext(AuthContext)
  const [reportData, setReportData] = useState({})
  const [error, setError] = useState('')
  const location = useLocation()
  const getReportData = async () => {
    try {
      const response = await axios.get(`/customer_v2/notification/report?businessId=${businessData?._id}&locationId=${locationId}`)

      if (response?.data) {
        setReportData(response.data)
        setLoading(false)
      } else {
        setError('No Data')
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
    }
  }

  useEffect(() => {
    getReportData()
  }, [])
  const centerStyles = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  }
  const borderStyles = { borderRight: '1px solid #d6d6d6' }
  return (
    <IonPage>
      <CommonHeader title={'Notification Report'} backIcon={true} />
      <IonContent>
        <StandardContainer>
          <IonCard>
            <div style={{ margin: 14 }}>
              <h4 style={{ color: 'black' }}>Text and Email Notifications</h4>
              <h6 style={{ color: 'grey', marginTop: -4 }}>Customers with :</h6>
              <IonRow style={{ margin: '1rem 0', width: '100%' }}>
                {loading && (
                  <IonRow>
                    <IonSpinner name='dots' />
                  </IonRow>
                )}
                {!loading && error && <IonLabel>No Data To Display</IonLabel>}
                {!loading && !error && (
                  <IonGrid style={{ color: 'black', maxWidth: '100%' }}>
                    <IonRow
                      style={{
                        borderBottom: '1px solid #d6d6d6',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <IonCol style={{ ...centerStyles, ...borderStyles }}>
                        <h1 style={{ fontSize: '2.8rem' }}>{reportData.textOn}%</h1>
                        <h6 style={{ fontSize: '1.2rem', marginTop: -10 }}>Text On</h6>
                      </IonCol>
                      <IonCol style={centerStyles}>
                        <h1 style={{ fontSize: '2.8rem' }}>{reportData.emailOn}%</h1>
                        <h6 style={{ fontSize: '1.2rem', marginTop: -10 }}>Email On</h6>
                      </IonCol>
                    </IonRow>
                    <IonRow
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <IonCol style={{ ...centerStyles, ...borderStyles }}>
                        <h1 style={{ fontSize: '2.8rem' }}>{reportData.bothOn}%</h1>
                        <h6 style={{ fontSize: '1.2rem', marginTop: -10 }}>Both On</h6>
                      </IonCol>
                      <IonCol style={centerStyles}>
                        <h1 style={{ fontSize: '2.8rem' }}>{reportData.bothOff}%</h1>
                        <h6 style={{ fontSize: '1.2rem', marginTop: -10 }}>Both Off</h6>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                )}
              </IonRow>
            </div>
          </IonCard>
        </StandardContainer>
      </IonContent>
    </IonPage>
  )
}
