import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';
import { colors } from '../../theme/colors';
import { IonCol, IonGrid, IonRow, IonSpinner } from '@ionic/react';
import StandardCenterCenter from '../../commonComponents/StandardCenterCenter';
import axios from 'axios';

const AppointmentsChart = ({ selectedCategory, setSelectedCategory, businessData, locationId, refreshValue, apptSummary, appointments }) => {
    const [chartRenderId, setChartRenderId] = useState(1)
    const [projectedValue, setProjectedValue] = useState('--')
    const [projectedData, setProjectedData] = useState({ data: null, loading: false, error: null, locationId: locationId })
    const [currentValue, setCurrentValue] = useState('--')

    let firstLoad = projectedValue ? true : false
    let data = projectedData?.data
    let loading = projectedData?.loading


    const getProjectedData = async () => {
        console.log('getProjectedData')
        setProjectedData({
            loading: true,
            data: projectedData?.locationId === locationId && projectedData?.selectedCategory === selectedCategory ? projectedData.data : null,
            error: null,
            locationId,
            selectedCategory,
        })

        const currentDate = new Date()
        const currentYear = currentDate.getFullYear()
        const currentMonth = currentDate.getMonth() + 1

        try {
            let url = `/dashboard/projectedTrends?businessId=${businessData?._id}&year=${currentYear}&month=${currentMonth}&locationId=${locationId}&category=${selectedCategory}`
            let response = await axios.get(url)
            setProjectedData({
                data: response?.data,
                loading: false,
                error: null,
                locationId,
                selectedCategory,
            })
        } catch (error) {
            setProjectedData({
                data: projectedData.data,
                loading: false,
                error: error,
                locationId,
                selectedCategory,
            })
        }

    }

    useEffect(() => {
        let sumOfApptThisMonth = projectedData?.data?.thisMonth?.trends?.reduce((accumulator, currentValue) => accumulator + currentValue.count, 0)

        if (projectedData?.data && appointments.data && selectedCategory === 'Appointments') {
            let pvalue = projectTotalAppointments(
                (sumOfApptThisMonth || 0) +
                (apptSummary?.Total || 0) -
                (apptSummary?.['No Show'] || 0) -
                (apptSummary?.['Canceled'] || 0) -
                (apptSummary?.['Complete'] || 0)
            )
            setProjectedValue(pvalue)
            setCurrentValue(sumOfApptThisMonth)
        } else if (projectedData?.data && selectedCategory === 'Revenue') {
            let pvalue = projectTotalAppointments(sumOfApptThisMonth || 0)
            setProjectedValue(pvalue)
            setCurrentValue(sumOfApptThisMonth)
        } else {
            setProjectedValue('--')
        }
    }, [appointments?.data, projectedData?.data])


    useEffect(() => {
        console.log('selectedCategory', selectedCategory)
        if(selectedCategory === 'Appointments'){
            getProjectedData()
        }
    }, [businessData, locationId, refreshValue, selectedCategory])

    const [chartData, setChartData] = useState({
        options: {
            chart: {
                type: 'area',
                height: 250,
                toolbar: false
            },
            xaxis: {
                categories: [],
                tickAmount: 7,
                labels: {
                    style: {
                        colors: 'gray'
                    },
                    formatter: function (value) {
                        if (!value) {
                            return '';
                        }
                        const day = value.split('/')[1];
                        return day.startsWith('0') ? day.substring(1) : day;
                    }
                },
                axisBorder: {
                    show: false // Remove the x-axis border line
                },
                axisTicks: {
                    show: false // Remove the x-axis tick marks
                }
            },
            stroke: {
                curve: 'smooth',
                width: 2
            },
            grid: {
                borderColor: '#f1f1f1',
                show: false
            },
            yaxis: {
                title: {
                    text: '',
                },
                min: 0, // Ensure the minimum y value is always 0
                labels: {
                    style: {
                        colors: 'gray'
                    },
                    formatter: getYFormatter
                },
                max: 0 // Initialize with 0, will be calculated based on data
            },
            tooltip: {
                shared: true,
            },
            fill: {
                type: ['solid', 'gradient'],
                opacity: [0, 1], // Opacity for gray and blue lines
                gradient: {
                    shadeIntensity: 1,
                    type: 'vertical',
                    opacityFrom: 0.7,
                    opacityTo: 0.1,
                    stops: [0, 100]
                }
            },
            legend: {
                show: true,
                offsetY: 0
            },
            colors: ['#A9A9A9', colors.primaryBlue], // Gray and primary blue colors
            dataLabels: {
                enabled: false // Disable the number boxes on the lines
            }
        },
        series: []
    });

    function getYFormatter(value) {
        if (selectedCategory === 'Revenue') {
            return formatAsMoney(value);
        } else {
            return value?.toFixed(0);
        }
    }

    useEffect(() => {
        if (!data || !data.thisMonth || !data.lastMonth) {
            return;
        }

        const categories = data.thisMonth.trends.map(item => item.date);
        let lastMonthData = data.lastMonth.trends.map(item => item.cumulativeCount);
        const thisMonthData = data.thisMonth.trends.map(item => item.cumulativeCount);

        // If the previous month has fewer days, fill the remaining days with null
        if (lastMonthData.length < thisMonthData.length) {
            lastMonthData = [...lastMonthData, ...Array(thisMonthData.length - lastMonthData.length).fill(null)];
        }

        const maxLastMonth = Math.max(...lastMonthData.filter(num => num !== null));
        const maxThisMonth = Math.max(...thisMonthData);
        const yAxisMax = Math.ceil(Math.max(maxLastMonth, maxThisMonth) * 1.10);

        setChartData(prevState => ({
            ...prevState,
            options: {
                ...prevState.options,
                xaxis: {
                    ...prevState.options.xaxis,
                    categories: categories
                },
                yaxis: {
                    ...prevState.options.yaxis,
                    max: yAxisMax
                }
            },
            series: [
                { name: 'Previous Month', data: lastMonthData },
                { name: 'Current Month', data: thisMonthData }
            ]
        }));
        setChartRenderId(JSON.stringify(data))
    }, [data]);

    return (

        <IonCol size='12'>
            {(!data) ?
                <StandardCenterCenter><div style={{ height: 200 }}><IonSpinner name="dots" /></div></StandardCenterCenter>
                : <>
                    <IonGrid style={{ margin: 0, padding: 0 }}>
                        <IonRow style={{ margin: 0, padding: 0 }}>
                            <IonCol style={{ margin: 0, padding: 0 }}>
                                <h2 style={{ marginTop: 4, marginBottom: 0 }}>{selectedCategory == "Revenue" ? formatAsMoney(currentValue) : currentValue}</h2>
                                <h6 style={{ marginTop: 0, fontSize: 12, color: 'gray' }}>{selectedCategory == "Revenue" ? formatAsMoney(projectedValue) : projectedValue} Projected</h6>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                    <Chart
                        key={chartRenderId} // Force re-render when chartData changes
                        options={chartData.options}
                        series={chartData.series}
                        type="area"
                        height={250}
                        style={{ marginBottom: -30, marginTop: -10, marginLeft: -10, marginRight: -10 }}
                    />
                </>}
        </IonCol>
    );
};


function formatAsMoney(amount, currency = 'USD', locale = 'en-US') {
    const roundedAmount = Math.round(amount); // Round to the nearest dollar
    return new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: currency,
        minimumFractionDigits: 0, // No decimal places
        maximumFractionDigits: 0  // No decimal places
    }).format(roundedAmount);
}

function projectTotalAppointments(sumOfApptThisMonth = 0) {
    const currentDate = new Date()
    const currentYear = currentDate.getFullYear()
    const currentMonth = currentDate.getMonth()
    const daysPassed = currentDate.getDate()
  
    const countSundays = (year, month, totalDays) => {
      let sundays = 0
      for (let day = 1; day <= totalDays; day++) {
        if (new Date(year, month, day).getDay() === 0) {
          sundays++
        }
      }
      return sundays
    }
  
    const sundaysPassed = countSundays(currentYear, currentMonth, daysPassed)
    const totalSundays = countSundays(currentYear, currentMonth, new Date(currentYear, currentMonth + 1, 0).getDate())
  
    const adjustedDaysPassed = daysPassed - sundaysPassed
    const adjustedTotalDays = new Date(currentYear, currentMonth + 1, 0).getDate() - totalSundays
  
    const averageDailyAppointments = sumOfApptThisMonth / adjustedDaysPassed
    const projectedTotal = averageDailyAppointments * adjustedTotalDays
  
    return Math.round(projectedTotal)
  }

export default AppointmentsChart;
