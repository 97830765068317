import { IonButton, IonCard, IonCol, IonContent, IonDatetime, IonIcon, IonInput, IonLabel, IonModal, IonPage, IonRow, IonSpinner } from '@ionic/react'
import { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../auth'
import axios from 'axios'
import CommonHeader from '../../commonComponents/CommonHeader'
import StandardContainer from '../../commonComponents/StandardContainer'
import Chart from 'react-apexcharts'
import { calendarClearOutline } from 'ionicons/icons'
import commonStyles from '../../theme/commonstyles.module.css'
import { colors } from '../../theme/colors'
import { getMonthRange } from '../../Utils/GetDates'
import GetMonthsBasedOnStartAndEndIndex from '../../Utils/GetMonthsBasedOnStartAndEndIndex'
import { GetSeriesDataForLineChart } from '../../Utils/GetSeriesDataForLineChart'
export default function ServiceTimeGraph({ id, type, title }) {
  const employee_id = id
  const { businessData } = useContext(AuthContext)
  const [selectedStartDate, setSelectedStartDate] = useState('')
  const [selectedEndDate, setSelectedEndDate] = useState('')
  const [calendarModalOpen, setCalendarModalOpen] = useState(false)
  const [selectedDateType, setSelectedDateType] = useState('startDate')
  const [isResetDisabled, setResetDisabled] = useState(true)
  const [applyDisabled, setApplyDisabled] = useState(true)
  const [overAllAverage, setOverallAverage] = useState(false)
  const [applyingFilter, setApplyingFilter] = useState(false)
  const [resettingFilter, setResettingFilter] = useState(false)
  const initialNumOfMonths = 6
  const [options, setOptions] = useState({
    chart: {
      toolbar: {
        show: false,
      },
    },
    grid: {
      borderColor: '#fff',
      xaxis: {
        lines: {
          show: false, // This will hide the horizontal grid lines
        },
      },
    },
    yaxis: {
      title: {
        text: type == 'serviceTimeStats' ? 'Service Time in Hrs' : 'Duration Time In Hrs',
      },
      labels: {
        formatter: function (value) {
          return `${Math.abs(value)}` // Add formatting to display values with $ sign
        },
      },
    },
    stroke: {
      width: 2, // Set your desired stroke width here
      curve: 'smooth',
    },
    xaxis: {
      categories: [],
    },
  })
  const [series, setSeries] = useState([])
  const getServiceTimeStats = async (startDate, endDate) => {
    try {
      const serviceStatsResponse = await axios.get(
        `/employee/${type}?businessId=${businessData?._id}&barberId=${employee_id}&startDate=${startDate}&endDate=${endDate}`
      )

      if (serviceStatsResponse.status === 200) {
        const data = serviceStatsResponse.data
        // Extract x-axis categories based on unique months
        const categoriesResponse = GetMonthsBasedOnStartAndEndIndex(startDate, endDate)
        const dataField = type == 'serviceTimeStats' ? 'totalServiceTime' : 'totalExpectedTimeDifference'
        const averageValue = type == 'serviceTimeStats' ? 'overallAverageServiceTime' : 'overallAverageExpectedTimeDifference'
        let series = await GetSeriesDataForLineChart(categoriesResponse, data.monthlyData, dataField)

        // Update state with processed series and categories
        setOverallAverage(data[averageValue])
        setApplyingFilter(false)
        setResettingFilter(false)
        setSeries(series)
        setOptions((prevOptions) => ({
          ...prevOptions,
          xaxis: {
            ...prevOptions.xaxis,
            categories: categoriesResponse.categories,
          },
        }))
        // setData(response.data)
      }
    } catch (error) {}
  }
  const openCalendarModal = (type, value) => {
    setSelectedDateType(type)
    setCalendarModalOpen(true)
  }
  const closeCalendarModal = (type, value) => {
    setCalendarModalOpen(false)
    // setSelectedDateType('startDate')
  }
  const resetFilter = () => {
    const data = getMonthRange(initialNumOfMonths)
    setResettingFilter(true)
    setResetDisabled(true)
    setApplyDisabled(true)
    setSelectedStartDate(data.startDate)
    setSelectedEndDate(data.endDate)
    getServiceTimeStats(data.startDate, data.endDate)
  }
  const applyFilter = () => {
    setApplyDisabled(true)
    setApplyingFilter(true)
    getServiceTimeStats(selectedStartDate, selectedEndDate)
  }
  const handleDate = (value) => {
    if (selectedDateType === 'startDate') {
      setSelectedStartDate(value)
    } else if (selectedDateType === 'endDate') {
      setSelectedEndDate(value)
    }
    setApplyDisabled(false)
    setResetDisabled(false)
    closeCalendarModal()
  }
  const formatDate = (date) => {
    // Create a Date object from the input string
    const dateObject = new Date(date)

    // Format the date as YYYY-MM-DD
    const formattedDate = dateObject.toISOString().slice(0, 10)
    return formattedDate
  }
  useEffect(() => {
    const data = getMonthRange(initialNumOfMonths)

    setSelectedStartDate(data.startDate)
    setSelectedEndDate(data.endDate)
    getServiceTimeStats(data.startDate, data.endDate)
  }, [employee_id])

  useEffect(() => {
    const data = getMonthRange(initialNumOfMonths)

    setSelectedStartDate(data.startDate)
    setSelectedEndDate(data.endDate)
    getServiceTimeStats(data.startDate, data.endDate)
  }, [])

  return (
    <IonCard className='flat' style={{ padding: '1rem' }}>
      <IonRow className={`${commonStyles.displayFlex} ${commonStyles.justifySpaceBetween}`}>
        <IonCol>
          <IonLabel>Start Date</IonLabel>
          <IonRow>
            <IonInput
              fill='outline'
              placeholder='Start Date'
              value={selectedStartDate ? formatDate(selectedStartDate) : ''}
              onClick={() => openCalendarModal('startDate')}
            >
              <IonIcon style={{ marginRight: '10px' }} slot='start' onClick={(e) => openCalendarModal('startDate')} icon={calendarClearOutline}></IonIcon>
            </IonInput>
          </IonRow>
        </IonCol>
        <IonCol>
          <IonLabel>End Date</IonLabel>
          <IonRow>
            <IonInput
              fill='outline'
              placeholder='End Date'
              value={selectedEndDate ? formatDate(selectedEndDate) : ''}
              onClick={() => openCalendarModal('endDate')}
            >
              <IonIcon style={{ marginRight: '10px' }} slot='start' onClick={(e) => openCalendarModal('endDate')} icon={calendarClearOutline}></IonIcon>
            </IonInput>
          </IonRow>
        </IonCol>
      </IonRow>

      <IonRow
        style={{
          borderTop: `1px solid ${colors.lightGrey}`,
          padding: '1.2rem 0',
          marginTop: '0.5rem',
        }}
        className={`${commonStyles.displayFlex} ${commonStyles.justifyEnd}`}
      >
        <IonCol size='6' style={{ display: 'flex', justifyContent: 'start', margin: 0, padding: 0 }}>
          {overAllAverage != 0 && (
            <IonCol
              size='4'
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                margin: 0,
                padding: 0,
              }}
            >
              <IonRow>
                <IonLabel style={{ fontWeight: 'bold', fontSize: '1.3rem', color: '#363535' }}>{overAllAverage.toFixed(2)}</IonLabel>
              </IonRow>
              <IonRow>
                <IonLabel style={{ color: '#b6b6b6' }}>Average</IonLabel>
              </IonRow>
            </IonCol>
          )}
        </IonCol>
        <IonCol size='6' className={`${commonStyles.displayFlex}  ${commonStyles.justifyEnd}`}>
          <IonButton fill='clear' disabled={isResetDisabled} onClick={resetFilter}>
            {resettingFilter && <IonSpinner style={{ marginRight: '10px' }} name='lines' />}
            Reset
          </IonButton>
          <IonButton fill='solid' disabled={applyDisabled} onClick={applyFilter}>
            {applyingFilter && <IonSpinner style={{ marginRight: '10px' }} name='lines' />}
            Apply
          </IonButton>
        </IonCol>
      </IonRow>
      <IonModal onDidDismiss={closeCalendarModal} isOpen={calendarModalOpen}>
        <CommonHeader
          title={selectedDateType == 'startDate' ? 'Choose Start Date' : 'Choose End Date'}
          closeButton={true}
          closeModal={closeCalendarModal}
        ></CommonHeader>
        <IonContent className='regularBackground'>
          <StandardContainer>
            <div className={commonStyles.flexCenter}>
              <IonDatetime
                presentation='date'
                displayFormat='MMM D, YYYY'
                value={selectedDateType == 'startDate' ? selectedStartDate : selectedEndDate}
                onIonChange={(e) => handleDate(e.detail.value)}
              />
            </div>
          </StandardContainer>
        </IonContent>
      </IonModal>

      <Chart options={options} series={series} type='line' height={200} />
    </IonCard>
  )
}
