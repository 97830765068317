import { useContext, useEffect } from 'react'
import { Redirect, Route } from 'react-router-dom'
import { IonIcon, IonLabel, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs } from '@ionic/react'
import { home, businessOutline, settingsOutline } from 'ionicons/icons'
import { useHistory } from 'react-router-dom'

import { AuthContext } from '../auth'
import Dashboard from './Dashboard'
import Settings from './Settings'
import BusinessDetails from './Details'
import BusinessDomains from './Domains'
import Locations from './Locations'
// import ImpressionsByRefId from './reports/ImpressionsByRefId'

const styles = {
  icon: {
    height: 22,
    width: 22,
  },
  tabButton: {
    backgroundColor: 'transparent',
  },
}

const BusinessTabs = () => {
  const { userData, firebaseUser, businessData, getUserData, businessDataError } = useContext(AuthContext)
  const history = useHistory()
  useEffect(() => {
    getUserData()
  }, [])

  if (!firebaseUser) {
    return <Redirect to='/login' />
  }

  if (firebaseUser && userData && !businessData && !businessDataError) {
    return <Redirect noThrow to='/setup' />
  }

  if (businessDataError) {
    return <Redirect noThrow to='/' />
  }

  return (
    <>
      <IonTabs>
        <IonRouterOutlet>
          {/* <Route path='/core/packagesList' render={props => <PackagesListView {...props} />} /> */}
          {/* <Route exact path="/home" render={(props) => (<Home {...props} openLoginModal={openLoginModal} openRegisterModal={openRegisterModal} openScheduleModal={openScheduleModal} appointments={appointments} />)} /> */}
          <Route path='/business/details/:id' render={(props) => <BusinessDetails {...props} />} />
          <Route path='/business/domains/:id' render={(props) => <BusinessDomains {...props} />} />
          <Route exact path='/business/dashboard' render={(props) => <Dashboard {...props} />} />
          <Route exact path='/business/locations' render={(props) => <Locations {...props} />} />
          <Route exact path='/business/settings' render={(props) => <Settings {...props} />} />
        </IonRouterOutlet>

        <IonTabBar slot='bottom' style={{ backgroundColor: 'white' }}>
          <IonTabButton tab='dashboard' href='/business/dashboard' style={styles.tabButton} routerDirection='none'>
            <IonIcon icon={home} style={styles.icon} />
            <IonLabel>Home</IonLabel>
          </IonTabButton>
          <IonTabButton tab='customer' href='/business/locations' style={styles.tabButton} routerDirection='none'>
            <IonIcon icon={businessOutline} style={styles.icon} />
            <IonLabel>Locations</IonLabel>
          </IonTabButton>
          <IonTabButton tab='employees' href='/business/settings' style={styles.tabButton} routerDirection='none'>
            <IonIcon icon={settingsOutline} style={styles.icon} />
            <IonLabel>Settings</IonLabel>
          </IonTabButton>
        </IonTabBar>
      </IonTabs>
      {/* <LoginModal authModalData={authModalData} setAuthModalData={setAuthModalData} /> */}
      {/* <ScheduleModal scheduleModalData={scheduleModalData} setScheduleModalData={setScheduleModalData} getAppointments={getAppointments} /> */}
    </>
  )
}

export default BusinessTabs
