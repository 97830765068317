import { IonItem, IonLabel, IonList } from '@ionic/react';


export default function ResultsList({locations=[]}) {

  return (
    <>
    <h6 style={{color:'grey', marginLeft:20}}>Locations</h6>
    <IonList inset={true}>
      {locations?.map((row) => (
        <IonItem button detail={true} routerLink={`/core/locations/${row._id}`} routerDirection="forward" key={row._id}>
          <IonLabel>
            <h4 style={{fontWeight:'600', color:'black', fontSize:18}}>{`${row.name}`}</h4>
            <p>{(row.addressStreet || row.addressState) ? `${(row.addressStreet1 || "")}, ${(row.addressCity || "")}, ${(row.addressState || "")} ${(row.addressZipcode || "")}` : ''}</p>
            </IonLabel>
        </IonItem>
      ))}
    </IonList>
    </>
  )
}
