import { IonLabel, IonSpinner } from '@ionic/react'
import { useState } from 'react'
import CreateSubscriptionModal from '../../../subscriptions/createSubscriptionModal'
import { useHistory } from 'react-router'

export default function SubscriptionData({ loadingSubscription, subscription, appointment, getCustomerSubscription, closeModal }) {
  const [createSubscriptionModalOpen, setCreateSubscriptionModalOpen] = useState(false)
  const history = useHistory()

  const navigateToCustomerSubscriptionPage = () => {
    closeModal()
    history.push(`/core/subscriptions/${subscription?._id}`)
  }
  return (
    <>
      {loadingSubscription ? (
        <IonSpinner name='dots' />
      ) : (
        <>
          {subscription?.isSubscribed ? (
            <>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <IonLabel
                  style={{
                    padding: '5px 10px',
                    color: 'white',
                    borderRadius: 20,
                    background: 'linear-gradient(90deg, #4E5BA6 0%, #222222 100%)',
                  }}
                >
                  {subscription?.name}
                </IonLabel>
                <div
                  onClick={() => navigateToCustomerSubscriptionPage()}
                  style={{
                    cursor: 'pointer',
                    marginLeft: 10,
                    color: '#0068DE',
                    fontSize: 14,
                    fontFamily: 'Figtree',
                    fontWeight: '600',
                    textDecoration: 'underline',
                    lineHeight: '20px',
                    wordWrap: 'break-word',
                  }}
                >
                  Update
                </div>
              </div>
            </>
          ) : (
            <>
              <div style={{ justifyContent: 'flex-start', alignItems: 'flex-start', gap: 8, display: 'inline-flex' }}>
                <div style={{ justifyContent: 'flex-start', alignItems: 'flex-start', display: 'flex' }}>
                  <div
                    style={{
                      paddingLeft: 8,
                      paddingRight: 8,
                      paddingTop: 2,
                      paddingBottom: 2,
                      background: '#E9E9E9',
                      borderRadius: 9999,
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      display: 'flex',
                    }}
                  >
                    <div
                      style={{
                        textAlign: 'center',
                        color: '#222222',
                        fontSize: 12,
                        fontFamily: 'Figtree',
                        fontWeight: '600',
                        lineHeight: '18px',
                        wordWrap: 'break-word',
                      }}
                    >
                      Unsubscribed
                    </div>
                  </div>
                </div>
                <div
                  onClick={() => {
                    setCreateSubscriptionModalOpen(true)
                  }}
                  style={{
                    color: '#0068DE',
                    fontSize: 14,
                    fontFamily: 'Figtree',
                    fontWeight: '600',
                    textDecoration: 'underline',
                    lineHeight: '20px',
                    wordWrap: 'break-word',
                  }}
                >
                  Add Subscription
                </div>
              </div>
            </>
          )}
        </>
      )}
      <CreateSubscriptionModal
        isOpen={createSubscriptionModalOpen}
        setIsOpen={setCreateSubscriptionModalOpen}
        customer={appointment?.customer}
        onSuccess={getCustomerSubscription}
      />
    </>
  )
}
