import { IonSelect, IonSelectOption, IonGrid, IonRow, IonCol, IonText } from '@ionic/react'

export default function ScheduledDaysInAdvance({ daysYouCanScheduleInAdvance, updateDaysInAdvance }) {
  return (
    <>
      <IonText>
        <h6>Days to Book in Advance</h6>
      </IonText>
      <IonText color='medium'>
        <p style={{ marginBottom: 20, fontSize: 12 }}>The number of days a client can book in advance.</p>
      </IonText>
      <IonGrid>
        <IonRow>
          <IonCol size='4'>
            <IonSelect value={daysYouCanScheduleInAdvance} placeholder='Select Days' onIonChange={(e) => updateDaysInAdvance(e.detail.value)}>
              <IonSelectOption value={3}>3 days</IonSelectOption>
              <IonSelectOption value={7}>7 days</IonSelectOption>
              <IonSelectOption value={14}>14 days</IonSelectOption>
              <IonSelectOption value={21}>21 days</IonSelectOption>
              <IonSelectOption value={30}>30 days</IonSelectOption>
              <IonSelectOption value={40}>40 days</IonSelectOption>
              <IonSelectOption value={50}>50 days</IonSelectOption>
              <IonSelectOption value={60}>60 days</IonSelectOption>
              <IonSelectOption value={90}>90 days</IonSelectOption>
              <IonSelectOption value={120}>120 days</IonSelectOption>
              <IonSelectOption value={365}>365 days</IonSelectOption>
            </IonSelect>
          </IonCol>
        </IonRow>
      </IonGrid>
    </>
  )
}
