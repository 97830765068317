import React, { useState, useEffect, useContext } from 'react'
import ResultsList from './resultsList'
import axios from 'axios'
import { AuthContext } from '../auth'
import { IonCard, IonCol, IonGrid, IonRefresher, IonRefresherContent, IonRow } from '@ionic/react'
import { useLocation } from 'react-router-dom'
import MiniHeader from '../commonComponents/MiniHeader'

const styles = {
  column: {
    padding: 0,
    margin: -4,
  },
  card: {
    padding: 16,
    height: 110,
    margin: 10,
  },
}

export default function SubscriptionsList() {
  const { businessData, locationId } = useContext(AuthContext)
  const [subscriptions, setSubscriptions] = useState([])
  const [newInLastWeek, setNewInLastWeek] = useState(null)
  const [soldByEmployees, setSoldByEmployees] = useState(0)
  const [errorMessage, setErrorMessage] = React.useState('')
  const [loading, setLoading] = React.useState(false)
  const windowLocation = useLocation()

  useEffect(() => {
    if (windowLocation.pathname === '/core/subscriptions') {
      getSubscriptionsList()
    }
  }, [businessData, locationId, windowLocation])

  const getSubscriptionsList = async () => {
    if (!businessData?._id) return
    setLoading(true)
    setErrorMessage(null)
    try {
      let locQuery = locationId ? `&locationId=${locationId}` : ''

      let response = await axios.get(
        `/subscription/getList?businessId=${businessData._id}${locQuery}`,
      )
      setSubscriptions(response.data)
    } catch (error) {
      setErrorMessage('There was an error fetching subscriptions.')
    }
    setLoading(false)
  }

  useEffect(() => {
    const oneWeekAgo = new Date()
    oneWeekAgo.setDate(oneWeekAgo.getDate() - 7)

    const newSubscriptions = subscriptions.filter(
      subscription => new Date(subscription.createdDate) >= oneWeekAgo,
    )

    setNewInLastWeek(newSubscriptions.length)
  }, [subscriptions])

  useEffect(() => {
    const oneWeekAgo = new Date()
    oneWeekAgo.setDate(oneWeekAgo.getDate() - 7)

    const newSubscriptions = subscriptions.filter(subscription => subscription.soldEmployeeId)

    setSoldByEmployees(newSubscriptions.length)
  }, [subscriptions])

  async function handleRefresh(event) {
    await getSubscriptionsList()
    event.detail.complete()
  }

  return (
    <>
      <IonRefresher slot='fixed' onIonRefresh={handleRefresh}>
        <IonRefresherContent></IonRefresherContent>
      </IonRefresher>
      {errorMessage ? (
        <p style={{ color: 'red', marginLeft: 20, marginRight: 20 }}>{errorMessage}</p>
      ) : null}
      <IonGrid style={{ padding: 10, margin: 0 }}>
        <IonRow style={{ padding: 0, margin: 0 }}>
          <IonCol style={styles.column}>
            <IonCard style={styles.card}>
              <MiniHeader title={'Total Subscribers'} />
              <h1 style={{ marginTop: 10, marginBottom: 0 }}>{subscriptions?.length || 0}</h1>
            </IonCard>
          </IonCol>
          <IonCol style={styles.column}>
            <IonCard style={styles.card}>
              <MiniHeader title={'New last 7d'} />
              <h1 style={{ marginTop: 10, marginBottom: 0 }}>{newInLastWeek || 0}</h1>
            </IonCard>
          </IonCol>
          <IonCol style={styles.column}>
            <IonCard style={styles.card}>
              <MiniHeader title={'Sold By Employees'} />
              <h1 style={{ marginTop: 10, marginBottom: 0 }}>
                {Math.round((soldByEmployees / subscriptions?.length) * 100)}%&nbsp;
                <span style={{ marginTop: 0, marginBottom: 0, fontSize: 16 }}>
                  ({soldByEmployees})
                </span>
              </h1>
            </IonCard>
          </IonCol>
        </IonRow>
      </IonGrid>

      <ResultsList subscriptions={subscriptions} locationId={locationId} />
    </>
  )
}
