// Import necessary modules and utilities
import { useEffect, useState } from 'react'
import { IonCard, IonCol, IonGrid, IonLabel, IonRow, IonSpinner } from '@ionic/react'
import Chart from 'react-apexcharts'
import axios from 'axios'
import { getMonthRange } from '../Utils/GetDates'
import Picker from '../commonComponents/Picker'
import GetPickerMonths from '../Utils/GetPickerMonths'
import { GetXAxisMonths } from '../Utils/GetXAxisMonths'
import { getAllDaysInCurrentMonth, getDaysInCurrentMonth } from '../utils'

export default function SubscriberConversion({ refId }) {
  // Initialize state variables and constants
  const pickerItems = GetPickerMonths()
  let initialNumOfMonths = 6
  const [selectedOption, setSelectedOption] = useState(initialNumOfMonths - 1)
  const [dataLoading, setDataLoading] = useState(true)
  const [data, setData] = useState(false)

  const [options, setOptions] = useState({
    chart: {
      type: 'bar',
      height: 250,
      stacked: true,
      toolbar: false,
    },
    xaxis: {
      categories: [],
      tickAmount: 5,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '70%',
        endingShape: 'rounded',
        dataLabels: {
          position: 'top', // top, center, bottom
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val // Format the label if needed
      },
      offsetY: -20,
      style: {
        fontSize: '12px',
        colors: ['#304758'],
      },
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    grid: {
      borderColor: '#fff',
    },
    yaxis: {
      title: {
        text: 'Appointments',
      },
    },
    // ... other options
  })
  const [series, setSeries] = useState([])

  // Function to fetch data based on reference ID, start date, end date, and number of months
  const getDataBasedOnRefId = async (startDate, endDate, noOfMonths) => {
    try {
      // Make API call to get report data
      const response = await axios.get(`/customerAcquisition/reports/subscriberconversion?fromDate=${startDate}&toDate=${endDate}&referenceId=${refId}`)

      // Set loading state to false and process response data if status is 200
      setDataLoading(false)
      if (response.status === 200) {
        const data = response.data

        const categoriesResponse = GetXAxisMonths(noOfMonths)

        const months = categoriesResponse.monthNumbers

        const daysInCurrentMonth = getDaysInCurrentMonth()
        // Create series data from the provided API data
        const series = [{ data: [] }]
        categoriesResponse.monthNumbers.forEach(() => {
          series[0].data = noOfMonths === 0 || noOfMonths === 1 ? Array(daysInCurrentMonth).fill(0) : Array(months.length).fill(0)
        })
        // Update series data with fetched API data
        if (data) {
          data.data.forEach((item) => {
            if (noOfMonths === 0 || noOfMonths === 1) {
              const dayIndex = item.day - 1
              series[0].data[dayIndex] = item.totalSubscribers
            } else {
              const paddedMonth = String(item.month).padStart(2, '0') // Padding the month number
              const lastTwoDigitsOfYear = item.year % 100
              const monthYear = paddedMonth + '-' + lastTwoDigitsOfYear
              const index = months.findIndex((month) => month === monthYear)

              if (index !== -1) {
                series[0].data[index] = item.totalSubscribers
              }
            }
          })
        }

        setSeries(series)
        setOptions((prevOptions) => ({
          ...prevOptions,
          yaxis: {
            title: {
              text: 'Total Subscribers', // Your desired Y-axis label
            },
            tickAmount: 4, // This specifies the number of ticks on the y-axis
            forceNiceScale: true, // This ensures the y-axis scale adheres to tickAmount
          },
          xaxis: {
            ...prevOptions.xaxis,
            categories: noOfMonths === 0 || noOfMonths === 1 ? getAllDaysInCurrentMonth(noOfMonths) : categoriesResponse.categories,
          },
        }))
        setData(response.data)
      }
    } catch (error) {
      console.error('Failed to load data.', error) // Log the error for debugging

      // Handle errors if any
    }
  }

  // Function to handle the selected index change
  const handleSelectedIndex = (indexValue) => {
    const data = getMonthRange(indexValue)

    const selectedValue = pickerItems.find((item, index) => index === indexValue)
    if (selectedOption !== selectedValue.text) {
      setDataLoading(true)
      setSelectedOption(selectedValue.text)
      getDataBasedOnRefId(data.startDate, data.endDate, indexValue)
    }
  }

  // Initial data fetching on component mount
  useEffect(() => {
    const data = getMonthRange(initialNumOfMonths)
    getDataBasedOnRefId(data.startDate, data.endDate, initialNumOfMonths)
  }, [])

  return (
    <IonCard className='flat' style={{ padding: '1rem' }}>
      <h6 style={{ margin: 0, color: 'black', fontWeight: 'bold' }}>Subscriber Conversion</h6>

      <div style={{ margin: '1rem 0', marginTop: 8 }}>
        <Picker options={pickerItems} selectedItemIndex={initialNumOfMonths} selectedItem={selectedOption} handleSelectedIndex={handleSelectedIndex} />
      </div>
      {dataLoading && <IonSpinner name='dots' />}
      {!dataLoading && (
        <>
          <Chart options={options} series={series} type='bar' height={200} />
          {data && (
            <IonGrid>
              <IonRow style={{ margin: '2rem 0', marginBottom: 0 }}>
                <IonCol
                  size='4'
                  style={{
                    borderRight: '2px solid #d4d4d4',
                    flexDirection: 'column',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <IonLabel
                    style={{
                      color: 'black',
                      fontWeight: 'bold',
                      fontSize: '1.6rem',
                      textAlign: 'center',
                    }}
                  >
                    {data.totalSubscribersCount}
                  </IonLabel>
                  <IonLabel style={{ fontSize: '0.8rem', fontWeight: 'bold', textAlign: 'center' }}>Total Conversions</IonLabel>
                </IonCol>
                <IonCol
                  size='4'
                  style={{
                    borderRight: '2px solid #d4d4d4',
                    flexDirection: 'column',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <IonLabel
                    style={{
                      color: 'black',
                      fontWeight: 'bold',
                      fontSize: '1.6rem',
                      textAlign: 'center',
                    }}
                  >
                    {Number.isInteger(data.subscribedPercentage)
                      ? data.subscribedPercentage + ' %' // If it's an integer, render the value directly
                      : data.subscribedPercentage.toFixed(2) + ' %'}{' '}
                    {/* If it has decimal places, apply toFixed(2) */}{' '}
                  </IonLabel>
                  <IonLabel style={{ fontSize: '0.8rem', fontWeight: 'bold', textAlign: 'center' }}>% that subscribes</IonLabel>
                </IonCol>
                <IonCol
                  size='4'
                  style={{
                    flexDirection: 'column',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <IonLabel
                    style={{
                      color: 'black',
                      fontWeight: 'bold',
                      fontSize: '1.6rem',
                      textAlign: 'center',
                    }}
                  >
                    {data.soldByProfessionalPercentage} %
                  </IonLabel>
                  <IonLabel style={{ fontSize: '0.8rem', fontWeight: 'bold', textAlign: 'center' }}>% sold by professional</IonLabel>
                </IonCol>
              </IonRow>
            </IonGrid>
          )}
        </>
      )}
    </IonCard>
  )
}
