import { useState, useEffect, useContext } from 'react'
import Axios from 'axios'
import { IonAvatar, IonButton, IonGrid, IonRow, IonCol, IonInput, IonText, IonSpinner, IonIcon, IonToast, IonPage, IonContent, IonCard } from '@ionic/react'
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth'
import { AuthContext } from './auth'
import checkEmailExists from './Utils/CheckEmailValidation'
import { storefrontOutline } from 'ionicons/icons'
import { useHistory } from 'react-router-dom'
import Copyright from './commonComponents/copyright'
import StandardCenterCenter from './commonComponents/StandardCenterCenter'
import StandardContainer from './commonComponents/StandardContainer'

const styles = {
  avatar: {
    margin: '10px auto',
    backgroundColor: '#1e90ff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  form: {
    width: '100%',
    marginTop: 10,
  },
  submit: {
    marginTop: 20,
    height: 50,
  },
}

const RegisterNewUserToBusiness = () => {
  const hist = useHistory()
  const authContextData = useContext(AuthContext)
  const userData = authContextData ? authContextData.userData : undefined
  const [disabled, setDisabled] = useState(true)
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)
  const [loadingPage, setLoadingPage] = useState(true)
  const [loadingMessage, setLoadingMessage] = useState('')
  const [toast, setToast] = useState({ isOpen: false, message: '', color: '' }) // For Toast notification
  const searchParams = new URLSearchParams(window.location.search)
  const businessId = searchParams.get('businessId')
  const code = searchParams.get('code')
  const email = searchParams.get('email')
  // Function to show toast messages
  const showToastMessage = (message, color) => {
    setToast({ isOpen: true, message, color })
  }
  useEffect(() => {
    const checkUserAndInvitation = async () => {
      setLoadingPage(true)

      if (userData && userData.email === email) {
        try {
          setLoadingMessage('Checking invitation status...')
          console.log('Checking invitation status...')
          const verifyResponse = await Axios.get(`/user/invite/check?code=${code}`)
          console.log('Done Checking invitation status...', verifyResponse.data.invitationExists)
          if (verifyResponse.data.invitationExists) {
            hist.push(`/pendingInvitations?email=${email}`)
          } else {
            hist.push('/core/dashboard')
          }
        } catch (error) {
          console.error('Error checking invitation status:', error)
          setLoadingMessage('Error checking invitation status')
        }
      } else {

        try {
          setLoadingMessage('Checking account status...')
          console.log('Checking account status...')
          const accountCheckResponse = await checkEmailExists('user', email)
          console.log('Done Checking account status...')
          if (accountCheckResponse.emailExists) {
            localStorage.setItem('redirectTo', 'pendingInvitations')
            hist.push('/login')
            console.log('Redirecting to login...')
          } else {
            setLoadingPage(false)
            localStorage.setItem('redirectTo', 'pendingInvitations')
            console.log('Redirecting to pending invitations...')
          }
        } catch (error) {
          console.error('Error checking account status:', error)
          showToastMessage('Error checking account status', 'danger')
        }
      }
    }
    checkUserAndInvitation()
  }, [userData, email, businessId, code, hist])

  const handlePassword = (event) => {
    setPassword(event.target.value)
    setDisabled(!event.target.value)
  }

  const handleSignUp = async (event) => {
    setDisabled(true)
    event.preventDefault()
    setLoading(true)

    try {
      setLoadingMessage('Verifying code...')
      const verifyResponse = await Axios.post('/user/verifyCode', {businessId, code, email,})
      if (verifyResponse.data.success) {
        setLoadingMessage('Creating account...')
        console.log('Creating account...')
        const response = await createUserWithEmailAndPassword(getAuth(), email, password)
        
        try {
          setLoadingMessage('Registering user...')
          const registerResponse = await Axios.post('/user/registerAndClaimCode', {email, firebaseId: response.user.uid, code, businessId})
          setLoadingMessage('Done Registering user...')
          if (registerResponse.status) {
            const redirectTo = localStorage.getItem('redirectTo')
            if (redirectTo === 'pendingInvitations') {
              hist.push('/core/dashboard')
              // hist.push(`/pendingInvitations?email=${email}`)
              localStorage.removeItem('redirectTo')
            } else {
              hist.push('/core/dashboard')
            }
            setToast({ isOpen: true, message: 'Account created successfully!', color: 'success' })
          } else {
            hist.push('/login')
          }
        } catch (error) {
          console.error('Error registering user:', error)
          hist.push('/login')
          setToast({ isOpen: true, message: 'Error registering user', color: 'danger' })
        }
      } else {
        throw new Error('Not Verified')
      }
    } catch (error) {
      console.error('Error during sign-up:', error)
      setToast({ isOpen: true, message: error.message || 'Error during sign-up', color: 'danger' })
      setDisabled(false)
    } finally {
      setLoading(false)
    }
  }

  if (!code || !businessId || !email) {
    hist.push('/core/dashboard')
  }

  return (
    <IonPage>
      <IonContent>
        <StandardContainer>
          <IonGrid>
            <IonRow>
              <IonCol size='12' className='ion-text-center'>
                {(loadingPage || loading) && (
                  <IonCard style={{ padding: '20px', textAlign: 'center' }}>
                    <IonSpinner name='dots' />
                    {loadingMessage && (
                      <IonText>
                        <p style={{ marginTop: '10px', fontSize: '16px' }}>{loadingMessage}</p>
                      </IonText>
                    )}
                  </IonCard>
                )}
                {!loadingPage && (
                  <>
                    
                    <IonText>
                      <h1>Create Account</h1>
                      <p>Welcome. Please enter your password to accept the invite and create your account.</p>
                    </IonText>
                    <form onSubmit={handleSignUp} style={styles.form}>
                      <IonRow>
                        <IonCol size='12'>
                          <IonInput value={email} disabled placeholder='Email' type='email' required />
                        </IonCol>
                        <IonCol size='12'>
                          <IonInput mode='md' style={{border:'1px solid #e6e6e6', borderRadius:5}} value={password} onIonInput={handlePassword} placeholder='Password' type='password' required />
                        </IonCol>
                      </IonRow>
                      <IonButton type='submit' expand='block' disabled={disabled} style={styles.submit}>
                        Continue
                      </IonButton>
                      <IonRow className='ion-justify-content-center' style={{ marginTop: 20 }}>
                        {loading && <IonSpinner name='dots' />}
                      </IonRow>
                    </form>
                  </>
                )}
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <Copyright />
              </IonCol>
            </IonRow>

            {/* Toast for feedback */}
            <IonToast
              position='top'
              isOpen={toast.isOpen}
              message={toast.message}
              duration={2000}
              color={toast.color}
              onDidDismiss={() => setToast({ isOpen: false, message: '', color: '' })}
            />
          </IonGrid>
        </StandardContainer>
      </IonContent>
    </IonPage>
  )
}

export default RegisterNewUserToBusiness
