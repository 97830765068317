import { useState } from 'react'
import EditPaneModal from './EditPaneModal'
import {
  IonAvatar,
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
} from '@ionic/react'
import { mailOutline, phonePortraitOutline } from 'ionicons/icons'
export default function DetailsView({
  customer,
  updateCustomer,
  checkCustomer,
  locations,
  locationId,
  emailValidationMessage,
  save,
  updateErrorMessage,
}) {
  let [profilePicDialogOpen, setProfilePicDialogOpen] = useState(false)
  let [editPaneOpen, setEditPaneOpen] = useState(false)

  let onValueChange = (field, value) => {
    updateCustomer(field, { ...customer, [field]: value })
  }

  function openProfilePicModal() {
    setProfilePicDialogOpen(true)
  }

  function closeProfilePicModal() {
    setProfilePicDialogOpen(false)
  }
  const closeEditPane = () => {
    setEditPaneOpen(false)
  }

  if (!customer) return null

  return (
    <>
      <IonGrid style={{ marginBottom: -20, marginLeft: -4, marginRight: -4 }}>
        <IonCol size='auto' style={{ justifyContent: 'end', alignItems: 'end', display: 'flex' }}>
          <IonButton
            fill='clear'
            onClick={() => {
              setEditPaneOpen(!editPaneOpen)
            }}
          >
            {'Edit'}
          </IonButton>
        </IonCol>

        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div style={{ width: '100%' }}>
            <div
              style={{
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <IonAvatar style={{ margin: 20, height: 100, width: 100, marginBottom: 0 }}>
                <img
                  alt="Silhouette of a person's head"
                  src='https://ionicframework.com/docs/img/demos/avatar.svg'
                />
              </IonAvatar>

              <h1
                style={{
                  fontSize: 30,
                  fontFamily: 'Montserrat',
                  fontWeight: '700',
                  color: 'black',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {customer?.firstName}&nbsp;{customer?.lastName}
              </h1>
              <IonLabel style={{ color: 'red' }}>{updateErrorMessage}</IonLabel>
            </div>

            <IonList inset={true} style={{ background: 'white' }} mode='ios'>
              <IonItem>
                <IonIcon icon={mailOutline} slot='start'></IonIcon>
                <IonLabel>{customer?.email}</IonLabel>
              </IonItem>
              <IonItem>
                <IonIcon icon={phonePortraitOutline} slot='start'></IonIcon>
                <IonLabel>{customer?.phoneNumber}</IonLabel>
              </IonItem>
            </IonList>
          </div>
        </div>
      </IonGrid>
      <EditPaneModal
        save={save}
        editPaneOpen={editPaneOpen}
        customer={customer}
        onValueChange={onValueChange}
        closeEditPane={closeEditPane}
        emailValidationMessage={emailValidationMessage}
      />
    </>
  )
}
