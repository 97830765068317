// customerNotesStyles.js

const styles = {
  containerStyle: {
    width: '100%',
    paddingTop: 16,
    paddingBottom: 16,
    borderBottom: '1px #EBEBEB solid',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: 12,
    display: 'flex',
  },

  headerStyle: {
    alignSelf: 'stretch',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 12,
    display: 'inline-flex',
  },

  innerContainerStyle: {
    flex: '1 1 0',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: 12,
    display: 'flex',
  },

  iconContainerStyle: {
    paddingTop: 2,
    paddingBottom: 2,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: 10,
    display: 'inline-flex',
  },

  iconStyle: {
    width: 24,
    height: 24,
    position: 'relative',
  },

  iconImageStyle: {
    width: 24,
    height: 24,
  },

  notesTitleStyle: {
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    display: 'inline-flex',
  },

  notesHeaderStyle: {
    alignSelf: 'stretch',
    color: '#222222',
    fontSize: 12,
    fontFamily: 'Figtree',
    fontWeight: '600',
    lineHeight: '20px',
    letterSpacing: 1.2,
    wordWrap: 'anywhere',
  },

  notesTextContainerStyle: {
    alignSelf: 'stretch',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 12,
    display: 'inline-flex',
  },

  notesTextStyle: {
    flex: '1 1 0',
    color: '#222222',
    fontSize: 18,
    fontFamily: 'Figtree',
    fontWeight: '400',
    lineHeight: '28px',
    wordWrap: 'anywhere',

    overflow: 'auto',
  },

  changeButtonStyle: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 8,
    paddingBottom: 8,
    borderRadius: 8,
    overflow: 'hidden',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 6,
    display: 'flex',
  },

  changeTextStyle: {
    color: '#0068DE',
    fontSize: 16,
    fontFamily: 'Figtree',
    fontWeight: '600',
    textDecoration: 'underline',
    lineHeight: '24px',
    wordWrap: 'break-word',
  },
}

export default styles
