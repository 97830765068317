import React, { useState, useEffect, useContext } from 'react'
import GeneralDetails from './details_general'
import Schedule from './schedule'
import axios from 'axios'
import { AuthContext } from '../auth'
import DetailsServicesView from './details_services'
import Avatar from '../commonComponents/avatar'
import AdvancedTab from './advanced'
import { useHistory } from 'react-router-dom'
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonPage,
  IonSpinner,
  IonTitle,
  IonToolbar,
  IonToast,
} from '@ionic/react'
import StandardContainer from '../commonComponents/StandardContainer'
import { calendarClearOutline, cutOutline, personOutline, settingsOutline, statsChartOutline } from 'ionicons/icons'
import Appointment from '../appointments'
import EmployeeStats from './employeeStats'
import CheckIsManagerOrAdmin from '../Utils/CheckIsManagerOrAdmin'
import { getFormattedMediumDate } from '../utils'

export default function Details({ match }) {
  const employee_id = match?.params?.id

  const [value, setValue] = useState(0)
  const [employee, setEmployee] = useState()
  const [employeeTemp, setEmployeeTemp] = useState()
  const [employeeChanged, setEmployeeChanged] = useState(false)
  const [locations, setLocations] = useState()
  const [isSaving, setIsSaving] = useState(false)
  const { businessData, locationId, userData } = useContext(AuthContext)
  const [activeModal, setActiveModal] = useState(null)
  const userEntitlements = CheckIsManagerOrAdmin(userData, businessData, locationId)
  const [bookedPercentage, setBookedPercentage] = useState(false)
  const history = useHistory()

  // Toast state
  const [toast, setToast] = useState({ isOpen: false, message: '', color: '' })

  // Common handle toast function
  const showToastMessage = (message, color = 'success') => {
    setToast({ isOpen: true, message, color })
  }

  const getEmployeeBookingStats = async () => {
    // Get the current date
    let currentDate = new Date()

    // Get current month and year
    const currentMonth = currentDate.getMonth()
    const currentYear = currentDate.getFullYear()

    // Get the start date of the current month
    const startDate = getFormattedMediumDate(new Date(currentYear, currentMonth, 1))

    // Get the end date of the current month
    const endDate = getFormattedMediumDate(new Date(currentYear, currentMonth + 1, 0))
    currentDate = getFormattedMediumDate(currentDate)
    try {
      const response = await axios.get(`/employee/bookingStats?fromDate=${startDate}&id=${employee_id}&toDate=${endDate}`)

      setBookedPercentage(response?.data?.bookedPercentage)
    } catch (error) {
      console.error('Failed to load booking stats.', error) // Log the error for debugging
    }
  }

  const getEmployee = async () => {
    try {
      let response = await axios.get(`/employee?id=${employee_id}`)

      setEmployee(response.data)
      setEmployeeTemp(response.data)
    } catch (error) {
      console.error('Failed to load employee data.', error) // Log the error for debugging
    }
  }
  useEffect(() => {
    getEmployee()
    getEmployeeBookingStats()
  }, [employee_id])

  const getLocationsList = async () => {
    try {
      let response = await axios.get(`/location_v2/getList?businessId=${businessData._id}`)
      setLocations(response.data)
    } catch (error) {
      console.error('Failed to load locations.', error) // Log the error for debugging
    }
  }
  useEffect(() => {
    getLocationsList()
  }, [])

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const updateEmployee = (value) => {
    setEmployeeTemp(value)
    setEmployeeChanged(true)
  }

  const save = async () => {
    setIsSaving(true)
    const input = { ...employeeTemp }
    delete input._id
    try {
      let response = await axios.patch(`/employee?id=${employee_id}`, input)

      await getEmployee()
      await getEmployeeBookingStats()
      setEmployeeChanged(false)
      showToastMessage('Employee data saved successfully.')
    } catch (error) {
      showToastMessage('There was an error saving. Please try again later.', 'danger')
    }
    setIsSaving(false)
  }

  const cancel = () => {
    setEmployeeTemp(employee)
    setEmployeeChanged(false)
  }

  let modalContent

  if (activeModal == 'Info') {
    modalContent = (
      <GeneralDetails employee={employeeTemp} updateEmployee={updateEmployee} locations={locations} locationId={locationId} businessId={businessData._id} />
    )
  } else if (activeModal == 'Services') {
    modalContent = <DetailsServicesView businessId={businessData._id} locationId={locationId} employee={employeeTemp} updateEmployee={updateEmployee} />
  } else if (activeModal == 'Schedule') {
    modalContent = <Schedule employee={employeeTemp} updateEmployee={updateEmployee} locationId={locationId} businessId={businessData._id} />
  } else if (activeModal == 'Advanced') {
    modalContent = <AdvancedTab employee={employeeTemp} getEmployee={getEmployee} locationId={locationId} businessId={businessData._id} />
  } else if (activeModal == 'My Appointments') {
    modalContent = <Appointment />
  } else if (activeModal == 'Stats') {
    modalContent = <EmployeeStats employee={employeeTemp} />
  }

  let body

  if (!employee) {
    body = (
      <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', height: 200 }}>
        <IonSpinner name='dots' />
      </div>
    )
  } else {
    let isAnyDayEnabled = true
    if (employee?.standardSchedule) {
      isAnyDayEnabled = Object.values(employee.standardSchedule).some((day) => day.enabled)
    }

    body = (
      <>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            padding: 30,
          }}
        >
          <div>
            <Avatar employee={employee} businessId={businessData._id} />
          </div>
          <div style={{ textAlign: 'center' }}>
            <h3>
              {employee.firstName} {employee.lastName}
            </h3>
            <h6>{employee.role === 'Barber' ? 'Professional' : employee.role}</h6>
            <div
              style={{
                border: '1px solid ' + (employee.isPublic ? 'green' : '#CD7F32'),
                borderRadius: 12,
                backgroundColor: employee.isPublic ? 'rgba(0, 255, 0, 0.2)' : 'rgba(255, 165, 0, 0.2)',
                paddingTop: 2,
                paddingBottom: 2,
                color: employee.isPublic ? 'green' : '#CD7F32	',
              }}
            >
              {employee.isPublic ? 'Profile Public' : 'Profile Private'}
            </div>
          </div>
          {/* <p>Booked Percentage : {bookedPercentage && bookedPercentage != 'Infinity' ? `${bookedPercentage}%` : 'N/A'}</p> */}
        </div>
        <div style={{ margin: 20 }}>
          <h3>Daily</h3>
        </div>
        <IonList inset={true} mode='ios'>
          <IonItem
            key='appt'
            button
            detail={true}
            onClick={() => {
              setActiveModal('My Appointments')
            }}
          >
            <IonIcon icon={calendarClearOutline} slot='start' />
            <IonLabel>My Appointments</IonLabel>
          </IonItem>
          {/* <IonItem button detail={true} >
                        <IonIcon icon={watchOutline} slot="start" />
                        <IonLabel>Clock In/Clock Out</IonLabel>
                    </IonItem> */}
          {/* <IonItem button detail={true} >
                        <IonIcon icon={airplaneOutline} slot="start" />
                        <IonLabel>Request Time Off</IonLabel>
                    </IonItem> */}
          {/* <IonItem button detail={true} >
                        <IonIcon icon={checkboxOutline} slot="start" />
                        <IonLabel>My Tasks</IonLabel>
                    </IonItem> */}
        </IonList>
        <div style={{ margin: 20 }}>
          <h3>Settings</h3>
        </div>
        <IonList inset={true} mode='ios'>
          <IonItem
            key='Info'
            button
            detail={true}
            onClick={() => {
              setActiveModal('Info')
            }}
          >
            <IonIcon icon={personOutline} slot='start' />
            <IonLabel>General Info</IonLabel>
          </IonItem>
          <IonItem
            key='schedule'
            button
            detail={true}
            onClick={() => {
              setActiveModal('Schedule')
            }}
          >
            <IonIcon icon={calendarClearOutline} slot='start' />
            <IonLabel>Schedule</IonLabel>
            {!isAnyDayEnabled && (
              <IonLabel
                style={{
                  fontSize: '12px',
                  backgroundColor: '#eeeeee',
                  borderRadius: '4px',
                  maxWidth: 'fit-content',
                  padding: '5px 15px',
                  fontWeight: 'bold',
                  color: 'grey',
                }}
              >
                No Schedule
              </IonLabel>
            )}
          </IonItem>
          <IonItem
            key='services'
            button
            detail={true}
            onClick={() => {
              setActiveModal('Services')
            }}
          >
            <IonIcon icon={cutOutline} slot='start' />
            <IonLabel>Services</IonLabel>
            {(!employee.services || employee.services?.length == 0) && (
              <IonLabel
                style={{
                  fontSize: '12px',
                  backgroundColor: '#eeeeee',
                  borderRadius: '4px',
                  maxWidth: 'fit-content',
                  padding: '5px 15px',
                  fontWeight: 'bold',
                  color: 'grey',
                }}
              >
                None Enabled
              </IonLabel>
            )}
          </IonItem>
          <IonItem
            key='stats'
            button
            detail={true}
            onClick={() => {
              setActiveModal('Stats')
            }}
          >
            <IonIcon icon={statsChartOutline} slot='start' />
            <IonLabel>Stats</IonLabel>
          </IonItem>
          <IonItem
            key='advanced'
            button
            detail={true}
            onClick={() => {
              setActiveModal('Advanced')
            }}
          >
            <IonIcon icon={settingsOutline} slot='start' />
            <IonLabel>Advanced</IonLabel>
          </IonItem>
        </IonList>
      </>
    )
  }
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color='white' style={{ backgroundColor: 'white', color: 'black' }}>
          <IonButtons slot='start'>
            <IonBackButton />
          </IonButtons>
          <IonTitle>{employeeTemp ? employeeTemp?.firstName + ' ' + employeeTemp?.lastName : 'loading...'}</IonTitle>
          {employeeChanged ? (
            <>
              <IonButtons slot='end'>
                <IonButton onClick={cancel}>Cancel</IonButton>
              </IonButtons>
              <IonButtons slot='end'>
                <IonButton color='primary' onClick={save}>
                  Save
                </IonButton>
              </IonButtons>
            </>
          ) : null}
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <StandardContainer>
          {body}

          {employeeTemp?._id && (
            <>
              <div style={{ margin: 20 }}>
                <h3>Reports</h3>
              </div>
              <IonList mode='ios' inset={true}>
                <IonItem routerLink={`/core/serviceTime/${employee_id}`} detail={true}>
                  <IonLabel>Service Time</IonLabel>
                </IonItem>
              </IonList>
            </>
          )}
        </StandardContainer>
        <ModalView
          isOpen={modalContent ? true : false}
          close={() => {
            setActiveModal(null)
          }}
          employeeChanged={employeeChanged}
          save={save}
          cancel={cancel}
          isSaving={isSaving}
          activeModal={activeModal}
        >
          {modalContent}
        </ModalView>
      </IonContent>
      <IonToast
        position='top'
        isOpen={toast.isOpen}
        onDidDismiss={() => setToast({ ...toast, isOpen: false })}
        message={toast.message}
        color={toast.color}
        duration={2000}
      />
    </IonPage>
  )
}

function ModalView({ children, isOpen, close, employeeTemp, employeeChanged, save, cancel, isSaving, activeModal }) {
  async function handleCancel() {
    await cancel()
    close()
  }
  async function handleSave() {
    await save()
    close()
  }

  return (
    <IonModal isOpen={isOpen} onDidDismiss={close} className='regularBackground'>
      <IonHeader>
        {/* <IonToolbar>
                <IonTitle>Modal</IonTitle>
                <IonButtons slot="end">
                  <IonButton onClick={close}>Close</IonButton>
                </IonButtons>
              </IonToolbar> */}
        <IonToolbar color='white' style={{ backgroundColor: 'white', color: 'black' }}>
          <IonButtons slot='start'>
            <IonBackButton />
          </IonButtons>
          <IonTitle>{activeModal}</IonTitle>
          {employeeChanged ? (
            <>
              <IonButtons slot='end'>
                <IonButton onClick={handleCancel}>Cancel</IonButton>
              </IonButtons>
              {isSaving ? (
                <IonItem slot='end'>
                  <IonSpinner name='dots' />
                </IonItem>
              ) : (
                <IonButtons slot='end'>
                  <IonButton color='primary' onClick={handleSave}>
                    Save
                  </IonButton>
                </IonButtons>
              )}
            </>
          ) : (
            <>
              <IonButtons slot='end'>
                <IonButton onClick={close}>Close</IonButton>
              </IonButtons>
            </>
          )}
        </IonToolbar>
      </IonHeader>
      <IonContent className='regularBackground' style={{ backgroundColor: 'red !important' }}>
        {children}
      </IonContent>
    </IonModal>
  )
}
