import { IonContent, IonPage } from '@ionic/react'
import { useContext } from 'react'
import CommonHeader from '../commonComponents/CommonHeader'
import StandardContainer from '../commonComponents/StandardContainer'
import { AuthContext } from '../auth'
import GetLocationDataBasedOnLocationId from '../Utils/GetLocationDataBasedOnLocationId'
import DisabledDeflectionMessageCard from './DisabledDeflectionMessageCard'
import DeflectedReportAndFeedback from './DeflectedReportAndFeedback'

export default function CustomerReviews() {
  const { locationData, businessData, locationId } = useContext(AuthContext)
  const selectedLocation = GetLocationDataBasedOnLocationId(locationData, locationId)
  const isDeflectionEnabled = selectedLocation?.deflectionSettings?.badReviewDeflectionEnabled

  const userReviewsData = {
    data: {
      businessName: 'Your Business Name',
      averageRating: 4.5,
      totalReviews: 100,
    },
    reviews: [
      {
        reviewer: 'Matt Ribard',
        avatar: 'https://ionicframework.com/docs/img/demos/avatar.svg',
        role: 'Local Guide',
        numReviews: 12,
        rating: 5,
        timestamp: '34 minutes ago',
        comment:
          'Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry...Lorem mply dummy text of the printing and typesetting industry...Lorem Ipsum is simply dummy text of the printing and typesetting industry...',
        expanded: false,
      },
      {
        reviewer: 'Jane Doe',
        avatar: 'https://ionicframework.com/docs/img/demos/avatar.svg',
        numReviews: 5,
        rating: 4.5,
        timestamp: '2 hours ago',
        comment: 'Great experience! Loved the ambiance and friendly staff.',
        expanded: false,
      },
      {
        reviewer: 'John Smith',
        avatar: 'https://ionicframework.com/docs/img/demos/avatar.svg',
        numReviews: 20,
        rating: 3.8,
        timestamp: '1 day ago',
        comment:
          'Decent service. Could be better.Decent service. Could be better.Decent service. Could be better.Decent service. Could be better.Decent service. Could be better.Decent service. Could be better.Decent service. Could be better.Decent service. Could be better.Decent service. Could be better.Decent service. Could be better.',
        expanded: false,
      },
      {
        reviewer: 'Alice Johnson',
        avatar: 'https://ionicframework.com/docs/img/demos/avatar.svg',
        role: 'Local Resident',
        numReviews: 8,
        rating: 2,
        timestamp: '3 days ago',
        comment: 'Not satisfied with the overall experience. Needs improvement.',
        expanded: false,
      },
      {
        reviewer: 'Charlie Brown',
        avatar: 'https://ionicframework.com/docs/img/demos/avatar.svg',
        role: 'Tourist',
        numReviews: 3,
        rating: 4,
        timestamp: '5 days ago',
        comment: 'Good service. Enjoyed my visit.',
        expanded: false,
      },
    ],
  }
  return (
    <>
      <IonPage id='main-content'>
        <CommonHeader
          title={'Customer Reviews'}
          settingsIcon={true}
          settingsLink={'/core/requestCustomerReviews'}
          backIcon={true}
        ></CommonHeader>

        {/* If The deflection settings for the locatin are not enabled then show the below image with text */}
        {!isDeflectionEnabled && (
          <IonContent className='regularBackground'>
            <StandardContainer>
              {!isDeflectionEnabled && <DisabledDeflectionMessageCard />}
            </StandardContainer>
          </IonContent>
        )}
        {isDeflectionEnabled && (
          <IonContent>
            <StandardContainer>
              {/* <OverallRating reviewData={userReviewsData.data} />
              <Reviews reviews={userReviewsData.reviews} /> */}
              <DeflectedReportAndFeedback />
            </StandardContainer>
          </IonContent>
        )}
        {/* 
        <IonContent className='regularBackground'>
          <StandardContainer>
            <div style={{ padding: '1rem' }}>
              <OverallRating reviewData={userReviewsData.data} />
              <Reviews reviews={userReviewsData.reviews} />
            </div>
          </StandardContainer>
        </IonContent> */}
      </IonPage>
    </>
  )
}
