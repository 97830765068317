import { colors } from '../theme/colors'
import { fontSizes, fontWeights } from '../theme/typography'
import { getFormattedMediumDate } from '../utils'
import { IonButton, IonLabel } from '@ionic/react'

export default function StepCancellation({ stepNext, updateScheduleData, scheduleData }) {
  const appointmentStartTime = scheduleData?.dateTime?.startTime
  const twentyFourHrsBeforeApptStart = appointmentStartTime - 24 * 3600000
  const currentTime = new Date().getTime()
  const timeDifferenceInHours = (scheduleData?.dateTime?.startTime - currentTime) / (60 * 60 * 1000)

  function formatTime(epochTime) {
    const date = new Date(epochTime)

    // Extracting time parts
    let hours = date.getHours()
    const minutes = date.getMinutes()
    const ampm = hours >= 12 ? 'PM' : 'AM'

    hours = hours % 12
    hours = hours ? hours : 12 // the hour '0' should be '12'
    const minutesStr = minutes < 10 ? '0' + minutes : minutes

    const strTime = hours + ':' + minutesStr + ' ' + ampm

    return `${strTime}`
  }

  const formattedTime = formatTime(twentyFourHrsBeforeApptStart)
  const formattedDate = getFormattedMediumDate(twentyFourHrsBeforeApptStart)
  // Outputs the formatted date and time one hour before the appointment start time
  function handleAgreeClick() {
    updateScheduleData('agreedToCancelPolicy', true)
    stepNext()
  }

  const containerStyle = {
    fontFamily: 'FigTree',
    padding: '2rem',
    marginTop: '6rem',
  }

  const progressBarContainerStyle = {
    position: 'relative',
    marginBottom: '2rem',
  }

  const progressBarStyle = {
    display: 'flex',
    height: 5,
    borderRadius: 2,
  }

  const blueSegmentStyle = {
    flex: '0 0 70%',
    backgroundColor: colors.primaryBlue,
  }

  const greySegmentStyle = {
    flex: '0 0 30%',
    background: `repeating-linear-gradient(132deg, #b7b7b79c, #b7b7b79c 4px, rgb(0 0 0 / 25%) 4px, rgb(0 0 0 / 25%) 8px)`,
  }

  const labelStyle = {
    fontSize: fontSizes.size12,
  }

  const timeStyle = {
    fontWeight: fontWeights.weight800,
    color: colors.black,
  }

  return (
    <>
      <style>
        {`
          .cancellation-policy {
            width: 9rem;
            height: 75px;
            background-color: black;
            color: #fff;
            padding: 20px;
            position: absolute;
            top: -6rem;
            right: 15%;
            border-radius: 10px;
          }

          .cancellation-policy.arrow-bottom:after {
            content: " ";
            position: absolute;
            right: 65px;
            bottom: -10px;
            border-top: 10px solid black;
            border-right: 10px solid transparent;
            border-left: 10px solid transparent;
            border-bottom: none;
          }
						@media (max-width: 768px) {
            .cancellation-policy {
              right: 10%;
            }
          }
        `}
      </style>
      <div style={containerStyle}>
        {timeDifferenceInHours > 24 && (
          <div style={progressBarContainerStyle}>
            <div style={progressBarStyle}>
              <div style={blueSegmentStyle}></div>
              <div style={greySegmentStyle}></div>
            </div>
            <div style={{ marginTop: 10, display: 'flex', justifyContent: 'space-between' }}>
              <IonLabel style={labelStyle}>NOW</IonLabel>
              <IonLabel style={labelStyle}>APPOINTMENT</IonLabel>
            </div>
            <div className='cancellation-policy arrow-bottom'>
              <div>{formattedTime}</div>
              <div style={{ color: '#aeaeae' }}>{formattedDate}</div>
            </div>
          </div>
        )}

        <div>
          {timeDifferenceInHours < 24 ? (
            <IonLabel>You are within 24 hours of your appointment, if you cancel your appointment you may be charged a cancel fee</IonLabel>
          ) : (
            <>
              <IonLabel>You'll have time until</IonLabel>
              <IonLabel style={timeStyle}> {formattedTime} </IonLabel>
              <IonLabel>on</IonLabel>
              <IonLabel style={timeStyle}> {formattedDate} </IonLabel>
              <IonLabel>to cancel this appointment without being charged</IonLabel>
            </>
          )}
        </div>
        <IonButton onClick={handleAgreeClick} style={{ display: 'flex', marginTop: '2rem' }}>
          Customer Agrees
        </IonButton>
      </div>
    </>
  )
}
