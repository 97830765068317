import { IonButton, IonButtons, IonContent, IonInput, IonLabel, IonModal, IonRadio, IonRadioGroup, IonSpinner, IonToast } from '@ionic/react'
import { useContext, useEffect, useState } from 'react'
import CommonHeader from '../../commonComponents/CommonHeader'
import StandardContainer from '../../commonComponents/StandardContainer'
import { AuthContext } from '../../auth'
import GetLocationDataBasedOnLocationId from '../../Utils/GetLocationDataBasedOnLocationId'
import axios from 'axios'
import { limitDecimalPlaces } from '../../utils'
import styles from '../NewCalendarView/Styles/NoShowButtonStyles' // Import the styles

export default function NoShowButton({ appointment, getAppointment }) {
  const [chargesModalOpen, setChargesModalOpen] = useState(false)
  const { locationId, locationData } = useContext(AuthContext)
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [cardOnFile, setCardOnFile] = useState(false)
  const selectedLocationData = GetLocationDataBasedOnLocationId(locationData, locationId)
  const isCustomerSubscribed = appointment?.customChargeValue?.isSubscribed
  const locationNoShowSettings = isCustomerSubscribed
    ? selectedLocationData?.appointmentSettings?.noShowSettings?.subscriber
    : selectedLocationData?.appointmentSettings?.noShowSettings?.nonSubscriber
  const currentTime = new Date().getTime()
  const timeDifferenceInHours = (appointment?.startTime - currentTime) / (60 * 60 * 1000)
  const cancellationAllowed = timeDifferenceInHours > (locationNoShowSettings?.hoursBefore || 24)
  const [selectedOption, setSelectedOption] = useState('standard')
  const [customCharge, setCustomCharge] = useState('')
  const [toast, setToast] = useState({
    isOpen: false,
    message: '',
    color: 'success', // default color
  })
  const handleOptionChange = (value) => {
    setSelectedOption(value)
    setCustomCharge('')
    setError('')
  }

  const handleCustomChargeChange = (e) => {
    setCustomCharge(e.detail.value)
    if (e.detail.value > appointment?.payment?.totalPrice) {
      setError('Charge value cannot be more than appointment total price')
    } else {
      setError('')
    }
  }
  const getCancellationCharges = () => {
    // Assuming appointment.payment.totalprice is the total price
    let totalPrice = appointment?.payment?.totalPrice
    let defaultCancellationCharges = 0
    // Check the type property and apply discount accordingly
    if (locationNoShowSettings?.type === 'fixed') {
      // Subtract the fixed value
      defaultCancellationCharges = locationNoShowSettings?.value
    } else if (locationNoShowSettings?.type === 'percentage') {
      // Subtract the percentage
      const percentage = locationNoShowSettings?.value / 100
      defaultCancellationCharges = totalPrice * percentage
    }
    return defaultCancellationCharges
  }
  const defaultCancellationCharges = getCancellationCharges()

  const openChargesModal = () => {
    setChargesModalOpen(true)
  }
  const closeChargesModal = () => {
    setChargesModalOpen(false)
    setSelectedOption('standard')
    setCustomCharge('')
    setError('')
  }

  const handleChargeAndUpdateStatus = async () => {
    setLoading(true)

    // Determine the no show charge based on selected option and settings
    const getNoShowCharge = () => {
      if (!locationNoShowSettings?.charge || cancellationAllowed || !cardOnFile) return { noShowCharge: 0, customized: false }

      switch (selectedOption) {
        case 'standard':
          return { noShowCharge: parseFloat(defaultCancellationCharges), customized: false }
        case 'waive':
          return { noShowCharge: 0, customized: true }
        default:
          return { noShowCharge: parseFloat(customCharge), customized: true }
      }
    }

    const { noShowCharge, customized: customizedNoShowCharge } = getNoShowCharge()

    try {
      const input = {
        appointmentId: appointment?._id,
        status: 'No Show',
        paidExternal: true,
        noShowCharge,
        customizedNoShowCharge,
      }

      // Update appointment status
      await axios.patch(`/appointment_v2/updateStatus?id=${appointment?._id}`, input)

      // Update appointment with no show charge
      const paymentObjectWithNoShowCharge = {
        ...appointment?.payment,
        noShowCharge,
      }
      await axios.patch(`/appointment_v2?id=${appointment?._id}`, { payment: paymentObjectWithNoShowCharge })

      await getAppointment()

      // Display success toast message
      setToast({
        isOpen: true,
        message: 'No show charge updated successfully!',
        color: 'success',
      })
    } catch (error) {
      // Display error toast message
      setError('Failed to update no show charge.')
      setToast({
        isOpen: true,
        message: 'Error updating no show charge.',
        color: 'danger',
      })
    } finally {
      setLoading(false)
    }
  }
  const getCard = async () => {
    try {
      let response = await axios.get(`/customer_v2/cardStripe?customerId=${appointment?.customer?._id}`)

      setCardOnFile(response?.data?.cardOnFile || false)
    } catch (error) {
      console.error('Error fetching card information:', error)
    }
  }
  useEffect(() => {
    getCard()
  }, [])
  useEffect(() => {
    let formattedValue = limitDecimalPlaces(customCharge, 2)
    setCustomCharge(formattedValue)
  }, [customCharge])
  const options = [
    {
      value: 'standard',
      label: 'Standard Cancellation Fee',
      description: `${appointment?.customer?.firstName} ${appointment?.customer?.lastName} will be charged a cancellation fee of `,
      amount: `$${defaultCancellationCharges}`,
    },
    {
      value: 'custom',
      label: 'Custom Fee',
      description: `${appointment?.customer?.firstName} ${appointment?.customer?.lastName} will be charged an amount you choose.`,
      customInput: true,
    },
    {
      value: 'waive',
      label: 'Waive Fee',
      description: `${appointment?.customer?.firstName} ${appointment?.customer?.lastName} will not be charged for missing the appointment.`,
    },
  ]
  return (
    <>
      <IonButton color={'dark'} shape='round' onClick={openChargesModal} style={styles.button} fill='solid'>
        No Show
      </IonButton>
      <IonModal isOpen={chargesModalOpen} onDidDismiss={closeChargesModal}>
        <CommonHeader closeButton={true} closeModal={closeChargesModal} title={'No Show'}></CommonHeader>
        <IonContent>
          <StandardContainer>
            <div style={styles.modalContent}>
              {locationNoShowSettings?.charge && !cancellationAllowed && cardOnFile ? (
                <>
                  <div style={styles.chargeContainer}>
                    <div style={styles.chargeHeader}>
                      <div style={styles.chargeTitle}>
                        <div style={styles.titleText}>Mark as no show</div>
                      </div>
                      <div style={styles.subTitle}>
                        <div style={styles.subTitleText}>Choose how you want to proceed below.</div>
                      </div>
                    </div>
                    <div style={styles.radioGroup}>
                      <IonRadioGroup value={selectedOption} onIonChange={(e) => handleOptionChange(e.detail.value)}>
                        {options.map((option) => (
                          <div onClick={() => handleOptionChange(option.value)} key={option.value} style={styles.optionContainer(option.value == 'custom')}>
                            <div style={styles.optionInnerContainer}>
                              <div style={styles.optionTextContainer}>
                                <div style={{ alignSelf: 'stretch', justifyContent: 'flex-start', alignItems: 'center', gap: 12, display: 'inline-flex' }}>
                                  <div style={styles.optionTextHeader}>{option.label}</div>
                                </div>
                                <div style={styles.optionDescription}>
                                  <span style={styles.descriptionText}>{option.description}</span>
                                  {option.amount && <span style={styles.amountText}>{option.amount}</span>}
                                </div>
                                {option.customInput && selectedOption === 'custom' && (
                                  <>
                                    <IonInput
                                      style={styles.customInput}
                                      fill='outline'
                                      type='number'
                                      value={customCharge}
                                      onIonInput={handleCustomChargeChange}
                                      placeholder='0'
                                      inputmode='decimal'
                                    />
                                    {error && <IonLabel style={styles.errorLabel}>{error}</IonLabel>}
                                  </>
                                )}
                              </div>
                              <div style={styles.ionRadio}>
                                <IonRadio value={option.value} slot='start' mode='md' />
                              </div>
                            </div>
                          </div>
                        ))}
                      </IonRadioGroup>
                    </div>
                  </div>
                  <div style={{ width: '100%', justifyContent: 'center', alignItems: 'center', display: 'inline-flex' }}>
                    <IonButton
                      disabled={
                        loading ||
                        (locationNoShowSettings?.charge
                          ? (selectedOption !== 'standard' && selectedOption !== 'waive' && customCharge === '') || error !== ''
                          : false)
                      }
                      onClick={handleChargeAndUpdateStatus}
                      style={styles.chargeButton}
                    >
                      <div style={styles.buttonInner}>
                        {loading && <IonSpinner style={{ marginRight: '0.5rem' }} name='lines' />}
                        <div style={styles.buttonText}>
                          {selectedOption === 'standard' && `Charge $${defaultCancellationCharges}`}
                          {selectedOption === 'custom' && `Charge $${customCharge}`}
                          {selectedOption === 'waive' && 'Waive Fee'}
                        </div>
                      </div>
                    </IonButton>
                  </div>
                </>
              ) : (
                <div style={{ width: '100%', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', display: 'inline-flex' }}>
                  <div>
                    <IonLabel style={{ fontWeight: 'bold' }}>Mark as No Show?</IonLabel>
                  </div>
                  <div>
                    <IonButtons style={{ marginTop: 20, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <IonButton onClick={() => closeChargesModal()} style={{ color: 'grey' }}>
                        Dismiss
                      </IonButton>
                      {loading && <IonSpinner name='lines' />}
                      {!loading && (
                        <IonButton color='primary' onClick={handleChargeAndUpdateStatus}>
                          Save
                        </IonButton>
                      )}
                    </IonButtons>
                  </div>
                </div>
              )}
            </div>
          </StandardContainer>
        </IonContent>
      </IonModal>
      <IonToast
        position='top'
        isOpen={toast.isOpen}
        message={toast.message}
        color={toast.color}
        onDidDismiss={() => setToast({ ...toast, isOpen: false })}
        duration={2000}
      />
    </>
  )
}
