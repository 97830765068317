import { IonCol, IonContent, IonGrid, IonIcon, IonLabel, IonPage, IonRow } from '@ionic/react'
import StandardContainer from '../commonComponents/StandardContainer'
import { chevronBack } from 'ionicons/icons'
import { fontSizes, fontWeights } from '../theme/typography'
import { colors } from '../theme/colors'
import { useHistory } from 'react-router'

export default function PhoneAssistant() {
  const history = useHistory()

  return (
    <IonPage>
      <IonContent>
        <StandardContainer padding={20}>
          <IonRow onClick={() => history.goBack()} style={{ width: 'fit-content', display: 'flex', alignItems: 'center' }}>
            <IonIcon icon={chevronBack} />
            <IonLabel style={{ fontSize: fontSizes.size18 }}>Back</IonLabel>
          </IonRow>
          <div style={{ marginTop: 20, fontFamily: 'Figtree' }}>
            <img src='/assets/ai-phone-assistant.png' alt='' style={{ height: '4rem' }} />
            <IonRow style={{ marginTop: 40 }}>
              <IonLabel style={{ fontSize: fontSizes.size50, fontWeight: fontWeights.weightBold }}>AI Phone</IonLabel>
              <IonLabel style={{ fontSize: fontSizes.size50, fontWeight: fontWeights.weightBold }}>Receptionist</IonLabel>
            </IonRow>
            <IonRow style={{ marginTop: 20 }}>
              <IonLabel style={{ fontSize: fontSizes.size20 }}>
                A phone receptionist to answer calls,schedule appointments and share information about your store.
              </IonLabel>
            </IonRow>
            <IonGrid style={{ marginTop: 40 }}>
              <IonRow>
                <IonCol size='auto'>
                  <div style={{ height: 25, width: 25, borderRadius: '50%', background: 'linear-gradient(130deg, #b87beb, #47c2ea)' }}></div>
                </IonCol>
                <IonCol style={{ fontSize: fontSizes.size20, marginLeft: 20, display: 'flex', flexDirection: 'column' }}>
                  <IonLabel>Active</IonLabel>
                  <IonLabel style={{ marginTop: 10 }}>720-864-0207</IonLabel>
                </IonCol>
              </IonRow>
            </IonGrid>
            <IonRow style={{ marginTop: 20, backgroundColor: colors.black10, padding: 20, borderRadius: 10 }}>
              <IonLabel style={{ fontSize: fontSizes.size20 }}>
                Update your business profiles on Google,Apple, Bing or anywhere else you have a business profile with this phone number : 720-864-0207
              </IonLabel>
            </IonRow>
          </div>
        </StandardContainer>
      </IonContent>
    </IonPage>
  )
}
