// Import necessary modules and utilities
import Chart from 'react-apexcharts'
import axios from 'axios'
import { getMonthRange } from '../Utils/GetDates'
import Picker from '../commonComponents/Picker'
import GetPickerMonths from '../Utils/GetPickerMonths'
import { GetXAxisMonths } from '../Utils/GetXAxisMonths'
import { IonCard, IonCol, IonGrid, IonItem, IonLabel, IonList, IonRow, IonSpinner } from '@ionic/react'
import { useEffect, useState } from 'react'
import { getAllDaysInCurrentMonth, getDaysInCurrentMonth } from '../utils'
export default function Acquisition({ type, title, hideDetails, refId, handleTitle }) {
  // Initialize state variables and constants
  let initialNumOfMonths = 6
  const [loadingData, setLoadingData] = useState(false)
  const [selectedOption, setSelectedOption] = useState(initialNumOfMonths - 1)
  const pickerItems = GetPickerMonths()
  const [data, setData] = useState(false)

  const [options, setOptions] = useState({
    chart: {
      type: 'bar',
      height: 250,
      stacked: true,
      toolbar: false,
    },
    xaxis: {
      categories: [],
      tickAmount: 5,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '90%',
        endingShape: 'rounded',
      },
    },

    stroke: {
      show: false,
      width: 2,
      colors: ['transparent'],
    },
    grid: {
      borderColor: '#fff',
    },
    yaxis: {
      title: {
        text: 'Customers',
      },
    },
    toolbar: {
      show: false,
      tools: {
        download: false,
      },
    },
    export: {
      show: false,
    },

    legend: {
      show: true,
      offsetY: 0,
    },
  })
  const [series, setSeries] = useState([])

  // Function to fetch data based on reference ID, start date, end date, and number of months
  const getAcquistionData = async (startDate, endDate, noOfMonths) => {
    try {
      let url = `/customerAcquisition/reports/${type}?fromDate=${startDate}&toDate=${endDate}`
      if (type == 'impressions') {
        url = url + `&referenceId=${refId}`
      }
      const response = await axios.get(url)

      setLoadingData(false)

      if (response.status === 200) {
        const data = response.data
        const categoriesResponse = GetXAxisMonths(noOfMonths)
        const daysInCurrentMonth = getDaysInCurrentMonth()
        const months = categoriesResponse.monthNumbers

        const series = data.map((category) => {
          const categoryData = {
            name: category.name,
            data: noOfMonths === 0 || noOfMonths === 1 ? Array(daysInCurrentMonth).fill(0) : Array(months.length).fill(0),
          }

          category.counts.forEach((count) => {
            if (noOfMonths === 0 || noOfMonths === 1) {
              const dayIndex = count.day - 1
              categoryData.data[dayIndex] = count.count
            } else {
              const { month, year } = count
              const paddedMonth = String(month).padStart(2, '0')
              const lastTwoDigitsOfYear = year % 100
              const monthYear = `${paddedMonth}-${lastTwoDigitsOfYear}`
              const index = months.findIndex((month) => month === monthYear)

              if (index !== -1) {
                categoryData.data[index] = count.count
              }
            }
          })

          return categoryData
        })
        if (handleTitle) {
          handleTitle(data?.name)
        }
        setData(data)
        setSeries(series)
        setOptions((prevOptions) => ({
          ...prevOptions,
          xaxis: {
            ...prevOptions.xaxis,
            categories: noOfMonths === 0 || noOfMonths === 1 ? getAllDaysInCurrentMonth(noOfMonths) : categoriesResponse.categories,
          },
        }))
      }
    } catch (error) {
      console.error('Failed to get acquisition data.', error) // Log the error for debugging

      // Handle errors if any
    }
  }

  // Function to handle the selected index change
  const handleSelectedIndex = (indexValue) => {
    const data = getMonthRange(indexValue)

    const selectedValue = pickerItems.find((item, index) => index === indexValue)
    if (selectedOption !== selectedValue.text) {
      setLoadingData(true)
      setSelectedOption(selectedValue.text)
      getAcquistionData(data.startDate, data.endDate, indexValue)
    }
  }

  // Initial data fetching on component mount
  useEffect(() => {
    const data = getMonthRange(initialNumOfMonths)
    getAcquistionData(data.startDate, data.endDate, initialNumOfMonths)
  }, [])

  return (
    // <IonPage>
    //   <CommonHeader title={title} backIcon={true} />
    //   <IonContent>
    //     <StandardContainer>
    <IonCard className='flat' style={{ padding: '1rem' }}>
      <h4 style={{ color: 'black', marginTop: 0 }}>{title}</h4>
      <Picker options={pickerItems} selectedItemIndex={initialNumOfMonths} selectedItem={selectedOption} handleSelectedIndex={handleSelectedIndex} />
      {loadingData && <IonSpinner name='dots' />}
      {!loadingData && (
        <>
          <Chart options={options} series={series} type='bar' height={250} />
        </>
      )}
      {!loadingData && !hideDetails && (
        <>
          <IonList lines='full' style={{ marginLeft: -16, marginRight: -16, marginTop: 10 }}>
            {data &&
              data.map((item, index) => {
                return (
                  <IonItem routerLink={`/core/report/${item.referenceId}`} detail={true}>
                    <IonGrid>
                      <IonRow style={{ color: 'black' }}>
                        <IonCol>
                          <IonLabel style={{ fontWeight: 'bold' }}>{item.name}</IonLabel>
                        </IonCol>
                        <IonCol size='auto'>
                          <IonLabel>{item.total}</IonLabel>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </IonItem>
                )
              })}
          </IonList>
        </>
      )}
    </IonCard>
    //     </StandardContainer>
    //   </IonContent>
    // </IonPage>
  )
}
