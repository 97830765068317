import { IonContent, IonGrid, IonIcon, IonImg, IonLabel, IonModal, IonRange, IonRow, IonSelect, IonSelectOption, IonToast } from '@ionic/react'
import { useEffect, useState } from 'react'
import CommonHeader from '../CommonHeader'
import StandardContainer from '../StandardContainer'
import { calendarClearOutline } from 'ionicons/icons'
import ConfirmationModal from '../ConfirmationModal'
import axios from 'axios'

// PictureDetail component to display and manage details of a selected image
export default function PictureDetail({
  customer_id,
  newImage,
  services,
  selectedImage,
  detailModalOpen,
  closeDetailModal,
  handleAddImageToGallery,
  handleUpdateImage,
  selectedImageIndex,
  addingOrUpdatingImage,
  refreshPicturesList,
}) {
  // State variables to manage image details
  const [rating, setRating] = useState()
  const [hairCutName, setHairCutName] = useState('')
  const [image, setImage] = useState('')
  const [uploadedTime, setUploadedTime] = useState(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [deletingData, setDeletingData] = useState(false)
  const [toast, setToast] = useState({ isOpen: false, message: '', color: 'success' })
  // Function to show toast messages
  const showToastMessage = (message, color) => {
    setToast({ isOpen: true, message, color })
  }
  // Function to handle changes in the social media recommendation value
  const handleRecommendValue = (value) => {
    setRating(value)
  }

  // Function to handle changes in the haircut name
  const handleHairCutName = (value) => {
    setHairCutName(value)
  }

  // Function to handle the opening/closing of the delete confirmation modal
  const handleDeleteModal = (value) => {
    setDeleteModalOpen(value)
  }

  // Function to handle image deletion
  const handleDelete = async () => {
    setDeletingData(true)

    try {
      const deleteResponse = await axios.delete(`/customer_v2/picture?customerId=${customer_id}&pictureUUID=${selectedImage.pictureUUID}`)
      if (deleteResponse.status === 200) {
        setDeleteModalOpen(false)
        closeDetailModal()
        refreshPicturesList()
        showToastMessage('Image deleted successfully!', 'success')
      }
    } catch (error) {
      showToastMessage('Error deleting image. Please try again.', 'danger')
    } finally {
      setDeletingData(false)
    }
  }

  // Function to handle saving or updating an image
  const handleSaveImage = () => {
    let imageInput = {
      ...selectedImage,
      data: image,
      contentType: 'image/png',
      name: hairCutName,
      socialMediaRating: rating,
    }
    if (newImage) {
      handleAddImageToGallery(imageInput)
    } else {
      handleUpdateImage(imageInput, selectedImageIndex)
    }
    setTimeout(() => {
      closeDetailModal()
    }, 1000)
  }

  // Update useEffect to prevent unnecessary updates
  useEffect(() => {
    if (selectedImage) {
      setHairCutName(selectedImage?.name || '')
      setRating(selectedImage?.socialMediaRating || null)
      setImage(selectedImage?.data || '')
      setUploadedTime(selectedImage?.uploadedTime || false)
    }
  }, [selectedImage])

  // Format uploadedTime to a readable date string
  const date = new Date(uploadedTime)
  const formattedDate = date.toLocaleDateString('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  })
  return (
    <>
      <IonModal isOpen={detailModalOpen} onDidDismiss={closeDetailModal}>
        {/* CommonHeader component for consistent header appearance */}
        <CommonHeader
          title={''}
          loading={addingOrUpdatingImage}
          saveButton={true}
          closeButton={true}
          deleteButton={newImage ? false : true}
          handleDeleteModal={handleDeleteModal}
          deleteTitle={'Delete Picture'}
          saveDisabled={!hairCutName}
          handleSaveButton={handleSaveImage}
          closeModal={closeDetailModal}
        ></CommonHeader>

        {/* IonContent for displaying image details */}
        <IonContent className='regularBackground'>
          {/* StandardContainer for consistent container appearance */}
          <StandardContainer>
            <IonGrid style={{ padding: '2rem' }}>
              {/* Display the image */}
              <IonRow
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <IonImg
                  style={{
                    height: '20rem',
                    width: '100%',
                    objectFit: 'cover',
                    '--border-radius': '10px',
                  }}
                  src={image}
                />
              </IonRow>
              {/* Display created date if available */}
              {uploadedTime && (
                <IonRow style={{ marginTop: '2rem' }}>
                  <IonIcon size='large' color='primary' icon={calendarClearOutline} />
                  <h1 style={{ margin: '0 0 0 10px' }}>{formattedDate}</h1>
                </IonRow>
              )}
              {/* Dropdown for selecting haircut name */}
              <div style={{ marginTop: '2rem' }}>
                <IonSelect
                  style={{ fontSize: '1.5rem', fontWeight: 'bold' }}
                  onIonChange={(e) => {
                    handleHairCutName(e.detail.value)
                  }}
                  aria-label='No Of Months'
                  value={hairCutName}
                >
                  {services.map((item, index) => (
                    <IonSelectOption key={item._id} value={item.name}>
                      {item.name}
                    </IonSelectOption>
                  ))}
                </IonSelect>
              </div>
              {/* Section for social media recommendation */}
              <div style={{ marginTop: '2rem' }}>
                <h4 style={{ fontWeight: 'bold' }}>Recommendation for Social</h4>

                <div>
                  {/* IonRange for selecting recommendation value */}
                  <IonRange
                    value={rating}
                    onIonKnobMoveEnd={({ detail }) => handleRecommendValue(detail.value)}
                    style={{
                      '--bar-height': ' 5px',
                    }}
                    ticks={true}
                    snaps={true}
                    min={1}
                    max={5}
                  ></IonRange>
                </div>
              </div>

              {/* Labels for social media recommendation scale */}
              <IonRow style={{ width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                <IonLabel style={{ fontSize: '14px', fontWeight: 'bold' }}>Do Not Recommend</IonLabel>
                <IonLabel style={{ fontSize: '14px', fontWeight: 'bold' }}>Highly Recommend</IonLabel>
              </IonRow>
            </IonGrid>
          </StandardContainer>
        </IonContent>
      </IonModal>
      {/* ConfirmationModal for confirming image deletion */}
      <IonModal isOpen={deleteModalOpen}>
        <ConfirmationModal
          isOpen={deleteModalOpen}
          message={'Are You Sure You want to delete this image?'}
          cancel={() => handleDeleteModal(false)}
          loading={deletingData}
          save={handleDelete}
          saveText={'Delete'}
        />
      </IonModal>
      <IonToast
        position='top'
        isOpen={toast.isOpen}
        onDidDismiss={() => setToast({ ...toast, isOpen: false })}
        message={toast.message}
        color={toast.color}
        duration={2000}
      />
    </>
  )
}
