import React from 'react';
import { IonCard, IonGrid, IonRow, IonCol, IonSpinner } from '@ionic/react';
import { colors } from '../theme/colors';
import StandardCenterCenter from '../commonComponents/StandardCenterCenter';

function TodayCard({ apptSummary, totalAppts, walkinsList, walkinsWaitingList, appointments }) {

    if (appointments?.loading && !appointments?.data) {
        return (
            <IonCard style={styles.topCard} className="flat">
                <StandardCenterCenter>
                    <div style={{ height: 127 }}>
                        <IonSpinner name='dots'  style={{marginTop:50}}/>
                    </div>
                </StandardCenterCenter>
            </IonCard>
        )
    }


    return (
        <IonCard style={styles.topCard} className="flat">
            <div style={styles.circleContainer}>
                <CircleNumber title="SCHEDULED" value={apptSummary?.Scheduled} color={colors.purple} />
                <CircleNumber title="ARRIVED" value={apptSummary?.Arrived} color={colors.orange} />
                <CircleNumber title="PROGRESS" value={apptSummary?.['In Progress']} color={colors.primaryBlue} />
                <CircleNumber title="COMPLETE" value={apptSummary?.Complete} color={colors.green} />
            </div>
            <div style={{ height: 1, width: '100%', backgroundColor: '#d6d6d6' }} />
            <IonGrid style={{ marginBottom: -14, padding: 0, marginTop: 4 }}>
                <IonRow style={{ margin: 0, padding: 0 }}>
                    <IonCol>
                        <div
                            style={{
                                marginTop: 4,
                                fontWeight: '700',
                                color: 'grey',
                                fontSize: 12,
                            }}
                        >
                            TOTAL: {totalAppts}
                        </div>
                    </IonCol>
                    <IonCol>
                        <div
                            style={{
                                marginTop: 4,
                                fontWeight: '700',
                                color: 'grey',
                                fontSize: 12,
                            }}
                        >
                            WALK-INS: {walkinsList?.length || 0}
                        </div>
                    </IonCol>
                    <IonCol>
                        {walkinsWaitingList?.length > 0 ? (
                            <div
                                style={{
                                    backgroundColor: colors.orange,
                                    marginLeft: 0,
                                    marginRight: -10,
                                    width: 134,
                                    textAlign: 'center',
                                    borderRadius: 20,
                                    padding: 4,
                                    fontSize: 12,
                                    fontWeight: '700',
                                    color: 'white',
                                }}
                            >
                                {walkinsWaitingList?.length} Walk-in
                                {walkinsWaitingList?.length != 1 ? 's' : ''} Waiting
                            </div>
                        ) : null}
                    </IonCol>
                </IonRow>
            </IonGrid>
        </IonCard>
    );
}

function CircleNumber({ title, value, color }) {
    let circleColor = {}
    if (value > 0) {
        circleColor = { backgroundColor: color || '#ddd' }
    }
    return (
        <div style={styles.circleItem}>
            <div style={{ ...styles.circle, ...circleColor }}>
                <h1 style={styles.circleNumber}>{value}</h1>
            </div>
            <h5 style={styles.topCardText}>{title}</h5>
        </div>
    )
}

const styles = {
    topCard: { padding: 20, margin: 0, minWidth: 160 },
    topCardNumber: { marginBottom: 0, marginTop: 4 },
    topCardText: { fontSize: 10, fontWeight: '700', marginBottom: 10 },
    circleContainer: {
        display: 'flex',
        justifyContent: 'space-around',
    },
    circleItem: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    circle: {
        width: '70px',
        height: '70px',
        borderRadius: '50%',
        backgroundColor: '#ddd',
        color: 'white',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    circleNumber: {
        fontSize: '24px',
        margin: '0',
    },
}
export default TodayCard;
