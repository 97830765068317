const GetPickerMonths = () => {
  const options = []
  const limit = 20 // Change this limit as needed

  for (let i = 0; i <= limit; i++) {
    if (i === 0) {
      options.push({
        text: 'This Month',
        value: i,
      })
    } else if (i === 1) {
      options.push({
        text: 'Last Month',
        value: i,
      })
    } else {
      options.push({
        text: `Last ${i} months`,
        value: i,
      })
    }
  }
  return options
}
export default GetPickerMonths
