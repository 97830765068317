import { IonCol, IonLabel, IonRow } from '@ionic/react'
import { colors } from '../theme/colors'
import { timeSince } from '../utils'

export default function ConversationsList({
  conversationList,
  handleCustomerClick,
  selectedConversation,
}) {
  return (
    <sidebar>
      <div class='list-wrap'>
        {conversationList?.map(conversation => {
          return (
            <div
              key={conversation.id}
              onClick={() => handleCustomerClick(conversation)}
              class='list'
            >
              <IonLabel
                style={{
                  padding: '10px',
                  backgroundColor: colors.lightGrey,
                  borderRadius: '50%',
                }}
              >
                {conversation.customerFirstName[0]}
                {conversation.customerLastName[0]}
              </IonLabel>
              <div class='info'>
                <IonLabel class='user'>
                  {conversation.customerFirstName} {conversation.customerLastName}
                </IonLabel>
                <IonLabel class='text latest-message'> {conversation?.latestMessageBody}</IonLabel>
              </div>
              {conversation.unReadMessagesInfo ? (
                <IonLabel
                  class='time'
                  style={{
                    color: 'white',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: colors.primaryBlue,
                    height: '1.5rem',
                    width: '1.5rem',
                    borderRadius: '50%',
                  }}
                >
                  {conversation.unReadMessagesInfo}
                </IonLabel>
              ) : null}
              <IonLabel class='time'>
                {timeSince(
                  new Date(
                    conversation?.latestMessageCreatedTimestamp || conversation?.createdTimestamp,
                  ),
                )}
              </IonLabel>
            </div>
          )
        })}
      </div>
    </sidebar>
    // <div
    //   key={conversation.id}
    //   onClick={() => handleCustomerClick(conversation)}
    //   style={{
    //     borderBottom: '1px solid grey',
    //     padding: '5px',
    //     backgroundColor:
    //       selectedConversation?.twilioConversationId == conversation?.twilioConversationId
    //         ? '#e8e8e8'
    //         : '',
    //     cursor: 'pointer',
    //   }}
    // >
    //   <IonRow
    //     style={{
    //       margin: '10px 0',
    //       display: 'flex',
    //       justifyContent: 'center',
    //       alignItems: 'center',
    //     }}
    //   >
    //     <IonCol size='auto'>
    //       <IonLabel
    //         style={{
    //           padding: '10px',
    //           backgroundColor: colors.lightGrey,
    //           borderRadius: '50%',
    //         }}
    //       >
    //         {conversation.customerFirstName[0]}
    //         {conversation.customerLastName[0]}
    //       </IonLabel>
    //     </IonCol>
    //     <IonCol>
    //       <h6 style={{ margin: 0 }}>
    //         {conversation.customerFirstName} {conversation.customerLastName}
    //       </h6>
    //     </IonCol>
    //     <IonCol style={{ display: 'flex', justifyContent: 'end' }}>
    //       <IonLabel style={{ fontSize: '0.8rem', color: 'gray' }}>
    //         {timeSince(
    //           new Date(
    //             conversation?.latestMessageCreatedTimestamp || conversation?.createdTimestamp,
    //           ),
    //         )}
    //       </IonLabel>
    //     </IonCol>
    //   </IonRow>
    //   <IonRow style={{ margin: '10px' }}>
    //     <IonLabel style={{ fontSize: '12px' }} className='latest-message'>
    //       {conversation?.latestMessageBody}
    //     </IonLabel>
    //   </IonRow>
    // </div>
  )
}
