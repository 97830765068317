import React, { useContext, useEffect, useState } from 'react'
import axios from 'axios'
import { IonButton, IonSpinner, IonToast } from '@ionic/react'
import { reviewOrderStyles } from './Styles/ReviewOrderStyles'
import { AuthContext } from '../auth'
import ClientInfoSummary from './Summary/ClientInfoSummary'
import BarberSummary from './Summary/BarberSummary'
import DateTimeSummary from './Summary/DateTimeSummary'
import ServicesProductsSummary from '../appointments/NewCalendarView/SubComponents/ServicesProductsSummary'
import LocationSummary from './Summary/LocationSummary'

const styles = {
  listItem: {
    paddingLeft: 0,
  },
}

export default function Review({
  choosecustomer,
  setStep,
  coreAppointment,
  userSubscription,
  scheduleData,
  updateScheduleData,
  stepNext,
  isWalkin,
  step,
  trackStep,
}) {
  const [errorMessage, setErrorMessage] = useState('')
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false)
  const { businessData } = useContext(AuthContext)
  const [toast, setToast] = useState({ isOpen: false, message: '', color: '' })

  useEffect(() => {
    trackStep('ReviewOrder', step, scheduleData)
  }, [])

  async function handleBookAndPay() {
    setSubmitButtonDisabled(true)
    try {
      const response = await axios.post(`/appointment_v2`, {
        businessId: scheduleData.businessId,
        locationId: scheduleData.location?._id,
        customerId: scheduleData?.customer?._id || coreAppointment?.customer?._id || null,
        barberId: scheduleData.barber?._id,
        requestedBarberId: scheduleData?.requestedBarber?._id,
        anyProfessional: scheduleData?.anyProfessional,
        services: scheduleData.services,
        selectedDate: scheduleData.dateTime?.dateText,
        startTime: scheduleData.dateTime?.startTime,
        endTime: scheduleData.dateTime?.endTime,
        startTimeText: scheduleData.dateTime?.startTimeText,
        endTimeText: scheduleData.dateTime?.endTimeText,
        durationMin: scheduleData?.durationMin,
        payment: scheduleData?.payment,
        type: isWalkin ? 'Walkin' : 'Appointment',
        agreedToCancelPolicy: scheduleData?.agreedToCancelPolicy,
      })

      if (response.status === 200) {
        showToastMessage('Appointment created successfully!', 'success')
        stepNext()
      } else {
        showToastMessage('Sorry, there was an issue. Please try again in a minute.', 'danger')
        setErrorMessage('Sorry there was an issue. Please try again in a minute.')
      }
      setSubmitButtonDisabled(false)
    } catch (error) {
      setErrorMessage('Sorry there was an issue. Please try again in a minute.')
      showToastMessage('Sorry, there was an issue. Please try again in a minute.', 'danger')
    } finally {
      setSubmitButtonDisabled(false)
    }
  }

  const showToastMessage = (message, color = 'success') => {
    setToast({ isOpen: true, message, color })
  }

  function handleTipClick(value) {
    updateScheduleData('payment', { ...scheduleData.payment, ...{ tipPercentage: value } })
  }

  return (
    <React.Fragment>
      <div style={{ marginLeft: 14, marginRight: 14 }}>
        {isWalkin ? (
          <>
            <h2>Reserve Your Spot In Line</h2>
            <ServicesProductsSummary mode='create' setStep={setStep} appointment={coreAppointment} />
            <br />
          </>
        ) : (
          <>
            <div
              style={{
                width: '100%',
                height: '100%',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'center',
                gap: 32,
                display: 'inline-flex',
              }}
            >
              <div
                style={{
                  alignSelf: 'stretch',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  gap: 28,
                  display: 'flex',
                }}
              >
                <div
                  style={{
                    alignSelf: 'stretch',
                    paddingLeft: 15,
                    paddingRight: 15,
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    gap: 16,
                    display: 'flex',
                  }}
                >
                  <div style={reviewOrderStyles.containerStyle}>
                    <div style={reviewOrderStyles.titleStyle}>Lets Make Sure Everything Looks Right</div>
                    <div style={reviewOrderStyles.subContainerStyle}>
                      <div style={reviewOrderStyles.innerContainerStyle}>
                        <div style={reviewOrderStyles.innerContentStyle}>
                          <div style={reviewOrderStyles.textContainerStyle}>
                            <div style={reviewOrderStyles.textStyle}>
                              <div style={reviewOrderStyles.headingStyle}>Appointment Details</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      alignSelf: 'stretch',
                      flexDirection: 'column',
                      justifyContent: 'flex-start',
                      alignItems: 'flex-start',
                      gap: 24,
                      display: 'flex',
                    }}
                  >
                    <div style={{ width: '100%', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', display: 'flex' }}>
                      <BarberSummary setStep={setStep} appointment={coreAppointment || scheduleData} businessData={businessData} />
                      <ClientInfoSummary
                        choosecustomer={choosecustomer}
                        setStep={setStep}
                        appointment={coreAppointment?.customer ? coreAppointment : scheduleData?.customer ? scheduleData : {}}
                      />
                      <DateTimeSummary setStep={setStep} appointment={coreAppointment || scheduleData} />
                      <LocationSummary setStep={setStep} appointment={coreAppointment || scheduleData} />
                    </div>
                  </div>
                </div>
                <div style={{ width: 390, height: 6, justifyContent: 'center', alignItems: 'center', display: 'inline-flex' }}>
                  <div style={{ width: 390, height: 6, background: '#F3F3F3' }} />
                </div>
              </div>
              <div style={{ alignSelf: 'stretch', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', display: 'flex' }}>
                <div
                  style={{
                    alignSelf: 'stretch',
                    paddingBottom: 12,
                    paddingLeft: 12,
                    paddingRight: 12,
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    gap: 16,
                    display: 'flex',
                  }}
                >
                  <ServicesProductsSummary mode='create' setStep={setStep} appointment={coreAppointment} />
                </div>
              </div>
            </div>
          </>
        )}

        <IonButton disabled={submitButtonDisabled} onClick={handleBookAndPay} expand='block'>
          {submitButtonDisabled ? <IonSpinner name='dots'></IonSpinner> : `${isWalkin ? 'Check In Now' : 'Create Appointment'}`}
        </IonButton>
      </div>

      <IonToast
        position='top'
        isOpen={toast.isOpen}
        onDidDismiss={() => setToast({ ...toast, isOpen: false })}
        message={toast.message}
        color={toast.color}
        duration={2000}
      />
    </React.Fragment>
  )
}
