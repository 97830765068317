import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { IonButton, IonIcon } from '@ionic/react'
import { colors } from '../theme/colors'
import { checkmarkCircleOutline } from 'ionicons/icons'
import { stepSucessStyles } from './Styles/StepSuccessStyles'

// Components
export default function StepSuccess({ isSubscribed, scheduleData, stepNext, getAppointments, isWalkin, step, trackStep, setSubscriptionReferrer }) {
  const history = useHistory()

  useEffect(() => {
    getAppointments()
  }, [])

  useEffect(() => {
    trackStep('Success', step, scheduleData)
  }, [])

  function complete() {
    stepNext()
  }

  return (
    <div style={stepSucessStyles.successContainerStyle}>
      <div style={stepSucessStyles.successContentStyle}>
        <div style={{ ...stepSucessStyles.successMessageStyle, justifyContent: 'center', alignItems: 'center' }}>
          <IonIcon style={{ color: colors.green50, width: 60, height: 60 }} icon={checkmarkCircleOutline} />
        </div>
        <div style={stepSucessStyles.successTextStyle}>Success! Thanks for {`${isWalkin ? 'Checking in' : 'Booking'}`}!</div>
      </div>
      <div style={stepSucessStyles.secondaryTextStyle}>We appreciate your business. Please come back soon.</div>
      <IonButton expand='block' style={stepSucessStyles.buttonStyle} onClick={complete}>
        Done
      </IonButton>
    </div>
  )
}
