import { IonItem, IonLabel, IonList, IonRouterLink } from '@ionic/react'

export default function ResultsList({ corporateDeals = [] }) {
  return (
    <>
      <h6 style={{ color: 'grey', marginLeft: 20 }}>Corporate Deals</h6>
      <IonList inset={true} mode='ios'>
        {corporateDeals?.map(row => (
          <IonItem
            button
            detail={true}
            routerLink={`/core/corporateDeals/${row._id}`}
            routerDirection='forward'
            key={row._id}
          >
            <IonLabel>
              <h2 style={{ fontWeight: '600', color: 'black' }}>{row.name}</h2>
            </IonLabel>
          </IonItem>
        ))}
      </IonList>
    </>
  )
}
