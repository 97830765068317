// Import necessary modules and utilities
import { useEffect, useState } from 'react'
import { IonCard, IonSpinner } from '@ionic/react'
import Chart from 'react-apexcharts'
import axios from 'axios'
import { getMonthRange } from '../Utils/GetDates'
import Picker from '../commonComponents/Picker'
import GetPickerMonths from '../Utils/GetPickerMonths'
import { GetXAxisMonths } from '../Utils/GetXAxisMonths'
import { getAllDaysInCurrentMonth, getDaysInCurrentMonth } from '../utils'

export default function LifeTimeValueOfCustomer({ refId }) {
  // Initialize state variables and constants
  const pickerItems = GetPickerMonths()
  let initialNumOfMonths = 6
  const [selectedOption, setSelectedOption] = useState(initialNumOfMonths - 1)
  const [dataLoading, setDataLoading] = useState(true)
  const [selectedIndex, setSelectedIndex] = useState(0)

  const [options, setOptions] = useState({
    chart: {
      toolbar: {
        show: false,
      },
    },
    grid: {
      borderColor: '#fff',
      xaxis: {
        lines: {
          show: false, // This will hide the horizontal grid lines
        },
      },
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          return `$${value}` // Add formatting to display values with $ sign
        },
      },
    },
    stroke: {
      width: 2, // Set your desired stroke width here
      curve: 'smooth',
    },
    xaxis: {
      tickAmount: 5,

      categories: [],
    },
  })
  const [series, setSeries] = useState([])

  // Function to fetch data based on reference ID, start date, end date, and number of months
  const getDataBasedOnRefId = async (startDate, endDate, noOfMonths) => {
    try {
      // Make API call to get report data
      const response = await axios.get(`/customerAcquisition/reports/lifetimevalue?fromDate=${startDate}&toDate=${endDate}&referenceId=${refId}`)
      //added comment
      // Set loading state to false and process response data if status is 200
      setDataLoading(false)
      if (response.status === 200) {
        // Extract x-axis categories based on unique months
        const data = response?.data
        const categoriesResponse = GetXAxisMonths(noOfMonths)
        const daysInCurrentMonth = getDaysInCurrentMonth()

        const months = categoriesResponse.monthNumbers

        // Create series data from the provided API data
        const series = [
          { name: response?.data?.referenceName, data: [] },
          { name: 'All Sources', data: [] },
        ]
        series.forEach((seriesItem) => {
          seriesItem.data = noOfMonths === 0 || noOfMonths === 1 ? Array(daysInCurrentMonth).fill(0) : Array(months.length).fill(0)
        })

        // Update series data with fetched API data
        data.requestedSource.forEach((item) => {
          if (noOfMonths === 0 || noOfMonths === 1) {
            const dayIndex = item.day - 1
            series[0].data[dayIndex] = item.averageLifetimeValue
          } else {
            const paddedMonth = String(item.month).padStart(2, '0') // Padding the month number
            const lastTwoDigitsOfYear = item.year % 100
            const monthYear = paddedMonth + '-' + lastTwoDigitsOfYear
            const index = months.findIndex((month) => month === monthYear)
            if (index !== -1) {
              series[0].data[index] = item.averageLifetimeValue
            }
          }
        })

        data.allSources.forEach((item) => {
          if (noOfMonths === 0 || noOfMonths === 1) {
            const dayIndex = item.day - 1
            series[1].data[dayIndex] = item.averageLifetimeValue
          } else {
            const paddedMonth = String(item.month).padStart(2, '0') // Padding the month number
            const lastTwoDigitsOfYear = item.year % 100
            const monthYear = paddedMonth + '-' + lastTwoDigitsOfYear
            const index = months.findIndex((month) => month === monthYear)
            if (index !== -1) {
              series[1].data[index] = item.averageLifetimeValue
            }
          }
        })

        // Update state with processed series and categories
        setSeries(series)
        setOptions((prevOptions) => ({
          ...prevOptions,
          xaxis: {
            ...prevOptions.xaxis,
            categories: noOfMonths === 0 || noOfMonths === 1 ? getAllDaysInCurrentMonth(noOfMonths) : categoriesResponse.categories,
          },
        }))
      }
    } catch (error) {
      console.error('Failed to load data.', error) // Log the error for debugging

      // Handle errors if any
    }
  }

  // Function to handle the selected index change
  const handleSelectedIndex = (indexValue) => {
    const data = getMonthRange(indexValue)

    const selectedValue = pickerItems.find((item, index) => index === indexValue)
    if (selectedOption !== selectedValue.text) {
      setDataLoading(true)
      setSelectedOption(selectedValue.text)
      getDataBasedOnRefId(data.startDate, data.endDate, indexValue)
    }
  }
  // Initial data fetching on component mount
  useEffect(() => {
    const data = getMonthRange(initialNumOfMonths)
    getDataBasedOnRefId(data.startDate, data.endDate, initialNumOfMonths)
  }, [])

  return (
    <IonCard className='flat' style={{ padding: '1rem' }}>
      <h6 style={{ margin: 0, color: 'black', fontWeight: 'bold' }}>Life Time Value Of Customer</h6>

      <div style={{ margin: '1rem 0', marginTop: 8 }}>
        <Picker options={pickerItems} selectedItemIndex={initialNumOfMonths} selectedItem={selectedOption} handleSelectedIndex={handleSelectedIndex} />
      </div>
      {dataLoading && <IonSpinner name='dots' />}
      {!dataLoading && <Chart options={options} series={series} type='line' />}
    </IonCard>
  )
}
