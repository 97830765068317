import { IonCol, IonGrid, IonIcon, IonLabel, IonRow } from '@ionic/react'
import { footstepsOutline, timeOutline } from 'ionicons/icons'

export default function VerticalEvent({ event }) {
  return (
    <IonGrid style={{ width: '100%', padding: 0 }}>
      <IonRow style={{ width: '100%' }}>
        <IonLabel
          style={{
            fontSize: 12,
            fontWeight: 'bold',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {event?.customer?.firstName} {event?.customer?.lastName}
        </IonLabel>
      </IonRow>
      <IonRow
        style={{
          width: '100%',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        }}
      >
        <IonCol style={{ display: 'flex', margin: 0, padding: 0, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
          <IonLabel style={{ textAlign: 'left', fontSize: 10, width: '100%', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
            {event?.services?.map((service, index) => {
              return (
                <>
                  {service.name}
                  {index < event.services.length - 1 && ', '}
                </>
              )
            })}
          </IonLabel>
        </IonCol>
        {event.durationMin <= 30 && (
          <IonCol style={{ margin: 0, padding: 0, display: 'flex' }} size='auto'>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <IonIcon style={{ height: 8, width: 8, marginRight: 5 }} icon={timeOutline} />
              <IonLabel style={{ fontSize: 8, marginRight: 5 }}>{event.durationMin} min</IonLabel>

              {event.type == 'Walkin' && <IonIcon style={{ height: 10, width: 10 }} icon={footstepsOutline} />}
            </div>
          </IonCol>
        )}
      </IonRow>
      {event.durationMin > 30 && (
        <IonRow
          style={{
            position: 'absolute',
            bottom: '3px',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <IonIcon style={{ height: 8, width: 8, marginRight: '5px' }} icon={timeOutline} />
            <IonLabel style={{ fontSize: 8, marginRight: 5 }}>{event.durationMin} min</IonLabel>
            {event.type == 'Walkin' && <IonIcon style={{ height: 10, width: 10 }} icon={footstepsOutline} />}
          </div>
        </IonRow>
      )}
    </IonGrid>
  )
}
