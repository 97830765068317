import { useState, useEffect } from 'react'
import {
  IonItem,
  IonLabel,
  IonAccordion,
  IonAccordionGroup,
  IonSegment,
  IonSegmentButton,
  IonList,
  IonModal,
  IonContent,
  IonCard,
  IonButton,
} from '@ionic/react'
import StandardContainer from '../commonComponents/StandardContainer'
import StatsBlock from './StatsBlock'
import {
  formatDate,
  generateMonthYearArray,
  getMonthFromDate,
  getFormattedMediumDate,
} from '../utils'
import { DateRange } from 'react-date-range'
import 'react-date-range/dist/styles.css' // main css file
import 'react-date-range/dist/theme/default.css' // theme css file
import CommonHeader from '../commonComponents/CommonHeader'
export default function EmployeeStats({ employee }) {
  const [viewMode, setViewMode] = useState('payPeriod')
  const [modalOpen, setModalOpen] = useState(false)
  const [getStatsData, setGetStats] = useState(false)
  const [customRange, setCustomRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ])

  const openModal = () => {
    setModalOpen(true)
  }
  const closeModal = () => {
    setGetStats(false)
    setModalOpen(false)
  }
  const handleSelection = range => {
    setCustomRange([range.selection])
  }
  const formatDate = date => {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear()

    if (month.length < 2) month = '0' + month
    if (day.length < 2) day = '0' + day

    return [year, month, day].join('-')
  }
  const getStats = () => {
    const startDate = formatDate(customRange[0].startDate)
    const endDate = formatDate(customRange[0].endDate)
    closeModal()
    setGetStats(true)
  }
  useEffect(() => {
    setCustomRange([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
      },
    ])
  }, [employee])
  if (!employee) return 'loading...'

  return (
    <StandardContainer>
      <div style={{ padding: 16 }}>
        <IonSegment value={viewMode} onIonChange={e => setViewMode(e.detail.value)}>
          <IonSegmentButton value='payPeriod'>
            <IonLabel>Pay Period</IonLabel>
          </IonSegmentButton>
          <IonSegmentButton value='monthly'>
            <IonLabel>Monthly</IonLabel>
          </IonSegmentButton>
          <IonSegmentButton value='custom'>
            <IonLabel>Custom</IonLabel>
          </IonSegmentButton>
        </IonSegment>
      </div>
      {/* <h4 style={{ padding: 16, paddingBottom: 0 }}>{getCurrentMonth()} Stats</h4>
      <StatsBlock employee={employee} month={month} year={year} /> */}
      {viewMode == 'custom' ? (
        
        <>
        <div style={{marginLeft:20, marginBottom:-10, color:'grey'}}>Choose Date Range</div>
          <IonList inset={true} mode='ios'>
          
            <IonItem onClick={openModal} detail={true}>
              
              <IonLabel style={{ display: 'flex',  alignItems: 'center' }}>
                {getFormattedMediumDate(customRange[0].startDate)} -{' '}
                {getFormattedMediumDate(customRange[0].endDate)}
              </IonLabel>
            </IonItem>
          </IonList>
          <StatsBlock
            startDate={customRange[0].startDate}
            endDate={customRange[0].endDate}
            employee={employee}
            shouldLoad={getStatsData}
          />
          <IonModal isOpen={modalOpen} onDidDismiss={closeModal}>
            <CommonHeader
              closeButton={true}
              closeModal={closeModal}
              title={'Custom Range Stats'}
            ></CommonHeader>
            <IonContent className='regularBackground'>
              <StandardContainer>
                <IonCard
                  style={{
                    flexDirection: 'column',
                    padding: '1rem',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <DateRange
                    onChange={item => handleSelection(item)}
                    moveRangeOnFirstSelection={false}
                    ranges={customRange}
                  />
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <IonButton onClick={getStats}>Get Stats</IonButton>
                  </div>
                </IonCard>
              </StandardContainer>
            </IonContent>
          </IonModal>
        </>
      ) : (
        <Historical employee={employee} viewMode={viewMode} />
      )}
    </StandardContainer>
  )
}

function Historical({ employee, viewMode }) {
  const [hist, setHist] = useState([])
  const [expanded, setExpanded] = useState([])

  useEffect(() => {
    setHist([]) //Clear the historical array before generating a new one.
    const hist = generateMonthYearArray(employee?.createdDate, viewMode)

    setHist(hist)
  }, [viewMode])

  const accordionGroupChange = event => {
    const expandedItem = event.detail.value
    setExpanded(expandedItem)
  }

  return (
    <div>
      <h4 style={{ padding: 16, paddingBottom: 0 }}>
        {viewMode == 'monthly'
          ? getMonthFromDate(hist[0]?.startDate)
          : `${formatDate(hist[0]?.startDate)} - ${formatDate(hist[0]?.endDate)}`}
      </h4>
      <StatsBlock
        startDate={hist?.[0]?.startDate}
        endDate={hist?.[0]?.endDate}
        employee={employee}
        shouldLoad={true}
      />
      <IonAccordionGroup multiple={true} onIonChange={accordionGroupChange} key={viewMode}>
        {hist?.map((i, index) => {
          if (index == 0) {
            return null
          }
          const key =
            viewMode == 'monthly'
              ? getMonthFromDate(i.startDate)
              : `${formatDate(i.startDate)} - ${formatDate(i?.endDate)}`
          return (
            <IonAccordion value={key} key={key + index}>
              <IonItem slot='header' color='light'>
                <IonLabel>
                  <strong>{key}</strong>
                </IonLabel>
              </IonItem>
              <div slot='content'>
                {/* {expanded.includes(key) ? ( */}
                <StatsBlock
                  startDate={i.startDate}
                  endDate={i.endDate}
                  employee={employee}
                  shouldLoad={expanded.includes(key)}
                />
                {/* ) : null} */}
              </div>
            </IonAccordion>
          )
        })}
      </IonAccordionGroup>
    </div>
  )
}
