import { useState } from 'react'
import slugify from 'slugify'
import CustomInput from '../../../SetupBusiness/CustomInput'
import ButtonPanel from '../buttonPanel'
import FlowError from '../error'
import axios from 'axios'
import StripeOnboardingButton from '../../StripeOnboardingButton'
import { colors } from '../../../theme/colors'

const BillingForm = ({ data, flowIndex, onBack, onNext, onCancel, history, locationId, locationData }) => {
  const [businessName, setBusinessName] = useState('')
  const [domainName, setDomainName] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState('')

  const handleNext = async () => {
    onNext()
  }

  return (
    <>
      <p>Next let's setup your billing information. This will allow you to accept payments from your customers. We redirect you to our secure partner Stripe to complete the process.</p>
      <br></br>
      {locationData.map((location) => (
        <div key={location.id}>
          <h3 style={{fontWeight: 'bold'}}>{location.name}</h3>
          <StripeOnboardingButton locationId={location._id} />
        </div>
      ))}
      <br />
      <FlowError message={error} />
      <ButtonPanel
        data={data}
        flowIndex={flowIndex}
        onBack={onBack}
        onNext={handleNext}
        onCancel={onCancel}
        history={history}
        loading={isLoading}
      />
    </>
  )
}

export default BillingForm
