import { useState, useEffect, useContext } from 'react'
import GeneralDetails from './details_general'
import axios from 'axios'
import { AuthContext } from '../auth'
import { useHistory } from 'react-router-dom'
import { IonCard, IonContent, IonModal, IonPage, IonSpinner, IonToast } from '@ionic/react'
import StandardContainer from '../commonComponents/StandardContainer'
import getEntitlementsByBusinessAndLocation from '../Utils/getEntitlementsByBusinessAndLocation'
import CommonHeader from '../commonComponents/CommonHeader'
import { checkmark, closeCircleOutline, trashOutline } from 'ionicons/icons'
import BusinessPermissions from './BusinessPermissions'
import LocationPermissions from './LocationPermissions'
import ConfirmationModal from '../commonComponents/ConfirmationModal'
import EachLocationPermission from './EachLocationPermission'

export default function Details({ match }) {
  const user_id = match?.params?.id
  const [user, setUser] = useState()
  const [userTemp, setUserTemp] = useState()
  const [userChanged, setUserChanged] = useState(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [enableDisableModalOpen, setEnableDisableModalOpen] = useState(false)
  const [deletingUser, setDeletingUser] = useState(false)
  const [enablingDisablingUser, setEnablingDisablingUser] = useState(false)
  const { businessData, locationId } = useContext(AuthContext)
  const history = useHistory()

  // Toast state
  const [toast, setToast] = useState({ isOpen: false, message: '', color: '' })

  const showToastMessage = (message, color) => {
    setToast({ isOpen: true, message, color })
  }

  const getUser = async () => {
    try {
      let response = await axios.get(`/user/byUserId?id=${user_id}`)
      let userData = { ...response.data }
      const entitlements = getEntitlementsByBusinessAndLocation(businessData?._id, locationId, response?.data?.businesses)

      userData['entitlements'] = entitlements
      setUser(userData)
      setUserTemp(userData)
    } catch (error) {
      console.error('Failed to fetch user data.', error) // Log the error for debugging
    }
  }

  useEffect(() => {
    getUser()
  }, [user_id])

  const updateUser = (value) => {
    setUserTemp(value)
    setUserChanged(true)
  }

  const openDeleteModal = () => {
    setDeleteModalOpen(true)
  }

  const closeDeleteModal = () => {
    setDeleteModalOpen(false)
  }

  const deleteUser = async () => {
    setDeletingUser(true)
    try {
      const deleteResponse = await axios.delete(`/user?id=${user_id}&firebaseId=${user?.firebaseId}`)
      if (deleteResponse.status === 200) {
        showToastMessage('User deleted successfully.', 'success')
        setDeleteModalOpen(false)
        history.goBack()
      }
    } catch (error) {
      showToastMessage('Failed to delete user.', 'danger')
    } finally {
      setDeletingUser(false)
    }
  }

  const enableDisableUser = async () => {
    setEnablingDisablingUser(true)
    const status = userTemp.status === 'Disabled' ? 'enable' : 'disable'
    const enableOrDisableUserUrl = `/user/${status}?id=${user_id}&firebaseId=${user?.firebaseId}`
    try {
      const enableDisableResponse = await axios.patch(enableOrDisableUserUrl)
      if (enableDisableResponse.message === 'Success') {
        showToastMessage(`User ${status === 'enable' ? 'enabled' : 'disabled'} successfully.`, 'success')
        setEnableDisableModalOpen(false)
        history.goBack()
      }
    } catch (error) {
      showToastMessage(`Failed to ${status} user.`, 'danger')
    } finally {
      setEnablingDisablingUser(false)
    }
  }

  const handleRefreshUser = async () => {
    await getUser()
  }

  const openEnableDisableModal = () => {
    setEnableDisableModalOpen(true)
  }

  const closeDisableModal = () => {
    setEnableDisableModalOpen(false)
  }

  if (!user) return <IonSpinner name='dots' />

  const menuItems = [
    {
      label: 'Remove User',
      icon: trashOutline,
      onClick: openDeleteModal,
    },
    {
      label: userTemp.status === 'Disabled' ? 'Enable Account' : 'Disable Account',
      icon: userTemp.status === 'Disabled' ? checkmark : closeCircleOutline,
      onClick: openEnableDisableModal,
    },
  ]

  return (
    <IonPage>
      <CommonHeader backIcon={true} title={'Permissions'} actionIcon={true} menuItems={menuItems} />
      <IonContent className='regularBackground'>
        <StandardContainer>
          <IonCard style={{ padding: '10px' }}>
            <GeneralDetails user={userTemp} updateUser={updateUser} />
          </IonCard>
          <IonCard style={{ padding: '10px' }}>
            <EachLocationPermission user={userTemp} refreshUser={handleRefreshUser} />
          </IonCard>
          {/* <IonCard style={{ padding: '10px' }}>
            <BusinessPermissions user={userTemp} refreshUser={handleRefreshUser} />
          </IonCard>
          <IonCard style={{ padding: '10px' }}>
            <LocationPermissions user={userTemp} refreshUser={handleRefreshUser} />
          </IonCard> */}
          <IonModal isOpen={deleteModalOpen}>
            <ConfirmationModal
              loading={deletingUser}
              message={'Are You Sure You want to delete this user?'}
              cancel={closeDeleteModal}
              save={deleteUser}
              saveText={'Delete'}
            />
          </IonModal>
          <IonModal isOpen={enableDisableModalOpen}>
            <ConfirmationModal
              loading={enablingDisablingUser}
              message={userTemp.status === 'Disabled' ? 'Are You Sure You want to enable this user?' : 'Are You Sure You want to disable this user?'}
              cancel={closeDisableModal}
              save={enableDisableUser}
              saveText={userTemp.status === 'Disabled' ? 'Enable' : 'Disable'}
            />
          </IonModal>
          <IonToast
            position='top'
            isOpen={toast.isOpen}
            onDidDismiss={() => setToast({ ...toast, isOpen: false })}
            message={toast.message}
            color={toast.color}
            duration={2000}
          />
        </StandardContainer>
      </IonContent>
    </IonPage>
  )
}
