import { IonItem, IonList } from '@ionic/react'
import StarRating from './StarRating'

export default function InternalRatingAndReviews({ ratingReport }) {
  return (
    <IonList mode='ios' inset={true}>
      <h5 style={{ margin: '1rem 0 0 1rem' }}>Internal Rating</h5>
      <IonItem>
        {ratingReport?.rating && <StarRating rating={ratingReport?.rating} />}
        <div
          style={{
            marginBottom: '1rem',
            width: '30%',
            justifyContent: 'center',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <h1 style={{ fontSize: '3rem', textAlign: 'center', margin: '0' }}>
            {ratingReport?.rating}
          </h1>
          <h6 style={{ margin: '0' }}>{ratingReport?.reviews} Reviews</h6>
        </div>
      </IonItem>
    </IonList>
  )
}
