export const GetReviewStylesBasedOnStatus = reviewStatus => {
  const reviewStatusStyles =
    reviewStatus == 'disabled'
      ? {
        color: '#606060',
        border: ' 2px solid #606060',
        backgroundColor: '#dedcdc',
      }
      : reviewStatus == 'pending'
        ? {
          color: 'darkorange',
          border: ' 2px solid darkorange',
          backgroundColor: '#ffe5b5',
        }
        : reviewStatus == 'error'
          ? {
            color: 'red',
            border: ' 2px solid red',
            backgroundColor: '#ffd4d4',
          }
          : {
            color: '#007a00',
            border: ' 2px solid #007a00',
            backgroundColor: '#a8daa8',
          }
  return {
    ...reviewStatusStyles,
    ...{
      padding: 4,
      borderRadius: 6,
      fontWeight: 'bold',
      fontSize:12,
      width: '5rem',
      display: 'flex',
      justifyContent: 'center',
    }
  }
}
