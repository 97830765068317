import { IonLabel, IonGrid, IonRow, IonCol, IonIcon, IonModal, IonContent, IonItem, IonSelect, IonSelectOption, IonCheckbox } from '@ionic/react'
import { colors } from '../theme/colors'
import { useState } from 'react'
import axios from 'axios'
import CommonHeader from '../commonComponents/CommonHeader'
import { addOutline } from 'ionicons/icons'
import { fontSizes } from '../theme/typography'

export default function MembershipDetails({ subscriptionPackages, service, getService, showToastMessage }) {
  const [editSection, setEditSection] = useState(null) // Tracks which modal to show
  const [editedValues, setEditedValues] = useState([]) // Local state for modal edits

  const filteredSubscriptionPackages = subscriptionPackages?.filter((item) => service?.includedInSubscriptions?.includes(item._id))

  const updateService = async (data) => {
    try {
      await axios.patch(`/service?id=${service?._id}`, data)
      await getService()
      showToastMessage('Service updated successfully!', 'success')
    } catch (error) {
      showToastMessage('Failed to update service.', 'danger')
    }
  }

  const handleSave = () => {
    updateService({ ...service, includedInSubscriptions: editedValues })
    setEditSection(null) // Close the modal after saving
  }
  const handleEditedValues = (id) => {
    setEditedValues((prevValues) => {
      if (prevValues.includes(id)) {
        // If the ID exists, remove it from the array
        return prevValues.filter((value) => value !== id)
      } else {
        // If the ID does not exist, add it to the array
        return [...prevValues, id]
      }
    })
  }

  const isSaveDisabled = () => {
    // Check if the editedValues are the same as the original values
    const originalValues = service?.includedInSubscriptions || []
    return editedValues?.length === originalValues?.length && editedValues?.every((value) => originalValues?.includes(value))
  }

  return (
    <>
      <IonGrid style={{ padding: 20, paddingBottom: 0 }}>
        <IonRow style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <IonCol>
            <IonLabel style={{ color: colors.black50, fontWeight: 'bold' }}>Memberships Included</IonLabel>
          </IonCol>
          {service?.includedInSubscriptions?.length > 0 && (
            <IonCol size='auto'>
              <IonLabel
                style={{ cursor: 'pointer', fontSize: '18px', color: colors.primaryBlue }}
                onClick={() => {
                  setEditedValues(service.includedInSubscriptions) // Populate modal with current values
                  setEditSection('memberships')
                }}
              >
                Edit
              </IonLabel>
            </IonCol>
          )}
        </IonRow>
        {service?.includedInSubscriptions?.length === 0 || !service?.includedInSubscriptions ? (
          <IonRow
            onClick={() => {
              setEditedValues(service.includedInSubscriptions) // Populate modal with current values
              setEditSection('memberships')
            }}
            style={{ margin: '20px  0' }}
          >
            <IonRow
              style={{
                padding: '20px 10px',
                border: `1px solid ${colors.black30}`,
                width: '100%',
                borderRadius: 10,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <IonIcon style={{ height: 25, width: 25, color: colors.black30, marginRight: 10 }} icon={addOutline} />
              <IonLabel> Add to a Membership</IonLabel>
            </IonRow>
          </IonRow>
        ) : (
          <IonRow>
            {filteredSubscriptionPackages?.map((item, index) => {
              return (
                <IonLabel
                  key={index}
                  style={{
                    fontSize: fontSizes.size14,
                    margin: 5,
                    backgroundColor: colors?.primaryBlue,
                    borderRadius: 20,
                    padding: '5px 10px',
                    color: 'white',
                  }}
                >
                  {item.name}
                </IonLabel>
              )
            })}
          </IonRow>
        )}
      </IonGrid>

      {/* Edit Modal */}
      <IonModal isOpen={editSection === 'memberships'} onDidDismiss={() => setEditSection(null)}>
        <IonContent>
          <CommonHeader
            title={'Update Memberships'}
            saveDisabled={isSaveDisabled()} // Pass saveDisabled as a prop
            saveButton={true}
            closeButton={true}
            closeModal={() => setEditSection(null)}
            handleSaveButton={handleSave}
          />
          {console.log(subscriptionPackages, 'subscriptionPackages')}
          <IonGrid style={{ padding: 20 }}>
            <IonRow>
              <IonCol>
                {subscriptionPackages?.map((packageItem) => {
                  return (
                    <IonItem onClick={() => handleEditedValues(packageItem._id)} key={packageItem._id}>
                      <IonCheckbox
                        mode='ios'
                        slot='start'
                        checked={editedValues.some((selectedItem) => selectedItem === packageItem?._id)} // Reflects the correct state
                      />
                      <IonLabel>{packageItem.name}</IonLabel>
                    </IonItem>
                  )
                })}
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>
      </IonModal>
    </>
  )
}
