import { useContext, useState } from 'react'
import { IonCard, IonContent, IonInput, IonItem, IonLabel, IonList, IonPage, IonToast } from '@ionic/react'
import CommonHeader from '../commonComponents/CommonHeader'
import StandardContainer from '../commonComponents/StandardContainer'
import SubscriptionOptionsList from './SubscriptionOptionsList'
import { AuthContext } from '../auth'
import axios from 'axios'
import { useHistory } from 'react-router'

const CreateSubscription = () => {
  const history = useHistory()
  const [subscriptionName, setSubscriptionName] = useState('')
  const [subscriptionDesc, setSubscriptionDesc] = useState('')
  const [pricingOptions, setPricingOptions] = useState([])
  const [loading, setLoading] = useState(false)
  const { businessData } = useContext(AuthContext)
  const [toast, setToast] = useState({ isOpen: false, message: '', color: '' })

  const addUpdatePricing = (input) => {}
  const addUpdatePricingToList = (mode, option, index) => {
    if (mode === 'add') {
      let pricingOptionsTemp = [...pricingOptions]
      pricingOptionsTemp.push(option)
      setPricingOptions(pricingOptionsTemp)
    } else {
      let pricingOptionsTemp = [...pricingOptions]
      pricingOptionsTemp[index] = option
      setPricingOptions(pricingOptionsTemp)
    }
  }

  const showToastMessage = (message, color = 'success') => {
    setToast({ isOpen: true, message, color })
  }

  const handleCreateSubscription = async () => {
    const pricingOptionsTemp = [...pricingOptions]
    pricingOptionsTemp.map((item, index) => {
      item.active = true
    })

    const subsInput = {
      name: subscriptionName,
      businessId: businessData?._id,
      description: subscriptionDesc,
      prices: pricingOptionsTemp,
      enabled: true,
    }

    setLoading(true)
    try {
      const createSubscriptionResponse = await axios.post('/subscriptionPackage', subsInput)
      if (createSubscriptionResponse.status === 200) {
        showToastMessage('Subscription package created successfully!', 'success')
        history.goBack()
      }
    } catch (error) {
      showToastMessage('Failed to create subscription package. Please try again.', 'danger')
    } finally {
      setLoading(false)
    }
  }

  return (
    <IonPage id='main-content'>
      <CommonHeader
        title={'Create Subscription Package'}
        backIcon={true}
        saveButton={true}
        handleSaveButton={handleCreateSubscription}
        loading={loading}
        saveDisabled={!subscriptionName || !subscriptionDesc || pricingOptions.length === 0}
      />
      <IonContent>
        <StandardContainer>
          <IonCard>
            <IonList mode='ios'>
              <h5 style={{ fontWeight: 'bold', margin: ' 1rem 1rem' }}>General Details</h5>
              <IonItem>
                <IonLabel position='stacked'>Name</IonLabel>
                <IonInput
                  onIonInput={(e) => setSubscriptionName(e.detail.value)}
                  placeholder='Subscription Name'
                  type='text'
                  value={subscriptionName}
                ></IonInput>
              </IonItem>

              <IonItem>
                <IonLabel position='stacked'>Description</IonLabel>
                <IonInput onIonInput={(e) => setSubscriptionDesc(e.detail.value)} placeholder='Description' type='text' value={subscriptionDesc} />
              </IonItem>
              {renderSubscriptionOptions()}
            </IonList>
          </IonCard>
        </StandardContainer>
      </IonContent>

      <IonToast
        position='top'
        isOpen={toast.isOpen}
        onDidDismiss={() => setToast({ ...toast, isOpen: false })}
        message={toast.message}
        color={toast.color}
        duration={2000}
      />
    </IonPage>
  )

  function renderSubscriptionOptions() {
    return (
      <SubscriptionOptionsList
        subscriptionPackage={{}}
        addOption={true}
        addUpdatePricingToList={addUpdatePricingToList}
        pricingOptions={pricingOptions}
        addUpdatePricing={addUpdatePricing}
      />
    )
  }
}

export default CreateSubscription
