import { useEffect, useRef, useState, useCallback, useMemo, useContext } from 'react'
import { IonModal, IonContent, IonIcon, IonSpinner, IonToast, IonLabel } from '@ionic/react'
import './ResizableModal.css'
import StandardContainer from '../../commonComponents/StandardContainer'
import { calendarOutline, star, timeOutline } from 'ionicons/icons'
import { colors } from '../../theme/colors'
import { formatDateWithWeekDay } from '../../utils'
import ChangeEmployeeModal from '../../appointments/details/changeEmployeeModal'
import axios from 'axios'
import ServicesProductsSummary from './SubComponents/ServicesProductsSummary'
import AppointmentStatus from './AppointmentStatus'
import FooterActions from './SubComponents/FooterActions'
import StandardCenterCenter from '../../commonComponents/StandardCenterCenter'
import Avatar from '../../commonComponents/avatar'
import { AuthContext } from '../../auth'
import ProfessionalInfo from './SubComponents/ProfessionalInfo'
import CustomerNotes from './SubComponents/CustomerNotes'
import AppointmentDateTime from './SubComponents/AppointmentDateTime'
import SubscriptionData from './SubComponents/SubscriptionData'

const ResizableModal = ({ selectedEvent, showModal, setShowModal, closeModal }) => {
  const { businessData } = useContext(AuthContext)
  const [appointment, setAppointment] = useState(selectedEvent)
  const modalRef = useRef(null)
  const [currentBreakpoint, setCurrentBreakpoint] = useState(0.75)
  const [subscription, setSubscription] = useState(null)
  const [loadingSubscription, setLoadingSubscription] = useState(false)
  const [employeeModalOpen, setEmployeeModalOpen] = useState(false)
  const [updatingStatus, setUpdatingStatus] = useState(false)
  const [rescheduling, setReScheduling] = useState(false)
  const [loadingAppointment, setLoadingAppointment] = useState(false)
  const [notesId, setNotesId] = useState(null)
  const [notes, setNotes] = useState('')
  const [lastEditTime, setLastEditTime] = useState(null)
  const [lastEditorName, setLastEditorName] = useState(null)
  const [isNotesOpen, setNotesOpen] = useState(false)
  const [toast, setToast] = useState({ isOpen: false, message: '', color: 'success' })

  const handleBreakpointDidChange = useCallback(
    (event) => {
      const breakpoint = event.detail.breakpoint
      if (breakpoint !== currentBreakpoint) {
        setCurrentBreakpoint(breakpoint)
      }
    },
    [currentBreakpoint]
  )

  const getAppointment = useCallback(
    async (displayLoader) => {
      if (displayLoader) {
        setLoadingAppointment(true)
      }
      if (selectedEvent?._id) {
        try {
          const response = await axios.get(`/appointment_v2?id=${selectedEvent._id}`)
          setAppointment(response.data)
        } catch (error) {
          console.error('Error fetching appointment:', error)
        }
        if (displayLoader) {
          setLoadingAppointment(false)
        }
      }
    },
    [selectedEvent]
  )

  const getCustomerSubscription = useCallback(async () => {
    setLoadingSubscription(true)
    if (appointment?.customer?._id) {
      try {
        const response = await axios.get(`/subscription/byCustomerId?id=${appointment.customer._id}&source=getCustomerSubscription`)
        setSubscription(response.data)
      } catch (error) {
        console.error('Error fetching subscription:', error)
        setSubscription(null)
      } finally {
        setLoadingSubscription(false)
      }
    }
  }, [appointment?.customer?._id])
  const updateStatus = async (status) => {
    if (status == 'Scheduled') {
      setReScheduling(true)
    } else {
      setUpdatingStatus(true)
    }
    try {
      let response = await axios.patch(`/appointment_v2/updateStatus`, {
        appointmentId: appointment?._id,
        status,
      })
      await getAppointment()
      // setToast({ isOpen: true, message: 'Status updated successfully!', color: 'success' })
    } catch (error) {
      setToast({ isOpen: true, message: 'Error updating status', color: 'danger' })
    }
    if (status == 'Scheduled') {
      setReScheduling(false)
    } else {
      setUpdatingStatus(false)
    }
  }
  const fetchNotes = useCallback(async () => {
    try {
      const response = await axios.get(`/customer_v2/getNotes`, {
        params: {
          customerId: appointment?.customer?._id,
          businessId: businessData._id,
        },
      })

      setNotesId(response.data._id)
      setNotes(response.data?.notes)
      setLastEditTime(response.data?.lastEditTime)
      setLastEditorName(response.data?.lastEditorName)
    } catch (error) {
      console.error('Error fetching notes:', error)
    } finally {
      // Optionally handle any final state here
    }
  }, [appointment?.customer?._id, businessData._id])

  useEffect(() => {
    if (selectedEvent) {
      getAppointment(true)
    }
  }, [selectedEvent, getAppointment])

  useEffect(() => {
    if (appointment?.customer?._id) {
      getCustomerSubscription()
    }
  }, [appointment?.customer?._id, getCustomerSubscription, showModal])
  useEffect(() => {
    if (appointment?.customer?._id) {
      fetchNotes()
    }
  }, [appointment?.customer?._id, fetchNotes, showModal])
  // useEffect(() => {
  //   fetchNotes()
  // }, [appointment?.customer?._id, fetchNotes])

  const Services = useMemo(() => {
    const services = appointment?.services?.map((item) => item.name)
    return (
      <div
        style={{
          color: '#717171',
          fontSize: 18,
          fontFamily: 'Figtree',
          fontWeight: '500',
          lineHeight: '28px',
          wordWrap: 'break-word',
        }}
      >
        {services?.join(' , ')}
      </div>
    )
  }, [appointment])

  const CustomerName = useMemo(() => {
    return (
      <div style={{ flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 10, display: 'flex' }}>
        <div
          style={{
            width: 263,
            color: '#222222',
            fontSize: 32,
            fontFamily: 'Figtree',
            fontWeight: '600',
            lineHeight: '38.4px',
            wordWrap: 'break-word',
          }}
        >
          {appointment?.customer?.firstName} {appointment?.customer?.lastName}
        </div>
      </div>
    )
  }, [appointment])

  const handleCloseModal = () => {
    closeModal()
    setCurrentBreakpoint(0.75)
  }
  return (
    <>
      <IonModal
        isOpen={showModal}
        onDidDismiss={() => handleCloseModal()}
        ref={modalRef}
        trigger='open-modal'
        initialBreakpoint={0.75}
        breakpoints={[0.75, 1]}
        cssClass='custom-modal'
        onIonBreakpointDidChange={handleBreakpointDidChange}
      >
        <div
          style={{
            marginTop: 20,
            borderBottom: `2px solid ${colors.black20}`,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative', // Make the container relatively positioned
          }}
        >
          <h4 style={{ margin: 0 }}>Appointment Details</h4>
          <IonLabel
            color={'primary'}
            style={{
              position: 'absolute',
              right: 10, // Move the IonLabel to the extreme right
              cursor: 'pointer', // Optionally make it look clickable
            }}
            onClick={() => handleCloseModal()} // Optional if there's a close action
          >
            Close
          </IonLabel>
        </div>

        {}
        <IonContent className='ion-padding'>
          {loadingAppointment ? (
            <StandardCenterCenter>
              <IonSpinner name='dots' />
            </StandardCenterCenter>
          ) : (
            <StandardContainer>
              {currentBreakpoint === 1 ? (
                <>
                  <div
                    style={{
                      width: '100%',
                      height: '100%',
                      paddingLeft: 10,
                      paddingRight: 10,
                      flexDirection: 'column',
                      justifyContent: 'flex-start',
                      alignItems: 'flex-start',
                      gap: 32,
                      display: 'inline-flex',
                    }}
                  >
                    <div
                      style={{
                        alignSelf: 'stretch',
                        // height: 875,
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                        gap: 36,
                        display: 'flex',
                      }}
                    >
                      <div
                        style={{
                          alignSelf: 'stretch',
                          // height: 183,
                          flexDirection: 'column',
                          justifyContent: 'flex-start',
                          alignItems: 'flex-start',
                          gap: 16,
                          display: 'flex',
                        }}
                      >
                        <div style={{ alignSelf: 'stretch', justifyContent: 'center', alignItems: 'flex-start', gap: 10, display: 'inline-flex' }}>
                          <div
                            style={{
                              flex: '1 1 0',
                              flexDirection: 'column',
                              justifyContent: 'flex-start',
                              alignItems: 'flex-start',
                              gap: 16,
                              display: 'inline-flex',
                            }}
                          >
                            <div style={{ alignSelf: 'stretch', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 16, display: 'inline-flex' }}>
                              <div
                                style={{
                                  flex: '1 1 0',
                                  flexDirection: 'column',
                                  justifyContent: 'flex-start',
                                  alignItems: 'flex-start',
                                  gap: 8,
                                  display: 'inline-flex',
                                }}
                              >
                                <div
                                  style={{
                                    alignSelf: 'stretch',
                                    flexDirection: 'column',
                                    justifyContent: 'flex-start',
                                    alignItems: 'flex-start',
                                    gap: 8,
                                    display: 'flex',
                                  }}
                                >
                                  <SubscriptionData
                                    closeModal={closeModal}
                                    loadingSubscription={loadingSubscription}
                                    subscription={subscription?.subscription}
                                    appointment={appointment}
                                    getCustomerSubscription={getCustomerSubscription}
                                  />
                                  {CustomerName}
                                  {Services}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div style={{ justifyContent: 'flex-end', alignItems: 'flex-start', display: 'flex' }}>
                            <div style={{ width: 56, height: 56, position: 'relative', background: '#E9E9E9', borderRadius: 9999 }}>
                              <div
                                style={{
                                  width: 56,
                                  height: 56,
                                  left: 0,
                                  top: 0,
                                  position: 'absolute',
                                  borderRadius: 9999,
                                  border: '0.75px rgba(0, 0, 0, 0.08) solid',
                                }}
                              />
                              <div
                                style={{
                                  width: 56,
                                  left: 0,
                                  top: 13,
                                  position: 'absolute',
                                  textAlign: 'center',
                                  color: '#717171',
                                  fontSize: 22,
                                  fontFamily: 'Inter',
                                  fontWeight: '600',
                                  lineHeight: '32px',
                                  wordWrap: 'break-word',
                                }}
                              >
                                {appointment?.customer?.firstName?.slice(0, 1)}
                                {appointment?.customer?.lastName?.slice(0, 1)}
                              </div>
                            </div>
                          </div>
                        </div>
                        <AppointmentStatus appointment={appointment} />
                      </div>
                      <div
                        style={{
                          width: '100%',
                          // height: '100%',
                          flexDirection: 'column',
                          justifyContent: 'flex-start',
                          alignItems: 'flex-start',
                          gap: 8,
                          display: 'inline-flex',
                        }}
                      >
                        <div style={{ alignSelf: 'stretch', justifyContent: 'flex-start', alignItems: 'center', gap: 36, display: 'inline-flex' }}>
                          <div style={{ flex: '1 1 0', height: 32, justifyContent: 'flex-start', alignItems: 'center', gap: 36, display: 'flex' }}>
                            <div style={{ flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 10, display: 'inline-flex' }}>
                              <div
                                style={{ color: '#222222', fontSize: 22, fontFamily: 'Inter', fontWeight: '600', lineHeight: '32px', wordWrap: 'break-word' }}
                              >
                                Appointment Details
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          style={{
                            width: '100%',
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start',
                            display: 'flex',
                          }}
                        >
                          <AppointmentDateTime getAppointment={getAppointment} appointment={appointment} />
                          <ProfessionalInfo appointment={appointment} businessData={businessData} setEmployeeModalOpen={setEmployeeModalOpen} />
                          <CustomerNotes
                            isOpen={isNotesOpen}
                            setOpen={setNotesOpen}
                            customerId={appointment?.customer?._id}
                            fetchNotes={fetchNotes}
                            setNotes={setNotes}
                            notes={notes}
                            notesId={notesId}
                            lastEditTime={lastEditTime}
                            lastEditorName={lastEditorName}
                            appointment={appointment}
                          />
                        </div>
                      </div>
                      <ServicesProductsSummary appointment={appointment} hideTotal={true} getAppointment={getAppointment} />
                    </div>
                    <FooterActions
                      currentBreakpoint={currentBreakpoint}
                      rescheduling={rescheduling}
                      subscription={subscription}
                      appointment={appointment}
                      updateStatus={updateStatus}
                      updatingStatus={updatingStatus}
                      getAppointment={getAppointment}
                      closeModal={handleCloseModal}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div
                    style={{
                      width: '100%',
                      height: '100%',
                      paddingLeft: 10,
                      paddingRight: 10,
                      flexDirection: 'column',
                      justifyContent: 'flex-start',
                      alignItems: 'flex-start',
                      gap: 36,
                      display: 'inline-flex',
                    }}
                  >
                    <div
                      style={{
                        alignSelf: 'stretch',
                        // height: 263,
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                        gap: 36,
                        display: 'flex',
                      }}
                    >
                      <div
                        style={{
                          alignSelf: 'stretch',
                          // height: 263,
                          flexDirection: 'column',
                          justifyContent: 'flex-start',
                          alignItems: 'flex-start',
                          gap: 24,
                          display: 'flex',
                        }}
                      >
                        <div
                          style={{
                            alignSelf: 'stretch',
                            // height: 263,
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start',
                            gap: 24,
                            display: 'flex',
                          }}
                        >
                          <div style={{ justifyContent: 'flex-start', alignItems: 'flex-start', gap: 16, display: 'inline-flex' }}>
                            <div
                              style={{
                                width: 286,
                                flexDirection: 'column',
                                justifyContent: 'flex-start',
                                alignItems: 'flex-start',
                                gap: 8,
                                display: 'inline-flex',
                              }}
                            >
                              <div
                                style={{
                                  alignSelf: 'stretch',
                                  // height: 183,
                                  flexDirection: 'column',
                                  justifyContent: 'flex-start',
                                  alignItems: 'flex-start',
                                  gap: 16,
                                  display: 'flex',
                                }}
                              >
                                {}

                                <div style={{ flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 8, display: 'flex' }}>
                                  <SubscriptionData
                                    closeModal={closeModal}
                                    loadingSubscription={loadingSubscription}
                                    subscription={subscription?.subscription}
                                    appointment={appointment}
                                    getCustomerSubscription={getCustomerSubscription}
                                  />

                                  {CustomerName}
                                  {Services}
                                </div>
                                <AppointmentStatus appointment={appointment} />
                              </div>
                            </div>

                            <div style={{ width: '100%', height: '100%', justifyContent: 'flex-end', alignItems: 'flex-start', display: 'inline-flex' }}>
                              <div style={{ justifyContent: 'center', alignItems: 'flex-start', gap: 10, position: 'absolute', right: 65, display: 'flex' }}>
                                <div
                                  style={{
                                    width: 40,
                                    height: 40,
                                    borderRadius: 9999,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    display: 'flex',
                                  }}
                                >
                                  <div style={{ justifyContent: 'flex-end', alignItems: 'flex-start', display: 'flex' }}>
                                    <div style={{ width: 40, height: 40, position: 'relative', background: '#E9E9E9', borderRadius: 9999 }}>
                                      <div
                                        style={{
                                          width: 40,
                                          height: 40,
                                          left: 0,
                                          top: 0,
                                          position: 'absolute',
                                          borderRadius: 9999,
                                          border: '0.75px rgba(0, 0, 0, 0.08) solid',
                                        }}
                                      />
                                      <div
                                        style={{
                                          height: '100%',
                                          textAlign: 'center',
                                          color: '#717171',
                                          fontSize: 22,
                                          fontFamily: 'Inter',
                                          fontWeight: '600',
                                          lineHeight: '32px',
                                          wordWrap: 'break-word',
                                          display: 'flex',
                                          justifyContent: 'center',
                                          alignItems: 'center',
                                        }}
                                      >
                                        {appointment?.customer?.firstName?.slice(0, 1)}
                                        {appointment?.customer?.lastName?.slice(0, 1)}
                                      </div>
                                    </div>
                                  </div>{' '}
                                  <div style={{ width: 40, height: 40, position: 'relative', borderRadius: 9999 }} />
                                </div>
                              </div>
                              <div style={{ position: 'absolute', right: 30, justifyContent: 'center', alignItems: 'flex-start', gap: 10, display: 'flex' }}>
                                <div
                                  style={{
                                    width: 40,
                                    height: 40,
                                    position: 'relative',
                                    borderRadius: 9999,
                                  }}
                                >
                                  <Avatar small employee={appointment?.barber} businessId={businessData._id} />
                                  <div
                                    style={{
                                      width: 40,
                                      height: 40,
                                      left: 0,
                                      top: 0,
                                      position: 'absolute',
                                      borderRadius: 9999,
                                    }}
                                  />
                                  <div
                                    style={{
                                      width: 14,
                                      height: 14,
                                      left: 26,
                                      top: 26,
                                      position: 'absolute',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                      display: 'inline-flex',
                                    }}
                                  >
                                    <div style={{ width: 14, height: 14, position: 'relative' }}>
                                      <IonIcon
                                        icon={star}
                                        style={{
                                          height: 12,
                                          width: 12,
                                          background: 'orange',
                                          color: 'white',
                                          position: 'absolute',
                                          bottom: 0,
                                          right: 0,
                                          borderRadius: '50%',
                                          padding: 2,
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            style={{
                              width: '100%',
                              alignSelf: 'stretch',
                              justifyContent: 'flex-start',
                              alignItems: 'flex-start',
                              gap: 10,
                              display: 'inline-flex',
                            }}
                          >
                            <div style={{ width: '50%', justifyContent: 'flex-start', alignItems: 'center', gap: 8, display: 'flex' }}>
                              <div style={{ width: 24, height: 24, position: 'relative' }}>
                                <IonIcon style={{ height: 25, width: 25, marginRight: '0.5rem' }} icon={calendarOutline} />
                              </div>
                              <div
                                style={{ color: '#222222', fontSize: 18, fontFamily: 'Figtree', fontWeight: '500', lineHeight: '28px', wordWrap: 'break-word' }}
                              >
                                {appointment ? formatDateWithWeekDay(new Date(appointment.startTime)) : ''}{' '}
                              </div>
                            </div>
                            <div style={{ width: '50%', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 8, display: 'flex' }}>
                              <div style={{ width: 24, height: 24, position: 'relative' }}>
                                <IonIcon style={{ height: 25, width: 25, marginRight: '0.5rem' }} icon={timeOutline} />
                              </div>
                              <div>
                                <IonLabel
                                  style={{
                                    color: '#222222',
                                    fontSize: 18,
                                    fontFamily: 'Figtree',
                                    fontWeight: '500',
                                    lineHeight: '28px',
                                    wordWrap: 'break-word',
                                  }}
                                >
                                  {appointment?.startTimeText} - {appointment?.endTimeText}
                                </IonLabel>
                                <IonLabel
                                  style={{
                                    color: '#717171',
                                    fontSize: 18,
                                    fontFamily: 'Figtree',
                                    fontWeight: '500',
                                    lineHeight: '28px',
                                    wordWrap: 'break-word',
                                  }}
                                >
                                  {' '}
                                  ({appointment?.durationMin} min)
                                </IonLabel>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <FooterActions
                      currentBreakpoint={currentBreakpoint}
                      rescheduling={rescheduling}
                      subscription={subscription}
                      appointment={appointment}
                      updateStatus={updateStatus}
                      updatingStatus={updatingStatus}
                      getAppointment={getAppointment}
                      closeModal={handleCloseModal}
                    />
                  </div>
                </>
              )}
            </StandardContainer>
          )}
        </IonContent>
      </IonModal>
      <ChangeEmployeeModal
        isOpen={employeeModalOpen}
        setEmployeeModalOpen={setEmployeeModalOpen}
        appointmentId={selectedEvent?._id}
        updateAppointment={getAppointment}
      />
      <IonToast
        position='top'
        isOpen={toast.isOpen}
        onDidDismiss={() => setToast({ ...toast, isOpen: false })}
        message={toast.message}
        duration={2000}
        color={toast.color}
      />
    </>
  )
}

export default ResizableModal
