import { useState, useContext, useEffect } from 'react'
import {
  IonButton,
  IonModal,
  IonHeader,
  IonContent,
  IonToolbar,
  IonTitle,
  IonList,
  IonItem,
  IonLabel,
  IonCheckbox,
  IonSegment,
  IonSegmentButton,
  IonSpinner,
  IonRow,
  IonGrid,
  IonIcon,
  IonInput,
  IonText,
  IonCol,
  IonToast,
} from '@ionic/react'
import { pricetagOutline, pricetag, addCircleOutline, trashBinOutline } from 'ionicons/icons'
import axios from 'axios'

import { AuthContext } from '../../auth'
import StandardContainer from '../../commonComponents/StandardContainer'
import { calculatePayment } from '../../sharedComponents/paymentCalculation'
import { colors } from '../../theme/colors'
import { fontSizes, fontWeights } from '../../theme/typography'
import CommonHeader from '../../commonComponents/CommonHeader'

function ServicesProductsAndDiscounts({ tab, isOpen, setOpen, appointment, updateAppointment, subscription }) {
  const { businessData, selectedLocationData } = useContext(AuthContext)

  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [selectedServices, setSelectedServices] = useState([])
  const [services, setServices] = useState([])
  const [selectedProducts, setSelectedProducts] = useState([])
  const [products, setProducts] = useState([])
  const [selectedDiscounts, setSelectedDiscounts] = useState([])
  const [discounts, setDiscounts] = useState([])
  const [activeTab, setActiveTab] = useState(tab ? tab : 'Products')
  const [servicesHasUnsavedChanges, setServicesHasUnsavedChanges] = useState(false)
  const [productsHasUnsavedChanges, setProductsHasUnsavedChanges] = useState(false)
  const [discountsHasUnsavedChanges, setDiscountsHasUnsavedChanges] = useState(false)
  const [isSaving, setSaving] = useState(false)
  const [totalSum, setTotalSum] = useState(0)
  const [data, setData] = useState({ totalPrice: 0 })
  const [customDiscountModalOpen, setCustomDiscountModalOpen] = useState(false)
  const [discountType, setDiscountType] = useState(null)
  const [discountValue, setDiscountValue] = useState('')
  const [discountError, setDiscountError] = useState('')
  const [customDiscountApplied, setCustomDiscountApplied] = useState(false)
  const [toast, setToast] = useState({ isOpen: false, message: '', color: 'success' })

  useEffect(() => {
    const totalQuantity = selectedProducts.length
    const servicesLength = selectedServices.length
    const discountsLength = selectedDiscounts.length

    setTotalSum(totalQuantity + servicesLength + discountsLength)

    const paymentData = calculatePayment(
      selectedServices,
      selectedProducts,
      selectedDiscounts,
      subscription?.data?.subscription?.subscriptionPackageId,
      null,
      selectedLocationData,
      null
    )
    setData(paymentData)
  }, [selectedProducts, selectedServices, selectedDiscounts])

  useEffect(() => {
    setSelectedServices(appointment.services || [])
    getServicesList()
    setSelectedProducts(appointment.products || [])
    getProductsList()
    setSelectedDiscounts(appointment.discounts || [])
    getDiscountsList()
    setSaving(false)
    setCustomDiscountModalOpen(false)
    setDiscountType(null)
    setDiscountValue('')
    setDiscountError('')
    // Check if there is a custom discount applied
    const isCustomDiscountApplied = appointment?.discounts?.some((discount) => discount._id.startsWith('custom-'))
    setCustomDiscountApplied(isCustomDiscountApplied)
  }, [isOpen, businessData])

  useEffect(() => {
    setActiveTab(isOpen)
  }, [isOpen])

  useEffect(() => {
    const hasChanges = JSON.stringify(selectedServices) !== JSON.stringify(appointment.services)
    setServicesHasUnsavedChanges(hasChanges)
  }, [selectedServices, appointment.services])

  useEffect(() => {
    const hasChanges = JSON.stringify(selectedProducts) !== JSON.stringify(appointment.products || [])
    setProductsHasUnsavedChanges(hasChanges)
  }, [selectedProducts, appointment.products])

  useEffect(() => {
    const hasChanges = JSON.stringify(selectedDiscounts) !== JSON.stringify(appointment.discounts || [])
    setDiscountsHasUnsavedChanges(hasChanges)
  }, [selectedDiscounts, appointment.discounts])

  const dismiss = () => {
    setOpen(false)
    setProducts([])
  }

  const getServicesList = async () => {
    try {
      setLoading(true)
      setErrorMessage(null)

      const response = await axios.get(`/service/getList?businessId=${businessData._id}`)
      setServices(response.data)
    } catch (error) {
      console.error('Error fetching services:', error)
      setErrorMessage('Sorry, there was an error fetching the data')
    } finally {
      setLoading(false)
    }
  }

  const getProductsList = async () => {
    try {
      setLoading(true)
      setErrorMessage(null)
      const response = await axios.get(`/product/getList?businessId=${businessData._id}`)
      setProducts(response.data)
    } catch (error) {
      console.error('Error fetching products:', error)
      setErrorMessage('Sorry, there was an error fetching the data')
    } finally {
      setLoading(false)
    }
  }

  const getDiscountsList = async () => {
    try {
      setLoading(true)
      setErrorMessage(null)

      const response = await axios.get(`/discount/getList?businessId=${businessData._id}`)
      setDiscounts(response.data)
    } catch (error) {
      console.error('Error fetching discounts:', error)
      setErrorMessage('Sorry, there was an error fetching the data')
    } finally {
      setLoading(false)
    }
  }

  const handleServiceSelect = (service) => {
    const serviceIndex = selectedServices.findIndex((selectedService) => selectedService._id === service._id)

    if (serviceIndex !== -1) {
      const updatedServices = [...selectedServices]
      updatedServices.splice(serviceIndex, 1)
      setSelectedServices(updatedServices)
    } else {
      setSelectedServices([...selectedServices, service])
    }
  }

  const handleProductSelect = (product) => {
    setSelectedProducts((prevSelectedProducts) => {
      const productIndex = prevSelectedProducts.findIndex((p) => p._id === product._id)

      if (productIndex !== -1) {
        // If product is already selected, remove it
        const updatedProducts = [...prevSelectedProducts]
        updatedProducts.splice(productIndex, 1)
        return updatedProducts
      } else {
        // If product is not selected, add it
        return [...prevSelectedProducts, product]
      }
    })
  }

  const handleDiscountSelect = (discount) => {
    const discountIndex = selectedDiscounts.findIndex((selectedDiscount) => selectedDiscount._id === discount._id)

    if (discountIndex !== -1) {
      const updatedDiscounts = [...selectedDiscounts]
      updatedDiscounts.splice(discountIndex, 1)
      setSelectedDiscounts(updatedDiscounts)
    } else {
      setSelectedDiscounts([...selectedDiscounts, discount])
    }
  }

  const updateAppointmentPaymentObject = async (paymentObject) => {
    const input = {
      payment: paymentObject,
    }

    try {
      const updatePaymentObjectResponse = await axios.patch(`/appointment_v2?id=${appointment?._id}`, input)
      // setToast({ isOpen: true, message: 'Updated payment information', color: 'success' })
    } catch (error) {
      setToast({ isOpen: true, message: 'Error updating payment information', color: 'danger' })
    }
  }

  const handleSave = async () => {
    setSaving(true)
    try {
      if (servicesHasUnsavedChanges) {
        await axios.patch(`/appointment_v2/updateServices`, {
          appointmentId: appointment?._id,
          services: selectedServices,
        })
      }
      if (productsHasUnsavedChanges) {
        await axios.patch(`/appointment_v2/updateProducts`, {
          appointmentId: appointment?._id,
          products: selectedProducts,
        })
      }
      if (discountsHasUnsavedChanges) {
        await axios.patch(`/appointment_v2/updateDiscounts`, {
          appointmentId: appointment?._id,
          discounts: selectedDiscounts,
        })
      }
      const data = calculatePayment(
        selectedServices,
        selectedProducts,
        selectedDiscounts,
        subscription?.data?.subscription?.subscriptionPackageId,
        null,
        selectedLocationData,
        null
      )

      await updateAppointmentPaymentObject(data)
      await updateAppointment()
      dismiss()
      // setToast({ isOpen: true, message: 'Changes saved successfully!', color: 'success' })
    } catch (error) {
      setToast({ isOpen: true, message: 'Error saving changes', color: 'danger' })
    } finally {
      setSaving(false)
    }
  }

  const renderTabContent = () => {
    switch (activeTab) {
      case 'Services':
        return (
          <IonList mode='ios' inset={true}>
            {loading ? (
              <IonItem>Loading...</IonItem>
            ) : errorMessage ? (
              <IonItem>{errorMessage}</IonItem>
            ) : (
              services.map((service) => (
                <IonItem key={service._id}>
                  <h6>{service.name}</h6>
                  <IonLabel slot='end'>${service.price}</IonLabel>
                  <IonCheckbox
                    slot='end'
                    checked={selectedServices?.some((selectedService) => selectedService._id === service._id)}
                    onIonChange={() => handleServiceSelect(service)}
                  />
                </IonItem>
              ))
            )}
          </IonList>
        )
      case 'Products':
        return (
          <IonList mode='ios' inset={true}>
            {loading ? (
              <IonItem>Loading...</IonItem>
            ) : errorMessage ? (
              <IonItem>{errorMessage}</IonItem>
            ) : (
              products.map((product) => (
                <IonItem key={product._id} onClick={() => handleProductSelect(product)}>
                  <IonGrid>
                    <h6>{product.name}</h6>
                    <IonLabel style={{ color: colors.black70 }}>${product.price}</IonLabel>
                  </IonGrid>
                  <IonCheckbox slot='end' checked={selectedProducts?.some((selectedProduct) => selectedProduct._id === product._id)} />
                </IonItem>
              ))
            )}
          </IonList>
        )
      case 'Discounts':
        return (
          <IonList inset={true} mode='ios'>
            {loading ? (
              <IonItem>Loading...</IonItem>
            ) : errorMessage ? (
              <IonItem>{errorMessage}</IonItem>
            ) : (
              <>
                {!customDiscountApplied ? (
                  <IonItem onClick={() => setCustomDiscountModalOpen(true)}>
                    <IonLabel style={{ fontWeight: fontWeights.weightBold, display: 'flex', alignItems: 'center' }}>
                      <IonIcon icon={addCircleOutline} style={{ height: 20, width: 20, marginRight: '0.5rem' }} />
                      Add Custom One-Time Discount
                    </IonLabel>
                  </IonItem>
                ) : (
                  <IonItem onClick={() => handleRemoveCustomDiscount()}>
                    <IonLabel style={{ fontWeight: fontWeights.weightBold, display: 'flex', alignItems: 'center' }}>
                      <IonIcon icon={trashBinOutline} style={{ height: 20, width: 20, marginRight: '0.5rem' }} />
                      Remove Custom Discount
                    </IonLabel>
                  </IonItem>
                )}
                {selectedDiscounts.map((discount) => {
                  // Check if it's a custom discount by looking at its ID or other unique properties
                  if (discount._id.startsWith('custom-')) {
                    return (
                      <IonItem key={discount._id}>
                        <div>
                          <h6>{discount.name}</h6>
                          <IonLabel style={{ marginBottom: '1rem' }}>
                            <DiscountType row={discount} />
                          </IonLabel>
                          <IonLabel style={{ color: colors.black50, marginBottom: '1rem' }}>
                            This is a custom discount that only applies to this appointment
                          </IonLabel>
                        </div>
                      </IonItem>
                    )
                  }
                  return null
                })}
                {discounts.map((discount) => {
                  return (
                    <>
                      {discount.couponType === 'servicesAndProducts' && (
                        <IonItem key={discount._id}>
                          <div>
                            <h6>{discount.name}</h6>
                            <IonLabel style={{ marginBottom: '1rem' }}>
                              <DiscountType row={discount} />
                            </IonLabel>
                          </div>
                          <IonCheckbox
                            slot='end'
                            checked={selectedDiscounts?.some((selectedDiscount) => selectedDiscount._id === discount._id)}
                            onIonChange={() => handleDiscountSelect(discount)}
                          />
                        </IonItem>
                      )}
                    </>
                  )
                })}
              </>
            )}
          </IonList>
        )
      default:
        return null
    }
  }

  const handleCustomDiscountApply = () => {
    let error = ''
    const value = parseFloat(discountValue)

    if (discountType === 'percentage' && (isNaN(value) || value <= 0 || value > 100)) {
      error = 'Percentage must be between 1 and 100'
    } else if (discountType === 'fixed' && (isNaN(value) || value <= 0 || value > data.totalPrice)) {
      error = `Amount must be between 1 and ${data.totalPrice}`
    }

    setDiscountError(error)
    if (!error) {
      const customDiscount = {
        _id: `custom-${Date.now()}`,
        name: `Custom Discount`,
        discountType: discountType,
        discountValue: value.toString(),
        couponType: 'servicesAndProducts',
        createdDate: Date.now(),
      }
      setSelectedDiscounts([...selectedDiscounts, customDiscount])
      setCustomDiscountApplied(true) // Set custom discount as applied
      setCustomDiscountModalOpen(false)
      setDiscountType(null)
      setDiscountValue('')
      setDiscountError('')
    }
  }
  const handleRemoveCustomDiscount = () => {
    setSelectedDiscounts(selectedDiscounts.filter((discount) => !discount._id.startsWith('custom-')))
    setCustomDiscountApplied(false) // Set custom discount as removed
  }
  useEffect(() => {
    let error = ''
    const value = parseFloat(discountValue)

    if (discountType === 'percentage' && (isNaN(value) || value <= 0 || value > 100)) {
      error = 'Percentage must be between 1 and 100'
    } else if (discountType === 'fixed' && (isNaN(value) || value <= 0 || value > data.totalPrice)) {
      error = `Amount must be between 1 and ${data.totalPrice}`
    }

    setDiscountError(error)
  }, [discountValue, discountType, data.totalPrice])
  return (
    <>
      <IonModal isOpen={isOpen} className='fullscreen'>
        <IonHeader>
          <IonToolbar color='black' style={{ backgroundColor: 'white', color: 'black' }}>
            <IonTitle style={{ color: 'black' }}>Edit or Add {activeTab}</IonTitle>
          </IonToolbar>
          <IonToolbar color='white' style={{ backgroundColor: 'white' }}>
            <IonSegment value={activeTab} color='dark' onIonChange={(e) => setActiveTab(e.detail.value)}>
              <IonSegmentButton value='Products'>
                <IonLabel>Products</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton value='Services'>
                <IonLabel>Services</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton value='Discounts'>
                <IonLabel>Discounts</IonLabel>
              </IonSegmentButton>
            </IonSegment>
          </IonToolbar>
        </IonHeader>
        <IonContent className='regularBackground'>
          <StandardContainer medium={true}>{renderTabContent()}</StandardContainer>
        </IonContent>

        <div style={{ padding: '0.5rem' }}>
          <div style={{ padding: '1rem', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div>
              <IonLabel style={{ color: colors.white, backgroundColor: colors.primaryBlue, padding: '10px 15px', borderRadius: '50%' }}>{totalSum}</IonLabel>
              <IonLabel style={{ fontWeight: fontWeights.weightBold, marginLeft: '1rem' }}>Selected</IonLabel>
            </div>
            <div style={{ fontWeight: fontWeights.weightBold }}>
              <IonLabel>Total : ${data?.totalPrice?.toFixed(2)}</IonLabel>
            </div>
          </div>
          <IonRow style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div>
              <IonButton disabled={isSaving} onClick={dismiss} color={'dark'} style={{ textDecoration: 'underline' }} fill='clear'>
                Cancel
              </IonButton>
            </div>
            <div>
              <IonButton
                onClick={() => handleSave()}
                disabled={!(servicesHasUnsavedChanges || productsHasUnsavedChanges || discountsHasUnsavedChanges) || isSaving}
                style={{ width: '7rem' }}
                color={'dark'}
              >
                {isSaving && <IonSpinner name='dots' style={{ marginRight: '0.7rem' }} />}
                Save
              </IonButton>
            </div>
          </IonRow>
        </div>
      </IonModal>
      <IonModal isOpen={customDiscountModalOpen} onDidDismiss={() => setCustomDiscountModalOpen(false)}>
        <CommonHeader title={'Choose Discount Type'} closeButton={true} closeModal={() => setCustomDiscountModalOpen(false)} />
        <IonContent>
          <IonGrid style={{ padding: '1rem 0' }}>
            <IonRow style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <IonCol
                size='5'
                style={{
                  margin: 10,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '8rem',
                  flexDirection: 'column',
                  border: `3px solid ${discountType === 'percentage' ? '#3880ff' : colors.black30}`,
                  backgroundColor: discountType === 'percentage' ? '#dbe6fa' : 'transparent',
                  borderRadius: 20,
                }}
                onClick={() => setDiscountType('percentage')}
              >
                <IonIcon style={{ height: 30, width: 30, marginBottom: '1rem' }} icon={pricetagOutline} />
                <IonLabel style={{ fontSize: fontSizes.size20 }}>Percentage Off</IonLabel>
              </IonCol>
              <IonCol
                size='5'
                style={{
                  margin: 10,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '8rem',
                  flexDirection: 'column',
                  border: `3px solid ${discountType === 'fixed' ? '#3880ff' : colors.black30}`,
                  backgroundColor: discountType === 'fixed' ? '#dbe6fa' : 'transparent',
                  borderRadius: 20,
                }}
                onClick={() => setDiscountType('fixed')}
              >
                <IonIcon style={{ height: 30, width: 30, marginBottom: '1rem' }} icon={pricetag} />
                <IonLabel style={{ fontSize: fontSizes.size20 }}>Dollars Off</IonLabel>
              </IonCol>
            </IonRow>
          </IonGrid>
          {}
          {discountType && (
            <div style={{ padding: '1rem' }}>
              <IonLabel>{discountType === 'percentage' ? 'Enter Percentage Amount' : 'Enter Dollar Amount'}</IonLabel>
              <IonInput
                type='number'
                value={discountValue}
                onIonInput={(e) => setDiscountValue(e.detail.value)}
                placeholder={discountType === 'percentage' ? 'Percentage (1-100)' : `Amount (1-${data.totalPrice})`}
              />
              {discountError && <IonText color='danger'>{discountError}</IonText>}
              <IonButton expand='block' onClick={handleCustomDiscountApply} disabled={discountError !== '' || discountValue === ''}>
                Apply
              </IonButton>
            </div>
          )}
        </IonContent>
      </IonModal>
      <IonToast
        position='top'
        isOpen={toast.isOpen}
        onDidDismiss={() => setToast({ ...toast, isOpen: false })}
        message={toast.message}
        duration={2000}
        color={toast.color}
      />
    </>
  )
}

function DiscountType({ row }) {
  if (row?.discountType === 'percentage') {
    return <>{row?.discountValue}% off</>
  } else if (row?.discountType === 'fixed') {
    return <>${row?.discountValue} off</>
  } else {
    return null
  }
}

export default ServicesProductsAndDiscounts
