import React, { useState, useEffect, useContext } from 'react'
import ResultsList from './resultsList'
import axios from 'axios'
import { AuthContext } from '../auth'
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonProgressBar,
  IonRefresher,
  IonRefresherContent,
  IonTitle,
  IonToast,
  IonToolbar,
} from '@ionic/react'
import { addCircle } from 'ionicons/icons'
import StandardContainer from '../commonComponents/StandardContainer'
import { useHistory, useLocation } from 'react-router-dom'

export default function CorporateDealListView({ locationId }) {
  const { businessData } = useContext(AuthContext)
  const [corporateDeals, setCorporateDeals] = useState([])
  const location = useLocation()
  const [addCorporateDealDialogOpen, setAddCorporateDealDialogOpen] = React.useState(false)
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [createButtonDisabled, setCreateButtonDisabled] = useState(false)
  const [toast, setToast] = useState({ isOpen: false, message: '', color: 'success' })

  let history = useHistory()

  useEffect(() => {
    if (location.pathname == '/core/corporateDeals') getCorporateDealsList()
  }, [businessData, location])
  const showToastMessage = (message, color = 'success') => {
    setToast({ isOpen: true, message, color })
  }

  const createButtonClicked = async (e) => {
    setCreateButtonDisabled(true)
    let locData = locationId ? { locations: [locationId] } : {}
    try {
      let response = await axios.post(`/corporateDeal`, { name: 'New Deal', description: '', businessId: businessData._id, ...locData })
      if (response.status === 200) {
        history.push(`/core/corporateDeals/${response.data._id}`)
        showToastMessage('Corporate deal created successfully!')
      } else {
        showToastMessage('Failed to create corporate deal. Please try again.', 'danger')
      }
    } catch (error) {
      showToastMessage('Failed to create corporate deal. Please try again.', 'danger')
    } finally {
      setCreateButtonDisabled(false)
    }
  }

  const getCorporateDealsList = async () => {
    if (!businessData?._id) return // Check if business ID is present

    setLoading(true) // Set loading state to true
    setErrorMessage(null) // Reset error message

    try {
      let locQuery = locationId ? `&locationId=${locationId}` : ''
      let response = await axios.get(`/corporateDeal/getList?businessId=${businessData._id}${locQuery}`)

      if (response.status === 200) {
        setCorporateDeals(response.data) // Update state with the response data
      } else {
        setErrorMessage('Unexpected response from the server.')
      }
    } catch (error) {
      console.error('Error fetching corporate deals:', error) // Log the error for debugging
      setErrorMessage('Sorry, there was an issue fetching corporate deals.')
    } finally {
      setLoading(false) // Always reset loading state
    }
  }

  const openAddCorporateDealDialog = () => {
    setAddCorporateDealDialogOpen(true)
  }
  const closeAddCorporateDealDialog = () => {
    getCorporateDealsList()
    setAddCorporateDealDialogOpen(false)
  }

  async function handleRefresh(event) {
    await getCorporateDealsList()
    event.detail.complete()
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color='white' style={{ backgroundColor: 'white', color: 'black' }}>
          <IonTitle>Corporate Deals</IonTitle>
          <IonButtons slot='end'>
            <IonButton color='primary' onClick={createButtonClicked} className='desktop-button'>
              Create
            </IonButton>
            <IonButton color='primary' onClick={createButtonClicked} className='mobile-button'>
              <IonIcon slot='icon-only' icon={addCircle} color='primary' />
            </IonButton>
          </IonButtons>
          {loading ? <IonProgressBar type='indeterminate' /> : null}
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonRefresher slot='fixed' onIonRefresh={handleRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        <StandardContainer>
          {errorMessage ? <p style={{ color: 'red' }}>{errorMessage}</p> : null}
          <ResultsList corporateDeals={corporateDeals} locationId={locationId} />
          <IonToast
            position='top'
            isOpen={toast.isOpen}
            message={toast.message}
            color={toast.color}
            onDidDismiss={() => setToast((prev) => ({ ...prev, isOpen: false }))}
            duration={2000}
          />
        </StandardContainer>
      </IonContent>
    </IonPage>
  )
}
