import { useContext, useState } from 'react'
import { IonCol, IonContent, IonGrid, IonImg, IonLabel, IonPage, IonRow, IonToggle, IonSpinner, IonButton, IonIcon, IonToast } from '@ionic/react'
import axios from 'axios'
import StandardContainer from './commonComponents/StandardContainer'
import { imageOutline } from 'ionicons/icons'
import CreatingProfileCard from './SetupBusiness/CreatingProfileCard'
import { AuthContext } from './auth'
import Suggestions from './SetupBusiness/Suggestions'
import LocationImages from './SetupBusiness/LocationImages'
import CustomInput from './SetupBusiness/CustomInput'
import { dataURLtoBlob } from './utils'

export default function SetupBusinessDetails() {
  const { getBusinessData } = useContext(AuthContext)
  const [businessName, setBusinessName] = useState('')
  const [locationName, setLocationName] = useState('')
  const [locationAddress, setLocationAddress] = useState('')
  const [email, setEmail] = useState('')
  const [street, setStreet] = useState('')
  const [city, setCity] = useState('')
  const [state, setState] = useState('')
  const [zipCode, setZipCode] = useState('')
  const [haveLocation, setHaveLocation] = useState(false)
  const [suggestionsData, setSuggestions] = useState([])
  const [images, setImages] = useState(false)
  const [phoneNumber, setPhoneNumber] = useState('')
  const [selectedPlaceId, setSelectedPlaceId] = useState(false)
  const [showSuggestions, setShowSuggestions] = useState(true)
  const [loadingData, setLoadingData] = useState(false)
  const [website, setWebsite] = useState('')
  const [loadingSocialMediaInfo, setLoadingSocialMediaInfo] = useState(false)
  const [twitterHandle, setTwitterHandle] = useState(false)
  const [instagramHandle, setInstaHandle] = useState(false)
  const [selectedImage, setSelectedImage] = useState(null)
  const [businessNameError, setBusinessNameError] = useState(false) // New state for space error
  const [creatingBusiness, setCreatingBusiness] = useState(false)
  const [createdBusiness, setCreatedBusiness] = useState(false)
  const [toast, setToast] = useState({ isOpen: false, message: '', color: '' }) // For Toast notification

  const toggleHaveLocation = (value) => {
    setHaveLocation(value)
    resetData()
  }
  const showToastMessage = (message, color) => {
    setToast({ isOpen: true, message, color })
  }
  async function urlToDataBlob(url) {
    try {
      // Fetch the content of the URL as a blob
      const response = await fetch(url)
      const blob = await response.blob()

      // Convert the blob to a Data URL
      const dataUrl = await new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = () => {
          resolve(reader.result)
        }
        reader.onerror = (error) => {
          reject(error)
        }
        reader.readAsDataURL(blob)
      })

      return dataUrl
    } catch (error) {
      console.error('Error converting URL to Data Blob:', error)
      throw error
    }
  }
  const resetData = () => {
    setLocationName('')
    setLocationAddress('')
    setEmail('')
    setStreet('')
    setCity('')
    setState('')
    setZipCode('')
    setSuggestions([])
    setImages(false)
    setPhoneNumber('')
    setSelectedPlaceId(false)
    setShowSuggestions(true)
    setLoadingData(false)
    setWebsite('')
    setLoadingSocialMediaInfo(false)
    setTwitterHandle(false)
    setInstaHandle(false)
    setSelectedImage(null)
    setBusinessNameError(false)
    setCreatingBusiness(false)
    setCreatedBusiness(false)
  }

  const handleBusinessName = (value) => {
    // Regular expression to match valid characters for a hostname
    if (value) {
      resetData()
      const validHostnameRegex = /^[a-zA-Z0-9.@_-]+$/

      // Check if the input matches the valid hostname pattern
      if (!validHostnameRegex.test(value)) {
        setBusinessNameError('Only a-z, A-Z, 0-9, ., _, and - is allowed . Spaces are not allowed')
      } else {
        setBusinessNameError('') // Clear the error message if the input is valid
      }
    } else {
      setBusinessNameError('') // Clear the error message if the input is valid
    }
    setBusinessName(value) // Update business name
  }

  const handleLocationName = (value) => {
    setLocationName(value)
  }

  const handleLocationAddress = (value) => {
    const address = value.trim()
    if (locationAddress != address) {
      if (!address) {
        setSelectedPlaceId(false)
        setShowSuggestions(false)
      } else {
        setShowSuggestions(true)
      }
      setLocationAddress(address)
      fetchAddress(address)
    } else {
      setShowSuggestions(false)
    }
  }

  const handleEmailChange = (value) => {
    setEmail(value)
  }

  const handleStreetChange = (value) => {
    setStreet(value)
  }

  const handleCityChange = (value) => {
    setCity(value)
  }

  const handleStateChange = (value) => {
    setState(value)
  }

  const handleZipCodeChange = (value) => {
    setZipCode(value)
  }
  const handleImageChange = (event) => {
    const file = event.target.files[0]
    setSelectedImage(file)
  }
  const handlePhoneNumber = (value) => {
    setPhoneNumber(value)
  }
  const handleInstagramHandle = (value) => {
    setInstaHandle(value)
  }
  const handleTwitterHandle = (value) => {
    setTwitterHandle(value)
  }
  const handleWebsite = (value) => {
    setWebsite(value)
  }

  const handleSubmit = async () => {
    setCreatingBusiness(true)
    try {
      // Prepare business input data
      const businessInput = {
        name: businessName,
        website: website,
        locationName: locationName,
        locationInfo: {
          name: locationName,
          email: email,
          addressStreet1: street,
          addressStreet2: '',
          addressCity: city,
          addressState: state,
          addressZipcode: zipCode,
          phoneNumber: phoneNumber,
          website: website,
          twitterHandle: twitterHandle,
          instagramHandle: instagramHandle,
        },
      }

      // Create the business
      const createBusinessResponse = await axios.post('/business_v2', businessInput)

      if (createBusinessResponse.status === 200) {
        showToastMessage('Business created successfully!', 'success')

        const createdBusinessId = createBusinessResponse?.data?.businessId
        const createdLocationId = createBusinessResponse?.data?.locationId
        if (selectedImage) {
          // Upload the logo image first
          const logoFormData = new FormData()
          logoFormData.append('selectedFile', new File([selectedImage], selectedImage.name))
          logoFormData.append('id', createdBusinessId)
          const logoUploadResponse = await axios.post('/business_v2/logo', logoFormData)

          if (logoUploadResponse.data.success !== 'success') {
            showToastMessage('Failed to upload Business logo', 'error')
            throw new Error('Logo upload failed')
          } else {
            showToastMessage('Business image uploaded successfully!', 'success')
          }
        }
        let allSuccess = true
        if (images && images.length > 0) {
          // Map the images array to an array of upload promises
          const uploadPromises = images.map(async (image) => {
            const url = image.url
            try {
              // Convert URL to Data Blob
              const dataUrl = await urlToDataBlob(url)
              const dataBlob = dataURLtoBlob(dataUrl)
              const file = new File([dataBlob], 'photo.jpg', { type: 'image/jpeg' })

              // Prepare form data
              const formData = new FormData()
              formData.append('selectedFile', file)
              formData.append('imageType', 'Image')
              formData.append('locationId', createdLocationId)

              // Upload image
              const response = await axios.post('/location_v2/image', formData)

              return response.data
            } catch (error) {
              console.error('Error uploading image:', error)
              throw error
            }
          })

          // Wait for all upload promises to resolve
          const uploadResponses = await Promise.all(uploadPromises)
          allSuccess = uploadResponses.every((result) => result.success === 'success')
        }

        if (allSuccess) {
          setCreatedBusiness(true)
          localStorage.setItem('defaultBusiness', createdBusinessId)
          getBusinessData()
        } else {
          console.error('Some images failed to upload.')
          setCreatingBusiness(false)
        }
      } else {
        console.error('Failed to create business.')
        setCreatingBusiness(false)
      }
    } catch (error) {
      console.error('Error:', error)
      setCreatingBusiness(false)
    }
  }

  const fetchAddress = async (value) => {
    try {
      const response = await axios.get(`/location_v2/search?value=${value}`)
      if (response.status === 200 && response.data.length > 0) {
        setSuggestions(response.data)
        setShowSuggestions(true)
      } else {
        throw new Error('No address found')
      }
    } catch (error) {
      console.error('Error fetching address:', error)
    }
  }

  const handleSelection = async (item) => {
    setLocationAddress(item.name)
    setLoadingData(true)
    setSelectedPlaceId(item.place_id)
    setShowSuggestions(false)
    try {
      const detailsResponse = await axios.get(`location/placeInfo?id=${item.place_id}`)

      if (detailsResponse.status === 200) {
        const images = detailsResponse?.data?.photos?.map((photo) => {
          return {
            url: `https://maps.googleapis.com/maps/api/place/photo?maxwidth=400&photoreference=${photo.photo_reference}&key=AIzaSyBsdj1VxtcNu1sykz9Q2Bepuy0FMlpal44`,
            alt: 'Location Image',
          }
        })
        const address = detailsResponse?.data?.formatted_address
        const phoneNumber = detailsResponse?.data?.formatted_phone_number
        const website = detailsResponse?.data?.website

        setImages(images)
        setPhoneNumber(phoneNumber)
        setWebsite(website)
        if (website) {
          setLoadingSocialMediaInfo(true)
          try {
            const socialMediaResponse = await axios.get(`/location_v2/scrape?website=${website}`)
            if (socialMediaResponse.status === 200) {
              setTwitterHandle(socialMediaResponse?.data?.twitterHandle)
              setInstaHandle(socialMediaResponse?.data?.instagramHandle)
            }
            setLoadingSocialMediaInfo(false)
          } catch (error) {
            setLoadingSocialMediaInfo(true)
          }
        }
        setLoadingData(false)
      } else {
        throw new Error('Failed to fetch details')
      }
    } catch (error) {
      console.error('Error fetching details:', error)
    }
  }

  return (
    <IonPage>
      <IonContent className='regularBackground'>
        <StandardContainer>
          <IonToast
            position='top'
            isOpen={toast.isOpen}
            message={toast.message}
            color={toast.color}
            onDidDismiss={() => setToast({ ...toast, isOpen: false })}
            duration={2000}
          />
          <IonGrid style={{ padding: '1rem' }}>
            {
              <>
                {!creatingBusiness && (
                  <>
                    <h1
                      style={{
                        fontWeight: 'bold',
                        fontSize: '2rem',
                        marginBottom: '2rem',
                        textAlign: 'center',
                      }}
                    >
                      Lets get some details about your business
                    </h1>
                    <CustomInput label={'Business Name'} value={businessName} handleInput={handleBusinessName} />

                    {businessNameError && (
                      <div style={{ marginTop: '1rem' }}>
                        <IonLabel style={{ color: 'red' }}>{businessNameError}</IonLabel>
                      </div>
                    )}
                    {businessName && (
                      <>
                        <IonRow style={{ marginTop: '2rem' }}>
                          <IonCol style={{ display: 'flex', alignItems: 'center' }} size='9'>
                            <IonToggle
                              style={{ marginRight: '1rem' }}
                              justify='space-between'
                              size='small'
                              checked={haveLocation}
                              onIonChange={(e) => {
                                toggleHaveLocation(e.detail.checked)
                              }}
                            />{' '}
                            <IonLabel>I have a location</IonLabel>
                          </IonCol>
                        </IonRow>
                        {haveLocation && (
                          <>
                            <CustomInput label={'Location Name'} value={locationName} handleInput={handleLocationName} />
                            <CustomInput clearInput={true} label={'Location Address'} value={locationAddress} handleInput={handleLocationAddress} />
                            {showSuggestions
                              ? suggestionsData &&
                                suggestionsData.length > 0 && <Suggestions suggestionsData={suggestionsData} handleSelection={handleSelection} />
                              : null}
                            {selectedPlaceId && images && <LocationImages images={images} />}

                            {loadingData && (
                              <IonSpinner
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  margin: '10px 0',
                                }}
                                name='dots'
                              />
                            )}
                            {/* fields to be displayed once any place is selected */}
                            {selectedPlaceId && (
                              <>
                                <CustomInput label={'Email'} value={email} handleInput={handleEmailChange} />
                                <CustomInput label={'Street'} value={street} handleInput={handleStreetChange} />
                                <CustomInput label={'City'} value={city} handleInput={handleCityChange} />
                                <CustomInput label={'State'} value={state} handleInput={handleStateChange} />
                                <CustomInput label={'Zip Code'} value={zipCode} handleInput={handleZipCodeChange} />
                                <CustomInput label={'Phone Number'} value={phoneNumber} handleInput={handlePhoneNumber} />
                                <CustomInput label={'Website'} value={website} handleInput={handleWebsite} />

                                {loadingSocialMediaInfo && <IonSpinner name='dots' />}
                                {!loadingSocialMediaInfo && (
                                  <>
                                    <CustomInput label={'X(Twitter)'} value={twitterHandle} handleInput={handleTwitterHandle} />
                                    <CustomInput label={'Instagram'} value={instagramHandle} handleInput={handleInstagramHandle} />

                                    <IonRow>
                                      <IonLabel style={{ margin: '10px 0', fontWeight: 'bold' }}>Logo</IonLabel>
                                      <div
                                        style={{
                                          border: '1px solid #d6d6d6',
                                          borderRadius: '5px',
                                          padding: '0 10px !important',
                                          display: 'flex',
                                          justifyContent: 'center',
                                          alignItems: 'center',
                                          height: '8rem',
                                          width: '100%',
                                          flexDirection: 'column',
                                          marginBottom: '1rem',
                                        }}
                                      >
                                        {selectedImage ? (
                                          <IonImg src={URL.createObjectURL(selectedImage)} style={{ width: '100%', height: '100%' }} />
                                        ) : (
                                          <div
                                            style={{
                                              display: 'flex',
                                              flexDirection: 'column',
                                              alignItems: 'center',
                                            }}
                                          >
                                            <IonIcon size='large' icon={imageOutline} />
                                            <IonLabel>Upload Image</IonLabel>
                                          </div>
                                        )}
                                        <input type='file' accept='image/*' onChange={handleImageChange} />
                                      </div>
                                    </IonRow>
                                  </>
                                )}
                              </>
                            )}
                            <IonButton
                              disabled={
                                !businessName ||
                                !locationName ||
                                !locationAddress ||
                                !email ||
                                !street ||
                                !city ||
                                !state ||
                                !zipCode ||
                                !phoneNumber ||
                                !website ||
                                !twitterHandle ||
                                !instagramHandle
                              }
                              color={'dark'}
                              onClick={handleSubmit}
                              size='full'
                            >
                              Next
                            </IonButton>
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            }
            {/* component to display the message when the business is being created */}
            {creatingBusiness && <CreatingProfileCard createdBusiness={createdBusiness} />}
          </IonGrid>
        </StandardContainer>
      </IonContent>
    </IonPage>
  )
}
