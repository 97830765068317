// Importing necessary dependencies and components
import { IonContent, IonPage, IonSpinner, IonToast } from '@ionic/react'
import { useContext, useEffect, useState } from 'react'
import CommonHeader from '../CommonHeader'
import StandardContainer from '../StandardContainer'
import PictureDetail from './PictureDetail'
import axios from 'axios'
import { AuthContext } from '../../auth'
import CameraAndGalleryOptions from './CameraAndGalleryOptions'
import { v4 as uuidv4 } from 'uuid'
import ListView from './ListView'
import { dataURLtoBlob } from '../../utils'

// Main component for displaying customer pictures and handling interactions
export default function CustomerGallery({ match, type }) {
  // Retrieve necessary context data
  const { businessData, locationId } = useContext(AuthContext)

  // Determine if the component is associated with an appointment
  let appointment_id = false
  if (type === 'appointment') {
    appointment_id = match?.params?.id
  }

  // State variables for managing component state
  const customer_id = match?.params?.id
  const [loading, setLoading] = useState(true)
  const [newDataLoading, setNewDataLoading] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [selectedImage, setSelectedImage] = useState(false) // Store the selected image URL
  const [detailModalOpen, setDetailModalOpen] = useState(false)
  const [imageList, setImagesList] = useState([])
  const [services, setServices] = useState([])
  const [selectedImageIndex, setSelectedImageIndex] = useState(null)
  const [addingOrUpdatingImage, setAddingUpdatingImage] = useState(false)
  const [appointment, setAppointment] = useState(false)
  const [customerid, setCustomerId] = useState(customer_id)
  const [toast, setToast] = useState({ isOpen: false, message: '', color: 'success' })

  // Function to open the modal for adding a new image
  const openModal = () => {
    setModalOpen(true)
  }

  // Function to close the modal
  const closeModal = () => {
    setModalOpen(false)
  }
  // Function to show toast messages
  const showToastMessage = (message, color) => {
    setToast({ isOpen: true, message, color })
  }

  // Function to open the detail modal for a selected image
  const openDetailModal = () => {
    setDetailModalOpen(true)
  }

  // Function to close the detail modal
  const closeDetailModal = () => {
    setDetailModalOpen(false)
  }

  // Function to handle clicking on an image in the list
  const handleImageClick = (image, index) => {
    setDetailModalOpen(true)
    setSelectedImageIndex(index)
    setSelectedImage(image)
  }

  // Function to handle a selected image (used in the CameraAndGalleryOptions component)
  const handleSelectedImage = (image) => {
    openDetailModal()
    const selectedImageData = {
      data: image,
      createdDate: Date.now(),
      contentType: 'image/png',
      name: '',
      socialMediaRating: '1',
    }
    setSelectedImage(selectedImageData)
  }

  // Function to add an image to the gallery
  const handleAddImageToGallery = async (image) => {
    setAddingUpdatingImage(true)
    const pictureUUID = uuidv4()

    // Convert data URL to Blob
    const dataBlob = dataURLtoBlob(image.data)

    // Create a File object (optional)
    const file = new File([dataBlob], 'photo.jpg', { type: 'image/jpeg' })

    const data = new FormData()
    data.append('selectedFile', file)
    data.append('customerId', customerid)
    data.append('pictureUUID', pictureUUID)
    data.append('name', image.name)
    data.append('socialMediaRating', image.socialMediaRating)
    if (appointment_id) {
      data.append('appointmentId', appointment_id)
    }

    try {
      const response = await axios.post('/customer_v2/picture', data)
      if (response?.data?.success === 'success') {
        showToastMessage('Image added successfully!', 'success')

        setAddingUpdatingImage(false)
        getCustomerPictures(customerid, true)
      }
    } catch (error) {
      setAddingUpdatingImage(false)
      showToastMessage('Error adding image. Please try again.', 'danger')

      console.error('Error adding image to gallery:', error)
    }
    // Call the API upload function (replace with your actual API endpoint)
  }

  // Function to handle updating an image in the gallery
  const handleUpdateImage = async (image, index) => {
    setAddingUpdatingImage(true)
    try {
      const updateImageInput = {
        name: image.name,
        socialMediaRating: parseInt(image.socialMediaRating),
      }
      const updateImageResponse = await axios.patch(`/customer_v2/picture/update?customerId=${customerid}&pictureUUID=${image.pictureUUID}`, updateImageInput)
      if (updateImageResponse.status === 200) {
        showToastMessage('Image updated successfully!', 'success')

        setAddingUpdatingImage(false)
        const imageListTemp = [...imageList]
        imageListTemp[index] = image
        setImagesList(imageListTemp)
      }
    } catch (error) {
      showToastMessage('Error updating image. Please try again.', 'danger')

      setAddingUpdatingImage(false)
    }
  }

  // Function to refresh the list of customer pictures
  const refreshPicturesList = () => {
    getCustomerPictures(customerid, true)
  }

  // Function to get customer pictures from the server
  const getCustomerPictures = async (customerId, getNewData) => {
    try {
      if (getNewData) {
        setNewDataLoading(true)
      } else {
        setLoading(true)
      }

      let apiUrl = `/customer_v2/picture?customerId=${customerId}`
      if (type === 'appointment') {
        apiUrl += `&appointmentId=${appointment_id}`
      }

      const imagesResponse = await axios.get(apiUrl)
      if (imagesResponse.status === 200) {
        setImagesList(imagesResponse.data)
      }
    } catch (error) {
      console.error('Error fetching customer pictures:', error)
    } finally {
      if (getNewData) {
        setNewDataLoading(false)
      } else {
        setLoading(false)
      }
    }
  }

  // Function to get the list of services from the server
  const getServicesList = async () => {
    if (!businessData?._id) return

    try {
      let locQuery = locationId ? `&locationId=${locationId}` : ''
      const response = await axios.get(`/service/getList?businessId=${businessData._id}${locQuery}`)
      setServices(response.data)
    } catch (error) {
      console.error('Error fetching services list:', error)
    }
  }

  // Function to get details of an appointment
  const getAppointmentDetails = async () => {
    setLoading(true)
    try {
      const response = await axios.get(`/appointment_v2?id=${appointment_id}`)

      if (response.status === 200) {
        setAppointment(response.data)
        setCustomerId(response.data.customer?._id)
        getCustomerPictures(response.data.customer?._id, false)
      }
    } catch (error) {
      console.error('Error fetching appointment details:', error)
    } finally {
      setLoading(false)
    }
  }

  // Function to get details of an appointment or customer gallery
  const getAppointmentAndGalleryDetails = () => {
    setCustomerId(customer_id)
    if (type === 'appointment') {
      getAppointmentDetails()
    } else {
      getCustomerPictures(customer_id, false)
    }
  }

  // Effect hook to fetch customer pictures when the component mounts or customer_id changes
  useEffect(() => {
    getAppointmentAndGalleryDetails()
  }, [customer_id, type])

  // Effect hook to fetch customer pictures and services when the

  // Effect hook to fetch customer pictures and services when the component mounts
  useEffect(() => {
    getAppointmentAndGalleryDetails()
    getServicesList()
  }, [])

  return (
    <IonPage id='main-content'>
      {/* CommonHeader component for displaying header with add button and progress bar */}
      <CommonHeader progressBar={newDataLoading} addIcon={true} handleAddButton={openModal} title={'Customer Pictures'} backIcon={true}></CommonHeader>

      <IonContent>
        <StandardContainer>
          {/* Display a loading spinner while data is being fetched */}
          {loading && (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <IonSpinner name='dots' />
            </div>
          )}

          {/* Display the list view of customer pictures */}
          {!loading && <ListView imageList={imageList} handleImageClick={handleImageClick} selectedImage={selectedImage} />}

          {/* CameraAndGalleryOptions component for handling camera and gallery actions */}
          <CameraAndGalleryOptions
            refreshPicturesList={refreshPicturesList}
            addingOrUpdatingImage={addingOrUpdatingImage}
            customer_id={customer_id}
            modalOpen={modalOpen}
            services={services}
            closeModal={closeModal}
            handleAddImageToGallery={handleAddImageToGallery}
            handleSelectedImage={handleSelectedImage}
          />

          {/* PictureDetail component for displaying details of a selected image */}
          <PictureDetail
            customer_id={customer_id}
            addingOrUpdatingImage={addingOrUpdatingImage}
            refreshPicturesList={refreshPicturesList}
            selectedImageIndex={selectedImageIndex}
            handleUpdateImage={handleUpdateImage}
            services={services}
            closeDetailModal={closeDetailModal}
            detailModalOpen={detailModalOpen}
            selectedImage={selectedImage}
          />
          <IonToast
            position='top'
            isOpen={toast.isOpen}
            onDidDismiss={() => setToast({ ...toast, isOpen: false })}
            message={toast.message}
            color={toast.color}
            duration={2000}
          />
        </StandardContainer>
      </IonContent>
    </IonPage>
  )
}
