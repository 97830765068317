import { statusColors } from '../../StaticData/StaticData'
import { setTimeFromDateStr } from '../../utils'
export const calculateEventStyles = (event, person, index, personEvents, locationStartTime) => {
  const startTimeString = event.startTimeText
  const endTimeString = event.endTimeText

  // Parse start time
  const [time12hr, period] = startTimeString.split(' ')
  const [hourString, minuteString] = time12hr.split(':')
  const startHour = parseInt(hourString, 10)
  const startMinute = parseInt(minuteString, 10)

  let height = event.durationMin
  if (event.isBreak) {
    // Parse end time
    const [time12hrend, periodend] = endTimeString.split(' ')
    const [hourStringend, minuteStringend] = time12hrend.split(':')
    const endHour = parseInt(hourStringend, 10)
    const endMinute = parseInt(minuteStringend, 10)

    // Calculate start and end times in minutes from midnight
    const startTimeInMinutes = startHour * 60 + startMinute
    const endTimeInMinutes = endHour * 60 + endMinute

    // Calculate duration in minutes
    const totalDurationInMinutes = endTimeInMinutes - startTimeInMinutes

    // Calculate height based on duration in hours, each row being 240px tall
    height = totalDurationInMinutes
  }

  // Calculate top position based on start time (minutes)
  const top = startMinute // Adjust as needed

  // Calculate z-index based on start time (higher z-index for later start times)
  const zIndex = startHour * 60 + startMinute

  // Filter out events for the person that have already been rendered
  const renderedEvents = personEvents[person]?.slice(0, index)

  // Check for overlapping events among rendered events
  const overlappingEvents = renderedEvents?.filter(
    (otherEvent) =>
      otherEvent._id !== event._id &&
      new Date(setTimeFromDateStr(otherEvent.startTimeText)) < new Date(setTimeFromDateStr(event.endTimeText)) &&
      new Date(setTimeFromDateStr(otherEvent.endTimeText)) > new Date(setTimeFromDateStr(event.startTimeText))
  )

  // Add the current event to the overlapping events
  overlappingEvents?.push(event)

  // Calculate width and left position
  let width = '98%'
  let left = '0%'

  if (overlappingEvents?.length > 1) {
    const totalOverlaps = overlappingEvents?.length
    width = 98 / totalOverlaps + '%'
    left = (98 / totalOverlaps) * overlappingEvents?.findIndex((overlap) => overlap._id === event._id) + '%'
  }

  return {
    top,
    left,
    width,
    height,
    minHeight: 15,
    backgroundColor: statusColors[event.status]?.bgColor || '#ccc',
    position: 'absolute',
    boxSizing: 'border-box',
    borderRadius: 6,
    color: statusColors[event.status]?.textColor || '#000',
    fontSize: 12,
    display: 'flex',
    alignItems: 'start',
    cursor: 'pointer',
    zIndex: event.isBreak ? 0 : zIndex,
  }
}
export const calculateNewEventStyles = (event, index, columns) => {
  if (event.type == 'Walkin' && !event.startTimeText) {
    return {
      marginTop: 2,
      height: `${event.durationMin}px`, // Adjust height calculation as per your requirement
      minHeight: 20,
      backgroundColor: statusColors[event.status]?.bgColor || '#ccc',
      color: statusColors[event.status]?.textColor || '#000',
      borderRadius: 6,
      boxSizing: 'border-box',
      padding: '2px',
      border: event.status == 'Canceled' || event.status == 'Arrived' ? `1px dashed ${statusColors[event.status]?.textColor}` : null,
    }
  } else {
    const startTimeString = event.startTimeText
    const endTimeString = event.endTimeText

    // Parse start time
    const [time12hr, period] = startTimeString.split(' ')
    const [hourString, minuteString] = time12hr.split(':')
    const startHour = parseInt(hourString, 10)
    const startMinute = parseInt(minuteString, 10)

    const totalOverlaps = columns.length
    const width = `${100 / totalOverlaps}%`
    const left = `${(100 / totalOverlaps) * columns.findIndex((col) => col.includes(event))}%`
    const zIndex = startHour * 60 + startMinute
    const top = startMinute // Adjust as needed

    return {
      position: 'absolute',
      top: top,
      height: `${event.durationMin}px`, // Adjust height calculation as per your requirement
      minHeight: 20,
      backgroundColor: statusColors[event.status]?.bgColor || '#ccc',
      color: statusColors[event.status]?.textColor || '#000',
      borderRadius: 6,
      boxSizing: 'border-box',
      zIndex: event.isBreak ? 0 : zIndex,
      padding: '2px',
      border: event.status == 'Canceled' || event.status == 'Arrived' ? `1px dashed ${statusColors[event.status]?.textColor}` : null,
    }
  }
}

export const organizeAppointmentsByHour = (appointments) => {
  const appointmentsByHour = new Array(24).fill().map(() => [])

  appointments.map((appointment) => {
    appointment.person = `${appointment?.barber?.firstName} ${appointment?.barber?.lastName}`

    const startTimeText = setTimeFromDateStr(appointment?.startTimeText)
    const startTime = new Date(startTimeText).getHours() - 1 // Extract hour from startTimeText

    // Push appointment into corresponding hour slot
    appointmentsByHour[startTime].push(appointment)
  })

  return appointmentsByHour
}
export const organizeEventsByName = (appointments) => {
  const eventsByName = {}

  appointments.forEach((appointment) => {
    const personName = `${appointment?.barber?.firstName} ${appointment?.barber?.lastName}`

    if (!eventsByName[personName]) {
      eventsByName[personName] = []
    }

    eventsByName[personName].push(appointment)
  })

  return eventsByName
}
