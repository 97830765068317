import React, { useState, useEffect } from 'react'
import {
  IonList,
  IonItem,
  IonLabel,
  IonButton,
  IonIcon,
  IonInput,
  IonReorder,
  IonReorderGroup,
  IonItemSliding,
  IonItemOptions,
  IonItemOption,
  IonCol,
  IonRow,
  IonGrid,
} from '@ionic/react'
import { trashOutline, reorderTwoOutline } from 'ionicons/icons'

const ListComponent = ({ subscriptionPackage, updateSubscriptionPackage }) => {
  const [newItem, setNewItem] = useState('')
  const [listItems, setListItems] = useState(subscriptionPackage?.features || [])
  const [isInit, setIsInit] = useState(false)
  const [changeOrder, setChangeOrder] = useState(false)

  useEffect(() => {
    if (isInit) {
      updateSubscriptionPackage({ ...subscriptionPackage, features: listItems })
    } else {
      setIsInit(true)
    }
  }, [listItems])

  const addItem = () => {
    if (newItem.trim() !== '') {
      setListItems([...listItems, newItem])
      setNewItem('')
    }
  }

  const deleteItem = (index) => {
    const newListItems = [...listItems]
    newListItems.splice(index, 1)
    setListItems(newListItems)
  }

  const handleInput = (e) => {
    setNewItem(e.detail.value)
  }

  const doReorder = (event) => {
    const { detail } = event
    if (detail.complete) {
      const reorderedList = detail.complete(listItems)
      setListItems(reorderedList)
    }
  }

  return (
    <>
      <IonGrid style={{ marginBottom: -20 }}>
        <IonRow>
          <IonCol>
            <h6 style={{ marginLeft: 15 }}>Feature List</h6>
          </IonCol>
          <IonCol size='auto'>
            <IonButton
              style={{ marginTop: 12 }}
              size='small'
              fill='clear'
              onClick={() => {
                setChangeOrder(!changeOrder)
              }}
            >
              {!changeOrder ? 'Change Order' : 'Done'}
            </IonButton>
          </IonCol>
        </IonRow>
      </IonGrid>

      <IonList inset={true} mode='ios'>
        <IonReorderGroup disabled={false} onIonItemReorder={doReorder}>
          {listItems.map((item, index) => (
            <IonItemSliding key={index}>
              <IonItem>
                {changeOrder ? (
                  <IonReorder slot='start'>
                    <IonIcon icon={reorderTwoOutline} />
                  </IonReorder>
                ) : null}

                <IonLabel>{item}</IonLabel>

                <IonButton slot='end' fill='clear' onClick={() => deleteItem(index)}>
                  <IonIcon icon={trashOutline} />
                </IonButton>
              </IonItem>

              <IonItemOptions side='end'>
                <IonItemOption color='danger' onClick={() => deleteItem(index)}>
                  Delete
                </IonItemOption>
              </IonItemOptions>
            </IonItemSliding>
          ))}
        </IonReorderGroup>
        <IonItem>
          <IonInput placeholder='New Item' value={newItem} onIonInput={handleInput} />
          <IonButton slot='end' onClick={addItem}>
            Add
          </IonButton>
        </IonItem>
      </IonList>
    </>
  )
}

export default ListComponent
