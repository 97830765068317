import Select from 'react-select'
import moment from 'moment-timezone'
import { IonContent, IonItem, IonLabel, IonList, IonModal, IonToast } from '@ionic/react'
import { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../auth'
import GetLocationDataBasedOnLocationId from '../Utils/GetLocationDataBasedOnLocationId'
import axios from 'axios'
import CommonHeader from './CommonHeader'
import StandardContainer from './StandardContainer'
export default function TimeZonePicker({}) {
  const [loading, setLoading] = useState(false)
  const [selectedTimeZone, setSelectedTimeZone] = useState(false)
  const { businessData, locationId, locationData, getBusinessData } = useContext(AuthContext)
  const selectedLocationData = GetLocationDataBasedOnLocationId(locationData, locationId)
  const [modalOpen, setModalOpen] = useState(false)
  const [toast, setToast] = useState({ isOpen: false, message: '', color: 'success' })

  const openModal = () => {
    setModalOpen(true)
  }
  const closeModal = () => {
    setModalOpen(false)
    setLoading(false)
  }
  const showToastMessage = (message, color) => {
    setToast({ isOpen: true, message, color })
  }

  useEffect(() => {
    if (selectedLocationData?.timezone) {
      setSelectedTimeZone({
        value: selectedLocationData?.timezone,
        label: selectedLocationData?.timezone,
      })
    }
  }, [])

  // Custom styles to control z-index
  const handleChangeTimeZone = async (selectedOption) => {
    setLoading(true)
    try {
      const locationTimeZoneInput = {
        businessId: businessData?._id,
        timezone: selectedOption.value,
      }
      const response = await axios.patch(`/location_v2?id=${locationId}`, locationTimeZoneInput)
      if (response && response.status === 200) {
        setSelectedTimeZone(selectedOption)
        getBusinessData()
        setLoading(false)
        showToastMessage('Timezone updated successfully!', 'success')
      }
    } catch (error) {
      setSelectedTimeZone(false)
      setLoading(false)
      showToastMessage('Failed to update timezone. Please try again.', 'danger')
    }
  }
  const timeZones = moment.tz.names().map((tz) => ({ value: tz, label: tz }))
  return (
    <>
      <IonList inset={true} mode='ios'>
        <IonItem style={{ cursor: 'pointer' }} onClick={openModal} detail={true}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <IonLabel>{`Timezone`}</IonLabel>
            <IonLabel style={{ marginLeft: '0.5rem', fontSize: '12px', color: 'grey' }}>{`(${selectedLocationData.timezone})`}</IonLabel>
          </div>
        </IonItem>
      </IonList>
      <IonModal isOpen={modalOpen} onDidDismiss={closeModal}>
        <CommonHeader title={'Update TimeZone'} closeButton={true} loading={loading} closeModal={closeModal}></CommonHeader>
        <IonContent className='regularBackground'>
          <StandardContainer>
            <div style={{ margin: '1rem ' }}>
              <div style={{ margin: '1rem 0' }}>
                <h4 style={{ margin: 0 }}>Timezone</h4>
              </div>
              <Select value={selectedTimeZone} onChange={handleChangeTimeZone} options={timeZones} />
            </div>
          </StandardContainer>
        </IonContent>
      </IonModal>
      <IonToast
        position='top'
        isOpen={toast.isOpen}
        message={toast.message}
        duration={2000}
        color={toast.color}
        onDidDismiss={() => setToast({ ...toast, isOpen: false })}
      />
    </>
  )
}
