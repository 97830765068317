import { IonCard, IonContent, IonModal } from '@ionic/react'
import React from 'react'
import CommonHeader from '../commonComponents/CommonHeader'
import StandardContainer from '../commonComponents/StandardContainer'
import DiscountList from './DiscountList'

export default function DiscountListModal({
  discountModalOpen,
  closeDiscountsModal,
  discounts,
  selectedDiscounts,
  handleDiscountSelect,
  removeItem,
}) {
  return (
    <IonModal isOpen={discountModalOpen} onDidDismiss={closeDiscountsModal}>
      <CommonHeader
        closeButton={true}
        closeModal={closeDiscountsModal}
        title={'Choose discounts'}
      ></CommonHeader>
      <IonContent className='regularBackground'>
        <StandardContainer>
          <IonCard style={{ padding: '1rem' }}>
            <DiscountList
              discounts={discounts}
              displaycheckbox={true}
              selectedDiscounts={selectedDiscounts}
              handleDiscountSelect={handleDiscountSelect}
              removeItem={removeItem}
            />
          </IonCard>
        </StandardContainer>
      </IonContent>
    </IonModal>
  )
}
