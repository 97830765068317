import { IonButton, IonContent, IonGrid, IonInput, IonItem, IonLabel, IonList, IonPage, IonRow, IonTextarea, IonToast } from '@ionic/react'
import { useEffect, useState } from 'react'
import CommonHeader from '../commonComponents/CommonHeader'
import StandardContainer from '../commonComponents/StandardContainer'
import axios from 'axios'
import { useHistory } from 'react-router'

export default function AddOrUpdateAcquisitionSoure({ match }) {
  const referenceId = match?.params?.id

  const [saveDisabled, setsaveDisabled] = useState(true)
  const [sourceName, setSourceName] = useState('')
  const [sourceCategory, setSourceCategory] = useState('')
  const [sourceDescription, setSourceDescription] = useState('')
  const [tagId, setTagId] = useState('')
  const [saveLoading, setSaveLoading] = useState(false)
  const history = useHistory()
  const [deleteModalVisible, setDeleteModalVisible] = useState(false)
  const [deletingData, setDeletingData] = useState(false)
  const [toast, setToast] = useState({ isOpen: false, message: '', color: 'success' })
  const showToastMessage = (message, color = 'success') => {
    setToast({ isOpen: true, message, color })
  }
  const handleSourceName = (value) => {
    setSourceName(value)
  }
  const handleSourceCategory = (value) => {
    setSourceCategory(value)
  }
  const handleSourceDescription = (value) => {
    setSourceDescription(value)
  }
  const handleTagId = (value) => {
    setTagId(value)
  }
  const handleDeleteModal = (value) => {
    setDeleteModalVisible(value)
  }
  const handleDelete = async () => {
    setDeletingData(true)
    try {
      const deleteResponse = await axios.delete(`/customerAcquisition/reference?id=${referenceId}`)
      if (deleteResponse.status === 200) {
        showToastMessage('Source deleted successfully!')
        setDeletingData(false)
        handleDeleteModal(false)
        history.goBack()
      }
    } catch (error) {
      showToastMessage('Failed to delete source. Please try again.', 'danger')
    } finally {
      setDeletingData(false)
    }
  }
  const saveSource = async () => {
    setSaveLoading(true)
    const input = {
      name: sourceName,
      category: sourceCategory,
      description: sourceDescription,
      referenceId: tagId,
    }

    if (referenceId) {
      try {
        let updateResponse = await axios.patch(`/customerAcquisition/reference?id=${referenceId}`, input)

        if (updateResponse.status == 200) {
          showToastMessage('Source updated successfully!')
          setSaveLoading(false)
          history.goBack()
        }
      } catch (error) {}
    } else {
      try {
        let createResponse = await axios.post(`/customerAcquisition/reference`, input)
        if (createResponse.status == 200) {
          showToastMessage('Source created successfully!')
          history.goBack()
        }
      } catch (error) {
        showToastMessage('Failed to save source. Please try again.', 'danger')
      } finally {
        setSaveLoading(false)
      }
    }
  }
  const getReferenceDetailsById = async () => {
    try {
      const referenceDetails = await axios.get(`/customerAcquisition/reference?id=${referenceId}`)

      if (referenceDetails?.data?._id) {
        const referenceData = referenceDetails.data
        setSourceCategory(referenceData.category)
        setSourceName(referenceData.name)
        setSourceDescription(referenceData.description)
        setTagId(referenceData.referenceId)
      }
    } catch (error) {
      console.error('Error fetching reference details:', error) // Log the error for debugging
    }
  }

  useEffect(() => {
    const issaveDisabled = !sourceName || !sourceCategory || !sourceDescription || !tagId

    setsaveDisabled(issaveDisabled)
  }, [sourceName, sourceCategory, sourceDescription, tagId])
  useEffect(() => {
    if (referenceId) {
      getReferenceDetailsById()
    }
  }, [referenceId])

  return (
    <IonPage id='main-content'>
      <CommonHeader
        title={'Acquisition Source'}
        handleDeleteModal={handleDeleteModal}
        deleteModalVisible={deleteModalVisible}
        deleteButton={true}
        deleteTitle={'Delete Source'}
        handleDelete={handleDelete}
        deletingData={deletingData}
        deleteMessage={'Are You Sure You want to delete this source?'}
        backIcon={true}
        // deleteButton={true}
        // deleteTitle={'Delete Source'}
        // loading={saveLoading}
        // // saveButton={true}
        // // saveDisabled={saveDisabled}
        // title={'Acquisition Source'}
        // backIcon={true}
        // handleSaveButton={saveSource}
      ></CommonHeader>
      <IonContent>
        <StandardContainer>
          <div>
            <IonRow style={{ margin: '1rem' }}>
              <IonLabel style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>{referenceId ? 'Update Source' : 'Add a source'}</IonLabel>
            </IonRow>
            <IonList inset={true} mode='ios'>
              <IonItem>
                <IonInput
                  onIonInput={(e) => {
                    handleSourceName(e.detail.value)
                  }}
                  value={sourceName}
                  placeholder='Name (What are you tracking)'
                ></IonInput>
              </IonItem>
              <IonItem>
                <IonInput
                  onIonInput={(e) => {
                    handleSourceCategory(e.detail.value)
                  }}
                  value={sourceCategory}
                  placeholder='Categories'
                ></IonInput>
              </IonItem>
              <IonItem>
                <IonTextarea
                  onIonInput={(e) => {
                    handleSourceDescription(e.detail.value)
                  }}
                  style={{ padding: '1rem 0' }}
                  rows={5}
                  value={sourceDescription}
                  placeholder='Description'
                ></IonTextarea>
              </IonItem>
            </IonList>
            <IonList inset={true} mode='ios'>
              <IonItem>
                <IonLabel>Tag Id : </IonLabel>
                <IonInput
                  onIonInput={(e) => {
                    handleTagId(e.detail.value)
                  }}
                  value={tagId}
                  placeholder='Enter Tag Id'
                ></IonInput>
              </IonItem>
            </IonList>
            <div style={{ margin: '1rem' }}>
              <IonGrid>
                <IonRow>
                  <IonLabel style={{ lineHeight: '2rem', fontSize: '1.2rem', fontWeight: 'bold' }}>How do i use this?</IonLabel>
                </IonRow>
                <IonRow>
                  <IonLabel style={{ lineHeight: '1.5rem', fontSize: '1.2rem' }}>
                    Add the Tag ID to the end of your website with the query parameter 'refid'.
                  </IonLabel>
                </IonRow>
              </IonGrid>
              <IonGrid style={{ marginTop: '1rem' }}>
                <IonRow>
                  <IonLabel style={{ lineHeight: '1.5rem', fontSize: '1.2rem' }}>Example:</IonLabel>
                </IonRow>
                <IonRow>
                  <IonLabel style={{ lineHeight: '1.5rem', fontSize: '1.2rem' }}>https://app.clubcuts.com?refid={tagId}</IonLabel>
                </IonRow>
              </IonGrid>
              <IonButton onClick={saveSource} style={{ marginTop: '1.5rem' }} expand='full'>
                {referenceId ? 'Update Tag' : 'Create Tag'}
              </IonButton>
            </div>
          </div>
          <IonToast
            position='top'
            isOpen={toast.isOpen}
            message={toast.message}
            color={toast.color}
            onDidDismiss={() => setToast((prev) => ({ ...prev, isOpen: false }))}
            duration={2000}
          />
        </StandardContainer>
      </IonContent>
    </IonPage>
  )
}
