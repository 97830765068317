import { IonContent, IonInput, IonItem, IonLabel, IonList, IonModal, IonToast } from '@ionic/react'
import { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../auth'
import axios from 'axios'
import GetLocationDataBasedOnLocationId from '../Utils/GetLocationDataBasedOnLocationId'
import CommonHeader from '../commonComponents/CommonHeader'
import StandardContainer from '../commonComponents/StandardContainer'

export default function BookingThreshold() {
  const { locationId, businessData, locationData, getBusinessData } = useContext(AuthContext)
  const selectedLocationData = GetLocationDataBasedOnLocationId(locationData, locationId)

  const [thresholdValue, setThresholdValue] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [valueChanged, setValueChanged] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [toast, setToast] = useState({ isOpen: false, message: '', color: '' })

  const showToastMessage = (message, color = 'success') => {
    setToast({ isOpen: true, message, color })
  }

  const openModal = () => {
    setModalOpen(true)
  }
  const closeModal = () => {
    setModalOpen(false)
  }
  const handleSave = async () => {
    setLoading(true)
    try {
      const input = {
        businessId: businessData?._id,
        appointmentSettings: {
          ...selectedLocationData?.appointmentSettings,
          futureAppointmentsLimit: parseInt(thresholdValue),
        },
      }

      const response = await axios.patch(`/location_v2?id=${locationId}`, input)
      if (response.status === 200) {
        setLoading(false)
        getBusinessData()
        closeModal()
        showToastMessage('Booking threshold updated successfully.')
      }
    } catch (error) {
      setLoading(false)
      console.error('Error saving booking threshold:', error)
      showToastMessage('Error saving booking threshold. Please try again later.', 'danger')
    }
  }

  const handleThresholdValue = async (e) => {
    setValueChanged(true)

    const value = e.detail.value

    // Validate if the value is a positive number
    if (!/^\d*\.?\d*$/.test(value) || parseFloat(value) < 0) {
      // If not a valid positive number, you can set an error message or take appropriate action
      // For example, set an error state or display an error message
      setError('Please enter a valid positive number')
    } else {
      setError('')
    }

    // If the value is a valid positive number, update the threshold value state
    setThresholdValue(value)
  }

  useEffect(() => {
    setThresholdValue(selectedLocationData?.appointmentSettings?.futureAppointmentsLimit)
  }, [locationId, businessData, modalOpen])

  return (
    <>
      <IonList inset={true} mode='ios'>
        <IonItem style={{ cursor: 'pointer' }} onClick={openModal} detail={true}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <IonLabel>Booking Threshold </IonLabel>
            <IonLabel style={{ marginLeft: '0.5rem', fontSize: '12px', color: 'grey' }}>
              {`(${selectedLocationData?.appointmentSettings?.futureAppointmentsLimit})`}
            </IonLabel>
          </div>
        </IonItem>
      </IonList>
      <IonModal isOpen={modalOpen} onDidDismiss={closeModal}>
        <CommonHeader
          title={'Booking Threshold'}
          closeButton={true}
          loading={loading}
          saveButton={true}
          saveDisabled={!thresholdValue || !valueChanged || error !== ''}
          closeModal={closeModal}
          handleSaveButton={handleSave}
        />
        <IonContent className='regularBackground'>
          <StandardContainer>
            <IonList inset={true} mode='ios'>
              <IonItem>
                <IonLabel position='stacked'>Booking Threshold</IonLabel>
                <IonInput value={thresholdValue} onIonInput={handleThresholdValue} type='number' placeholder='Enter threshold value'></IonInput>
              </IonItem>
              <IonLabel style={{ margin: '1rem', color: 'red', fontSize: '12px' }}>{error}</IonLabel>
            </IonList>
          </StandardContainer>
        </IonContent>
      </IonModal>
      <IonToast
        position='top'
        isOpen={toast.isOpen}
        onDidDismiss={() => setToast({ ...toast, isOpen: false })}
        message={toast.message}
        color={toast.color}
        duration={2000}
      />
    </>
  )
}
