import React, { useState, useEffect, useContext } from 'react'
import ResultsList from './resultsList'
import axios from 'axios'
import AddDialog from './addDialog'
import { AuthContext } from '../auth'
import { IonContent, IonPage } from '@ionic/react'
import AddHeader from '../commonComponents/AddHeader'
import StandardContainer from '../commonComponents/StandardContainer'

export default function LocationListView({ locationId, openCreatePopUp = false }) {
  const { businessData } = useContext(AuthContext)
  const [locations, setLocations] = useState([])
  const [addLocationDialogOpen, setAddLocationDialogOpen] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState('')
  const [loading, setLoading] = React.useState(false)
  useEffect(() => {
    getLocationsList()
  }, [businessData])
  useEffect(() => {
    if (openCreatePopUp) {
      setAddLocationDialogOpen(true)
    }
  }, [openCreatePopUp])

  const getLocationsList = async () => {
    if (!businessData?._id) return
    setLoading(true)
    setErrorMessage(null)
    try {
      let locQuery = locationId ? `&locationId=${locationId}` : ''
      let response = await axios.get(`/location_v2/getList?businessId=${businessData._id}${locQuery}`)
      setLocations(response.data)
    } catch (error) {
      setErrorMessage('There was an issue fetching the data')
    }
    setLoading(false)
  }

  const openAddLocationDialog = () => {
    setAddLocationDialogOpen(true)
  }
  const closeAddLocationDialog = () => {
    getLocationsList()
    setAddLocationDialogOpen(false)
  }

  return (
    <IonPage>
      <AddHeader title={'Locations'} handleAddClick={openAddLocationDialog} loading={loading} />
      <IonContent>
        <StandardContainer>
          {errorMessage ? <p style={{ color: 'red', margin: 20 }}>{errorMessage}</p> : null}
          <ResultsList locations={locations} locationId={locationId} />
          <AddDialog open={addLocationDialogOpen} handleClose={closeAddLocationDialog} businessId={businessData._id} locationId={locationId} />
        </StandardContainer>
      </IonContent>
    </IonPage>
  )
}
