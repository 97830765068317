import axios from 'axios'
import { useEffect, useState } from 'react'
import CancelledSubscriptionsResultList from './CancelledSubscriptionsResultList'
import { IonRefresher, IonRefresherContent } from '@ionic/react'

export default function CancelledSubscriptionsList() {
  const [cancelledList, setCancelledList] = useState([])
  const [loading, setLoading] = useState(false)
  const getCancelledSubscriptions = async () => {
    setLoading(true)

    try {
      const response = await axios.get('/subscription/canceled/list')
      if (response?.data?.length > 0) {
        setCancelledList(response.data)
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }
  async function handleRefresh(event) {
    await getCancelledSubscriptions()
    event.detail.complete()
  }

  useEffect(() => {
    getCancelledSubscriptions()
  }, [])

  return (
    <>
      <IonRefresher slot='fixed' onIonRefresh={handleRefresh}>
        <IonRefresherContent></IonRefresherContent>
      </IonRefresher>
      <CancelledSubscriptionsResultList loading={loading} cancelledList={cancelledList} />
    </>
  )
}
