import { IonCol, IonIcon, IonLabel, IonRow } from '@ionic/react'
import { personAddOutline } from 'ionicons/icons'
import { useHistory } from 'react-router'
import { colors } from '../../../theme/colors'

export default function ClientInfo({ appointment, closeModal }) {
  const history = useHistory()
  const navigateToCustomerProfile = () => {
    closeModal()
    history.push(`/core/customers/${appointment?.customer?._id}`)
  }
  return (
    <div
      onClick={navigateToCustomerProfile}
      style={{
        cursor: 'pointer',
        flex: '1 1 0',
        padding: 8,
        background: '#EBEBEB',
        borderRadius: 12,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 8,
        display: 'inline-flex',
      }}
    >
      <div style={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 4, display: 'flex' }}>
        <div style={{ width: 24, height: 24, position: 'relative' }}>
          <IonIcon style={{ height: 30, width: 30, marginBottom: '0.5rem', color: colors.black70 }} icon={personAddOutline} />
        </div>
        <div style={{ color: '#525252', fontSize: 16, fontFamily: 'Figtree', fontWeight: '600', lineHeight: '24px', wordWrap: 'break-word' }}>Client Info</div>
      </div>
    </div>
  )
}
