import { useState, useEffect } from 'react'
import GeneralDetails from './details_general'
import Schedule from './schedule'
import axios from 'axios'
import Purchase from './details_purchase_settings'
import { useHistory } from 'react-router-dom'
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonLabel,
  IonPage,
  IonList,
  IonItem,
  IonModal,
  IonSpinner,
  IonTitle,
  IonToolbar,
  IonIcon,
  IonToast,
  IonToggle,
  IonGrid,
  IonRow,
  IonCol,
} from '@ionic/react'
import StandardContainer from '../commonComponents/StandardContainer'
import AdvancedDetails from './AdvancedDetails'
import { informationCircleOutline, calendarOutline, cartOutline, settingsOutline, codeDownloadOutline, cashOutline, imageOutline } from 'ionicons/icons'
import DetailsRelationshipToBusiness from './details_relationship_to_business'
import RelationshipToBusiness from './RelationshipToBusiness'
import PaymentProcessorSettings from './PaymentProcessorSettings'
import { AccessControl } from '../AccessControl'
import Images from './Images'

export default function Details({ match }) {
  const locationId = match?.params?.id
  const history = useHistory()
  const [tabValue, setTabValue] = useState(0)
  const [location, setLocation] = useState()
  const [locationTemp, setLocationTemp] = useState()
  const [locationChanged, setLocationChanged] = useState(false)
  const [activeTab, setActiveTab] = useState(null)
  const [isSaving, setIsSaving] = useState(false)
  const [toast, setToast] = useState({ isOpen: false, message: '', color: '' })

  const getLocation = async () => {
    try {
      const response = await axios.get(`/location_v2?id=${locationId}`)
      setLocation(response.data) // Update state with the location data
      setLocationTemp(response.data) // Update temporary state if needed
    } catch (error) {
      console.error('Error fetching location:', error) // Log the error for debugging
      // Optionally, you can set an error state to inform the user
    } finally {
    }
  }

  useEffect(() => {
    getLocation()
  }, [locationId])

  const updateLocation = (value) => {
    console.log(value, 'valuevalue')
    setLocationTemp(value)
    setLocationChanged(true)
  }

  const showToastMessage = (message, color = 'success') => {
    setToast({ isOpen: true, message, color })
  }

  const save = async () => {
    setIsSaving(true)
    try {
      if (locationTemp.reviewIndex) {
        delete locationTemp.reviewIndex
      }
      if (locationTemp.twilioPhoneNumber) {
        delete locationTemp.twilioPhoneNumber
      }
      if (locationTemp.images) {
        delete locationTemp.images
      }
      // Filter out empty location images
      if (locationTemp.locationImages) {
        locationTemp.locationImages = locationTemp.locationImages.filter((image) => image !== null && image !== undefined && image !== '')
      }
      console.log(locationTemp, 'locationTemplocationTemp')
      await axios.patch(`/location_v2?id=${locationId}`, locationTemp)
      await getLocation()
      setLocationChanged(false)
      setActiveTab(null)
      showToastMessage('Location saved successfully.')
    } catch (error) {
      console.error('Error saving location:', error)
      showToastMessage('There was a problem saving the location. Please try again.', 'danger')
    } finally {
      setIsSaving(false)
    }
  }
  const cancel = () => {
    setLocationTemp(location)
    setLocationChanged(false)
  }
  const toggleIsPublic = async (value) => {
    setLocationTemp((prev) => ({ ...prev, isPublic: value }))
    setIsSaving(true)
    try {
      if (locationTemp.reviewIndex) {
        delete locationTemp.reviewIndex
      }
      if (locationTemp.twilioPhoneNumber) {
        delete locationTemp.twilioPhoneNumber
      }
      if (locationTemp.images) {
        delete locationTemp.images
      }
      let data = { ...locationTemp, isPublic: value }

      await axios.patch(`/location_v2?id=${locationId}`, data)
      await getLocation()

      showToastMessage('Location Updated successfully.')
    } catch (error) {
      console.error('Error saving location:', error)
      showToastMessage('There was a problem saving the location. Please try again.', 'danger')
    } finally {
      setIsSaving(false)
    }
  }
  const deleteLocation = async () => {
    if (window.confirm('Are you sure you want to delete the location?')) {
      try {
        await axios.delete(`/location_v2?id=${locationId}`)
        history.push(-1)
        showToastMessage('Location deleted successfully.')
      } catch (error) {
        console.error('Error deleting location:', error)
        showToastMessage('There was a problem deleting the location. Please try again.', 'danger')
      }
    }
  }

  let body

  if (!location)
    body = (
      <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', height: 200 }}>
        <IonSpinner name='dots' />
      </div>
    )
  if (activeTab === 'general') {
    body = <GeneralDetails location={locationTemp} updateLocation={updateLocation} locationId={locationId} />
  } else if (activeTab === 'images') {
    body = <Images location={locationTemp} updateLocation={updateLocation} locationId={locationId} />
  } else if (activeTab === 'schedule') {
    body = <Schedule location={locationTemp} updateLocation={updateLocation} />
  } else if (activeTab === 'purchases') {
    body = <Purchase location={locationTemp} updateLocation={updateLocation} />
  } else if (activeTab === 'advanced') {
    body = <AdvancedDetails deleteLocation={deleteLocation} />
  } else if (activeTab === 'relationship') {
    body = <DetailsRelationshipToBusiness location={locationTemp} updateLocation={updateLocation} />
  } else if (activeTab == 'payment_processor') {
    body = <PaymentProcessorSettings location={locationTemp} updateLocation={updateLocation} saveLocation={save} />
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color='black' style={{ backgroundColor: 'white', color: 'black' }}>
          <IonButtons slot='start'>
            <IonBackButton />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className='regularBackground'>
        <StandardContainer padding={20}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <h1>{locationTemp ? locationTemp?.name : 'Loading...'}</h1>
          </div>
          <RelationshipToBusiness relationshipToBusiness={locationTemp?.relationshipToBusiness} />
          <p style={{ color: 'grey' }}>{locationTemp?.addressStreet1 ? `${locationTemp.addressStreet1 || ''}` : ''}</p>
          <p style={{ marginTop: -14, color: 'grey' }}>
            {locationTemp?.addressStreet1 ? `${locationTemp.addressCity || ''}, ${locationTemp.addressState || ''} ${locationTemp.addressZipcode || ''}` : ''}
          </p>
          <IonGrid style={{ margin:0, padding:0 }}>
            <IonRow  style={{ margin:0, padding:0 }}>
              <IonCol size='auto' style={{ margin:0, padding:0 }}>
                <AccessControl componentName='location_public_toggle'>
                  <IonToggle
                    checked={locationTemp?.isPublic} // Toggle state bound to isPublic
                    onIonChange={(e) => toggleIsPublic(e.detail.checked)} // Toggle handler
                    style={{ marginLeft: 0, marginTop: 12 }}
                  />
                </AccessControl>
              </IonCol>
              <IonCol size='auto' style={{ margin:0, padding:0, marginTop:-8, marginLeft: 10 }}>
                <h1 style={{ fontSize: 14 }}>{locationTemp?.isPublic ? '(Public)' : ''}</h1>
              </IonCol>
            </IonRow>
          </IonGrid>

          <h5 style={{ marginTop: 30 }}>Settings</h5>
          <IonList>
            <AccessControl componentName='location_general'>
              <IonItem button onClick={() => setActiveTab('general')}>
                <IonIcon icon={informationCircleOutline} slot='start' />
                <IonLabel>General</IonLabel>
              </IonItem>
            </AccessControl>
            <IonItem button onClick={() => setActiveTab('images')}>
              <IonIcon icon={imageOutline} slot='start' />
              <IonLabel>Images</IonLabel>
            </IonItem>
            <AccessControl componentName='location_schedule'>
              <IonItem button onClick={() => setActiveTab('schedule')}>
                <IonIcon icon={calendarOutline} slot='start' />
                <IonLabel>Schedule</IonLabel>
              </IonItem>
            </AccessControl>
            <AccessControl componentName='location_advanced_all'>
              <IonItem button onClick={() => setActiveTab('purchases')}>
                <IonIcon icon={cartOutline} slot='start' />
                <IonLabel>Purchase Settings</IonLabel>
              </IonItem>
              <IonItem button onClick={() => setActiveTab('relationship')}>
                <IonIcon icon={codeDownloadOutline} slot='start' />
                <IonLabel>Relationship to Business</IonLabel>
              </IonItem>
              <IonItem button onClick={() => setActiveTab('payment_processor')}>
                <IonIcon icon={cashOutline} slot='start' />
                <IonLabel>Payment Processor Settings</IonLabel>
              </IonItem>
              <IonItem button onClick={() => setActiveTab('advanced')}>
                <IonIcon icon={settingsOutline} slot='start' />
                <IonLabel>Advanced</IonLabel>
              </IonItem>
            </AccessControl>
          </IonList>

          {body}
        </StandardContainer>
      </IonContent>
      <IonModal isOpen={activeTab !== null} onDidDismiss={() => setActiveTab(null)}>
        <IonHeader>
          <IonToolbar color='white' style={{ backgroundColor: 'white', color: 'black' }}>
            <IonTitle style={{ textTransform: 'capitalize' }}>{activeTab}</IonTitle>
            {isSaving ? (
              <IonButtons slot='end'>
                <IonSpinner name='dots' />
              </IonButtons>
            ) : locationChanged ? (
              <>
                <IonButtons slot='end'>
                  <IonButton
                    onClick={async () => {
                      await cancel()
                      setActiveTab(null)
                    }}
                  >
                    Cancel
                  </IonButton>
                </IonButtons>
                <IonButtons slot='end'>
                  <IonButton color='primary' onClick={save}>
                    Save
                  </IonButton>
                </IonButtons>
              </>
            ) : (
              <IonButtons slot='end'>
                <IonButton onClick={() => setActiveTab(null)}>Close</IonButton>
              </IonButtons>
            )}
          </IonToolbar>
        </IonHeader>
        <IonContent>{body}</IonContent>
      </IonModal>

      <IonToast
        position='top'
        isOpen={toast.isOpen}
        onDidDismiss={() => setToast({ ...toast, isOpen: false })}
        message={toast.message}
        color={toast.color}
        duration={2000}
      />
    </IonPage>
  )
}
