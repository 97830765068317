import { IonIcon, IonSpinner } from '@ionic/react'
import { colors } from '../../../theme/colors'
import { sync } from 'ionicons/icons'
import styles from '../Styles/ReScheduleAppointmentStyles'

export default function RescheduleAppointment({ appointment, updateStatus, updatingStatus }) {
  return (
    <div onClick={() => !updatingStatus && updateStatus('Scheduled')} style={styles.container}>
      <div style={styles.iconContainer}>
        <div style={styles.iconWrapper}>
          {updatingStatus ? <IonSpinner name='dots' /> : <IonIcon style={{ ...styles.icon, color: colors.black70 }} icon={sync} />}
        </div>
        <div style={styles.text}>Reschedule</div>
      </div>
    </div>
  )
}
