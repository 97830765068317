import { useState, useEffect } from 'react'
import { IonButton, IonContent, IonHeader, IonTitle, IonToolbar, IonBackButton, IonButtons, IonPage, IonSpinner, IonToast } from '@ionic/react'
import axios from 'axios'
import { useHistory } from 'react-router-dom'
import StandardContainer from '../commonComponents/StandardContainer'
import DetailsView from './details_general'

export default function Details({ match }) {
  const discount_id = match?.params?.id
  const [discount, setDiscount] = useState()
  const [discountTemp, setDiscountTemp] = useState()
  const [discountChanged, setDiscountChanged] = useState(false)
  const [error, setError] = useState('')
  const history = useHistory()
  const [nameError, setNameError] = useState('')
  const [toast, setToast] = useState({ isOpen: false, message: '', color: '' })
  const showToastMessage = (message, color) => {
    setToast({ isOpen: true, message, color })
  }
  const handleNameError = (error) => {
    setNameError(error)
  }
  const getDiscount = async () => {
    try {
      let response = await axios.get(`/discount?id=${discount_id}`)
      setDiscount(response.data)
      setDiscountTemp(response.data)
    } catch (error) {
      showToastMessage('Error fetching discount data', 'danger')
    }
  }
  useEffect(() => {
    getDiscount()
  }, [discount_id])

  const updateDiscount = (value) => {
    setDiscountTemp(value)
    setDiscountChanged(true)
    if (value.discountType === 'percentage' && value.discountValue > 100) {
      setError('Percentage discount value cannot be more than 100')
    } else {
      setError('')
    }
  }

  const save = async () => {
    try {
      let discountInput = { ...discountTemp }
      await axios.patch(`/discount?id=${discount_id}`, discountInput)
      await getDiscount()
      setDiscountChanged(false)
      showToastMessage('Discount saved successfully', 'success')
    } catch (error) {
      showToastMessage('Error saving discount', 'danger')
    }
  }

  const cancel = () => {
    setDiscountTemp(discount)
    setDiscountChanged(false)
  }

  if (!discount) {
    return (
      <IonPage>
        <IonHeader>
          <IonToolbar color='black' style={{ backgroundColor: 'white', color: 'black' }}>
            <IonButtons slot='start'>
              <IonBackButton />
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonSpinner name='dots' />
        </IonContent>
      </IonPage>
    )
  }

  const isDisabled =
    discountTemp.name.trim() === '' ||
    !discountTemp.discountType ||
    !discountTemp.couponType ||
    discountTemp.discountValue.trim() === '' ||
    error.trim() !== '' ||
    nameError.trim() !== ''

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color='black' style={{ backgroundColor: 'white', color: 'black' }}>
          <IonButtons slot='start'>
            <IonBackButton />
          </IonButtons>
          <IonTitle>{discountTemp?.name || 'loading...'}</IonTitle>
          {discountChanged ? (
            <>
              <IonButtons slot='end'>
                <IonButton onClick={cancel}>Cancel</IonButton>
              </IonButtons>
              <IonButtons slot='end'>
                <IonButton disabled={isDisabled} color='primary' onClick={save}>
                  Save
                </IonButton>
              </IonButtons>
            </>
          ) : null}
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <StandardContainer>
          <DetailsView
            handleNameError={handleNameError}
            nameError={nameError}
            error={error}
            discountData={discount}
            discount={discountTemp}
            updateDiscount={updateDiscount}
          />
          <IonToast
            position='top'
            isOpen={toast.isOpen}
            message={toast.message}
            color={toast.color}
            duration={2000}
            onDidDismiss={() => setToast({ isOpen: false, message: '', color: '' })}
          />
        </StandardContainer>
      </IonContent>
    </IonPage>
  )
}
