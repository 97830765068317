import React, { useState, useContext, useEffect } from 'react'
import {
  IonButtons,
  IonButton,
  IonModal,
  IonHeader,
  IonContent,
  IonToolbar,
  IonTitle,
  IonAvatar,
  IonItem,
  IonLabel,
  IonList,
  IonIcon,
  IonToast, // Import IonToast
} from '@ionic/react'
import { AuthContext } from '../auth'
import axios from 'axios'
import Avatar from '../commonComponents/avatar'
import { person } from 'ionicons/icons'

function SalesCreditModal({ isOpen, setIsOpen, locationId, subscriptionId, onSuccess }) {
  const { businessData } = useContext(AuthContext)
  const [employees, setEmployees] = useState([])
  const [loading, setLoading] = useState(false)
  const [saving, setSaving] = useState(false)
  const [errorMessage, setErrorMessage] = React.useState('')
  const [toast, setToast] = useState({ isOpen: false, message: '', color: '' })

  function dismiss() {
    setIsOpen(false)
  }

  useEffect(() => {
    getEmployeesList()
  }, [])

  const getEmployeesList = async () => {
    if (!businessData?._id) return
    setLoading(true)
    setErrorMessage('')
    try {
      let locQuery = locationId ? `&locationId=${locationId}` : ''
      let response = await axios.get(`/employee/getList?businessId=${businessData._id}${locQuery}`)
      setEmployees(response.data)
    } catch (error) {
      console.error('There was an error loading employees. Try again', error) // Log the error for debugging
      setErrorMessage('There was an error loading. Try again')
    }
    setLoading(false)
  }

  const updateEmployee = async (id) => {
    setSaving(true)
    try {
      await axios.patch(`/subscription/updateSalesCredit`, { barberId: id, subscriptionId })
      await onSuccess()
      showToastMessage('Sales credit updated successfully!', 'success')
      dismiss()
    } catch (error) {
      showToastMessage('There was an issue saving the sales credit. Try again', 'danger')
    }
    setSaving(false)
  }

  const noCredit = async () => {
    setSaving(true)
    try {
      await axios.patch(`/subscription/updateSalesCredit`, { subscriptionId, noCredit: true })
      await onSuccess()
      showToastMessage('No credit assigned successfully!', 'success')
      dismiss()
    } catch (error) {
      showToastMessage('There was an issue saving the no credit option. Try again', 'danger')
    }
    setSaving(false)
  }

  const showToastMessage = (message, color) => {
    setToast({ isOpen: true, message, color })
  }

  return (
    <IonModal isOpen={isOpen}>
      <IonHeader>
        <IonToolbar color='white' style={{ backgroundColor: 'white', color: 'black' }}>
          <IonTitle>Sales Credit</IonTitle>
          <IonButtons slot='end'>
            <IonButton onClick={() => dismiss()}>Close</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className='regularBackground'>
        <h6 style={{ color: 'grey', marginLeft: 20 }}>Who got credit for the sale?</h6>
        <IonList inset={true} mode='ios'>
          <IonItem
            button
            onClick={() => {
              noCredit()
            }}
          >
            <IonAvatar
              slot='start'
              style={{
                marginTop: 10,
                marginBottom: 10,
                backgroundColor: '#ddd',
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
              }}
            >
              -
            </IonAvatar>
            <IonLabel>
              <h4 style={{ fontWeight: '600', color: 'black', fontSize: 18 }}>Nobody (Self Sale)</h4>
            </IonLabel>
          </IonItem>
          {employees?.map((row) => (
            <IonItem
              key={row._id}
              button
              onClick={() => {
                updateEmployee(row._id)
              }}
            >
              <IonAvatar slot='start' style={{ marginTop: 10, marginBottom: 10 }}>
                <Avatar employee={row} businessId={businessData._id} small />
              </IonAvatar>
              <IonLabel>
                <h4 style={{ fontWeight: '600', color: 'black', fontSize: 18 }}>{`${row.firstName} ${row.lastName}`}</h4>
              </IonLabel>
            </IonItem>
          ))}
        </IonList>
      </IonContent>
      {/* Toast Notification */}
      <IonToast
        position='top'
        isOpen={toast.isOpen}
        onDidDismiss={() => setToast({ ...toast, isOpen: false })}
        message={toast.message}
        color={toast.color}
        duration={2000}
      />
    </IonModal>
  )
}

export default SalesCreditModal
