import React from 'react'
import { GetReviewStylesBasedOnStatus } from './GetReviewStylesBasedOnStatus'
import { IonItem, IonList } from '@ionic/react'
import ReviewSiteItem from './ReviewSiteItem'

export default function ReviewSitesList({ reviewSites, openSetupReviewSite }) {
  return (
    <IonList inset={true} style={{ margin: '1rem ' }} mode='ios'>
      {reviewSites &&
        reviewSites.map((reviewSite, reviewSiteIndex) => {
          const statusStyles = GetReviewStylesBasedOnStatus(reviewSite.status)

          return (
            <IonItem
              onClick={() => openSetupReviewSite(reviewSite)}
              key={`reviewSite${reviewSiteIndex}`}
              detail={true}
            >
              <ReviewSiteItem reviewSite={reviewSite} statusStyles={statusStyles} />
            </IonItem>
          )
        })}
    </IonList>
  )
}
