import { useState, useEffect, useContext } from 'react'
import ResultsList from './resultsList'
import axios from 'axios'
import { AuthContext } from '../auth'
import { IonContent, IonRefresher, IonRefresherContent, useIonViewWillEnter } from '@ionic/react'
import StandardContainer from '../commonComponents/StandardContainer'
import { useLocation } from 'react-router'

export default function InactiveUsers({ updateLoader }) {
  const { businessData, locationId } = useContext(AuthContext)
  const [users, setUsers] = useState([])
  const [errorMessage, setErrorMessage] = useState(null)
  let locQuery = locationId ? `&locationId=${locationId}` : ''

  const location = useLocation()
  useEffect(() => {
    getInactiveUsersList()
  }, [locationId])
  useIonViewWillEnter(() => {
    getInactiveUsersList()
  })
  useEffect(() => {
    if (location.pathname == '/core/users') {
      getInactiveUsersList()
    }
  }, [location])

  const getInactiveUsersList = async () => {
    if (!businessData?._id) return
    updateLoader(true)
    setErrorMessage(null)
    try {
      let response = await axios.get(
        `/user/list/inactive?businessId=${businessData._id}${locQuery}`,
      )
      setUsers(response.data)
    } catch (error) {
      setErrorMessage('Sorry there was an error fetching the data')
    }
    updateLoader(false)
  }

  async function handleRefresh(event) {
    await getInactiveUsersList(true)
    event.detail.complete()
  }

  return (
    <IonContent>
      <StandardContainer>
        <IonRefresher slot='fixed' onIonRefresh={handleRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        <ResultsList title={'InActive Users'} users={users} />
      </StandardContainer>
    </IonContent>
  )
}
