import { useEffect, useState } from 'react'
import { IonCol, IonGrid, IonRow, IonSelect, IonSelectOption, IonToggle, useIonPicker } from '@ionic/react'

let enabledColor = 'black'
let disabledColor = '#cdcdcd'

function removeItem(array, key) {
  const index = array.indexOf(key)
  if (index > -1) {
    // only splice array when item is found
    array.splice(index, 1) // 2nd parameter means remove one item only
    removeItem(array, key)
  }
  return array
}

export default function List({ locationServices, employeeServices = [], updateServices, employeeServicesInfo }) {
  let [tempEmployeeServices, setTempEmployeeServices] = useState(employeeServices || [])
  let [tempLocationServices, setTempLocationServices] = useState(locationServices || [])
  let [tempLocationServicesInfo, setTempLocationServicesInfo] = useState(locationServices || [])

  const [present] = useIonPicker()
  useEffect(() => {
    setTempLocationServices(locationServices || [])

    const servicesInfo = employeeServicesInfo?.map((item, index) => {
      return {
        _id: item._id,
        durationType: item.durationMin ? 'custom' : 'default',
        durationMin: item.durationMin ? item.durationMin : '',
      }
    })

    // Remove duplicates based on the _id property
    const uniqueServicesInfo = Array.from(new Set(servicesInfo?.map((item) => item._id)))?.map((_id) => servicesInfo.find((item) => item._id === _id))

    setTempLocationServicesInfo(uniqueServicesInfo || [])
  }, [locationServices, employeeServicesInfo])

  const getConvertedMins = (mins) => {
    return mins <= 60 ? `${mins} mins` : mins % 60 === 0 ? `${Math.floor(mins / 60)} hrs` : `${Math.floor(mins / 60)} hrs ${mins % 60} mins`
  }
  const durationOptions = []

  for (let mins = 5; mins <= 180; mins += 5) {
    durationOptions.push({
      text: getConvertedMins(mins),
      value: mins,
    })
  }

  if (!tempLocationServices) return null

  function handleSwitch(id, value) {
    let t = [...tempEmployeeServices]
    if (value) {
      if (!(t.indexOf(id) > -1)) {
        t.push(id)
      }
    } else {
      t = removeItem(t, id)
    }

    setTempEmployeeServices(t)
    updateServices(t)
  }

  return (
    <>
      <IonGrid>
        {tempLocationServices?.map((row) => {
          const selectedValue =
            tempLocationServicesInfo.find((item) => item._id === row._id)?.durationType === 'custom'
              ? tempLocationServicesInfo.find((item) => item._id === row._id)?.durationMin
              : row.durationMin

          return (
            <IonRow key={row._id}>
              <IonCol component='th' scope='row'>
                {row.name}
              </IonCol>
              <IonCol>
                <IonToggle
                  justify='space-between'
                  size='small'
                  checked={tempEmployeeServices.includes(row._id)}
                  onIonChange={(e) => {
                    handleSwitch(row._id, e.detail.checked)
                  }}
                />
              </IonCol>
              <IonCol
                style={{
                  color: tempEmployeeServices.includes(row._id) ? enabledColor : disabledColor,
                }}
              >
                ${row.price}
              </IonCol>

              <IonCol
                style={{
                  color: tempEmployeeServices.includes(row._id) ? enabledColor : disabledColor,
                }}
              >
                {row.durationMin} min
                {/* <IonSelect
                  onIonChange={e => {
                    handleDurationChange(e.detail.value, row)
                  }}
                  aria-label='Duration in Mins'
                  value={selectedValue}
                >
                  {durationOptions?.map(item => (
                    <IonSelectOption key={item.value} value={item.value}>
                      {item.text}
                    </IonSelectOption>
                  ))}
                </IonSelect> */}
              </IonCol>
            </IonRow>
          )
        })}
      </IonGrid>
    </>
  )
}
