import { IonContent, IonGrid, IonItem, IonLabel, IonList, IonPage, IonRow, IonIcon, IonMenuButton, IonButton, IonButtons } from '@ionic/react'
import { useContext, useEffect, useState } from 'react'
import { add, storefrontOutline } from 'ionicons/icons' // import the home icon from ionicons
import StandardContainer from '../commonComponents/StandardContainer'
import { AuthContext } from '../auth'
import { fontSizes, fontWeights } from '../theme/typography'
import DefaultMenu from '../commonComponents/SideMenu/DefaultMenu'
import { AccessControl } from '../AccessControl'
import { useHistory } from 'react-router'
import axios from 'axios'
import AddDialog from '../locations/addDialog'
export default function Locations() {
  const { locationId, businessData } = useContext(AuthContext)
  const [locations, setLocations] = useState([])
  const [addLocationDialogOpen, setAddLocationDialogOpen] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const getLocationsList = async () => {
    if (!businessData?._id) return
    setLoading(true)
    setErrorMessage(null)
    try {
      let response = await axios.get(`/location_v2/getList?businessId=${businessData._id}`)

      setLocations(response.data)
    } catch (error) {
      setErrorMessage('There was an issue fetching the data')
    }
    setLoading(false)
  }
  const history = useHistory()
  const handleLinkClick = () => {
    setAddLocationDialogOpen(true)
  }
  const closeAddLocationDialog = () => {
    getLocationsList()
    setAddLocationDialogOpen(false)
  }
  useEffect(() => {
    getLocationsList()
  }, [businessData])

  return (
    <>
      <DefaultMenu />
      <IonPage id='main-content'>
        <IonContent className='regularBackground'>
          <StandardContainer padding={20}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <IonMenuButton style={{ display: 'flex', width: 'fit-content' }} color={'dark'}>
                {' '}
                <IonIcon icon={storefrontOutline}></IonIcon>
              </IonMenuButton>
              <AccessControl componentName={'create_location_button'} businessLevelEntitlementsOnly={true}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <IonButtons>
                    <IonButton onClick={handleLinkClick}>
                      <IonIcon style={{ marginRight: 5 }} size='small' color='primary' icon={add} />
                      <IonLabel color='primary'>Add</IonLabel>
                    </IonButton>
                  </IonButtons>
                </div>
              </AccessControl>
            </div>
            <IonGrid>
              <IonRow>
                <IonLabel style={{ fontSize: fontSizes.size35, marginTop: 10, fontWeight: fontWeights.weightBold }}>Locations</IonLabel>
              </IonRow>
            </IonGrid>
            <IonList style={{ marginTop: 20 }} lines='none'>
              {locations?.map((location) => (
                <IonItem detail={true} style={{ padding: '5px 0' }}>
                  <IonGrid style={{ padding: 0, margin: '10px 0' }}>
                    <IonRow style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <IonLabel>
                        <h4 style={{ fontWeight: '600', color: 'black', fontSize: 18 }}>{`${location.name}`}</h4>
                        <p>
                          {location.addressStreet || location.addressState
                            ? `${location.addressStreet1 || ''}, ${location.addressCity || ''}, ${location.addressState || ''} ${location.addressZipcode || ''}`
                            : ''}
                        </p>

                        {/* Legend */}
                      </IonLabel>
                    </IonRow>
                  </IonGrid>
                </IonItem>
              ))}
            </IonList>
            <AddDialog open={addLocationDialogOpen} handleClose={closeAddLocationDialog} businessId={businessData._id} locationId={locationId} />
          </StandardContainer>
        </IonContent>
      </IonPage>
    </>
  )
}
