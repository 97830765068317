import { IonContent, IonPage } from '@ionic/react'
import CommonHeader from '../commonComponents/CommonHeader'
import StandardContainer from '../commonComponents/StandardContainer'
import ManageNotifications from './ManageNotificcations/ManageNotifications'

export default function CustomerNotifications({ match }) {
  const customer_id = match?.params?.id

  return (
    <IonPage id='main-content'>
      <CommonHeader title={'Manage Notifications'} backIcon={true}></CommonHeader>
      <IonContent>
        <StandardContainer>
          <ManageNotifications customer_id={customer_id} />
        </StandardContainer>
      </IonContent>
    </IonPage>
  )
}
