import { useState, useEffect, useContext } from 'react'
import { IonButton, IonCol, IonContent, IonInput, IonPage, IonRow, IonSpinner, useIonViewWillEnter } from '@ionic/react'
import CommonHeader from '../commonComponents/CommonHeader'
import './Messages.css'
import Conversation from './Conversation'
import ConversationsList from './ConversationsList'
import axios from 'axios'
import { AuthContext } from '../auth'
import { useHistory, useLocation } from 'react-router'
import NoConversationSelectedMessage from './NoConversationSelectedMessage'

function MessagesListView() {
  const [conversationList, setConversationList] = useState([])
  const [messages, setMessages] = useState([])
  const [selectedConversation, setSelectedConversation] = useState(null)
  const [draft, setDraft] = useState('')
  const [isDesktopView, setIsDesktopView] = useState(window.innerWidth >= 500)
  const { locationId, businessData, userData } = useContext(AuthContext)
  const [sendingMessage, setSendingMessage] = useState(false)
  const [loadingConversation, setLoadingConversation] = useState(true)
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const location = useLocation()

  const [page, setPage] = useState(1)
  const pageSize = 10
  const [totalMessages, setTotalMessages] = useState(0)
  const [loadingMessages, setLoadingMessages] = useState(false)
  const getConversationList = async () => {
    setLoading(true)
    try {
      const response = await axios.get(`/conversation?locationId=${locationId}`)

      if (response?.status == 200) {
        setLoading(false)

        setConversationList(response.data)
      }
    } catch (error) {}
  }
  useIonViewWillEnter(() => {
    getConversationList()
  })
  const getConversationMessages = async (twilioConversationId, page, showLoader) => {
    if (showLoader) {
      setLoadingConversation(true)
    } else {
      setLoadingMessages(true)
    }
    try {
      const conversationResponse = await axios.get(`/conversation/message?twilioConversationId=${twilioConversationId}&page=${page}&pageSize=${pageSize}`)
      if (conversationResponse.status == 200) {
        setTotalMessages(conversationResponse?.data?.total)
        if (showLoader) {
          setLoadingConversation(false)
        } else {
          setLoadingMessages(false)
        }
        const messagesSortedBasedOnCreatedTime = conversationResponse?.data?.messages.sort((a, b) => a.createdTimestamp - b.createdTimestamp)
        const sortedExistingMessages = messages.sort((a, b) => a.createdTimestamp - b.createdTimestamp)

        // Combine both sorted arrays
        const combinedMessages = messagesSortedBasedOnCreatedTime.concat(sortedExistingMessages)

        // Remove duplicates
        // const uniqueMessages = combinedMessages.filter((message, index, self) => {
        //   return index === self.findIndex(m => m.id === message.id)
        // })
        //
        // Set the unique messages to state
        setMessages(combinedMessages)
        setPage(page + 1)
      }
    } catch (error) {}
  }
  useEffect(() => {
    if (location.pathname == '/core/messages') {
      setPage(1)
      getConversationList()
    }
  }, [location])

  // Function to handle input changes
  const handleInputChange = (event) => {
    setDraft(event.detail.value)
  }

  // Function to send a message
  const sendMessage = async () => {
    if (draft.trim() !== '') {
      setSendingMessage(true)
      try {
        const messageInput = {
          locationId: locationId,
          customerId: selectedConversation.customerId,
          twilioConversationId: selectedConversation.twilioConversationId,
          conversationId: selectedConversation._id,
          body: draft,
          author: userData?._id,
          authorType: 'Employee',
        }

        const response = await axios.post('/conversation/message', messageInput)
        if (response.status == 200) {
          setSendingMessage(false)
          const updatedMessages = [...messages, messageInput]
          setMessages(updatedMessages)
        }
      } catch (error) {}

      setDraft('')
    }
  }

  const handleCustomerClick = async (conversation) => {
    setPage(1)
    if (isDesktopView) {
      setSelectedConversation(conversation)
      await getConversationMessages(conversation?.twilioConversationId, 1, true)
    } else {
      history.push(`/core/messages/${conversation?.twilioConversationId}`)
      // Navigate to separate conversation view for mobile
      // You can implement this navigation using React Router or your preferred navigation method
    }
  }

  const handleScroll = async (event) => {
    const { scrollTop, clientHeight, scrollHeight } = event.target

    // Store the current scroll position
    const currentScrollPosition = scrollTop

    if (scrollTop == 0 && !loading && messages.length < totalMessages) {
      await getConversationMessages(selectedConversation?.twilioConversationId, page, false)

      // Set the scroll position back to its previous value after loading new messages
      // event.target.scrollTop = currentScrollPosition
    }
  }

  useEffect(() => {
    const handleResize = () => {
      setIsDesktopView(window.innerWidth >= 1024)
    }

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])
  return (
    <IonPage id='main-content'>
      <CommonHeader progressBar={loading} title={'Messages'} backIcon={true}></CommonHeader>
      <IonContent className='regularBackground'>
        {isDesktopView && (
          <div class='container'>
            <ConversationsList selectedConversation={selectedConversation} handleCustomerClick={handleCustomerClick} conversationList={conversationList} />
            {}
            <div class='content'>
              {selectedConversation ? (
                <div class='message-wrap' onScroll={handleScroll}>
                  {loadingMessages && (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <IonSpinner name='dots' />
                    </div>
                  )}
                  <Conversation messages={messages} loadingConversation={loadingConversation} />
                </div>
              ) : (
                <NoConversationSelectedMessage />
              )}
              {selectedConversation && !loadingConversation && (
                <div class='message-footer'>
                  <IonRow>
                    <IonCol size='10'>
                      <IonInput disabled={sendingMessage} value={draft} placeholder='Type a message...' onIonChange={handleInputChange} />
                    </IonCol>
                    <IonCol size='2'>
                      <IonButton color={'dark'} disabled={!draft || sendingMessage} expand='block' onClick={sendMessage}>
                        {sendingMessage && <IonSpinner name='lines' style={{ marginRight: '1rem' }} />}
                        Send
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </div>
              )}
            </div>
          </div>
        )}
        {!isDesktopView && (
          <>
            <ConversationsList conversationList={conversationList} handleCustomerClick={handleCustomerClick} selectedConversation={selectedConversation} />
          </>
        )}
      </IonContent>
    </IonPage>
  )
}

export default MessagesListView
