import { IonItem, IonLabel, IonList, IonReorder, IonReorderGroup, IonButton, IonIcon, IonPopover, IonRow, IonGrid, IonCol, IonToast } from '@ionic/react'
import axios from 'axios'
import { ellipsisVertical } from 'ionicons/icons'
import { useEffect, useState } from 'react'

export default function ResultsList({ services = [] }) {
  const [servicesList, setServicesList] = useState(services)
  const [showPopover, setShowPopover] = useState(false)
  const [popoverEvent, setPopoverEvent] = useState(undefined)
  const [reorderEnabled, setReorderEnabled] = useState(false)
  const [toast, setToast] = useState({ isOpen: false, message: '', color: '' })

  useEffect(() => {
    setServicesList(services)
  }, [services])

  const showToastMessage = (message, color = 'success') => {
    setToast({ isOpen: true, message, color })
  }

  const handleReorder = async (event) => {
    const fromIndex = event.detail.from
    const toIndex = event.detail.to

    // Update the order of the servicesList array
    const updatedServices = [...servicesList]
    const [movedItem] = updatedServices.splice(fromIndex, 1)
    updatedServices.splice(toIndex, 0, movedItem)

    // Update the state with the new order
    const reorderedData = updatedServices.map((item, index) => ({
      id: item._id,
      position: index + 1,
    }))

    // Finish the reorder
    const reorderInput = {
      services: reorderedData,
    }

    try {
      const response = await axios.patch('/service/changePositions', reorderInput)

      if (response && response.status === 200) {
        setServicesList(updatedServices)
        event.detail.complete()
        showToastMessage('Services reordered successfully!', 'success')
      }
    } catch (error) {
      event.detail.complete()
      showToastMessage('Failed to reorder services.', 'danger')
    }
  }

  const openPopover = (e) => {
    e.persist()
    setPopoverEvent(e)
    setShowPopover(true)
  }

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '0 20px' }}>
        <h6 style={{ color: 'grey' }}>Services</h6>
        <IonButton fill='clear' onClick={openPopover} style={{ paddingTop: 4, marginRight: -20 }} color={'dark'}>
          <IonIcon icon={ellipsisVertical} />
        </IonButton>
      </div>
      <IonPopover isOpen={showPopover} event={popoverEvent} onDidDismiss={() => setShowPopover(false)}>
        <IonList>
          <IonItem
            button
            onClick={() => {
              setReorderEnabled(!reorderEnabled)
              setShowPopover(false)
            }}
          >
            {!reorderEnabled ? 'Reorder services' : 'Done with Reorder'}
          </IonItem>
        </IonList>
      </IonPopover>
      {reorderEnabled ? (
        <IonButton
          fill='clear'
          color={'danger'}
          onClick={() => {
            setReorderEnabled(!reorderEnabled)
          }}
        >
          Done with Reorder
        </IonButton>
      ) : null}
      <IonList inset={true} mode='ios'>
        <IonReorderGroup disabled={!reorderEnabled} onIonItemReorder={handleReorder}>
          {servicesList?.map((row) => (
            <IonItem button detail={true} routerLink={`/core/services/${row._id}`} routerDirection='forward' key={row._id}>
              <IonReorder>
                <IonGrid style={{ padding: 0, margin: 0 }}>
                  <IonRow style={{ padding: 0, margin: 0 }}>
                    {reorderEnabled ? (
                      <IonCol style={{ padding: 0, margin: 0 }} size='auto'>
                        <div style={{ height: 2, width: 20, backgroundColor: 'grey', margin: 4, marginTop: 20, marginLeft: 4 }} />
                        <div style={{ height: 2, width: 20, backgroundColor: 'grey', margin: 4 }} />
                        <div style={{ height: 2, width: 20, backgroundColor: 'grey', margin: 4 }} />
                        <div style={{ height: 2, width: 20, backgroundColor: 'transparent', marginLeft: 20 }} />
                      </IonCol>
                    ) : null}
                    <IonCol style={{ padding: 0, margin: 0 }}>
                      <IonLabel style={{ paddingTop: 8, paddingBottom: 8 }}>
                        <h4 style={{ fontWeight: '600', color: 'black', fontSize: 18 }}>{`${row.name}`}</h4>
                        <p>{row.durationMin} min</p>
                      </IonLabel>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonReorder>
            </IonItem>
          ))}
        </IonReorderGroup>
      </IonList>

      <IonToast
        position='top'
        isOpen={toast.isOpen}
        onDidDismiss={() => setToast({ ...toast, isOpen: false })}
        message={toast.message}
        color={toast.color}
        duration={2000}
      />
    </>
  )
}
