import { IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle } from '@ionic/react'
import { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import BusinessForm from './steps/business'
import CodeForm from './steps/code'
import ContactForm from './steps/contact'
import Intro from './steps/intro'
import LocationForm from './steps/locations'
import Personal from './steps/personal'
import SiteForm from './steps/site'
import SummaryForm from './steps/summary'
import BillingForm from './steps/billing'
import { AuthContext } from '../../auth'

const Flow = ({ data = [] }) => {
  const history = useHistory()
  const [flowIndex, setFlowIndex] = useState(0)
  const { locationId, businessData, locationData, selectedLocationData, getUserData, getBusinessData } = useContext(AuthContext)

  const onNext = () => {
    const index = flowIndex + 1
    setFlowIndex(index)
  }

  const onBack = () => {
    const index = flowIndex - 1
    setFlowIndex(index)
  }

  const onCancel = () => {
    setFlowIndex(0)
    history.push('../setup')
  }

  const percentComplete = data.length !== 0 ? ((flowIndex + 1) / data.length) * 100 : 0

  const getStep = (stepName) => {
    switch (stepName) {
      case 'intro':
        return <Intro data={data} flowIndex={flowIndex} onBack={onBack} onNext={onNext} onCancel={onCancel} history={history} />
      case 'businessform':
        return <BusinessForm data={data} flowIndex={flowIndex} onBack={onBack} onNext={onNext} onCancel={onCancel} history={history} businessData={businessData} getUserData={getUserData} getBusinessData={getBusinessData} />
      case 'location':
        return <LocationForm data={data} flowIndex={flowIndex} onBack={onBack} onNext={onNext} onCancel={onCancel} history={history} businessData={businessData} locationData={locationData} selectedLocationData={selectedLocationData} getBusinessData={getBusinessData} />
      case 'billing':
        return <BillingForm data={data} flowIndex={flowIndex} onBack={onBack} onNext={onNext} onCancel={onCancel} history={history} businessData={businessData} locationData={locationData} locationId={locationId} />
      case 'personal':
        return <Personal data={data} flowIndex={flowIndex} onBack={onBack} onNext={onNext} onCancel={onCancel} history={history} />
      case 'code':
        return <CodeForm data={data} flowIndex={flowIndex} onBack={onBack} onNext={onNext} onCancel={onCancel} history={history} />
      case 'website':
        return <SiteForm data={data} flowIndex={flowIndex} onBack={onBack} onNext={onNext} onCancel={onCancel} history={history} />
      case 'contact':
        return <ContactForm data={data} flowIndex={flowIndex} onBack={onBack} onNext={onNext} onCancel={onCancel} history={history} />
      case 'summary':
        return <SummaryForm data={data} flowIndex={flowIndex} onBack={onBack} onNext={onNext} onCancel={onCancel} history={history} />

      default:
        break
    }
  }

  return (
    <IonCard style={{ marginTop: 22, marginBottom: 22, transition: 'max-height 2.25s ease-in' }}>
      <IonCardHeader>
        <IonCardTitle>{data[flowIndex]?.title}</IonCardTitle>
        <IonCardSubtitle>{`Step ${flowIndex + 1} of ${data.length}`}</IonCardSubtitle>
        <div style={{ width: '100%', backgroundColor: '#efefef' }}>
          <div
            style={{
              height: 10,
              width: `${percentComplete}%`,
              backgroundColor: percentComplete === 100 ? 'green' : '#000',
              transition: 'width 0.25s ease-in',
            }}
            color='dark'
          ></div>
        </div>
      </IonCardHeader>
      <IonCardContent>{getStep(data[flowIndex]?.stepName)}</IonCardContent>
    </IonCard>
  )
}

export default Flow
