import {
  IonContent,
  IonPage,
  IonLabel, IonSegment, IonSegmentButton, IonToolbar
} from '@ionic/react'
import { useContext, useEffect, useState } from 'react'
import CommonHeader from '../commonComponents/CommonHeader'
import { AuthContext } from '../auth'
import { useHistory, useLocation } from 'react-router'
import EmailRemarketingSettings from './settings'
import EmailRemarketingList from './listStatus'


export default function EmailRemarketing() {
  const { businessData, locationId } = useContext(AuthContext)
  const [ activeTab, setActiveTab ] = useState('dashboard')
  const history = useHistory()
  const location = useLocation()

  let body;

  if(activeTab == 'dashboard'){
    body = <h1> Dashboard </h1>
  } else if(activeTab == 'sent'){
    body = <EmailRemarketingList status={"sent"}/>
  }else if(activeTab == 'notSent'){
    body = <EmailRemarketingList status={"notSent"}/>
  } else if (activeTab == 'settings'){
    body = <EmailRemarketingSettings />
  }


  return (
    <IonPage id='main-content'>
      <CommonHeader
        title={'Email Remarketing'} backIcon={true}
        additionalToolbar={
          <IonToolbar   mode='ios' color="white" style={{backgroundColor:'white'}}>
            <IonSegment 
              value={activeTab}
              color='dark'
              onIonChange={e => {
                setActiveTab(e?.detail?.value)
              }}
              >
              <IonSegmentButton value="dashboard"  mode='ios'>
                <IonLabel>Dashboard</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton value="notSent"  mode='ios'>
                <IonLabel>Not Sent</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton value="sent"  mode='ios'>
                <IonLabel>Sent</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton value="settings"  mode='ios'>
                <IonLabel>Settings</IonLabel>
              </IonSegmentButton>
            </IonSegment>
          </IonToolbar>
        }
      ></CommonHeader>
      <IonContent>
        {body}
      </IonContent>
    </IonPage>
  )
}
