import { IonCheckbox, IonIcon, IonItem, IonLabel } from '@ionic/react'
import { removeCircleOutline } from 'ionicons/icons'
import React from 'react'

export default function ProductsList({
  products,
  displaycheckbox,
  selectedProducts,
  removeItem,
  handleProductSelect,
}) {
  return products.map(product => (
    <IonItem key={product._id}>
      <h6>{product.name}</h6>
      <IonLabel slot='end'>${product.price}</IonLabel>
      {displaycheckbox && (
        <IonCheckbox
          mode='ios'
          slot='end'
          checked={selectedProducts.some(selectedProduct => selectedProduct._id === product._id)}
          onIonChange={() => handleProductSelect(product)}
        />
      )}
      {!displaycheckbox && (
        <IonIcon
          slot='end'
          style={{ cursor: 'pointer', color: 'red' }}
          onClick={() => removeItem(product, 'product')}
          icon={removeCircleOutline}
        />
      )}
    </IonItem>
  ))
}
