import StandardHoursTable from './StandardHoursTable'
import ModifiedSchedule from './modifiedSchedule'
import ScheduledDaysInAdvance from './ScheduledDaysInAdvance'
import { IonCard, IonGrid } from '@ionic/react'

export default function DetailsView({ location, updateLocation }) {
  function updateStandardSchedule(value) {
    updateLocation({ ...location, ...{ standardSchedule: value } })
  }

  function updateDaysInAdvance(value) {
    updateLocation({ ...location, ...{ daysYouCanScheduleInAdvance: value } })
  }

  if (!location) return 'loading...'

  return (
    <IonGrid container>
      <IonCard style={{ padding: 10, margin: 0 }}>
        <StandardHoursTable standardSchedule={location.standardSchedule} updateStandardSchedule={updateStandardSchedule} />
      </IonCard>
      <IonCard style={{ padding: 10, margin: 0, marginTop: 10 }}>
        <ModifiedSchedule location={location} standardSchedule={location.standardSchedule} locationId={location._id} businessId={location.businessId} />
        <br></br>
        <br></br>
      </IonCard>
      <IonCard style={{ padding: 10, margin: 0, marginTop: 10 }}>
        <ScheduledDaysInAdvance daysYouCanScheduleInAdvance={location.daysYouCanScheduleInAdvance} updateDaysInAdvance={updateDaysInAdvance} />
      </IonCard>
    </IonGrid>
  )
}
