// PickCustomerStyles.js

const PickCustomerStyles = {
  loadingBox: {
    display: 'flex',
    justifyContent: 'center',
  },
  container: {
    marginTop: 20,
    padding: '8px 24px',
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    display: 'inline-flex',
  },
  header: {
    alignSelf: 'stretch',
    paddingBottom: 12,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: 10,
    display: 'flex',
  },
  title: {
    alignSelf: 'stretch',
    color: '#222222',
    fontSize: 32,
    fontFamily: 'Figtree',
    fontWeight: '600',
    lineHeight: '38.4px',
    wordWrap: 'break-word',
  },
  searchContainer: {
    alignSelf: 'stretch',
    height: 62,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: 4,
    display: 'flex',
  },
  searchBox: {
    alignSelf: 'stretch',
    height: 62,
    background: 'white',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: 12,
    display: 'flex',
  },
  searchInputContainer: {
    alignSelf: 'stretch',
    paddingLeft: 12,
    paddingRight: 12,
    paddingTop: 8,
    paddingBottom: 8,
    background: 'white',
    borderRadius: 8,
    border: '1px #DADADA solid',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 4,
    display: 'inline-flex',
  },
  searchIconContainer: {
    width: 24,
    height: 24,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  searchIcon: {
    width: 24,
    height: 24,
    position: 'relative',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    display: 'flex',
  },
  searchIconBorder: {
    width: 18,
    height: 18,
    border: '1.33px #222222 solid',
  },
  searchText: {
    flex: '1 1 0',
    height: 46,
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 8,
    display: 'flex',
  },
  searchTextInner: {
    flex: '1 1 0',
    height: 46,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    display: 'inline-flex',
  },
  searchTextPlaceholder: {
    alignSelf: 'stretch',
    color: '#B0B0B0',
    fontSize: 18,
    fontFamily: 'Figtree',
    fontWeight: '400',
    lineHeight: '28px',
    wordWrap: 'break-word',
  },
  customerList: {
    alignSelf: 'stretch',
    paddingBottom: 32,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    display: 'flex',
  },
  customerCard: {
    cursor: 'pointer',
    width: '100%',
    height: 80,
    paddingTop: 16,
    paddingBottom: 16,
    borderBottom: '1px #EBEBEB solid',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: 4,
    display: 'flex',
  },
  customerCardRow: {
    width: '100%',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: 12,
    display: 'inline-flex',
  },
  customerCardContent: {
    flex: '1 1 0',
    height: 48,
    justifyContent: 'center',
    alignItems: 'center',
    gap: 12,
    display: 'flex',
  },
  avatarContainer: {
    paddingTop: 2,
    paddingBottom: 2,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: 10,
    display: 'inline-flex',
  },
  customerName: {
    alignSelf: 'stretch',
    color: '#222222',
    fontSize: 18,
    fontFamily: 'Figtree',
    fontWeight: '500',
    lineHeight: '28px',
    wordWrap: 'break-word',
  },
  customerPhone: {
    alignSelf: 'stretch',
    color: '#717171',
    fontSize: 14,
    fontFamily: 'Figtree',
    fontWeight: '400',
    lineHeight: '20px',
    wordWrap: 'break-word',
  },
  badgeContainer: {
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    display: 'flex',
  },
  badge: {
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 2,
    paddingBottom: 2,
    background: 'linear-gradient(90deg, #4E5BA6 0%, #222222 100%)',
    borderRadius: 9999,
    justifyContent: 'flex-start',
    alignItems: 'center',
    display: 'flex',
  },
  badgeText: {
    textAlign: 'center',
    color: 'white',
    fontSize: 12,
    fontFamily: 'Figtree',
    fontWeight: '600',
    lineHeight: '18px',
    wordWrap: 'break-word',
  },
}

export default PickCustomerStyles
