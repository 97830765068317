import { IonCol, IonIcon, IonItem, IonLabel, IonList, IonModal, IonToast } from '@ionic/react'
import { checkmark, chevronDown } from 'ionicons/icons'
import React, { useEffect, useState } from 'react'
import CommonHeader from '../commonComponents/CommonHeader'
import axios from 'axios'

export default function ChooseAudience({
  mode,
  audiencesList,
  remarketingTempData,
  refreshRemarketingDetails,
  smtpSettingsId,
  ruleName,
  emailSteps,
  id,
  updateAudienceData,
}) {
  const [audienceModalOpen, setAudienceModal] = useState(false)
  const [selectedAudience, setSelectedAudience] = useState([])
  const [audienceChanged, setAudienceChanged] = useState(false)
  const [updatingData, setUpdatingData] = useState(false)
  const [toast, setToast] = useState({ isOpen: false, message: '', color: '' })
  const showToastMessage = (message, color) => {
    setToast({ isOpen: true, message, color })
  }

  const audiences = [
    {
      label: 'All Customers',
      value: 'ALL_CUSTOMERS',
    },
    {
      label: 'Non-Subscribers',
      value: 'NON_SUBSCRIBERS',
    },
    {
      label: 'Subscribers',
      value: 'ACTIVE_SUBSCRIBERS',
    },
    {
      label: 'Account without Appointment',
      value: 'CUSTOMERS_WITHOUT_APPOINTMENT',
    },
  ]
  const openAudienceModal = () => {
    setAudienceModal(true)
  }
  const closeAudienceModal = () => {
    if (mode === 'add') {
      const selectedAudiences = audiences?.filter((audience) => audiencesList?.includes(audience.value))
      setSelectedAudience(selectedAudiences)
    }
    setAudienceModal(false)
  }
  const handleSelectedAudience = (item) => {
    const audienceIndex = selectedAudience.findIndex((selected) => selected.value === item.value)
    let updatedSelectedAudience = [...selectedAudience]

    if (audienceIndex !== -1) {
      // If item exists in the selectedAudience array, remove it
      updatedSelectedAudience.splice(audienceIndex, 1)
    } else {
      // If item doesn't exist in the selectedAudience array, add it
      updatedSelectedAudience = [...updatedSelectedAudience, item]
    }
    setSelectedAudience(updatedSelectedAudience)
  }

  const closeModalAndUpdateData = () => {
    setAudienceModal(false)
    refreshRemarketingDetails()
  }
  const handleSaveButton = async () => {
    const selectedAudienceArray = selectedAudience.map((audience) => audience.value)
    if (mode === 'add') {
      closeAudienceModal()
      updateAudienceData(selectedAudienceArray)
    } else {
      setUpdatingData(true)

      const remarketingInput = {
        smtpSettingsId: smtpSettingsId,
        ruleName: ruleName,
        audiences: selectedAudienceArray,
        emailSteps: emailSteps,
      }

      try {
        const updateRemarketingResponse = await axios.patch(`/emailRemarketing/rule/?id=${id}`, remarketingInput)
        if (updateRemarketingResponse.status === 200) {
          setUpdatingData(false)
          showToastMessage('Audience updated successfully!', 'success')
          closeModalAndUpdateData()
        } else {
          showToastMessage('Failed to update audience', 'danger')
        }
      } catch (error) {
        setUpdatingData(false)
        showToastMessage('Error updating audience', 'danger')
        console.error('Error updating audience:', error)
      }
    }
  }
  useEffect(() => {
    // Map the audiences from DB to the audiences array structure

    const selectedAudiences = audiences?.filter((audience) => audiencesList?.includes(audience.value))

    // Result will be an array of objects in the structure of your audiences array
    setSelectedAudience(selectedAudiences)
  }, [audiencesList])

  return (
    <>
      <IonToast
        position='top'
        isOpen={toast.isOpen}
        message={toast.message}
        duration={2000}
        color={toast.color}
        onDidDismiss={() => setToast({ isOpen: false, message: '', color: '' })}
      />
      <IonItem>
        <IonCol size='6'>
          <IonLabel>Audience</IonLabel>
        </IonCol>
        <IonCol size='6' onClick={openAudienceModal} style={{ display: 'flex', justifyContent: 'end' }}>
          <div
            style={{
              cursor: 'pointer',
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <IonLabel style={{ marginRight: '0.5rem' }}>{audiencesList?.join(',')}</IonLabel>
            <IonIcon size='large' icon={chevronDown} />
          </div>
        </IonCol>
      </IonItem>
      <IonModal isOpen={audienceModalOpen}>
        <CommonHeader
          title={'Choose Audience'}
          closeButton={true}
          showSpinner={true}
          updatingData={updatingData}
          handleSaveButton={handleSaveButton}
          saveButton={true}
          closeModal={closeAudienceModal}
        />
        <IonList mode='ios'>
          {audiences.map((item, index) => {
            const isAudienceSelected = selectedAudience.some((selected) => selected.value === item.value)
            return (
              <IonItem style={{ cursor: 'pointer' }} onClick={() => handleSelectedAudience(item)}>
                <IonLabel>{item.label}</IonLabel>
                {isAudienceSelected && <IonIcon icon={checkmark}></IonIcon>}
              </IonItem>
            )
          })}
        </IonList>
      </IonModal>
    </>
  )
}
