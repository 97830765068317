import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';
import { colors } from '../../theme/colors';
import { IonCol, IonGrid, IonRow, IonSpinner, IonToggle } from '@ionic/react';
import StandardCenterCenter from '../../commonComponents/StandardCenterCenter';
import axios from 'axios';

const RevenueChart = ({ selectedCategory, setSelectedCategory, businessData, locationId, refreshValue }) => {
    const [chartRenderId, setChartRenderId] = useState(1)
    const [projectedValue, setProjectedValue] = useState('--')
    const [revenueData, setRevenueData] = useState({ data: null, loading: false, error: null, locationId: locationId })
    const [currentValue, setCurrentValue] = useState('--')
    const [isCumulative, setIsCumulative] = useState(() => {
        const saved = localStorage.getItem('revenueCumulativeView');
        return saved ? JSON.parse(saved) : false;
    });

    let data = revenueData?.data
    let loading = revenueData?.loading

    const getRevenueData = async () => {
        setRevenueData({
            loading: true,
            data: revenueData?.locationId === locationId ? revenueData.data : null,
            error: null,
            locationId,
        })

        // Get first and last day of current month
        const currentDate = new Date()
        const firstDayThisMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)
        const lastDayThisMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0)
        
        // Get first and last day of last month
        const firstDayLastMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1)
        const lastDayLastMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0)

        try {
            // Fetch this month's data
            const thisMonthUrl = `/location_v2/sales?businessId=${businessData?._id}&startDateText=${firstDayThisMonth.toISOString().split('T')[0]}&endDateText=${lastDayThisMonth.toISOString().split('T')[0]}&locationId=${locationId}`
            const thisMonthResponse = await axios.get(thisMonthUrl)

            // Fetch last month's data
            const lastMonthUrl = `/location_v2/sales?businessId=${businessData?._id}&startDateText=${firstDayLastMonth.toISOString().split('T')[0]}&endDateText=${lastDayLastMonth.toISOString().split('T')[0]}&locationId=${locationId}`
            const lastMonthResponse = await axios.get(lastMonthUrl)

            setRevenueData({
                data: {
                    thisMonth: thisMonthResponse?.data,
                    lastMonth: lastMonthResponse?.data
                },
                loading: false,
                error: null,
                locationId,
            })
        } catch (error) {
            setRevenueData({
                data: revenueData.data,
                loading: false,
                error: error,
                locationId,
            })
        }
    }

    useEffect(() => {
        if (selectedCategory === 'Revenue') {
            getRevenueData()
        }
    }, [businessData, locationId, refreshValue, selectedCategory])

    useEffect(() => {
        if (!data) return;

        // Format this month's data
        let thisMonthData = data.thisMonth.map(item => ({
            x: new Date(item.date).getTime(),
            y: parseFloat(item.totalSales) || 0
        })).sort((a, b) => a.x - b.x);

        // Format last month's data
        let lastMonthData = data.lastMonth.map(item => ({
            x: new Date(new Date(item.date).setMonth(new Date(item.date).getMonth() + 1)).getTime(),
            y: parseFloat(item.totalSales) || 0
        })).sort((a, b) => a.x - b.x);

        // Apply cumulative calculation if enabled
        if (isCumulative) {
            thisMonthData = calculateCumulativeData(thisMonthData);
            lastMonthData = calculateCumulativeData(lastMonthData);
        }

        // Calculate current and projected values for this month
        const totalSales = thisMonthData.reduce((sum, item) => isCumulative ? item.y : sum + item.y, 0);
        setCurrentValue(totalSales);
        setProjectedValue(projectRevenue(totalSales));

        // Find max value across both months for y-axis scaling
        const yAxisMax = Math.ceil(Math.max(
            ...thisMonthData.map(item => item.y),
            ...lastMonthData.map(item => item.y)
        ) * 1.10);

        setChartData(prevState => ({
            options: {
                ...prevState.options,
                yaxis: {
                    ...prevState.options.yaxis,
                    max: yAxisMax
                }
            },
            series: [
                {
                    name: 'Last Month',
                    data: lastMonthData
                },
                {
                    name: 'This Month',
                    data: thisMonthData
                }
            ]
        }));
        
        setChartRenderId(prev => prev + 1);
    }, [data, isCumulative]);

    useEffect(() => {
        localStorage.setItem('revenueCumulativeView', JSON.stringify(isCumulative));
    }, [isCumulative]);

    const [chartData, setChartData] = useState({
        options: {
            chart: {
                type: 'area',
                height: 250,
                toolbar: false
            },
            xaxis: {
                categories: [],
                tickAmount: 7,
                type: 'datetime',
                labels: {
                    style: {
                        colors: 'gray'
                    },
                    formatter: function(value) {
                        if (!value) return '';
                        const date = new Date(value);
                        const today = new Date();
                        if (date.getMonth() !== today.getMonth()) return '';
                        return date.getDate();
                    }
                },
                axisBorder: {
                    show: false
                },
                axisTicks: {
                    show: false
                }
            },
            stroke: {
                curve: 'smooth',
                width: 2
            },
            grid: {
                borderColor: '#f1f1f1',
                show: false
            },
            yaxis: {
                title: {
                    text: '',
                },
                min: 0, // Ensure the minimum y value is always 0
                labels: {
                    style: {
                        colors: 'gray'
                    },
                    formatter: getYFormatter
                },
                max: 0 // Initialize with 0, will be calculated based on data
            },
            tooltip: {
                shared: true,
            },
            fill: {
                type: ['solid', 'gradient'],
                opacity: [0, 1], // Opacity for gray and blue lines
                gradient: {
                    shadeIntensity: 1,
                    type: 'vertical',
                    opacityFrom: 0.7,
                    opacityTo: 0.1,
                    stops: [0, 100]
                }
            },
            legend: {
                show: true,
                offsetY: 0
            },
            colors: ['#A9A9A9', colors.primaryBlue], // Gray and primary blue colors
            dataLabels: {
                enabled: false // Disable the number boxes on the lines
            }
        },
        series: []
    });

    function getYFormatter(value) {
        if (selectedCategory === 'Revenue') {
            return formatAsMoney(value);
        } else {
            return value?.toFixed(0);
        }
    }

    const calculateCumulativeData = (data) => {
        let sum = 0;
        return data.map(item => ({
            x: item.x,
            y: (sum += item.y)
        }));
    };

    return (
        <IonCol size='12'>
            {loading ? (
                <StandardCenterCenter><div style={{ height: 200 }}><IonSpinner name="dots" /></div></StandardCenterCenter>
            ) : !data ? (
                <StandardCenterCenter><div style={{ height: 200 }}>No data available</div></StandardCenterCenter>
            ) : (
                <>
                    <IonGrid style={{ margin: 0, padding: 0 }}>
                        <IonRow style={{ margin: 0, padding: 0 }}>
                            <IonCol size="8" style={{ margin: 0, padding: 0 }}>
                                <h2 style={{ marginTop: 4, marginBottom: 0 }}>{formatAsMoney(currentValue)}</h2>
                                <h6 style={{ marginTop: 0, fontSize: 12, color: 'gray' }}>{formatAsMoney(projectedValue)} Projected</h6>
                            </IonCol>
                            <IonCol size="4" style={{ margin: 0, padding: 0, display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                    <span style={{ fontSize: '12px', color: 'gray' }}>{isCumulative ? 'Cumulative' : 'Daily'}</span>
                                    <IonToggle
                                        size='small'
                                        checked={isCumulative}
                                        onIonChange={e => setIsCumulative(e.detail.checked)}
                                        // style={{ '--handle-width': '20px', '--handle-height': '12px' }}
                                    />
                                </div>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                    <Chart
                        key={chartRenderId}
                        options={chartData.options}
                        series={chartData.series}
                        type="area"
                        height={250}
                        style={{ marginBottom: -30, marginTop: -10, marginLeft: -10, marginRight: -10 }}
                    />
                </>
            )}
        </IonCol>
    );
};


function formatAsMoney(amount, currency = 'USD', locale = 'en-US') {
    const roundedAmount = Math.round(amount); // Round to the nearest dollar
    return new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: currency,
        minimumFractionDigits: 0, // No decimal places
        maximumFractionDigits: 0  // No decimal places
    }).format(roundedAmount);
}

function projectRevenue(currentRevenue = 0) {
    const currentDate = new Date()
    const daysPassed = currentDate.getDate()
    const totalDays = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0).getDate()
    
    const dailyAverage = currentRevenue / daysPassed
    return Math.round(dailyAverage * totalDays)
}

export default RevenueChart;
