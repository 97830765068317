import { IonItem, IonLabel } from '@ionic/react'
import React from 'react'
import Avatar from '../commonComponents/avatar'
import StatusCircle from '../commonComponents/statusCircle'
import { useHistory } from 'react-router'

export default function ResultItem({ row, businessId, closeModal }) {
  const history = useHistory()
  const handleResultItem = row => {
    if (closeModal) {
      closeModal()
    }
    history.push(`/core/appointments/${row._id}`)
  }
  return (
    <IonItem
      button
      detail={true}
      onClick={() => handleResultItem(row)}
      routerDirection='forward'
      key={row?._id}
    >
      <div style={{ position: 'absolute', top: 10, right: 35 }}>
        <Avatar employee={row.barber} businessId={businessId} mini={true} />
      </div>
      <IonLabel style={{ maxWidth: 50 }}>
        <StatusCircle status={row.status} />
      </IonLabel>
      <IonLabel>
        <h4
          style={{ fontWeight: '600', color: 'black', fontSize: 18 }}
        >{`${row.customer.firstName} ${row.customer.lastName}`}</h4>
        {/* <IonGrid style={{ marginLeft: -10, marginBottom: -10, marginTop: -10 }}>
							<IonRow style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
								<IonCol size='auto'>
									
								</IonCol>
							</IonRow>
						</IonGrid> */}

        <p>
          {row?.services?.map(s => {
            return `${s.name} `
          })}
        </p>
        <p style={{ color: 'black' }}>
          {row?.type == 'Walkin' ? null : (
            <>
              {row?.startTimeText?.replace(' PM', '')?.replace(' AM', '')} - {row?.endTimeText}
              &nbsp;&nbsp;
            </>
          )}
          ({row?.durationMin} min)
        </p>
        <p></p>
      </IonLabel>
    </IonItem>
  )
}
