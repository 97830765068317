import { IonGrid, IonIcon, IonItem, IonLabel, IonList, IonRow, IonSelect, IonSelectOption } from '@ionic/react'
import { checkmarkCircle } from 'ionicons/icons'
import { calculateCostPerDay, getPricing } from '../utils'
import { colors } from '../theme/colors'

export default function ChoosePackageAndPlan({
  selectedPackage,
  handleSelectedPackage,
  subscriptionPackages,
  currentSubscription,
  currentSubscriptionPricePerDay,
  handleSelectedPrice,
  selectedPrice,
  title,
}) {
  return (
    <>
      <div style={{ padding: 20, paddingBottom: 0, paddingTop: 4 }}>
        <h6>1. {title}</h6>
      </div>
      <IonList inset={true} mode='ios'>
        <IonSelect
          value={selectedPackage}
          onIonChange={(e) => {
            handleSelectedPackage(e.detail.value)
          }}
        >
          {subscriptionPackages?.data?.map((pkg) => (
            <>
              {pkg.enabled && (
                <IonSelectOption key={pkg?._id} value={pkg}>
                  {pkg?.name}
                  {pkg._id == currentSubscription?.subscriptionPackage?._id ? (
                    <IonIcon icon={checkmarkCircle} slot='end' color={'primary'} style={{ marginLeft: 0, marginRight: 0 }}></IonIcon>
                  ) : null}
                </IonSelectOption>
              )}
            </>
          ))}
        </IonSelect>
      </IonList>
      {selectedPackage && (
        <>
          <div style={{ padding: '0 1rem', paddingBottom: 0 }}>
            <h6>Choose Subscription Option</h6>
            <h6 style={{ fontSize: '12px', color: 'grey' }}>Update available only for subscription options of higher value </h6>
          </div>
          <IonList mode='ios' inset={true}>
            {selectedPackage?.prices?.map((item, index) => {
              const priceOfPlanPerDay = calculateCostPerDay(item.amount, item.interval, item.interval_count)

              return item.active ? (
                <IonItem disabled={currentSubscription.stripeSubscriptionPlanId == item.stripePriceId} onClick={() => handleSelectedPrice(item)}>
                  <IonGrid>
                    <IonRow style={{ width: '100%' }}>
                      <IonLabel
                        style={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        {item.name}

                        {selectedPrice.stripePriceId == item.stripePriceId && (
                          <IonIcon icon={checkmarkCircle} slot='end' color={'primary'} style={{ marginLeft: 0, marginRight: 0 }}></IonIcon>
                        )}
                      </IonLabel>
                    </IonRow>
                    <IonRow style={{ width: '100%' }}>
                      <h6
                        style={{
                          margin: '5px 0',
                          color: 'grey',
                          fontSize: '12px',
                        }}
                      >
                        {getPricing(item)}
                      </h6>
                    </IonRow>
                    <IonRow style={{ width: '100%' }}>
                      {currentSubscription.stripeSubscriptionPlanId == item.stripePriceId && (
                        <div
                          style={{
                            margin: '0.5rem 0',
                            fontSize: 10,
                            fontWeight: '600',
                            backgroundColor: colors.primaryBlue,
                            borderRadius: 30,
                            width: 90,
                            color: 'white',
                            padding: 2,
                            textAlign: 'center',
                          }}
                        >
                          Current Plan
                        </div>
                      )}
                    </IonRow>
                  </IonGrid>
                </IonItem>
              ) : null
            })}
          </IonList>
        </>
      )}
    </>
  )
}
