import Box from '@mui/material/Box'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import StandardCenterCenter from '../../commonComponents/StandardCenterCenter'
import StatusCircle from '../../commonComponents/statusCircle'

const standardSteps = ['Scheduled', 'Arrived', 'In Progress', 'Complete']

export default function ProgressStepper({ appointment, status, isWalkin }) {
  if (!status) return null

  let steps = standardSteps

  let stepsData = {
    Scheduled: 0,
    Arrived: 1,
    'In Progress': 2,
    Complete: 3,
  }

  let activeStep = status === 'No Show' ? -1 : stepsData?.[status] || null

  if ('No Show' == status) return <NoShow />
  if ('Canceled' == status) return <Canceled appointment={appointment} />

  return (
    <Box sx={{ width: '100%', marginTop: 2 }}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label, index) => {
          let text = label
          if (label == 'Scheduled' && isWalkin) {
            text = 'Walk-in'
          }

          return (
            <Step key={label} completed={['Complete'].includes(status) || index <= activeStep}>
              <StepLabel warning={true}>
                <div style={{ marginTop: -8, fontSize: 12 }}>{text}</div>
              </StepLabel>
            </Step>
          )
        })}
      </Stepper>
    </Box>
  )
}

function NoShow() {
  return (
    <div style={{ marginTop: 10 }}>
      <StandardCenterCenter>
        <StatusCircle status={'No Show'} xs />
        <div style={{ fontSize: 12, marginTop: 8 }}>No Show</div>
      </StandardCenterCenter>
    </div>
  )
}

function Canceled({ appointment }) {
  return (
    <div style={{ marginTop: 10 }}>
      <StandardCenterCenter>
        <StatusCircle status={'Canceled'} xs />
        <div style={{ fontSize: 12, marginTop: 8 }}>Canceled {appointment?.cancelledBy ? `(${appointment.cancelledBy})` : ''}</div>
      </StandardCenterCenter>
    </div>
  )
}
