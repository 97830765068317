export default function StandardContainer({ children, medium, maxWidth= 800, padding }){
    let w = maxWidth
    if(medium){
        w = 600
    }

    let p = padding===0 ? 0 : (padding || 0)

    return (
        <div style={{ justifyContent: 'center', width: '100%', display: 'flex' }}>
            <div style={{ maxWidth: w, width: '100%', padding:p }}>
                {children}
            </div>
        </div>
    )
}