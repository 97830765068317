import { IonContent, IonItem, IonList, IonPage } from '@ionic/react'
import StandardContainer from '../commonComponents/StandardContainer'
import CommonHeader from '../commonComponents/CommonHeader'

const reportItems = [
  { title: 'Account & Appointment Report', link: '/core/accountsReport' },
  { title: 'Notification Report', link: '/core/notificationReport' },
  { title: 'Membership Cancellations', link: '/core/membershipCancellations' },
  { title: 'Customers By Time of Day', link: '/core/customersByDay' },
  { title: 'Customers Schedule', link: '/core/customersSchedule' },
  { title: 'Filled Capacity', link: '/core/filledCapacity' },
  { title: 'Retention Report', link: '/core/retentionReport' },
]

export default function Reports() {
  return (
    <IonPage>
      <CommonHeader title={'Reports'} backIcon={true} />
      <IonContent>
        <StandardContainer>
          <IonList inset={true} mode='ios'>
            {reportItems.map((item, index) => (
              <IonItem key={index} detail={true} routerLink={item.link}>
                {item.title}
              </IonItem>
            ))}
          </IonList>
        </StandardContainer>
      </IonContent>
    </IonPage>
  )
}
