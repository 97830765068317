import { useState, useEffect, useContext } from 'react'
import ResultsList from './resultsList'
import axios from 'axios'
import { AuthContext } from '../auth'
import { IonContent, IonRefresher, IonRefresherContent, useIonViewWillEnter } from '@ionic/react'
import StandardContainer from '../commonComponents/StandardContainer'
import InviteResultsList from './inviteResultsList'
import { useLocation } from 'react-router'

export default function ActiveUsers({ updateLoader }) {
  const { businessData, locationId } = useContext(AuthContext)
  const [users, setUsers] = useState([])
  const [invites, setInvites] = useState([])
  const [invitesLoading, setInvitesLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  let locQuery = locationId ? `&locationId=${locationId}` : ''

  const location = useLocation()

  useEffect(() => {
    getUsersList()
    fetchInvites()
  }, [locationId])

  useIonViewWillEnter(() => {
    getUsersList()
    fetchInvites()
  })

  useEffect(() => {
    if (location.pathname == '/core/users') {
      getUsersList()
      fetchInvites()
    }
  }, [location])

  const getUsersList = async ignoreLoading => {
    if (!businessData?._id) return
    updateLoader(true)
    setErrorMessage(null)
    try {
      let response = await axios.get(`/user/getList?businessId=${businessData._id}${locQuery}`)
      setUsers(response.data)
    } catch (error) {
      setErrorMessage('Sorry there was an error fetching the data')
    }
    updateLoader(false)
  }

  const fetchInvites = async () => {
    setInvitesLoading(true)
    try {
      const response = await axios.get(`/user/invitesList?businessId=${businessData._id}${locQuery}`)
      setInvites(response.data)
    } catch (error) {
      console.error('Error fetching invites:', error)
    }
    setInvitesLoading(false)
  }

  const closeAddUserDialog = () => {
    fetchInvites()
  }

  async function handleRefresh(event) {
    await getUsersList(true)
    await fetchInvites()
    event.detail.complete()
  }

  return (
    <IonContent>
      <StandardContainer>
        <IonRefresher slot='fixed' onIonRefresh={handleRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        <ResultsList title={'Active Users'} users={users} />
        <InviteResultsList
          refreshInvites={closeAddUserDialog}
          invites={invites}
          loading={invitesLoading}
        />
      </StandardContainer>
    </IonContent>
  )
}
