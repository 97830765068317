import React, { useState } from 'react'
import {
  IonButton,
  IonInput,
  IonItem,
  IonLabel,
  IonSelect,
  IonSelectOption,
  IonModal,
  IonContent,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonToast,
} from '@ionic/react'
import axios from 'axios'
import { useHistory } from 'react-router-dom'

export default function CreateTestDialog({ open, handleClose, businessId, locationId }) {
  const [isDisabled, setIsDisabled] = useState(false)
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [durationMin, setDurationMin] = useState('')
  const [price, setPrice] = useState('')
  const [toast, setToast] = useState({ isOpen: false, message: '', color: '' })
  const history = useHistory()

  const showToastMessage = (message, color = 'success') => {
    setToast({ isOpen: true, message, color })
  }
  const createButtonClicked = async () => {
    setIsDisabled(true)
    const locData = locationId ? { locations: [locationId] } : {}
    try {
      const response = await axios.post(`/service`, { name, description, durationMin, businessId, price, ...locData })

      if (response.status === 200) {
        showToastMessage('Service created successfully!', 'success')
        history.push(`services/${response.data._id}/details`)
        close()
      } else {
        showToastMessage('Failed to create service. Please try again.', 'danger')
      }
    } catch (error) {
      showToastMessage('An error occurred. Please try again.', 'danger')
    } finally {
      setIsDisabled(false)
    }
  }

  const close = () => {
    setName('')
    setDescription('')
    setDurationMin('')
    setPrice('')
    setIsDisabled(false)
    handleClose()
  }

  return (
    <IonModal isOpen={open} onDidDismiss={handleClose}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Add Service</IonTitle>
          <IonButtons slot='end'>
            <IonButton onClick={close}>Close</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonItem>
          <IonLabel position='stacked'>Name</IonLabel>
          <IonInput value={name} onIonInput={(e) => setName(e.detail.value)} placeholder='Enter service name' />
        </IonItem>
        <IonItem>
          <IonLabel position='stacked'>Description</IonLabel>
          <IonInput value={description} onIonInput={(e) => setDescription(e.detail.value)} placeholder='Enter service description' />
        </IonItem>
        <IonItem>
          <IonLabel>Duration</IonLabel>
          <IonSelect value={durationMin} onIonChange={(e) => setDurationMin(e.detail.value)} placeholder='Select duration'>
            <IonSelectOption value={10}>10 min</IonSelectOption>
            <IonSelectOption value={15}>15 min</IonSelectOption>
            <IonSelectOption value={30}>30 min</IonSelectOption>
            <IonSelectOption value={45}>45 min</IonSelectOption>
            <IonSelectOption value={60}>60 min</IonSelectOption>
            <IonSelectOption value={90}>90 min</IonSelectOption>
          </IonSelect>
        </IonItem>
        <IonItem>
          <IonLabel position='stacked'>Price</IonLabel>
          <IonInput value={price} onIonInput={(e) => setPrice(e.detail.value)} placeholder='Enter price' />
        </IonItem>
      </IonContent>
      <div style={{ paddingBottom: 20, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <IonButton fill='clear' onClick={close}>
          Cancel
        </IonButton>
        <IonButton disabled={isDisabled} onClick={createButtonClicked}>
          Create
        </IonButton>
      </div>

      <IonToast
        position='top'
        isOpen={toast.isOpen}
        onDidDismiss={() => setToast({ ...toast, isOpen: false })}
        message={toast.message}
        color={toast.color}
        duration={2000}
      />
    </IonModal>
  )
}
