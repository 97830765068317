import { IonContent, IonInput, IonItem, IonLabel, IonList, IonModal } from '@ionic/react'
import React from 'react'
import CommonHeader from '../commonComponents/CommonHeader'
import StandardContainer from '../commonComponents/StandardContainer'

export default function CustomAmountModal({
  customAmountModalOpen,
  customAmount,
  handleCustomAmount,
  closeCustomAmountModal,
}) {
  return (
    <IonModal isOpen={customAmountModalOpen} onDidDismiss={closeCustomAmountModal}>
      <CommonHeader
        closeButton={true}
        closeModal={closeCustomAmountModal}
        title={'Provide Custom Amount'}
      ></CommonHeader>
      <IonContent className='regularBackground'>
        <StandardContainer>
          <IonList inset={true} mode='ios'>
            <IonItem>
              <IonLabel position='stacked'>Custom Amount</IonLabel>
              <IonInput
                value={customAmount}
                onIonInput={handleCustomAmount}
                type='number'
                placeholder='Enter custom amount'
              ></IonInput>
            </IonItem>
          </IonList>
        </StandardContainer>
      </IonContent>
    </IonModal>
  )
}
