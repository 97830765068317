const GetMonthsBasedOnStartAndEndIndex = (startDate, endDate) => {
  const start = new Date(startDate)
  const end = new Date(endDate)
  const allMonths = []
  let monthNames = []
  let monthNumbersArr = []
  let categories = {}
  while (start <= end) {
    const monthNumbers = String(start.getMonth() + 1).padStart(2, '0')
    const year = start.getFullYear().toString()
    const lastTwoDigitsOfYear = year % 100

    const monthName = start.toLocaleString('default', { month: 'short' })

    monthNumbersArr.push(`${monthNumbers}-${lastTwoDigitsOfYear}`)
    monthNames.push(`${monthName}-${lastTwoDigitsOfYear}`)
    categories = {
      categories: monthNames,
      monthNumbers: monthNumbersArr,
    }
    start.setMonth(start.getMonth() + 1)
  }

  return categories
}
export default GetMonthsBasedOnStartAndEndIndex
