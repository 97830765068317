import { IonButton, IonIcon, IonSpinner, IonText } from '@ionic/react'
import { caretBackOutline, caretForwardOutline, closeCircleOutline } from 'ionicons/icons'

const ButtonPanel = ({ data, flowIndex, onCancel, onBack, onNext, history, loading }) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 12 }}>
      <div style={{ display: 'flex', justifySelf: 'flex-start' }}>
        {flowIndex === 0 ? (
          <IonButton fill='outline' color='dark' onClick={onCancel}>
            <IonIcon slot='start' icon={closeCircleOutline} />
            <IonText>Cancel</IonText>
          </IonButton>
        ) : (
          <IonButton fill='outline' color='dark' onClick={onBack}>
            <IonIcon slot='start' icon={caretBackOutline} />
            <IonText>{data[flowIndex]?.backButtonText}</IonText>
          </IonButton>
        )}
      </div>
      <div style={{ display: 'flex', justifySelf: 'flex-end' }}>
        <IonButton
          disabled={loading}
          fill='solid'
          color='dark'
          onClick={data[flowIndex]?.nextButtonRedirect ? () => history.push(data[flowIndex]?.nextButtonRedirect) : onNext}
        >
          {loading ? <IonSpinner slot='end' /> : <IonIcon slot='end' icon={caretForwardOutline} />}
          <IonText>{data[flowIndex]?.nextButtonText}</IonText>
        </IonButton>
      </div>
    </div>
  )
}

export default ButtonPanel
