import React, { useContext, useEffect, useState } from 'react'
import { IonContent, IonInfiniteScroll, IonInfiniteScrollContent, IonLabel } from '@ionic/react'
import { fontSizes, fontWeights } from '../../theme/typography'
import { colors } from '../../theme/colors'
import GetLocationDataBasedOnLocationId from '../../Utils/GetLocationDataBasedOnLocationId'
import { AuthContext } from '../../auth'
import { getFormattedMediumDate } from '../../utils'
import axios from 'axios'
import { statusColors } from '../../StaticData/StaticData'
import ResizableModal from '../NewCalendarView/ResizableModal'
import CalendarView5Header from '../CalendarView5/CalendarView5Header'
import BreakEvent from './BreakEvent'
import EventData from './EventData'

// Styles
const styles = {
  dayLabel: {
    color: colors.black50,
    fontSize: fontSizes.size14,
  },
  dayNumberLabel: (isToday) => ({
    marginTop: 5,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: isToday ? 25 : '',
    width: isToday ? 25 : '',
    borderRadius: '50%',
    backgroundColor: isToday ? colors.primaryBlue : '',
    color: isToday ? '#ffffff' : colors.black50,
    fontSize: isToday ? fontSizes.size14 : fontSizes.size16,
    fontWeight: fontWeights.weightBold,
  }),
  noAppointments: {
    backgroundColor: '#efefef',
    padding: '0.5rem',
    borderRadius: '5px',
  },
  breakEvent: {
    position: 'relative',
    color: '#000',
    padding: '0.5rem',
    margin: '10px 0',
    borderRadius: '5px',
  },
  appointmentEvent: (bgColor, textColor) => ({
    backgroundColor: bgColor || '#ccc',
    color: textColor || '#000',
    padding: '0.5rem',
    margin: '10px 0',
    borderRadius: '5px',
  }),
}

// Helper function to get all days in the selected month
const getDaysInMonth = (startDay, noOfDays, date) => {
  const year = new Date(date).getFullYear()
  const month = new Date(date).getMonth()

  return Array.from({ length: noOfDays }, (_, index) => {
    return new Date(year, month, startDay + index)
  })
}

// Main component to display the appointments list view
const ListViewAppointments = ({
  selectedStylists,
  selectedTypes,
  setAppointmentsInMenu,
  selectedDate,
  handleView,
  setSelectedDate,
  handlePrevWeek,
  handleNextWeek,
}) => {
  const [startDay, setStartDay] = useState(1)
  const noOfDays = 5
  const [fromDay, setFromDay] = useState(1)
  const [daysInMonth, setDaysInMonth] = useState(getDaysInMonth(startDay, noOfDays, selectedDate))
  const [appointments, setAppointments] = useState([])
  const [loading, setLoading] = useState(false)
  const [breaks, setBreaks] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [selectedEvent, setSelectedEvent] = useState(false)
  const [loadMoreDisabled, setLoadMoreDisabled] = useState(false)

  const [employees, setEmployees] = useState([])
  const { businessData, locationData, locationId } = useContext(AuthContext)

  const getAppointmentsForDay = (allEvents, day) => {
    return allEvents.filter((appointment) => {
      const appointmentDate = new Date(appointment.selectedDate)
      return appointmentDate.getFullYear() === day.getFullYear() && appointmentDate.getMonth() === day.getMonth() && appointmentDate.getDate() === day.getDate()
    })
  }

  const isStoreClosed = (selectedDate) => {
    const selectedLocationData = GetLocationDataBasedOnLocationId(locationData, locationId)

    const dayOfWeek = selectedDate.getDay()
    const dayOfWeekString = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][dayOfWeek]

    const todaySchedule = selectedLocationData?.standardSchedule?.[dayOfWeekString]

    return !todaySchedule?.enabled
  }

  const refreshAppointments = () => {
    setFromDay(1)
    setStartDay(1)
    setDaysInMonth(getDaysInMonth(1, 5, selectedDate))
    setLoadMoreDisabled(false)
    setAppointments([])
    setAppointmentsInMenu([])
    setBreaks([])
  }

  const getEmployeesList = async () => {
    try {
      let response = await axios.get(`/employee/getPublicList?businessId=${businessData?._id}`)

      const filteredEmployeeFormattedData = response?.data?.map((item) => ({
        id: item._id,
        _id: item._id,
        title: item.firstName + ' ' + item.lastName,
        ...item,
      }))

      setEmployees(filteredEmployeeFormattedData)
    } catch (error) {
      console.error('Error fetching employees list:', error)
    }
  }

  useEffect(() => {
    refreshAppointments()
  }, [selectedDate])
  useEffect(() => {
    getEmployeesList()
  }, [])

  useEffect(() => {
    getAppointmentsList(false)
    getBreaksList()
    const interval = setInterval(() => {
      refreshAppointmentsList()
    }, 20000)
    return () => clearInterval(interval)
  }, [daysInMonth])

  const getEpochTimes = (dateText, startTime, endTime) => {
    const startDateTimeString = `${dateText} ${startTime}`
    const endDateTimeString = `${dateText} ${endTime}`

    const startDateTime = new Date(startDateTimeString)
    const endDateTime = new Date(endDateTimeString)

    const epochStartTime = startDateTime.getTime()
    const epochEndTime = endDateTime.getTime()

    return {
      epochStartTime,
      epochEndTime,
    }
  }

  const getBreaksList = async () => {
    if (!businessData?._id) return

    const locQuery = locationId ? `&locationId=${locationId}` : ''
    const allBreaks = [...breaks]

    // Create an array of promises for each date
    const promises = daysInMonth.slice(fromDay - 1).map((day) => {
      const date = getFormattedMediumDate(day)
      return axios.get(`/employee/searchForBreaksByDate?businessId=${businessData._id}${locQuery}&dateText=${date}`)
    })

    try {
      // Wait for all promises to resolve
      const responses = await Promise.all(promises)

      // Concatenate all the breaks from the responses
      const newBreaks = responses.flatMap((response) => response.data || [])
      const updatedBreaks = allBreaks.concat(newBreaks).map((item) => {
        const { epochStartTime, epochEndTime } = getEpochTimes(item.dateText, item.startTime, item.endTime)

        item.startTimeText = item.startTime
        item.endTimeText = item.endTime
        // item.startTime = epochStartTime;
        // item.endTime = epochEndTime;
        item.selectedDate = item.dateText

        return item
      })

      // Update the state
      setBreaks(updatedBreaks)
    } catch (error) {
      console.error(error)
    }
  }
  const refreshAppointmentsList = async () => {
    if (!businessData?._id) return

    try {
      // Get the number of days in the selected month
      const year = new Date(selectedDate).getFullYear()
      const month = new Date(selectedDate).getMonth()
      const daysInMonthCount = new Date(year, month + 1, 0).getDate() // Total days in the month

      // Set fromDay and startDay as 1, and use daysInMonthCount
      const fromDay = 1
      const startDay = 1
      const daysInMonth = getDaysInMonth(startDay, daysInMonthCount, selectedDate)

      let locQuery = locationId ? `&locationId=${locationId}` : ''
      let allAppointments = []

      // Create an array of promises for each date
      const promises = daysInMonth.slice(fromDay - 1).map((day) => {
        let date = getFormattedMediumDate(day)
        return axios.get(`/appointment_v2/getList?businessId=${businessData._id}${locQuery}&dateText=${date}`)
      })

      // Wait for all promises to resolve
      const responses = await Promise.all(promises)

      // Concatenate all the appointments from the responses
      responses.forEach((response) => {
        const filteredData = response.data
        allAppointments = allAppointments.concat(filteredData)
      })

      // Update the state
      setAppointments(allAppointments)
      setAppointmentsInMenu(allAppointments)
    } catch (error) {
      console.error('Error refreshing appointments list:', error)
    }
  }
  const getAppointmentsList = async (hideLoader = false) => {
    if (!businessData?._id) return

    if (!hideLoader) {
      setLoading(true)
    }

    try {
      let locQuery = locationId ? `&locationId=${locationId}` : ''
      let allAppointments = [...appointments]

      // Create an array of promises for each date
      const promises = daysInMonth.slice(fromDay - 1).map((day) => {
        let date = getFormattedMediumDate(day)
        return axios.get(`/appointment_v2/getList?businessId=${businessData._id}${locQuery}&dateText=${date}`)
      })

      // Wait for all promises to resolve
      const responses = await Promise.all(promises)

      // Concatenate all the appointments from the responses
      responses.forEach((response) => {
        const filteredData = response.data
        allAppointments = allAppointments.concat(filteredData)
      })

      // Update the state
      setAppointments(allAppointments)
      setAppointmentsInMenu(allAppointments)
    } catch (error) {
      console.error(error)
    }

    if (!hideLoader) {
      setLoading(false)
    }
  }

  const handleSelectedEvent = (event) => {
    setSelectedEvent(event)
    setShowModal(true)
  }

  const closeModal = () => {
    setShowModal(false)
  }
  const mergeAndSortEvents = (appointments, breaks) => {
    const breaksWithFlag = breaks.map((b) => ({
      ...b,
      isBreak: true,
    }))

    const allEvents = [...appointments, ...breaksWithFlag]

    allEvents.sort((a, b) => {
      const startTimeA = new Date(a.startTime).getTime()
      const startTimeB = new Date(b.startTime).getTime()
      return startTimeA - startTimeB
    })

    return allEvents
  }
  const loadMoreData = async () => {
    if (loading) return
    setLoading(true)
    const newStartDay = startDay + noOfDays
    const newDays = getDaysInMonth(newStartDay, noOfDays, selectedDate)

    // Get the current year and month
    const year = new Date(selectedDate).getFullYear()
    const month = new Date(selectedDate).getMonth()

    // Calculate the end date of the current month
    const endOfMonth = new Date(year, month + 1, 0).getDate()

    // Check if any new days are outside the current month
    const filteredDays = newDays.filter((day) => {
      const isWithinMonth = day.getDate() <= endOfMonth && day.getMonth() === month
      return isWithinMonth
    })

    // Update the state with the filtered days
    setStartDay(newStartDay)
    setFromDay(newStartDay)
    setDaysInMonth((prevDays) => {
      const updatedDays = [...prevDays, ...filteredDays]
      return updatedDays
    })
    // Determine if there are days beyond the current month
    const daysOtherThanCurrentMonth = newDays.some((day) => day.getMonth() != month)

    // Disable infinite scroll if there are days outside the current month
    if (daysOtherThanCurrentMonth) {
      // Update a state or variable to disable infinite scroll
      setLoadMoreDisabled(true)
    }
    setLoading(false)
  }

  const allEvents = mergeAndSortEvents(appointments, breaks)

  return (
    <>
      <ResizableModal closeModal={closeModal} selectedEvent={selectedEvent} showModal={showModal} setShowModal={setShowModal} />
      <CalendarView5Header
        handlePrevWeek={handlePrevWeek}
        handleNextWeek={handleNextWeek}
        refreshAppointments={refreshAppointments}
        displayWeeks={false}
        displayCalendar={false}
        getBreaks={refreshAppointments}
        employees={employees}
        selectedDate={selectedDate}
        loading={loading}
        setSelectedDate={setSelectedDate}
      />
      <IonContent class='regularBackground'>
        <div style={{ display: 'grid', gridTemplateColumns: '5rem 3fr' }}>
          {daysInMonth.map((day, index) => {
            const dayAppointments = getAppointmentsForDay(allEvents, day)

            const isToday = day.getDate() === new Date().getDate()

            return (
              <React.Fragment key={index}>
                <div
                  id={`day-${index}`} // Assign id for last item in the list
                  style={{
                    ...styles.dayLabel,
                    padding: 10,
                    display: 'flex',
                    justifyContent: 'start',
                    alignItems: 'center',
                    flexDirection: 'column',
                    borderRight: '1px solid #ccc',
                  }}
                >
                  <IonLabel>{day.toLocaleDateString('en-US', { weekday: 'short' })}</IonLabel>
                  <IonLabel style={styles.dayNumberLabel(isToday)}>{day.toLocaleDateString('en-US', { day: 'numeric' })}</IonLabel>
                </div>
                <div style={{ padding: 10, borderTop: `1px solid ${colors.black5}` }}>
                  {isStoreClosed(day) ? (
                    <IonLabel>Store closed</IonLabel>
                  ) : dayAppointments.length === 0 ? (
                    <div style={styles.noAppointments}>
                      <IonLabel style={{ fontSize: fontSizes.size14 }}>No appointments</IonLabel>
                    </div>
                  ) : (
                    <>
                      {dayAppointments.map((appointment, idx) => {
                        const selectedEmployee = employees.find((item) => item.id == appointment?.employeeId)
                        let barberName = selectedEmployee
                          ? selectedEmployee?.firstName + ' ' + selectedEmployee?.lastName
                          : appointment?.barber?.firstName + ' ' + appointment?.barber?.lastName

                        return (selectedStylists.length === 0 || selectedStylists.includes(barberName)) &&
                          (selectedTypes.length == 0 || selectedTypes.includes(appointment?.status)) ? (
                          appointment.isBreak ? (
                            <BreakEvent appointment={appointment} businessData={businessData} index={index} />
                          ) : (
                            <div
                              onClick={() => handleSelectedEvent(appointment)}
                              key={idx}
                              style={styles.appointmentEvent(statusColors[appointment.status]?.bgColor, statusColors[appointment.status]?.textColor)}
                            >
                              <EventData appointment={appointment} businessData={businessData} />
                            </div>
                          )
                        ) : null
                      })}
                    </>
                  )}
                </div>
              </React.Fragment>
            )
          })}
        </div>
        <IonInfiniteScroll
          threshold='100px'
          onIonInfinite={async (ev) => {
            try {
              await loadMoreData() // Wait for loadMoreData to complete
              ev.target.complete() // Signal that loading is complete
            } catch (error) {
              console.error('Error loading more data:', error)
              ev.target.complete() // Complete the event even if there's an error
            }
          }}
          disabled={loadMoreDisabled}
        >
          <IonInfiniteScrollContent loadingText='Please wait...' loadingSpinner='bubbles'></IonInfiniteScrollContent>
        </IonInfiniteScroll>
      </IonContent>
    </>
  )
}

export default ListViewAppointments
