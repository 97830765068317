import { colors } from '../theme/colors'

export const editAppointmentTimeIntervals = [
  '5:00 AM',
  '5:15 AM',
  '5:30 AM',
  '5:45 AM',
  '6:00 AM',
  '6:15 AM',
  '6:30 AM',
  '6:45 AM',
  '7:00 AM',
  '7:15 AM',
  '7:30 AM',
  '7:45 AM',
  '8:00 AM',
  '8:15 AM',
  '8:30 AM',
  '8:45 AM',
  '9:00 AM',
  '9:15 AM',
  '9:30 AM',
  '9:45 AM',
  '10:00 AM',
  '10:15 AM',
  '10:30 AM',
  '10:45 AM',
  '11:00 AM',
  '11:15 AM',
  '11:30 AM',
  '11:45 AM',
  '12:00 PM',
  '12:15 PM',
  '12:30 PM',
  '12:45 PM',
  '1:00 PM',
  '1:15 PM',
  '1:30 PM',
  '1:45 PM',
  '2:00 PM',
  '2:15 PM',
  '2:30 PM',
  '2:45 PM',
  '3:00 PM',
  '3:15 PM',
  '3:30 PM',
  '3:45 PM',
  '4:00 PM',
  '4:15 PM',
  '4:30 PM',
  '4:45 PM',
  '5:00 PM',
  '5:15 PM',
  '5:30 PM',
  '5:45 PM',
  '6:00 PM',
  '6:15 PM',
  '6:30 PM',
  '6:45 PM',
  '7:00 PM',
  '7:15 PM',
  '7:30 PM',
  '7:45 PM',
  '8:00 PM',
  '8:15 PM',
  '8:30 PM',
  '8:45 PM',
  '9:00 PM',
  '9:15 PM',
  '9:30 PM',
  '9:45 PM',
  '10:00 PM',
  '10:15 PM',
  '10:30 PM',
  '10:45 PM',
  '11:00 PM',
  '11:15 PM',
  '11:30 PM',
  '11:45 PM',
  '11:59 PM',
]

export const reviewOptions = [
  {
    name: 'Google',
    imagesrc: '/assets/google.png',
  },
  {
    name: 'Facebook',
    imagesrc: '/assets/facebook.png',
  },
  {
    name: 'Yelp',
    imagesrc: '/assets/yelp.png',
  },
  {
    name: 'Business',
    imagesrc: '/assets/apple_business.png',
  },
  {
    name: 'Custom Link',
    custom: true,
    text: 'Custom Link',
  },
]
export const cancelSubscriptionOptions = [
  { value: 'not_enough', label: "I don't use the services enough" },
  { value: 'not_satisfied', label: 'I am not satisfied with the services' },
  { value: 'moving', label: 'I am moving' },
  { value: 'payment_declined', label: 'Payment Declined' },
  { value: 'payment_failed', label: 'Payment Failed' },
  { value: 'cancellation_requested', label: 'Cancellation Requested' },
  { value: 'other', label: 'Other' },
  { value: 'unknown', label: 'Unknown' },
  // Add more objects for additional radio buttons
]
export const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

export const roles = [
  {
    role: 'Owner',
    value: 'owner',
    description: 'Owner of the business',
  },
  {
    role: 'Admin',
    value: 'admin',
    description: 'Permission to do anything you want. With great power comes great responsibility.',
  },
  {
    role: 'Manager',
    value: 'manager',
    description: 'Permissions to change subscriptions and modify accounts.',
  },
  {
    role: 'Professional',
    value: 'professional',
    description: 'Standard user who can handle appointments',
  },
]

export const newUserRoles = [
  {
    role: 'Owner',
    value: 'owner',
    description: 'Owner of the business',
  },
  {
    role: 'Admin',
    value: 'admin',
    description: 'Permission to do anything you want. With great power comes great responsibility.',
  },
  {
    role: 'Manager',
    value: 'manager',
    description: 'Permissions to change subscriptions and modify accounts.',
  },
  {
    role: 'Professional',
    value: 'professional',
    description: 'Standard user who can handle appointments',
  },
]

export const statusColors = {
  Scheduled: { bgColor: '#45454552', textColor: '#454545' },
  Arrived: { bgColor: '#FFFFFF99', textColor: '#0068DE' },
  Canceled: { bgColor: '#E9E9E999', textColor: '#717171' },
  Complete: { bgColor: '#A9EFDC99', textColor: '#079455' },
  'In Progress': { bgColor: '#C2DEFF99', textColor: '#0068DE' },
  // 'No Show': { bgColor: '#FECDCA99', textColor: '#DE1200' },
  'No Show': { bgColor: '#E9E9E999', textColor: '#717171' },
}

export const messagesdata = [
  {
    id: 1,
    customerName: 'John Doe',
    mnemonic: 'JD',
    lastMessage: 'Hello, how are you?',
    time: Date.now() - 5 * 60000, // 5 minutes ago
    messages: [
      { sender: 'me', text: 'Hello, how are you?' },
      { sender: 'you', text: 'I need some help' },
      { sender: 'you', text: 'Can you please assist me' },
      { sender: 'me', text: 'Hey!' },
      { sender: 'me', text: 'yeah sure' },
      { sender: 'me', text: 'Let  me know how can i help you' },
      { sender: 'you', text: 'How about you?' },
      { sender: 'me', text: 'Im doing well, thanks for asking.' },
      { sender: 'you', text: 'Thats good to hear.' },
      { sender: 'me', text: 'Indeed.' },
      { sender: 'you', text: 'Alright, catch you later!' },
    ],
  },
  {
    id: 2,
    customerName: 'Jane Smith',
    mnemonic: 'JS',
    lastMessage: 'Is my order ready?',
    time: Date.now() - 30 * 60000, // 30 minutes ago
    messages: [
      { sender: 'you', text: 'Is my order ready?' },
      { sender: 'me', text: 'Yes, your order is ready.' },
      { sender: 'you', text: 'Thank you!' },
      { sender: 'me', text: 'Youre welcome!' },
      { sender: 'you', text: 'Can I pick it up today?' },
      { sender: 'me', text: 'Sure, itll be ready for pickup.' },
      { sender: 'you', text: 'Great, see you then!' },
      { sender: 'me', text: 'Looking forward to it.' },
    ],
  },
  {
    id: 3,
    customerName: 'Alice Johnson',
    mnemonic: 'AJ',
    lastMessage: 'Thank you for your help!',
    time: Date.now() - 90 * 60000, // 1.5 hours ago
    messages: [
      { sender: 'you', text: 'Thank you for your help!' },
      { sender: 'me', text: 'Youre welcome!' },
      { sender: 'you', text: 'Have a great day!' },
      { sender: 'me', text: 'You too!' },
      { sender: 'you', text: 'Goodbye!' },
      { sender: 'me', text: 'Goodbye!' },
    ],
  },
  {
    id: 4,
    customerName: 'Bob Brown',
    mnemonic: 'BB',
    lastMessage: 'Can I change the delivery date?',
    time: Date.now() - 60 * 60000 * 24, // 1 day ago
    messages: [
      { sender: 'you', text: 'Can I change the delivery date?' },
      { sender: 'me', text: 'Yes, you can change the delivery date.' },
      { sender: 'you', text: 'Thank you for letting me know.' },
      { sender: 'me', text: 'No problem.' },
      { sender: 'you', text: 'When is the earliest available date?' },
      { sender: 'me', text: 'The earliest available date is...' },
      { sender: 'you', text: 'That works for me.' },
      { sender: 'me', text: 'Alright, its been updated.' },
    ],
  },
  {
    id: 5,
    customerName: 'Clara Hill',
    mnemonic: 'CH',
    lastMessage: 'What payment methods do you accept?',
    time: Date.now() - 45 * 60000, // 45 minutes ago
    messages: [
      { sender: 'you', text: 'What payment methods do you accept?' },
      { sender: 'me', text: 'We accept credit/debit cards and PayPal.' },
      { sender: 'you', text: 'Great, thanks for the information.' },
      { sender: 'me', text: 'Youre welcome.' },
      { sender: 'you', text: 'Can I pay in installments?' },
      { sender: 'me', text: 'Yes, we offer installment plans.' },
      { sender: 'you', text: 'Awesome, Ill choose that option.' },
      { sender: 'me', text: 'Perfect, let me know if you need further assistance.' },
    ],
  },
  {
    id: 6,
    customerName: 'Dexter Plains',
    mnemonic: 'DP',
    lastMessage: 'Do you offer gift wrapping?',
    time: Date.now() - 2 * 60000 * 24 * 60, // 2 days ago
    messages: [
      { sender: 'you', text: 'Do you offer gift wrapping?' },
      { sender: 'me', text: 'Yes, we offer gift wrapping services.' },
      { sender: 'you', text: 'Thats fantastic, thank you!' },
      { sender: 'me', text: 'Youre welcome.' },
      { sender: 'you', text: 'Do you charge extra for it?' },
      { sender: 'me', text: 'No, its complimentary.' },
      { sender: 'you', text: 'Great, Ill add it to my order.' },
      { sender: 'me', text: 'Sounds good, let me know if you need anything else.' },
    ],
  },
  {
    id: 7,
    customerName: 'Eva Storm',
    mnemonic: 'ES',
    lastMessage: 'I need to update my shipping address.',
    time: Date.now() - 10 * 60000, // 10 minutes ago
    messages: [
      { sender: 'you', text: 'I need to update my shipping address.' },
      { sender: 'me', text: 'Sure, please provide me with the updated address.' },
      { sender: 'you', text: 'Here is my new address...' },
      { sender: 'me', text: 'Got it, Ill update it right away.' },
      { sender: 'you', text: 'Thank you!' },
      { sender: 'me', text: 'Youre welcome.' },
      { sender: 'you', text: 'Have a great day!' },
      { sender: 'me', text: 'You too!' },
    ],
  },
  {
    id: 8,
    customerName: 'Frank Peak',
    mnemonic: 'FP',
    lastMessage: 'How long does delivery take?',
    time: Date.now() - 120 * 60000, // 2 hours ago
    messages: [
      { sender: 'you', text: 'How long does delivery take?' },
      { sender: 'me', text: 'Delivery usually takes 3-5 business days.' },
      { sender: 'you', text: 'Thank you for the information.' },
      { sender: 'me', text: 'Youre welcome.' },
      { sender: 'you', text: 'Can I track my package?' },
      { sender: 'me', text: 'Yes, youll receive a tracking number.' },
      { sender: 'you', text: 'Great, Ill keep an eye out for it.' },
      { sender: 'me', text: 'Let me know if you need further assistance.' },
    ],
  },
  {
    id: 9,
    customerName: 'Grace Lake',
    mnemonic: 'GL',
    lastMessage: 'Can I return an item if I don’t like it?',
    time: Date.now() - 3 * 60000 * 24 * 60, // 3 days ago
    messages: [
      { sender: 'you', text: 'Can I return an item if I don’t like it?' },
      { sender: 'me', text: 'Yes, you can return the item within 30 days.' },
      { sender: 'you', text: 'Good to know, thank you!' },
      { sender: 'me', text: 'Youre welcome.' },
      { sender: 'you', text: 'How do I initiate the return process?' },
      { sender: 'me', text: 'You can initiate it through our website.' },
      { sender: 'you', text: 'Okay, Ill do that.' },
      { sender: 'me', text: 'Let me know if you encounter any issues.' },
    ],
  },
  {
    id: 10,
    customerName: 'John Doe',
    mnemonic: 'JD',
    lastMessage: 'Hello, how are you?',
    time: Date.now() - 5 * 60000, // 5 minutes ago
    messages: [
      { sender: 'me', text: 'Hello, how are you?' },
      { sender: 'you', text: 'I need some help' },
      { sender: 'you', text: 'Can you please assist me' },
      { sender: 'me', text: 'Hey!' },
      { sender: 'me', text: 'yeah sure' },
      { sender: 'me', text: 'Let  me know how can i help you' },
      { sender: 'you', text: 'How about you?' },
      { sender: 'me', text: 'Im doing well, thanks for asking.' },
      { sender: 'you', text: 'Thats good to hear.' },
      { sender: 'me', text: 'Indeed.' },
      { sender: 'you', text: 'Alright, catch you later!' },
    ],
  },
  {
    id: 11,
    customerName: 'John Doe',
    mnemonic: 'JD',
    lastMessage: 'Hello, how are you?',
    time: Date.now() - 5 * 60000, // 5 minutes ago
    messages: [
      { sender: 'me', text: 'Hello, how are you?' },
      { sender: 'you', text: 'I need some help' },
      { sender: 'you', text: 'Can you please assist me' },
      { sender: 'me', text: 'Hey!' },
      { sender: 'me', text: 'yeah sure' },
      { sender: 'me', text: 'Let  me know how can i help you' },
      { sender: 'you', text: 'How about you?' },
      { sender: 'me', text: 'Im doing well, thanks for asking.' },
      { sender: 'you', text: 'Thats good to hear.' },
      { sender: 'me', text: 'Indeed.' },
      { sender: 'you', text: 'Alright, catch you later!' },
    ],
  },
]

export const chartItems = [
  'Revenue',
  'Revenue from Subs (Beta)',
  'Appointments Completed',
  'Value of Products',
  'Total Tips',
  'Average Tip',
  'Taxes',
  'Subscriptions Sold',
  'Subscriptions Canceled',
  'Avg Appt per Sub',
]
