import { IonCol, IonGrid, IonRow, IonText, IonToggle } from '@ionic/react'
import TimeSelector from '../timeSelector'

const template = {
  Monday: { dayOfTheWeek: 1, enabled: false, startTime: null, endTime: null },
  Tuesday: { dayOfTheWeek: 2, enabled: false, startTime: null, endTime: null },
  Wednesday: { dayOfTheWeek: 3, enabled: false, startTime: null, endTime: null },
  Thursday: { dayOfTheWeek: 4, enabled: false, startTime: null, endTime: null },
  Friday: { dayOfTheWeek: 5, enabled: false, startTime: null, endTime: null },
  Saturday: { dayOfTheWeek: 6, enabled: false, startTime: null, endTime: null },
  Sunday: { dayOfTheWeek: 0, enabled: false, startTime: null, endTime: null },
}

const times = [
  '5:00 AM',
  '5:30 AM',
  '6:00 AM',
  '6:30 AM',
  '7:00 AM',
  '7:30 AM',
  '8:00 AM',
  '8:30 AM',
  '9:00 AM',
  '9:30 AM',
  '10:00 AM',
  '10:30 AM',
  '11:00 AM',
  '11:30 AM',
  '12:00 PM',
  '12:30 PM',
  '1:00 PM',
  '1:30 PM',
  '2:00 PM',
  '2:30 PM',
  '3:00 PM',
  '3:30 PM',
  '4:00 PM',
  '4:30 PM',
  '5:00 PM',
  '5:30 PM',
  '6:00 PM',
  '6:30 PM',
  '7:00 PM',
  '7:30 PM',
  '8:00 PM',
  '8:30 PM',
  '9:00 PM',
  '9:30 PM',
  '10:00 PM',
  '10:30 PM',
]

export default function StandardHoursTable({ standardSchedule, updateStandardSchedule }) {
  function handleChange(dayKey, fieldKey, newValue) {
    let d = data[dayKey]
    d[fieldKey] = newValue
    let a = { ...data, ...{ [dayKey]: d } }
    updateStandardSchedule(a)
  }

  let data = standardSchedule

  if (!standardSchedule) {
    data = template
  }

  return (
    <>
      <IonText>
        <h6>Standard Schedule</h6>
      </IonText>
      <IonGrid>
        <IonRow style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 12, fontWeight: '600', color:'gray' }}>
          <IonCol size='auto' style={{ justifyContent: 'end', display: 'flex', textAlign:'right' }}>
            <div style={{ width: 90 }}>OPEN</div>
          </IonCol>
          {/* <IonCol size='auto' style={{ justifyContent: 'center', display: 'flex' }}>
            <div style={{ width: 40 }}>OPEN</div>
          </IonCol> */}
          <IonCol style={{ justifyContent: 'center', display: 'flex' }}>
            <div>START</div>
          </IonCol>
          <IonCol style={{ justifyContent: 'center', display: 'flex' }}>
            <div>END</div>
          </IonCol>
        </IonRow>

        {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map((key) => (
          <IonRow key={key} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'nowrap', minWidth: '100%' }}>
            <IonCol size='auto' style={{ display: 'flex', flexShrink: 0 }}>
              <div style={{ width: 24, marginRight: 20, fontWeight: '600', marginTop:8 }}>{key.substring(0, 2)}</div>
              <div style={{ width: 50 }}>
                <IonToggle
                  checked={data[key].enabled}
                  onIonChange={(e) => {
                    handleChange(key, 'enabled', e.detail.checked)
                  }}
                />
              </div>
            </IonCol>
            <IonCol size='auto' style={{ justifyContent: 'center', display: 'flex', flexShrink: 0 }}>
              {data[key].enabled ? (
                <TimeSelector dayKey={key} fieldKey='startTime' value={data[key].startTime} handleChange={handleChange} />
              ) : (
                <div style={{ width: 85, height: 1 }} />
              )}
            </IonCol>
            <IonCol size='auto' style={{ justifyContent: 'center', display: 'flex', flexShrink: 0 }}>
              {data[key].enabled ? (
                <TimeSelector dayKey={key} fieldKey='endTime' value={data[key].endTime} handleChange={handleChange} />
              ) : (
                <div style={{ width: 85, height: 1 }} />
              )}
            </IonCol>
          </IonRow>
        ))}
      </IonGrid>
    </>
  )
}
