import { IonContent, IonIcon, IonItem, IonLabel, IonList, IonModal, IonInput, IonSpinner, IonToolbar, IonSearchbar } from '@ionic/react'
import React, { useState, useRef } from 'react'
import CommonHeader from '../commonComponents/CommonHeader'
import StandardContainer from '../commonComponents/StandardContainer'
import { checkmark } from 'ionicons/icons'
import axios from 'axios'

export default function CustomerListModal({ customersModalOpen, closeCustomersModal, chooseCustomer, customers, selectedCustomer }) {
  const [searchValue, setSearchValue] = useState('')
  const [filteredList, setFilteredList] = useState([])
  const [loading, setLoading] = useState(false)

  const handleSearchValue = async (value) => {
    setSearchValue(value)

    if (value !== '') {
      setLoading(true)
      try {
        const searchResponse = await axios.get(`/customer_v2/search?searchText=${value}`)
        if (searchResponse?.data) {
          setFilteredList(searchResponse.data)
        }
      } catch (error) {
        console.error('Error searching customers:', error)
      } finally {
        setLoading(false)
      }
    } else {
      setFilteredList([]) // Reset to show the original customer list when search is cleared
    }
  }

  const debounce = (func, wait) => {
    let timeout
    return (...args) => {
      clearTimeout(timeout)
      timeout = setTimeout(() => func(...args), wait)
    }
  }

  const debouncedHandleSearchValue = useRef(debounce((value) => handleSearchValue(value), 500)).current

  return (
    <IonModal isOpen={customersModalOpen} onDidDismiss={closeCustomersModal}>
      <CommonHeader
        debouncedHandleSearchValue={debouncedHandleSearchValue}
        searchValue={true}
        closeButton={true}
        closeModal={closeCustomersModal}
        title={'Choose Customer'}
      />
      <IonContent className='regularBackground'>
        <StandardContainer>
          {loading ? (
            <IonSpinner name='dots' />
          ) : (
            <IonList mode='ios' inset={true}>
              {(searchValue ? filteredList : customers)?.map((row) => (
                <IonItem onClick={() => chooseCustomer(row)} key={row._id}>
                  <IonLabel>
                    <h4 style={{ fontWeight: '600', color: 'black', fontSize: 18 }}>{`${row.firstName} ${row.lastName}`}</h4>
                    <p>{row.email}</p>
                  </IonLabel>
                  {selectedCustomer._id === row._id && <IonIcon icon={checkmark} />}
                </IonItem>
              ))}
            </IonList>
          )}
        </StandardContainer>
      </IonContent>
    </IonModal>
  )
}
