export const getMonthRange = monthsAgo => {
  const today = new Date()
  const currentMonth = today.getMonth()
  const currentYear = today.getFullYear()

  let start
  let end

  if (monthsAgo === 1) {
    start = new Date(currentYear, currentMonth - 1, 1) // First day of the previous month
    end = new Date(today.getFullYear(), currentMonth, 0) // Last day of the previous month
  } else {
    start = new Date(currentYear, currentMonth - monthsAgo + 1, 1)
    end = new Date(today.getFullYear(), today.getMonth() + 1, 0) // Last day of the current month

    if (monthsAgo === 0) {
      start = new Date(currentYear, currentMonth, 1) // 1st day of the current month
      end = new Date(today.getFullYear(), today.getMonth() + 1, 0) // Last day of the current month
    }
  }

  return {
    startDate: `${start.getFullYear()}-${('0' + (start.getMonth() + 1)).slice(-2)}-${(
      '0' + start.getDate()
    ).slice(-2)}`,
    endDate: `${end.getFullYear()}-${('0' + (end.getMonth() + 1)).slice(-2)}-${(
      '0' + end.getDate()
    ).slice(-2)}`,
  }
}
