import React from 'react'
import { IonCard, IonCardContent, IonInput, IonItem, IonLabel, IonRadioGroup, IonIcon, IonGrid, IonText } from '@ionic/react'
import { businessOutline, personOutline, storefrontOutline, earthOutline } from 'ionicons/icons';
import { colors } from '../theme/colors';

export default function DetailsRelationshipToBusiness({ location, updateLocation }) {
  const onValueChange = (field, value) => {
    updateLocation({ ...location, [field]: value })
  }

  if (!location) return <IonText>Loading...</IonText>
  const relationshipOptions = [
    {
      value: 'corporate',
      label: 'Corporate Store',
      description: 'This is a store that is majority owned by the corporate entity.',
      icon: businessOutline
    },
    {
      value: 'franchise',
      label: 'Franchise',
      description: 'This is a store that is a franchise of the corporate entity.',
      icon: storefrontOutline
    },
    {
      value: 'other',
      label: 'Other',
      description: 'This is a store that is not a corporate store or a franchise, but has rights to operate under this business.',
      icon: earthOutline
    }
  ];

  return (
    <div style={{ padding: 24 }}>
      <IonRadioGroup value={location.relationshipToBusiness} onIonChange={(e) => onValueChange('relationshipToBusiness', e.detail.value)}>
        {relationshipOptions.map((option) => (
          <IonItem
            key={option.value}
            lines="none"
            button
            detail={false}
            onClick={() => onValueChange('relationshipToBusiness', option.value)}
            style={{
              border: location.relationshipToBusiness === option.value ? `2px solid ${colors.primaryBlue}` : '1px solid #dcdcdc',
              borderRadius: '10px',
              marginBottom: '1rem',
              backgroundColor: location.relationshipToBusiness === option.value ? 'red' : 'white',
              display: 'flex',
              justifyContent: 'space-between', 
              alignItems: 'center',
              width: '100%'
            }}
          >
            <IonLabel style={{ padding: '1rem' }}>
              <h2 style={{ fontWeight: 'bold', margin: 0 }}>{option.label}</h2>
              <p style={{ margin: 0, color: '#6c757d' }}>{option.description}</p>
            </IonLabel>
            <IonIcon icon={option.icon} style={{ 
              fontSize: '24px',
              color: location.relationshipToBusiness === option.value ? `${colors.primaryBlue}` : 'gray'
            }} />
          </IonItem>
        ))}
      </IonRadioGroup>
    </div>
  );
}
