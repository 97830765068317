import { colors } from '../../../theme/colors'
import { fontSizes, fontWeights } from '../../../theme/typography'

const styles = {
  baseStyle: {
    cursor: 'pointer',
    borderRadius: 10,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    display: 'flex',
    border: `1px solid ${colors.black20}`,
  },
  modifyerStyle: {
    border: `1px solid ${colors.black20}`,
    backgroundColor: colors.white,
  },
  modifyerSelectedStyle: {
    backgroundColor: '#e9f1ff',
    border: `2px solid #2a73f5`,
    boxShadow: 'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px',
  },
  tipButtonLabel: {
    fontWeight: fontWeights.weightBold,
    margin: 0,
    fontSize: fontSizes.size26,
  },
  tipButtonSubLabel: {
    margin: 0,
    color: colors.black50,
  },
  gridStyle: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    display: 'flex',
    position: 'relative',
  },
  totalLabel: {
    fontSize: fontSizes.size12,
  },
  totalPriceRow: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 1rem',
    fontSize: fontSizes.size50,
    fontWeight: fontWeights.weight600,
  },
  dollarSign: {
    fontSize: fontSizes.size30,
    position: 'absolute',
    left: 0,
    top: 0,
  },
  totalPrice: {
    fontSize: fontSizes.size50,
  },
  tipInfo: {
    fontSize: fontSizes.size14,
    color: colors.black60,
  },
  viewReceiptRow: {
    marginTop: '1rem',
  },
  receiptIcon: {
    marginRight: 10,
  },
  viewReceiptLabel: {
    fontSize: 12,
    textDecoration: 'underline',
  },
  tipHeaderGrid: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    display: 'flex',
    marginTop: '1rem',
  },
  tipHeaderLabel: {
    color: colors.black50,
  },
  finalizePaymentGrid: {
    display: 'flex',
    flexDirection: 'column',
    // position: 'absolute',
    // bottom: '1rem',
    width: '99%',
    borderTop: `1px solid ${colors.black20}`,
  },
  finalizePaymentButton: {
    height: 30,
  },
  processingSuccessContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: 20,
  },
  errorIcon: {
    height: 100,
    width: 100,
  },
  errorTitle: {
    fontSize: 50,
    marginTop: 0,
  },
  retryButton: {
    width: '100%',
    height: 70,
  },
  successTitle: {
    fontSize: 50,
    marginTop: 0,
  },
  doneButton: {
    width: '100%',
    height: 70,
  },
  cancelButton: {
    width: '100%',
    height: 70,
  },
  successGrid: {
    marginTop: '2rem',
    padding: '20px',
    textAlign: 'center',
  },
  successRow: {
    marginBottom: '15px',
  },
  successLabel: {
    fontSize: '18px',
    fontWeight: 'bold',
  },
}
export default styles
