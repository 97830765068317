import { IonCol, IonGrid, IonLabel, IonRow } from '@ionic/react'

export default function ListView({ imageList, handleImageClick, selectedImage }) {
  // Format uploadedTime to a readable date string
  const formatDate = date => {
    let newDate = new Date(date)
    const formattedDate = newDate.toLocaleDateString('en-US', {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    })
    return formattedDate
  }

  return (
    <IonGrid>
      <IonRow className='ion-align-items-stretch' style={{ width: '100%' }}>
        {imageList &&
          imageList.map((image, index) => (
            <IonCol
              style={{
                'padding': '0 10px',
                'marginBottom': '1.5rem',
                // Apply the border-radius and shadow
                '--border-radius': '10px',
                'borderRadius': 'var(--border-radius)',
              }}
              size='6'
              key={index}
            >
              <img
                style={{
                  minHeight: '11rem',
                  height: '11rem',
                  width: '11rem',
                  minWidth: '11rem',
                  objectFit: 'cover',
                  borderRadius: 'var(--border-radius)',
                  boxShadow: 'rgb(0 0 0 / 40%) 0px 4px 4px',
                }}
                alt={image.name}
                src={image.data}
                onClick={() => handleImageClick(image, index)}
                className={selectedImage === image.data ? 'selected-image' : ''}
              />
              <IonRow>
                <IonLabel style={{ margin: '10px 0 0 10px' }}>{image.name}</IonLabel>
              </IonRow>
              <IonRow>
                <IonLabel style={{ margin: '5px 0 5px 10px' }}>
                  {formatDate(image.uploadedTime)}
                </IonLabel>
              </IonRow>
            </IonCol>
          ))}
        {imageList.length === 0 && (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <IonLabel>No Pictures To Display</IonLabel>
          </div>
        )}
      </IonRow>
    </IonGrid>
  )
}
