import { IonButton, IonImg } from '@ionic/react'

export default function DisabledDeflectionMessageCard() {
  return (
    <>
      <div style={{ padding: '1rem' }}>
        <h1 style={{ marginTop: '3rem', fontSize: '36px', fontWeight: 'bold' }}>
          Request Customer Reviews to Boost your Credibility
        </h1>
        <IonButton
          routerLink='/core/requestCustomerReviews'
          fill='outline'
          style={{ marginTop: '2rem', width: '80%' }}
        >
          Get Started
        </IonButton>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <IonImg
          style={{
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
          }}
          src={'/assets/reviewArt.png'}
        ></IonImg>
      </div>
    </>
  )
}
