import { useState } from 'react'
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonLabel,
  IonPage,
  IonProgressBar,
  IonSegment,
  IonSegmentButton,
  IonTitle,
  IonToolbar,
} from '@ionic/react'
import { addCircle } from 'ionicons/icons'
import ActiveUsers from './ActiveUsers'
import InactiveUsers from './InactiveUsers'

export default function UserListView() {
  const [loading, setLoading] = useState(false)
  const [activeTab, setActiveTab] = useState('activeUsers')
  const updateLoader = (value) => {
    setLoading(value)
  }
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color='white' style={{ backgroundColor: 'white', color: 'black' }}>
          <IonTitle>Users</IonTitle>
          <IonButtons slot='end'>
            <IonButton color='primary' routerLink='/core/users/addNew' className='desktop-button'>
              Create
            </IonButton>
            <IonButton color='primary' routerLink='/core/users/addNew' className='mobile-button'>
              <IonIcon slot='icon-only' icon={addCircle} color='primary' />
            </IonButton>
          </IonButtons>
        </IonToolbar>
        <IonToolbar color='white' style={{ backgroundColor: 'white', color: 'black' }}>
          <IonSegment
            style={{ display: 'flex', justifyContent: 'flex-start' }}
            value={activeTab}
            color='dark'
            onIonChange={(e) => {
              setActiveTab(e?.detail?.value)
            }}
          >
            <IonSegmentButton style={{ minWidth: 'fit-content' }} value='activeUsers'>
              <IonLabel>Active Users</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton style={{ minWidth: 'fit-content' }} value='inactiveUsers'>
              <IonLabel>Inactive Users</IonLabel>
            </IonSegmentButton>
          </IonSegment>
          {loading ? <IonProgressBar type='indeterminate' /> : null}
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {activeTab == 'activeUsers' && <ActiveUsers updateLoader={updateLoader} />}
        {activeTab == 'inactiveUsers' && <InactiveUsers updateLoader={updateLoader} />}
      </IonContent>
    </IonPage>
  )
}
