import { useState, useEffect, useContext } from 'react'
import axios from 'axios'
import ResultsList from './resultsList'
import AddDialog from './addDialog'
import { AuthContext } from '../auth'
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonMenuButton,
  IonPage,
  IonProgressBar,
  IonRefresher,
  IonRefresherContent,
  IonTitle,
  IonToolbar,
  IonMenu,
  IonItem,
  IonLabel,
  IonRouterLink,
  IonText,
  IonRow,
  IonCol,
} from '@ionic/react'
import { addCircle, alertCircleOutline, archiveOutline, fileTrayFull, fileTrayFullOutline, storefrontOutline } from 'ionicons/icons'
import StandardContainer from '../commonComponents/StandardContainer'
import DefaultMenu from '../commonComponents/SideMenu/DefaultMenu'
import CheckIsManagerOrAdmin from '../Utils/CheckIsManagerOrAdmin'
import AddHeader from '../commonComponents/AddHeader'
import NoPermissionsCard from '../commonComponents/NoPermissionsCard'
import { colors } from '../theme/colors'

export default function EmployeeListView() {
  const { businessData, userData, locationId } = useContext(AuthContext)
  const userEntitlements = CheckIsManagerOrAdmin(userData, businessData, locationId)
  const [employees, setEmployees] = useState([])
  const [addLocationDialogOpen, setAddLocationDialogOpen] = useState(false)
  const [showingArchived, setShowingArchived] = useState(false)
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [showAlert, setShowAlert] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)

  useEffect(() => {
    getEmployeesList()
  }, [showingArchived, businessData, locationId])

  const getEmployeesList = async () => {
    if (!businessData?._id) return
    setLoading(true)
    setErrorMessage('')
    try {
      const locQuery = locationId ? `&locationId=${locationId}` : ''
      const showArchivedQuery = showingArchived ? '&showArchived=true' : ''
      const response = await axios.get(`/employee/getList?businessId=${businessData?._id}${locQuery}${showArchivedQuery}`)
      setEmployees(response.data)
    } catch (error) {
      setErrorMessage('There was an error loading. Try again')
    }
    setLoading(false)
  }

  const openAddLocationDialog = () => {
    setAddLocationDialogOpen(true)
  }

  const closeAddLocationDialog = () => {
    getEmployeesList()
    setAddLocationDialogOpen(false)
  }

  async function handleRefresh(event) {
    await getEmployeesList()
    event.detail.complete()
  }

  if (userEntitlements?.noPermissions) {
    return (
      <>
        <DefaultMenu />
        <IonPage id='main-content'>
          <AddHeader title={'Employees'} />
          <IonContent>
            <StandardContainer>
              <NoPermissionsCard />
            </StandardContainer>
          </IonContent>
        </IonPage>
      </>
    )
  }

  return (
    <>
      <DefaultMenu />
      <IonPage id='main-content'>
        <IonHeader>
          <IonToolbar color='white' style={{ backgroundColor: 'white', color: 'black' }}>
            <IonButtons slot='start'>
              <IonMenuButton>
                <IonIcon icon={storefrontOutline} />
              </IonMenuButton>
            </IonButtons>
            <IonTitle>Employees</IonTitle>
            <IonButtons slot='end'>
              <IonButton onClick={() => setShowingArchived(!showingArchived)} color={showingArchived ? 'danger' : 'default'}>
                <IonIcon slot='icon-only' icon={showingArchived ? fileTrayFull : fileTrayFullOutline} />
              </IonButton>
              <IonButton color='primary' onClick={openAddLocationDialog} className='desktop-button'>
                Create
              </IonButton>
              <IonButton color='primary' onClick={openAddLocationDialog} className='mobile-button'>
                <IonIcon slot='icon-only' icon={addCircle} color='primary' />
              </IonButton>
            </IonButtons>
            {loading && <IonProgressBar type='indeterminate' />}
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonRefresher slot='fixed' onIonRefresh={handleRefresh}>
            <IonRefresherContent />
          </IonRefresher>
          <StandardContainer>
            {showingArchived ? (
              <>
                <IonRow style={{ backgroundColor: '#fff4e5', padding: 10, display: 'flex', width: '100%' }}>
                  <IonRow>
                    <IonCol size='auto'>
                      <IonIcon size='large' icon={alertCircleOutline} color='warning' />
                    </IonCol>
                    <IonCol>
                      <IonLabel style={{ fontSize: 14, color: colors.black70 }}>
                        {' '}
                        You are viewing archived employees. To unarchive an employee: Click on the employee name -&gt; Advanced -&gt; Unarchived{' '}
                      </IonLabel>
                    </IonCol>
                  </IonRow>
                  <IonRow style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <IonButton
                      onClick={() => {
                        setShowingArchived(false)
                      }}
                    >
                      Show Active Employees
                    </IonButton>
                  </IonRow>
                </IonRow>
              </>
            ) : null}
            {errorMessage && (
              <IonText color='danger' style={{ margin: 20 }}>
                {errorMessage}
              </IonText>
            )}
            <ResultsList employees={employees} locationId={locationId} businessId={businessData?._id} />
            <AddDialog open={addLocationDialogOpen} handleClose={closeAddLocationDialog} businessId={businessData?._id} locationId={locationId} />
            <IonMenu side='end' isOpen={Boolean(anchorEl)} onDidDismiss={() => setAnchorEl(null)}>
              <IonContent>
                <IonItem>
                  <IonRouterLink routerLink={showingArchived ? '?' : '?showArchived=true'}>
                    <IonLabel>{showingArchived ? 'Show Active Employees' : 'Show Archived Employees'}</IonLabel>
                    <IonIcon icon={archiveOutline} />
                  </IonRouterLink>
                </IonItem>
              </IonContent>
            </IonMenu>
          </StandardContainer>
        </IonContent>
      </IonPage>
    </>
  )
}
