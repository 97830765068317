import React from 'react';
import { IonIcon, IonLabel } from '@ionic/react';
import { businessOutline, storefrontOutline, earthOutline } from 'ionicons/icons';

const RelationshipToBusiness = ({ relationshipToBusiness }) => {
  let icon;
  let name;

  switch (relationshipToBusiness?.toLowerCase()) {
    case 'corporate':
      icon = businessOutline;
      name = 'Corporate';
      break;
    case 'franchise':
      icon = storefrontOutline;
      name = 'Franchise';
      break;
    default:
      icon = earthOutline;
      name = 'Other';
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <IonIcon icon={icon} style={{ marginRight: '8px' }} />
      <IonLabel>{name}</IonLabel>
    </div>
  );
};

export default RelationshipToBusiness;
