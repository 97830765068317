import { IonAvatar, IonIcon, IonImg, IonItem, IonLabel, IonList, IonListHeader } from '@ionic/react'

export default function ResultsList({ customers = [], locationId, searchValue, loading }) {
  return (
    <>
      <h6 style={{ color: 'grey', marginLeft: 20 }}>Customers</h6>
      {customers && customers.length > 0 && (
        <IonList inset={true} mode='ios'>
          {customers?.map(row => (
            <IonItem
              button
              detail={true}
              routerLink={`/core/customers/${row._id}`}
              routerDirection='forward'
              key={row._id}
            >
              <IonLabel>
                <h4
                  style={{ fontWeight: '600', color: 'black', fontSize: 18 }}
                >{`${row.firstName} ${row.lastName}`}</h4>
                <p>{row.email}</p>
                <p>{row.phone}</p>
              </IonLabel>
            </IonItem>
          ))}
        </IonList>
      )}
      {!loading && customers && !customers.length && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <IonImg
            style={{
              width: '45%',
              maxWidth:200,
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
            }}
            src={'/assets/noresultsguy.png'}
          ></IonImg>
          {searchValue && <h6 style={{ color: 'grey' }}>No Matches found for {searchValue}</h6>}
          {!searchValue && <h6 style={{ color: 'grey' }}>No Customers </h6>}
        </div>
      )}
    </>
  )
}
