import { IonCol, IonContent, IonGrid, IonItem, IonLabel, IonList, IonPage, IonRow, IonIcon, IonMenuButton } from '@ionic/react'
import { useContext, useEffect, useState } from 'react'
import { storefrontOutline } from 'ionicons/icons' // import the home icon from ionicons
import axios from 'axios'
import StandardContainer from '../commonComponents/StandardContainer'
import { AuthContext } from '../auth'
import { fontSizes, fontWeights } from '../theme/typography'
import { getFormattedMediumDate } from '../utils'
import DefaultMenu from '../commonComponents/SideMenu/DefaultMenu'

export default function Dashboard() {
  const { locationData, businessData } = useContext(AuthContext)
  const [locationDetails, setLocationDetails] = useState([]) // State to store location data with appointments

  // Fetch each location's data
  useEffect(() => {
    const fetchLocationData = async () => {
      try {
        const locationPromises = locationData.map(async (location) => {
          let locQuery = location?._id ? `&locationId=${location?._id}` : ''
          let today = new Date()

          let date = getFormattedMediumDate(today)
          let response = await axios.get(`/appointment_v2/getList?businessId=${businessData._id}${locQuery}&dateText=${date}`)
          let apptData = {
            Complete: 0,
            'In Progress': 0,
            'No Show': 0,
            Scheduled: 0,
            Arrived: 0,
            Total: 0,
            Canceled: 0,
          }
          for (let a of response?.data) {
            apptData[a.status]++
            apptData['Total']++
          }
          return { ...location, apptData: apptData } // Append appointments to each location
        })
        const detailedLocations = await Promise.all(locationPromises)
        setLocationDetails(detailedLocations) // Update state with detailed location data
      } catch (error) {
        console.error('Error fetching locations:', error)
      }
    }

    if (businessData && businessData._id) {
      fetchLocationData() // Initial fetch

      const interval = setInterval(() => {
        fetchLocationData() // Fetch data every 15 seconds
      }, 15000)

      return () => clearInterval(interval) // Cleanup interval on unmount
    }
  }, [businessData?._id])

  // Helper function to calculate width percentage of each appointment status
  const calculateWidth = (statusCount, totalCount) => {
    return totalCount > 0 ? `${(statusCount / totalCount) * 100}%` : '0%'
  }

  const statusArray = [
    { key: 'Complete', color: '#079455' }, // Complete
    { key: 'In Progress', color: '#0068DE' }, // In Progress
    { key: 'Scheduled', color: '#454545' }, // Scheduled
    { key: 'Arrived', color: '#0068DE' }, // Arrived
    { key: 'No Show', color: '#717171' }, // No Show
    { key: 'Canceled', color: '#717171' }, // Canceled
  ]

  const renderStatusBar = (count, total, color) => (
    <div
      style={{
        width: calculateWidth(count, total),
        backgroundColor: color,
        height: '100%',
      }}
    />
  )

  const styles = {
    barContainer: {
      display: 'flex',
      width: '100%',
      height: '10px',
      backgroundColor: '#e0e0e0',
    },
  }

  return (
    <>
      <DefaultMenu />
      <IonPage id='main-content'>
        <IonContent className='regularBackground'>
          <StandardContainer padding={20}>
            <IonMenuButton style={{ display: 'flex', width: 'fit-content' }} color={'dark'}>
              <IonIcon icon={storefrontOutline}></IonIcon>
            </IonMenuButton>
            <IonGrid>
              <IonRow style={{ marginTop: 10 }}>
                <IonLabel color={'primary'}>Business</IonLabel>
              </IonRow>
              <IonRow>
                <IonLabel style={{ fontSize: fontSizes.size35, marginTop: 10, fontWeight: fontWeights.weightBold }}>{businessData?.name}</IonLabel>
              </IonRow>
            </IonGrid>
            <IonList lines='none'>
              {locationDetails?.map((location) => (
                <IonItem>
                  <IonGrid style={{ padding: 0, margin: '10px 0' }}>
                    <IonRow style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <IonCol size='9'>
                        <IonLabel>
                          <h4 style={{ fontWeight: '600', color: 'black', fontSize: 18 }}>{`${location.name}`}</h4>
                          <p>
                            {location.addressStreet || location.addressState
                              ? `${location.addressStreet1 || ''}, ${location.addressCity || ''}, ${location.addressState || ''} ${
                                  location.addressZipcode || ''
                                }`
                              : ''}
                          </p>

                          {/* Legend */}
                        </IonLabel>
                      </IonCol>
                      <IonCol size='2' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                        {location?.apptData?.Total > 0 && (
                          <>
                            <IonLabel style={{ fontSize: fontSizes.size25 }}>{location.apptData?.Total - (location.apptData?.Canceled || 0) - (location.apptData?.["No Show"] || 0)}</IonLabel>

                            {/* Stacked bar chart */}
                            <div style={styles.barContainer}>
                              {statusArray
                                .filter(({ key }) => key !== 'Canceled' && key !== 'No Show')
                                .map(({ key, count, color }) => renderStatusBar(location.apptData[key], location.apptData.Total, color))}
                            </div>
                          </>
                        )}
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </IonItem>
              ))}
            </IonList>
          </StandardContainer>
        </IonContent>
      </IonPage>
    </>
  )
}
