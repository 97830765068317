import { IonCol, IonGrid, IonItem, IonLabel, IonList, IonRow } from '@ionic/react'
import { getPricing } from '../utils'

export default function SubscriptionInvoice({ currentSubscription, selectedPackage, selectedPrice }) {
  return (
    <IonList inset={true} mode='ios'>
      <IonItem>
        <IonLabel>
          {}
          <p style={{ color: 'black', fontWeight: '600' }}>
            {currentSubscription.customer.firstName} {currentSubscription.customer.lastName}
          </p>
          <br></br>

          <IonGrid style={{ margin: 0, padding: 0, marginLeft: -4 }}>
            <IonRow style={{ margin: 0, color: 'grey' }}>
              <IonCol>
                <s>{currentSubscription?.subscriptionPackage?.name}</s>
              </IonCol>
              <IonCol size='auto'>
                <s>
                  {' '}
                  ${currentSubscription?.price} {currentSubscription?.period}
                </s>
              </IonCol>
            </IonRow>
            <IonRow style={{ margin: 0 }}>
              <IonCol>
                <h6 style={{ fontWeight: 'bold' }}>{selectedPackage?.name}</h6>
              </IonCol>
            </IonRow>
            <IonRow style={{ margin: 0 }}>
              <IonCol>
                <IonLabel>{selectedPrice?.name}</IonLabel>
              </IonCol>

              <IonCol size='auto'>{getPricing(selectedPrice)}</IonCol>
            </IonRow>
          </IonGrid>
        </IonLabel>
      </IonItem>
    </IonList>
  )
}
