import { IonIcon, IonLabel } from '@ionic/react'
import { footstepsOutline, timeOutline } from 'ionicons/icons'

export default function HorizontalEvent({ person, expandedColumn, event, overlappingEventsCount }) {
  return (
    <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
      {/* Name and Services Container */}
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        }}
      >
        <IonLabel
          style={{
            fontSize: 12,
            fontWeight: 'bold',
            marginRight: 5,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {event?.customer?.firstName} {event?.customer?.lastName}
        </IonLabel>
        {(overlappingEventsCount == 0 || expandedColumn == person) && (
          <>
            <span style={{ fontSize: 10, marginRight: 5 }}> | </span>
            <div
              style={{
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }}
            >
              {event?.services.map((service, index) => (
                <IonLabel
                  key={index}
                  style={{
                    fontSize: 10,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {service.name}
                  {index < event.services.length - 1 && ', '}
                </IonLabel>
              ))}
            </div>
          </>
        )}
      </div>

      {/* Duration and Link Icon Container */}
      {(overlappingEventsCount == 0 || overlappingEventsCount == 1 || expandedColumn == person) && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            flex: '0 0 auto',
            marginLeft: 'auto',
            // position: 'absolute',
            right: '10px',
          }}
        >
          {(overlappingEventsCount == 0 || expandedColumn == person) && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                width: '50px',
              }}
            >
              <IonIcon style={{ height: 10, width: 10, marginRight: 2 }} icon={timeOutline} />
              <IonLabel
                style={{
                  fontSize: 10,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  marginRight: 5,
                }}
              >
                {event.durationMin} min
              </IonLabel>
              {event.type == 'Walkin' && <IonIcon style={{ height: 10, width: 10 }} icon={footstepsOutline} />}
            </div>
          )}
        </div>
      )}
    </div>
  )
}
