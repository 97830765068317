import React, { useEffect, useState } from 'react'
import { IonList, IonItem, IonLabel, IonInput, IonTextarea, IonSelect, IonSelectOption } from '@ionic/react'
import axios from 'axios'

export default function DetailsView({ corporateDeal, updateCorporateDeal, businessData, locationId }) {
  const [subscriptionPackages, setSubscriptionPackages] = useState([])
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)

  useEffect(() => {
    const getSubscriptionPackagesList = async () => {
      if (!businessData?._id) return

      setLoading(true)
      setErrorMessage(null) // Clear any previous error messages
      try {
        let locQuery = locationId ? `&locationId=${locationId}` : ''
        const response = await axios.get(`/subscriptionPackage/getList?businessId=${businessData._id}${locQuery}`)

        if (response.status === 200) {
          setSubscriptionPackages(response.data)
        } else {
          setErrorMessage('Failed to fetch subscription packages.')
        }
      } catch (error) {
        console.error('Error fetching subscription packages:', error) // Log error for debugging
        setErrorMessage('Sorry, there was an issue fetching subscription packages.') // User-friendly error message
      } finally {
        setLoading(false) // Ensure loading state is reset
      }
    }

    getSubscriptionPackagesList()
  }, [businessData._id, locationId]) // dependencies

  let onValueChange = (field, value) => {
    updateCorporateDeal({ ...corporateDeal, [field]: value })
  }

  if (!corporateDeal) return 'Loading...'

  return (
    <>
      <IonList inset={true} mode='ios'>
        <IonItem>
          <IonLabel position='stacked'>Name</IonLabel>
          <IonInput
            type='text'
            value={corporateDeal.name}
            onIonInput={(e) => {
              onValueChange('name', e.detail.value)
            }}
          ></IonInput>
        </IonItem>
      </IonList>
      <IonList inset={true} mode='ios'>
        <IonItem>
          <IonLabel position='stacked'>Description</IonLabel>
          <IonTextarea
            value={corporateDeal.description}
            onIonChange={(e) => {
              onValueChange('description', e.detail.value)
            }}
          ></IonTextarea>
        </IonItem>
      </IonList>

      {loading ? (
        <p>Loading subscription packages...</p>
      ) : errorMessage ? (
        <p>{errorMessage}</p>
      ) : (
        <IonList inset={true} mode='ios'>
          <IonItem>
            <IonLabel position='stacked'>Subscription Package</IonLabel>
            <IonSelect
              value={corporateDeal?.subscriptionPackageId}
              onIonChange={(e) => {
                onValueChange('subscriptionPackageId', e.detail.value)
              }}
            >
              {subscriptionPackages?.map((pkg) => (
                <IonSelectOption key={pkg?._id} value={pkg?._id}>
                  {pkg?.name}
                </IonSelectOption>
              ))}
              <IonSelectOption key={null} value={null}>
                None
              </IonSelectOption>
            </IonSelect>
          </IonItem>
        </IonList>
      )}
    </>
  )
}
