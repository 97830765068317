import React from 'react'
import { IonCard, IonCardContent, IonInput, IonItem, IonLabel, IonRow, IonCol, IonGrid, IonText } from '@ionic/react'

export default function DetailsView({ location, updateLocation }) {
  const onValueChange = (field, value) => {
    updateLocation({ ...location, [field]: value })
  }

  if (!location) return <IonText>Loading...</IonText>

  return (
    <IonCard>
      <IonGrid>
        <IonRow>
          <IonCol size='12'>
            <IonItem>
              <IonLabel position='stacked'>Name</IonLabel>
              <IonInput value={location.name} onIonInput={(e) => onValueChange('name', e.detail.value)} />
            </IonItem>
            <IonItem>
              <IonLabel position='stacked'>Email</IonLabel>
              <IonInput value={location.email} onIonInput={(e) => onValueChange('email', e.detail.value)} />
            </IonItem>
            <IonItem>
              <IonLabel position='stacked'>Phone Number</IonLabel>
              <IonInput value={location.phoneNumber} onIonInput={(e) => onValueChange('phoneNumber', e.detail.value)} />
            </IonItem>
            <IonItem>
              <IonLabel position='stacked'>Address</IonLabel>
              <IonInput value={location.addressStreet1} onIonInput={(e) => onValueChange('addressStreet1', e.detail.value)} />
            </IonItem>
            <IonItem>
              <IonLabel position='stacked'>Address (line 2)</IonLabel>
              <IonInput value={location.addressStreet2} onIonInput={(e) => onValueChange('addressStreet2', e.detail.value)} />
            </IonItem>
            <IonRow>
              <IonCol size='4'>
                <IonItem>
                  <IonLabel position='stacked'>City</IonLabel>
                  <IonInput value={location.addressCity} onIonInput={(e) => onValueChange('addressCity', e.detail.value)} />
                </IonItem>
              </IonCol>
              <IonCol size='4'>
                <IonItem>
                  <IonLabel position='stacked'>State</IonLabel>
                  <IonInput value={location.addressState} onIonInput={(e) => onValueChange('addressState', e.detail.value)} />
                </IonItem>
              </IonCol>
              <IonCol size='4'>
                <IonItem>
                  <IonLabel position='stacked'>Zipcode</IonLabel>
                  <IonInput value={location.addressZipcode} onIonInput={(e) => onValueChange('addressZipcode', e.detail.value)} />
                </IonItem>
              </IonCol>
            </IonRow>
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonCard>
  )
}
