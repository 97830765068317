import { IonContent, IonPage } from '@ionic/react'
import AppHeader from '../../app/header'
import StandardContainer from '../../commonComponents/StandardContainer'
import Flow from './flow'

const BusinessCreateFlow = () => {
  console.log('CREATE')
  return (
    <IonPage id='main-content'>
      <AppHeader />
      <IonContent className='regularBackground'>
        <StandardContainer>
          <Flow
            data={[
              // {
              //   title: 'Your Information',
              //   stepName: 'personal',
              //   backButtonText: 'Back',
              //   nextButtonText: 'Business Information',
              // },
              {
                title: 'Business Information',
                stepName: 'businessform',
                backButtonText: 'Back',
                nextButtonText: 'Locations',
              },
              {
                title: 'Location Setup',
                stepName: 'location',
                backButtonText: 'Back',
                nextButtonText: 'Billing',
              },
              {
                title: 'Billing',
                stepName: 'billing',
                backButtonText: 'Back',
                nextButtonText: 'Next',
              },
              // {
              //   title: 'Website Styles',
              //   stepName: 'website',
              //   backButtonText: 'Back',
              //   nextButtonText: 'Website Contact',
              // },
              // {
              //   title: 'Website Contact',
              //   stepName: 'contact',
              //   backButtonText: 'Back',
              //   nextButtonText: 'Finish',
              // },

              {
                title: 'You are all set!',
                stepName: 'summary',
                backButtonText: 'Back',
                nextButtonText: 'Go to my dashboard',
                nextButtonRedirect: '/core/dashboard',
              },
            ]}
          />
        </StandardContainer>
      </IonContent>
    </IonPage>
  )
}

export default BusinessCreateFlow

// const [flowState, setFlowState] = useState([
//   {
//     title: 'Create my business',
//     content:
//       'In just a few easy steps you will up and running and ready to go. Lets get your barbershop, salon or personal business started on ClubCuts today!',
//     backButtonText: '',
//     nextButtonText: 'Lets get started!',
//   },
//   {
//     title: 'Business Information?',
//     content: `what is the name of your business?
//               Do you already have a domain name registered?
//               Do you have a business email?
//               `,
//     backButtonText: 'Cancel',
//     backButtonIcon: closeCircleOutline,
//     nextButtonText: 'Locations',
//     nextButtonIcon: caretForwardOutline,
//   },
//   {
//     title: 'Location Setup',
//     content: `Lets name the location: (North Side, South Side, Highlands Ranch)
//               What is the physical address of the location? (If you dont have a physical location or you travel that is ok, just select the Mobile option )
//               We found a few pictures of this location select the ones you want to use, if any (we can upload another custom image later)
//               `,
//     canSkip: true,
//     backButtonText: 'Cancel',
//     backButtonIcon: closeCircleOutline,
//     nextButtonText: 'Social',
//     nextButtonIcon: caretForwardOutline,
//   },
// ])
