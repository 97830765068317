import { IonIcon, IonLabel } from '@ionic/react'
import { copy } from 'ionicons/icons'
import React, { useState } from 'react'

export default function CopyText({ textToCopy }) {
  const [copied, setCopied] = useState(false)

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        setCopied(true)
        setTimeout(() => {
          setCopied(false)
        }, 2000) // Reset copied state after 2 seconds
      })
      .catch(err => {
        // Fallback for browsers that don't support clipboard API
        console.error('Could not copy text: ', err)
        // You can implement a fallback copy method here
      })
  }
  return (
    <>
      <>
        <IonLabel
          onClick={copyToClipboard}
          color={'primary'}
          style={{ cursor: 'pointer', margin: '10px' }}
        >
          Copy Invite Link
          {/* {registrationURL} */}
        </IonLabel>
        <IonIcon
          color='primary'
          title='Copy Link'
          icon={copy}
          style={{ cursor: 'pointer' }}
          onClick={copyToClipboard}
        />
      </>
      <IonLabel
        style={{
          marginLeft: '10px',

          opacity: copied ? 1 : 0,
          transition: copied ? 'opacity 1s ease-in-out' : 'opacity 0.5s ease-in-out',
          color: 'green',
        }}
      >
        Link Copied
      </IonLabel>
    </>
  )
}
