import {
  IonButton,
  IonButtons,
  IonCard,
  IonCol,
  IonContent,
  IonDatetime,
  IonHeader,
  IonLabel,
  IonModal,
  IonRow,
  IonTitle,
  IonToast,
  IonToolbar,
} from '@ionic/react'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { formatDateToISOString, getFormattedMediumDate } from '../../utils'
import MiniHeader from '../../commonComponents/MiniHeader'
import TimeSelector from './TimeSelector'
const dayjs = require('dayjs')

export default function EditAppointmentTime(props) {
  const { appointmentData, showCalendar, updateAppointmentData } = props

  const [isAppointmentChanged, setIsAppointmentChanged] = useState(false)
  const [appointmentTime, setAppointmentTime] = useState(false)
  const [slotAvailable, setSlotAvailable] = useState(true)
  const [startTime, setStartTime] = useState(false)
  const [endTime, setEndTime] = useState(false)
  const [toast, setToast] = useState({ isOpen: false, message: '', color: '' })

  const closeCalendar = () => {
    setAppointmentTime(false)
    props.closeCalendar()
  }

  const handleSelectedAppointment = async (value) => {
    setIsAppointmentChanged(true)
    setSlotAvailable(true)
    setAppointmentTime(value)
  }

  const checkAvailability = async (type, value) => {
    const date = getFormattedMediumDate(appointmentTime)
    const selectedStartTime = type === 'startTime' ? value : startTime
    const selectedEndTime = type === 'endTime' ? value : endTime
    const selectedStartDateTime = date + ' ' + selectedStartTime
    const selectedEndDateTime = date + ' ' + selectedEndTime
    const startTimeInEpoch = getTimeStampFromDate(selectedStartDateTime)
    const endTimeInEpoch = getTimeStampFromDate(selectedEndDateTime)
    const locationId = appointmentData.locationId ? appointmentData.locationId : appointmentData?.location?._id
    try {
      const response = await axios.get(
        `/appointment_v2/slotAvailability?locationId=${locationId}&customerId=${appointmentData?.customer?._id}&startTime=${startTimeInEpoch}&endTime=${endTimeInEpoch}`
      )

      if (response?.data) {
        setSlotAvailable(response.data.slotAvailable)
      } else {
        setSlotAvailable(true)
      }
    } catch (error) {
      console.error('Error checking slot availability:', error)
      // Handle error state here if needed (e.g., showing error messages in the UI)
    }
  }

  //function to update the end time
  const handleChangeStartTime = async (day, field, value) => {
    //check availability for the slot when ever the start time is changed

    setStartTime(value)
    await checkAvailability('startTime', value)
  }

  //function to update the end time
  const handleChangeEndTime = async (day, field, value) => {
    //check availability for the slot when ever the start time is changed
    setEndTime(value)
    await checkAvailability('endTime', value)
  }

  //funciton to get the formatted date based on selected date
  const getTimeStampFromDate = (formattedDate) => {
    const date = new Date(formattedDate)
    // Get the epoch time (milliseconds since January 1, 1970)
    const epochTime = date.getTime()
    return epochTime
  }

  //function to udpate the appointment time when ever the  update button is clickeed
  const updateAppointmentTime = async () => {
    let selectedDate = getFormattedMediumDate(appointmentTime)

    try {
      const response = await axios.patch(`/appointment_v2/changeTime?id=${appointmentData?._id}`, {
        startTimeText: startTime,
        endTimeText: endTime,
        selectedDate: selectedDate,
      })
      //if response is success then close the modal popup and go to appointment detail page and refresh the data
      if (response.status === 200) {
        updateAppointmentData()
        closeCalendar()
        setToast({ isOpen: true, message: 'Appointment time updated successfully!', color: 'success' })
      }
    } catch (error) {
      setToast({ isOpen: true, message: 'Error updating appointment time. Please try again.', color: 'danger' })
    }
  }

  useEffect(() => {
    const dateString = appointmentData?.selectedDate
    const dateObject = dayjs(dateString)
    setAppointmentTime(dateObject)
    setStartTime(appointmentData?.startTimeText)
    setEndTime(appointmentData?.endTimeText)
    handleSelectedAppointment(dateObject)
    setSlotAvailable(true)
    //
  }, [appointmentData?.selectedDate, showCalendar])
  return (
    <>
      <IonModal style={{ color: '#757575' }} isOpen={showCalendar} onDidDismiss={closeCalendar}>
        <IonHeader>
          <IonToolbar color='white' style={{ backgroundColor: 'white', color: 'black' }}>
            <IonTitle>Update Appointment Date & Time</IonTitle>
            <IonButtons slot='end'>
              <IonButton onClick={closeCalendar}>Close</IonButton>
              {isAppointmentChanged && (
                <IonButton color={'primary'} onClick={updateAppointmentTime}>
                  Update
                </IonButton>
              )}
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <div style={{ color: '#757575', overflowY: 'auto', paddingBottom: '2rem' }}>
            <h6 style={{ margin: '2rem 1rem' }}>Appointment Details</h6>
            <IonRow>
              <IonCard style={{ display: 'flex', width: '100%', margin: '0 1rem', padding: '0.5rem' }}>
                <IonCol>
                  <MiniHeader title='Date' />
                  <IonLabel>{appointmentData?.selectedDate}</IonLabel>
                </IonCol>
                <IonCol>
                  <MiniHeader title='Time' />
                  <IonLabel>{appointmentData?.startTimeText}</IonLabel>
                </IonCol>
                <IonCol size='auto'>
                  <MiniHeader title='Duration' />
                  <IonLabel>{appointmentData?.durationMin} min</IonLabel>
                </IonCol>
              </IonCard>
            </IonRow>
            {}
            {!slotAvailable && (
              <IonLabel
                style={{
                  fontSize: '14px',
                  color: 'orange',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: '1rem',
                }}
              >
                Warning : This Slot Is Not Available. You can still Proceed to update the schedule
              </IonLabel>
            )}

            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'row',
                width: '100%',
              }}
            >
              <IonRow>
                <div style={{ color: '#757575' }}>
                  <IonDatetime
                    mode='ios'
                    presentation='day'
                    value={formatDateToISOString(new Date(appointmentTime))}
                    onIonChange={(e) => {
                      handleSelectedAppointment(e.detail.value)
                    }}
                    style={{ color: 'black', background: 'white', minHeight: '20rem' }}
                  />{' '}
                </div>
              </IonRow>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-evenly',
                marginBottom: '1rem',
              }}
            >
              <IonLabel>Start Time</IonLabel>
              <IonLabel>End Time</IonLabel>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
              <TimeSelector value={startTime} handleChange={handleChangeStartTime}></TimeSelector>
              <TimeSelector value={endTime} handleChange={handleChangeEndTime}></TimeSelector>
            </div>
          </div>
        </IonContent>
      </IonModal>
      <IonToast
        position='top'
        isOpen={toast.isOpen}
        onDidDismiss={() => setToast({ ...toast, isOpen: false })}
        message={toast.message}
        color={toast.color}
        duration={2000}
      />
    </>
  )
}
