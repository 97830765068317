import React, { useState } from 'react'
import { IonButton, IonChip, IonGrid, IonIcon, IonLabel, IonRow, IonCol, IonText, IonToast } from '@ionic/react'
import { trashOutline } from 'ionicons/icons'
import axios from 'axios'
import SimpleDialog from './addNewSchedule'

export default function ModifiedSchedule({ location, standardSchedule, locationId, businessId }) {
  const [open, setOpen] = useState(false)
  const [modifiedSchedule, setModifiedSchedule] = useState([])
  const [toast, setToast] = useState({ isOpen: false, message: '', color: '' })

  const showToastMessage = (message, color = 'success') => {
    setToast({ isOpen: true, message, color })
  }

  const getModifiedSchedule = async () => {
    try {
      const response = await axios.get(`/location_v2/modifiedSchedule?locationId=${locationId}`)
      setModifiedSchedule(response.data)
    } catch (error) {
      console.error('Error fetching modified schedule:', error)
    }
  }

  React.useEffect(() => {
    getModifiedSchedule()
  }, [locationId])

  const handleSave = async (data) => {
    try {
      await axios.post(`/location_v2/modifiedSchedule`, { locationId, businessId, ...data })
      getModifiedSchedule()
      handleClose()
      showToastMessage('Modified schedule saved successfully!', 'success')
    } catch (error) {
      console.error('Error saving modified schedule:', error)
      showToastMessage('Sorry, there was an issue saving. Try again later.', 'danger')
    }
  }

  const handleDelete = async (id) => {
    try {
      if (window.confirm('Are you sure you want to delete the modified schedule?')) {
        await axios.delete(`/location_v2/modifiedSchedule?id=${id}`)
        getModifiedSchedule()
        showToastMessage('Modified schedule deleted successfully!', 'success')
      }
    } catch (error) {
      console.error('Error deleting modified schedule:', error)
      showToastMessage('Looks like there was an issue. Try again later.', 'danger')
    }
  }

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <IonGrid>
        <IonRow>
          <IonCol size='8'>
            <IonText>
              <h5>Modified Schedule</h5>
            </IonText>
          </IonCol>
          <IonCol size='4' className='ion-text-right'>
            <IonButton color='primary' onClick={handleClickOpen} fill='clear'>
              + Add
            </IonButton>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size='12'>
            <p style={{ marginBottom: 20, fontSize: 12 }}>Modify your future schedule by changing hours or closing on a day.</p>
            <IonGrid>
              <IonRow>
                <IonCol size='3'>
                  <strong>Date</strong>
                </IonCol>
                <IonCol size='3'>
                  <strong>Open</strong>
                </IonCol>
                <IonCol size='2'>
                  <strong>Open Time</strong>
                </IonCol>
                <IonCol size='2'>
                  <strong>Close Time</strong>
                </IonCol>
                <IonCol size='2'>
                  <strong>Delete</strong>
                </IonCol>
              </IonRow>
              {modifiedSchedule.map((d) => (
                <IonRow key={d._id}>
                  <IonCol size='3'>{d.dateText}</IonCol>
                  <IonCol size='3'>
                    <IonChip color={d.enabled ? 'primary' : 'medium'}>
                      <IonLabel>{d.enabled ? 'Open' : 'Closed'}</IonLabel>
                    </IonChip>
                  </IonCol>
                  <IonCol size='2'>{d.enabled ? d.startTime : '-'}</IonCol>
                  <IonCol size='2'>{d.enabled ? d.endTime : '-'}</IonCol>
                  <IonCol size='2'>
                    <IonButton fill='clear' color='danger' onClick={() => handleDelete(d._id)}>
                      <IonIcon icon={trashOutline} />
                    </IonButton>
                  </IonCol>
                </IonRow>
              ))}
            </IonGrid>
          </IonCol>
        </IonRow>
      </IonGrid>
      <IonToast
        position='top'
        isOpen={toast.isOpen}
        onDidDismiss={() => setToast({ ...toast, isOpen: false })}
        message={toast.message}
        color={toast.color}
        duration={2000}
      />
      <SimpleDialog
        showToastMessage={showToastMessage}
        modifiedSchedule={modifiedSchedule}
        location={location}
        open={open}
        onClose={handleClose}
        standardSchedule={standardSchedule}
        handleSave={handleSave}
      />
    </>
  )
}
