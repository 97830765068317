import React, { useState } from 'react'
import { IonButton, IonIcon, IonPopover, IonContent, IonList, IonItem, IonLabel, IonText } from '@ionic/react'
import { ellipsisVerticalOutline } from 'ionicons/icons'
import axios from 'axios'
import { convertDataToCSV } from '../utils'

const styles = {
  exportToCSVTitleStyle: {
    fontSize: '0.8rem',
    fontWeight: 'bold',
    color: 'grey',
  },
  moreIconStyle: {
    height: 22,
    width: 22,
    color: 'grey',
  },
}

export default function ExportCustomersToCSV({ updateErrorMessage, title }) {
  const [showPopover, setShowPopover] = useState(false)

  const handleExportData = async () => {
    if (title === 'Customers') {
      await getCustomersList()
    }
  }

  const getCustomersList = async () => {
    try {
      let response = await axios.get(`/customer_v2/export`)

      const formattedCustomerData =
        response.data &&
        response.data.map((item) => {
          return {
            firstName: item.firstName ? item.firstName : '-',
            lastName: item.lastName ? item.lastName : '-',
            email: item.email ? item.email : '-',
            phoneNumber: item.phoneNumber ? item.phoneNumber : '-',
          }
        })
      const csvContent = 'data:text/csv;charset=utf-8,' + convertDataToCSV(formattedCustomerData)
      const encodedUri = encodeURI(csvContent)
      const link = document.createElement('a')
      link.setAttribute('href', encodedUri)
      link.setAttribute('download', 'customersList.csv')
      document.body.appendChild(link)
      link.click()
      setShowPopover(false)
    } catch (error) {
      console.error('Error exporting customer list:', error) // Log error for debugging

      updateErrorMessage("You Don't Have Enough Permissions To Export This Data")
    }
  }

return null

  return (
    <>
      <IonButton id='basic-button' onClick={() => setShowPopover(true)}>
        <IonIcon icon={ellipsisVerticalOutline} />
      </IonButton>
      <IonPopover isOpen={showPopover} onDidDismiss={() => setShowPopover(false)}>
        <IonContent>
          <IonList>
            <IonItem button onClick={handleExportData}>
              <IonLabel>
                <IonText style={styles.exportToCSVTitleStyle}>Export TO CSV</IonText>
              </IonLabel>
            </IonItem>
          </IonList>
        </IonContent>
      </IonPopover>
    </>
  )
}
