import React, { useState } from 'react'
import {
  IonButton,
  IonInput,
  IonItem,
  IonLabel,
  IonModal,
  IonText,
  IonTextarea,
  IonToolbar,
  IonButtons,
  IonTitle,
  IonHeader,
  IonContent,
  IonToast,
} from '@ionic/react'
import axios from 'axios'
import { useHistory } from 'react-router-dom'

export default function CreateTestDialog({ open, handleClose, businessId, locationId }) {
  const [isDisabled, setIsDisabled] = useState(false)
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [price, setPrice] = useState('')
  const history = useHistory()

  // Toast state management
  const [toast, setToast] = useState({ isOpen: false, message: '', color: '' })

  const showToastMessage = (message, color = 'danger') => {
    setToast({ isOpen: true, message, color })
  }

  const createButtonClicked = async () => {
    setIsDisabled(true)
    let locData = locationId ? { locations: [locationId] } : {}
    try {
      let response = await axios.post(`/product`, { name, description, businessId, price, ...locData })

      if (response.status === 200) {
        history.push(`products/${response.data._id}/details`)
        close()
        showToastMessage('Product created successfully!', 'success') // Success toast
      }
    } catch (error) {
      showToastMessage('Failed to create product. Please try again.', 'danger') // Error toast
      setIsDisabled(false)
    }
  }

  const close = () => {
    setName('')
    setDescription('')
    setPrice('')
    setIsDisabled(false)
    handleClose()
  }

  return (
    <>
      <IonModal isOpen={open} onDidDismiss={handleClose}>
        <IonHeader>
          <IonToolbar>
            <IonTitle>Add Product</IonTitle>
            <IonButtons slot='end'>
              <IonButton onClick={close}>Cancel</IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonItem>
            <IonLabel position='stacked'>Name</IonLabel>
            <IonInput value={name} onIonInput={(e) => setName(e.detail.value)} placeholder='Enter product name' />
          </IonItem>
          <IonItem>
            <IonLabel position='stacked'>Description</IonLabel>
            <IonTextarea value={description} onIonChange={(e) => setDescription(e.detail.value)} placeholder='Enter product description' />
          </IonItem>
          <IonItem>
            <IonLabel position='stacked'>Price</IonLabel>
            <IonInput value={price} onIonInput={(e) => setPrice(e.detail.value)} placeholder='Enter product price' type='number' />
          </IonItem>
          <IonButton expand='full' onClick={createButtonClicked} disabled={isDisabled} color='primary'>
            Create
          </IonButton>
        </IonContent>
      </IonModal>
      <IonToast
        position='top'
        isOpen={toast.isOpen}
        onDidDismiss={() => setToast({ ...toast, isOpen: false })}
        message={toast.message}
        color={toast.color}
        duration={2000}
      />
    </>
  )
}
