import React, { useContext, useEffect, useRef, useState } from 'react'
import { IonRow, IonCol, IonSpinner, IonLabel, IonImg, IonToast } from '@ionic/react'
import axios from 'axios'
import { AuthContext } from '../auth'

const UploadLogoBusinessImage = () => {
  const { businessData, locationId } = useContext(AuthContext)
  const [businessImagePreview, setBusinessImagePreview] = useState('')
  const [loadingBusinessImage, setLoadingBusinessImage] = useState(false)
  const businessImageInputRef = useRef(null)
  const [loadingData, setLoadingData] = useState(true)
  const [logoImagePreview, setLogoImagePreview] = useState('')
  const [loadingLogoImage, setLoadingLogoImage] = useState(false)
  const logoImageInputRef = useRef(null)

  // Toast state
  const [toast, setToast] = useState({
    isOpen: false,
    message: '',
    color: 'success',
  })

  const showToastMessage = (message, color = 'success') => {
    setToast({ isOpen: true, message, color })
  }

  const handleBusinessImageUpload = async (event) => {
    setLoadingBusinessImage(true)
    const file = event.target.files[0]
    const reader = new FileReader()

    reader.onloadend = async () => {
      setBusinessImagePreview(reader.result)
      const data = new FormData()
      data.append('selectedFile', file)
      data.append('imageType', 'Image')
      try {
        const response = await axios.post('/emailRemarketing/templateImage', data)
        if (response?.data?.success === 'success') {
          setLoadingBusinessImage(false)
          showToastMessage('Business image uploaded successfully!')
        } else {
          showToastMessage('Failed to upload business image.', 'danger')
        }
      } catch (error) {
        showToastMessage('Error uploading business image.', 'danger')
      } finally {
        setLoadingBusinessImage(false)
      }
    }

    if (file) {
      reader.readAsDataURL(file)
    }
  }

  const handleLogoImageUpload = async (event) => {
    setLoadingLogoImage(true)
    const file = event.target.files[0]
    const reader = new FileReader()

    reader.onloadend = async () => {
      setLogoImagePreview(reader.result)
      const data = new FormData()
      data.append('selectedFile', file)
      data.append('emailRemarketingId', '65798bb27bd77798f9d00f5c')
      data.append('imageType', 'Logo')
      try {
        const response = await axios.post('/emailRemarketing/templateImage', data)
        if (response?.data?.success === 'success') {
          setLoadingLogoImage(false)
          showToastMessage('Logo uploaded successfully!')
        } else {
          showToastMessage('Failed to upload logo.', 'danger')
        }
      } catch (error) {
        showToastMessage('Error uploading logo.', 'danger')
      } finally {
        setLoadingLogoImage(false)
      }
    }

    if (file) {
      reader.readAsDataURL(file)
    }
  }

  const handleBusinessImageClick = () => {
    if (businessImageInputRef.current) {
      // Create a new mouse click event
      const clickEvent = new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window,
      })
      // Dispatch the click event to the input element
      businessImageInputRef.current.dispatchEvent(clickEvent)
    }
  }

  const handleLogoImageClick = () => {
    if (logoImageInputRef.current) {
      // Create a new mouse click event
      const clickEvent = new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window,
      })
      // Dispatch the click event to the input element
      logoImageInputRef.current.dispatchEvent(clickEvent)
    }
  }

  const getLogoImage = async () => {
    setLoadingLogoImage(true)
    try {
      const logoResponse = await axios.get('/emailRemarketing/templateImage?imageType=Logo')
      if (logoResponse.status === 200) {
        setLogoImagePreview(logoResponse.data)
      }
    } catch (error) {
      console.error('Error fetching logo image:', error) // Log the error for debugging
    } finally {
      setLoadingLogoImage(false)
    }
  }

  const getBusinessImage = async () => {
    setLoadingBusinessImage(true)
    try {
      const businessImageResponse = await axios.get('/emailRemarketing/templateImage?imageType=Image')
      if (businessImageResponse.status == 200) {
        setLoadingBusinessImage(false)
        setLoadingData(false)
        setBusinessImagePreview(businessImageResponse.data)
      }
    } catch (error) {
      console.error('Error fetching business image:', error) // Log the error for debugging
    } finally {
      setLoadingBusinessImage(false)
    }
  }

  useEffect(() => {
    if (locationId && locationId !== 'loading') {
      getLogoImage()
      getBusinessImage()
    }
  }, [businessData, locationId])
  const labelRowStyle = {
    minHeight: '2rem',
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'start',
  }
  const labelStyle = {
    cursor: 'pointer',
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: '600',
  }
  const previewRowStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '9rem',
    padding: '10px',
    width: '100%',
    backgroundColor: '#c5c5c5',
    color: 'black',
    borderRadius: '5px',
    cursor: 'pointer',
  }

  const spinnerStyle = {
    position: 'absolute',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
  }
  const imageStyle = { width: '100%', height: '100%', objectFit: 'contain' }
  return (
    <IonRow style={{ display: 'flex', justifyContent: 'space-evenly' }}>
      {loadingData && <IonSpinner name='dots' />}
      {!loadingData && (
        <>
          <IonCol size='6' style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
            <IonRow style={labelRowStyle}>
              <IonLabel style={labelStyle} htmlFor='business-image-upload'>
                Business Image
              </IonLabel>
            </IonRow>
            <IonRow style={{ width: '100%' }}>
              <input
                id='business-image-upload'
                type='file'
                style={{ display: 'none' }}
                accept='image/*'
                onChange={handleBusinessImageUpload}
                ref={businessImageInputRef}
              />
              <div onClick={handleBusinessImageClick} style={previewRowStyle}>
                {loadingBusinessImage && <IonSpinner style={spinnerStyle} name='lines' />}
                {!loadingBusinessImage && businessImagePreview && <IonImg src={businessImagePreview} alt='Business Preview' style={imageStyle} />}
              </div>
            </IonRow>
          </IonCol>

          <IonCol size='6' style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
            <IonRow style={labelRowStyle}>
              <IonLabel style={labelStyle} htmlFor='logo-image-upload'>
                Logo
              </IonLabel>
            </IonRow>
            <IonRow style={{ width: '100%' }}>
              <input id='logo-image-upload' type='file' style={{ display: 'none' }} accept='image/*' onChange={handleLogoImageUpload} ref={logoImageInputRef} />
              <div onClick={handleLogoImageClick} style={previewRowStyle}>
                {loadingLogoImage && <IonSpinner style={spinnerStyle} name='lines' />}
                {!loadingLogoImage && logoImagePreview && <IonImg src={logoImagePreview} alt='Logo Preview' style={imageStyle} />}
              </div>
            </IonRow>
          </IonCol>
        </>
      )}

      <IonToast
        position='top'
        isOpen={toast.isOpen}
        onDidDismiss={() => setToast({ ...toast, isOpen: false })}
        message={toast.message}
        color={toast.color}
        duration={2000}
      />
    </IonRow>
  )
}

export default UploadLogoBusinessImage
