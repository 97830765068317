import { IonActionSheet } from '@ionic/react'
import { cameraOutline, imageOutline } from 'ionicons/icons'
import { useState, useRef } from 'react'
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera'
import PictureDetail from './PictureDetail'

export default function CameraAndGalleryOptions({
  customer_id,
  modalOpen,
  closeModal,
  services,
  addingImage,
  handleAddImageToGallery,
  refreshPicturesList,
}) {
  // State to store the selected image data
  const [selectedImage, setSelectedImage] = useState(null)

  // State to manage the detail modal visibility
  const [detailModalOpen, setDetailModalOpen] = useState(false)

  // Reference to the hidden file input for gallery selection
  const fileInputRef = useRef(null)

  // Function to open the detail modal
  const openDetailModal = () => {
    setDetailModalOpen(true)
  }

  // Function to close the detail modal
  const closeDetailModal = () => {
    setDetailModalOpen(false)
  }

  // Function to handle the selected image and open the detail modal
  const handleSelectedImage = image => {
    openDetailModal()
    const selectedImageData = {
      data: image,
      createdDate: Date.now(),
      contentType: 'image/png',
      name: '',
      socialMediaRating: '1',
    }

    setSelectedImage(selectedImageData)
  }

  // Function to take a photo using the device camera
  const takePhoto = async () => {
    try {
      const photo = await Camera.getPhoto({
        resultType: CameraResultType.DataUrl,
        source: CameraSource.Camera,
      })
      handleSelectedImage(photo.dataUrl)
    } catch (error) {
      console.error('Error taking photo:', error)
    }

    closeModal()
  }

  // Function to trigger the file input for gallery selection
  const selectFromGallery = () => {
    // Trigger click on the hidden file input to open the gallery picker
    fileInputRef.current.click()
  }

  // Function to handle file selection from the gallery
  const handleFileSelection = async event => {
    const fileInput = event.target

    try {
      // Check if a file is selected
      if (fileInput.files.length > 0) {
        const selectedFile = fileInput.files[0]

        // Convert the selected file to a data URL or handle it as needed
        const dataUrl = await fileToDataUrl(selectedFile)
        handleSelectedImage(dataUrl)
      } else {
        console.warn('No file selected.')
      }
    } catch (error) {
      console.error('Error handling file selection:', error)
    }

    // Reset the file input
    fileInput.value = ''

    closeModal()
  }

  // Function to convert a file to a data URL
  const fileToDataUrl = file => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()

      reader.onload = event => {
        resolve(event.target.result)
      }

      reader.onerror = error => {
        reject(error)
      }

      reader.readAsDataURL(file)
    })
  }

  // Function to handle cancel action
  const cancel = () => {
    closeModal()
  }

  return (
    <>
      {/* IonActionSheet for camera and gallery options */}
      <IonActionSheet
        onDidDismiss={cancel}
        isOpen={modalOpen}
        buttons={[
          {
            text: 'Take Photo',
            icon: cameraOutline,
            handler: takePhoto,
          },
          {
            text: 'Photo Library',
            icon: imageOutline,
            handler: selectFromGallery,
          },
          {
            text: 'Cancel',
            role: 'cancel',
            icon: '',
            handler: cancel,
          },
        ]}
      ></IonActionSheet>
      {/* Hidden file input for gallery selection */}
      <input
        ref={fileInputRef}
        type='file'
        accept='image/*'
        style={{ display: 'none' }}
        onChange={handleFileSelection}
      />
      {/* PictureDetail component for displaying details of a selected image */}
      <PictureDetail
        refreshPicturesList={refreshPicturesList}
        addingImage={addingImage}
        newImage={true}
        handleAddImageToGallery={handleAddImageToGallery}
        services={services}
        closeDetailModal={closeDetailModal}
        detailModalOpen={detailModalOpen}
        selectedImage={selectedImage}
      />
    </>
  )
}
