import axios from 'axios'
import { useContext, useState } from 'react'
import CustomInput from '../../../SetupBusiness/CustomInput'
import { AuthContext } from '../../../auth'
import ButtonPanel from '../buttonPanel'
import FlowError from '../error'
import { IonToast } from '@ionic/react'

const PersonalForm = ({ data, flowIndex, onBack, onNext, onCancel, history }) => {
  const { userData } = useContext(AuthContext)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [error, setError] = useState('')
  const [toast, setToast] = useState({ isOpen: false, message: '', color: 'success' })
  const showToastMessage = (message, color) => {
    setToast({ isOpen: true, message, color })
  }
  const handleFirstName = (value) => setFirstName(value)
  const handleLastName = (value) => setLastName(value)

  const handleNext = async () => {
    try {
      await axios.patch(`/user`, { email: userData.email, firstName, lastName })
      setError('')
      showToastMessage('Personal information updated successfully!', 'success')
      onNext()
    } catch (error) {
      const errorMessage = error?.response?.data?.error || 'An unexpected error occurred.'
      setError(errorMessage)
      showToastMessage(errorMessage, 'danger')
      console.log('ERROR', error)
    }
  }

  return (
    <>
      <IonToast
        position='top'
        isOpen={toast.isOpen}
        message={toast.message}
        duration={2000}
        color={toast.color}
        onDidDismiss={() => setToast({ ...toast, isOpen: false })}
      />
      <p>Let's start out with getting to know you.</p>
      <CustomInput label={'What is your first name? *'} value={firstName} placeholder='first name' handleInput={handleFirstName} />
      <CustomInput label={'What is your last name? *'} value={lastName} placeholder='last name' handleInput={handleLastName} />
      <FlowError message={error} />
      <ButtonPanel data={data} flowIndex={flowIndex} onBack={onBack} onNext={handleNext} onCancel={onCancel} history={history} />
    </>
  )
}

export default PersonalForm
