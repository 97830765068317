import { useState } from 'react'
import axios from 'axios'
import { useHistory } from 'react-router-dom'
import { IonButton, IonContent, IonHeader, IonModal, IonTitle, IonToast, IonToolbar } from '@ionic/react'
import DetailsView from './details_general'
import StandardContainer from '../commonComponents/StandardContainer'

let initialState = {
  name: '',
  discountType: 'percentage',
  couponType: 'subscription',
}

export default function CreateTestDialog({ open, handleClose, businessId, locationId }) {
  const [discount, setDiscount] = useState(initialState)
  const history = useHistory()
  const [error, setError] = useState('')
  const [nameError, setNameError] = useState('')
  const [toast, setToast] = useState({ isOpen: false, message: '', color: '' })

  const handleNameError = (error) => {
    setNameError(error)
  }
  const handleDiscount = (value) => {
    setDiscount(value)
    if (value.discountType === 'percentage' && value.discountValue > 100) {
      setError('Percentage discount value cannot be more than 100')
    } else if (value.discountType === 'percentage' && value.discountValue < 0) {
      setError('Percentage discount value cannot be less than 0')
    } else {
      setError('')
    }
  }
  const updateError = (error) => {
    setError(error)
  }
  const createButtonClicked = async () => {
    const locData = locationId
    try {
      const response = await axios.post(`/discount`, { ...discount, locationId })
      if (response.status === 200) {
        setToast({ isOpen: true, message: 'Discount created successfully!', color: 'success' })
        setTimeout(() => {
          close()
        }, 1000)
      } else {
        setToast({ isOpen: true, message: 'Failed to create discount', color: 'danger' })
      }
    } catch (error) {
      setToast({ isOpen: true, message: 'Error creating discount', color: 'danger' })
    }
  }

  const close = () => {
    setDiscount(initialState)
    handleClose()
  }
  const isDisabled = !discount.name || !discount.discountType || !discount.couponType || !discount.discountValue || error != '' || nameError != ''

  return (
    <IonModal onDidDismiss={close} isOpen={open}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Add Discount</IonTitle>
        </IonToolbar>
      </IonHeader>
      {}
      <IonContent className='regularBackground'>
        <StandardContainer>
          <DetailsView
            createMode={true}
            handleNameError={handleNameError}
            discount={discount}
            error={error}
            nameError={nameError}
            updateDiscount={handleDiscount}
          />
          <div style={{ padding: 14 }}>
            <IonButton expand='block' onClick={close} fill='outline' color='dark'>
              Cancel
            </IonButton>
            <IonButton expand='block' onClick={createButtonClicked} disabled={isDisabled} color='dark'>
              Create
            </IonButton>
          </div>
        </StandardContainer>
        <IonToast
          position='top'
          isOpen={toast.isOpen}
          onDidDismiss={() => setToast({ isOpen: false, message: '', color: '' })}
          message={toast.message}
          color={toast.color}
          duration={2000}
        />
      </IonContent>
    </IonModal>
  )
}
