import { IonRouterLink, IonText } from '@ionic/react'

export default function LayoutTextFields() {
  return (
    <IonText color='medium' className='ion-text-center'>
      {'Copyright © '}
      <IonRouterLink color='primary' href='/'>
        Club Cuts, LLC
      </IonRouterLink>{' '}
      {new Date().getFullYear()}
      {'.'}
    </IonText>
  )
}
