export function calculatePayment(services = [], products = [], discounts = [], subscriptionPackageId, tipAmount = 0, tipId, location, cashTipAmount = 0) {
  let payment = {
    tipAmount,
    tipId,
    taxes: 0,
    productsTaxRate: location?.purchase?.productsTaxRate || 0,
    servicesTaxRate: location?.purchase?.servicesTaxRate || 0,
    productsTotal: 0,
    servicesTotal: 0,
    otherDiscounts: 0,
    subscriptionDiscount: 0,
    subscriptionDiscountForProducts: 0,
    discountsTotal: 0,
    totalPrice: 0,
    cashTipAmount: 0,
    discounts: [],
  }

  console.log('location', location)

  //Services Total & Subscription Discounts
  for (let s of services) {
    payment.servicesTotal += parseFloat(s.price)
    if (subscriptionPackageId && s?.includedInSubscriptions?.some((subscription) => subscription._id === subscriptionPackageId)) {
      payment.discounts.push({
        name: 'Subscriber Discount - ' + s.name,
        amount: parseFloat(s.price),
      })
      payment.subscriptionDiscount += parseFloat(s.price)
    }
  }

  //Products
  for (let p of products) {
    let price = parseFloat(p.price)
    payment.productsTotal += price

    if (subscriptionPackageId) {
      let discount = price * 0.15
      payment.discounts.push({ name: 'Subscriber Discount (15%) - ' + p.name, amount: discount })
      payment.subscriptionDiscount += discount
      payment.subscriptionDiscountForProducts += discount
      // payment.taxes += (price - discount) * taxRate
    }
    // else {
    //     payment.taxes += (price * taxRate)
    // }
  }

  //Discounts
  for (let d of discounts) {
    if (d.discountType == 'fixed') {
      let valueLeft = valueOfServicesAndPaymentsAndTax(payment)
      let totalOff = parseFloat(d.discountValue) || 0
      if (valueLeft < (parseFloat(d.discountValue) || 0)) {
        totalOff = valueLeft
      }
      payment.discounts.push({ name: d.name, amount: totalOff })
      payment.otherDiscounts += totalOff
    } else if (d.discountType == 'percentage') {
      let totalOff = valueOfServicesAndPayments(payment) * ((parseFloat(d.discountValue) || 0) / 100)
      payment.discounts.push({
        name: `${d.name} (${d.discountValue}%)`,
        amount: parseFloat(totalOff.toFixed(2)),
      })
      payment.otherDiscounts += parseFloat(totalOff.toFixed(2))
    }
  }

  // //Calculate Taxes for Products
  // //If there is a tax rate for products, then calculate taxes for products
  // if (location?.productsTaxRate && location?.productsTaxRate !== 0) {
  //   //If the total price of products is less than the total discount for products, then taxes are 0
  //   if (payment.productsTotal <= payment.subscriptionDiscountForProducts + payment.otherDiscounts) {
  //     payment.taxes = 0
  //   } else {
  //     let priceToBeTaxed = payment.productsTotal - (payment.subscriptionDiscountForProducts + payment.otherDiscounts)
  //     priceToBeTaxed = priceToBeTaxed < 0 ? 0 : priceToBeTaxed
  //     payment.taxes = priceToBeTaxed * (location?.productsTaxRate || 0)
  //   }
  // }

  payment.taxes = calculateTaxes(payment)



  payment.cashTipAmount = cashTipAmount

  payment.discountsTotal = payment.subscriptionDiscount + payment.otherDiscounts

  payment.totalPrice = calculateTotalPrice(payment)

  if (cashTipAmount) {
    payment.totalPrice = payment.totalPrice + cashTipAmount
  }

  return payment
}

function calculateTotalPrice(payment) {
  let total = payment.servicesTotal + payment.tipAmount + payment.taxes + payment.productsTotal - payment.discountsTotal

  return total < 0 ? 0 : total
}

function valueOfServicesAndPayments(payment) {
  return payment.servicesTotal + payment.productsTotal - payment.subscriptionDiscount
}

function valueOfServicesAndPaymentsAndTax(payment) {
  return payment.servicesTotal + payment.taxes + payment.productsTotal - payment.subscriptionDiscount
}

function calculateTaxes(payment) {
  // Destructure the input payment object for easier access
  const {
    productsTaxRate,
    servicesTaxRate,
    productsTotal,
    servicesTotal,
    otherDiscounts,
    subscriptionDiscount,
    subscriptionDiscountForProducts
  } = payment;

  // 1. Calculate the proportional split of `otherDiscounts` between products and services
  const totalBeforeDiscounts = productsTotal + servicesTotal;
  let productsOtherDiscount = 0;
  let servicesOtherDiscount = 0;

  if (totalBeforeDiscounts > 0) {
    productsOtherDiscount = (productsTotal / totalBeforeDiscounts) * otherDiscounts;
    servicesOtherDiscount = otherDiscounts - productsOtherDiscount; // Remaining discount goes to services
  }

  // 2. Adjust products and services totals after applying all relevant discounts
  const adjustedProductsTotal = Math.max(0, productsTotal - subscriptionDiscountForProducts - productsOtherDiscount);
  const adjustedServicesTotal = Math.max(0, servicesTotal - subscriptionDiscount - servicesOtherDiscount);

  // 3. Calculate the taxes for both products and services
  const productsTaxes = adjustedProductsTotal * (productsTaxRate );
  const servicesTaxes = adjustedServicesTotal * (servicesTaxRate );

  // 4. Total taxes
  const totalTaxes = productsTaxes + servicesTaxes;

  // Return the calculated total taxes
  console.log('totalTaxes', totalTaxes)
  console.log('productsTaxes', productsTaxRate)
  console.log('servicesTaxes', servicesTaxRate)
  return Math.max(0, totalTaxes);
}