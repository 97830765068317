import { IonButton, IonContent, IonIcon, IonModal, IonRow, IonSpinner } from '@ionic/react'
import CommonHeader from '../../../commonComponents/CommonHeader'
import StandardContainer from '../../../commonComponents/StandardContainer'
import ServicesProductsSummary from './ServicesProductsSummary'
import { handRightOutline } from 'ionicons/icons'
import styles from '../Styles/ExternalPaymentStyles'

export default function ExternalPayment({ payExternalModalOpen, payment, setPayExternalModalOpen, updating, appointment, payedExternal }) {
  return (
    <IonModal
      isOpen={payExternalModalOpen}
      onDidDismiss={() => setPayExternalModalOpen(false)}
      trigger='open-modal'
      initialBreakpoint={1}
      breakpoints={[1]}
      cssClass='custom-modal'
    >
      <IonContent>
        <CommonHeader closeButton={true} closeModal={() => setPayExternalModalOpen(false)} title={'Checkout'} />

        <StandardContainer>
          <div style={styles.modalContent}>
            <div style={styles.headerContainer}>
              <div style={styles.headerContent}>
                <div style={styles.totalLabelContainer}>
                  <div style={styles.totalLabel}>
                    <div style={styles.totalLabelText}>YOUR TOTAL</div>
                  </div>
                </div>
              </div>
              <div style={styles.amountContainer}>
                <div style={styles.amountText}>$23.35</div>
              </div>
            </div>
            <div style={styles.summaryContainer}>
              <div style={styles.summaryCard}>
                <ServicesProductsSummary hideTotal={true} appointment={appointment} />
              </div>
            </div>
            <div style={styles.tipContainer}>
              <div style={styles.tipContent}>
                <div style={styles.tipIcon}>
                  <IonIcon style={styles.tipIcon} icon={handRightOutline} />{' '}
                </div>
                <div style={styles.tipText}>Tips are always appreciated if you enjoyed your visit today.</div>
              </div>
            </div>
          </div>
        </StandardContainer>
        <IonRow style={styles.buttonContainer}>
          <IonButton disabled={updating} onClick={payedExternal} style={styles.button} shape='round'>
            {updating && <IonSpinner name='dots' style={{ marginRight: '1rem' }} />}
            Mark as paid external
          </IonButton>
        </IonRow>
      </IonContent>
    </IonModal>
  )
}
