import { IonIcon } from '@ionic/react'
import Avatar from '../../../commonComponents/avatar'
import { personOutline } from 'ionicons/icons'
import { styles } from '../Styles/ProfessionalInfoStyles'

export default function ProfessionalInfo({ mode, setStep, appointment, businessData, setEmployeeModalOpen }) {
  const ProfessionalLabel = ({ appointment }) => {
    const label =
      appointment.type === 'Walkin'
        ? ''
        : appointment.anyProfessional
        ? 'Any Professional Requested'
        : appointment.requestedBarber?._id === appointment.barber?._id
        ? 'Requested'
        : appointment.requestedBarber?._id
        ? `Requested ${appointment.requestedBarber.firstName} ${appointment.requestedBarber.lastName}`
        : ''

    return (
      label && (
        <div style={styles.labelContainerStyle}>
          <div style={styles.labelTextStyle}>{label}</div>{' '}
        </div>
      )
    )
  }

  return (
    <div style={styles.containerStyle}>
      <div style={styles.flexContainerStyle}>
        <div style={styles.avatarContainerStyle}>
          {appointment?.barber ? <Avatar small employee={appointment.barber} businessId={businessData._id} /> : <IonIcon icon={personOutline} />}
        </div>
        <div style={styles.nameContainerStyle}>
          <div style={styles.flexContainerStyle}>
            <div style={styles.nameStyle}>
              {appointment?.barber ? (
                <>
                  {appointment.barber.firstName} {appointment.barber.lastName}
                </>
              ) : (
                <>Any Stylist</>
              )}
            </div>
          </div>
          {['Complete'].includes(appointment?.status) ? null : <ProfessionalLabel appointment={appointment} />}
        </div>
        {['Complete'].includes(appointment.status) ? null : (
          <div onClick={() => (mode == 'create' ? setStep('barber') : setEmployeeModalOpen(true))} style={styles.changeButtonStyle}>
            <div style={styles.changeButtonTextStyle}>Change</div>
          </div>
        )}
      </div>
    </div>
  )
}
