import React, { useState, useEffect, useContext } from 'react';
import { IonAvatar, IonButton, IonCard, IonCol, IonContent, IonGrid, IonIcon, IonItem, IonLabel, IonPage, IonRow, IonSpinner, IonTitle } from '@ionic/react';

import Avatar from '../../commonComponents/avatar';
import { createOutline, pencil, pencilOutline, pencilSharp } from 'ionicons/icons';

import MiniHeader from '../../commonComponents/MiniHeader';
import { AccessControl } from '../../AccessControl';


export default function ProfessionalCard({ appointment, handleEmployeeChange, businessData }) {

    return (
        <IonCard style={{ color: 'black', padding: 18 }}>
            <MiniHeader title="Professional" />

            <IonGrid style={{ padding: 0, marginBottom: -10, marginTop: -8, marginLeft: -6 }}>
                <IonRow >
                    <IonCol size='auto' style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                        <Avatar small employee={appointment.barber} businessId={businessData._id} />
                    </IonCol>
                    <IonCol style={{ balignItems: 'center', display: 'flex', alignItems: 'center', display: 'flex' }}>
                        <h4 style={{ margin: 0 }}>{appointment?.barber?.firstName} {appointment?.barber?.lastName}</h4>
                    </IonCol>
                    {['Complete'].includes(appointment?.status) ?
                        <AccessControl componentName="change_employee_post_complete">
                            <IonCol size="auto">
                                <IonButton onClick={handleEmployeeChange} fill='clear'><IonIcon icon={createOutline} /></IonButton>
                            </IonCol>
                        </AccessControl>
                        :
                        <IonCol size="auto">
                            <IonButton onClick={handleEmployeeChange} fill='clear'><IonIcon icon={createOutline} /></IonButton>
                        </IonCol>
                    }
                </IonRow>
                <IonRow>
                    <IonCol style={{ marginTop: -10 }}>
                        {appointment.type === 'Walkin' ? null :
                            appointment.anyProfessional ? 'Any Professional Requested' :
                                appointment.requestedBarber?._id === appointment.barber?._id ? (
                                    <>
                                        {"★ Requested"}
                                    </>
                                ) : appointment.requestedBarber?._id && (
                                    <>
                                        {`Requested ${appointment.requestedBarber.firstName} ${appointment.requestedBarber.lastName}`}
                                    </>
                                )
                        }

                    </IonCol>

                </IonRow>
                {!appointment?.barber?._id ?
                    <IonRow>
                        <IonCol>
                            <div style={{ backgroundColor: 'rgb(255, 137, 44, 0.3)', padding: '10px', borderRadius: '5px' }}>
                                <p style={{ margin: 0, color: 'black', fontWeight: 'bold' }}>
                                    Warning: You must add a professional before you can start the appointment
                                </p>
                            </div>
                        </IonCol>
                    </IonRow>
                    : null
                }

            </IonGrid>
        </IonCard>
    );
}

