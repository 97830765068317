// externalPaymentStyles.js

import { colors } from '../../../theme/colors'

const styles = {
  modalContent: {
    width: '100%',
    height: '100%',
    paddingLeft: 24,
    paddingRight: 24,
    background: 'white',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 24,
    display: 'inline-flex',
  },

  headerContainer: {
    marginTop: 24,
    alignSelf: 'stretch',
    height: 95,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 5,
    display: 'flex',
  },

  headerContent: {
    alignSelf: 'stretch',
    paddingLeft: 24,
    paddingRight: 24,
    justifyContent: 'center',
    alignItems: 'center',
    gap: 36,
    display: 'inline-flex',
  },

  totalLabelContainer: {
    flex: '1 1 0',
    height: 18,
    justifyContent: 'center',
    alignItems: 'center',
    gap: 36,
    display: 'flex',
  },

  totalLabel: {
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: 10,
    display: 'flex',
  },

  totalLabelText: {
    color: '#222222',
    fontSize: 14,
    fontFamily: 'Figtree',
    fontWeight: '700',
    textTransform: 'uppercase',
    lineHeight: '18px',
    letterSpacing: 1.4,
    wordWrap: 'break-word',
  },

  amountContainer: {
    alignSelf: 'stretch',
    height: 72,
    paddingLeft: 24,
    paddingRight: 24,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 16,
    display: 'flex',
  },

  amountText: {
    textAlign: 'center',
    color: '#222222',
    fontSize: 60,
    fontFamily: 'Figtree',
    fontWeight: '500',
    lineHeight: '72px',
    wordWrap: 'break-word',
  },

  summaryContainer: {
    alignSelf: 'stretch',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 16,
    display: 'flex',
  },

  summaryCard: {
    alignSelf: 'stretch',
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 12,
    paddingBottom: 12,
    background: '#F5F5F6',
    borderRadius: 12,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: 8,
    display: 'flex',
  },

  tipContainer: {
    alignSelf: 'stretch',
    height: 72,
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 12,
    paddingBottom: 12,
    borderRadius: 12,
    border: '1px #B0B0B0 solid',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: 8,
    display: 'flex',
  },

  tipContent: {
    width: 316,
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: 8,
    display: 'inline-flex',
  },

  tipIcon: {
    width: 24,
    height: 24,
    position: 'relative',
  },

  tipText: {
    flex: '1 1 0',
    alignSelf: 'stretch',
    color: '#222222',
    fontSize: 18,
    fontFamily: 'Figtree',
    fontWeight: '500',
    lineHeight: '24px',
    wordWrap: 'break-word',
  },

  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    position: 'absolute',
    bottom: '1rem',
    borderTop: `1px solid ${colors.black20}`,
  },

  button: {
    marginTop: '1rem',
    width: '80%',
  },
}

export default styles
