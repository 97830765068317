exports.calculateElapsedTime = (startTime, currentTime) => {
  const elapsedTimeInMs = currentTime - startTime

  if (elapsedTimeInMs <= 0) {
    return 'Just arrived'
  }

  const elapsedSeconds = Math.floor(elapsedTimeInMs / 1000)
  const elapsedMinutes = Math.floor(elapsedTimeInMs / (1000 * 60))
  const elapsedHours = Math.floor(elapsedTimeInMs / (1000 * 60 * 60))
  const elapsedDays = Math.floor(elapsedTimeInMs / (1000 * 60 * 60 * 24))

  if (elapsedDays > 0) {
    return elapsedDays === 1 ? 'A day ' : `${elapsedDays} days `
  } else if (elapsedHours > 0) {
    return elapsedHours === 1 ? 'An hour ' : `${elapsedHours} hours `
  } else if (elapsedMinutes > 0) {
    return elapsedMinutes === 1 ? 'A minute ' : `${elapsedMinutes} minutes `
  } else {
    return elapsedSeconds === 1 ? 'A second ' : `${elapsedSeconds} seconds `
  }
}
