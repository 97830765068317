import { IonRadioGroup, IonRadio, IonItem, IonLabel, IonIcon } from '@ionic/react';
import { businessOutline, personOutline } from 'ionicons/icons';
import { colors } from '../../theme/colors';

export default function BusinessTypeStep({ formData, updateFormData }) {
  return (
    <div style={{ padding: 24 }}>
        <p style={{fontSize:16, lineHeight:1.5, marginTop:-20}}>For billing purposes, we need to know if this location is legally structured as a business or an individual.</p>
      <IonRadioGroup value={formData.businessType} onIonChange={(e) => updateFormData('businessType', e.detail.value)}>
        <IonItem
          lines="none"
          button
          detail={false}
          onClick={() => updateFormData('businessType', 'company')}
          style={{
            border: formData.businessType === 'company' ? `2px solid ${colors.primaryBlue}` : '1px solid #dcdcdc',
            borderRadius: '10px',
            marginBottom: '1rem',
            backgroundColor: formData.businessType === 'company' ? 'red' : 'white',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%'
          }}
        >
          <IonLabel style={{ padding: '1rem' }}>
            <h2 style={{ fontWeight: 'bold', margin: 0 }}>Business</h2>
            <p style={{ margin: 0, color: '#6c757d' }}>For businesses or companies</p>
          </IonLabel>
          <IonIcon icon={businessOutline} style={{ 
            fontSize: '24px' ,
            color: formData.businessType === 'company' ? `${colors.primaryBlue}` : 'gray'
            }} />
        </IonItem>
        <IonItem
          lines="none"
          button
          detail={false}
          onClick={() => updateFormData('businessType', 'individual')}
          style={{
            border: formData.businessType === 'individual' ? `2px solid ${colors.primaryBlue}` : '1px solid #dcdcdc',
            borderRadius: '10px',
            marginBottom: '1rem',
            backgroundColor: 'white',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%'
          }}
        >
          <IonLabel style={{ padding: '1rem' }}>
            <h2 style={{ fontWeight: 'bold', margin: 0 }}>Individual</h2>
            <p style={{ margin: 0, color: '#6c757d' }}>For individuals or freelancers</p>
          </IonLabel>
          <IonIcon icon={personOutline} style={{ 
            fontSize: '24px',
            color: formData.businessType === 'individual' ? `${colors.primaryBlue}` : 'gray' }} />
        </IonItem>
      </IonRadioGroup>
    </div>
  );
}
