import {
  IonAccordion,
  IonAccordionGroup,
  IonCard,
  IonContent,
  IonItem,
  IonLabel,
  IonModal,
} from '@ionic/react'
import CommonHeader from '../commonComponents/CommonHeader'
import StandardContainer from '../commonComponents/StandardContainer'
import { colors } from '../theme/colors'
import ServicesList from './ServicesList'
import ProductsList from './ProductsList'
export default function ProductListModal({
  productsModalOpen,
  services,
  products,
  closeProductsModal,
  selectedServices,
  selectedProducts,
  handleProductSelect,
  handleServiceSelect,
  removeItem,
}) {
  return (
    <IonModal isOpen={productsModalOpen} onDidDismiss={closeProductsModal}>
      <CommonHeader
        closeButton={true}
        closeModal={closeProductsModal}
        title={'Choose services or products '}
      ></CommonHeader>
      <IonContent className='regularBackground'>
        <StandardContainer>
          <IonCard style={{ padding: '1rem' }}>
            <IonAccordionGroup>
              <IonAccordion value='first'>
                <IonItem slot='header'>
                  <IonLabel>Services</IonLabel>
                  {selectedServices.length > 0 && (
                    <IonLabel
                      style={{ display: 'flex', color: colors.grey, justifyContent: 'end' }}
                    >
                      {selectedServices.length}{' '}
                      {selectedServices.length > 1 ? 'services' : 'service'}
                    </IonLabel>
                  )}
                </IonItem>
                <div class='ion-padding' slot='content'>
                  <ServicesList
                    handleServiceSelect={handleServiceSelect}
                    removeItem={removeItem}
                    services={services}
                    selectedServices={selectedServices}
                    displaycheckbox={true}
                  />
                </div>
              </IonAccordion>
              <IonAccordion value='second'>
                <IonItem slot='header'>
                  <IonLabel>Products</IonLabel>
                  {selectedProducts.length > 0 && (
                    <IonLabel
                      style={{ display: 'flex', color: colors.grey, justifyContent: 'end' }}
                    >
                      {selectedProducts.length}{' '}
                      {selectedProducts.length > 1 ? 'products' : 'product'}
                    </IonLabel>
                  )}
                </IonItem>
                <div class='ion-padding' slot='content'>
                  <ProductsList
                    handleProductSelect={handleProductSelect}
                    products={products}
                    removeItem={removeItem}
                    selectedProducts={selectedProducts}
                    displaycheckbox={true}
                  />
                </div>
              </IonAccordion>
            </IonAccordionGroup>
          </IonCard>
        </StandardContainer>
      </IonContent>
    </IonModal>
  )
}
