import React from 'react'
import { colors } from '../../theme/colors'
import { IonIcon } from '@ionic/react'
import { personOutline } from 'ionicons/icons'

// Styles
const outerContainerStyle = {
  width: '100%',
  paddingTop: 16,
  paddingBottom: 16,
  borderBottom: '1px #EBEBEB solid',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  gap: 8,
  display: 'flex',
}

const headerStyle = {
  alignSelf: 'stretch',
  height: 20,
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  display: 'flex',
}

const headerTextStyle = {
  alignSelf: 'stretch',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: 12,
  display: 'inline-flex',
}

const headerTitleStyle = {
  flex: '1 1 0',
  color: '#525252',
  fontSize: 14,
  fontFamily: 'Figtree',
  fontWeight: '600',
  textTransform: 'uppercase',
  lineHeight: '20px',
  letterSpacing: 1.4,
  wordWrap: 'break-word',
}

const containerStyle = {
  width: '100%',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: 12,
  display: 'inline-flex',
}

const iconContainerStyle = {
  paddingTop: 2,
  paddingBottom: 2,
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  gap: 10,
  display: 'inline-flex',
}

const avatarStyle = {
  width: 40,
  height: 40,
  borderRadius: 9999,
  overflow: 'hidden',
  justifyContent: 'center',
  alignItems: 'center',
  display: 'inline-flex',
  backgroundColor: colors.lightGrey,
}

const textContainerStyle = {
  flex: '1 1 0',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  gap: 6,
  display: 'inline-flex',
}

const nameStyle = {
  alignSelf: 'stretch',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: 12,
  display: 'inline-flex',
}

const nameTextStyle = {
  flex: '1 1 0',
  color: '#222222',
  fontSize: 18,
  fontFamily: 'Figtree',
  fontWeight: '500',
  lineHeight: '28px',
  wordWrap: 'break-word',
}

const changeContainerStyle = {
  justifyContent: 'flex-end',
  alignItems: 'center',
  gap: 10,
  display: 'flex',
}

const changeTextStyle = {
  textAlign: 'right',
  color: '#0068DE',
  fontSize: 16,
  fontFamily: 'Figtree',
  fontWeight: '600',
  textDecoration: 'underline',
  lineHeight: '28px',
  wordWrap: 'break-word',
  padding: '8px 12px',
}

export default function ClientInfoSummary({ choosecustomer, setStep, appointment }) {
  return (
    <div style={outerContainerStyle}>
      <div style={headerStyle}>
        <div style={headerTextStyle}>
          <div style={headerTitleStyle}>Client</div>
        </div>
      </div>
      <div style={containerStyle}>
        <div style={iconContainerStyle}>
          <div style={avatarStyle}>
            <IonIcon icon={personOutline} />
          </div>
        </div>
        <div style={textContainerStyle}>
          <div style={nameStyle}>
            <div style={nameTextStyle}>
              {appointment?.customer?.firstName} {appointment?.customer?.lastName}
            </div>
          </div>
        </div>
        {!choosecustomer ? null : (
          <div style={changeContainerStyle} onClick={() => setStep('customer')}>
            <div style={changeTextStyle}>Change</div>
          </div>
        )}
      </div>
    </div>
  )
}
