import { IonButton, IonCheckbox, IonCol, IonInput, IonItem, IonLabel, IonList, IonModal, IonRow, IonSelect, IonSelectOption, IonToast } from '@ionic/react'
import { useState } from 'react'
import ConfirmationModal from '../commonComponents/ConfirmationModal'
import axios from 'axios'
import { useHistory } from 'react-router'

export default function DetailsView({ handleNameError, discount, nameError, error, discountData, updateDiscount, createMode }) {
  const history = useHistory()

  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [deletingData, setDeletingData] = useState(false)
  const [toast, setToast] = useState({ isOpen: false, message: '', color: '' })
  const showToastMessage = (message, color) => {
    setToast({ isOpen: true, message, color })
  }

  const openDeleteModal = () => {
    setDeleteModalOpen(true)
  }
  const closeDeletemodal = () => {
    setDeleteModalOpen(false)
  }
  const handleDelete = async () => {
    setDeletingData(true)
    try {
      const response = await axios.delete(`/discount?id=${discount?._id}`)
      if (response && response.status === 200) {
        setDeletingData(false)
        showToastMessage('Discount deleted successfully!', 'success')
        history.goBack()
      }
    } catch (error) {
      setDeletingData(false)
      showToastMessage('Error deleting discount', 'danger')
    }
  }

  const onValueChange = async (field, value) => {
    if (field === 'discountValue') {
      value = limitDecimalPlaces(value, 2) // Limit decimal places
    }

    updateDiscount({ ...discount, [field]: value })

    if ((!createMode && field === 'name' && discountData.name !== value) || (createMode && field === 'name')) {
      try {
        const response = await axios.get(`/discount/check?name=${value}`)
        if (response?.data && response.data.couponExists) {
          handleNameError('Coupon with this name already exists')
        } else {
          handleNameError('')
        }
      } catch (error) {
        handleNameError('')
        showToastMessage('Error checking coupon name', 'danger')
      }
    }
  }

  // Helper function to limit decimal places
  const limitDecimalPlaces = (value, decimalPlaces) => {
    const stringValue = value?.toString()
    const decimalIndex = stringValue.indexOf('.')

    if (decimalIndex !== -1) {
      const decimalPart = stringValue.substring(decimalIndex + 1)
      return decimalPart.length > decimalPlaces ? parseFloat(stringValue).toFixed(decimalPlaces) : value
    }

    return value
  }

  if (!discount) return 'loading...'

  const discountOptions = [
    {
      id: 1,
      label: 'Subscriptions',
      value: 'subscription',
    },
    {
      id: 2,
      label: 'Services & Products',
      value: 'servicesAndProducts',
    },
  ]
  return (
    <>
      <IonList inset={true} mode='ios'>
        <IonItem>
          <IonSelect
            style={{ minWidth: '100%', padding: 0 }}
            value={discount.couponType}
            disabled={!createMode}
            onIonChange={(e) => onValueChange('couponType', e.detail.value)}
          >
            {discountOptions.map((item) => (
              <IonSelectOption key={item.value} value={item.value}>
                <IonLabel>{item.label}</IonLabel>
              </IonSelectOption>
            ))}
          </IonSelect>
        </IonItem>
      </IonList>

      <IonList inset='true'>
        <IonItem>
          <IonInput type='text' value={discount.name} onIonInput={(e) => onValueChange('name', e.detail.value)} placeholder='Discount Name' />
        </IonItem>
      </IonList>
      <IonLabel style={{ marginLeft: '1rem', color: 'red', fontSize: '12px' }}>{nameError}</IonLabel>

      <div style={{ marginTop: '1rem' }}>
        <IonLabel style={{ marginLeft: '1rem', fontWeight: '600', marginBottom: -20, color: 'grey' }}>Type</IonLabel>
        <IonList inset='true'>
          <IonItem
            disabled={discount?.couponType == 'subscription'}
            onClick={() => onValueChange('discountType', discount.discountType === 'percentage' ? 'fixed' : 'percentage')}
          >
            <IonCheckbox
              slot='start'
              checked={discount.discountType === 'percentage'}
              onIonChange={() => onValueChange('discountType', discount.discountType === 'percentage' ? 'fixed' : 'percentage')}
            />
            <IonLabel>Percentage Discount</IonLabel>
          </IonItem>
          <IonItem
            disabled={discount?.couponType == 'subscription'}
            onClick={() => onValueChange('discountType', discount.discountType === 'fixed' ? 'percentage' : 'fixed')}
          >
            <IonCheckbox
              slot='start'
              checked={discount.discountType === 'fixed'}
              onIonChange={() => onValueChange('discountType', discount.discountType === 'fixed' ? 'percentage' : 'fixed')}
            />
            <IonLabel>Fixed Price Discount</IonLabel>
          </IonItem>
        </IonList>
      </div>

      <IonList inset='true'>
        {discount.discountType === 'percentage' ? (
          <IonItem>
            <IonLabel position='stacked'>Percentage</IonLabel>
            <IonRow>
              <IonCol>
                <IonInput
                  disabled={discount?.couponType == 'subscription'}
                  type='number'
                  value={discount.discountValue}
                  onIonInput={(e) => onValueChange('discountValue', e.detail.value)}
                  placeholder='0'
                  inputmode='decimal'
                />
              </IonCol>
              <IonCol size='auto' style={{ paddingTop: 13, marginLeft: -5, color: 'grey' }}>
                %
              </IonCol>
            </IonRow>
          </IonItem>
        ) : (
          <IonItem>
            <IonLabel position='stacked'>Discount Amount</IonLabel>
            <IonRow>
              <IonCol size='auto' style={{ paddingTop: 13, marginLeft: -5, color: 'grey' }}>
                $
              </IonCol>
              <IonCol>
                <IonInput
                  type='number'
                  value={discount.discountValue}
                  onIonInput={(e) => onValueChange('discountValue', e.detail.value)}
                  placeholder='0.00'
                  inputmode='decimal'
                />
              </IonCol>
            </IonRow>
          </IonItem>
        )}
      </IonList>
      <IonLabel style={{ margin: '1rem', color: 'red', fontSize: '12px' }}>{error}</IonLabel>

      {discount?._id && (
        <>
          <div style={{ margin: '0 1rem' }}>
            <IonButton color='warning' fill='solid' expand='block' onClick={openDeleteModal}>
              Delete
            </IonButton>
          </div>

          <IonModal isOpen={deleteModalOpen}>
            <ConfirmationModal
              isOpen={deleteModalOpen}
              message={'Are You Sure You want to delete this coupon?'}
              cancel={closeDeletemodal}
              loading={deletingData}
              save={handleDelete}
              saveText={'Delete'}
            />
          </IonModal>

          <IonToast
            position='top'
            isOpen={toast.isOpen}
            onDidDismiss={() => setToast({ isOpen: false, message: '', color: '' })}
            message={toast.message}
            color={toast.color}
            duration={2000}
          />
        </>
      )}
    </>
  )
}
