import { IonIcon } from '@ionic/react'
import { documentTextOutline } from 'ionicons/icons'
import CustomerNotesModal from '../../../commonComponents/customerNotes'
import styles from '../Styles/CustomerNotesStyles'

export default function CustomerNotes({ appointment, isOpen, setOpen, customerId, fetchNotes, setNotes, notes, notesId, lastEditTime, lastEditorName }) {
  return (
    <>
      <div style={styles.containerStyle}>
        <div style={styles.headerStyle}>
          <div style={styles.innerContainerStyle}>
            <div style={styles.iconContainerStyle}>
              <div style={styles.iconStyle}>
                <IonIcon style={styles.iconImageStyle} icon={documentTextOutline} />
              </div>
            </div>
            <div style={styles.notesTitleStyle}>
              <div style={styles.notesHeaderStyle}>NOTES</div>
              <div style={styles.notesTextContainerStyle}>
                <div style={{ ...styles.notesTextStyle, height: notes ? '80px' : '' }}>{notes ? notes : 'Write a customer note'}</div>
              </div>
            </div>
            {['Complete'].includes(appointment.status) ? null : (
              <div style={styles.changeButtonStyle} onClick={() => setOpen(true)}>
                <div style={styles.changeTextStyle}>Change</div>
              </div>
            )}
          </div>
        </div>
      </div>
      <CustomerNotesModal
        isOpen={isOpen}
        setOpen={setOpen}
        customerId={customerId}
        fetchNotes={fetchNotes}
        setNotes={setNotes}
        notes={notes}
        notesId={notesId}
        lastEditTime={lastEditTime}
        lastEditorName={lastEditorName}
      />
    </>
  )
}
