import React from 'react'
import { calculateEmployeeFreeEventStyles, setTimeFromDateStrForDate } from '../../utils'
import { calculateEventStyles } from './helpers'
import { IonLabel } from '@ionic/react'
import { fontWeights } from '../../theme/typography'
import { colors } from '../../theme/colors'

export default function EmployeeFreeTime({ hour, selectedDate, freeTime, locationStartTime }) {
  const hourStart = new Date(hour)
  const hourEnd = new Date(hour)
  hourEnd.setMinutes(hourEnd.getMinutes() + 59)

  return freeTime?.map((freeTimeEvent, breakIndex) => {
    const startTime = setTimeFromDateStrForDate(selectedDate, freeTimeEvent.start)

    // Check if freeTimeEvent's start time is within the current hour
    if (startTime >= hourStart && startTime < hourEnd) {
      const endTime = setTimeFromDateStrForDate(selectedDate, freeTimeEvent.end)
      freeTimeEvent.startTimeText = freeTimeEvent.start
      freeTimeEvent.endTimeText = freeTimeEvent.end

      return <td className='employee-free' key={breakIndex} style={calculateEmployeeFreeEventStyles(freeTimeEvent, locationStartTime)}></td>
    }

    return null
  })
}
