import StandardContainer from '../../commonComponents/StandardContainer'
import { IonCard } from '@ionic/react'

export default function StoreClosedCard() {
  return (
    <StandardContainer>
      <IonCard
        style={{
          padding: '3rem',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <h6 style={{ color: 'grey', textAlign: 'center' }}> Closed</h6>
      </IonCard>
    </StandardContainer>
  )
}
