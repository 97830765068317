import {
  IonAvatar,
  IonCol,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonRow,
} from '@ionic/react'
import { colors } from '../theme/colors'
import StarRating from '../commonComponents/StarRating'
import { personOutline } from 'ionicons/icons'
import { getFormattedMediumDate } from '../utils'

export default function DeflectedFeedback({ feedbackList }) {
  return (
    <>
      <h4 style={{ margin: '1rem' }}>Deflected Feedback</h4>
      <IonList mode='ios' inset={true}>
        {feedbackList.map((item, index) => {
          return (
            <IonItem key={index}>
              <IonGrid>
                <IonRow>
                  <IonCol size='auto'>
                    <IonAvatar style={{ marginTop: '3px', height: 25, width: 25, marginBottom: 0 }}>
                      <img
                        alt='User Avatar'
                        src={'https://ionicframework.com/docs/img/demos/avatar.svg'}
                      />
                    </IonAvatar>
                  </IonCol>{' '}
                  <IonCol>
                    <IonRow className='ion-justify-content-center ion-align-items-center'>
                      <IonCol>
                        <h6 style={{ margin: 0, fontSize: '14px' }}>
                          {item.customerFirstName} {item.customerLastName}
                        </h6>
                      </IonCol>
                      <IonCol size='auto'>
                        <StarRating color={'#3780ff'} rating={item.rating} />
                      </IonCol>
                    </IonRow>
                    {item?.feedback && (
                      <IonRow>
                        <IonCol>
                          {/* <h6 style={{ margin: 0, fontSize: '12px' }}>{item.feedback}</h6> */}
                          <h6 style={{ margin: 0, fontSize: '12px', color: '#555555' }}>
                            {item.feedback}
                          </h6>
                        </IonCol>
                      </IonRow>
                    )}
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonLabel style={{ fontSize: '12px', color: colors.grey }}>
                      {item.submittedDate && getFormattedMediumDate(item.submittedDate)}
                    </IonLabel>
                  </IonCol>
                  <IonCol
                    style={{
                      display: 'flex',
                      color: colors.grey,
                      justifyContent: 'end',
                      alignItems: 'center',
                    }}
                  >
                    <IonIcon icon={personOutline} />
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonItem>
          )
        })}
      </IonList>
    </>
  )
}
