import { IonGrid, IonSpinner, IonRow, IonCol, IonLabel, IonIcon } from '@ionic/react'
import { personSharp } from 'ionicons/icons'
export default function DetailsView({ user, updateUser }) {
  if (!user) return <IonSpinner name='dots' />

  const getFormattedDateTime = date => {
    const dateObject = new Date(date)
    const formattedDateTime = dateObject.toLocaleString('en-US', {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    })

    // Output: April 20, 2023 15:30:45
    return formattedDateTime
  }

  return (
    <IonGrid>
      <IonRow style={{ display: 'flex', alignItems: 'center' }}>
        <IonCol size='auto'>
          <IonIcon style={{ height: 30, width: 30 }} icon={personSharp} />
        </IonCol>
        <IonCol size='10' style={{ display: 'flex', flexDirection: 'column' }}>
          <IonRow style={{ marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
            <h5 style={{ margin: '0', fontWeight: 'bold', color: 'black' }}>{user?.email}</h5>
            {user.status === 'Disabled' && (
              <p
                style={{
                  margin: '0 0 0 0.5rem',

                  alignItems: 'center',
                  fontSize: '10px',
                  backgroundColor: 'rgb(214 214 214 / 70%)',
                  padding: '5px 1rem',
                  color: '#555555',
                  display: 'inline-flex',
                  borderRadius: '10px',
                }}
              >
                Disabled
              </p>
            )}
          </IonRow>
          {user && !user.lastLoggedInTime && (
            <IonLabel>
              Acc Created :{' '}
              {user && user.createdDate ? getFormattedDateTime(user.createdDate) : 'N/A'}
            </IonLabel>
          )}
          <IonLabel>
            Last Login :{' '}
            {user && user.lastLoggedInTime ? getFormattedDateTime(user.lastLoggedInTime) : 'N/A'}
          </IonLabel>
        </IonCol>
      </IonRow>
    </IonGrid>
  )
}
