import { IonButton, IonCard, IonInput } from "@ionic/react";
import { useEffect, useState } from "react";
import axios from 'axios'
import useAxios from 'axios-hooks'

export default function RegisterUser({ employee, businessId }) {
    const [registrationLink, setRegistrationLink] = useState("")
    const [isCreating, setIsCreating] = useState(false)
    const [registerLink, refreshLink] = useAxios(`/user/registerLink?employeeId=${employee._id}&type="employee"`)



    async function handleCreateRegistrationLink() {
        setIsCreating(true)
        let newCode = Math.floor(100000 + Math.random() * 900000)
        try {
            //await axios.post('/user/registerLink', {code: newCode, employeeId:employee._id, type:'employee', businessId})
            setRegistrationLink(`${window.location.hostname}/newUserRegistration?bid=${businessId}&link=${newCode}`)
        } catch (error) {

        }
        setIsCreating(false)

    }

    return (
        <>
            <IonCard style={{ padding: 20 }}>
                <h5 style={{ marginTop: 0 }}>REGISTER USERS</h5>
                <IonInput value={registrationLink} labelPlacement="floating" fill="outline"></IonInput>
                <IonButton onClick={handleCreateRegistrationLink} disabled={registerLink.loading}>Create Link</IonButton>
            </IonCard>
        </>
    )
}