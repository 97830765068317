import React, { useState, useEffect, useContext } from 'react'
import ResultsList from './resultsList'
import axios from 'axios'
import { AuthContext } from '../auth'
import { getFormattedMediumDate } from '../utils'
import { IonContent, IonPage, IonRefresher, IonRefresherContent } from '@ionic/react'
import AddHeader from '../commonComponents/AddHeader'
import StandardContainer from '../commonComponents/StandardContainer'
import { useLocation } from 'react-router-dom'
import CheckIsManagerOrAdmin from '../Utils/CheckIsManagerOrAdmin'
import DefaultMenu from '../commonComponents/SideMenu/DefaultMenu'
import NoPermissionsCard from '../commonComponents/NoPermissionsCard'
import ListViewHeader from './ListViewHeader'

export default function AppointmentListView({
  defaultView,
  selectedDate,
  setSelectedDate,
  selectedStylists,
  selectedTypes,
  employees,
  refreshAppointments,
  handleView,
  setDefaultView,
}) {
  const { businessData, userData, locationId } = useContext(AuthContext)
  const userEntitlements = CheckIsManagerOrAdmin(userData, businessData, locationId)
  const [appointments, setAppointments] = useState()
  const [loading, setLoading] = React.useState(false)
  const [breaks, setBreaks] = React.useState()
  const location = useLocation()

  useEffect(() => {
    getAppointmentsList()
    getBreaks()

    const interval = setInterval(() => {
      getAppointmentsList(true)
    }, 15000)
    return () => clearInterval(interval)
  }, [selectedDate, businessData, location, locationId])

  const getAppointmentsList = async (hideLoader = false) => {
    if (!businessData?._id) return
    setLoading(!hideLoader)
    try {
      let locQuery = locationId ? `&locationId=${locationId}` : ''
      let date = getFormattedMediumDate(selectedDate)

      let response = await axios.get(`/appointment_v2/getList?businessId=${businessData?._id}${locQuery}&dateText=${date}`)
      const filteredData = response.data.filter((item, index) => item.status != 'Canceled')
      const data = filteredData.map((item, index) => {
        return {
          resourceId: item?.barber?._id,
          start: new Date(item.startTime),
          end: new Date(item.endTime),
          extendedProps: { ...item },
        }
      })
      setAppointments(response.data)
    } catch (error) {}
    setLoading(false)
  }

  const getBreaks = async () => {
    if (!businessData?._id) return
    let locQuery = locationId ? `&locationId=${locationId}` : ''
    let date = getFormattedMediumDate(selectedDate)
    try {
      let response = await axios.get(`/employee/searchForBreaksByDate?businessId=${businessData?._id}${locQuery}&dateText=${date}`)
      setBreaks(response.data)
    } catch (error) {}
  }

  async function handleRefresh(event) {
    await getAppointmentsList()
    await getBreaks()
    event.detail.complete()
  }

  if (userEntitlements?.noPermissions) {
    return (
      <>
        <DefaultMenu />
        <IonPage id='main-content'>
          <AddHeader title={'Appointments'} />
          <IonContent>
            <StandardContainer>
              <NoPermissionsCard />
            </StandardContainer>
          </IonContent>
        </IonPage>
      </>
    )
  }
  let filteredAppointmentsList = appointments?.filter((appointment) => {
    let barberName = appointment?.barber?.firstName + ' ' + appointment?.barber?.lastName

    // Check if the appointment matches the selected stylists and selected types
    return (
      (selectedStylists.length === 0 || selectedStylists.includes(barberName)) && (selectedTypes.length === 0 || selectedTypes.includes(appointment?.status))
    )
  })
  return (
    <>
      <ListViewHeader
        defaultView={defaultView}
        setDefaultView={setDefaultView}
        refreshAppointments={refreshAppointments}
        displayCalendar={true}
        getBreaks={getBreaks}
        employees={employees}
        selectedDate={selectedDate}
        loading={loading}
        setSelectedDate={setSelectedDate}
        handleView={handleView}
      />
      <IonContent>
        <IonRefresher slot='fixed' onIonRefresh={handleRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        <StandardContainer>
          <br></br>

          <ResultsList appointments={filteredAppointmentsList} loading={loading} locationId={locationId} businessId={businessData?._id} breaks={breaks} />
        </StandardContainer>
      </IonContent>
    </>
  )
}
