import { IonCard, IonCheckbox, IonCol, IonGrid, IonIcon, IonRow, IonSpinner, IonToast, IonButton } from '@ionic/react'
import { businessOutline } from 'ionicons/icons'
import { useContext, useEffect, useState } from 'react'
import axios from 'axios'
import { AuthContext } from '../auth'
import { roles } from '../StaticData/StaticData'
import { AccessControl } from '../AccessControl'

const initialPermissions = {
  owner: false,
  admin: false,
  manager: false,
  professional: false
}

export default function EachLocationPermission({ user, refreshUser }) {
  const { selectedLocationData } = useContext(AuthContext)
  const [selectedPermissions, setSelectedPermissions] = useState(initialPermissions)
  const [loading, setLoading] = useState(false)

  // Toast state
  const [toast, setToast] = useState({ isOpen: false, message: '', color: '' })

  const showToastMessage = (message, color) => {
    setToast({ isOpen: true, message, color })
  }

  // Fetch the entitlements for the current location
  const getLocationEntitlements = () => {
    const location = user?.businesses?.flatMap((business) => business.locations).find((loc) => loc._id === selectedLocationData?._id)
    // Return entitlements or default to initialPermissions
    return location?.entitlements || initialPermissions
  }

  // Update the entitlements state based on the user’s current entitlements for this location
  const updateEntitlements = () => {
    const entitlements = getLocationEntitlements()
    setSelectedPermissions(entitlements)  // Safe initialization
  }

  // Initialize permissions on component load
  useEffect(() => {
    updateEntitlements()
  }, [user?.businesses])

  // Handle permission updates and post the updated permissions
  const handlePermissionUpdate = async (permission) => {
    const updatedPermissions = {
      ...selectedPermissions,
      [permission]: !selectedPermissions[permission]
    }

    setSelectedPermissions(updatedPermissions)

    try {
      setLoading(true)
      const response = await axios.post('/user/updateLocationPermissions', {
        userId: user._id,
        entitlements: updatedPermissions
      })

      refreshUser()

      if (response && response.status === 200) {
        showToastMessage(`${permission} permission updated successfully.`, 'success')
      } else {
        showToastMessage('Failed to update permissions.', 'danger')
      }
    } catch (error) {
      console.error(error)
      showToastMessage('Failed to update permissions.', 'danger')
    } finally {
      setLoading(false)
    }
  }

  // Handle removing all permissions
  const handleRemoveAllPermissions = async () => {
    const clearedPermissions = { ...initialPermissions }

    setSelectedPermissions(clearedPermissions)

    try {
      setLoading(true)
      const response = await axios.post('/user/updateLocationPermissions', {
        userId: user._id,
        entitlements: clearedPermissions
      })

      refreshUser()

      if (response && response.status === 200) {
        showToastMessage('All permissions removed successfully.', 'success')
      } else {
        showToastMessage('Failed to remove permissions.', 'danger')
      }
    } catch (error) {
      console.error(error)
      showToastMessage('Failed to remove permissions.', 'danger')
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      {loading && <IonSpinner name="dots" />}
      {!loading && (
        <IonGrid>
          <IonRow style={{ display: 'flex', alignItems: 'end', marginBottom: '1rem' }}>
            <IonIcon style={{ marginRight: '10px' }} icon={businessOutline} size="large" />
            <h6 style={{ margin: '0', color: 'black', fontWeight: 'bold' }}>
              {selectedLocationData?.name} Permissions
            </h6>
          </IonRow>

          {/* Render permissions checkboxes */}
          {roles.map((item, index) => (
            <AccessControl componentName={`${item.value}_permission`}>
              <IonRow key={index} onClick={() => handlePermissionUpdate(item.value)} style={{ cursor: 'pointer' }}>
                <IonCol>
                  <h6
                    style={{
                      margin: '5px',
                      fontWeight: 'bold',
                      color: 'black',
                      fontSize: '1rem',
                    }}
                  >
                    {item.role}
                  </h6>
                  <h6 style={{ margin: '5px', fontSize: '12px' }}>{item.description}</h6>
                </IonCol>
                <IonCol size="auto" style={{ display: 'flex', justifyContent: 'end', paddingTop: 20 }}>
                  <IonCheckbox
                    style={{ height: '1.5rem', width: '1.5rem' }}
                    checked={!!selectedPermissions[item.value]} // Ensure boolean value
                    onIonChange={() => handlePermissionUpdate(item.value)}
                  />
                </IonCol>
              </IonRow>
            </AccessControl>
          ))}

          {/* Button to remove all permissions 
          TODO This button should only remove the permissions for the current level or below
          */}
          <IonRow style={{ marginTop: '1rem' }}>
            <IonCol></IonCol>
            <IonCol size="auto">
              <IonButton fill="clear" color="primary" onClick={handleRemoveAllPermissions} style={{ marginRight: -10 }}>
                Remove all permissions
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      )}
      <IonToast
        position="top"
        isOpen={toast.isOpen}
        onDidDismiss={() => setToast({ ...toast, isOpen: false })}
        message={toast.message}
        color={toast.color}
        duration={2000}
      />
    </>
  )
}
