export const stepSucessStyles = {
  // Styles
  successContainerStyle: {
    marginTop: 30,
    width: '100%',
    height: '100%',
    paddingLeft: 24,
    paddingRight: 24,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 16,
    display: 'inline-flex',
  },

  successContentStyle: {
    alignSelf: 'stretch',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 4,
    display: 'flex',
  },

  successMessageStyle: {
    width: 60,
    height: 60,
    padding: 5,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'inline-flex',
  },

  successTextStyle: {
    textAlign: 'center',
    color: '#222222',
    fontSize: 32,
    fontFamily: 'Figtree',
    fontWeight: '600',
    lineHeight: '38.4px',
    wordWrap: 'break-word',
  },

  secondaryTextStyle: {
    alignSelf: 'stretch',
    textAlign: 'center',
    color: '#717171',
    fontSize: 18,
    fontFamily: 'Figtree',
    fontWeight: '500',
    lineHeight: '24px',
    wordWrap: 'break-word',
  },

  buttonStyle: {
    marginTop: 20,
    width: 200,
  },
}
