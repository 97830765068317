import React from 'react';
import axios from 'axios'
import {  IonButton, IonCard, IonCol, IonGrid, IonIcon, IonRow, IonSpinner,  } from '@ionic/react';
import MiniHeader from '../../commonComponents/MiniHeader';
import { documentOutline } from 'ionicons/icons';
import SubscriberInfo from '../../commonComponents/SubscriberInfo';


export default function CustomerCard({ appointment, subscription, setNotesOpen, refreshData, soldEmployee }) {
    console.log('appointment', appointment)
    return (
        <IonCard style={{ color: 'black', padding: 18, marginTop: 10 }}>
            <MiniHeader title="Client" />
            <h3 style={{ margin: 0 }}>{appointment?.customer?.firstName} {appointment?.customer?.lastName}</h3>
            <SubscriberInfo customer={appointment?.customer} refreshData={refreshData} soldEmployee={soldEmployee}/>
            <div style={{backgroundColor:'#e6e6e6', height:1, width:'100%', marginTop:8}}/>
        <IonButton fill={'clear'} expand='block' onClick={()=>{setNotesOpen(true)}} style={{marginLeft:-14, marginTop:0, marginBottom:-16, fontSize:14}}><IonIcon icon={documentOutline}/> &nbsp;&nbsp;Customer Notes</IonButton>
        </IonCard>
    )
}

