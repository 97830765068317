import { useEffect, useState } from 'react'
import {
  IonItem,
  IonLabel,
  IonList,
  IonReorder,
  IonReorderGroup,
  IonButton,
  IonIcon,
  IonPopover,
  IonRow,
  IonGrid,
  IonCol,
  IonSpinner,
  IonToast,
} from '@ionic/react'
import { ellipsisVertical } from 'ionicons/icons' // Import the icons
import axios from 'axios'

export default function ResultsList({ subscriptionPackages = [], getSubscriptionPackagesList }) {
  const [packages, setPackages] = useState(subscriptionPackages)
  const [showPopover, setShowPopover] = useState(false)
  const [popoverEvent, setPopoverEvent] = useState(undefined)
  const [reorderEnabled, setReorderEnabled] = useState(false)
  const [migratingIndices, setMigratingIndices] = useState({ active: false, index: null })
  const [toast, setToast] = useState({ isOpen: false, message: '', color: '' })

  useEffect(() => {
    setPackages(subscriptionPackages)
  }, [subscriptionPackages])
  const showToastMessage = (message, color = 'success') => {
    setToast({ isOpen: true, message, color })
  }

  const handleReorder = async (event) => {
    const fromIndex = event.detail.from
    const toIndex = event.detail.to
    const updatedPackages = [...packages]
    const [movedItem] = updatedPackages.splice(fromIndex, 1)
    updatedPackages.splice(toIndex, 0, movedItem)

    const reorderedData = updatedPackages.map((item, index) => ({
      id: item._id,
      position: index + 1,
    }))
    const reorderInput = {
      subscriptionPackages: reorderedData,
    }

    try {
      const response = await axios.patch('/subscriptionPackage/changePositions', reorderInput)
      if (response && response.status === 200) {
        setPackages(updatedPackages)
        event.detail.complete()
        showToastMessage('Reordered successfully!', 'success')
      }
    } catch (error) {
      console.error('Failed to reorder:', error)
      event.detail.complete()
      showToastMessage('Error reordering packages. Please try again.', 'danger')
    }
  }

  const handleMigrate = async (event, id, index) => {
    event.stopPropagation() // Prevents the click event from propagating

    if (migratingIndices.active) return // Migration already in progress, ignore

    try {
      setMigratingIndices({ active: true, index })

      const response = await axios.post(`/subscriptionPackage/migrateToV2?id=${id}`)
      if (response.status === 200) {
        setMigratingIndices({ active: false, index: null })
        getSubscriptionPackagesList()
        showToastMessage('Migration successful!', 'success')
      }
    } catch (error) {
      console.error('Migration failed:', error)
      setMigratingIndices({ active: false, index: null })
      showToastMessage('Migration failed. Please try again.', 'danger')
    }
  }

  const openPopover = (e) => {
    e.persist()
    setPopoverEvent(e)
    setShowPopover(true)
  }

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '0 20px',
        }}
      >
        <h6 style={{ color: 'grey' }}>Subscription Packages</h6>
        <IonButton fill='clear' onClick={openPopover} style={{ paddingTop: 4, marginRight: -20 }} color={'dark'}>
          <IonIcon icon={ellipsisVertical} />
        </IonButton>
      </div>
      <IonPopover isOpen={showPopover} event={popoverEvent} onDidDismiss={() => setShowPopover(false)}>
        <IonList>
          <IonItem
            button
            onClick={() => {
              setReorderEnabled(!reorderEnabled)
              setShowPopover(false)
            }}
          >
            {!reorderEnabled ? 'Reorder Subscriptions' : 'Done with Reorder'}
          </IonItem>
        </IonList>
      </IonPopover>
      {reorderEnabled ? (
        <IonButton
          fill='clear'
          color={'danger'}
          onClick={() => {
            setReorderEnabled(!reorderEnabled)
          }}
        >
          Done with Reorder
        </IonButton>
      ) : null}
      <IonList inset={true} mode='ios'>
        <IonReorderGroup disabled={!reorderEnabled} onIonItemReorder={handleReorder}>
          {packages.map((row, index) => (
            <IonItem button detail={true} routerLink={`/core/subscriptionPackages/${row._id}`} routerDirection='forward' key={row._id}>
              <IonReorder style={{ width: '100%' }}>
                <IonGrid style={{ padding: 0, margin: 0 }}>
                  <IonRow
                    style={{
                      padding: 0,
                      margin: 0,
                      display: 'flex',
                      justifyContent: 'space-between',
                      width: '100%',
                    }}
                  >
                    {reorderEnabled ? (
                      <IonCol style={{ padding: 0, margin: 0 }} size='auto'>
                        <div
                          style={{
                            height: 2,
                            width: 20,
                            backgroundColor: 'grey',
                            margin: 4,
                            marginTop: 20,
                            marginLeft: 4,
                          }}
                        />
                        <div style={{ height: 2, width: 20, backgroundColor: 'grey', margin: 4 }} />
                        <div style={{ height: 2, width: 20, backgroundColor: 'grey', margin: 4 }} />
                        <div
                          style={{
                            height: 2,
                            width: 20,
                            backgroundColor: 'transparent',
                            marginLeft: 20,
                          }}
                        />
                      </IonCol>
                    ) : null}
                    <IonCol style={{ padding: 0, margin: 0 }}>
                      <IonLabel style={{ paddingTop: 8, paddingBottom: 8 }}>
                        <h2 style={{ fontWeight: '600', color: 'black' }}>{row.name}</h2>
                        <p>{row.enabled ? 'Enabled' : 'Disabled'}</p>
                      </IonLabel>
                    </IonCol>
                    {!row.prices && (
                      <IonCol style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                        <IonButton disabled={migratingIndices.active} onClick={(event) => handleMigrate(event, row._id, index)}>
                          {migratingIndices.active && migratingIndices.index === index && (
                            <IonSpinner style={{ height: 15, width: 15, marginRight: '10px' }} name='lines' />
                          )}
                          Migrate To V2
                        </IonButton>
                      </IonCol>
                    )}
                  </IonRow>
                </IonGrid>
              </IonReorder>
            </IonItem>
          ))}
        </IonReorderGroup>
      </IonList>

      <IonToast
        position='top'
        isOpen={toast.isOpen}
        onDidDismiss={() => setToast({ ...toast, isOpen: false })}
        message={toast.message}
        color={toast.color}
        duration={2000}
      />
    </>
  )
}
