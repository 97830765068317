import { IonContent, IonPage } from '@ionic/react'
import CommonHeader from '../commonComponents/CommonHeader'
import StandardContainer from '../commonComponents/StandardContainer'
import PaymentHistory from './PaymentHistory/PaymentHistory'

export default function CustomerPaymentHistory({ match }) {
  const customer_id = match?.params?.id

  return (
    <IonPage id='main-content'>
      <CommonHeader title={'Payment History'} backIcon={true}></CommonHeader>
      <IonContent>
        <StandardContainer>
          <PaymentHistory customer_id={customer_id} />
        </StandardContainer>
      </IonContent>
    </IonPage>
  )
}
