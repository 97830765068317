import ButtonPanel from '../buttonPanel'

const SummaryForm = ({ data, flowIndex, onBack, onNext, onCancel, history }) => {
  const handleNext = () => onNext()

  return (
    <>
      <p>Business Name: </p>
      <ButtonPanel data={data} flowIndex={flowIndex} onBack={onBack} onNext={handleNext} onCancel={onCancel} history={history} />
    </>
  )
}

export default SummaryForm
