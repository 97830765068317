import { useState, useContext, useEffect } from 'react'
import { IonModal, IonContent, IonList, IonToast } from '@ionic/react'
import { AuthContext } from '../auth'
import axios from 'axios'
import StandardContainer from '../commonComponents/StandardContainer'
import CardOnFile from '../Payment/cardOnFile'
import ChoosePackageAndPlan from './ChoosePackageAndPlan'
import CommonHeader from '../commonComponents/CommonHeader'
import PaymentConfirmationSection from './PaymentConfirmationSection'
import { dataURLtoBlob } from '../utils'

function CreateSubscriptionModal({ isOpen, setIsOpen, locationId, subscriptionId, onSuccess, customer, soldEmployee }) {
  const { businessData } = useContext(AuthContext)

  const [subscriptionPackages, setSubscriptionPackages] = useState({
    loading: false,
    data: null,
    error: null,
  })
  const [customerSignatureImage, setCustomerSignatureImage] = useState(false)
  const [selectedPackage, setSelectedPackage] = useState(null)
  const [isCardOnFile, setIsCardOnFile] = useState(false)
  const [isSubscribing, setIsSubscribing] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [openAdvanced, setOpenAdvanced] = useState(false)
  const [freeTrialDays, setFreeTrialDays] = useState(0)
  const [selectedPrice, setSelectedPrice] = useState(false)
  const [discounts, setDiscounts] = useState([])
  const [coupon, setCoupon] = useState(false)

  // Toast state
  const [toast, setToast] = useState({ isOpen: false, message: '', color: '' })
  const showToastMessage = (message, color) => {
    setToast({ isOpen: true, message, color })
  }
  const updateCustomerSignature = (value) => {
    setCustomerSignatureImage(value)
  }
  const handleSelectedPackage = (packageItem) => {
    updateCustomerSignature(false)
    setSelectedPackage(packageItem)
    setSelectedPrice(false)
  }
  const handleSelectedPrice = (price) => {
    setSelectedPrice(price)
  }
  function dismiss() {
    setCoupon(false)
    setSelectedPackage(null)
    setIsSubscribing(false)
    setErrorMessage(null)
    setIsOpen(false)
    setSelectedPrice(false)
  }
  const getDiscountsList = async () => {
    try {
      let response = await axios.get(`/customer_v2/coupons/active?id=${customer._id}`)
      setDiscounts(response.data)
    } catch (error) {
      console.error('Error fetching discounts.', error) // Log the error for debugging
    }
  }
  useEffect(() => {
    getDiscountsList()
    getSubscriptionPackages()
  }, [isOpen])

  async function getSubscriptionPackages() {
    setSubscriptionPackages({ ...subscriptionPackages, loading: true })
    let locQuery = locationId ? `&locationId=${locationId}` : ''
    try {
      let r = await axios.get(`/subscriptionPackage/getList?businessId=${businessData._id}${locQuery}`)
      setSubscriptionPackages({ loading: false, data: r.data, error: null })
    } catch (error) {
      setSubscriptionPackages({ ...subscriptionPackages, loading: false, error })
    }
  }
  const updateCoupon = (value) => {
    setCoupon(value)
  }
  const removeCoupon = () => {
    setCoupon(false)
  }
  const uploadCustomerSignature = async (subscriptionId) => {
    const dataBlob = dataURLtoBlob(customerSignatureImage)
    const file = new File([dataBlob], 'photo.jpg', { type: 'image/jpeg' })

    const data = new FormData()
    data.append('selectedFile', file)
    data.append('subscriptionId', subscriptionId)
    data.append('customerId', customer._id)

    try {
      const response = await axios.post('/subscription/customer/signature', data)
      return response?.status === 200
    } catch (error) {
      return false
    }
  }

  async function subscriberUserToPlan() {
    setIsSubscribing(true)
    if (isNaN(freeTrialDays)) {
      alert('There was an issue with your free trial days')
      return
    }
    const periodValue = `Every ${selectedPrice.interval_count === 1 ? '' : selectedPrice.interval_count} ${
      selectedPrice.interval_count > 1 ? `${selectedPrice.interval}s` : selectedPrice.interval
    }`
    try {
      let createSubscriptionInput = {
        subscriptionId: selectedPackage._id,
        period: periodValue,
        businessId: businessData._id,
        customerId: customer._id,
        price: Math.abs(selectedPrice.amount),
        stripeSubscriptionPlanId: selectedPrice?.stripePriceId,
        freeTrialDays: parseInt(freeTrialDays) || 0,
      }
      if (coupon && coupon.stripeCouponId) {
        createSubscriptionInput.couponId = coupon.stripeCouponId
      }

      if (soldEmployee) {
        createSubscriptionInput.soldEmployeeId = soldEmployee._id
        createSubscriptionInput.soldMethod = "EmployeeSale"
      }

      const response = await axios.post('/subscription', createSubscriptionInput)

      if (response.status === 200) {
        if (customerSignatureImage) {
          await uploadCustomerSignature(response?.data?._id)
        }
        onSuccess()
        showToastMessage('Subscription created successfully!', 'success') // Show success toast
        dismiss()
      }
    } catch (error) {
      setIsSubscribing(false)
      let message = error?.response?.data?.error?.raw?.message || 'Failed'
      setErrorMessage('There was an issue subscribing to the package. ' + message + ' (PA-1000)')
      showToastMessage('Failed to create subscription. Please try again.', 'danger') // Show error toast
    }
  }

  const handleToastDismiss = () => {}

  return (
    <>
      <IonModal isOpen={isOpen} onDidDismiss={() => setIsOpen(false)}>
        <CommonHeader title={'Add A Subscription'} closeButton={true} closeModal={dismiss} />
        <IonContent className='regularBackground'>
          <StandardContainer>
            <div style={{ padding: 20, paddingBottom: 0, paddingTop: 0, marginTop: -76 }}>
              <h1>Add a Subscription</h1>
            </div>
            <ChoosePackageAndPlan
              title={'Select a subscription'}
              selectedPackage={selectedPackage}
              subscriptionPackages={subscriptionPackages}
              currentSubscription={false}
              currentSubscriptionPricePerDay={false}
              selectedPrice={selectedPrice}
              handleSelectedPrice={handleSelectedPrice}
              handleSelectedPackage={handleSelectedPackage}
            />

            {selectedPrice ? (
              <>
                <div style={{ padding: 20, paddingBottom: 0, paddingTop: 4 }}>
                  <h6>2. Credit Card</h6>
                </div>
                <IonList inset={true} mode='ios'>
                  <CardOnFile customerId={customer._id} setIsCardOnFile={setIsCardOnFile} />
                </IonList>
                {isCardOnFile && (
                  <>
                  
                  <PaymentConfirmationSection
                    soldEmployee={soldEmployee}
                    customerSignatureImage={customerSignatureImage}
                    updateCustomerSignature={updateCustomerSignature}
                    removeCoupon={removeCoupon}
                    selectedCoupon={coupon}
                    coupons={discounts}
                    updateCoupon={updateCoupon}
                    customer={customer}
                    selectedPackage={selectedPackage}
                    selectedPrice={selectedPrice}
                    freeTrialDays={freeTrialDays}
                    openAdvanced={openAdvanced}
                    errorMessage={errorMessage}
                    isSubscribing={isSubscribing}
                    setOpenAdvanced={setOpenAdvanced}
                    subscriberUserToPlan={subscriberUserToPlan}
                    setFreeTrialDays={setFreeTrialDays}
                  />
                  
                  </>
                )}
              </>
            ) : null}
          </StandardContainer>
        </IonContent>
      </IonModal>
      <IonToast
        position='top'
        isOpen={toast.isOpen}
        onDidDismiss={() => setToast({ ...toast, isOpen: false })}
        message={toast.message}
        color={toast.color}
        duration={2000}
      />{' '}
    </>
  )
}

export default CreateSubscriptionModal
