import { IonContent, IonPage } from '@ionic/react'
import CommonHeader from '../commonComponents/CommonHeader'
import StandardContainer from '../commonComponents/StandardContainer'
import DetailsHistory from './detailsHistory'
import { useContext } from 'react'
import { AuthContext } from '../auth'

export default function CustomerHistory({ match }) {
  const customer_id = match?.params?.id
  const { businessData } = useContext(AuthContext)
  return (
    <IonPage id='main-content'>
      <CommonHeader title={'History'} backIcon={true}></CommonHeader>
      <IonContent>
        <StandardContainer>
          <DetailsHistory businessId={businessData?._id} customer_id={customer_id} />
        </StandardContainer>
      </IonContent>
    </IonPage>
  )
}
