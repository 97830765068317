import { useState } from 'react'
import { IonModal, IonContent, IonButton, IonFooter, IonProgressBar, IonToolbar, IonIcon, IonButtons, IonToast } from '@ionic/react'
import { closeOutline } from 'ionicons/icons'
import LocationNameStep from './locationNameStep'
import BusinessTypeStep from './businessTypeStep'
import LocationAddressStep from './locationAddressStep'
import updateDisabledState from './updateDisabledState'
import CorporateRelationshipStep from './corporateRelationship'
import GetStartedStep from './getStartedStep'
import axios from 'axios'

const steps = [
  { id: 1, title: '', component: GetStartedStep },
  { id: 2, title: 'Name the Location', component: LocationNameStep },
  { id: 3, title: 'Select the business type', component: BusinessTypeStep },
  { id: 4, title: "What is your location's address?", component: LocationAddressStep },
  { id: 5, title: "What is this location's relationship to the business?", component: CorporateRelationshipStep },
]

export default function CreateLocationDialog({ open, handleClose, businessId }) {
  const [isDisabled, setIsDisabled] = useState(true)
  const [currentStep, setCurrentStep] = useState(0)
  const [formData, setFormData] = useState({
    name: '',
    businessType: '',
    addressStreet1: '',
    addressStreet2: '',
    addressCity: '',
    addressState: '',
    addressZipcode: '',
    relationshipToBusiness: '',
  })
  const [toast, setToast] = useState({ isOpen: false, message: '', color: '' })
  const [isSubmitting, setIsSubmitting] = useState(false)

  const showToastMessage = (message, color) => {
    setToast({ isOpen: true, message, color })
  }

  const updateFormData = (field, value) => {
    setFormData((prev) => ({ ...prev, [field]: value }))
    setIsDisabled(updateDisabledState(formData))
  }

  const handleNextStep = () => {
    if (!isCurrentStepValid(currentStep, formData)) {
      showToastMessage('Oops, looks like you missed something! 🧐', 'danger')
      return // Stop here if validation fails
    }

    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1)
    }
  }

  const handlePreviousStep = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1)
    }
  }

  const close = () => {
    setFormData({
      name: '',
      businessType: '',
      addressStreet1: '',
      addressStreet2: '',
      addressCity: '',
      addressState: '',
      addressZipcode: '',
      relationshipToBusiness: '',
    })
    setCurrentStep(0)
    handleClose()
  }

  const isCurrentStepValid = (step, data) => {
    switch (step) {
      case 0:
        return true // Get Started step is always valid
      case 1:
        return data.name.trim() !== ''
      case 2:
        return data.businessType !== ''
      case 3:
        return data.addressStreet1.trim() !== '' && data.addressCity.trim() !== '' && data.addressState.trim() !== '' && data.addressZipcode.trim() !== ''
      case 4:
        return data.relationshipToBusiness !== ''
      default:
        return false
    }
  }

  const CurrentStepComponent = steps[currentStep].component

  const handleSubmit = async () => {
    if (!isCurrentStepValid(currentStep, formData)) {
      showToastMessage('You gotta fill in all the details before submitting! 😅', 'danger')
      return
    }

    setIsSubmitting(true)
    try {
      const response = await axios.post(`/location_v2`, { ...formData, businessId })
      console.log('Location saved successfully:', response.data)
      showToastMessage('Location saved successfully! 🎉', 'success')
      close()
    } catch (error) {
      console.error('Failed to save location:', error)
      showToastMessage('There was an issue saving the location. Please try again. 🙁', 'danger')
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <IonModal isOpen={open}>
      <IonContent>
        <IonToolbar color='white' style={{ backgroundColor: 'white', color: 'black' }}>
          <IonButtons slot='start'>
            {currentStep > 1 ? (
              <IonButton
                onClick={close}
                style={{ border: '1px solid #d6d6d6', borderRadius: 20, padding: '2px 10px', fontSize: 14, marginTop: 20, fontWeight: '600', marginLeft: 10 }}
              >
                Save & exit
              </IonButton>
            ) : (
              <IonButton onClick={close} style={{ marginTop: 20 }}>
                <IonIcon icon={closeOutline} />
              </IonButton>
            )}
          </IonButtons>
        </IonToolbar>
        <h1 style={{ paddingLeft: 24, marginTop: 20, fontSize: '1.6rem', whiteSpace: 'normal', overflowWrap: 'break-word' }}>{steps[currentStep].title}</h1>
        <CurrentStepComponent formData={formData} updateFormData={updateFormData} />
      </IonContent>
      <IonFooter>
        {currentStep !== 0 && <IonProgressBar value={(currentStep + 1) / steps.length} color='dark' />}
        <IonToolbar>
          <div
            style={{
              display: currentStep !== 0 ? 'flex' : null,
              justifyContent: 'space-between',
              padding: '1rem',
            }}
          >
            {currentStep > 0 && (
              <IonButton expand='block' onClick={handlePreviousStep} color={'dark'} fill='clear' style={{ textDecoration: 'underline' }}>
                Back
              </IonButton>
            )}
            {currentStep === 0 && <div></div>}
            {currentStep < steps.length - 1 ? (
              <IonButton expand='block' onClick={handleNextStep} disabled={!isCurrentStepValid(currentStep, formData)} color={'dark'}>
                {currentStep !== 0 ? 'Next' : 'Get Started'}
              </IonButton>
            ) : (
              <IonButton
                expand='block'
                onClick={handleSubmit}
                disabled={!isCurrentStepValid(currentStep, formData) || isDisabled || isSubmitting}
                color={'dark'}
              >
                {isSubmitting ? 'Submitting...' : 'Submit'}
              </IonButton>
            )}
          </div>
        </IonToolbar>
      </IonFooter>
      <IonToast
        position='top'
        isOpen={toast.isOpen}
        onDidDismiss={() => setToast({ ...toast, isOpen: false })}
        message={toast.message}
        color={toast.color}
        duration={2000}
      />
    </IonModal>
  )
}
