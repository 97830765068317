const allMonthNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]
const getLastMonths = noOfMonths => {
  const currentMonthIndex = new Date().getMonth() // Get the current month index
  const currentYear = new Date().getFullYear() // Get the current year

  if (noOfMonths == 0) {
    const currentMonthDate = new Date(currentYear, currentMonthIndex, 1)
    const month = currentMonthDate.toLocaleString('default', { month: 'short' })
    const year = currentMonthDate.getFullYear()
    const lastTwoDigitsOfYear = year % 100
    const value = [`${month}-${lastTwoDigitsOfYear}`]

    return value
  }
  if (noOfMonths == 1) {
    const prevMonthDate = new Date(currentYear, currentMonthIndex - 1, 1)
    const month = prevMonthDate.toLocaleString('default', { month: 'short' })
    const year = prevMonthDate.getFullYear()
    const lastTwoDigitsOfYear = year % 100
    const value = [`${month}-${lastTwoDigitsOfYear}`]

    return value
  }

  const allMonths = []

  for (let i = noOfMonths - 1; i >= 0; i--) {
    const tempDate = new Date(currentYear, currentMonthIndex - i, 1)
    const month = tempDate.toLocaleString('default', { month: 'short' })
    const year = tempDate.getFullYear()
    const lastTwoDigitsOfYear = year % 100
    allMonths.push(`${month}-${lastTwoDigitsOfYear}`)
  }

  return allMonths
}

const getMonthNumbers = noOfMonths => {
  const currentMonthIndex = new Date().getMonth()
  const currentYear = new Date().getFullYear()

  if (noOfMonths === 1) {
    const prevMonthDate = new Date(currentYear, currentMonthIndex - 1, 1)
    const month = String(prevMonthDate.getMonth() + 1).padStart(2, '0')
    const year = prevMonthDate.getFullYear()
    const lastTwoDigitsOfYear = year % 100

    return [`${month}-${lastTwoDigitsOfYear}`]
  }

  if (noOfMonths === 0) {
    const currentMonthDate = new Date(currentYear, currentMonthIndex, 1)
    const month = String(currentMonthDate.getMonth() + 1).padStart(2, '0')
    const year = currentMonthDate.getFullYear()
    const lastTwoDigitsOfYear = year % 100

    return [`${month}-${lastTwoDigitsOfYear}`]
  }

  const allMonths = []

  for (let i = noOfMonths - 1; i >= 0; i--) {
    const tempDate = new Date(currentYear, currentMonthIndex - i, 1)
    const month = String(tempDate.getMonth() + 1).padStart(2, '0')
    const year = tempDate.getFullYear()
    const lastTwoDigitsOfYear = year % 100
    allMonths.push(`${month}-${lastTwoDigitsOfYear}`)
  }

  return allMonths
}

// Usage example
export const GetXAxisMonths = noOfMonths => {
  const selectedMonths = noOfMonths // Replace this with your selected value

  const categories = {
    categories: getLastMonths(selectedMonths),
    monthNumbers: getMonthNumbers(selectedMonths),
  }

  return categories
}
