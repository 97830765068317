import React, { useState } from 'react'
import LifeTimeValueOfCustomer from './LifeTimeValueOfCustomer'
import SubscriberConversion from './SubscriberConversion'
import { IonContent, IonPage } from '@ionic/react'
import CommonHeader from '../commonComponents/CommonHeader'
import ConversionFunnel from './ConversionFunnel'
import StandardContainer from '../commonComponents/StandardContainer'
import ImpressionsByRefId from './ImpressionsByRefId'

export default function ReportDetail({ match }) {
  const [title, setTitle] = useState('')
  const refId = match?.params?.id
  const updateTitle = title => {
    setTitle(title)
  }
  return (
    <>
      <IonPage>
        <CommonHeader backIcon={true} title={title} />
        <IonContent>
          <StandardContainer>
            <ConversionFunnel updateTitle={updateTitle} refId={refId} />
            <LifeTimeValueOfCustomer updateTitle={updateTitle} refId={refId} />
            <SubscriberConversion updateTitle={updateTitle} refId={refId} />
            <ImpressionsByRefId updateTitle={updateTitle} refId={refId} />
          </StandardContainer>
        </IonContent>
      </IonPage>
    </>
  )
}
