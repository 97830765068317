import { IonButton, IonContent, IonIcon, IonInput, IonItem, IonLabel, IonList, IonPage, IonRow, IonSpinner, IonTextarea, IonToast } from '@ionic/react'
import { useEffect, useState } from 'react'
import CommonHeader from '../commonComponents/CommonHeader'
import StandardContainer from '../commonComponents/StandardContainer'
import axios from 'axios'
import { add, checkmark, pencil } from 'ionicons/icons'
import { GetOrdinalText } from '../Utils/GetOrdinalText'
import ListView from './ListView'
import ChooseAudience from './ChooseAudience'
import { useHistory } from 'react-router'
import CheckEmailRemarketingRuleExists from '../Utils/CheckEmailRemarketingRuleExists'
import { colors } from '../theme/colors'

export default function DetailView({ match }) {
  const [loading, setLoading] = useState(true)
  const [remarketingData, setRemarketingData] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const [loadingRuleName, setLoadingRuleName] = useState(false)
  const [remarketingTempData, setRemarketingTempData] = useState(false)
  const [ruleName, setRuleName] = useState('')
  const [ruleNameTemp, setRuleNameTemp] = useState('')
  const [deleteModalVisible, setDeleteModalVisible] = useState(false)
  const [deletingData, setDeletingData] = useState(false)
  const [addingStep, setAddingStep] = useState(false)
  const [errorMessage, setErrorMessage] = useState(false)
  const [toast, setToast] = useState({ isOpen: false, message: '', color: '' })

  let remarketingId = match?.params?.id
  const history = useHistory()
  const showToastMessage = (message, color) => {
    setToast({ isOpen: true, message, color })
  }
  const updateRemarketingRules = async (input) => {
    try {
      const updateRemarketingResponse = await axios.patch(`/emailRemarketing/rule/?id=${remarketingTempData._id}`, input)
      if (updateRemarketingResponse.status === 200) {
        refreshRemarketingDetails()
        setLoadingRuleName(false)
        setAddingStep(false)
        setEditMode(false)
        showToastMessage('Remarketing rule updated successfully!', 'success')
      }
    } catch (error) {
      setLoadingRuleName(false)
      showToastMessage('Error updating remarketing rule.', 'danger')
      console.error('Error updating remarketing rules:', error)
    }
  }

  const addEmailToRule = () => {
    setAddingStep(true)
    const emailNumber = remarketingTempData.emailSteps.length + 1

    const emailName = GetOrdinalText(emailNumber) + ' email'
    const newEmailObject = {
      id: emailNumber,
      name: emailName,
      followUpDays: false,
    }

    const newRules = [...remarketingTempData.emailSteps, newEmailObject]

    setRemarketingTempData((prevData) => ({
      ...prevData,
      emailSteps: newRules,
    }))
    const remarketingInput = {
      smtpSettingsId: remarketingTempData.smtpSettingsId,
      ruleName: ruleName,
      audiences: remarketingTempData.audiences,
      emailSteps: newRules,
    }

    updateRemarketingRules(remarketingInput)
  }
  const handleDeleteModal = (value) => {
    setDeleteModalVisible(value)
  }
  const handleEditClick = () => {
    setEditMode(true)
  }
  const handleRuleNameChange = async (e) => {
    const ruleNameValue = e.target.value
    setRuleName(ruleNameValue)
    if (ruleNameTemp !== ruleNameValue) {
      try {
        const ruleExistsResponse = await CheckEmailRemarketingRuleExists(ruleNameValue)
        const validationMessage = ruleExistsResponse.ruleExists ? 'Rule Name Already In Use' : false
        setErrorMessage(validationMessage)
      } catch (error) {
        showToastMessage('Error checking rule name.', 'danger')
      }
    }
  }

  const handleSaveRuleName = async () => {
    setLoadingRuleName(true)

    const remarketingInput = {
      smtpSettingsId: remarketingTempData.smtpSettingsId,
      ruleName: ruleName,
      audiences: remarketingTempData.audiences,
      emailSteps: remarketingTempData.emailSteps,
    }
    await updateRemarketingRules(remarketingInput)
  }
  const getRemarketingDetails = async () => {
    try {
      let response = await axios.get(`/emailRemarketing/rule?id=${remarketingId}`)

      if (response.status === 200) {
        setRemarketingData(response.data)
        setRemarketingTempData(response.data)
        setRuleName(response?.data?.ruleName)
        setRuleNameTemp(response?.data?.ruleName)
        setLoading(false)
      }
    } catch (error) {
      showToastMessage('Error fetching remarketing details.', 'danger')
      console.error('Error fetching remarketing details:', error)
    }
  }
  const handleDelete = async () => {
    setDeletingData(true)
    try {
      const deleteResponse = await axios.delete(`/emailRemarketing/rule/?id=${remarketingData._id}`)
      if (deleteResponse.status === 200) {
        setDeletingData(false)
        handleDeleteModal(false)
        history.goBack()
        showToastMessage('Remarketing rule deleted successfully!', 'success')
      }
    } catch (error) {
      setDeletingData(false)
      showToastMessage('Error deleting remarketing rule.', 'danger')
      console.error('Error deleting remarketing rule:', error)
    }
  }
  const refreshRemarketingDetails = () => {
    getRemarketingDetails()
  }
  useEffect(() => {
    getRemarketingDetails()
  }, [remarketingId])
  return (
    <IonPage id='main-content'>
      <CommonHeader
        title={'Rule'}
        handleDeleteModal={handleDeleteModal}
        deleteModalVisible={deleteModalVisible}
        deleteButton={true}
        deleteMessage={'Are you sure you want to delete this rule?'}
        deleteTitle={'Delete Rule'}
        handleDelete={handleDelete}
        deletingData={deletingData}
        backIcon={true}
      />
      <IonContent>
        <StandardContainer>
          <div style={{ padding: '0 1rem 1rem 1rem' }}>
            {loading && (
              <IonRow style={{ marginTop: '1rem', display: 'flex', justifyContent: 'center' }}>
                <IonSpinner name='dots' />
              </IonRow>
            )}

            {!loading && remarketingTempData && (
              <>
                {editMode ? (
                  <>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginTop: '1rem',
                      }}
                    >
                      <IonList style={{ width: '85%' }} mode='ios'>
                        <IonItem>
                          <IonTextarea
                            style={{ fontSize: '1.2rem', lineHeight: '1.5rem', fontWeight: 'bold' }}
                            value={ruleName}
                            autoGrow={true}
                            onIonChange={handleRuleNameChange}
                          />
                        </IonItem>
                      </IonList>

                      {loadingRuleName && <IonSpinner name='lines' />}
                      {!loadingRuleName && !errorMessage && (
                        <IonIcon onClick={handleSaveRuleName} icon={checkmark} color='primary' style={{ fontSize: '2rem' }} />
                      )}
                    </div>
                    {errorMessage && (
                      <div>
                        <IonLabel style={{ color: colors.errorText, fontSize: '1rem' }}>{errorMessage}</IonLabel>
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    <h2 style={{ fontWeight: 'bold' }}>
                      {ruleName}
                      <IonIcon color='primary' icon={pencil} onClick={handleEditClick} style={{ cursor: 'pointer', marginLeft: '1rem' }} />
                    </h2>
                  </>
                )}
                <IonList inset={true} style={{ margin: '1rem 0' }} mode='ios'>
                  <ChooseAudience
                    smtpSettingsId={remarketingTempData?.smtpSettingsId}
                    ruleName={ruleName}
                    emailSteps={remarketingTempData?.emailSteps}
                    id={remarketingTempData?._id}
                    audiencesList={remarketingTempData?.audiences}
                    refreshRemarketingDetails={refreshRemarketingDetails}
                  />
                </IonList>
                <ListView
                  refreshRemarketingDetails={refreshRemarketingDetails}
                  id={remarketingTempData?._id}
                  smtpSettingsId={remarketingTempData?.smtpSettingsId}
                  ruleName={remarketingTempData?.ruleName}
                  audiences={remarketingTempData?.audiences}
                  emailSteps={remarketingTempData?.emailSteps}
                />
                {addingStep && <IonSpinner name='dots' />}
                {!addingStep && (
                  <IonRow style={{ display: 'flex', alignItems: 'center' }} onClick={addEmailToRule}>
                    <IonIcon size='small' color='primary' icon={add} />
                    <IonLabel color='primary'>Add an Email</IonLabel>
                  </IonRow>
                )}
              </>
            )}
            <IonToast
              position='top'
              isOpen={toast.isOpen}
              onDidDismiss={() => setToast({ ...toast, isOpen: false })}
              message={toast.message}
              color={toast.color}
              duration={2000}
            />
          </div>
        </StandardContainer>
      </IonContent>
    </IonPage>
  )
}
