import {
  IonButton,
  IonButtons,
  IonCard,
  IonChip,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonLabel,
  IonModal,
  IonRow,
  IonSpinner,
  IonText,
  IonTitle,
  IonToolbar,
} from '@ionic/react'
import StandardContainer from '../../commonComponents/StandardContainer'
import RefundTransaction from './RefundTransaction'
import { useEffect, useState } from 'react'
import CommonHeader from '../../commonComponents/CommonHeader'
import axios from 'axios'

export default function PaymentDetails({ customer_id, closePaymentDetailModal, selectedPayment, refreshPaymentHistory }) {
  const formattedAmount = `$${(selectedPayment.amount / 100).toFixed(2)}`
  const [refundModalOpen, setRefundModal] = useState(false)
  const [paymentTransactionDetails, setPaymentTransactionDetails] = useState(false)
  const [loading, setLoading] = useState(false)
  const columns = [
    {
      label: 'Statement Descriptor',
      field: 'statement_descriptor',
    },
    { label: 'Amount', field: 'amount' },
    { label: 'Status', field: 'status' },
    { label: 'Description', field: 'description' },
    { label: 'Id', field: 'id' },
  ]
  const openRefundModal = () => {
    setRefundModal(true)
  }
  const closeRefundModal = () => {
    getTransactionDetails()
    setRefundModal(false)
  }
  const getStatusLabel = (status) => {
    switch (status) {
      case 'succeeded':
        return 'Success'
      case 'pending':
        return 'Pending'
      case 'failed':
        return 'Failed'
      case 'requires_payment_method':
        return 'Req Payment Method'
      case 'requires_confirmation':
        return 'Req Confirmation'
      case 'requires_action':
        return 'Req Action'
      case 'canceled':
        return 'Cancelled'
      case 'refunded':
        return 'Refunded'
      // Add more cases as needed
      default:
        return status
    }
  }
  const renderColumnValue = (column) => {
    const { field } = column
    const fieldValue = selectedPayment[field]

    switch (field) {
      case 'amount':
        // Render formatted amount for the 'amount' field
        return formattedAmount

      case 'status':
        // Check if status is not 'succeeded'
        if (fieldValue !== 'succeeded') {
          // Check if there are payment errors
          const paymentErrors = selectedPayment?.last_payment_error || selectedPayment?.review

          // Get the error message or use 'Unknown Error' if not available
          const errorMessage = paymentErrors?.message || ''

          // Get the cancellation reason
          const cancellationReason = selectedPayment?.cancellation_reason

          // Create the formatted status message without the "Cancellation Reason" label if not available
          const statusMessage = `${getStatusLabel(fieldValue)}: ${errorMessage}`

          // Add cancellation reason to the message if available
          return cancellationReason ? `${statusMessage} (Cancellation Reason: ${cancellationReason})` : statusMessage
        }

        // Return formatted status for 'succeeded' status
        return getStatusLabel(fieldValue)

      default:
        // Return field value for other fields
        return fieldValue
    }
  }
  const getTransactionDetails = async () => {
    setLoading(true) // Start loading state
    try {
      let response = await axios.get(`/customer_v2/transaction/details?paymentIntentId=${selectedPayment.id}`)
      setPaymentTransactionDetails(response?.data?.[0])
    } catch (error) {
      console.error('Error fetching transaction details:', error) // Log the error for debugging
    } finally {
      setLoading(false) // Ensure loading is set to false in the finally block
    }
  }

  useEffect(() => {
    getTransactionDetails()
  }, [selectedPayment])

  const totalRefunded = `$${(paymentTransactionDetails.totalRefunded / 100).toFixed(2)}`


  return (
    <>
      <IonHeader>
        <IonToolbar
          color='white'
          style={{
            backgroundColor: 'white',
            color: 'black',
          }}
        >
          <IonTitle>Payment Details</IonTitle>
          <IonButtons slot='end'>
            <IonButton color={'primary'} onClick={closePaymentDetailModal}>
              Close
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className='regularBackground'>
        <StandardContainer>

          {loading && (
            <IonButtons style={{ margin: '1rem', display: 'flex', justifyContent: 'end' }}>
              <IonSpinner name='dots' />
            </IonButtons>
          )}
          {!loading && (
            <IonCard style={{ padding: '1rem' }}>
              <IonGrid>
                <IonRow style={{ display: 'flex', alignItems: 'center', margin: '1rem 0' }}>
                  <IonLabel style={{ fontSize: '2rem', fontWeight: 'bold' }}>
                    <strong>{formattedAmount}</strong>
                    <span style={{ color: '#0066cc', fontSize: '1.3rem' }}>{paymentTransactionDetails.status == 'refunded' && `(${totalRefunded})`}</span>
                  </IonLabel>
                  <IonText style={{ fontSize: '1.7rem', margin: '0 0.5rem', color: 'grey' }}>USD</IonText>
                  <div style={{ marginLeft: '1rem' }}>

                    {paymentTransactionDetails?.status == 'succeeded' ? (
                      <IonChip
                        style={{
                          backgroundColor: '#bdffbd',
                          color: '#007a00',
                        }}
                      >
                        <IonLabel>{getStatusLabel(paymentTransactionDetails?.status)}</IonLabel>
                      </IonChip>
                    ) : paymentTransactionDetails?.status == 'refunded' ? (
                      <IonChip
                        style={{
                          backgroundColor: '#e6f2ff',
                          color: '#0066cc',
                        }}
                      >
                        <IonLabel>{getStatusLabel(paymentTransactionDetails?.status)}</IonLabel>
                      </IonChip>
                    ) : (
                      <IonChip
                        style={{
                          backgroundColor: '#ffcaca',
                          color: '#ca0000',
                        }}
                      >
                        <IonLabel>{getStatusLabel(paymentTransactionDetails?.status)}</IonLabel>
                      </IonChip>
                    )}
                  </div>
                </IonRow>
                { }
                <IonRow style={{ marginTop: '2rem' }}>
                  <IonLabel style={{ fontSize: '1.3rem', fontWeight: 'bold' }}>Payment Details</IonLabel>
                </IonRow>
                <IonGrid style={{ fontSize: '1rem', lineHeight: '1.5rem', color: '#5e5e5e' }}>
                  {columns.map((column, index) => {
                    return (
                      paymentTransactionDetails[column.field] && (
                        <IonRow
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}
                        >
                          <IonCol size='4'>
                            <IonLabel>{column.label}</IonLabel>
                          </IonCol>
                          <IonCol size='8'>
                            <IonLabel>{renderColumnValue(column)}</IonLabel>
                          </IonCol>
                        </IonRow>
                      )
                    )
                  })}
                </IonGrid>
              </IonGrid>
            </IonCard>
          )}
          {loading && (
            <IonButtons style={{ margin: '1rem', display: 'flex', justifyContent: 'end' }}>
              <IonSpinner name='dots' />
            </IonButtons>
          )}
          {!loading && (
            <>
              {paymentTransactionDetails?.status == 'refunded' ? (
                <IonRow style={{ margin: '1rem', display: 'flex', justifyContent: 'end' }}>
                  <IonLabel>Refunded </IonLabel>
                </IonRow>
              ) : (
                <IonButtons style={{ margin: '1rem', display: 'flex', justifyContent: 'end' }}>
                  <IonButton onClick={openRefundModal}>Refund transaction</IonButton>
                </IonButtons>
              )}
            </>
          )}
          <IonModal onDidDismiss={closeRefundModal} isOpen={refundModalOpen}>
            <CommonHeader title={'Refund A Transaction'} closeButton={true} closeModal={closeRefundModal} />
            <IonContent>
              <StandardContainer>
                {selectedPayment.status == 'succeeded' && (
                  <RefundTransaction closeRefundModal={closeRefundModal} customer_id={customer_id} paymentDetails={selectedPayment} refreshPaymentHistory={refreshPaymentHistory} />
                )}
              </StandardContainer>
            </IonContent>
          </IonModal>
        </StandardContainer>
      </IonContent>
    </>
  )
}
