import { IonContent, IonPage } from '@ionic/react'
import { useState } from 'react'
import CommonHeader from '../../commonComponents/CommonHeader'
import StandardContainer from '../../commonComponents/StandardContainer'
import CreditDetailPage from './CreditDetailPage'
export default function CustomerCreditBalance({ match }) {
  const customerId = match?.params?.id
  const [loading, setLoading] = useState(false)
  const updateLoader = value => {
    setLoading(value)
  }

  return (
    <IonPage id='main-content'>
      <CommonHeader progressBar={loading} title={'Credit Balance'} backIcon={true}></CommonHeader>
      <IonContent>
        <StandardContainer>
          <CreditDetailPage updateLoader={updateLoader} customerId={customerId} />
        </StandardContainer>
      </IonContent>
    </IonPage>
  )
}
