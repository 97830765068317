import { IonCard, IonGrid, IonItem, IonLabel, IonRow } from '@ionic/react'
import React from 'react'
import { colors } from '../theme/colors'

export default function Suggestions({ suggestionsData, handleSelection }) {
  return (
    <IonGrid>
      <IonRow style={{ width: '100%' }}>
        <IonCard
          style={{
            width: '100%',
            margin: 0,
            padding: '1rem 0',
            maxHeight: '17rem',
            overflowY: 'scroll',
          }}
        >
          <h6 style={{ margin: '1.2rem' }}>Map Locations</h6>
          {suggestionsData.map((item, index) => {
            return (
              <IonItem
                style={{
                  margin: '10px 0',
                  display: 'flex',
                  justifyContent: 'start',
                  alignItems: 'start',
                }}
                key={index}
                onClick={() => handleSelection(item)}
              >
                <IonGrid>
                  <IonRow>
                    <IonLabel
                      style={{
                        display: 'flex',
                        justify: 'center',
                        alignItems: 'center',
                        margin: 0,
                      }}
                    >
                      {item.name}
                    </IonLabel>
                  </IonRow>
                  <IonRow>
                    <IonLabel
                      style={{
                        display: 'flex',
                        justify: 'center',
                        alignItems: 'center',
                        margin: 0,
                        fontSize: '12px',
                        color: colors.grey,
                      }}
                    >
                      {item.formatted_address}
                    </IonLabel>
                  </IonRow>
                </IonGrid>
              </IonItem>
            )
          })}
        </IonCard>
      </IonRow>
    </IonGrid>
  )
}
