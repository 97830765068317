import { useState, useEffect, useContext } from 'react'
import axios from 'axios'
import { IonButton, IonCol, IonContent, IonGrid, IonIcon, IonItem, IonLabel, IonList, IonModal, IonReorder, IonReorderGroup, IonRow } from '@ionic/react'
import StandardContainer from '../commonComponents/StandardContainer'
import { convertMonthNumberToText } from '../utils'
import { AuthContext } from '../auth'
import StatItems from './StatItems'
import { addCircle, ellipsisVertical, removeCircle } from 'ionicons/icons'
import CommonHeader from '../commonComponents/CommonHeader'
import MiniHeader from '../commonComponents/MiniHeader'
import { colors } from '../theme/colors'
import { chartItems } from '../StaticData/StaticData'

export default function LocationStats() {
  const { businessData, locationId } = useContext(AuthContext)
  const [showModal, setShowModal] = useState(false)
  const [selectedChartItems, setSelectedChartItems] = useState([])
  const removeAll = () => {
    setSelectedChartItems([])
  }
  const includeAll = () => {
    setSelectedChartItems(chartItems)
  }
  const handleToggleItem = (item) => {
    // Copy the current selectedChartItems into a new variable
    let updatedSelectedItems = [...selectedChartItems]

    // Check if the item is already selected
    const exists = updatedSelectedItems.some((selected) => selected === item)

    // If the item exists, remove it; otherwise, add it to the list
    if (exists) {
      updatedSelectedItems = updatedSelectedItems.filter((selected) => selected !== item) // Deselect
    } else {
      updatedSelectedItems.push(item) // Select
    }

    // Update the state with the new list of selected items
    setSelectedChartItems(updatedSelectedItems || [])

    // Save the updated list to localStorage
    localStorage.setItem('selectedChartItems', JSON.stringify(updatedSelectedItems))
  }

  // Function to handle the reordering of selected items
  // Function to handle the reordering of selected items
  const handleReorder = (event) => {
    // Make sure the event.detail.from and event.detail.to are defined and valid
    const fromIndex = event.detail.from
    const toIndex = event.detail.to

    if (fromIndex === undefined || toIndex === undefined) {
      console.error('Invalid reorder indices:', fromIndex, toIndex)
      event.detail.complete()
      return
    }

    const reorderedItems = [...selectedChartItems]

    // Remove the item at the 'from' index and store it
    const movedItem = reorderedItems.splice(fromIndex, 1)[0]

    // Insert the item at the 'to' index
    reorderedItems.splice(toIndex, 0, movedItem)

    // Debug log to see the new order

    // Update the state with the new order
    setSelectedChartItems(reorderedItems)

    // Persist the new order to localStorage
    localStorage.setItem('selectedChartItems', JSON.stringify(reorderedItems))

    // Complete the reorder action
    event.detail.complete()
  }

  useEffect(() => {
    // Load selected chartItems from localStorage
    const savedItems = JSON.parse(localStorage.getItem('selectedChartItems')) || chartItems.filter((item) => item != 'Taxes')
    setSelectedChartItems(savedItems)
  }, [showModal])
  if (!businessData?._id) return 'location null...'
  let { month, year } = getCurrentMonthAndYear()
  // Separate selected and unselected items
  const selectedItems = selectedChartItems.map((selectedItem) => chartItems.find((item) => item === selectedItem)).filter((item) => item !== undefined)
  const unselectedItems = chartItems.filter((item) => !selectedChartItems.includes(item))

  return (
    <StandardContainer>
      <IonGrid style={{ display: 'flex', justifyContent: 'end', marginTop: 20 }}>
        <IonRow>
          <IonIcon icon={ellipsisVertical} onClick={() => setShowModal(true)} style={{ cursor: 'pointer' }} />
        </IonRow>
      </IonGrid>
      <IonModal isOpen={showModal} onDidDismiss={() => setShowModal(false)}>
        <IonContent>
          <StandardContainer>
            <CommonHeader title={'Charts'} closeButton={true} closeModal={() => setShowModal(false)} />
            <IonGrid>
              <IonRow>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
                  <IonButton
                    fill='clear'
                    style={{ display: 'flex', justifyContent: 'end' }}
                    onClick={() => (selectedItems.length == chartItems.length ? removeAll() : includeAll())}
                    color={selectedItems.length == chartItems.length ? 'danger' : 'success'}
                  >
                    <IonLabel>{selectedItems.length == chartItems.length ? 'Remove All' : 'Include All'}</IonLabel>
                  </IonButton>
                </div>
                <IonCol size='12'>
                  <IonList>
                    {selectedItems?.length > 0 && (
                      <div style={{ marginBottom: 20, marginTop: 10 }}>
                        <MiniHeader fontSize={14} title={'Include'} />
                      </div>
                    )}
                    {/* Display selected items with a minus icon */}
                    <IonReorderGroup disabled={false} onIonItemReorder={handleReorder}>
                      {selectedItems.map((item, index) => (
                        <IonItem key={item} style={{ cursor: 'pointer' }}>
                          <IonReorder slot='end' />
                          <IonIcon onClick={() => handleToggleItem(item)} style={{ color: 'red', cursor: 'pointer' }} icon={removeCircle} slot='start' />
                          <IonLabel>{item}</IonLabel>
                        </IonItem>
                      ))}
                    </IonReorderGroup>
                    {unselectedItems?.length > 0 && (
                      <div style={{ margin: '20px 0' }}>
                        <MiniHeader fontSize={14} title={'Other Charts'} />
                      </div>
                    )}
                    {/* Display unselected items with a plus icon */}
                    {unselectedItems.map((item) => (
                      <IonItem style={{ cursor: 'pointer' }} onClick={() => handleToggleItem(item)} key={item}>
                        <IonIcon style={{ color: colors.green, cursor: 'pointer' }} icon={addCircle} slot='start' />
                        <IonLabel style={{ cursor: 'pointer' }}>{item}</IonLabel>
                      </IonItem>
                    ))}
                  </IonList>
                </IonCol>
              </IonRow>
            </IonGrid>
          </StandardContainer>
        </IonContent>
      </IonModal>
      <h4 style={{ padding: 16, paddingBottom: 0 }}>{getCurrentMonth()} Stats</h4>
      <StatsBlock selectedChartItems={selectedChartItems} businessId={businessData?._id} month={month} year={year} />
      <Historical businessId={businessData?._id} />
    </StandardContainer>
  )
}

function getCurrentMonth() {
  const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

  const currentDate = new Date()
  const currentMonth = currentDate.getMonth()

  return months[currentMonth]
}

function getCurrentMonthAndYear() {
  const currentDate = new Date()
  const currentMonth = currentDate.getMonth() + 1 // Months are zero-based, so we add 1
  const currentYear = currentDate.getFullYear()

  return { month: currentMonth, year: currentYear }
}

function StatsBlock({ selectedChartItems, businessId, month, year }) {
  const [stats, setStats] = useState()
  const { businessData, locationId } = useContext(AuthContext)

  const [subscriberAverage, setSubscriberAverage] = useState(0)

  async function getStats() {
    try {
      let r = await axios.get(`/location_v2/stats?businessId=${businessData?._id}&month=${month}&year=${year}`)
      setStats(r.data)
    } catch (error) {}
  }
  useEffect(() => {
    getStats()
  }, [businessData, locationId])

  useEffect(() => {
    const interval = setInterval(() => {
      getStats()
    }, 60000)
    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    let totalAppointments = 0
    let totalSubscribers = 0

    for (let s of stats?.subscriberAppointments || []) {
      totalAppointments += s.totalAppointments
      totalSubscribers += s.countOfSubscribers * (s.numberOfPeopleInPackage || 1)
    }
    console.log(totalAppointments, totalSubscribers, 'totalAppointments,totalSubscribers')
    setSubscriberAverage(totalAppointments / totalSubscribers || 0)
  }, [stats?.subscriberAppointments])
  console.log(subscriberAverage, 'subscriberAveragesubscriberAverage')
  return (
    <>
      <StatItems selectedChartItems={selectedChartItems} month={month} year={year} stats={stats} subscriberAverage={subscriberAverage} />
    </>
  )
}

function Historical({ businessId }) {
  const { selectedLocationData } = useContext(AuthContext)

  const hist = generateMonthYearArray(selectedLocationData?.createdDate || 1680347469000, true)

  return (
    <IonList mode='ios' inset={true}>
      {hist?.map((i) => (
        <>
          <IonItem routerLink={`/core/stats?month=${i.month}&year=${i.year}`} detail={true}>
            <IonLabel>
              <strong>
                {convertMonthNumberToText(i.month)} {i.year}
              </strong>
            </IonLabel>
          </IonItem>
        </>
      ))}
    </IonList>
  )
}

function generateMonthYearArray(startDate, excludeCurrentMonth = false) {
  const currentDate = new Date()
  const start = new Date(startDate)
  const monthYearArray = []

  while (currentDate > start) {
    const month = start.getMonth() + 1 // Months are zero-based, so we add 1
    const year = start.getFullYear()

    if (!(excludeCurrentMonth && currentDate.getMonth() === start.getMonth() && currentDate.getFullYear() === start.getFullYear())) {
      monthYearArray.push({ month, year })
    }

    // Move to the previous month
    start.setMonth(start.getMonth() + 1)
  }

  return monthYearArray.reverse()
}
