import { IonButton, IonCard, IonCol, IonContent, IonInput, IonLabel, IonModal, IonRow, IonSpinner, IonToast } from '@ionic/react'
import { useEffect, useState } from 'react'
import spacingStyles from '../../theme/spacing.module.css'
import CommonHeader from '../../commonComponents/CommonHeader'
import StandardContainer from '../../commonComponents/StandardContainer'
import axios from 'axios'

export default function AddCashTip({ appointment, refreshAppointment, textcolor }) {
  const [cashTipModalOpen, setCashTipModalOpen] = useState(false)
  const [tip, setTip] = useState(appointment?.payment?.cashTipAmount)
  const [updatingTip, setUpdatingTip] = useState(false)
  const [toast, setToast] = useState({ isOpen: false, message: '', color: '' })

  const openCashTipModal = () => {
    setCashTipModalOpen(true)
  }

  const closeCashTipModal = () => {
    setCashTipModalOpen(false)
  }

  const handleTip = (value) => {
    setTip(value)
  }

  useEffect(() => {
    setTip(
      appointment?.payment?.cashTipAmount !== undefined && appointment?.payment?.cashTipAmount !== null && appointment?.payment?.cashTipAmount !== 0
        ? appointment?.payment?.cashTipAmount
        : null
    )
  }, [appointment, cashTipModalOpen])

  const handleAddUpdateTip = async () => {
    setUpdatingTip(true)
    const totalPrice =
      parseFloat(appointment?.payment?.servicesTotal ?? 0) +
      parseFloat(appointment?.payment?.tipAmount ?? 0) +
      parseFloat(appointment?.payment?.taxes ?? 0) +
      parseFloat(appointment?.payment?.productsTotal ?? 0) -
      parseFloat(appointment?.payment?.discountsTotal ?? 0) +
      parseFloat(tip ?? 0)

    const input = {
      payment: {
        ...appointment.payment,
        cashTipAmount: parseFloat(tip),
        totalPrice: parseFloat(totalPrice),
      },
    }

    try {
      const addOrUpdateTipResponse = await axios.patch(`/appointment_v2?id=${appointment?._id}`, input)

      if (addOrUpdateTipResponse?.status === 200) {
        setCashTipModalOpen(false)
        refreshAppointment()
        setToast({ isOpen: true, message: 'Cash tip updated successfully.', color: 'success' })
      }
    } catch (error) {
      setToast({ isOpen: true, message: 'Error updating cash tip. Please try again.', color: 'danger' })
    } finally {
      setUpdatingTip(false)
    }
  }

  return (
    <>
      <IonLabel style={{ cursor: 'pointer' }} color={textcolor ? textcolor : 'primary'} fill='clear' onClick={openCashTipModal}>
        {appointment?.payment?.cashTipAmount ? 'Modify Cash Tip' : 'Add Cash Tip'}
      </IonLabel>
      <IonModal isOpen={cashTipModalOpen} onDidDismiss={closeCashTipModal}>
        <CommonHeader
          title={appointment?.payment?.cashTipAmount ? 'Modify Cash Tip' : 'Add Cash Tip'}
          closeButton={true}
          closeModal={closeCashTipModal}
        ></CommonHeader>
        <IonContent className='regularBackground'>
          <StandardContainer>
            <IonCard className={spacingStyles.p4}>
              <IonRow style={{ margin: 0, padding: 0 }}>
                <IonCol size='auto' style={{ paddingTop: 15, margin: 0, marginLeft: 6, color: 'grey' }}>
                  $
                </IonCol>
                <IonCol>
                  <IonInput
                    value={tip}
                    onIonInput={(e) => handleTip(e.detail.value)}
                    label='Cash Tip'
                    labelPlacement='floating'
                    fill='outline'
                    placeholder='0.00'
                    inputmode='decimal'
                  ></IonInput>
                </IonCol>
              </IonRow>

              <div className={spacingStyles.mt4}>
                <IonButton onClick={handleAddUpdateTip} expand='block'>
                  {updatingTip && <IonSpinner name='lines' />}
                  Done
                </IonButton>
                <IonButton onClick={closeCashTipModal} expand='block' fill='clear'>
                  Cancel
                </IonButton>
              </div>
            </IonCard>
          </StandardContainer>
        </IonContent>
      </IonModal>

      <IonToast
        position='top'
        isOpen={toast.isOpen}
        onDidDismiss={() => setToast({ ...toast, isOpen: false })}
        message={toast.message}
        duration={2000}
        color={toast.color}
      />
    </>
  )
}
