import { IonGrid, IonIcon, IonInput, IonLabel, IonRow, IonSpinner, IonToast } from '@ionic/react'
import commonStyles from '../../theme/commonstyles.module.css'
import { returnUpBackOutline } from 'ionicons/icons'
import { useState } from 'react'
import axios from 'axios'

export default function PartialRefund({ cardNumber, paymentDetails, closeRefundModal }) {
  const [refundAmount, setRefundAmount] = useState(paymentDetails?.amount / 100)
  const [validationError, setValidationError] = useState('')
  const [processingRefund, setProcessingRefund] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [successMessage, setSuccessMessage] = useState('')
  const [toast, setToast] = useState({ isOpen: false, message: '', color: '' })

  const handleAmount = (event) => {
    const amount = event.detail.value
    if (amount * 100 > paymentDetails?.amount) {
      setValidationError('Refund amount cannot be greater than the original payment amount.')
    } else {
      setValidationError('')
    }
    setRefundAmount(amount)
  }

  const handlePartialRefund = async () => {
    setProcessingRefund(true)
    setSuccessMessage('')
    setErrorMessage('')
    try {
      const partialRefundInput = {
        paymentIntentId: paymentDetails?.id,
        refundType: 'partial',
        amount: refundAmount * 100,
      }
      const response = await axios.post('/customer_v2/payment/refund', partialRefundInput)

      if (response?.data?.status == 'succeeded') {
        setToast({ isOpen: true, message: 'Processed Refund Successfully', color: 'success' })

        setSuccessMessage('Processed Refund Successfully')
        setErrorMessage('')
        setProcessingRefund(false)
        setRefundAmount(paymentDetails?.amount / 100)
        setTimeout(() => {
          closeRefundModal()
        }, 1000)
      } else {
        setToast({ isOpen: true, message: response?.data?.raw?.message || 'Refund failed', color: 'danger' })

        setSuccessMessage('')
        setProcessingRefund(false)
        setErrorMessage(response?.data?.raw?.message)
        setRefundAmount(paymentDetails?.amount / 100)
        setTimeout(() => {
          setErrorMessage('')
        }, 1000)
      }
    } catch (error) {
      setToast({ isOpen: true, message: 'Error processing refund. Please try again.', color: 'danger' })
    } finally {
      setProcessingRefund(false)
    }
  }

  return (
    <IonGrid>
      <IonToast
        position='top'
        isOpen={toast.isOpen}
        onDidDismiss={() => setToast({ isOpen: false, message: '', color: '' })}
        message={toast.message}
        color={toast.color}
        duration={2000}
      />
      <IonRow className={`${commonStyles.flexCenter}`}>
        <IonInput
          fill='outline'
          labelPlacement='stacked'
          label='amount'
          placeholder='Amount'
          value={refundAmount}
          className={commonStyles.inputStyle}
          style={{ fontSize: '2rem', maxWidth: '50%' }}
          onIonInput={handleAmount}
        >
          <IonLabel style={{ fontSize: '1.5rem', padding: '0 10px' }} slot='start'>
            $
          </IonLabel>
        </IonInput>
        {validationError && (
          <IonLabel className={commonStyles.flexCenter} style={{ textAlign: 'center', color: 'red' }}>
            {validationError}
          </IonLabel>
        )}
      </IonRow>
      <IonRow className={`${commonStyles.flexCenter}`}>
        <h6 style={{ textAlign: 'center' }}>
          $ {refundAmount} will be refunded to Visa .... {cardNumber}
        </h6>
      </IonRow>
      <span style={{ margin: '1rem 0', border: '1px solid #dad5d5' }}></span>

      <IonRow className={`${commonStyles.flexCenter}`}>
        {processingRefund && <IonSpinner name='lines' />}
        {!processingRefund && !validationError && (
          <div onClick={handlePartialRefund} className={`${commonStyles.flexCenter}`} style={{ padding: '10px', cursor: 'pointer' }}>
            <IonIcon size='medium' color='primary' style={{ marginRight: '10px', height: 25, width: 25 }} icon={returnUpBackOutline} />
            <IonLabel color='primary'>Refund</IonLabel>
          </div>
        )}
      </IonRow>
      <IonRow className={commonStyles.flexCenter}>
        <IonLabel className={commonStyles.flexCenter} style={{ textAlign: 'center', color: 'green' }}>
          {successMessage}
        </IonLabel>
      </IonRow>
      <IonRow className={commonStyles.flexCenter}>
        <IonLabel className={commonStyles.flexCenter} style={{ textAlign: 'center', color: 'red' }}>
          {errorMessage}
        </IonLabel>
      </IonRow>
    </IonGrid>
  )
}
