import { IonCard, IonCheckbox, IonCol, IonGrid, IonIcon, IonRow, IonSpinner, IonToast, IonButton } from '@ionic/react'
import { businessOutline } from 'ionicons/icons'
import { useContext, useEffect, useState } from 'react'
import axios from 'axios'
import { AuthContext } from '../../auth'
import { roles } from '../../StaticData/StaticData'
import { AccessControl } from '../../AccessControl'

const initialPermissions = {
  owner: false,
  admin: false,
  manager: false,
  professional: false
}

export default function EachLocationPermission({ user, entitlementsData, refreshUser, updateEntitlements }) {
  const { selectedLocationData } = useContext(AuthContext)
  const [loading, setLoading] = useState(false)

  // Handle permission updates and post the updated permissions
  const handlePermissionUpdate = async (permission) => {
    const updatedPermissions = {
      ...entitlementsData,
      [permission]: !entitlementsData[permission]
    }

    updateEntitlements(updatedPermissions)
  }

  // Handle removing all permissions
  const handleRemoveAllPermissions = async () => {
    const clearedPermissions = { ...initialPermissions }

    updateEntitlements(clearedPermissions)
  }

  return (
    <>
      {loading && <IonSpinner name="dots" />}
      {!loading && (
        <IonGrid>
          <IonRow style={{ display: 'flex', alignItems: 'end', marginBottom: '1rem' }}>
            <IonIcon style={{ marginRight: '10px' }} icon={businessOutline} size="large" />
            <h6 style={{ margin: '0', color: 'black', fontWeight: 'bold' }}>
              {selectedLocationData?.name} Permissions
            </h6>
          </IonRow>

          {/* Render permissions checkboxes */}
          {roles.map((item, index) => (
            <AccessControl componentName={`${item.value}_permission`} key={index}>
              <IonRow onClick={() => handlePermissionUpdate(item.value)} style={{ cursor: 'pointer' }}>
                <IonCol>
                  <h6 style={{ margin: '5px', fontWeight: 'bold', color: 'black', fontSize: '1rem' }}>
                    {item.role}
                  </h6>
                  <h6 style={{ margin: '5px', fontSize: '12px' }}>{item.description}</h6>
                </IonCol>
                <IonCol size="auto" style={{ display: 'flex', justifyContent: 'end', paddingTop: 20 }}>
                  <IonCheckbox
                    style={{ height: '1.5rem', width: '1.5rem' }}
                    checked={!!entitlementsData[item.value]} // Ensure boolean value
                    onIonChange={() => handlePermissionUpdate(item.value)}
                  />
                </IonCol>
              </IonRow>
            </AccessControl>
          ))}

          {/* Button to remove all permissions */}
          <IonRow style={{ marginTop: '1rem' }}>
            <IonCol></IonCol>
            <IonCol size="auto">
              <IonButton fill="clear" color="primary" onClick={handleRemoveAllPermissions} style={{ marginRight: -10 }}>
                Remove all permissions
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      )}
    </>
  )
}
