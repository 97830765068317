import { IonCol, IonItem, IonLabel, IonList, IonPicker, IonRow, IonSpinner, IonToast } from '@ionic/react'
import { useState } from 'react'
import DaysPicker from '../Utils/GetDaysPicker'
import axios from 'axios'
import DeleteEmailStep from './DeleteEmailStep'

export default function ListView({ emailSteps, smtpSettingsId, ruleName, audiences, id, refreshRemarketingDetails, refreshEmailSteps, mode }) {
  const styles = {
    exportToCSVTitleStyle: {
      fontSize: '0.8rem',
      fontWeight: 'bold',
      color: 'grey',
    },
    moreIconStyle: {
      height: 22,
      width: 22,
      color: 'grey',
    },
  }

  const [anchorEl, setAnchorEl] = useState(null)
  const [selectedStep, setSelectedStep] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [updatingDays, setUpdatingDays] = useState(false)
  const [selectedDaysIndex, setSelectedDaysIndex] = useState(false)
  const [toast, setToast] = useState({ isOpen: false, message: '', color: '' })
  const daysArray = DaysPicker()
  const selectedIndex = daysArray.findIndex((day) => day.value === selectedStep?.followUpDays)

  const showToastMessage = (message, color) => {
    setToast({ isOpen: true, message, color })
  }

  const handleOpenDayPicker = (item) => {
    setSelectedStep(item)
    setIsOpen(true)
  }

  const closePicker = async () => {
    setIsOpen(false)

    if (mode === 'add') {
      // Handle add mode
    } else {
      setUpdatingDays(true)
    }

    const emailStepToUpdate = emailSteps.findIndex((email) => email.id === selectedStep.id)
    const stepsToUpdate = JSON.parse(JSON.stringify(emailSteps))

    if (emailStepToUpdate !== -1) {
      stepsToUpdate[emailStepToUpdate].followUpDays = selectedDaysIndex + 1
    }

    if (mode === 'add') {
      refreshEmailSteps(stepsToUpdate)
    } else {
      try {
        const remarketingInput = {
          smtpSettingsId,
          ruleName,
          audiences,
          emailSteps: stepsToUpdate,
        }

        const updateRemarketingResponse = await axios.patch(`/emailRemarketing/rule?id=${id}`, remarketingInput)

        if (updateRemarketingResponse.status === 200) {
          showToastMessage('Follow-up days updated successfully!', 'success')
          emailSteps[emailStepToUpdate].followUpDays = selectedDaysIndex + 1
        }
      } catch (error) {
        showToastMessage('Error updating follow-up days: ' + error.message, 'danger')
      } finally {
        setUpdatingDays(false)
      }
    }
  }

  return (
    <>
      <IonLabel style={{ color: 'grey', lineHeight: '1.5rem' }}>When and how many emails will be sent</IonLabel>

      <IonList inset={true} style={{ margin: '1rem 0' }} mode='ios'>
        {emailSteps?.map((item, index) => {
          return (
            <IonItem key={item.id}>
              <IonCol size='7'>
                <IonRow>
                  <IonCol size='auto'>
                    <IonLabel
                      style={{
                        height: '1.3rem',
                        width: '1.3rem',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: 'black',
                        borderRadius: '50%',
                        color: 'white',
                      }}
                    >
                      {index + 1}
                    </IonLabel>
                  </IonCol>
                  <IonCol>
                    <IonLabel style={{ fontWeight: 'bold' }}>{item.name}</IonLabel>
                    <p style={{ fontSize: '12px', lineHeight: '1rem', color: 'grey' }}>How many days after the appointment should we send a follow-up email?</p>
                  </IonCol>
                </IonRow>
              </IonCol>
              <IonCol
                size='5'
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'end',
                  alignItems: 'end',
                }}
              >
                <DeleteEmailStep
                  mode={mode}
                  refreshEmailSteps={refreshEmailSteps}
                  refreshRemarketingDetails={refreshRemarketingDetails}
                  stepData={item}
                  emailSteps={emailSteps}
                  smtpSettingsId={smtpSettingsId}
                  ruleName={ruleName}
                  audiences={audiences}
                  id={id}
                />
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '5px',
                    border: '2px solid #c1bfbf',
                    borderRadius: '5px',
                    width: '7rem',
                  }}
                >
                  {updatingDays && selectedStep.id === item.id && <IonSpinner name='lines' />}
                  {(!updatingDays || selectedStep.id !== item.id) && (
                    <IonLabel onClick={() => handleOpenDayPicker(item)} style={{ fontWeight: 'bold', marginRight: '0.5rem', fontSize: '1rem' }} color='primary'>
                      {item.followUpDays ? item.followUpDays : 'Select '} days
                    </IonLabel>
                  )}
                </div>
              </IonCol>
            </IonItem>
          )
        })}

        <IonPicker
          style={{ cursor: 'pointer' }}
          isOpen={isOpen}
          onDidDismiss={() => closePicker()}
          columns={[
            {
              name: 'days',
              options: daysArray,
              selectedIndex,
            },
          ]}
          onDidPresent={(event) => {
            event.target.addEventListener('ionPickerColChange', (event) => {
              const selectedValue = event.detail.selectedIndex
              setSelectedDaysIndex(selectedValue)
            })
          }}
        ></IonPicker>
      </IonList>

      <IonToast
        position='top'
        isOpen={toast.isOpen}
        onDidDismiss={() => setToast({ ...toast, isOpen: false })}
        message={toast.message}
        color={toast.color}
        duration={2000}
      />
    </>
  )
}
