import { IonList, IonItem, IonLabel, IonInput, IonTextarea, IonToggle } from '@ionic/react'

export default function DetailsView({ activeSubscriptionsCount, subscriptionPackage, updateSubscriptionPackage }) {
  let onValueChange = (field, value) => {
    updateSubscriptionPackage({ ...subscriptionPackage, [field]: value })
  }

  if (!subscriptionPackage) return 'loading...'

  return (
    <>
      <h5 style={{ margin: '1rem', fontWeight: 'bold' }}>General</h5>
      {activeSubscriptionsCount > 0 && <IonLabel style={{ margin: '1rem', color: 'grey' }}>Active Subscriptions Count : {activeSubscriptionsCount}</IonLabel>}
      <IonList inset={true} mode='ios'>
        <IonItem>
          <IonLabel position='stacked'>Name</IonLabel>
          <IonInput
            type='text'
            value={subscriptionPackage.name}
            onIonInput={(e) => {
              onValueChange('name', e.detail.value)
            }}
          ></IonInput>
        </IonItem>
        <IonItem>
          <IonLabel>Enabled</IonLabel>
          <IonToggle
            justify='space-between'
            size='small'
            checked={subscriptionPackage.enabled}
            onIonChange={(e) => {
              onValueChange('enabled', e.detail.checked)
            }}
          />
        </IonItem>
        <IonItem>
          <IonLabel position='stacked'>Description</IonLabel>
          <IonTextarea
            value={subscriptionPackage.description}
            onIonChange={(e) => {
              onValueChange('description', e.detail.value)
            }}
          ></IonTextarea>
        </IonItem>
        <IonItem>
          <IonLabel position='stacked'>Percent Discount on Product (Format 0-1)</IonLabel>
          <IonInput
            type='number'
            value={subscriptionPackage.percentDiscountOnProduct}
            onIonInput={(e) => {
              onValueChange('percentDiscountOnProduct', e.detail.value)
            }}
          ></IonInput>
        </IonItem>
      </IonList>
    </>
  )
}
