import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonContent, IonPage, IonText } from '@ionic/react'
import AppHeader from '../../app/header'
import StandardContainer from '../../commonComponents/StandardContainer'
import { AuthContext } from '../../auth'
import { useContext } from 'react'

const BusinessSetupFlow = () => {
  const {userData} = useContext(AuthContext)
  return (
    <IonPage>
      <AppHeader />
      <IonContent className='regularBackground'>
        <StandardContainer>
          <IonCard style={{ textAlign: 'center' }}>
            <IonCardContent>
              <IonText>
                <p style={{ fontSize: '1.1rem' }}>Hey! It looks like you arent set up yet with ClubCuts.</p>
                <p>{userData?.email}</p>
                <p style={{ fontSize: '0.9rem' }}>
                  <i>(Choose one of the options below to get started)</i>
                </p>
              </IonText>
            </IonCardContent>
          </IonCard>

          <IonCard>
            <IonCardHeader>
              <IonCardTitle>Create My Business</IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
              <p>
                In just a few easy steps you will up and running and ready to go. Lets get your barbershop, salon or personal business started on ClubCuts today!
              </p>
            </IonCardContent>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <IonButton routerLink='./setup/create' style={{ margin: '12px' }} color='dark'>
                Lets get started!
              </IonButton>
            </div>
          </IonCard>

          <IonCard>
            <IonCardHeader>
              <IonCardTitle>I have an invite code</IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
              <p>You have been sent a 6 digit invite code by someone already working with ClubCuts</p>
              <p>Click join to link up</p>
            </IonCardContent>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <IonButton routerLink='./setup/invite' style={{ margin: '12px' }} color='dark'>
                Join
              </IonButton>
            </div>
          </IonCard>
        </StandardContainer>
      </IonContent>
    </IonPage>
  )
}

export default BusinessSetupFlow
