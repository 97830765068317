import { IonCheckbox, IonIcon, IonItem, IonLabel } from '@ionic/react'
import { removeCircleOutline } from 'ionicons/icons'
import React from 'react'

export default function DiscountList({
  discounts,
  displaycheckbox,
  selectedDiscounts,
  handleDiscountSelect,
  removeItem,
}) {
  function DiscountType({ row }) {
    if (row?.discountType == 'percentage') {
      return <>{row?.discountValue}% off</>
    } else if (row?.discountType == 'fixed') {
      return <>${row?.discountValue} off</>
    } else {
      return null
    }
  }
  return discounts.map((discount, index) => {
    return (
      discount.couponType == 'servicesAndProducts' && (
        <IonItem key={discount._id}>
          <h6>{discount.name}</h6>
          <IonLabel slot='end'>
            <DiscountType row={discount} />
          </IonLabel>

          {displaycheckbox && (
            <IonCheckbox
              mode='ios'
              slot='end'
              checked={selectedDiscounts.some(
                selectedDiscount => selectedDiscount._id === discount._id,
              )}
              onIonChange={() => handleDiscountSelect(discount)}
            />
          )}
          {!displaycheckbox && (
            <IonIcon
              slot='end'
              style={{ cursor: 'pointer', color: 'red' }}
              onClick={() => removeItem(discount, 'discount')}
              icon={removeCircleOutline}
            />
          )}
        </IonItem>
      )
    )
  })
}
