import { IonButton, IonCard, IonCol, IonContent, IonIcon, IonLabel, IonPage, IonRow, IonSpinner, IonToast } from '@ionic/react'
import { useContext, useEffect, useState } from 'react'
import CommonHeader from '../../commonComponents/CommonHeader'
import StandardContainer from '../../commonComponents/StandardContainer'
import { AuthContext } from '../../auth'
import axios from 'axios'
import AcceptedInvitationCard from './AcceptedInvitationCard'
import GetExpiration from '../../Utils/GetExpiration'
import { chevronBack } from 'ionicons/icons'

export default function PendingInvitationsList() {
  const { userData, locationData } = useContext(AuthContext)
  const [deletingIndices, setDeletingIndices] = useState([])
  const [invitationsList, setInvitationsList] = useState([])
  const [loadingInvitations, setLoadingInvitations] = useState(false)
  const [invitationAccepted, setInvitationAccepted] = useState(false)
  const [refreshingList, setRefreshingList] = useState(false)
  const [acceptingIndices, setAcceptingIndices] = useState([])
  const [acceptedIndices, setAcceptedIndices] = useState([])

  const [toast, setToast] = useState({ isOpen: false, message: '', color: '' })

  const searchParams = new URLSearchParams(window.location.search)
  const email = searchParams.get('email')

  const getPendingInvitations = async (refreshing) => {
    refreshing ? setRefreshingList(true) : setLoadingInvitations(true)
    try {
      const pendingInvitationsResponse = await axios.get(`/user/invitations?email=${email}`)
      if (pendingInvitationsResponse?.data) {
        refreshing ? setRefreshingList(false) : setLoadingInvitations(false)
        setInvitationsList(pendingInvitationsResponse?.data)
      }
    } catch (error) {
      setLoadingInvitations(false)
    }
  }

  const showToastMessage = (message, color) => {
    setToast({ isOpen: true, message, color })
  }

  const handleAcceptInvitation = async (invitation, index) => {
    setAcceptingIndices((prevIndices) => [...prevIndices, index])
    let locationsArr = locationData.map((item) => item._id)
    const acceptInvitationInput = {
      code: invitation.code,
      businessId: invitation.businessId,
      firebaseId: userData?._id,
      email: invitation.email,
      locations: locationsArr,
    }
    try {
      const registerAndClaimCodeResponse = await axios.post('/user/registerAndClaimCode', acceptInvitationInput)
      if (registerAndClaimCodeResponse.status) {
        showToastMessage('Invitation accepted successfully!', 'success')
        setTimeout(() => {
          setAcceptingIndices((previousIndices) => previousIndices.filter((_, i) => !acceptingIndices.includes(i)))
          setAcceptedIndices((prevIndices) => [...prevIndices, index])
        }, 3000)
      }
    } catch (error) {
      showToastMessage('Failed to accept invitation.', 'danger')
    }
  }

  const handleDeleteInvite = async (invitation, index) => {
    try {
      setDeletingIndices((prevIndices) => [...prevIndices, index])
      const deleteResponse = await axios.post(`/user/deleteInvite?id=${invitation?._id}`)
      if (deleteResponse?.data?.deletedCount) {
        showToastMessage('Invitation declined successfully!', 'success')
        refreshPendingInvitations()
        setInvitationsList((prevInvitations) => prevInvitations.filter((_, i) => !deletingIndices.includes(i)))
      }
    } catch (error) {
      showToastMessage('Error deleting invitation.', 'danger')
    } finally {
      setDeletingIndices([]) // Reset deleting indices
    }
  }

  const refreshPendingInvitations = () => {
    getPendingInvitations(true)
  }

  useEffect(() => {
    getPendingInvitations()
  }, [userData])
  useEffect(() => {
    getPendingInvitations()
  }, [])

  const goBack = () => {
    setInvitationAccepted(false)
    getPendingInvitations()
  }

  return (
    <IonPage id='main-content'>
      <CommonHeader progressBar={refreshingList} title={'Pending Invitations'} />
      <IonContent>
        <StandardContainer>
          <IonToast
            position='top'
            isOpen={toast.isOpen}
            onDidDismiss={() => setToast({ ...toast, isOpen: false })}
            message={toast.message}
            color={toast.color}
            duration={2000}
          />
          {loadingInvitations && (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <IonSpinner name='dots' />
            </div>
          )}
          {!loadingInvitations && invitationsList.length === 0 && (
            <IonRow
              style={{
                padding: '1rem',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                margin: '1rem 0',
              }}
            >
              <IonLabel>No Pending Invitations</IonLabel>
              <IonButton routerLink='/core/dashboard' style={{ margin: '1rem' }}>
                <IonIcon icon={chevronBack} />
                Go Back Home
              </IonButton>
            </IonRow>
          )}
          {!loadingInvitations && !invitationAccepted && (
            <>
              {invitationsList.map(
                (invitation, index) =>
                  invitation?.business?.name && (
                    <IonCard style={{ padding: '1rem' }} key={invitation._id}>
                      <IonRow style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                        <h6 style={{ fontWeight: 'bold', fontSize: '1.2rem' }}>{invitation?.business?.name} has invited you to join their team</h6>
                        <IonLabel color={'primary'}>{GetExpiration(invitation.expiryTime)}</IonLabel>
                      </IonRow>
                      <IonRow style={{ margin: '10px 0' }}>
                        <IonCol size='6'>
                          <IonButton
                            onClick={() => handleDeleteInvite(invitation, index)}
                            style={{ '--background': '#c2c2c2', '--color': '#101010' }}
                            expand='full'
                            disabled={deletingIndices.includes(index)}
                          >
                            {deletingIndices.includes(index) ? <IonSpinner style={{ marginRight: '1rem' }} name='lines' /> : 'Decline'}
                          </IonButton>
                        </IonCol>
                        <IonCol size='6'>
                          <IonButton
                            style={GetExpiration(invitation.expiryTime).includes('Expired') ? { '--background': '#c2c2c2', '--color': '#101010' } : {}}
                            disabled={GetExpiration(invitation.expiryTime).includes('Expired')}
                            onClick={() => handleAcceptInvitation(invitation, index)}
                            expand='full'
                          >
                            {acceptingIndices.includes(index) ? <IonSpinner style={{ marginRight: '1rem' }} name='lines' /> : 'Accept'}
                          </IonButton>
                        </IonCol>
                      </IonRow>
                    </IonCard>
                  )
              )}
            </>
          )}
        </StandardContainer>
      </IonContent>
    </IonPage>
  )
}
