import { IonCard, IonCol, IonGrid, IonIcon, IonItem, IonLabel, IonList, IonRow } from '@ionic/react'
import { cubeOutline, cutOutline, image, imageOutline, pricetagOutline } from 'ionicons/icons'

export default function EditServicesProductAndDiscounts({ appointment, setServiceEditModalOpen }) {
  return (
    <>
      {['Complete', 'No Show'].includes(appointment?.status) ? null : (
        <>
          <IonList mode='ios' inset={true}>
            <IonItem
              detail={true}
              onClick={() => {
                setServiceEditModalOpen('Services')
              }}
            >
              <IonIcon style={{ marginRight: '1rem' }} icon={cutOutline} size={'large'} color={'dark'} />

              <IonLabel>Edit Services</IonLabel>
            </IonItem>
            <IonItem
              detail={true}
              onClick={() => {
                setServiceEditModalOpen('Products')
              }}
            >
              <IonIcon style={{ marginRight: '1rem' }} icon={cubeOutline} size={'large'} color={'dark'} />

              <IonLabel>Edit Products</IonLabel>
            </IonItem>
            <IonItem
              detail={true}
              onClick={() => {
                setServiceEditModalOpen('Services')
              }}
            >
              <IonIcon style={{ marginRight: '1rem' }} icon={pricetagOutline} size={'large'} color={'dark'} />

              <IonLabel>Edit Discounts</IonLabel>
            </IonItem>
            <IonItem detail={true} routerLink={`/core/appointmentgallery/${appointment?._id}`}>
              <IonIcon style={{ marginRight: '1rem' }} icon={imageOutline} size={'large'} color={'dark'} />

              <IonLabel>Customer Pictures</IonLabel>
            </IonItem>
          </IonList>
        </>
      )}
    </>
  )
}
