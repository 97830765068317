import { IonButton, IonButtons, IonContent, IonIcon, IonInput, IonItem, IonLabel, IonList, IonModal, IonPage, IonSpinner, IonToast } from '@ionic/react'
import { useEffect, useState } from 'react'
import CommonHeader from '../commonComponents/CommonHeader'
import StandardContainer from '../commonComponents/StandardContainer'
import axios from 'axios'
import { useHistory } from 'react-router'
import { eye, eyeOff } from 'ionicons/icons'
// import DecryptData from '../Utils/DecryptData'
// import EncryptData from '../Utils/EncryptData'

var CryptoJS = require('crypto-js')
export default function EmailConfiguration({ match }) {
  let smtpId = match?.params?.id

  const history = useHistory()
  const [configurationChanged, setConfigurationChanged] = useState(false)
  const [loading, setLoading] = useState(smtpId)
  const [service, setService] = useState('')
  const [userName, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [fromEmail, setFromEmail] = useState('')
  const [updatingSmtpSettings, setUpdatingSmtpSettings] = useState(false)
  const [testingEmail, setTestingEmail] = useState(false)
  const [smtpResponse, setSmtpResponse] = useState('')
  const [passwordType, setPasswordType] = useState('password')
  const [testEmailPopupVisible, setTestEmailPopupVisible] = useState(false)
  const [toEmailAddress, setToEmailAddrress] = useState('')
  const [fromName, setFromName] = useState('')
  const [toast, setToast] = useState({ isOpen: false, message: '', color: 'success' })
  // Function to show toast messages
  const showToastMessage = (message, color) => {
    setToast({ isOpen: true, message, color })
  }
  const handleService = (value) => {
    setConfigurationChanged(true)
    setService(value)
  }
  const handleFromName = (value) => {
    setConfigurationChanged(true)
    setFromName(value)
  }
  const handleUsername = (value) => {
    setConfigurationChanged(true)
    setUsername(value)
  }
  const handlePassword = (value) => {
    setConfigurationChanged(true)
    setPassword(value)
  }
  const handleFromEmail = (value) => {
    setConfigurationChanged(true)
    setFromEmail(value)
  }
  const changePasswordType = () => {
    const type = passwordType == 'password' ? 'text' : 'password'
    setPasswordType(type)
  }
  const handleToEmailAddress = (value) => {
    setToEmailAddrress(value)
  }
  const handleSaveConfiguration = async () => {
    setUpdatingSmtpSettings(true)
    // var encryptedPassword = EncryptData(password)
    const configurationInput = { fromName, service, userName, password: password, fromEmail }

    let method = 'POST'
    let url = '/emailSettings'

    if (smtpId) {
      method = 'PATCH'
      url = `/emailSettings/?id=${smtpId}`
    }
    try {
      const smtpSettingsResponse = await axios({
        method, // Use dynamic method here
        url,
        data: configurationInput,
      })
      if (smtpSettingsResponse?.status == 200) {
        showToastMessage('SMTP settings saved successfully!', 'success')

        setUpdatingSmtpSettings(false)
        setConfigurationChanged(false)
        setPasswordType('password')
        history.goBack()
      }
    } catch (error) {
      showToastMessage('Failed to save SMTP settings. Please try again.', 'danger')

      setUpdatingSmtpSettings(false)
      setConfigurationChanged(false)
    }
  }
  const openTestEmailPopUp = () => {
    setTestEmailPopupVisible(true)
  }
  const closeTestEmailPopUp = () => {
    setToEmailAddrress('')
    setTestEmailPopupVisible(false)
  }
  const handleTestEmail = async () => {
    setTestingEmail(true)

    const testEmailInput = {
      fromName,
      service,
      userName,
      password,
      fromEmail,
      toEmail: toEmailAddress,
    }

    try {
      const smtpResponse = await axios.post(`/emailSettings/test`, testEmailInput)
      closeTestEmailPopUp()
      if (smtpResponse?.data) {
        setSmtpResponse(smtpResponse?.data)
        showToastMessage('Test email sent successfully!')

        setTimeout(() => {
          setSmtpResponse(false)
        }, 5000)
        setTestingEmail(false)
      }
    } catch (error) {
      closeTestEmailPopUp()
      showToastMessage('Failed to send test email. Please check your settings.', 'danger')

      setSmtpResponse(smtpResponse?.data)

      setTestingEmail(false)
    }
  }
  const getConfigurationDetails = async () => {
    try {
      const smtpResponse = await axios.get(`/emailSettings/?id=${smtpId}`)

      if (smtpResponse && smtpResponse.status == 200 && smtpResponse.data) {
        let data = smtpResponse.data

        // var decryptedPassword = DecryptData(data?.password)

        setLoading(false)
        setUsername(data?.userName)
        setPassword(data && data.password)
        setFromEmail(data?.fromEmail)
        setService(data?.service)
      }
    } catch (error) {
      console.error('Error fetching email settings:', error) // Log error to console
    } finally {
      setLoading(false) // Set loading state to false after the request completes
    }
  }
  useEffect(() => {
    if (smtpId) {
      getConfigurationDetails()
    }
  }, [smtpId])
  useEffect(() => {
    if (smtpId) {
      getConfigurationDetails()
    }
  }, [])
  const isSaveEnabled = userName && service && password && fromEmail && fromName

  return (
    <IonPage id='main-content'>
      <CommonHeader
        loading={updatingSmtpSettings}
        saveButton={configurationChanged && isSaveEnabled}
        handleSaveButton={handleSaveConfiguration}
        title={'SMTP Configuration'}
        backIcon={true}
      ></CommonHeader>
      <IonContent>
        <StandardContainer>
          <div style={{ padding: '0 1rem 1rem 1rem' }}>
            {loading && (
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <IonSpinner name='dots' />
              </div>
            )}
            {!loading && (
              <>
                <div
                  style={{
                    margin: '1.2rem 0',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <IonLabel style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>Email SMTP Settings</IonLabel>
                  <IonButtons>
                    {testingEmail && <IonSpinner name='lines' />}
                    {!testingEmail && (
                      <IonButton onClick={openTestEmailPopUp} style={{ cursor: 'pointer' }} color={'primary'}>
                        Test Email
                      </IonButton>
                    )}
                  </IonButtons>
                </div>
                <IonList inset={true} mode='ios'>
                  <IonItem>
                    <IonLabel>Service</IonLabel>
                    <IonInput
                      disabled={testingEmail}
                      onIonInput={(e) => {
                        handleService(e.detail.value)
                      }}
                      value={service}
                      placeholder='google.com'
                      className='ion-text-end'
                    ></IonInput>
                  </IonItem>
                  <IonItem>
                    <IonLabel>Username</IonLabel>
                    <IonInput
                      disabled={testingEmail}
                      onIonInput={(e) => {
                        handleUsername(e.detail.value)
                      }}
                      value={userName}
                      placeholder='myuserName'
                      className='ion-text-end'
                    ></IonInput>
                  </IonItem>
                  <IonItem>
                    <IonLabel>Password</IonLabel>
                    <IonInput
                      disabled={testingEmail}
                      onIonInput={(e) => {
                        handlePassword(e.detail.value)
                      }}
                      type={passwordType}
                      value={password}
                      placeholder='secret'
                      className='ion-text-end'
                    ></IonInput>
                    <IonIcon
                      style={testingEmail ? { color: 'grey' } : {}}
                      disabled={testingEmail}
                      icon={passwordType == 'password' ? eye : eyeOff}
                      onClick={changePasswordType}
                    />
                  </IonItem>
                  <IonItem>
                    <IonLabel>From Email</IonLabel>
                    <IonInput
                      disabled={testingEmail}
                      onIonInput={(e) => {
                        handleFromEmail(e.detail.value)
                      }}
                      value={fromEmail}
                      placeholder='Email Address'
                      className='ion-text-end'
                    ></IonInput>
                  </IonItem>
                  <IonItem>
                    <IonLabel>From Name</IonLabel>
                    <IonInput
                      disabled={testingEmail}
                      onIonInput={(e) => {
                        handleFromName(e.detail.value)
                      }}
                      value={fromName}
                      placeholder='From Name'
                      className='ion-text-end'
                    ></IonInput>
                  </IonItem>
                </IonList>
                {((configurationChanged && isSaveEnabled) || smtpId) && (
                  <>
                    {smtpResponse && smtpResponse.message == 'Failure' && (
                      <IonLabel
                        style={{
                          color: 'red',
                          textAlign: 'center',
                        }}
                      >
                        Failed to send email. Please re-verify your configuration
                      </IonLabel>
                    )}
                    {smtpResponse && smtpResponse.message == 'Success' && (
                      <IonLabel
                        style={{
                          color: 'green',
                        }}
                      >
                        Email Sent Successfully
                      </IonLabel>
                    )}
                  </>
                )}
              </>
            )}
          </div>
          <IonModal isOpen={testEmailPopupVisible}>
            <CommonHeader title={'Test Email Configuration'} closeButton={true} closeModal={closeTestEmailPopUp}></CommonHeader>
            <IonContent className='regularBackground'>
              <StandardContainer>
                <IonItem>
                  <IonLabel>To Email Address</IonLabel>
                  <IonInput
                    onIonInput={(e) => {
                      handleToEmailAddress(e.detail.value)
                    }}
                    value={toEmailAddress}
                    placeholder='sample@example.com'
                    className='ion-text-end'
                  ></IonInput>
                </IonItem>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <IonButton onClick={handleTestEmail} disabled={!toEmailAddress}>
                    Test Email
                  </IonButton>
                </div>
              </StandardContainer>
            </IonContent>
          </IonModal>
          <IonToast
            position='top'
            isOpen={toast.isOpen}
            onDidDismiss={() => setToast({ ...toast, isOpen: false })}
            message={toast.message}
            color={toast.color}
            duration={2000}
          />
        </StandardContainer>
      </IonContent>
    </IonPage>
  )
}
