import React, { useEffect, useState } from 'react'
import { IonList, IonItem, IonLabel, IonButton, IonIcon, IonInput, IonItemSliding, IonItemOptions, IonItemOption, IonAlert } from '@ionic/react'
import { trashOutline } from 'ionicons/icons'

const ListComponent = ({ corporateDeal, updateCorporateDeal }) => {
  const [newItem, setNewItem] = useState('')
  const [listItems, setListItems] = useState(corporateDeal?.enrolledEmails || [])
  const [isInit, setIsInit] = useState(false)
  const [showAlert, setShowAlert] = useState(false)
  const [deleteIndex, setDeleteIndex] = useState(null)

  useEffect(() => {
    if (isInit) {
      updateCorporateDeal({ ...corporateDeal, ...{ enrolledEmails: listItems } })
    } else {
      setIsInit(true)
    }
  }, [listItems])

  // Email validation function
  const isValidEmail = (email) => {
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/
    return regex.test(email)
  }

  const addItem = () => {
    if (newItem.trim() !== '' && isValidEmail(newItem)) {
      setListItems([...listItems, newItem.trim()])
      setNewItem('')
    } else {
      // Handle invalid email case
      alert('Please enter a valid email address.')
    }
  }

  const promptDeleteItem = (index) => {
    setShowAlert(true)
    setDeleteIndex(index)
  }

  const confirmDeleteItem = () => {
    if (deleteIndex !== null) {
      const newListItems = [...listItems]
      newListItems.splice(deleteIndex, 1)
      setListItems(newListItems)
    }
    setShowAlert(false)
    setDeleteIndex(null)
  }

  const handleInput = (e) => {
    setNewItem(e.detail.value?.toLowerCase()?.trim())
  }

  return (
    <>
      <IonAlert
        isOpen={showAlert}
        onDidDismiss={() => setShowAlert(false)}
        header={'Confirm Delete'}
        message={'Are you sure you want to delete this item?'}
        buttons={[
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {
              setShowAlert(false)
            },
          },
          {
            text: 'Yes',
            handler: () => {
              confirmDeleteItem()
            },
          },
        ]}
      />
      <IonList inset={true} mode='ios'>
        <IonItem>
          <IonLabel>
            <h3 style={{ fontWeight: '600' }}>Enrolled Emails</h3>
          </IonLabel>
        </IonItem>
        {listItems.map((item, index) => (
          <IonItemSliding key={index}>
            <IonItem>
              <IonLabel>{item}</IonLabel>
              <IonButton slot='end' fill='clear' onClick={() => promptDeleteItem(index)}>
                <IonIcon icon={trashOutline} />
              </IonButton>
            </IonItem>

            <IonItemOptions side='end'>
              <IonItemOption color='grey' onClick={() => promptDeleteItem(index)}>
                Delete
              </IonItemOption>
            </IonItemOptions>
          </IonItemSliding>
        ))}
        <IonItem>
          <IonInput placeholder='New Item' value={newItem} onIonInput={handleInput} />
          <IonButton slot='end' onClick={addItem}>
            Add
          </IonButton>
        </IonItem>
      </IonList>
    </>
  )
}

export default ListComponent
