import { useState, useEffect, useContext } from 'react'
import GeneralDetails from './details_general'
import axios from 'axios'
import { AuthContext } from '../auth'
import { useHistory } from 'react-router-dom'
import { IonBackButton, IonButtons, IonContent, IonHeader, IonPage, IonSpinner, IonToolbar, IonToast, IonGrid } from '@ionic/react'
import StandardContainer from '../commonComponents/StandardContainer'
import AdvancedDetails from './advancedDetails'

export default function Details({ match }) {
  const product_id = match?.params?.id
  const [product, setProduct] = useState()
  const [productTemp, setProductTemp] = useState()
  const [locations, setLocations] = useState()
  const [subscriptionPackages, setSubscriptionPackages] = useState()
  const { businessData, locationId } = useContext(AuthContext)
  const [loading, setLoading] = useState(false)
  const [isReloading, setIsReloading] = useState(false) // To track if the reload is from useEffect

  const history = useHistory()

  // Toast state management
  const [toast, setToast] = useState({ isOpen: false, message: '', color: '' })

  const showToastMessage = (message, color = 'danger') => {
    setToast({ isOpen: true, message, color })
  }

  const getProduct = async (reload) => {
    if (reload) {
      setIsReloading(true)
    } else {
      setLoading(true)
    }
    try {
      const response = await axios.get(`/product?id=${product_id}`)
      setProduct(response.data) // Update state with product data
      setProductTemp(response.data) // Set temporary product state
      setIsReloading(false)
      setLoading(false)
    } catch (error) {
      console.error('Failed to load product details:', error) // Log the error for debugging
    } finally {
    }
  }

  useEffect(() => {
    getProduct(true)
  }, [product_id])

  const getLocationsList = async () => {
    if (!businessData?._id) return
    try {
      let response = await axios.get(`/location_v2/getList?businessId=${businessData._id}`)
      setLocations(response.data)
    } catch (error) {
      console.error('Failed to load locations.', error) // Log the error for debugging
    }
  }

  useEffect(() => {
    getLocationsList()
  }, [businessData])

  const getSubscriptionPackagesList = async () => {
    if (!businessData?._id) return
    try {
      let response = await axios.get(`/subscriptionPackage/getList?businessId=${businessData._id}`)
      setSubscriptionPackages(response.data)
    } catch (error) {
      console.error('Failed to load subscription packages.', error) // Log the error for debugging
    }
  }

  useEffect(() => {
    getSubscriptionPackagesList()
  }, [businessData])

  const deleteProduct = async () => {
    try {
      await axios.delete(`/product?id=${product_id}`)
      history.goBack()
      showToastMessage('Product deleted successfully!', 'success') // Success toast
    } catch (error) {
      showToastMessage('Failed to delete product.', 'danger') // Error toast
    }
  }

  if (!product) {
    return (
      <IonPage>
        <IonHeader>
          <IonToolbar color='black' style={{ backgroundColor: 'white', color: 'black' }}>
            <IonButtons slot='start'>
              <IonBackButton />
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonSpinner name='dots' />
        </IonContent>
      </IonPage>
    )
  }

  return (
    <>
      <IonPage>
        <IonContent class='regularBackground'>
          <StandardContainer>
            {isReloading && (
              <IonGrid style={{ padding: '0 20px' }}>
                <IonSpinner name='dots' />
              </IonGrid>
            )}
            {!isReloading && (
              <>
                <GeneralDetails
                  loading={loading}
                  showToastMessage={showToastMessage}
                  product={product}
                  productTemp={productTemp}
                  getProduct={() => getProduct(false)}
                  locations={locations}
                  subscriptionPackages={subscriptionPackages}
                  locationId={locationId}
                />
                <AdvancedDetails
                  loading={loading}
                  getProduct={() => getProduct(false)}
                  showToastMessage={showToastMessage}
                  product={product}
                  productTemp={productTemp}
                  locations={locations}
                  subscriptionPackages={subscriptionPackages}
                  locationId={locationId}
                  deleteProduct={deleteProduct}
                />
              </>
            )}
          </StandardContainer>
        </IonContent>
        {/* <IonContent>
          <StandardContainer>
            <IonCard style={{ padding: 20 }}>
              <GeneralDetails
                product={productTemp}
                updateProduct={updateProduct}
                locations={locations}
                subscriptionPackages={subscriptionPackages}
                locationId={locationId}
              />
            </IonCard>
            <IonCard style={{ padding: 20 }}>
              <IonButton color='warning' variant='contained' onClick={deleteProduct}>
                Delete
              </IonButton>
            </IonCard>
          </StandardContainer>
        </IonContent> */}
      </IonPage>
      <IonToast
        position='top'
        isOpen={toast.isOpen}
        onDidDismiss={() => setToast({ ...toast, isOpen: false })}
        message={toast.message}
        color={toast.color}
        duration={2000}
      />
    </>
  )
}
