function getEntitlementsByBusinessAndLocation(businessId, locationId, userBusinesses) {
  const business = userBusinesses.find(item => item._id === businessId)

  if (business) {
    const location = business.locations.find(loc => loc._id === locationId)

    if (location) {
      return location.entitlements
    }
  }

  return null // Return null or handle the case when business or location is not found
}

export default getEntitlementsByBusinessAndLocation
