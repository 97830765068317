import { IonCol, IonIcon, IonLabel } from '@ionic/react'
import { useState } from 'react'
import RescheduleAppointment from '../Actions/RescheduleAppointment'
import ClientInfo from './ClientInfo'
import CancelAppointment from '../Actions/CancelAppointment'
import { colors } from '../../../theme/colors'
import { ellipsisHorizontal } from 'ionicons/icons'
import StatusButtons from './StatusButtons'
import AddCashTip from '../../details/AddCashTip'
import MoreActionsModal from './MoreActions'

// Import styles
import {
  containerStyle,
  totalPaidContainerStyle,
  totalPaidTextStyle,
  totalPaidAmountStyle,
  addCashTipStyle,
  noShowFeeContainerStyle,
  noShowFeeTextStyle,
  noShowFeeAmountStyle,
  moreButtonStyle,
  moreButtonTextStyle,
} from '../Styles/FooterActionStyles'

export default function FooterActions({
  currentBreakpoint,
  appointment,
  closeModal,
  rescheduling,
  updateStatus,
  subscription,
  updatingStatus,
  getAppointment,
}) {
  const [showModal, setShowModal] = useState(false)

  const openModal = () => {
    setShowModal(true)
  }

  const closeModalHandler = () => {
    setShowModal(false)
  }

  return (
    <>
      <div style={currentBreakpoint == 1 ? containerStyle : { ...containerStyle, borderTop: '1px #EBEBEB solid', paddingTop: 10 }}>
        {['Complete'].includes(appointment?.status) ? (
          <div style={totalPaidContainerStyle}>
            <div style={{ alignSelf: 'stretch', justifyContent: 'flex-start', alignItems: 'center', gap: 12, display: 'inline-flex' }}>
              <div style={{ flex: '1 1 0', height: 28, justifyContent: 'flex-start', alignItems: 'center', gap: 6, display: 'flex' }}>
                <div style={{ flex: '1 1 0', display: 'flex' }}>
                  <span style={totalPaidTextStyle}>Total Paid:</span>
                  <span style={totalPaidAmountStyle}>${appointment?.payment?.totalPrice?.toFixed(2)}</span>
                </div>
              </div>
              <div style={{ justifyContent: 'flex-start', alignItems: 'flex-start', gap: 12, display: 'flex' }}>
                <div style={addCashTipStyle}>
                  <AddCashTip refreshAppointment={getAppointment} appointment={appointment} />
                </div>
              </div>
            </div>
          </div>
        ) : null}
        <StatusButtons
          rescheduling={rescheduling}
          subscription={subscription}
          appointment={appointment}
          updateStatus={updateStatus}
          updatingStatus={updatingStatus}
          getAppointment={getAppointment}
        />
        <div
          style={{ height: 68, background: 'white', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 8, display: 'flex' }}
        >
          {appointment?.status == 'No Show' && appointment?.noShowCharge && (
            <div style={noShowFeeContainerStyle}>
              <div style={{ alignSelf: 'stretch', justifyContent: 'flex-start', alignItems: 'center', gap: 12, display: 'inline-flex' }}>
                <div style={{ flex: '1 1 0', height: 28, justifyContent: 'flex-start', alignItems: 'center', gap: 6, display: 'flex' }}>
                  <div style={{ flex: '1 1 0', display: 'flex' }}>
                    <span style={noShowFeeTextStyle}>No Show Fee:</span>
                    <span style={noShowFeeAmountStyle}>${appointment?.payment?.noShowCharge}</span>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div style={{ alignSelf: 'stretch', justifyContent: 'center', alignItems: 'flex-start', gap: 4, display: 'inline-flex' }}>
            <div style={{ flex: '1 1 0', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 8, display: 'flex', margin: '10px 0' }}>
              {['Complete', 'Scheduled'].includes(appointment?.status) ? null : (
                <RescheduleAppointment updatingStatus={rescheduling} updateStatus={updateStatus} appointment={appointment} />
              )}
              <ClientInfo closeModal={closeModal} appointment={appointment} />
              {appointment?.status == 'Arrived' || appointment?.status == 'Scheduled' ? (
                <CancelAppointment appointment={appointment} getAppointment={getAppointment} />
              ) : (
                <div onClick={openModal} style={moreButtonStyle}>
                  <div style={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 4, display: 'flex' }}>
                    <div style={{ width: 24, height: 24, position: 'relative' }}>
                      <IonIcon style={{ height: 30, width: 30, marginBottom: '0.5rem', color: colors.black70 }} icon={ellipsisHorizontal} />{' '}
                    </div>
                    <div style={moreButtonTextStyle}>More</div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <MoreActionsModal
        subscription={subscription}
        closeModal={closeModal}
        getAppointment={getAppointment}
        appointment={appointment}
        isOpen={showModal}
        onClose={closeModalHandler}
      />
    </>
  )
}
