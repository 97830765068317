import { IonCard, IonIcon, IonLabel } from '@ionic/react'
import { chevronBack } from 'ionicons/icons'
import { useHistory } from 'react-router'

export default function UserNotice({ fromBusiness }) {
  const history = useHistory()
  const goBackToHome = () => {
    history.push('/')
  }
  return (
    <>
      <IonCard
        style={{
          margin: '50px auto',
          padding: '20px',
          borderRadius: '8px',
        }}
      >
        <div
          style={{
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <h2>Contact Administrator</h2>
            <IonLabel>
              Please reach out to our administrator for assistance with being added to an existing business. Your request to join will be processed promptly by
              our team. Thank you for your cooperation.
            </IonLabel>
          </div>
        </div>
        <div
          style={{
            marginTop: '2rem',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <IonIcon color='primary' style={{ marginTop: '2px' }} icon={chevronBack} />
          <IonLabel onClick={goBackToHome} color={'primary'}>
            Go Back To Home
          </IonLabel>
        </div>
      </IonCard>
    </>
  )
}
