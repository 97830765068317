import * as React from 'react'
import dayjs from 'dayjs'
import { formatDateToISOString, getFormattedMediumDate, getWeekday } from '../../utils'
import TimeSelector from '../timeSelector'
import { IonButton, IonCol, IonContent, IonDatetime, IonGrid, IonIcon, IonLabel, IonModal, IonRow, IonText, IonToggle } from '@ionic/react'
import StandardContainer from '../../commonComponents/StandardContainer'
import { checkmarkOutline, closeOutline } from 'ionicons/icons'
import { colors } from '../../theme/colors'
import { fontSizes, fontWeights } from '../../theme/typography'
import axios from 'axios'

function getDefaultData() {
  return {
    dateText: getFormattedMediumDate(new Date()),
    dateEpoch: new Date().getTime(),
    enabled: false,
    startTime: null,
    endTime: null,
  }
}

export default function SimpleDialog({ showToastMessage, modifiedSchedule, employee, onClose, open, handleSave, standardSchedule }) {
  const [data, setData] = React.useState(getDefaultData())
  const [errorMessage, setErrorMessage] = React.useState('')
  const [isSaving, setIsSaving] = React.useState(false)
  const [showConfirmationModal, setShowConfirmationModal] = React.useState(false) // State for confirmation modal

  React.useEffect(() => {
    setData(getDefaultData())
    setIsSaving(false)
    setErrorMessage('')
    setShowConfirmationModal(false)
  }, [open])
  const scheduleExists = (dateText) => {
    return modifiedSchedule.some((schedule) => {
      const matchesDate = schedule.dateText === dateText

      return matchesDate
    })
  }

  const save = () => {
    setErrorMessage('')
    if (data.enabled) {
      if (!data.startTime || !data.endTime) {
        setErrorMessage('**Open time and close time must be filled out')
        return
      }
    }

    if (scheduleExists(data.dateText)) {
      setShowConfirmationModal(true) // Show confirmation if schedule exists
      return
    }

    proceedToSave()
  }
  const proceedToSave = async () => {
    try {
      setIsSaving(true) // Indicate that saving is in progress

      // Check if there is already an entry for the same `dateEpoch` in `modifiedSchedule`
      const existingEntry = modifiedSchedule.find((schedule) => schedule.dateText === data.dateText)

      if (existingEntry) {
        // If an entry exists, delete it first
        await axios.delete(`/employee/modifiedSchedule?id=${existingEntry._id}`)
      }
      // Save the new entry after deleting the old one
      await handleSave(data)
      showToastMessage('Modified schedule saved successfully.')
    } catch (error) {
      console.error('Error overriding modified schedule:', error)
      showToastMessage('There was an issue overriding the modified schedule. Try again later.', 'danger')
    } finally {
      setIsSaving(false) // Reset saving state
    }
  }

  const handleClose = () => {
    onClose()
  }

  const handleConfirmation = (confirm) => {
    if (confirm) {
      proceedToSave()
    } else {
      setShowConfirmationModal(false)
    }
  }
  function handleChange(id, fieldKey, newValue) {
    setData({ ...data, ...{ [fieldKey]: newValue } })
  }

  function updateDate(id, date) {
    let weekday = getWeekday(date)
    let standardScheduleForDay = standardSchedule[weekday]
    let startTime = standardScheduleForDay.startTime
    let endTime = standardScheduleForDay.endTime
    let enabled = standardScheduleForDay.enabled

    setData({
      ...data,
      ...{
        startTime,
        endTime,
        enabled,
        dateText: getFormattedMediumDate(date),
        dateEpoch: date.getTime(),
      },
    })
  }

  return (
    <>
      <IonModal isOpen={open} onDidDismiss={handleClose}>
        <IonContent>
          <StandardContainer padding={20}>
            <IonGrid>
              <IonRow>
                <IonCol>
                  <h2>
                    Enter a modified schedule for {employee?.firstName} {employee?.lastName}
                  </h2>
                </IonCol>
                <IonCol size='1'>
                  <IonIcon
                    onClick={handleClose}
                    style={{ fontSize: fontSizes.size24, color: colors.black40, fontWeight: fontWeights.weightBold }}
                    icon={closeOutline}
                  />
                </IonCol>
              </IonRow>
            </IonGrid>

            <IonGrid style={{ marginBottom: 80, flexDirection: 'column', display: 'flex', justifyContent: 'center' }}>
              <IonRow style={{ display: 'flex', justifyContent: 'center', marginBottom: 10 }}>
                <CalendarSelect dateText={data.dateText} id={data._id} updateDate={updateDate} />
              </IonRow>
              <IonRow style={{ padding: 10, border: `1px solid ${colors.black30}`, borderRadius: 10 }}>
                <IonCol>
                  <IonRow>
                    <IonLabel style={{ fontWeight: fontWeights.weightBold }}>Will {employee?.firstName} work this day?</IonLabel>
                  </IonRow>
                  <IonRow style={{ marginTop: 10 }}>
                    <IonLabel style={{ color: colors.black40 }}>Check the box to indicate yes</IonLabel>
                  </IonRow>
                </IonCol>
                <IonCol size='auto' style={{ display: 'flex', alignItems: 'center' }}>
                  <IonToggle mode='ios' checked={data.enabled} onIonChange={(e) => handleChange(data, 'enabled', e.detail.checked)} />
                  {data.enabled && (
                    <IonIcon
                      onClick={() => handleChange(data, 'enabled', false)}
                      icon={checkmarkOutline} // Or any other suitable icon from react-icons or IonIcons
                      style={{ cursor: 'pointer', position: 'absolute', right: 11, zIndex: 10, marginLeft: '8px', color: colors.primaryBlue }} // Adjust styling as needed
                    />
                  )}
                </IonCol>
              </IonRow>
              {data.enabled && (
                <>
                  <IonRow
                    style={{
                      marginTop: 30,
                      padding: 10,
                      border: `1px solid ${colors.black30}`,
                      borderBottom: 'none',
                      borderTopLeftRadius: 10,
                      borderTopRightRadius: 10,

                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <IonLabel>Start Time</IonLabel>
                    <TimeSelector dayKey={data._id} fieldKey='startTime' value={data?.startTime} handleChange={handleChange} />
                  </IonRow>
                  <IonRow
                    style={{
                      padding: 10,
                      border: `1px solid ${colors.black30}`,
                      borderBottomLeftRadius: 10,
                      borderBottomRightRadius: 10,
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <IonLabel>End Time</IonLabel>
                    {}
                    <TimeSelector dayKey={data._id} fieldKey='endTime' value={data?.endTime} handleChange={handleChange} />
                  </IonRow>
                </>
              )}
            </IonGrid>

            <IonText color='danger'>
              <p>{errorMessage}</p>
            </IonText>
            {/* Fixed Footer */}
            <div
              style={{
                zIndex: 10,
                position: 'fixed',
                bottom: 0,
                left: 0,
                width: '100%',
                background: 'white',
                padding: '10px 20px 40px 20px',
                boxShadow: '0 -2px 8px rgba(0, 0, 0, 0.1)',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <IonButton fill='clear' color={'dark'} onClick={handleClose}>
                Cancel
              </IonButton>
              <IonButton
                style={{
                  color: 'white',
                  fontWeight: '600',
                  justifyContent: 'center',
                  alignItems: 'end',
                  flexDirection: 'column',
                  display: 'flex',
                }}
                color={'dark'}
                disabled={isSaving}
                onClick={save}
              >
                <IonLabel style={{ padding: '0 22px' }}>Save</IonLabel>
              </IonButton>
            </div>
          </StandardContainer>
        </IonContent>
      </IonModal>
      {/* Confirmation Modal */}
      <IonModal onDidDismiss={() => setShowConfirmationModal(false)} isOpen={showConfirmationModal}>
        <IonContent>
          <StandardContainer padding={20} style={{ textAlign: 'center' }}>
            <h2 style={{ marginBottom: '40px' }}>A modified schedule already exists for this day. Would you like to override it?</h2>
            <IonGrid style={{ fontWeight: fontWeights.weight600 }}>
              <IonRow>
                <IonCol>
                  <IonLabel style={{ color: colors.black40, fontWeight: fontWeights.weightBold }}>Existing</IonLabel>
                  <div>
                    {modifiedSchedule
                      .filter((schedule) => schedule.dateText === data.dateText)
                      .map((schedule, index) => (
                        <React.Fragment key={index}>
                          <IonRow style={{ marginTop: 10, alignItems: 'center' }}>
                            <span
                              style={{
                                display: 'inline-block',
                                width: '12px',
                                height: '12px',
                                borderRadius: '50%',
                                backgroundColor: schedule.enabled ? colors.primaryBlue : colors.black40,
                                marginRight: '8px',
                              }}
                            ></span>
                            <IonLabel>{schedule.enabled ? `Working` : 'Not Working'}</IonLabel>
                          </IonRow>
                          {schedule.enabled && (
                            <IonRow style={{ marginTop: 10 }}>
                              <IonLabel>{`${schedule.startTime} - ${schedule.endTime}`}</IonLabel>
                            </IonRow>
                          )}
                        </React.Fragment>
                      ))}
                  </div>
                </IonCol>
              </IonRow>

              <IonRow style={{ marginTop: 20 }}>
                <IonCol>
                  <IonLabel style={{ color: colors.black40, fontWeight: fontWeights.weightBold }}>New</IonLabel>
                  <>
                    <IonRow style={{ marginTop: 10, alignItems: 'center' }}>
                      <span
                        style={{
                          display: 'inline-block',
                          width: '12px',
                          height: '12px',
                          borderRadius: '50%',
                          backgroundColor: data.enabled ? colors.primaryBlue : colors.black40,
                          marginRight: '8px',
                        }}
                      ></span>
                      <IonLabel>{data.enabled ? `Working` : 'Not Working'}</IonLabel>
                    </IonRow>
                    {data.enabled && (
                      <IonRow style={{ marginTop: 10 }}>
                        <IonLabel>{`${data.startTime} - ${data.endTime}`}</IonLabel>
                      </IonRow>
                    )}
                  </>
                </IonCol>
              </IonRow>
              <IonRow style={{ display: 'flex', justifyContent: 'center', marginTop: 30 }}>
                <IonButton style={{ margin: '10px' }} fill='outline' color='dark' onClick={() => handleConfirmation(false)}>
                  <IonLabel style={{ padding: '0 20px', textTransform: 'capitalize' }}>No</IonLabel>
                </IonButton>
                <IonButton style={{ margin: '10px' }} color='dark' onClick={() => handleConfirmation(true)}>
                  <IonLabel style={{ padding: '0 20px', textTransform: 'capitalize' }}>Yes</IonLabel>
                </IonButton>
              </IonRow>
            </IonGrid>
          </StandardContainer>
        </IonContent>
      </IonModal>
    </>
  )
}

function CalendarSelect({ dateText, updateDate, id }) {
  const [value, setValue] = React.useState(dayjs(dateText))

  const handleChange = (newValue) => {
    updateDate(id, new Date(newValue))
    setValue(new Date(newValue))
  }

  return (
    <>
      <IonDatetime
        style={{ background: 'white' }}
        id='datetime'
        presentation='date'
        value={formatDateToISOString(new Date(value))}
        onIonChange={(e) => {
          handleChange(e.detail.value)
        }}
        formatOptions={{
          date: {
            month: 'long',
          },
        }}
      ></IonDatetime>
    </>
  )
}
