import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios'

import ArchiveEmployee from './archive';
import RegisterUser from './registerUser';

export default function Advanced({ employee, getEmployee, businessId }) {

    return (
        <>
            <RegisterUser employee={employee} businessId={businessId} />
            <ArchiveEmployee employee={employee} getEmployee={getEmployee}/>
        </>
    )
}
