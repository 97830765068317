import React, { useState } from 'react';
import { IonItem, IonLabel, IonIcon, IonImg } from '@ionic/react';
import { personOutline } from 'ionicons/icons';
import { colors } from '../../theme/colors';

export default function GetStartedStep({ formData, updateFormData }) {
  const [imageLoaded, setImageLoaded] = useState(false);

  return (
    <>
      <div style={{
        width: '100%',
        height: '370px', // Adjust this to match your image's aspect ratio
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: -20,
        position: 'relative',
      }}>
        <IonImg 
          src={'/assets/location/getStarted.png'} 
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            position: 'absolute',
            top: 0,
            left: 0,
            opacity: imageLoaded ? 1 : 0,
            transition: 'opacity 1s ease-out',
          }}
          onIonImgDidLoad={() => setImageLoaded(true)}
        />
      </div>
      <p style={{marginLeft:22, fontSize:13}}>N E W &nbsp;&nbsp; L O C A T I O N </p>
      <h1 style={{marginLeft:20, marginTop:-6, fontSize:38}}>Tell us about your location</h1>
      <IonItem
        lines="none"
        button
        detail={false}
        style={{
          borderRadius: '10px',
          marginBottom: '1rem',
          backgroundColor: 'white',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%'
        }}
      >
        <p style={{lineHeight:1.5, marginLeft:4, fontSize:18, marginTop:0}}>Getting started with a location is really easy. We will ask you some basic information so we can make sure you are setup for success.</p>

        {/* <IonLabel style={{ padding: '1rem', marginLeft: 20 }}>
          <h2 style={{ fontWeight: 'bold', margin: 0, marginLeft: -24 }}>1. &nbsp;&nbsp;Individual</h2>
          <p style={{ margin: 0, color: '#6c757d' }}>For individuals or freelancers</p>
        </IonLabel>
        <IonIcon icon={personOutline} style={{
          fontSize: '24px'
        }} /> */}
      </IonItem>
    </>
  );
}
