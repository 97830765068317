import { IonButton, IonCard, IonCol, IonContent, IonGrid, IonIcon, IonInput, IonLabel, IonPage, IonRow, IonSpinner, IonToast } from '@ionic/react'
import { useContext, useEffect, useState } from 'react'
import CommonHeader from '../commonComponents/CommonHeader'
import StandardContainer from '../commonComponents/StandardContainer'
import { closeCircleOutline, personSharp, trashOutline } from 'ionicons/icons'
import commonStyles from '../theme/commonstyles.module.css'
import BusinessPermissions from './NewUser/BusinessPermissions'
import { useHistory } from 'react-router'
import checkEmailExists from '../Utils/CheckEmailValidation'
import { AuthContext } from '../auth'
import { colors } from '../theme/colors'
import axios from 'axios'
import LocationPermissions from './NewUser/LocationPermissions'
import EachLocationPermission from './NewUser/EachLocationPermission'

const initialPermissions = {
  owner: false,
  admin: false,
  manager: false,
  professional: false
}

export default function AddNewUser() {
  const { businessData, selectedLocationData } = useContext(AuthContext)
  const [invitationSent, setInvitationSent] = useState(false)
  const [sendingInvitation, setSendingInvitation] = useState(false)
  const [resendInvitation, setResendInvitation] = useState(false)
  const [resendCount, setResendCount] = useState(false)
  let [isDisabled, setIsDisabled] = useState(true)
  const [entitlementsData, setEntitlementsData] = useState(initialPermissions)
  let [email, setEmail] = useState('')
  const [emailErrorMessage, setEmailErrorMessage] = useState('')
  let history = useHistory()

  // Toast state with both message and color
  const [toastState, setToast] = useState({
    isOpen: false,
    message: '',
    color: '',
  })

  const showToastMessage = (message, color) => {
    setToast({
      isOpen: true,
      message,
      color,
    })
  }

  const updateEntitlements = async (entitlements) => {
    setIsDisabled(false)
    setEntitlementsData(entitlements)
  }

  const handleEmail = async (event) => {
    const emailValue = event.detail.value
    setEmail(emailValue)
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    const isValid = emailPattern.test(emailValue)
    if (emailValue) {
      if (isValid) {
        setEmailErrorMessage(false)
        try {
          const checkEmailAndInviteExistsResponse = await checkEmailExists('user', emailValue)
          const errorMessage = checkEmailAndInviteExistsResponse.emailExists
            ? 'Email Already In Use'
            : checkEmailAndInviteExistsResponse.userInvitationExists
            ? 'Invitation already exists for this email'
            : false

          setEmailErrorMessage(errorMessage)
          setIsDisabled(!!errorMessage)
        } catch (error) {
          showToastMessage('Error checking email. Please try again.', 'danger')
        }
      } else {
        setEmailErrorMessage('Email Is Not Valid')
        setIsDisabled(true)
      }
    } else {
      setIsDisabled(true)
      setEmailErrorMessage('')
    }
  }

  const sendInvitation = async () => {
    const addUserInviteInput = {
      email,
      businessId: businessData?._id,
      locationId: selectedLocationData?._id,
      entitlements: entitlementsData,
      domain: 'https://team.clubcuts.com',
    }

    try {
      let response = await axios.post(`/user/addInvite`, addUserInviteInput)
      return response
    } catch (error) {
      showToastMessage('Error sending invitation. Please try again.', 'danger')
      throw error // Re-throw error to handle it in calling function
    }
  }

  const resendInvite = async () => {
    setSendingInvitation(true)
    try {
      const sendInviteResponse = await sendInvitation()

      if (sendInviteResponse && sendInviteResponse.status === 200) {
        showToastMessage('Invitation resent successfully!', 'success')
        setResendInvitation(false)
        setResendCount(true)
      }
    } catch (error) {
      showToastMessage('Failed to resend invitation!', 'danger')
    } finally {
      setSendingInvitation(false)
    }
  }

  const handleInvitation = async () => {
    setSendingInvitation(true)
    setIsDisabled(true)
    try {
      const sendInviteResponse = await sendInvitation()

      if (sendInviteResponse && sendInviteResponse.status === 200) {
        setInvitationSent(true)
        showToastMessage('Invitation sent successfully!', 'success')
        setTimeout(() => {
          setResendInvitation(true)
        }, 2000)
      }
    } catch (error) {
      showToastMessage('Failed to send invitation!', 'danger')
    } finally {
      setSendingInvitation(false)
    }
  }

  return (
    <IonPage id='main-content'>
      <CommonHeader
        saveButton={true}
        loading={sendingInvitation}
        saveDisabled={isDisabled}
        handleSaveButton={handleInvitation}
        title={'New User'}
        backIcon={true}
      />
      <IonContent>
        <StandardContainer>
          <IonCard style={{ padding: '1rem' }}>
            {!invitationSent && (
              <>
                <IonInput
                  fill='outline'
                  labelPlacement='stacked'
                  label='Email'
                  style={{ height: '3rem' }}
                  placeholder='example@test.com'
                  value={email}
                  onIonInput={handleEmail}
                />

                {emailErrorMessage && (
                  <div style={{ marginTop: '0.5rem', marginLeft: '1rem' }}>
                    <IonLabel style={{ color: colors.errorText, fontSize: '12px' }}>{emailErrorMessage}</IonLabel>
                  </div>
                )}
              </>
            )}

            {invitationSent && (
              <IonGrid>
                <IonRow style={{ display: 'flex', alignItems: 'center' }}>
                  <IonCol size='auto'>
                    <IonIcon style={{ height: 30, width: 30 }} icon={personSharp} />
                  </IonCol>
                  <IonCol size='10' style={{ display: 'flex', flexDirection: 'column' }}>
                    <h4 style={{ margin: '0 0 10px 0', fontWeight: 'bold', color: 'black' }}>{email}</h4>
                  </IonCol>
                </IonRow>
                <IonRow style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <div
                    style={{
                      padding: '0.5rem 2rem',
                      backgroundColor: '#d1ffd1',
                      color: 'green',
                      borderRadius: '5rem',
                    }}
                  >
                    <IonLabel>{'Invite Sent. Awaiting Response'}</IonLabel>
                  </div>
                </IonRow>
              </IonGrid>
            )}

            {resendInvitation && !resendCount && (
              <IonButton onClick={resendInvite} expand='full' style={{ marginTop: '1rem' }}>
                {sendingInvitation && <IonSpinner style={{ marginRight: '1rem' }} name='lines' />}
                Send Invite Again
              </IonButton>
            )}

            {resendCount && (
              <IonButton
                strong={true}
                style={{
                  marginTop: '1rem',
                  '--background': '#c2c2c2',
                  '--color': '#101010',
                }}
                expand='full'
                disabled={true}
              >
                Sent
              </IonButton>
            )}
          </IonCard>
          <IonCard style={{ padding: '10px' }}>
            <EachLocationPermission entitlementsData={entitlementsData} updateEntitlements={updateEntitlements} />
          </IonCard>

          {/* <IonCard style={{ padding: '10px' }}>
            <BusinessPermissions invitationSent={invitationSent} updateEntitlements={updateEntitlements} entitlementsData={entitlementsData} />
          </IonCard>
          <IonCard style={{ padding: '10px' }}>
            <LocationPermissions updateEntitlements={updateEntitlements} entitlementsData={entitlementsData} />
          </IonCard> */}
        </StandardContainer>

        {/* Toast for showing messages */}
        <IonToast
          isOpen={toastState.isOpen}
          message={toastState.message}
          duration={2000}
          color={toastState.color}
          position='top'
          onDidDismiss={() => setToast({ isOpen: false, message: '', color: '' })}
        />
      </IonContent>
    </IonPage>
  )
}
