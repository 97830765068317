import { editAppointmentTimeIntervals } from '../../StaticData/StaticData'
import { IonSelect, IonSelectOption } from '@ionic/react'

const times = editAppointmentTimeIntervals

export default function TimeSelector({ dayKey, fieldKey, value, handleChange }) {
  const valueChanged = (event) => {
    handleChange(dayKey, fieldKey, event.target.value)
  }

  return (
    <div>
      <IonSelect onIonChange={valueChanged} value={value} placeholder={value}>
        {times.map((v) => (
          <IonSelectOption value={v}>{v}</IonSelectOption>
        ))}
      </IonSelect>
    </div>
  )
}
