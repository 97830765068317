import React, { useContext, useState } from 'react'
import { CardNumberElement, CardExpiryElement, CardCvcElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import axios from 'axios'
import { IonButton, IonCol, IonGrid, IonIcon, IonLoading, IonRow, IonSpinner, IonToast } from '@ionic/react'
import { card, cardOutline } from 'ionicons/icons'
import { colors } from '../../../theme/colors'
import StandardCenterCenter from '../../../commonComponents/StandardCenterCenter'
import { AuthContext } from '../../../auth'

const styles = {
  element: {
    height: 40,
    padding: '10px 12px',
    fontSize: 16,
  },
  submitButton: {
    height: 40,
    borderRadius: 4,
    backgroundColor: colors.primaryBlue,
    color: 'white',
    position: 'absolute',
    bottom: '1rem',
    width: '94%',
  },
}

const PaymentForm = ({ setToast, buttonText, addCardToFileSuccess, customerId }) => {
  const stripe = useStripe()
  const elements = useElements()
  const [errorMessage, setErrorMessage] = useState('')
  const [submitting, setSubmitting] = useState(false)

  const handleSubmit = async (event) => {
    event.preventDefault()

    if (!stripe || !elements) {
      return
    }

    setSubmitting(true)

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardNumberElement),
    })

    if (error) {
      setErrorMessage(error.message)
      setToast({ isOpen: true, message: error.message, color: 'danger' })
    } else {
      try {
        // Send the PaymentMethod ID to your server to save the payment details to the user's Stripe customer
        const { data } = await axios.post(`/customer_v2/addCardStripe`, {
          paymentMethodId: paymentMethod.id,
          customerId: customerId
        })

        // { success: true }
        // Reset the form fields
        const cardNumberElement = elements.getElement(CardNumberElement)
        const cardExpiryElement = elements.getElement(CardExpiryElement)
        const cardCvcElement = elements.getElement(CardCvcElement)
        if (data.status == 500) {
          setToast({ isOpen: true, message: data.message, color: 'danger' })

          setErrorMessage(data.message)
        } else {
          setToast({ isOpen: true, message: 'Card added successfully!', color: 'success' })
          addCardToFileSuccess()
        }
        setTimeout(() => {
          cardNumberElement.clear()
          cardExpiryElement.clear()
          cardCvcElement.clear()
          addCardToFileSuccess()
        }, 2000)
      } catch (error) {
        setErrorMessage(error.message)
        setToast({ isOpen: true, message: error.message, color: 'danger' })
      }
    }
    setSubmitting(false)
  }

  return (
    <form onSubmit={handleSubmit} style={{ margin: 10 }}>
      <IonGrid
        style={{
          border: `1px solid ${colors.black40}`,
          borderRadius: 4,
          padding: 0,
          margin: '2rem 1rem',
        }}
      >
        <IonRow>
          <IonCol
            size='auto'
            style={{
              padding: 0,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              paddingLeft: 12,
              backgroundColor: 'white',
            }}
          >
            <IonIcon icon={cardOutline} size='large' />
          </IonCol>
          <IonCol style={{ padding: 0, height: 50 }}>
            <CardNumberElement style={styles.element} id='cardNumber' />
          </IonCol>
        </IonRow>
        <IonRow style={{ borderTop: `1px solid ${colors.black40} ` }}>
          <IonCol style={{ padding: 0 }}>
            <CardExpiryElement id='cardExpiry' />
          </IonCol>
          <IonCol style={{ borderLeft: `1px solid ${colors.black40} `, padding: 0 }}>
            <CardCvcElement className='StripeElement' id='cardCvc' />
          </IonCol>
        </IonRow>
      </IonGrid>
      <StandardCenterCenter>
        <img src='/assets/poweredByStripe_black.svg' alt='' style={{ height: 22, marginBottom: 20 }} />
      </StandardCenterCenter>
      <IonButton expand='block' color={'dark'} type='submit' disabled={submitting} style={styles.submitButton}>
        {!submitting ? <>{buttonText}</> : <IonSpinner name='dots' />}
      </IonButton>
      {errorMessage && <div style={{ marginTop: 10, color: 'red' }}>{errorMessage}</div>}
    </form>
  )
}

const AddCardToFile = ({ buttonText, addCardToFileSuccess, customerId }) => {
  const [toast, setToast] = useState({ isOpen: false, message: '', color: 'success' })
  const { selectedLocationData } = useContext(AuthContext)
  const stripePromise = loadStripe(selectedLocationData?.stripeAPIPublicKey)
  console.log(selectedLocationData?.stripeAPIPublicKey)
  console.log(selectedLocationData)

  if(!selectedLocationData?.stripeAPIPublicKey) {
    return (
      <div style={{
        border: '1px solid rgba(255, 0, 0)',
        padding: '15px',
        borderRadius: '5px',
        marginBottom: '20px'
      }}>
        <p style={{ color: 'red', marginBottom: '10px', fontWeight: 'bold' }}>
          Warning: This location's Stripe key has not been set up yet. Go to the location details page to set it up.
        </p>
        {/* <IonButton
          color="danger"
          fill="outline"
          routerLink={`/core/locations/${selectedLocationData._id}`}
        >
          Click here to go to the settings page
        </IonButton> */}
      </div>
    );
  }


  return (
    <Elements stripe={stripePromise}>
      <PaymentForm buttonText={buttonText} addCardToFileSuccess={addCardToFileSuccess} customerId={customerId} setToast={setToast}/>
      <IonToast
        position='top'
        isOpen={toast.isOpen}
        onDidDismiss={() => setToast({ ...toast, isOpen: false })}
        message={toast.message}
        duration={2000}
        color={toast.color}
      />
    </Elements>
  )
}

export default AddCardToFile
