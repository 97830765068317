import React, { useState, useEffect } from 'react'
import { IonCard, IonInput, IonItem, IonLabel, IonRow, IonCol, IonGrid, IonText } from '@ionic/react'

export default function DetailsView({ location, updateLocation }) {
  const [productsTaxRate, setProductsTaxRate] = useState('')
  const [servicesTaxRate, setServicesTaxRate] = useState('')

  useEffect(() => {
    if (location?.purchase?.productsTaxRate !== undefined) {
      setProductsTaxRate((location.purchase.productsTaxRate * 100).toFixed(2))
    }
    if (location?.purchase?.servicesTaxRate !== undefined) {
      setServicesTaxRate((location.purchase.servicesTaxRate * 100).toFixed(2))
    }
  }, [])

  const updateProductsTaxRate = (value) => {
    setProductsTaxRate(value)
    const decimalValue = parseFloat(value) / 100
    updateLocation({ ...location, purchase: { ...location.purchase, productsTaxRate: decimalValue } })
  }

  const updateServicesTaxRate = (value) => {
    setServicesTaxRate(value)
    const decimalValue = parseFloat(value) / 100
    updateLocation({ ...location, purchase: { ...location.purchase, servicesTaxRate: decimalValue } })
  }

  if (!location) return <IonText>Loading...</IonText>

  return (
    <IonCard style={{ padding: 20 }}>
      <IonGrid>
        <IonRow>
          <IonCol size='6'>
            <IonItem>
              <IonLabel position='stacked'>Products Tax Rate (%)</IonLabel>
              <IonInput 
                type='number' 
                value={productsTaxRate} 
                onIonInput={(e) => updateProductsTaxRate(e.detail.value)} 
                debounce={300}
              />
            </IonItem>
          </IonCol>
          <IonCol size='6'>
            <IonItem>
              <IonLabel position='stacked'>Services Tax Rate (%)</IonLabel>
              <IonInput 
                type='number' 
                value={servicesTaxRate} 
                onIonInput={(e) => updateServicesTaxRate(e.detail.value)} 
                debounce={300}
              />
            </IonItem>
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonCard>
  )
}
