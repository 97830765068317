import { useState, useEffect, useContext } from 'react'
import axios from 'axios'
import { IonItem, IonLabel, IonList, IonSpinner } from '@ionic/react'
import { AuthContext } from '../auth'
import CheckIsManagerOrAdmin from '../Utils/CheckIsManagerOrAdmin'
import { AccessControl } from '../AccessControl'

function StatsBlock({ employee, startDate, endDate, viewMode, shouldLoad }) {
  const [stats, setStats] = useState()
  const [statsV2, setStatsV2] = useState()
  const [loading, setLoading] = useState(true)
  const { businessData, locationId, userData } = useContext(AuthContext)
  const [appointmentStats, setAppointmentStats] = useState(false)
  const [serviceValueStats, setServiceValueStatsData] = useState({ loading: false, data: null })
  const userEntitlements = CheckIsManagerOrAdmin(userData, businessData, locationId)
  const formatDate = (date) => {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear()

    if (month.length < 2) month = '0' + month
    if (day.length < 2) day = '0' + day

    return [year, month, day].join('-')
  }
  async function getStats() {
    try {
      setLoading(true)
      if (startDate && endDate && shouldLoad) {
        let start = formatDate(startDate)
        let end = formatDate(endDate)
        let r = await axios.get(`/employee/barberStats?barberId=${employee._id}&startDate=${start}&endDate=${end}`)
        console.log('BarberStats', r)
        let r2 = await axios.get(`/employee/barberStatsV2?barberId=${employee._id}&startDate=${start}&endDate=${end}`)
        console.log('BarberStatsV2', r2)

        setStatsV2(r2.data)
        setStats(r.data)

      }
    } catch (error) {
      console.error('Failed to get stats.', error) // Log the error for debugging
      alert('Failed to get stats.')
    } finally {
      setLoading(false)
    }
  }
  // Convert minutes to days, hours, and rounded minutes
  const convertToDaysHoursMinutes = (minutes) => {
    const days = Math.floor(minutes / (24 * 60))
    const remainingHours = Math.floor((minutes % (24 * 60)) / 60)
    const remainingMinutes = Math.round(minutes % 60)

    let result = ''

    if (days > 0) {
      result += `${days}d `
    }

    if (remainingHours > 0) {
      result += `${remainingHours}h `
    }

    if (remainingMinutes > 0) {
      result += `${remainingMinutes}min`
    }

    return result.trim()
  }
  const getBarberStats = async () => {
    if (businessData?._id && employee?._id && startDate && endDate && shouldLoad) {
      try {
        let start = formatDate(startDate)
        let end = formatDate(endDate)
        const statsResponse = await axios.get(
          `/employee/appointmentStats?businessId=${businessData?._id}&barberId=${employee?._id}&startDate=${start}&endDate=${end}`
        )
        if (statsResponse && statsResponse.status === 200) {
          setAppointmentStats(statsResponse?.data)
        }
      } catch (error) { }
    }
  }

  const getEmployeeServiceValueStats = async () => {
    if (businessData?._id && employee?._id && startDate && endDate && shouldLoad) {
      setServiceValueStatsData({ loading: true, data: null })
      let start = formatDate(startDate)
      let end = formatDate(endDate)
      try {
        const response = await axios.get(
          `/employee/serviceValueStats?businessId=${businessData?._id}&barberId=${employee?._id}&startDate=${start}&endDate=${end}`
        )
        if (response.status == 200) {
          setServiceValueStatsData({ loading: false, data: response.data })
        }
      } catch (error) {
        setServiceValueStatsData({ loading: false, data: null, error: error })
      }
    }
  }

  useEffect(() => {
    getStats()
    getBarberStats()
    getEmployeeServiceValueStats()
  }, [viewMode, startDate, endDate, shouldLoad])

  const statistics = [
    { label: 'Total # of Services', v2Value: 'totalCompletedAppointments' },
    { label: 'Value of Services (BSV)', prefix: '$', v2Value: 'blendedServiceValue' },
    { label: 'Value of Products', prefix: '$', v2Value: 'productValue' },
    { label: 'Tips (Credit Card)', prefix: '$', v2Value: 'digitalTipValue' },
    { label: 'Tips (Cash)', prefix: '$', v2Value: 'cashTipValue' },
    // { label: 'Total Tips',  prefix: '$', v2Value: 'totalTipValue' },
    { label: 'Subscriptions Sold', value: 'countSubscriptionsSold' },
  ]

  const legacyStatistics = [
    { label: 'Legacy Service Value', prefix: '$', v2Value: 'serviceValue' },
  ]

  const additionalStatistics = [
    { label: 'Avg Wait Time', value: 'averageWaitTime' },
    { label: 'Avg Late End Time', value: 'avgLateEndTime' },
    { label: 'Avg Service Time Min', value: 'avgServiceTimeMin' },
    { label: 'Median Service Time Min', value: 'medianServiceTime' },
    {
      label: 'Median Expected Time Difference',
      value: 'medianExpectedTimeDifference',
    },
  ]
  const serviceValueStatsArr = [
    { label: 'Service Value for a Subscriber', value: 'SVSub', v2Value: 'serviceValueSubscribers' },
    { label: 'Service Value for a Non-Subscriber', value: 'SVNonSub', v2Value: 'serviceValueNonSubscribers' },
    { label: 'Blended Service Value', value: 'BlendedSV', v2Value: 'blendedServiceValue' },
  ]
  return (
    <>
      <IonList inset={true} mode='ios'>
        {(userEntitlements?.isAdmin || userEntitlements?.isProfessional || userEntitlements?.isManager) && (
          <>
            {statistics.map((statistic) => {
              return (
                <IonItem key={statistic.label}>
                  <IonLabel>
                    <strong>{statistic.label}</strong>
                  </IonLabel>
                  <IonLabel slot='end'>
                    {loading ? (
                      <IonSpinner name='dots' style={{ marginTop: -9, marginBottom: -9 }} />
                    ) : (
                      <>
                        {statistic?.prefix ? statistic?.prefix : null}
                        {statistic?.value ? stats?.[statistic?.value] || 0 : null}
                        {statistic?.v2Value ? `${statsV2?.[statistic.v2Value] || 0}` : null}
                      </>
                    )}
                  </IonLabel>
                </IonItem>
              )
            })}
          </>
        )}
      </IonList>

      <AccessControl componentName='legacy_service_value'>
        <h6 style={{ marginLeft: 18 }}>Legacy Metrics</h6>

        <IonList inset={true} mode='ios'>
        <>
            {legacyStatistics.map((statistic) => {
              return (
                <IonItem key={statistic.label}>
                  <IonLabel>
                    <strong>{statistic.label}</strong>
                  </IonLabel>
                  <IonLabel slot='end'>
                    {loading ? (
                      <IonSpinner name='dots' style={{ marginTop: -9, marginBottom: -9 }} />
                    ) : (
                      <>
                        {statistic?.prefix ? statistic?.prefix : null}
                        {statistic?.value ? stats?.[statistic?.value] || 0 : null}
                        {statistic?.v2Value ? `${statsV2?.[statistic.v2Value] || 0}` : null}
                      </>
                    )}
                  </IonLabel>
                </IonItem>
              )
            })}
          </>
        </IonList>
      </AccessControl>

      {userEntitlements?.isAdmin
        ? <>
          <h6 style={{ marginLeft: 18 }}>Advanced Admin Metrics</h6>
          <p style={{ marginLeft: 18, fontSize: 12 }}>Value of Services is now the same as Blended Service Value</p>
          <IonList inset={true} mode='ios'>
            {serviceValueStatsArr.map((statistic) => {
              const statValue = serviceValueStats?.data?.[statistic.value]?.toFixed(2)
              return (
                <IonItem key={serviceValueStats.label}>
                  <IonLabel>
                    <strong>{statistic.label}</strong>
                  </IonLabel>
                  <IonLabel slot='end'>
                    {serviceValueStats.loading ? <IonSpinner name='dots' style={{ marginTop: -9, marginBottom: -9 }} /> :
                      <>
                        {statistic?.v2Value ? `$${statsV2?.[statistic.v2Value] || 0}` : null}
                      </>
                    }

                  </IonLabel>
                </IonItem>
              )
            })}
          </IonList>
        </>
        : null
      }


      {userEntitlements?.isAdmin
        ?
        <>
          <h6 style={{ marginLeft: 18 }}>Timing Metrics</h6>

          <IonList inset={true} mode='ios'>
            {additionalStatistics.map((statistic) => {
              const statValue = appointmentStats?.[statistic.value]?.toFixed(2)
              return (
                <IonItem key={statistic.value}>
                  <IonLabel>
                    <strong>{statistic.label}</strong>
                  </IonLabel>
                  <IonLabel slot='end'>
                    {loading ? (
                      <IonSpinner name='dots' style={{ marginTop: -9, marginBottom: -9 }} />
                    ) : statValue > 60 ? (
                      convertToDaysHoursMinutes(statValue) || 0
                    ) : (
                      statValue || 0
                    )}

                  </IonLabel>
                </IonItem>
              )
            })}
          </IonList>
        </>
        : null
      }

    </>
  )
}
export default StatsBlock
