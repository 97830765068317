import { useState } from 'react'
import { IonCard, IonIcon, IonLabel, IonItem, IonGrid, IonRow, IonList, IonToggle, IonSelect, IonSelectOption, IonInput } from '@ionic/react'
import { pencilOutline } from 'ionicons/icons'
import PhoneNumberTextField from '../commonComponents/phoneNumberTextFieldFlat'
import LocationsMultiSelect from './locationsMultiSelect'
import ProfilePicDialog from './profilePicDialog'
import Avatar from '../commonComponents/avatar'
import { colors } from '../theme/colors'

export default function DetailsView({ employee, updateEmployee, checkEmployee, locations, locationId, businessId }) {
  const [profilePicDialogOpen, setProfilePicDialogOpen] = useState(false)
  const [cacheBuster, setCacheBuster] = useState(1)

  const onValueChange = (field, value) => {
    updateEmployee({ ...employee, [field]: value })
  }

  const openProfilePicModal = () => {
    setProfilePicDialogOpen(true)
  }

  const closeProfilePicModal = () => {
    setCacheBuster(new Date().getTime())
    setProfilePicDialogOpen(false)
  }

  if (!employee) return 'loading...'

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', padding: 30 }}>
        <div onClick={openProfilePicModal} style={{ cursor: 'pointer' }}>
          <Avatar employee={employee} businessId={businessId} cacheBuster={cacheBuster} />
          <IonIcon icon={pencilOutline} style={{ marginLeft: -20 }} color={'primary'} />
        </div>
        <div style={{ textAlign: 'center' }}>
          <h2>
            {employee.firstName} {employee.lastName}
          </h2>
          <h3>{employee.role === 'Barber' ? 'Professional' : employee.role}</h3>
        </div>


      </div>
      <div style={{ width: '100%', backgroundColor: 'transparent', height: 10 }} />
      <div style={{ backgroundColor: 'white' }}>
        <h6 style={{ marginLeft: 10, paddingTop:20 }}>Profile Visibility</h6>
        <IonList>
          <IonItem>

            <IonToggle
              checked={employee.isPublic ?? false}
              onIonChange={(e) => {
                onValueChange('isPublic', e.detail.checked)
              }}
            >
              {employee.isPublic ? 'Public' : 'Private'}
            </IonToggle>

          </IonItem>
          <p style={{ margin: 16 }}>{employee.isPublic ? 'This employee is visible to customers to book appointments.' : 'This employee is not visible to customer to book appointments.'}</p>

        </IonList>
      </div>
      <div style={{ width: '100%', backgroundColor: 'transparent', height: 10 }} />
      <IonList>
        <IonItem>
          <IonInput label="First Name" labelPlacement="fixed" labelStyle={{ width: '100px' }} placeholder='John' value={employee.firstName} onIonInput={(e) => onValueChange('firstName', e.detail.value)}></IonInput>
        </IonItem>

        <IonItem>
          <IonInput label="Last Name" labelPlacement="fixed" labelStyle={{ width: '100px' }} placeholder='Smith' value={employee.lastName} onIonInput={(e) => onValueChange('lastName', e.detail.value)}></IonInput>
        </IonItem>

        <IonItem>
          <IonSelect label="Role" labelPlacement="fixed" labelStyle={{ width: '100px' }} value={employee.role} onIonChange={(e) => onValueChange('role', e.detail.value)}>
            <IonSelectOption value={'Barber'}>Professional</IonSelectOption>
            <IonSelectOption value={'Receptionist'}>Receptionist</IonSelectOption>
            <IonSelectOption value={'Office'}>Office</IonSelectOption>
          </IonSelect>
        </IonItem>

        <IonItem>
          <IonInput label="Email" labelPlacement="fixed" labelStyle={{ width: '100px' }} value={employee.email} onIonInput={(e) => onValueChange('email', e.detail.value)}></IonInput>
        </IonItem>

        <IonItem>
          <PhoneNumberTextField label="Phone" labelPlacement="fixed" labelStyle={{ width: '100px' }} value={employee.phoneNumber} onChange={(e) => onValueChange('phoneNumber', e.target.value)} />
        </IonItem>

        {/* <IonItem>
          <LocationsMultiSelect locations={locations} employeeLocations={employee.locations} onValueChange={onValueChange} locationId={locationId} />
        </IonItem> */}
      </IonList>
      <ProfilePicDialog open={profilePicDialogOpen} handleClose={closeProfilePicModal} locationId={locationId} businessId={businessId} employee={employee} />
    </div >
  )
}
