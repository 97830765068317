import React, { useState } from 'react'
import { IonInput, IonItem, IonLabel, IonToast } from '@ionic/react'

const PhoneNumberInput = ({ value, onChange }) => {
  const [inputValue, setInputValue] = useState(value)
  const [showToast, setShowToast] = useState(false)

  // Handle input changes and format the phone number
  const handleChange = (event) => {
    const rawValue = event.detail.value || ''
    if (rawValue.length > 14) {
      setShowToast(true)
      return
    }
    const formattedValue = formatPhoneNumber(rawValue)
    setInputValue(formattedValue)
    onChange(event)
  }

  // Format phone number as (xxx) xxx-xxxx
  const formatPhoneNumber = (value) => {
    const cleaned = ('' + value).replace(/\D/g, '')
    const match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/)
    if (match) {
      return `${match[1] ? `(${match[1]}` : ''}${match[2] ? `) ${match[2]}` : ''}${match[3] ? `-${match[3]}` : ''}`
    }
    return value
  }

  return (
    <>
      <IonItem>
        <IonLabel position='stacked'>Phone Number</IonLabel>
        <IonInput
          type='tel'
          value={inputValue}
          onIonInput={handleChange}
          placeholder='(303) 000-0000'
          maxlength={14} // Limit input length to 14 characters (including formatting characters)
        />
      </IonItem>
      <IonToast position='top' isOpen={showToast} onDidDismiss={() => setShowToast(false)} message='Phone number must be 14 digits long' duration={2000} />
    </>
  )
}

export default PhoneNumberInput
