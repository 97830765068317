import axios from 'axios'
import { useHistory } from 'react-router-dom'
import { IonButton, IonCard, IonToast } from '@ionic/react'
import { useState } from 'react'

export default function ArchiveEmployee({ employee, getEmployee }) {
  const history = useHistory()
  const [toast, setToast] = useState({ isOpen: false, message: '', color: '' })

  // Common handle toast function
  const showToastMessage = (message, color = 'success') => {
    setToast({ isOpen: true, message, color })
  }

  const archiveEmployee = async () => {
    if (window.confirm('Are you sure you want to archive the employee?')) {
      try {
        await axios.delete(`/employee?id=${employee._id}`)
        history.push(-1)
        showToastMessage('Employee archived successfully.')
      } catch (error) {
        showToastMessage("I'm sorry, there was a problem. Please try again later. AE-1002", 'danger')
      }
    }
  }

  const unArchiveEmployee = async () => {
    if (window.confirm('Are you sure you want to unarchive the employee?')) {
      try {
        await axios.post(`/employee/unarchive`, { id: employee._id })
        getEmployee()
        showToastMessage('Employee unarchived successfully.')
      } catch (error) {
        showToastMessage("I'm sorry, there was a problem. Please try again later. UAE-1001", 'danger')
      }
    }
  }

  return (
    <>
      <IonCard style={{ padding: '20px', display: 'flex', justifyContent: 'center' }}>
        {employee.isArchived ? (
          <IonButton color='warning' onClick={unArchiveEmployee}>
            Unarchive Employee
          </IonButton>
        ) : (
          <IonButton color='warning' onClick={archiveEmployee}>
            Archive Employee
          </IonButton>
        )}
      </IonCard>

      <IonToast
        position='top'
        isOpen={toast.isOpen}
        onDidDismiss={() => setToast({ ...toast, isOpen: false })}
        message={toast.message}
        color={toast.color}
        duration={2000}
      />
    </>
  )
}
