// rescheduleAppointmentStyles.js

const styles = {
  container: {
    flex: '1 1 0',
    padding: 8,
    background: '#EBEBEB',
    borderRadius: 12,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 8,
    display: 'inline-flex',
  },

  iconContainer: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 4,
    display: 'flex',
  },

  iconWrapper: {
    width: 24,
    height: 24,
    position: 'relative',
  },

  icon: {
    height: 30,
    width: 30,
    marginBottom: '0.5rem',
    color: '#000000', // Replaced with color from the colors module
  },

  text: {
    color: '#525252',
    fontSize: 16,
    fontFamily: 'Figtree',
    fontWeight: '600',
    lineHeight: '24px',
    wordWrap: 'break-word',
  },
}

export default styles
