import React, { useState, useEffect, useContext } from 'react'
import ResultsList from './resultsList'
import axios from 'axios'
import { AuthContext } from '../auth'
import {
  IonCard,
  IonCol,
  IonContent,
  IonGrid,
  IonLabel,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonRow,
  IonSegment,
  IonSegmentButton,
  IonToolbar,
} from '@ionic/react'
import AddHeader from '../commonComponents/AddHeader'
import StandardContainer from '../commonComponents/StandardContainer'
import { useLocation } from 'react-router-dom'
import MiniHeader from '../commonComponents/MiniHeader'
import SubscriptionsList from './SubscriptionsList'
import CancelledSubscriptionsList from './CancelledSubscriptions/CancelledSubscriptionsList'

const styles = {
  column: {
    padding: 0,
    margin: -4,
  },
  card: {
    padding: 16,
    height: 110,
    margin: 10,
  },
}

export default function Subscriptions({ locationId }) {
  const { businessData } = useContext(AuthContext)
  const [subscriptions, setSubscriptions] = useState([])
  const [newInLastWeek, setNewInLastWeek] = useState(null)
  const [soldByEmployees, setSoldByEmployees] = useState(0)
  const [addLocationDialogOpen, setAddLocationDialogOpen] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState('')
  const [loading, setLoading] = React.useState(false)
  const [searchValue, setSearchValue] = useState()
  const windowLocation = useLocation()
  const [activeTab, setActiveTab] = useState('subscriptions')

  return (
    <IonPage>
      <AddHeader
        additionalToolbar={
          <IonToolbar color='white' style={{ backgroundColor: 'white', color: 'black' }}>
            <IonSegment
              style={{ display: 'flex', justifyContent: 'flex-start' }}
              value={activeTab}
              color='dark'
              onIonChange={(e) => {
                setActiveTab(e?.detail?.value)
              }}
            >
              <IonSegmentButton style={{ minWidth: 'fit-content' }} value='subscriptions'>
                <IonLabel>Subscriptions</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton style={{ minWidth: 'fit-content' }} value='cancelledSubscriptions'>
                <IonLabel>Cancelled Subscriptions</IonLabel>
              </IonSegmentButton>
            </IonSegment>
          </IonToolbar>
        }
        title={'Subscriptions'}
        loading={loading}
      />

      <IonContent>
        <StandardContainer>
          {activeTab == 'subscriptions' && <SubscriptionsList />}
          {activeTab == 'cancelledSubscriptions' && <CancelledSubscriptionsList />}
        </StandardContainer>
      </IonContent>
    </IonPage>
  )
}
