import { IonButton, IonModal, IonHeader, IonToolbar, IonTitle, IonContent, IonFooter } from '@ionic/react'
import ImageUpload from '../commonComponents/uploadImage'

export default function ProfilePicDialog({ open, handleClose, businessId, locationId, employee }) {
  function successCallback() {
    handleClose(true)
  }

  function failureCallback() {
    alert('Sorry, there was an issue')
  }

  return (
    <IonModal isOpen={open} onDidDismiss={handleClose}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Profile Pic Upload</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <ImageUpload
          successCallback={successCallback}
          failureCallback={failureCallback}
          postUrl='/employee/profilePic'
          headerMetadata={{ cc_businessId: businessId, cc_employeeId: employee._id }}
        />
      </IonContent>

      <IonFooter>
        <IonButton onClick={handleClose} expand='block' color='medium'>
          Cancel
        </IonButton>
        {/* Uncomment and adjust if needed */}
        {/* <IonButton disabled={isDisabled} expand="block" color="primary" onClick={createButtonClicked}>Create</IonButton> */}
      </IonFooter>
    </IonModal>
  )
}
