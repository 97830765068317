import { useState, useEffect } from 'react'
import { IonModal, IonContent } from '@ionic/react'

import axios from 'axios'
import StandardContainer from '../commonComponents/StandardContainer'
import StepController from './stepController'

function SchedulerModal({ handlePrevWeek, handleNextWeek, customer, isOpen, setIsOpen, onSuccess, customerId, isWalkin }) {
  const [subscription, setSubscription] = useState({ data: null, loading: false, error: null })
  const [isSubscribed, setIsSubscribed] = useState({ data: null, loading: false, error: null })

  const getCustomerSubscription = async () => {
    if (customerId) {
      try {
        setSubscription({ data: subscription.data, loading: true, error: null })
        let response = await axios.get(`/subscription/byCustomerId?id=${customerId}&source=schdulerModal`)
        setSubscription({ data: response.data, loading: false, error: null })
        setIsSubscribed(response?.data?.isSubscribed || false)
      } catch (error) {
        setSubscription({ data: null, loading: false, error: 'There was an issue getting your data' })
      }
    }
  }

  useEffect(() => {
    getCustomerSubscription()
  }, [customerId])

  return (
    <IonModal isOpen={isOpen}>
      <IonContent className='regularBackground'>
        <StandardContainer>
          <StepController
            handlePrevWeek={handlePrevWeek}
            handleNextWeek={handleNextWeek}
            choosecustomer={false}
            isOpen={isOpen}
            closeModal={() => {
              setIsOpen({ isOpen: false, isWalkin: false })
            }}
            isWalkin={isWalkin}
            setSubscriptionReferrer={() => {}}
            customer={customer}
            customerId={customerId}
            getAppointments={() => {}}
            customerSubscription={subscription?.data?.subscription}
            customerIsSubscribed={isSubscribed}
          />
        </StandardContainer>
      </IonContent>
    </IonModal>
  )
}

export default SchedulerModal
