import { IonGrid, IonIcon, IonLabel, IonRow } from '@ionic/react'
import React from 'react'
import Avatar from '../../commonComponents/avatar'
import { timeOutline } from 'ionicons/icons'

// Styles
const styles = {
  breakEvent: {
    position: 'relative',
    color: '#000',
    padding: '0.5rem',
    margin: '10px 0',
    borderRadius: '5px',
  },
}

export default function BreakEvent({ appointment, businessData, index }) {
  return (
    <div className='break-event' key={index} style={styles.breakEvent}>
      <IonGrid>
        <IonRow style={{ width: '100%', margin: '2px 0' }}>
          <IonLabel
            style={{
              fontSize: 14,
              fontWeight: 'bold',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            Break
          </IonLabel>
        </IonRow>
        <IonRow style={{ position: 'absolute', right: 10, top: 10 }}>
          <Avatar employee={appointment.employee?.[0]} businessId={businessData?._id} mini />
        </IonRow>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <IonIcon style={{ height: 8, width: 8, marginRight: 2 }} icon={timeOutline} />
          <IonLabel style={{ fontSize: 10 }}>
            {appointment.startTimeText} - {appointment.endTimeText}
          </IonLabel>
        </div>
      </IonGrid>
    </div>
  )
}
