import { useContext, useEffect, useState } from 'react'
import { addHours, format, startOfDay } from 'date-fns'
import { AuthContext } from '../auth'
import { setTimeFromDateStr } from './CalendarView5/Utils'
import { getWeekday } from '../utils'
import { IonButton, IonCheckbox, IonCol, IonContent, IonDatetime, IonGrid, IonIcon, IonItem, IonLabel, IonList, IonModal, IonRow, IonToast } from '@ionic/react'
import CalendarView5Header from './CalendarView5/CalendarView5Header'
import StoreClosedCard from './CalendarView5/StoreClosedCard'
import GetLocationDataBasedOnLocationId from '../Utils/GetLocationDataBasedOnLocationId'
import VerticalEvent from './CalendarView5/VerticalEvent'
import HorizontalEvent from './CalendarView5/HorizontalEvent'
import ResizableModal from './NewCalendarView/ResizableModal'
import { calculateNewEventStyles, organizeAppointmentsByHour, organizeEventsByName } from './NewCalendarView/helpers'
import { styles } from './NewCalendarView/styles'
import WalkinAppointments from './NewCalendarView/WalkinAppointments'
import Breaks from './NewCalendarView/Breaks'
import EmployeeFreeTime from './NewCalendarView/EmployeeFreeTime'
import { colors } from '../theme/colors'
import Avatar from '../commonComponents/avatar'
import CommonHeader from '../commonComponents/CommonHeader'
import moment from 'moment-timezone'
import axios from 'axios'
import { fontWeights } from '../theme/typography'
import { cafeOutline, calendarOutline, callOutline, footstepsOutline, resizeOutline } from 'ionicons/icons'
import StandardContainer from '../commonComponents/StandardContainer'
import StepController from '../Scheduler/stepController'
import AddBreak from './CalendarView5/AddBreak'
import { useHistory } from 'react-router'
const CalendarDayView = ({
  getBreaks,
  employees,
  selectedStylists,
  selectedTypes,
  refreshAppointments,
  breaks,
  appointments,
  selectedDate,
  handleView,
  loading,
  setSelectedDate,
  filteredBarbers,
  handleStylistChange,
  handleTypeChange,
  setDefaultView,
  defaultView,
}) => {
  console.log(appointments, 'appointments')
  const formatDateToISO = (date) => {
    const pad = (n) => (n < 10 ? '0' + n : n)
    const year = date.getFullYear()
    const month = pad(date.getMonth() + 1)
    const day = pad(date.getDate())
    const hours = pad(date.getHours())
    const minutes = pad(date.getMinutes())
    const seconds = pad(date.getSeconds())
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`
  }
  const [isDesktop, setIsDesktop] = useState(false)
  const [locationSchedule, setLocationSchedule] = useState(false)
  const { locationData, locationId } = useContext(AuthContext)
  const [personEvents, setPersonEvents] = useState({})
  const [walkinsList, setFilteredWalkins] = useState([])
  const [allDayEvents, setAllDayEvents] = useState([])
  const [hours, setHours] = useState([])
  const [expandedColumn, setExpandedColumn] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const [selectedEvent, setSelectedEvent] = useState(false)
  const [locationStartTime, setLocationStartTime] = useState(false)
  const [regularAppointments, setAppointments] = useState([])
  const [startDate, setStartDate] = useState(moment(selectedDate))
  const [view, setView] = useState('week') // State to toggle between week and month view
  const [date, setDate] = useState(formatDateToISO(new Date(selectedDate)))
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isBreakModalOpen, setIsBreakModalOpen] = useState(false)
  const { businessData } = useContext(AuthContext)
  const [isWalkin, setIsWalkin] = useState(false)
  const startOfWeek = moment(selectedDate).startOf('week') // Start of the current selected week
  const [apptModalOpen, setApptModalOpen] = useState(false)
  const [toast, setToast] = useState({ isOpen: false, message: '', color: '' })
  const history = useHistory()
  const handleExpandClick = (person) => {
    setExpandedColumn(expandedColumn === person ? null : person)
  }

  const handlePrevWeek = () => {
    const newSelectedDate = new Date(selectedDate)
    newSelectedDate.setDate(newSelectedDate.getDate() - 7)
    setSelectedDate(newSelectedDate)
  }

  const handleNextWeek = (date) => {
    const newSelectedDate = new Date(date)
    newSelectedDate.setDate(newSelectedDate.getDate() + 7)
    setSelectedDate(newSelectedDate)
  }

  useEffect(() => {
    setExpandedColumn(null)
  }, [selectedDate])

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 768) // Example breakpoint, adjust as needed
    }

    // Initial check
    handleResize()

    // Event listener for window resize
    window.addEventListener('resize', handleResize)

    // Cleanup
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    if (locationId) {
      const selectedLocationData = GetLocationDataBasedOnLocationId(locationData, locationId)

      setLocationSchedule(selectedLocationData.standardSchedule)
      let weekday = getWeekday(selectedDate)
      let standardScheduleForDay = selectedLocationData?.standardSchedule?.[weekday]
      let startTime = standardScheduleForDay?.startTime

      if (startTime) {
        startTime = setTimeFromDateStr(startTime)
        setLocationStartTime(startTime)
      }
    }
  }, [locationId])

  useEffect(() => {
    if (locationId) {
      let weekday = getWeekday(selectedDate)
      const selectedLocationData = GetLocationDataBasedOnLocationId(locationData, locationId)

      let standardScheduleForDay = selectedLocationData?.standardSchedule[weekday]

      const startTime = standardScheduleForDay.startTime
      const endTime = standardScheduleForDay.endTime
      let appointmentStart
      let appointmentEnd
      if (startTime) {
        appointmentStart = setTimeFromDateStr(startTime, selectedDate)
        appointmentEnd = setTimeFromDateStr(endTime, selectedDate)
      }

      const startHour = appointmentStart
      const endHour = addHours(startOfDay(selectedDate), 24)
      let hours = []
      let currentHour = startHour

      while (currentHour < endHour) {
        hours.push(currentHour)
        currentHour = addHours(currentHour, 1)
      }

      setHours(hours)

      const regularAppointments = appointments.filter((item, index) => item.startTimeText && item.endTimeText)
      setAppointments(regularAppointments)

      let allDayEvents = {}
      let regularEvents = {}

      for (let a of appointments) {
        if (a?.status !== 'Canceled') {
          let barberName = `${a?.barber?.firstName} ${a?.barber?.lastName}`
          if (a?.barber) {
            if (!allDayEvents[barberName]) {
              allDayEvents[barberName] = []
              regularEvents[barberName] = []
            }
            let dataItem = {
              title: a?.services?.map((service) => service?.name).join(', '),
              _id: a?._id,
              ...a,
            }
            if (a?.barber?._id) {
              if (!a.endTime) {
                dataItem.allDay = true
                dataItem.start = new Date(selectedDate)
                dataItem.end = new Date(selectedDate)
                allDayEvents[barberName].push(dataItem)
              } else {
                dataItem.start = setTimeFromDateStr(a?.startTimeText)
                dataItem.end = setTimeFromDateStr(a?.endTimeText)
                regularEvents[barberName].push(dataItem)
              }
            }
          }
        }
      }

      setAllDayEvents(allDayEvents)
      let filteredWalkins = appointments?.filter((a) => a.type === 'Walkin' && !a?.barber?._id)
      setFilteredWalkins(filteredWalkins)
      setPersonEvents(regularEvents)
    }
  }, [appointments])

  const isStoreClosed = () => {
    const dayOfWeek = selectedDate.getDay()
    const dayOfWeekString = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][dayOfWeek]
    const todaySchedule = locationSchedule?.[dayOfWeekString]

    return !todaySchedule?.enabled
  }

  const eventData = (event, overlappingEventsCount, person) => {
    if (event.isBreak) {
      return <BreakEvent event={event} />
    }
    if (overlappingEventsCount > 1) {
      return <VerticalEvent event={event} />
    }
    if (event.durationMin < 30 || (expandedColumn == person && event.durationMin < 30)) {
      return <HorizontalEvent expandedColumn={expandedColumn} person={person} event={event} overlappingEventsCount={overlappingEventsCount} />
    } else {
      return <VerticalEvent event={event} />
    }
  }
  const handleSelectedEvent = (event) => {
    history.push(`/core/appointments/${event._id}`)
    // setSelectedEvent(event)
    // setShowModal(true)
  }
  const closeModal = () => {
    setShowModal(false)
    refreshAppointments()
  }
  const renderEvent = (event, person, index) => {
    const overlappingEvents = personEvents[person].filter(
      (otherEvent) =>
        otherEvent._id !== event._id && new Date(otherEvent.startTime) < new Date(event.endTime) && new Date(otherEvent.end) > new Date(event.start)
    )

    if (event.isBreak) {
      event.status = 'Break'
    }
    return (
      <div onClick={() => handleSelectedEvent(event)} style={{ color: 'inherit', width: '100%', height: '100%' }}>
        {eventData(event, overlappingEvents.length, person)}
      </div>
    )
  }
  function assignColumns(events) {
    let rows = []

    // Group events into rows based on overlap
    events.forEach((event) => {
      let placed = false

      for (let row of rows) {
        if (row.some((existingEvent) => isOverlapping(existingEvent, event))) {
          row.push(event)
          placed = true
          break
        }
      }
      if (!placed) {
        rows.push([event])
      }
    })

    // Calculate the width and left position for each event
    rows = rows.map((row) => {
      const totalEvents = row.length
      const widthPercentage = 100 / totalEvents

      return row.map((event, index) => ({
        ...event,
        width: widthPercentage,
        left: widthPercentage * index,
      }))
    })

    return rows
  }

  const isOverlapping = (event1, event2) => {
    return event1.startTime < event2.endTime && event1.endTime > event2.startTime
  }

  function calculateFreeTime(day, employeeSchedule) {
    let freeTimes = []
    if (!locationSchedule?.[day]?.enabled || !employeeSchedule?.[day]?.enabled) {
      return null // If either schedule is not enabled, no free time calculation is needed
    }

    const locStart = locationSchedule[day].startTime
    const locEnd = locationSchedule[day].endTime

    const empStart = employeeSchedule[day].startTime
    const empEnd = employeeSchedule[day].endTime

    const freeTimeBefore = {
      start: locStart,
      end: empStart,
    }
    const freeTimeAfter = {
      start: empEnd,
      end: '11:59 PM',
    }
    freeTimes.push(freeTimeBefore)
    freeTimes.push(freeTimeAfter)

    return freeTimes
  }

  const handleSaveBreak = async (data) => {
    console.log('innnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnn')
    const businessId = businessData?._id
    const breakInput = {
      ...{ locationId, businessId },
      ...data,
      repeat: data.repeat,
    }

    try {
      await axios.post(`/employee/break`, breakInput)
      setToast({ isOpen: true, message: 'Break saved successfully!', color: 'success' })
      getBreaks()
      closeModal()
      closeBreakModal()
      // Show success toast message
    } catch (error) {
      // Show error toast message
      setToast({ isOpen: true, message: 'Sorry, there was an issue saving. Try again later.', color: 'danger' })
    }
  }

  const handleMonthDateChange = (date) => {
    setSelectedDate(new Date(date))
    setDate(date)
    setStartDate(moment(date))
    setView('week')
  }

  const openEventsModal = () => {
    setIsModalOpen(true)
  }
  const closeEventsModal = () => {
    setIsWalkin(false)
    setIsModalOpen(false)
  }

  const openBreakModal = () => {
    setIsBreakModalOpen(true)
  }

  const closeBreakModal = () => {
    setIsWalkin(false)

    setIsBreakModalOpen(false)
  }

  useEffect(() => {
    setStartDate(moment(selectedDate))
  }, [selectedDate])

  const selectedPeople = Object.keys(personEvents).sort((a, b) => a.localeCompare(b))
  const people = selectedStylists?.length > 0 ? selectedStylists : selectedPeople
  const appointmentsBySelectedTypes =
    selectedTypes?.length > 0 ? regularAppointments.filter((item, index) => selectedTypes.includes(item.status)) : regularAppointments

  const organisedEventsByHour = organizeAppointmentsByHour(appointmentsBySelectedTypes)
  const organisedEventsByName = organizeEventsByName(appointmentsBySelectedTypes)
  const renderAppointmentsTable = () => {
    return (
      <>
        {locationSchedule && isStoreClosed() ? (
          <StoreClosedCard />
        ) : (
          <>
            <div className='mainContainer'>
              {walkinsList.length > 0 && <WalkinAppointments walkinsList={walkinsList} />}{' '}
              {appointments?.length > 0 ? (
                <table style={styles.tableStyle}>
                  <thead>
                    <tr>
                      <td
                        style={{
                          borderRight: '1px solid #c8c8c8',
                          background: 'white',
                          position: 'sticky',
                          top: 0,
                          left: 0,
                          zIndex: 999,
                          width: '3.5rem',
                          maxWidth: '3.5rem',
                        }}
                      ></td>
                      {people.map((person, columnIndex) => {
                        return (
                          <th
                            key={columnIndex}
                            style={{
                              ...styles.thStyle,
                              top: walkinsList.length > 0 ? '2.5rem' : 0,
                              minWidth: expandedColumn === person ? (isDesktop ? '30rem' : '22rem') : '18rem',
                            }}
                            scope='col'
                          >
                            <div style={{ borderBottom: '1px solid #c8c8c8', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                              <IonLabel
                                style={{
                                  padding: '10px 8px ',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  whiteSpace: 'nowrap',
                                  textWrap: 'nowrap',
                                  width: '80%',
                                }}
                              >
                                {person}
                              </IonLabel>
                              <IonLabel style={{ width: '20%', display: 'flex', justifyContent: 'end' }}>
                                {expandedColumn === person ? (
                                  <IonIcon
                                    icon={callOutline}
                                    onClick={() => handleExpandClick(person)}
                                    style={{ cursor: 'pointer', borderRadius: '50%', background: '#d8d8d8', padding: '4px' }}
                                  />
                                ) : (
                                  <IonIcon
                                    icon={resizeOutline}
                                    style={{ cursor: 'pointer', borderRadius: '50%', background: '#d8d8d8', padding: '4px' }}
                                    onClick={() => handleExpandClick(person)}
                                  />
                                )}
                              </IonLabel>
                            </div>
                          </th>
                        )
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th style={styles.hourStyle} scope='row'>
                        <IonLabel
                          style={{
                            verticalAlign: 'baseline',
                            width: '3.5rem',
                            display: 'block',
                            fontSize: 12,
                            fontWeight: 'normal',
                            marginTop: -38,
                            zIndex: 9999,
                            color: colors.black60,
                            marginLeft: -14,
                            marginRight: 4,
                            textAlign: 'right',
                          }}
                        >
                          All Day{' '}
                        </IonLabel>
                      </th>{' '}
                      {people.map((person, index) => {
                        return (
                          <td style={{ ...styles.appointmentItemStyle }}>
                            {allDayEvents[person].map((event, index) => {
                              const style = calculateNewEventStyles(event, index, allDayEvents[person])
                              return (
                                <div
                                  key={event._id}
                                  className='calendar-event'
                                  style={{ ...style, width: `${event.width}%`, left: `${event.left}%`, cursor: 'pointer' }}
                                >
                                  {renderEvent(event, person, index)}
                                </div>
                              )
                            })}
                          </td>
                        )
                      })}
                    </tr>
                    {hours.map((hour, index) => {
                      return (
                        <>
                          <tr style={{ position: 'relative' }} key={index}>
                            <th style={styles.hourStyle} scope='row'>
                              <IonLabel
                                style={{
                                  verticalAlign: 'baseline',
                                  width: '3.5rem',
                                  display: 'block',
                                  fontSize: 12,
                                  fontWeight: 'normal',
                                  marginTop: -38,
                                  zIndex: 9999,
                                  color: colors.black60,
                                  marginLeft: -14,
                                  marginRight: 4,
                                  textAlign: 'right',
                                }}
                              >
                                {format(hour, 'ha').replace('AM', 'am').replace('PM', 'pm') === '12 pm'
                                  ? 'Noon'
                                  : format(hour, 'ha').replace('AM', 'am').replace('PM', 'pm')}
                              </IonLabel>
                            </th>

                            {people.map((person, index) => {
                              const selectedHour = new Date(hour).getHours()
                              const events = organisedEventsByHour?.[selectedHour - 1]?.filter((item, index) => item.person == person)
                              const selectedDay = getWeekday(new Date(selectedDate))
                              const employeeData = employees.find((item) => `${item?.firstName} ${item?.lastName}` == person)

                              const freeTime = calculateFreeTime(selectedDay, employeeData?.standardSchedule)

                              const sortedAppointments = [...events].sort((a, b) => a.startTime - b.startTime)
                              const columns = assignColumns(sortedAppointments)
                              return (
                                <td style={styles.appointmentItemStyle}>
                                  <EmployeeFreeTime hour={hour} selectedDate={selectedDate} freeTime={freeTime} locationStartTime={locationStartTime} />
                                  <Breaks
                                    breaks={breaks}
                                    selectedDate={selectedDate}
                                    hour={hour}
                                    person={person}
                                    index={index}
                                    organisedEventsByName={organisedEventsByName}
                                    locationStartTime={locationStartTime}
                                  />
                                  {columns.flat().map((event, index) => {
                                    const style = calculateNewEventStyles(event, index, columns)
                                    return (
                                      <div
                                        key={event._id}
                                        className='calendar-event'
                                        style={{ ...style, width: `${event.width}%`, left: `${event.left}%`, cursor: 'pointer' }}
                                      >
                                        {renderEvent(event, person, index)}
                                      </div>
                                    )
                                  })}
                                  {/* {events.map((item, index) => {
                                  return (
                                    <div
                                      style={{
                                        ...calculateEventStyles(item, person, index, organisedEventsByName, locationStartTime),
                                        ...eventStyle(item?.status),
                                      }}
                                    >
                                      {renderEvent(item, person, index)}
                                    </div>
                                  )
                                })} */}
                                </td>
                              )
                            })}
                          </tr>
                        </>
                      )
                    })}
                  </tbody>
                </table>
              ) : (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '4rem' }}>
                  <IonLabel>No Appointments</IonLabel>
                </div>
              )}
            </div>
          </>
        )}
      </>
    )
  }
  return (
    <>
      <IonContent className='desktop-view regularBackground'>
        <IonToast
          isOpen={toast.isOpen}
          message={toast.message}
          color={toast.color}
          onDidDismiss={() => setToast({ ...toast, isOpen: false })}
          duration={2000}
          position='top'
        />
        <CommonHeader
          removeCalendarControls={false}
          defaultView={defaultView}
          setDefaultView={setDefaultView}
          progressBar={loading}
          menuIcon={true}
          title={'Appointments'}
        />
        <IonGrid style={{ width: '100%' }} className='grid-container'>
          <IonRow style={{ width: '100%' }}>
            <IonCol style={{ padding: 0, maxWidth: '16rem' }} className='column'>
              <div className='scrollable-content'>
                <div style={{ display: 'flex', padding: 10, justifyContent: 'center', alignItems: 'center' }} onClick={openEventsModal}>
                  <IonButton fill='outline'>
                    <IonLabel>Add Event</IonLabel>
                  </IonButton>
                </div>
                <div className='month-dates-container' style={{ display: 'flex', justifyContent: 'center' }}>
                  <IonDatetime
                    mode='ios'
                    presentation='day'
                    value={date}
                    onIonChange={(e) => {
                      handleMonthDateChange(e.detail.value)
                    }}
                    style={{
                      '--ion-font-size': '10px',

                      color: 'black',
                      background: 'white',
                      minHeight: '20rem',
                    }}
                  />{' '}
                </div>
                <div>
                  <h6
                    style={{
                      paddingLeft: 20,
                      paddingTop: 20,
                      borderTop: `1px solid ${colors.black10}`,
                      color: colors.black50,
                      fontWeight: fontWeights.weightBold,
                    }}
                  >
                    STYLISTS
                  </h6>
                  <IonList mode='ios' inset={true} lines='none'>
                    {filteredBarbers?.map((item, index) => (
                      <IonItem key={index} style={{ padding: '2px 0' }} onClick={() => handleStylistChange(item.title)}>
                        <IonCheckbox slot='end' checked={selectedStylists.includes(item.title)} onIonChange={(e) => handleStylistChange(item.title)} />
                        <Avatar employee={item} businessId={businessData?._id} small />
                        <IonLabel style={{ marginLeft: 10 }}>{item.title}</IonLabel>
                      </IonItem>
                    ))}
                  </IonList>
                  <h6 style={{ paddingLeft: 20, color: colors.black50, fontWeight: fontWeights.weightBold }}>TYPE</h6>
                  <IonList mode='ios' inset={true} lines='none'>
                    {['Scheduled', 'In Progress', 'Complete', 'Canceled', 'No Show', 'Arrived'].map((item, index) => (
                      <IonItem key={index} style={{ padding: '2px 0' }} onClick={() => handleTypeChange(item)}>
                        <IonCheckbox slot='end' checked={selectedTypes.includes(item)} onIonChange={(e) => handleTypeChange(item)} />
                        <IonLabel>{item}</IonLabel>
                      </IonItem>
                    ))}
                  </IonList>
                </div>
              </div>
            </IonCol>
            <IonCol style={{ padding: 0 }} className='column'>
              <div className='scrollable-content'>{renderAppointmentsTable()}</div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
      <IonContent className='mobile-view regularBackground'>
        <CalendarView5Header
          setDefaultView={setDefaultView}
          defaultView={defaultView}
          displayWeeks={true}
          displayCalendar={true}
          getBreaks={getBreaks}
          employees={employees}
          selectedDate={selectedDate}
          handlePrevWeek={handlePrevWeek}
          loading={loading}
          handleNextWeek={handleNextWeek}
          setSelectedDate={setSelectedDate}
          refreshAppointments={refreshAppointments}
        />

        {/* Render store closed message if the store is closed on the selected day */}
        {renderAppointmentsTable()}
        <ResizableModal closeModal={closeModal} selectedEvent={selectedEvent} showModal={showModal} setShowModal={setShowModal} />
      </IonContent>
      <IonModal isOpen={isModalOpen} onDidDismiss={closeEventsModal}>
        <CommonHeader title={'Select Event'} closeButton={true} closeModal={closeEventsModal} />
        <IonContent>
          <IonList>
            <IonItem
              detail={true}
              button
              onClick={() => {
                setApptModalOpen(true)
              }}
            >
              <IonIcon slot='start' icon={calendarOutline} />
              <IonLabel>Appointment</IonLabel>
            </IonItem>
            <IonItem
              detail={true}
              button
              onClick={() => {
                setApptModalOpen(true)
                setIsWalkin(true)
              }}
            >
              <IonIcon slot='start' icon={footstepsOutline} />
              <IonLabel>Walk-in</IonLabel>
            </IonItem>
            <IonItem detail={true} button onClick={openBreakModal}>
              <IonIcon slot='start' icon={cafeOutline} />
              <IonLabel>Break</IonLabel>
            </IonItem>
          </IonList>
        </IonContent>
      </IonModal>
      <IonModal isOpen={apptModalOpen}>
        <IonContent className='regularBackground'>
          <StandardContainer>
            <StepController
              isWalkin={isWalkin}
              getAppointments={() => {
                refreshAppointments()
                closeModal()
              }}
              handlePrevWeek={handlePrevWeek}
              handleNextWeek={handleNextWeek}
              choosecustomer={true}
              isOpen={apptModalOpen}
              closeModal={() => {
                setApptModalOpen(false)
              }}
              setSubscriptionReferrer={() => {}}
            />
          </StandardContainer>
        </IonContent>
      </IonModal>
      <AddBreak selectedDate={selectedDate} handleSave={handleSaveBreak} employees={employees} open={isBreakModalOpen} onClose={closeBreakModal} />
    </>
  )
}

const BreakEvent = ({ event }) => {
  const timeDifference = event.end.getTime() - event.start.getTime()
  const minutesDifference = Math.floor(timeDifference / (1000 * 60))

  return (
    <IonRow className='break-event-row'>
      <div className='label-container'>
        <IonLabel className='break-event-label'>Break</IonLabel>
        <IonLabel className='break-event-info'>{`(${event.startTime}-${event.endTime}) (${minutesDifference} min)`}</IonLabel>
      </div>
    </IonRow>
  )
}
export default CalendarDayView
