import axios from 'axios'

const CheckEmailRemarketingRuleExists = async name => {
  try {
    let response = await axios.get(`/emailRemarketing/rule/check?name=${name}`)
    return response.data
  } catch (error) {
    return { ruleExists: false }
  }
}
export default CheckEmailRemarketingRuleExists
