// CreditCard.js

import styles from './CardAndHistory.module.css' // Import your CSS module
import { IonLabel, IonRow, IonSpinner } from '@ionic/react'

const CardBalance = ({ loadingData, balance }) => {
  return (
    <>
      <IonRow className={styles['credit-card']}>
        <IonRow className={styles['card-header']}>
          <IonLabel className={styles.headingLabel}>Credit Balance</IonLabel>
        </IonRow>
        <IonRow className={styles['card-body']}>
          <IonRow className={styles['credit-balance']}>
            {loadingData && <IonSpinner name='lines' />}
            {!loadingData && (
              <IonLabel className={styles.creditBalanceLabel}>
                {balance ? formatDollarValue(balance/100) : "$0.00"}
              </IonLabel>
            )}
          </IonRow>
        </IonRow>
      </IonRow>
    </>
  )
}

function formatDollarValue(value) {
  if (value < 0) {
    const formattedValue = Math.abs(value).toFixed(2);
    return `($${formattedValue})`;
  } else {
    return `$${value.toFixed(2)}`;
  }
}

export default CardBalance
