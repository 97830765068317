import { useState, useEffect, useContext } from 'react'
import GeneralDetails from './details_general'
import axios from 'axios'
import { AuthContext } from '../auth'
import { useHistory } from 'react-router-dom'
import { IonContent, IonPage, IonSpinner, IonToast, IonRow, IonIcon, IonGrid, IonCol } from '@ionic/react'
import StandardContainer from '../commonComponents/StandardContainer'
import { chevronBack } from 'ionicons/icons'
import { colors } from '../theme/colors'
import MembershipDetails from './membershipDetails'
import Professionals from './professionals'
import AdvancedDetails from './advancedDetails'

export default function Details({ match }) {
  const service_id = match?.params?.id
  const [service, setService] = useState({})
  const [serviceTemp, setServiceTemp] = useState({})
  const [locations, setLocations] = useState([])
  const [subscriptionPackages, setSubscriptionPackages] = useState([])
  const [loading, setLoading] = useState(false)
  const { businessData, locationId } = useContext(AuthContext)
  const history = useHistory()
  const [isReloading, setIsReloading] = useState(false) // To track if the reload is from useEffect

  const [toast, setToast] = useState({ isOpen: false, message: '', color: '' })

  const getService = async (reload) => {
    if (reload) {
      setIsReloading(true)
    } else {
      setLoading(true)
    }
    try {
      // Fetch the service details using the service ID
      let response = await axios.get(`/service?id=${service_id}`)

      // Update state with the fetched service data
      setService(response.data)
      setServiceTemp(response.data)
      setIsReloading(false)
      setLoading(false)
    } catch (error) {
      console.error('Error fetching service details:', error) // Log the error for debugging
      // Optionally, handle the error (e.g., set an error state or display an error message in the UI)
    } finally {
    }
  }

  useEffect(() => {
    getService(true)
  }, [service_id])

  const getLocationsList = async () => {
    if (!businessData?._id) return
    try {
      let response = await axios.get(`/location_v2/getList?businessId=${businessData._id}`)
      setLocations(response.data)
    } catch (error) {
      console.error('Failed to load locations.', error) // Log the error for debugging
    }
  }

  useEffect(() => {
    getLocationsList()
  }, [businessData])

  const getSubscriptionPackagesList = async () => {
    if (!businessData?._id) return
    try {
      let response = await axios.get(`/subscriptionPackage/getList?businessId=${businessData._id}`)
      setSubscriptionPackages(response.data)
    } catch (error) {
      console.error('Failed to load subscription packages.', error) // Log the error for debugging
    }
  }

  useEffect(() => {
    getSubscriptionPackagesList()
  }, [businessData])

  const updateService = (value) => {
    setServiceTemp(value)(true)
  }

  const deleteService = async () => {
    try {
      await axios.delete(`/service?id=${service_id}`)
      showToastMessage('Service deleted successfully!', 'success')
      history.goBack()
    } catch (error) {
      showToastMessage('Failed to delete service.', 'danger')
    }
  }

  const showToastMessage = (message, color = 'success') => {
    setToast({ isOpen: true, message, color })
  }

  return (
    <IonPage>
      <IonContent class='regularBackground'>
        <StandardContainer>
          <IonGrid>
            <IonGrid style={{ padding: '20px 0' }}>
              <IonRow style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <IonCol>
                  <IonIcon onClick={() => history.goBack()} icon={chevronBack} style={{ height: 30, width: 30 }} />
                </IonCol>
              </IonRow>
            </IonGrid>

            {}
            {isReloading && (
              <IonGrid style={{ padding: '0 20px' }}>
                <IonSpinner name='dots' />
              </IonGrid>
            )}
            {!isReloading && (
              <>
                {loading && (
                  <IonGrid style={{ padding: '0 20px' }}>
                    <IonSpinner name='dots' />
                  </IonGrid>
                )}
                <GeneralDetails
                  loading={loading}
                  getService={() => getService(false)}
                  showToastMessage={showToastMessage}
                  service={service}
                  serviceTemp={serviceTemp}
                  updateService={updateService}
                  locations={locations}
                  subscriptionPackages={subscriptionPackages}
                  locationId={locationId}
                />
                <IonRow style={{ borderTop: `3px solid ${colors.black10} `, marginTop: 10 }}></IonRow>

                <MembershipDetails
                  loading={loading}
                  getService={() => getService(false)}
                  showToastMessage={showToastMessage}
                  service={service}
                  serviceTemp={serviceTemp}
                  updateService={updateService}
                  locations={locations}
                  subscriptionPackages={subscriptionPackages}
                  locationId={locationId}
                />
                <IonRow style={{ borderTop: `3px solid ${colors.black10} `, marginTop: 10 }}></IonRow>

                <Professionals
                  loading={loading}
                  getService={() => getService(false)}
                  showToastMessage={showToastMessage}
                  service={service}
                  serviceTemp={serviceTemp}
                  updateService={updateService}
                  locations={locations}
                  subscriptionPackages={subscriptionPackages}
                  locationId={locationId}
                />
                <IonRow style={{ borderTop: `3px solid ${colors.black10} `, marginTop: 10 }}></IonRow>

                <AdvancedDetails
                  loading={loading}
                  getService={() => getService(false)}
                  showToastMessage={showToastMessage}
                  service={service}
                  serviceTemp={serviceTemp}
                  updateService={updateService}
                  locations={locations}
                  subscriptionPackages={subscriptionPackages}
                  locationId={locationId}
                  deleteService={deleteService}
                />
              </>
            )}
          </IonGrid>
        </StandardContainer>
      </IonContent>

      <IonToast
        position='top'
        isOpen={toast.isOpen}
        onDidDismiss={() => setToast({ ...toast, isOpen: false })}
        message={toast.message}
        color={toast.color}
        duration={2000}
      />
    </IonPage>
  )
}
