import { IonLabel, IonGrid, IonRow, IonCol, IonIcon, IonModal, IonContent, IonItem, IonCheckbox, IonAvatar, IonList, IonSpinner } from '@ionic/react'
import { colors } from '../theme/colors'
import { useEffect, useState } from 'react'
import axios from 'axios'
import CommonHeader from '../commonComponents/CommonHeader'
import { addOutline } from 'ionicons/icons'
import Avatar from '../commonComponents/avatar'

export default function Professionals({ service, showToastMessage }) {
  const [editSection, setEditSection] = useState(null) // Tracks which modal to show
  const [employeesChanged, setEmployeesChanged] = useState(false)
  const [selectedEmployees, setSelectedEmployees] = useState([]) // Selected employees state
  const [loading, setLoading] = useState(false)
  const [employees, setEmployees] = useState([]) // Employee details
  const getEmployeeDetails = async (employeeList) => {
    const detailedEmployees = await Promise.all(
      employeeList.map(async (employee) => {
        try {
          const res = await axios.get(`/employee?id=${employee?._id}`)
          return res.data
        } catch (err) {
          console.error(`Error fetching details for employee ${employee?._id}:`, err)
          return null
        }
      })
    )
    const employeesWithThisService = detailedEmployees?.filter((item, index) => item?.services?.includes(service?._id))
    setSelectedEmployees(employeesWithThisService)
    setEmployees(detailedEmployees.filter((employee) => employee !== null))
  }

  const getEmployeesList = async () => {
    if (!service?.businessId) return
    setLoading(true)
    try {
      const response = await axios.get(`/employee/getList?businessId=${service?.businessId}`)
      const employeeList = response.data
      await getEmployeeDetails(employeeList) // Fetch details for all employees
    } catch (error) {}
    setLoading(false)
  }

  const handleSave = async () => {
    const serviceId = service?._id // The ID of the current service

    // Employees from whom the service needs to be removed
    const employeesToRemoveService = employees.filter(
      (employee) =>
        employee?.services?.includes(serviceId) && // Employee has the service
        !selectedEmployees.some((selected) => selected._id === employee._id) // Employee is not in the selected list
    )

    // Employees to whom the service needs to be added
    const employeesToAddService = selectedEmployees.filter(
      (employee) => !employee?.services?.includes(serviceId) // Employee does not already have the service
    )

    // Prepare updated employee objects
    const updatedEmployeesToRemoveService = employeesToRemoveService.map((employee) => ({
      ...employee,
      services: employee?.services.filter((id) => id !== serviceId), // Remove the service ID
    }))

    const updatedEmployeesToAddService = employeesToAddService.map((employee) => ({
      ...employee,
      services: employee?.services ? [...employee.services, serviceId] : [serviceId],
    }))

    try {
      // Only execute the patch request if there are employees to update
      const updateRequests = []

      if (updatedEmployeesToRemoveService.length > 0) {
        updateRequests.push(
          ...updatedEmployeesToRemoveService.map((employee) =>
            axios.patch(`/employee?id=${employee?._id}`, {
              services: employee?.services,
            })
          )
        )
      }

      if (updatedEmployeesToAddService.length > 0) {
        updateRequests.push(
          ...updatedEmployeesToAddService.map((employee) =>
            axios.patch(`/employee?id=${employee?._id}`, {
              services: employee?.services,
            })
          )
        )
      }

      if (updateRequests.length > 0) {
        // Send the API requests if there are any to send
        await Promise.all(updateRequests)

        showToastMessage('Service updated successfully!', 'success')
        setEditSection(null) // Close the modal
        setEmployeesChanged(false)
        getEmployeesList() // Refresh the employees list
      } else {
        showToastMessage('No changes made to services.', 'info')
      }
    } catch (error) {
      console.error('Error updating employees:', error)
      showToastMessage('Failed to update service.', 'danger')
    }
  }

  const handleEmployeeSelect = (employee) => {
    setEmployeesChanged(true)
    setSelectedEmployees((prevSelected) => {
      // Check if the employee is already selected
      const isAlreadySelected = prevSelected.some((emp) => emp?._id === employee?._id)

      // If already selected, remove them; otherwise, add them
      if (isAlreadySelected) {
        return prevSelected.filter((emp) => emp?._id !== employee?._id)
      } else {
        return [...prevSelected, employee]
      }
    })
  }

  useEffect(() => {
    getEmployeesList()
  }, [service._id])

  return (
    <>
      <IonGrid style={{ padding: 20, paddingBottom: 0 }}>
        <IonRow style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <IonCol>
            <IonLabel style={{ color: colors.black50, fontWeight: 'bold' }}>Professionals who can perform this service</IonLabel>
          </IonCol>
          {selectedEmployees?.length > 0 && (
            <IonCol size='auto'>
              <IonLabel style={{ cursor: 'pointer', fontSize: '18px', color: colors.primaryBlue }} onClick={() => setEditSection('professional')}>
                Edit
              </IonLabel>
            </IonCol>
          )}
        </IonRow>
        <IonRow>{loading && <IonSpinner name='dots' />}</IonRow>
        {!loading && (
          <>
            {selectedEmployees?.length === 0 ? (
              <IonRow style={{ margin: '20px  0' }}>
                <IonRow
                  style={{
                    padding: '20px 10px',
                    border: `1px solid ${colors.black30}`,
                    width: '100%',
                    borderRadius: 10,
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}
                  onClick={() => setEditSection('professional')}
                >
                  <IonIcon style={{ height: 25, width: 25, color: colors.black30, marginRight: 10 }} icon={addOutline} />
                  <IonLabel> Add a professional</IonLabel>
                </IonRow>
              </IonRow>
            ) : (
              <IonList mode='ios' style={{ marginTop: 10, marginBottom: 20 }}>
                {selectedEmployees?.map((employee, index) => {
                  return (
                    <IonItem key={employee._id}>
                      <IonAvatar slot='start' style={{ marginTop: 10, marginBottom: 10 }}>
                        <Avatar employee={employee} businessId={service?.businessId} small />
                      </IonAvatar>
                      <IonLabel>
                        {/* {`${employee.firstName} ${employee.lastName}`} */}
                        <h4 style={{ fontWeight: '600', color: 'black', fontSize: 18 }}>{`${employee.firstName} ${employee.lastName}`}</h4>
                      </IonLabel>
                    </IonItem>
                  )
                })}
              </IonList>
            )}
          </>
        )}
      </IonGrid>

      {/* Edit Modal */}
      <IonModal isOpen={editSection === 'professional'} onDidDismiss={() => setEditSection(null)}>
        <IonContent>
          <CommonHeader
            saveDisabled={!employeesChanged}
            saveButton={true}
            closeButton={true}
            closeModal={() => setEditSection(null)}
            handleSaveButton={handleSave}
          />
          <IonGrid style={{ padding: 20 }}>
            <IonRow>
              <IonCol>
                {employees.map((employee) => (
                  <IonItem onClick={() => handleEmployeeSelect(employee)} key={employee?._id}>
                    <IonCheckbox
                      mode='ios'
                      slot='start'
                      checked={selectedEmployees.some((emp) => emp?._id === employee?._id)} // Reflects the correct state
                    />
                    <IonLabel>
                      {employee?.firstName} {employee?.lastName}
                    </IonLabel>
                  </IonItem>
                ))}
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>
      </IonModal>
    </>
  )
}
