const { statusColors } = require('../../StaticData/StaticData')

exports.parseTime = (date, timeString) => {
  const [time, period] = timeString.split(' ')
  let [hour, minute] = time.split(':').map((x) => parseInt(x, 10))

  // Correctly handle 12 AM as 0 hours and PM times except 12 PM
  if (period === 'PM' && hour !== 12) {
    hour += 12
  } else if (period === 'AM' && hour === 12) {
    hour = 0
  }

  const dateText = new Date(date)
  return new Date(dateText.getFullYear(), dateText.getMonth(), dateText.getDate(), hour, minute)
}
exports.setTimeFromDateStr = (timeStr, date = new Date()) => {
  const dateWithNoTime = new Date(date.setHours(0, 0, 0, 0)); // Reset hours, minutes, seconds, and milliseconds

  if (!timeStr) {
    return dateWithNoTime;
  }

  const [time, modifier] = timeStr.split(' ');
  let [hours, minutes] = time.split(':');

  hours = parseInt(hours, 10);
  minutes = parseInt(minutes, 10);

  // Convert hours to 24-hour format based on AM/PM
  if (modifier === 'PM' && hours !== 12) {
    hours += 12;
  } else if (modifier === 'AM' && hours === 12) {
    hours = 0;
  }

  dateWithNoTime.setHours(hours);
  dateWithNoTime.setMinutes(minutes);

  return dateWithNoTime;
};

exports.calculateEventStyles = (event, person, index, personEvents, locationStartTime) => {
  const start = new Date(event.start)
  const end = new Date(event.end)

  // Calculate the difference in minutes between start and end
  const durationInMinutes = (end.getTime() - start.getTime()) / (1000 * 60)

  // Calculate the difference in hours between event start time and location start time
  const startHourDifference = (start.getHours() - locationStartTime.getHours() + 1) * 60

  // Convert the start time to minutes
  const startHour = start.getHours()
  const startMinute = start.getMinutes()

  // Calculate the top position in pixels considering the location start time
  let top = startHourDifference + startMinute + 'px'
  if (event.allDay && index > 0) {
    top = '20px'
  } else if (event.allDay && index === 0) {
    top = 0 + 'px'
  }

  // Calculate the height based on the duration in minutes
  const height = durationInMinutes + 'px'

  // Calculate the width of the event
  let width = '96%'

  // Calculate z-index based on start time (higher z-index for later start times)
  const zIndex = 1000 + startHour * 60 + startMinute

  // Filter out events for the person that have already been rendered
  const renderedEvents = personEvents[person].slice(0, index)

  // Check for overlapping events among rendered events
  const overlappingEvents = renderedEvents.filter(
    (otherEvent) => otherEvent._id !== event._id && new Date(otherEvent.start) < new Date(event.end) && new Date(otherEvent.end) > new Date(event.start)
  )

  // If there are overlapping events, adjust the position and width
  if (!event.isBreak && !event.allDay && overlappingEvents.length > 0) {
    const left = (100 / (overlappingEvents.length + 1)) * overlappingEvents.length + '%'
    width = 96 / (overlappingEvents.length + 1) + '%'
    return {
      top,
      left,
      width,
      height,
      minHeight: 15,
      backgroundColor: statusColors[event.status]?.bgColor || '#ccc',
      position: 'absolute',
      boxSizing: 'border-box',
      borderRadius: 6,
      color: statusColors[event.status]?.textColor || '#000',
      fontSize: 12,
      display: 'flex',
      alignItems: 'start',
      // paddingTop: '2px',
      cursor: 'pointer',
      zIndex,
    }
  }

  // If there are no overlapping events, use regular styling
  return {
    // margin: '1px 0',
    overflow: 'hidden',
    top,
    height,
    minHeight: 20,
    backgroundColor: statusColors[event.status]?.bgColor || '#ccc',
    position: 'absolute',
    width,
    boxSizing: 'border-box',
    borderRadius: 6,
    color: statusColors[event.status]?.textColor || '#000',
    fontSize: 12,
    display: 'flex',
    alignItems: 'start',
    // paddingTop: '2px',
    cursor: 'pointer',
    zIndex,
  }
}
