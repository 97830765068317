import { useState } from 'react'
import CustomInput from '../../../SetupBusiness/CustomInput'
import ButtonPanel from '../buttonPanel'

const SiteForm = ({ data, flowIndex, onBack, onNext, onCancel, history }) => {
  const [logo, setLogo] = useState('')
  const [primary, setPrimary] = useState('')

  const handleLogo = (index, value) => setLogo(() => {})
  const handlePrimary = (index, value) => setPrimary(() => {})
  const handleNext = () => onNext()

  return (
    <>
      <p>We want to get your site custom to your personality right away. You will be able to customize your site alot more after set up. </p>
      <CustomInput label={'Have a logo to add? (optional)'} value={logo} placeholder='' handleInput={handleLogo} />
      <p>If you dont have one right away we can just use the default logo for now.</p>
      <img alt='default logo' style={{ width: 150, display: 'block', margin: '0 auto' }} src='assets/defaultLogo.png' />
      <CustomInput label={'What Primary color do you prefer? (optional)'} value={primary} placeholder='#0000' handleInput={handlePrimary} />
      <p>Your primary color will be used for headers and buttons</p>
      <CustomInput label={'What Secondary color do you prefer? (optional)'} value={primary} placeholder='#0000' handleInput={handlePrimary} />
      <p>Your secondary color will be used as an alternate color for items on your site</p>
      <ButtonPanel data={data} flowIndex={flowIndex} onBack={onBack} onNext={handleNext} onCancel={onCancel} history={history} />
    </>
  )
}

export default SiteForm
