import { IonItem, IonLabel, IonList } from '@ionic/react'
import { colors } from '../theme/colors'

export default function ResultsList({ users = [], title }) {
  return (
    <>
      <h6 style={{ color: 'grey', marginLeft: 20 }}>{title}</h6>
      <IonList inset={true} mode='ios'>
        {users?.map(row => (
          <IonItem
            button
            detail={true}
            routerLink={`/core/users/user/${row._id}`}
            routerDirection='forward'
            key={row._id}
          >
            <IonLabel>
              <h4 style={{ fontWeight: '600', color: 'black', fontSize: 18 }}>{`${row.email}`}</h4>
              {row.status === 'Disabled' && (
                <p
                  style={{
                    fontSize: '10px',
                    backgroundColor: 'rgb(214 214 214 / 70%)',
                    padding: '5px 1rem',
                    color: '#555555',
                    display: 'inline-flex',
                    borderRadius: '10px',
                  }}
                >
                  Disabled
                </p>
              )}
              {row.entitlements?.admin ? <p>Admin</p> : null}
            </IonLabel>
          </IonItem>
        ))}
      </IonList>
    </>
  )
}
