import React, { useState, useEffect, useContext } from 'react'
import SubscriberInfo from '../commonComponents/SubscriberInfo'
import { IonCard, IonIcon, IonItem, IonLabel, IonList } from '@ionic/react'
import { arrowUpCircleOutline, trashBinOutline, trashOutline } from 'ionicons/icons'

export default function CustomerSubscription({
  customer,
  refreshData,
  setIsSubscribed,
  reloadTrigger,
}) {
  return (
    <IonList inset={true} mode='ios'>
      <div style={{ paddingLeft: 18, paddingTop: 6, paddingBottom: 6 }}>
        <SubscriberInfo
          fromCustomer={true}
          customer={customer}
          refreshData={refreshData}
          setIsSubscribed={setIsSubscribed}
          reloadTrigger={reloadTrigger}
        />
      </div>
    </IonList>
    // <IonCard>
    //     <div style={{paddingLeft:20, paddingTop:8, paddingBottom:8}}>

    //     </div>
    // </IonCard>
  )
}
