import React, { useState, useEffect } from 'react';
import { IonCol, IonGrid, IonRow, IonSpinner } from '@ionic/react';
import CategoryToggelControls from './progressToggleControl';
import AppointmentsChart from './appointments';
import RevenueChart from './revenue';

const ProgressChart = ({ refreshValue, businessData, locationId, apptSummary, appointments }) => {
    const [selectedCategory, setSelectedCategory] = useState(() => {
        // Get stored value from localStorage or default to 'Appointments'
        return localStorage.getItem('dashboardCategory') || 'Appointments'
    });
    
    // Update localStorage when category changes
    useEffect(() => {
        localStorage.setItem('dashboardCategory', selectedCategory);
    }, [selectedCategory]);

    let body;
    if(selectedCategory === "Appointments"){
        body = <AppointmentsChart refreshValue={refreshValue} businessData={businessData} locationId={locationId} apptSummary={apptSummary} appointments={appointments} selectedCategory={selectedCategory}/>
    } else if (selectedCategory === "Revenue"){
        body = <RevenueChart refreshValue={refreshValue} businessData={businessData} locationId={locationId} selectedCategory={selectedCategory}/>
    }
   
    return (
        <IonRow>
            <IonCol>
                <CategoryToggelControls selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory} />
            </IonCol>
            {body}
        </IonRow>
    );
};

export default ProgressChart;
