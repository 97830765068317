import { IonIcon } from '@ionic/react'
import { star, starHalf } from 'ionicons/icons'
import { colors } from '../theme/colors'

export default function StarRating({ rating }) {
  const fullStars = Math.floor(rating) // Number of full stars
  const partialStarPercentage = (rating - fullStars) * 100 // Percentage of partial star

  return (
    <div
      style={{
        marginBottom: '1rem',
        width: '70%',
        display: 'flex',
        justifyContent: 'space-evenly',
      }}
    >
      {[...Array(fullStars)].map((_, index) => (
        <IonIcon
          key={index}
          icon={star}
          style={{
            fontSize: '2.5rem',
            color: '#3780ff',
          }}
        />
      ))}
      {partialStarPercentage > 0 && (
        <IonIcon
          icon={starHalf}
          style={{
            fontSize: '2.5rem',
            color: '#3780ff',
          }}
        />
      )}
      {[...Array(5 - fullStars - (partialStarPercentage > 0 ? 1 : 0))].map((_, index) => (
        <IonIcon
          key={index}
          icon={star}
          style={{
            fontSize: '2.5rem',
            color: colors.lightGrey,
          }}
        />
      ))}
    </div>
  )
}
