export default function StepLoginOrRegister({ updateScheduleData, stepNext, step, scheduleData, trackStep }) {
  // const [authModalData, setAuthModalData] = useState({ viewType: 'register' })
  // const { userData, firebaseUser } = useContext(AuthContext)
  // const [checkAttempts, setCheckAttempts] = useState(0)
  // const [completed, setCompleted] = useState(false)

  // useEffect(()=>{
  //     trackStep("LoginOrRegister", step, scheduleData)
  // },[])

  // function handleClose() {
  //     //setAuthModalData({isOpen:false, viewType:authModalData.viewType})
  // }

  // function complete() {
  //     setCompleted(true)
  // }

  // function switchView(type) {
  //     if(type){
  //         setAuthModalData({ isOpen: true, viewType: type })
  //     } else {
  //         if (authModalData.viewType == 'register') {
  //             setAuthModalData({ isOpen: true, viewType: 'login' })
  //         } else {
  //             setAuthModalData({ isOpen: true, viewType: 'register' })
  //         }
  //     }

  // }

  // if (completed) {
  //     return (
  //         <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', padding: 40 }}>
  //             <IonSpinner name='dots' />
  //         </div>
  //     )
  // }

  return (
    <>
      {/* {authModalData.viewType == 'register'
                    ? <Fade in={true}><div><Register complete={complete} cancel={handleClose} switchView={switchView} removeHeader={true} headerMessage={"In order to continue please login or create a new account."} /></div></Fade>
                    : authModalData.viewType == 'login' ?
                    <Fade in={true}><div><Login complete={complete} cancel={handleClose} switchView={switchView} removeHeader={true} headerMessage={"In order to continue please login or create a new account."} /></div></Fade>
                        : authModalData.viewType == 'passwordReset' ?
                        <Fade in={true}><div><PasswordReset cancel={complete} complete={handleClose} switchView={switchView} removeHeader={true}/></div></Fade>
                            :
                            <Fade in={true}><div><Login complete={complete} cancel={handleClose} switchView={switchView} removeHeader={true} headerMessage={"In order to continue please login or create a new account."} /></div></Fade>
                } */}
    </>
  )
}
