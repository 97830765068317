import { IonImg, IonLabel, IonRow } from '@ionic/react'
import React from 'react'
import { colors } from '../theme/colors'

export default function LocationImages({ images }) {
  return (
    <>
      <IonRow
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '10px',
        }}
      >
        <IonLabel style={{ margin: '10px 0', fontWeight: 'bold' }}>Location Images</IonLabel>
      </IonRow>
      <IonRow
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        {images?.map((item, index) => {
          return (
            <IonImg
              key={index}
              style={{
                objectFit: 'cover',
                width: '49%',
                height: '8rem',
                backgroundColor: colors.white,
                borderRadius: '5px',
                border: '2px solid grey',
                marginBottom: '5px',
              }}
              src={item.url.replace('https://cors-anywhere.herokuapp.com/', '')}
            />
          )
        })}
      </IonRow>
    </>
  )
}
