import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonSpinner,
  IonText,
  IonTextarea,
  IonToggle,
  IonToast,
} from '@ionic/react'
import { useContext, useEffect, useState } from 'react'
import { validateUrl } from '../utils'
import { chevronForward } from 'ionicons/icons'
import { AuthContext } from '../auth'
import GetLocationDataBasedOnLocationId from '../Utils/GetLocationDataBasedOnLocationId'
import axios from 'axios'
import { GetReviewStylesBasedOnStatus } from './GetReviewStylesBasedOnStatus'
import CommonHeader from '../commonComponents/CommonHeader'

export default function SetupReview({
  selectedReviewOption,
  updateReviewSitesList,
  openModal = false,
  fromList = false,
  closeSetupReviewSite,
  reviewData = {},
  closeReviewModal,
  reviewSites,
}) {
  const { locationData, locationId } = useContext(AuthContext)
  const [setupReviewModalVisible, setSetupReviewModalVisible] = useState(openModal)
  const [reviewStatus, setReviewStatus] = useState('disabled')
  const [enableReview, setToggleEnableReview] = useState(false)
  const [reviewLink, setReviewLink] = useState('')
  const [selectedReviewData, setSelectedReviewData] = useState(false)
  const [updatingReviewToggle, setUpdatingReviewToggle] = useState(false)
  const [updatingReviewLink, setUpdatingReviewLink] = useState(false)
  // Toast state
  const [toast, setToast] = useState({ isOpen: false, message: '', color: '' })

  const showToastMessage = (message, color = 'success') => {
    setToast({ isOpen: true, message, color })
  }
  const handleReviewLink = (event) => {
    let newSiteLink = event.detail.value
    const isValidUrl = validateUrl(newSiteLink)
    setReviewLink(newSiteLink)
    let newSiteStatus = ''

    if (newSiteLink && newSiteLink !== '') {
      newSiteStatus = isValidUrl ? 'active' : 'error'
    } else {
      newSiteStatus = enableReview ? 'pending' : 'disabled'
    }

    setReviewStatus(newSiteStatus)

    if (newSiteStatus !== 'error') {
      updateSitesForLocation(newSiteStatus, newSiteLink)
    }
  }
  const toggleEnableReview = (value) => {
    setUpdatingReviewToggle(true)
    setToggleEnableReview(value)
    setReviewLink('')
    const newSiteStatus = value ? 'pending' : 'disabled'
    setReviewStatus(newSiteStatus)

    updateSitesForLocation(newSiteStatus)
  }
  const resetData = () => {
    setTimeout(() => {
      setReviewStatus('disabled')
      setToggleEnableReview(false)
      setReviewLink('')
      setUpdatingReviewLink(false)
      setUpdatingReviewToggle(false)
    }, 2000)
  }
  const openSetupReviewModal = () => {
    setSetupReviewModalVisible(true)
    updateSitesForLocation(reviewStatus)
  }
  const closeSetupReviewModal = () => {
    if (fromList) {
      closeSetupReviewSite()
      updateReviewSitesList()
    } else {
      setSetupReviewModalVisible(false)
    }

    resetData()
  }
  const updateSitesForLocation = async (newSiteStatus, newSiteLink) => {
    if (setupReviewModalVisible) {
      const selectedLocationData = GetLocationDataBasedOnLocationId(locationData, locationId)

      const newReviewSite = {
        name: fromList ? reviewData.name : selectedReviewOption.name,
        enabled: newSiteStatus !== 'disabled',
        status: newSiteStatus,
        link: newSiteLink ? newSiteLink : reviewLink,
      }

      let locationReviewSites = reviewSites ? [...reviewSites] : []
      let index = locationReviewSites.findIndex((item) => item.name === newReviewSite.name)

      if (index !== -1) {
        locationReviewSites[index] = newReviewSite
      } else {
        locationReviewSites.push(newReviewSite)
      }

      const reviewSettingsInput = {
        businessId: selectedLocationData.businessId,
        reviewSettings: {
          reviewRequestsEnabled: true,
          websites: locationReviewSites,
        },
      }

      try {
        const updateLocationResponse = await axios.patch(`/location_v2?id=${locationId}`, reviewSettingsInput)

        if (updateLocationResponse.status === 200) {
          setUpdatingReviewLink(false)
          setUpdatingReviewToggle(false)
          showToastMessage('Review site updated successfully!', 'success')
        }
      } catch (error) {
        showToastMessage('Error updating review site.', 'danger')
      }
    }
  }

  useEffect(() => {
    if (fromList) {
      setSetupReviewModalVisible(openModal)
    }
  }, [openModal])
  useEffect(() => {
    setSetupReviewModalVisible(setupReviewModalVisible)
  }, [setupReviewModalVisible])
  useEffect(() => {
    if (fromList && openModal) {
      setSelectedReviewData(reviewData)
      setReviewStatus(reviewData.status)
      setToggleEnableReview(reviewData.status !== 'disabled')
      setReviewLink(reviewData.link)
    }
  }, [openModal, fromList])

  useEffect(() => {
    setSelectedReviewData(selectedReviewOption)
  }, [selectedReviewOption])
  const statusStyles = GetReviewStylesBasedOnStatus(reviewStatus)

  return (
    <>
      {!fromList && (
        <IonButtons style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
          <IonButton onClick={openSetupReviewModal} color={'primary'}>
            <IonLabel style={{ fontWeight: 'bold' }}>Go</IonLabel>
            <IonIcon icon={chevronForward}></IonIcon>
          </IonButton>
        </IonButtons>
      )}
      <IonModal isOpen={setupReviewModalVisible}>
        <CommonHeader title={'Setup Review Site'} closeButton={true} saveDisabled={reviewStatus === 'error'} closeModal={closeSetupReviewModal} />
        <IonContent className='regularBackground'>
          <div style={{ padding: '1rem' }}>
            <h4 style={{ fontWeight: 'bold' }}>{selectedReviewData?.name} Business Page</h4>
            <div style={{ margin: '10px 0', padding: '5px 0' }}>
              <IonText style={statusStyles}>{reviewStatus}</IonText>
            </div>
            <div>
              <IonLabel style={{ color: 'grey', lineHeight: '1.5rem' }}>
                Enable review to be requested for your {selectedReviewData?.name} Business Page
              </IonLabel>
            </div>
            <IonList inset={true} style={{ margin: '1rem 0' }} mode='ios'>
              <IonItem>
                <IonCol size='9'>
                  <IonLabel>Enable Review</IonLabel>
                </IonCol>
                <IonCol size='3' style={{ display: 'flex', justifyContent: 'end' }}>
                  {updatingReviewToggle && <IonSpinner name='lines' />}
                  {!updatingReviewToggle && (
                    <IonToggle justify='end' size='small' checked={enableReview} onIonChange={(e) => toggleEnableReview(e.detail.checked)} />
                  )}
                </IonCol>
              </IonItem>
            </IonList>
            <>
              <h5 style={{ fontWeight: 'bold' }}>Settings</h5>
              <IonList style={{ padding: '1rem' }} mode='ios'>
                <h6 style={{ paddingTop: 0 }}>Link to be requested for your {selectedReviewData?.name} Business Page</h6>
                <IonTextarea
                  style={{ border: '1px solid #d6d6d6', borderRadius: '5px', padding: 4 }}
                  type='text'
                  value={reviewLink}
                  onIonChange={handleReviewLink}
                ></IonTextarea>
                {updatingReviewLink && (
                  <div style={{ marginTop: '0.5rem', display: 'flex', alignItems: 'center' }}>
                    <IonSpinner name='lines ' style={{ marginRight: '0.6rem' }} />
                    <IonLabel style={{ color: 'grey', fontSize: '1rem' }}>Updating Review Link...</IonLabel>
                  </div>
                )}
              </IonList>
            </>
          </div>
        </IonContent>
        <IonToast
          position='top'
          isOpen={toast.isOpen}
          onDidDismiss={() => setToast({ ...toast, isOpen: false })}
          message={toast.message}
          color={toast.color}
          duration={2000}
        />
      </IonModal>
    </>
  )
}
