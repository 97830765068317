import { IonButton } from '@ionic/react'
import React, { useState, useRef, useEffect, useCallback } from 'react'
import SignatureCanvas from 'react-signature-canvas'

export default function SignatureCanva({ updateCustomerSignature, isSubscribing }) {
  const padRef = useRef(null)
  const [canvas, setCanvas] = useState('')
  const [canvasVisibility, setCanvasVisibility] = useState(false)

  const clearSignatureCanvas = useCallback(() => {
    padRef?.current?.clear()
    updateCustomerSignature(false)
    setCanvas(undefined)
    setCanvasVisibility(false)
  }, [])

  const handleGetCanvas = useCallback(() => {
    const canvas = padRef?.current?.toDataURL()
    updateCustomerSignature(canvas)
    setCanvas(canvas)
    setCanvasVisibility(true)
  }, [])
  return (
    <div className='App'>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {canvasVisibility ? null : (
          <SignatureCanvas
            ref={padRef}
            backgroundColor={'white'}
            canvasProps={{
              width: 500,
              height: 200,
            }}
          />
        )}
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {canvasVisibility && <img src={canvas} alt='signature' />}
      </div>
      <div
        style={{
          marginTop: '1rem',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {canvas && (
          <IonButton onClick={clearSignatureCanvas} disabled={isSubscribing}>
            Clear
          </IonButton>
        )}
        {!canvas && (
          <IonButton onClick={handleGetCanvas} disabled={isSubscribing}>
            Save
          </IonButton>
        )}
      </div>
    </div>
  )
}
