import { IonGrid, IonIcon, IonLabel, IonRow, IonSpinner } from '@ionic/react'
import commonStyles from '../../theme/commonstyles.module.css'
import { returnUpBackOutline } from 'ionicons/icons'

export default function FullRefund({
  paymentDetails,
  processingRefund,
  handleFullRefund,
  successMessage,
  errorMessage,
  cardNumber,
}) {
  const amount = paymentDetails?.amount
  let lastTwoDigits = false
  let remainingDigits = false
  if (amount) {
    // Divide the amount by 100
    const dividedAmount = amount
    // Convert the amount to a string
    const amountString = dividedAmount.toString()
    // Extract the last two digits
    lastTwoDigits = amountString.slice(-2)
    // Extract the remaining digits
    remainingDigits = amountString.slice(0, -2)
  }

  return (
    <IonGrid>
      <IonRow className={`${commonStyles.flexCenter}`}>
        <h4 style={{ fontSize: '1.5rem' }}>$</h4>
        <h3 style={{ fontSize: '2rem', display: 'flex' }}>
          {remainingDigits}
          <span style={{ fontSize: '1rem', marginTop: '5px' }}>{lastTwoDigits}</span>
        </h3>{' '}
      </IonRow>
      <IonRow className={`${commonStyles.flexCenter}`}>
        <h6 style={{ textAlign: 'center' }}>
          $ {(paymentDetails?.amount / 100)?.toFixed(2)} will be refunded to Visa .... {cardNumber}
        </h6>
      </IonRow>
      <span style={{ margin: '1rem 0', border: '1px solid #dad5d5' }}></span>

      <IonRow className={`${commonStyles.flexCenter}`}>
        {processingRefund && <IonSpinner name='lines' />}
        {!processingRefund && (
          <div
            onClick={handleFullRefund}
            className={`${commonStyles.flexCenter}`}
            style={{ padding: '10px', cursor: 'pointer' }}
          >
            <IonIcon
              size='medium'
              color='primary'
              style={{ marginRight: '10px', height: 25, width: 25 }}
              icon={returnUpBackOutline}
            />
            <IonLabel color='primary'>Refund</IonLabel>
          </div>
        )}
      </IonRow>
      <IonRow className={commonStyles.flexCenter}>
        <IonLabel
          className={commonStyles.flexCenter}
          style={{ textAlign: 'center', color: 'green' }}
        >
          {successMessage}
        </IonLabel>
      </IonRow>
      <IonRow className={commonStyles.flexCenter}>
        <IonLabel className={commonStyles.flexCenter} style={{ textAlign: 'center', color: 'red' }}>
          {errorMessage}
        </IonLabel>
      </IonRow>
    </IonGrid>
  )
}
