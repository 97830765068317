import axios from 'axios'

const checkEmailExists = async (type = 'customer', email) => {
  try {
    let response = await axios.get(`/${type}/checkEmail?email=${email}`)

    return response.data
  } catch (error) {
    return { emailExists: false, userInvitationExists: false }
  }
}
export default checkEmailExists
