import { IonRow } from '@ionic/react'
import React from 'react'

const NoPermissionsCard = () => {
  return (
    <IonRow style={styles.container}>
      <h2>No Permissions</h2>
      <p>
        You don't have the necessary permissions to access this feature. Please reach out to your
        administrator for assistance.
      </p>
    </IonRow>
  )
}

const styles = {
  container: {
    textAlign: 'center',
    padding: '20px',
    border: '1px solid #ccc',
    borderRadius: '8px',
    backgroundColor: '#fff',
    maxWidth: '400px',
    margin: 'auto',
    marginTop: '50px',
  },
}

export default NoPermissionsCard
