import { IonIcon, IonItem, IonLabel, IonList } from '@ionic/react'
import { trash } from 'ionicons/icons'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'

export default function InviteResultsList({
  invites,
  loading,
  error,
  deleteInvite,
  refreshInvites,
}) {
  const history = useHistory()

  useEffect(() => {
    const unlisten = history.listen((location, action) => {
      refreshInvites()
    })
    return () => {
      unlisten() // Clean up the listener when the component unmounts
    }
  }, [history])

  if (loading) {
    return null
  } else if (!loading && (invites?.length == 0 || !invites)) {
    return null
  }

  return (
    <>
      <h6 style={{ color: 'grey', marginLeft: 20 }}>Invites</h6>
      <IonList inset={true} mode='ios'>
        {invites?.map(row => (
          <IonItem
            button
            detail={true}
            routerLink={`/core/users/invites/${row._id}`}
            routerDirection='forward'
            key={row._id}
          >
            <IonLabel>
              <h4 style={{ fontWeight: '600', color: 'black', fontSize: 18 }}>{`${row.email}`}</h4>
              <p>{row.createdDate}</p>
            </IonLabel>
          </IonItem>
        ))}
      </IonList>
    </>
  )
}
