import React from 'react'

const DataVisualization = ({ data }) => {
  // Extracting months and their corresponding values from the data object
  const months = Object.keys(data)
  const values = Object.values(data)

  // Function to generate table rows for each month
  const generateRows = () => {
    const rows = []

    // Loop through each month
    for (let i = 0; i < months.length; i++) {
      const month = months[i]
      const monthData = values[i]

      // Generating table cells for the month
      const cells = []
      cells.push(
        <div
          key={`${month}-name`}
          style={{
            display: 'table-cell',
            padding: '5px 10px',
            borderBottom: '1px solid #ddd',
            borderRight: '1px solid #f1f1f1',
            minWidth: '60px',
            whiteSpace: 'nowrap',
            backgroundColor: i % 2 === 0 ? '#f6f6f6' : '#f5f5f5',
            position: 'sticky',
            left: 0,
            zIndex: 2,
          }}
        >
          {month}
        </div>,
      )

      // Loop through each value of the month and generate table cells
      for (let j = 0; j < monthData.length; j++) {
        // Determine color based on value
        const color = getColor(monthData[j])

        cells.push(
          <div
            key={`${month}-${j}`}
            style={{
              display: 'table-cell',
              padding: '5px 10px',
              borderBottom: '1px solid #ddd',
              borderRight: '1px solid #f1f1f1',
              minWidth: '60px',
              whiteSpace: 'nowrap',
              position: j == 0 ? 'sticky' : '',
              left: j == 0 ? '5rem' : '',
              zIndex: j == 0 ? 999 : 0,
              backgroundColor: j == 0 ? '#f5f5f5' : color,
            }}
          >
            {j == 0 ? monthData[j] : `${monthData[j]}%`}
          </div>,
        )
      }

      // Generating table row for the month
      rows.push(
        <div key={month} style={{ display: 'table-row' }}>
          {cells}
        </div>,
      )
    }

    return rows
  }
  // Function to get color based on value
  const getColor = value => {
    // Define color scale with shades of blue

    const colorScale = [
      '#F0FAF4',
      '#DDF5E7',
      '#CCEEDB',
      '#B9E8D1',
      '#A6E0C7',
      '#94D9BE',
      '#81D1B5',
      '#70C9AD',
      '#5EC1A4',
      '#4AB999',
      '#39B18D',
    ];
    

    // Determine color based on value
    const index = Math.min(Math.floor(value / 2), colorScale.length - 1)
    return value == 0 ? 'white' : colorScale[index]
  }
  // Generate month numbers for the top row
  const generateMonthNumbers = () => {
    const monthNumbers = []

    monthNumbers.push(
      <div
        key={'keys'}
        style={{
          display: 'table-cell',
          padding: '5px 10px',
          borderBottom: '1px solid #ddd',
          borderRight: '1px solid #f1f1f1',
          minWidth: '60px',
          whiteSpace: 'nowrap',
          backgroundColor: '#f5f5f5',
          position: 'sticky',
          top: 0,
          zIndex: 2,
          left: 0,
        }}
      >
        Months
      </div>,
    )

    for (let i = 0; i < 12; i++) {
      monthNumbers.push(
        <div
          key={`month-${i}`}
          style={{
            display: 'table-cell',
            padding: '5px 10px',
            borderBottom: '1px solid #ddd',
            borderRight: '1px solid #f1f1f1',
            minWidth: '60px',
            width: '3rem',
            whiteSpace: 'nowrap',
            backgroundColor: i % 2 === 0 ? '#c5d5e3' : '#d9e6ed',
            position: 'sticky',
            top: 0,
            left: i == 0 ? '5rem' : '',
            zIndex: i == 0 ? 999 : 1,
          }}
        >
          {i == 0 ? 'Customers' : `Month ${i}`}
        </div>,
      )
    }

    return monthNumbers
  }

  return (
    <div style={{ padding: '1rem' }}>
      <div style={{ overflowX: 'auto' }}>
        <div
          style={{
            display: 'table',
            width: '100%',
            borderCollapse: 'collapse',
            textAlign: 'center',
          }}
        >
          <div
            style={{
              display: 'table-row',
              fontWeight: 'bold',
              padding: '15px 30px',
              borderBottom: '1px solid #f1f1f1',
              borderTop: '1px solid #f1f1f1',
            }}
          >
            {generateMonthNumbers()}
          </div>
          {generateRows()}
        </div>
      </div>
    </div>
  )
}

// Usage of the component
const RetentionReportDataVisualization = ({ data }) => {
  return <DataVisualization data={data} />
}

export default RetentionReportDataVisualization
