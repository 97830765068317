import React, { useState } from 'react'
import { IonIcon } from '@ionic/react'
import { star, starOutline } from 'ionicons/icons'

const FeedbackRating = ({ rating, onRatingChange }) => {
  const [hoverRating, setHoverRating] = useState(0)

  const handleStarClick = selectedRating => {
    onRatingChange(selectedRating)
  }

  return (
    <div
      style={{
        margin: '2rem 0 2rem 0',
        display: 'flex',
        justifyContent: 'space-evenly',
      }}
    >
      {[1, 2, 3, 4, 5].map(index => (
        <IonIcon
          key={index}
          icon={star}
          style={{
            fontSize: '3.5rem',
            color: index <= (hoverRating || rating) ? '#3780ff' : 'grey',
            cursor: 'pointer',
          }}
          onMouseEnter={() => setHoverRating(index)}
          onMouseLeave={() => setHoverRating(0)}
          onClick={() => handleStarClick(index)}
        />
      ))}
    </div>
  )
}

export default FeedbackRating
