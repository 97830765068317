import { IonCard, IonItem, IonLabel, IonList, IonRow } from '@ionic/react'
import spacingStyles from '../../theme/spacing.module.css'
import commonStyles from '../../theme/commonstyles.module.css'
import GetDateFromEpoch from '../../Utils/GetDateFromEpoch'
import styles from './CreditBalance.module.css'

export default function CreditBalanceTransactions({ transactions }) {
  return (
    <IonCard>
      <IonRow
        style={{ width: '100%' }}
        className={`${commonStyles.flexCenter} ${spacingStyles.p4} ${spacingStyles.mtb4}`}
      >
        <IonLabel style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>Credit History</IonLabel>
      </IonRow>
      <IonRow style={{ width: '100%' }} className={commonStyles.flexCenter}>
        <IonList className={styles.listStyles} inset={true} mode='ios'>
          {transactions &&
            transactions.length > 0 &&
            transactions.map(transaction => {
              const date = GetDateFromEpoch(transaction.created)
              return (
                <IonItem key={transaction.id}>
                  <IonLabel>
                    <IonLabel style={{ fontSize: '14px' }}>
                      {transaction?.metadata?.internal_notes}
                    </IonLabel>
                    <IonLabel style={{ marginTop: '5px', color: 'grey', fontSize: '12px' }}>
                      {date}
                    </IonLabel>
                  </IonLabel>
                  <IonRow
                    className={`${commonStyles.displayFlex} ${commonStyles.justifyEnd} ${commonStyles.alignEnd} ${commonStyles.flexColumn}`}
                  >
                    <IonLabel
                      style={{
                        minWidth: '4rem',
                        fontSize: '1rem',
                        fontWeight: 'bold',
                        display: 'flex',
                        justifyContent: 'end',
                        alignItems: 'end',
                        marginBottom: '5px',
                      }}
                      color={transaction.amount >= 0 ? 'dark' : 'primary'}
                    >
                      
                      {formatDollarValue(transaction?.amount / 100)}
                    </IonLabel>
                    <IonLabel style={{ fontSize: '12px', color: 'grey' }}>
                      {formatDollarValue(transaction?.ending_balance / 100)}
                    </IonLabel>
                  </IonRow>
                </IonItem>
              )
            })}
          {transactions && transactions.length == 0 && <IonLabel>No transactions</IonLabel>}
        </IonList>
      </IonRow>
    </IonCard>
  )
}

function formatDollarValue(value) {
  if (value < 0) {
    const formattedValue = Math.abs(value).toFixed(2);
    return `($${formattedValue})`;
  } else {
    return `$${value.toFixed(2)}`;
  }
}
