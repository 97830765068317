import { IonButton, IonSpinner } from '@ionic/react'

export default function ChangeSubscriptionActionSection({
  useCustomProrationValue,
  customProratedAmount,
  errorMessage,
  isSubscribing,
  isErrorWithCustomInput,
  currentSubscription,
  selectedPrice,
  officialProratedAmount,
  subscriberUserToPlan,
  customerSignatureImage,
}) {
  return (
    <div style={{ padding: 14, paddingBottom: 0, paddingTop: 0 }}>
      {useCustomProrationValue && isNaN(parseFloat(customProratedAmount)) ? (
        <p style={{ color: 'red', fontSize: 12 }}>**Fix Custom Prorated Amount Input</p>
      ) : null}
      {errorMessage ? <p style={{ color: 'red', fontSize: 12 }}>**{errorMessage}</p> : null}
      <IonButton
        disabled={
          isSubscribing ||
          isErrorWithCustomInput ||
          currentSubscription?.stripeSubscriptionPlanId == selectedPrice.stripePriceId
        }
        expand='block'
        onClick={subscriberUserToPlan}
      >
        {isSubscribing ? (
          <IonSpinner name='dots' />
        ) : (
          <>Change Plan and Pay ${officialProratedAmount?.toFixed(2)}</>
        )}
      </IonButton>
    </div>
  )
}
