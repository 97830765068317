import { IonCol, IonContent, IonItem, IonLabel, IonList, IonPage, IonSpinner, IonToggle, IonToast } from '@ionic/react'
import React, { useContext, useEffect, useState } from 'react'
import CommonHeader from '../commonComponents/CommonHeader'
import StandardContainer from '../commonComponents/StandardContainer'
import axios from 'axios'
import { AuthContext } from '../auth'
import GetLocationDataBasedOnLocationId from '../Utils/GetLocationDataBasedOnLocationId'

export default function EmailReminder() {
  const { locationData, locationId, businessData } = useContext(AuthContext)
  const [enableEmailReminders, setEnableEmailReminders] = useState(false)
  const [enablingReminders, setEnablingReminders] = useState(true)

  // Toast state
  const [toast, setToast] = useState({
    isOpen: false,
    message: '',
    color: 'success',
  })

  const showToastMessage = (message, color = 'success') => {
    setToast({ isOpen: true, message, color })
  }

  const toggleEmailReminders = async () => {
    const value = !enableEmailReminders
    setEnableEmailReminders(value)
    setEnablingReminders(true)
    try {
      const response = await axios.patch(`/location_v2?id=${locationId}`, {
        businessId: businessData?._id,
        remindersEnabled: value,
      })
      if (response && response.status === 200) {
        showToastMessage('Email reminders updated successfully!')
      } else {
        showToastMessage('Failed to update email reminders.', 'danger')
      }
    } catch (error) {
      showToastMessage('Error updating email reminders.', 'danger')
    } finally {
      setEnablingReminders(false)
    }
  }

  useEffect(() => {
    const locationDataResponse = GetLocationDataBasedOnLocationId(locationData, locationId)
    if (locationDataResponse?._id) {
      setEnableEmailReminders(locationDataResponse?.remindersEnabled)
      setEnablingReminders(false)
    } else {
      setEnablingReminders(false)
    }
  }, [locationId])

  return (
    <IonPage id='main-content'>
      <CommonHeader title={'Email Reminders'} backIcon={true} />
      <IonContent>
        <StandardContainer>
          <div style={{ padding: '0 1rem' }}>
            <IonList inset={true} style={{ margin: '1rem 0' }} mode='ios'>
              <IonItem>
                <IonCol size='9'>
                  <IonLabel>Enable Email Reminders</IonLabel>
                </IonCol>
                <IonCol size='3' style={{ display: 'flex', justifyContent: 'end' }}>
                  {enablingReminders && <IonSpinner name='lines' />}
                  {!enablingReminders && (
                    <IonToggle
                      justify='space-between'
                      size='small'
                      checked={enableEmailReminders}
                      onIonChange={(e) => toggleEmailReminders(e.detail.checked)}
                    />
                  )}
                </IonCol>
              </IonItem>
            </IonList>
          </div>
        </StandardContainer>
      </IonContent>
      <IonToast
        position='top'
        isOpen={toast.isOpen}
        onDidDismiss={() => setToast({ ...toast, isOpen: false })}
        message={toast.message}
        color={toast.color}
        duration={2000}
      />
    </IonPage>
  )
}
