import { useState } from 'react'
import CustomInput from '../../../SetupBusiness/CustomInput'
import { logoFacebook, logoInstagram, logoTwitter } from 'ionicons/icons'
import ButtonPanel from '../buttonPanel'

const ContactForm = ({ data, flowIndex, onBack, onNext, onCancel, history }) => {
  const [logo, setLogo] = useState('')
  const [primary, setPrimary] = useState('')

  const handleLogo = (index, value) => setLogo(() => {})
  const handlePrimary = (index, value) => setPrimary(() => {})
  const handleNext = () => onNext()

  return (
    <>
      <p>
        Your contact information is important to allow your customers to get in touch. The following contact information will be displayed on your site. Any
        field left blank wont show up on the website but can be added later
      </p>
      <CustomInput label={'Business Email address? (optional)'} value={logo} placeholder='' handleInput={handleLogo} />
      <p>We recommend a seperate business email address that isnt the same as your private email</p>
      <CustomInput icon={logoTwitter} label={'X formerly Twitter (optional)'} value={primary} placeholder='#0000' handleInput={handlePrimary} />
      <CustomInput icon={logoInstagram} label={'Instagram'} value={primary} placeholder='#0000' handleInput={handlePrimary} />
      <CustomInput icon={logoFacebook} label={'Facebook'} value={primary} placeholder='#0000' handleInput={handlePrimary} />
      <ButtonPanel data={data} flowIndex={flowIndex} onBack={onBack} onNext={handleNext} onCancel={onCancel} history={history} />
    </>
  )
}

export default ContactForm
