import { IonCol, IonGrid, IonIcon, IonInput, IonItem, IonLabel, IonList, IonRow } from '@ionic/react'
import { calendarClearOutline, createOutline, timeOutline } from 'ionicons/icons'
import { getFormattedMediumDateNoYear } from '../utils'
import { colors } from '../theme/colors'

export default function ProratedDetailsSection({
  proratedAmountCalculatedServer,
  useCustomProrationValue,
  customProratedAmount,
  isOpenChangeProratedAmountBox,
  setCustomProratedAmount,
  setIsOpenChangeProratedAmountBox,
}) {
  return (
    <IonList inset={true} mode='ios'>
      <IonItem>
        <IonLabel>
          <IonGrid style={{ margin: 0, padding: 0, marginLeft: -4, fontSize: 14 }}>
            <IonRow>
              <IonCol size='auto' style={{ marginTop: 2, marginBottom: -2 }}>
                <IonIcon icon={calendarClearOutline} />
              </IonCol>
              <IonCol>Next Bill Date</IonCol>
              <IonCol size='auto'>{getFormattedMediumDateNoYear(proratedAmountCalculatedServer?.data?.nextInvoiceDate)}</IonCol>
            </IonRow>
            <IonRow>
              <IonCol size='auto' style={{ marginTop: 2, marginBottom: -2 }}>
                <IonIcon icon={timeOutline} />
              </IonCol>
              <IonCol>Days Until Next Bill</IonCol>
              <IonCol size='auto'>{proratedAmountCalculatedServer?.data?.daysUntilNextInvoice} days</IonCol>
            </IonRow>

            <IonRow>
              <IonCol>
                <div style={{ height: 1, widht: '100%', backgroundColor: '#dfdfdf' }} />
              </IonCol>
            </IonRow>
            <IonRow
              style={{
                fontSize: 17,
                textDecoration: useCustomProrationValue ? 'line-through' : 'none',
                color: useCustomProrationValue ? '#cdcdcd' : 'black',
              }}
            >
              <IonCol>
                <strong>Prorated Amount Due Now</strong>
              </IonCol>
              <IonCol size='auto'>
                <strong> ${proratedAmountCalculatedServer?.data?.prorationAmount?.toFixed(2)}</strong>
              </IonCol>
            </IonRow>
            {useCustomProrationValue && !isNaN(customProratedAmount) ? (
              <IonRow style={{ fontSize: 17 }}>
                <IonCol>
                  <strong>Prorated Amount Due Now</strong>
                </IonCol>
                <IonCol size='auto'>
                  <strong> ${customProratedAmount}</strong>
                </IonCol>
              </IonRow>
            ) : null}
            <IonRow
              style={{ color: colors.primaryBlue, fontSize: 14 }}
              onClick={() => {
                if (isOpenChangeProratedAmountBox) {
                  setCustomProratedAmount(null)
                }
                setIsOpenChangeProratedAmountBox(!isOpenChangeProratedAmountBox)
              }}
            >
              <IonCol size='auto'>
                <IonIcon icon={createOutline} />
              </IonCol>
              <IonCol>Change Prorated Amount</IonCol>
            </IonRow>
          </IonGrid>
        </IonLabel>
      </IonItem>
      {isOpenChangeProratedAmountBox ? (
        <IonItem>
          $<>&nbsp;</>
          <IonInput
            label='Custom Prorated Amount'
            labelPlacement='stacked'
            clearInput={true}
            placeholder='Custom Prorated Amount'
            onIonInput={(e) => {
              if (e.detail.value == '') {
                setCustomProratedAmount(null)
              } else {
                setCustomProratedAmount(parseFloat(e.detail.value).toFixed(2))
              }
            }}
          ></IonInput>
        </IonItem>
      ) : null}
    </IonList>
  )
}
