import { IonCol, IonGrid, IonIcon, IonLabel, IonRow } from '@ionic/react'
import React from 'react'
import Avatar from '../../commonComponents/avatar'
import { statusColors } from '../../StaticData/StaticData'
import { footstepsOutline, timeOutline } from 'ionicons/icons'

export default function EventData({ appointment, businessData }) {
  return (
    <IonGrid style={{ display: 'grid', width: '100%', padding: 0 }}>
      <IonRow>
        <IonCol style={{ padding: 0 }}>
          <IonRow style={{ width: '100%', margin: '2px 0' }}>
            <IonLabel
              style={{
                fontSize: 14,
                fontWeight: 'bold',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {appointment.customer?.firstName} {appointment.customer?.lastName}
            </IonLabel>
          </IonRow>
          <IonRow
            style={{
              margin: '4px 0',
              width: '100%',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            }}
          >
            <IonLabel style={{ textWrap: 'wrap', fontSize: 12 }}>
              {appointment.services?.map((service, index) => (
                <React.Fragment key={index}>
                  {service.name}
                  {index < appointment.services.length - 1 && ', '}
                </React.Fragment>
              ))}
            </IonLabel>
          </IonRow>
          <IonRow
            style={{
              margin: '4px 0',
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <IonIcon style={{ height: 8, width: 8, marginRight: 2 }} icon={timeOutline} />
              <IonLabel style={{ fontSize: 10 }}>
                {appointment.startTimeText ? `${appointment.startTimeText} - ${appointment.endTimeText} | ` : null}
                {appointment.durationMin} min
              </IonLabel>
            </div>
          </IonRow>
        </IonCol>
        <IonCol size='auto'>
          <IonRow>
            <Avatar employee={appointment.barber} businessId={businessData?._id} mini />
          </IonRow>
          {appointment.type === 'Walkin' && (
            <IonRow>
              <IonIcon
                style={{
                  color: statusColors[appointment.status]?.textColor || '#000',
                  position: 'absolute',
                  bottom: 0,
                  right: 0,
                  height: 12,
                  width: 12,
                  marginRight: '5px',
                }}
                icon={footstepsOutline}
              />
            </IonRow>
          )}
        </IonCol>
      </IonRow>
    </IonGrid>
  )
}
