import { IonLabel, IonModal, IonItem, IonContent, IonIcon } from '@ionic/react'
import { useState } from 'react'
import { expandOutline, footstepsOutline, timeOutline } from 'ionicons/icons'
import CommonHeader from '../../commonComponents/CommonHeader'
import { calculateElapsedTime } from './Utils'
import ResizableModal from './ResizableModal'
import { styles } from './Styles/WalkinAppointmentStyles'
import { useHistory } from 'react-router'

export default function WalkinAppointments({ walkinsList }) {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedEvent, setSelectedEvent] = useState(null)
  const [showResizableModal, setShowResizableModal] = useState(false)
  const history = useHistory()
  const closeModal = () => {
    setIsModalOpen(false)
  }

  const openModal = () => {
    setIsModalOpen(true)
  }

  const handleItemClick = (walkin) => {
    closeModal()
    history.push(`/core/appointments/${walkin._id}`)
    // setSelectedEvent(walkin)
    // setShowResizableModal(true)
  }

  const closeResizableModal = () => {
    setShowResizableModal(false)
    setSelectedEvent(null)
  }

  return (
    <>
      <div style={styles.headerStyle} onClick={openModal}>
        <IonLabel style={{ padding: 10, width: '100%', display: 'block' }}>{walkinsList.length} Walk-ins Waiting</IonLabel>
        <IonIcon
          icon={expandOutline}
          style={{
            padding: 1,
            marginRight: 10,
            background: '#fac966',
            borderRadius: '50%',
          }}
        />
      </div>

      <IonModal trigger='open-modal' initialBreakpoint={0.75} breakpoints={[0.75, 1]} cssClass='custom-modal' isOpen={isModalOpen} onDidDismiss={closeModal}>
        <CommonHeader title={'Walk-ins'} />
        <IonContent>
          <div style={styles.modalContainerStyle}>
            {walkinsList.length > 0 ? (
              walkinsList.map((walkin, index) => {
                const services = walkin?.services?.map((item) => item.name)
                return (
                  <div onClick={() => handleItemClick(walkin)} style={styles.walkinItemStyle} key={index}>
                    <div style={styles.walkinDetailStyle}>
                      <div style={styles.walkinHeaderStyle}>
                        <div style={styles.walkinNameStyle}>
                          {walkin?.customer?.firstName}-{walkin?.customer?.lastName}
                        </div>
                        <div style={styles.walkinServiceStyle}>{services?.join(',')}</div>
                      </div>
                      <div style={styles.infoContainerStyle}>
                        <div style={styles.infoItemStyle}>
                          <IonIcon icon={timeOutline} style={{ marginRight: 5 }} />
                          <div style={{ color: '#222222', fontSize: 14, fontFamily: 'Figtree', fontWeight: '500', lineHeight: '20px', wordWrap: 'break-word' }}>
                            {walkin.durationMin} Min
                          </div>
                        </div>
                        <div style={styles.infoDividerStyle}></div>
                        <div style={styles.infoContainerStyle}>
                          <IonIcon style={{ color: '#CC6900' }} icon={footstepsOutline} />
                          <div style={styles.waitingStyle}>
                            Waiting {calculateElapsedTime(walkin?.timing?.stateTimestamps?.scheduled, new Date().getTime())}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })
            ) : (
              <IonItem>
                <IonLabel>No walk-in appointments</IonLabel>
              </IonItem>
            )}
          </div>
        </IonContent>
      </IonModal>
      <ResizableModal closeModal={closeResizableModal} selectedEvent={selectedEvent} showModal={showResizableModal} setShowModal={setShowResizableModal} />
    </>
  )
}
