import { IonButton, IonCard, IonContent, IonInput, IonItem, IonLabel, IonList, IonModal, IonSelect, IonSelectOption } from '@ionic/react'
import { useEffect, useState } from 'react'
import CommonHeader from '../commonComponents/CommonHeader'

export default function AddUpdateSubscriptionOption({ subscriptionPackage_id, editMode, openModal, optionData, closeModal, modalOpen, addUpdatePricing }) {
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    amount: '',
    currency: 'usd',
    billingPeriod: '',
    interval: 'month',
    interval_count: 2,
    active: true,
  })

  const billingPeriodOptions = ['Daily', 'Weekly', 'Monthly', 'Yearly', 'Every 3 Months', 'Every 6 Months', 'Custom']

  const handlePricing = () => {
    const intervalData = mapBillingOptionToInterval(formData.billingPeriod)

    let formDataTemp = { ...formData }
    if (formData.billingPeriod == 'Custom') {
      formDataTemp = {
        ...formDataTemp,
        billingPeriod: 'custom',
        amount: formDataTemp.amount,
      }
    } else {
      formDataTemp = {
        ...formDataTemp,
        billingPeriod: intervalData.billingPeriod,
        interval: intervalData.interval,
        interval_count: intervalData.intervalCount,
        amount: formDataTemp.amount,
      }
    }

    addUpdatePricing(formDataTemp)
    closeModal()
  }
  const handleCloseModal = () => {
    setFormData({
      name: '',
      description: '',
      amount: '',
      currency: 'usd',
      billingPeriod: '',
      interval: 'month',
      interval_count: 2,
    })
    closeModal()
  }
  const mapBillingOptionToInterval = (billingOption) => {
    switch (billingOption) {
      case 'Daily':
        return { billingPeriod: 'Daily', interval: 'day', intervalCount: 1 }
      case 'Weekly':
        return { billingPeriod: 'Weekly', interval: 'week', intervalCount: 1 }
      case 'Monthly':
        return { billingPeriod: 'Monthly', interval: 'month', intervalCount: 1 }
      case 'Yearly':
        return { billingPeriod: 'Yearly', interval: 'year', intervalCount: 1 }
      case 'Every 3 Months':
      case 'Every 6 Months':
        return {
          billingPeriod: 'Custom',
          interval: 'month',
          intervalCount: billingOption.includes('3') ? 3 : 6,
        }
      default:
        return { interval: '', intervalCount: 1 }
    }
  }
  const handleInput = (field, value) => {
    setFormData((prevData) => ({ ...prevData, [field]: value }))
  }
  const isButtonDisabled = !formData.name || !formData.amount || !formData.billingPeriod
  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      ...optionData,
      name: optionData?.name || '',
      description: optionData?.description || '',
      amount: Math.abs(optionData.amount) || '',
      currency: optionData?.currency || 'usd',
      billingPeriod: optionData?.billingPeriod == 'custom' ? 'Custom' : optionData?.billingPeriod || '',
      interval_count: optionData?.interval_count || 2,
      interval: optionData?.interval || 'month',
    }))
  }, [editMode])

  return (
    <IonModal isOpen={modalOpen} onDidDismiss={handleCloseModal}>
      <CommonHeader title={editMode ? 'Update Pricing' : 'Add Pricing'} closeButton={true} closeModal={handleCloseModal}></CommonHeader>

      <IonContent>
        <IonCard>
          <IonList mode='ios' inset={true}>
            <IonItem>
              <IonLabel position='stacked'>Name</IonLabel>
              <IonInput onIonInput={(e) => handleInput('name', e.detail.value)} placeholder='Name' type='text' value={formData.name}></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel position='stacked'>Description</IonLabel>
              <IonInput
                onIonInput={(e) => handleInput('description', e.detail.value)}
                placeholder='Description'
                type='text'
                value={formData.description}
              ></IonInput>
            </IonItem>
            <IonItem style={{ marginTop: '0.5rem' }}>
              <span style={{ marginRight: '1rem' }}>$</span>
              <IonInput onIonInput={(e) => handleInput('amount', e.detail.value)} placeholder='Amount' type='text' value={formData.amount}></IonInput>
              <IonSelect placeholder='Currency' value={formData.currency} onIonChange={(e) => handleInput('currency', e.detail.value)}>
                <IonSelectOption value='usd'>USD</IonSelectOption>
              </IonSelect>
            </IonItem>
            <IonItem>
              <IonLabel position='stacked'>Billing Period</IonLabel>

              <IonSelect placeholder='Choose Type' value={formData.billingPeriod} onIonChange={(e) => handleInput('billingPeriod', e.detail.value)}>
                {billingPeriodOptions.map((option) => (
                  <IonSelectOption key={option} value={option}>
                    {option}
                  </IonSelectOption>
                ))}
              </IonSelect>
            </IonItem>

            {formData.billingPeriod === 'Custom' && (
              <IonItem style={{ marginTop: '0.5rem' }}>
                <span style={{ fontSize: '14px' }}>Every</span>
                <IonInput
                  style={{ marginLeft: '1rem' }}
                  onIonInput={(e) => handleInput('interval_count', e.detail.value)}
                  placeholder='Amount'
                  type='number'
                  value={formData.interval_count}
                ></IonInput>
                <IonSelect placeholder='Interval' value={formData.interval} onIonChange={(e) => handleInput('interval', e.detail.value)}>
                  <IonSelectOption value='day'>Days</IonSelectOption>
                  <IonSelectOption value='month'>Months</IonSelectOption>
                  <IonSelectOption value='week'>Weeks</IonSelectOption>
                  <IonSelectOption value='year'>Years</IonSelectOption>
                </IonSelect>
              </IonItem>
            )}
            <IonButton disabled={isButtonDisabled} onClick={handlePricing} style={{ margin: '1rem 0' }} expand='full'>
              {editMode ? 'Update' : 'Add'}
            </IonButton>
          </IonList>
        </IonCard>
      </IonContent>
    </IonModal>
  )
}
