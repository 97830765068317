import { IonButton, IonIcon, IonRow, IonSpinner } from '@ionic/react'
import { checkmarkCircle } from 'ionicons/icons'
import React, { useEffect, useState } from 'react'

export default function CreatingProfileCard({ createdBusiness }) {
  const steps = [
    'Creating your business profile',
    'Setting up your personal website',
    'Putting everything in place',
  ]
  const [animationIndex, setAnimationIndex] = useState(0)

  useEffect(() => {
    // Start the animation with a delay for each step
    steps.forEach((step, index) => {
      setTimeout(() => {
        setAnimationIndex(index)
      }, index * 1000) // Increase the delay for each step
    })
  }, [])

  return (
    <>
      <IonRow>
        <h1 style={{ fontSize: '40px', textAlign: 'center', marginBottom: '3rem' }}>
          Creating your business
        </h1>
      </IonRow>
      <div style={{ display: 'flex', marginBottom: '2rem' }}>
        <div style={{ margin: '0 auto' }}>
          {steps.map((step, index) => {
            return (
              <IonRow
                key={index}
                style={{
                  margin: '1rem 0',
                  display: 'flex',
                  alignItems: 'center',
                  opacity: animationIndex >= index ? 1 : 0,
                  transition: 'opacity 1s ease',
                }}
              >
                <IonIcon color='primary' style={{ marginRight: '1rem' }} icon={checkmarkCircle} />
                <h6 style={{ margin: 0, fontSize: '1.2rem' }}>{step}</h6>
              </IonRow>
            )
          })}
        </div>
      </div>
      {!createdBusiness && (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <IonSpinner name='lines' />
        </div>
      )}
      {createdBusiness && (
        <>
          <IonRow
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: '2rem',
            }}
          >
            <h1>Your profile is ready</h1>
          </IonRow>
          <IonButton routerLink='/core/dashboard' color={'dark'} expand='full'>
            Let's Go
          </IonButton>
        </>
      )}
    </>
  )
}
