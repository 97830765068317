import React, { useState } from 'react'
import axios from 'axios'
import { IonButton, IonProgressBar, IonImg, IonContent, IonItem } from '@ionic/react'

export default function UploadImage({ postUrl, headerMetadata, successCallback, failureCallback }) {
  const [file, setFile] = useState()
  const [fileObject, setFileObject] = useState()
  const [saving, setSaving] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  function handleChange(e) {
    const selectedFile = e.target.files[0]
    if (selectedFile) {
      setFile(URL.createObjectURL(selectedFile))
      setFileObject(selectedFile)
    }
  }

  async function onSave() {
    setSaving(true)
    const formData = new FormData()
    formData.append('selectedFile', fileObject)

    try {
      const response = await axios({
        method: 'post',
        url: postUrl,
        data: formData,
        headers: { ...{ 'Content-Type': 'multipart/form-data' }, ...(headerMetadata ? headerMetadata : {}) },
      })
      if (response.status !== 200) throw Error()

      successCallback()
    } catch (error) {
      setErrorMessage('Sorry, there was an error. Please try again in a few minutes.')
      setSaving(false)
      failureCallback(error)
    }
  }

  return (
    <IonContent className='App'>
      <IonItem>
        <input type='file' accept='image/*' onChange={handleChange} />
      </IonItem>
      <br />
      {file && <IonImg src={file} style={{ borderRadius: '50%', maxWidth: 200 }} />}

      {file && !saving ? (
        <IonButton expand='block' onClick={onSave}>
          Save Picture
        </IonButton>
      ) : null}
      {saving ? (
        <>
          <LinearDeterminate /> saving...
        </>
      ) : null}
    </IonContent>
  )
}

function LinearDeterminate() {
  const [progress, setProgress] = React.useState(0)

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress > 95) return oldProgress

        let multiplier = 10
        if (oldProgress > 80) multiplier = 1
        if (oldProgress > 50) multiplier = 2

        const diff = Math.random() * multiplier
        return Math.min(oldProgress + diff, 100)
      })
    }, 500)

    return () => {
      clearInterval(timer)
    }
  }, [])

  return (
    <IonItem>
      <IonProgressBar value={progress / 100}></IonProgressBar>
    </IonItem>
  )
}
