import { IonInput, IonItem, IonLabel, IonGrid, IonRow, IonCol, IonModal, IonContent, IonIcon, IonSpinner } from '@ionic/react'
import { useState } from 'react'
import CommonHeader from '../commonComponents/CommonHeader'
import StandardContainer from '../commonComponents/StandardContainer'
import { colors } from '../theme/colors'
import { useHistory } from 'react-router'
import { chevronBack } from 'ionicons/icons'
import axios from 'axios'
export default function DetailsView({ loading, product, getProduct, showToastMessage, productTemp, subscriptionPackages }) {
  const history = useHistory()
  const [editSection, setEditSection] = useState(null) // Tracks which modal to show
  const [editedValues, setEditedValues] = useState({}) // Local state for modal edits
  const updateProduct = async (data) => {
    try {
      let response = await axios.patch(`/product?id=${productTemp?._id}`, data)
      await getProduct()
      showToastMessage('Product updated successfully!', 'success') // Success toast
    } catch (error) {
      showToastMessage('Failed to update product.', 'danger') // Error toast
    }
  }
  const handleSave = () => {
    console.log({ ...product, ...editedValues }, '{ ...product, ...editedValues }')
    updateProduct({ ...product, ...editedValues })
    setEditSection(null) // Close modal after saving
  }
  const isDataChanged = () => {
    return editedValues.name !== product.name || editedValues.price !== product.price || editedValues.description !== product.description
  }
  if (!product) return 'loading...'

  return (
    <>
      {/* Main Service Details */}
      <IonGrid style={{ padding: 0 }}>
        <IonGrid style={{ padding: '20px 20px 0 0' }}>
          <IonRow style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <IonCol>
              <IonIcon onClick={() => history.goBack()} icon={chevronBack} style={{ height: 30, width: 30 }} />
            </IonCol>
            <IonCol size='auto'>
              <IonLabel
                onClick={() => {
                  setEditedValues({
                    name: product.name,
                    price: product.price,
                    description: product.description,
                  })
                  setEditSection('mainEdit')
                }}
                style={{ cursor: 'pointer', fontSize: '18px', color: colors.primaryBlue }}
              >
                Edit
              </IonLabel>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonGrid>
      <IonGrid style={{ padding: '0 20px' }}>
        {loading && (
          <IonGrid style={{ padding: '0 20px' }}>
            <IonSpinner name='dots' />
          </IonGrid>
        )}
        <IonRow>
          <h2>{product?.name}</h2>
        </IonRow>
        <IonRow>
          <IonLabel style={{ color: colors.black70, fontWeight: '600' }}>{product?.description}</IonLabel>
        </IonRow>
        <IonRow>
          <h2>${parseFloat(product?.price)?.toFixed(2)}</h2>
        </IonRow>
      </IonGrid>

      {/* Modal for Main Edit */}
      <IonModal isOpen={editSection === 'mainEdit'} onDidDismiss={() => setEditSection(null)}>
        <IonContent>
          <CommonHeader
            backIcon={true}
            saveButton={true}
            handleSaveButton={handleSave}
            saveDisabled={!isDataChanged()}
            closeButton={true}
            closeModal={() => setEditSection(null)}
          />
          <StandardContainer>
            <IonGrid style={{ padding: 20 }}>
              <IonRow>
                <IonCol>
                  <IonItem>
                    <IonLabel position='stacked'>Name</IonLabel>
                    <IonInput
                      value={editedValues.name}
                      onIonInput={(e) => setEditedValues({ ...editedValues, name: e.detail.value })}
                      placeholder='Enter service name'
                    />
                  </IonItem>
                  <IonItem>
                    <IonLabel position='stacked'>Price</IonLabel>
                    <IonInput
                      value={editedValues.price}
                      onIonInput={(e) => setEditedValues({ ...editedValues, price: e.detail.value })}
                      type='number'
                      placeholder='Enter price'
                    />
                  </IonItem>

                  <IonItem>
                    <IonLabel position='stacked'>Description</IonLabel>
                    <IonInput
                      value={editedValues.description}
                      onIonInput={(e) => setEditedValues({ ...editedValues, description: e.detail.value })}
                      placeholder='Enter service description'
                    />
                  </IonItem>
                </IonCol>
              </IonRow>
            </IonGrid>
          </StandardContainer>
        </IonContent>
      </IonModal>
    </>
  )
}
