const GetExpiration = expirationTimestamp => {
  const currentTimestamp = Date.now() // current timestamp in milliseconds
  const timeDifference = expirationTimestamp - currentTimestamp

  // Convert milliseconds to seconds
  const secondsUntilExpiration = Math.ceil(timeDifference / 1000)

  let resultString = ''

  if (secondsUntilExpiration < 0) {
    // Time has already expired
    const expiredTimeAgo = Math.abs(secondsUntilExpiration)

    if (expiredTimeAgo < 60) {
      resultString = `Expired ${expiredTimeAgo} sec${expiredTimeAgo !== 1 ? 's' : ''} ago`
    } else if (expiredTimeAgo < 3600) {
      const minutesAgo = Math.floor(expiredTimeAgo / 60)
      resultString = `Expired ${minutesAgo} min${minutesAgo !== 1 ? 's' : ''} ago`
    } else if (expiredTimeAgo < 86400) {
      const hoursAgo = Math.floor(expiredTimeAgo / 3600)
      resultString = `Expired ${hoursAgo} hr${hoursAgo !== 1 ? 's' : ''} ago`
    } else if (expiredTimeAgo < 2678400) {
      const daysAgo = Math.floor(expiredTimeAgo / 86400)
      resultString = `Expired ${daysAgo} day${daysAgo !== 1 ? 's' : ''} ago`
    } else {
      const monthsAgo = Math.floor(expiredTimeAgo / 2678400)
      resultString = `Expired ${monthsAgo} month${monthsAgo !== 1 ? 's' : ''} ago`
    }
  } else if (secondsUntilExpiration < 60) {
    // Less than 1 minute
    resultString = `Expires in ${secondsUntilExpiration} sec${
      secondsUntilExpiration !== 1 ? 's' : ''
    }`
  } else if (secondsUntilExpiration < 3600) {
    // Less than 1 hour, display in minutes
    const minutesUntilExpiration = Math.ceil(secondsUntilExpiration / 60)
    resultString = `Expires in ${minutesUntilExpiration} min${
      minutesUntilExpiration !== 1 ? 's' : ''
    }`
  } else if (secondsUntilExpiration < 86400) {
    // Less than 24 hours, display in hours
    const hoursUntilExpiration = Math.ceil(secondsUntilExpiration / 3600)
    resultString = `Expires in ${hoursUntilExpiration} hr${hoursUntilExpiration !== 1 ? 's' : ''}`
  } else if (secondsUntilExpiration < 2678400) {
    // Less than 31 days, display in days
    const daysUntilExpiration = Math.ceil(secondsUntilExpiration / 86400)
    resultString = `Expires in ${daysUntilExpiration} day${daysUntilExpiration !== 1 ? 's' : ''}`
  } else {
    // More than 30 days, display in months
    const monthsUntilExpiration = Math.ceil(secondsUntilExpiration / 2678400)
    resultString = `Expires in ${monthsUntilExpiration} month${
      monthsUntilExpiration !== 1 ? 's' : ''
    }`
  }

  return resultString
}

export default GetExpiration
