import { IonRow, IonText } from '@ionic/react'
import React from 'react'

export default function ReviewSiteItem({ reviewSite, statusStyles }) {
  return (
    <>
      <IonRow
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '65%',
          marginBottom: '10px',
        }}
      >
        <h6 style={{ fontWeight: 'bold' }}>{reviewSite.name}</h6>
        <IonText style={{ fontSize: '1rem', color: 'grey' }}>{reviewSite.reviews}</IonText>
      </IonRow>
      <IonRow style={{ width: '35%', display: 'flex', justifyContent: 'center' }}>
        <div style={{ margin: '10px 0', padding: '5px 0' }}>
          <IonText style={statusStyles}>{reviewSite.status}</IonText>
        </div>
      </IonRow>
    </>
  )
}
