import { useState, useEffect, useContext, useRef } from 'react'
import ResultsList from './resultsList'
import axios from 'axios'
import { AuthContext } from '../auth'
import { IonCard, IonCol, IonContent, IonGrid, IonPage, IonRefresher, IonRefresherContent, IonRow, IonSpinner } from '@ionic/react'
import AddHeader from '../commonComponents/AddHeader'
import StandardContainer from '../commonComponents/StandardContainer'
import DefaultMenu from '../commonComponents/SideMenu/DefaultMenu'
import { useLocation } from 'react-router-dom'
import MiniHeader from '../commonComponents/MiniHeader'
import CheckIsManagerOrAdmin from '../Utils/CheckIsManagerOrAdmin'
import NoPermissionsCard from '../commonComponents/NoPermissionsCard'
import InfiniteScroll from 'react-infinite-scroll-component'
export default function CustomerListView() {
  const { businessData, selectedLocationData, userData } = useContext(AuthContext)
  const [customers, setCustomers] = useState([])
  const [newInLastWeek, setNewInLastWeek] = useState(null)
  const [errorMessage, setErrorMessage] = useState('')
  const [loading, setLoading] = useState(true)
  const [filteredList, setFilteredList] = useState([])
  const [searchValue, setSearchValue] = useState('')
  const [totalCustomersCount, setTotalCustomersCount] = useState(0)
  const windowLocation = useLocation()
  const [pageNumber, setPageNumber] = useState(1)
  const pageSize = 10

  let locationId = selectedLocationData?._id

  const userEntitlements = CheckIsManagerOrAdmin(userData, businessData, locationId)

  const getData = async () => {
    if (windowLocation.pathname === '/core/customers') {
      setPageNumber(1)
      await getCustomersList()
    }
  }

  useEffect(() => {
    if (businessData?._id && selectedLocationData?._id && userData?._id) {
      setPageNumber(1)
      getData()
    }
  }, [businessData, selectedLocationData?._id, windowLocation, userData._id])


  const handleSearchValue = async (value) => {
    setSearchValue(value)

    if (value !== '') {
      setLoading(true) // Set loading state to true when searching
      try {
        const searchResponse = await axios.get(`/customer_v2/search?searchText=${value}`)

        if (searchResponse?.data) {
          setFilteredList(searchResponse.data)
        }
      } catch (error) {
        console.error('Error searching customers:', error)
      } finally {
        setLoading(false) // Set loading state to false when search completes
      }
    } else {
      setPageNumber(1)
      setFilteredList([])
      await getCustomersList()
    }
  }
  const debounce = (func, wait) => {
    let timeout
    return (...args) => {
      clearTimeout(timeout)
      timeout = setTimeout(() => func(...args), wait)
    }
  }

  const debouncedHandleSearchValue = useRef(debounce((value) => handleSearchValue(value), 500)).current

  const getNextCustomersList = async () => {
    setLoading(true) // Set loading state to true when fetching next page
    try {
      let response = await axios.get(`/customer_v2/getList?businessId=${businessData._id}&locationId=${locationId}&page=${pageNumber}&pageSize=${pageSize}`)

      setCustomers([...customers, ...response?.data?.[0]?.data || []])
      setPageNumber((prevPageNumber) => prevPageNumber + 1)
      setTotalCustomersCount(response?.data?.[0]?.total)
    } catch (error) {
      console.error('Error fetching customers:', error)
      setErrorMessage('There was an error fetching customers.')
    } finally {
      setLoading(false) // Set loading state to false when fetch completes
    }
  }

  const getCustomersList = async () => {
    setLoading(true) // Set loading state to true when fetching initial data
    setErrorMessage(null)
    try {
      let response = await axios.get(`/customer_v2/getList?businessId=${businessData._id}&locationId=${locationId}&page=${1}&pageSize=${pageSize}`)
      setPageNumber(2)
      setCustomers(response?.data?.[0]?.data)
      setTotalCustomersCount(response?.data?.[0]?.total)
      setNewInLastWeek(response?.data?.[0]?.newCustomers)
    } catch (error) {
      console.error('Error fetching customers:', error)
      setErrorMessage('There was an error fetching customers.')
    } finally {
      setLoading(false) // Set loading state to false when fetch completes
    }
  }

  async function handleRefresh(event) {
    setCustomers([])
    setPageNumber(1)
    await getCustomersList()
    event.detail.complete()
  }
  if (userEntitlements?.noPermissions) {
    return (
      <>
        <DefaultMenu />
        <IonPage id='main-content'>
          <AddHeader title={'Customers'} />
          <IonContent>
            <StandardContainer>
              <NoPermissionsCard />
            </StandardContainer>
          </IonContent>
        </IonPage>
      </>
    )
  }

  return (
    <>
      <DefaultMenu />
      <IonPage id='main-content'>
        <AddHeader
          title={'Customers'}
          showExportButton={userEntitlements.isAdmin}
          loading={loading}
          searchValue={searchValue}
          handleSearchValue={debouncedHandleSearchValue}
        />
        <IonContent>
          <IonRefresher slot='fixed' onIonRefresh={handleRefresh}>
            <IonRefresherContent></IonRefresherContent>
          </IonRefresher>
          <StandardContainer>
            {errorMessage && <p style={{ color: 'red', marginLeft: 20, marginRight: 20 }}>{errorMessage}</p>}
            {searchValue != null && searchValue != '' ? null : (
              <IonGrid style={{ padding: 0, margin: 0 }}>
                <IonRow style={{ padding: 0, margin: 0 }}>
                  <IonCol style={{ padding: 0, margin: 0, marginRight: -8 }}>
                    <IonCard style={{ padding: 20 }} className='flat'>
                      <MiniHeader title={'All Customers'} />
                      <h1 style={{ marginTop: 10, marginBottom: 0 }}>{totalCustomersCount || 0}</h1>
                    </IonCard>
                  </IonCol>
                  <IonCol style={{ padding: 0, margin: 0, marginLeft: -8 }}>
                    <IonCard style={{ padding: 20 }} className='flat'>
                      <MiniHeader title={'New in last 7 days'} />
                      <h1 style={{ marginTop: 10, marginBottom: 0 }}>{newInLastWeek || "-"}</h1>
                    </IonCard>
                  </IonCol>
                </IonRow>
              </IonGrid>
            )}

            <div id='scrollableDiv' style={{ height: 600, overflow: 'auto' }}>
              <InfiniteScroll
                dataLength={searchValue != null && searchValue != '' ? filteredList.length : customers.length}
                next={getNextCustomersList}
                hasMore={searchValue != null && searchValue != '' ? false : totalCustomersCount > customers.length}
                loader={<IonSpinner name='dots' />}
                scrollThreshold={0.6}
                scrollableTarget='scrollableDiv'
              >
                <ResultsList
                  loading={loading}
                  searchValue={searchValue}
                  customers={searchValue != null && searchValue != '' ? filteredList : customers}
                  locationId={locationId}
                />
              </InfiniteScroll>
            </div>
          </StandardContainer>
        </IonContent>
      </IonPage>
    </>
  )
}
