import { IonIcon } from '@ionic/react'
import { star, starHalf, starOutline } from 'ionicons/icons'
import React from 'react'

export default function StarRating({ color, rating }) {
  return (
    <>
      {[...Array(Math.floor(rating))].map((_, i) => (
        <IonIcon key={i} style={{ color: color ? color : 'gold' }} icon={star} />
      ))}
      {rating % 1 !== 0 && <IonIcon style={{ color: 'gold' }} icon={starHalf} />}
      {[...Array(5 - Math.ceil(rating))].map((_, i) => (
        <IonIcon key={i + Math.ceil(rating)} style={{ color: 'grey' }} icon={starOutline} />
      ))}
    </>
  )
}
