// FooterActionsStyles.js

export const containerStyle = {
  width: '100%',
  height: '100%',
  background: 'white',
  borderTop: '1px #EBEBEB solid',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  gap: 8,
  display: 'inline-flex',
  paddingTop: 10,
}

export const totalPaidContainerStyle = {
  width: '100%',
  height: '100%',
  paddingTop: 8,
  paddingBottom: 8,
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  gap: 12,
  display: 'inline-flex',
}

export const totalPaidTextStyle = {
  color: '#222222',
  fontSize: 18,
  fontFamily: 'Figtree',
  fontWeight: '500',
  lineHeight: '28px',
  wordWrap: 'break-word',
}

export const totalPaidAmountStyle = {
  marginLeft: 5,
  color: '#222222',
  fontSize: 18,
  fontFamily: 'Figtree',
  fontWeight: '700',
  lineHeight: '28px',
  wordWrap: 'break-word',
}

export const addCashTipStyle = {
  textAlign: 'right',
  color: '#1570EF',
  fontSize: 18,
  fontFamily: 'Figtree',
  fontWeight: '600',
  textDecoration: 'underline',
  lineHeight: '28px',
  wordWrap: 'break-word',
}

export const noShowFeeContainerStyle = {
  height: 44,
  paddingTop: 8,
  paddingBottom: 8,
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  gap: 12,
  display: 'flex',
}

export const noShowFeeTextStyle = {
  color: '#222222',
  fontSize: 18,
  fontFamily: 'Figtree',
  fontWeight: '500',
  lineHeight: '28px',
  wordWrap: 'break-word',
}

export const noShowFeeAmountStyle = {
  marginLeft: 10,
  color: '#222222',
  fontSize: 18,
  fontFamily: 'Figtree',
  fontWeight: '700',
  lineHeight: '28px',
  wordWrap: 'break-word',
}

export const moreButtonStyle = {
  flex: '1 1 0',
  padding: 8,
  background: '#EBEBEB',
  borderRadius: 12,
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 8,
  display: 'inline-flex',
}

export const moreButtonTextStyle = {
  color: '#525252',
  fontSize: 16,
  fontFamily: 'Figtree',
  fontWeight: '600',
  lineHeight: '24px',
  wordWrap: 'break-word',
}
