import { IonContent, IonPage } from '@ionic/react'
import AppHeader from '../../app/header'
import StandardContainer from '../../commonComponents/StandardContainer'
import Flow from './flow'

const BusinessInviteFlow = () => {
  return (
    <IonPage id='main-content'>
      <AppHeader />
      <IonContent className='regularBackground'>
        <StandardContainer>
          <Flow
            data={[
              {
                title: 'Invite Code',
                stepName: 'code',
                backButtonText: 'Back',
                nextButtonText: 'Personal Information',
              },
              {
                title: 'Your Information',
                stepName: 'personal',
                backButtonText: 'Back',
                nextButtonText: 'Finish',
              },

              {
                title: 'You are all set!',
                stepName: '',
                backButtonText: 'Back',
                nextButtonText: 'Go to my dashboard',
                nextButtonRedirect: '/core/dashboard',
              },
            ]}
          />
        </StandardContainer>
      </IonContent>
    </IonPage>
  )
}

export default BusinessInviteFlow
