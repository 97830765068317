import { IonContent, IonIcon, IonLabel, IonList, IonPage, IonRow, IonTextarea, IonToast } from '@ionic/react'
import { useEffect, useState } from 'react'
import CommonHeader from '../commonComponents/CommonHeader'
import StandardContainer from '../commonComponents/StandardContainer'
import axios from 'axios'
import { add, checkmark, pencil } from 'ionicons/icons'
import { GetOrdinalText } from '../Utils/GetOrdinalText'
import ListView from './ListView'
import ChooseAudience from './ChooseAudience'
import { useHistory } from 'react-router'
import { colors } from '../theme/colors'
import CheckEmailRemarketingRuleExists from '../Utils/CheckEmailRemarketingRuleExists'

export default function AddMarketingRule() {
  const [loading, setLoading] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const [ruleName, setRuleName] = useState('All Customers Rule')
  const [selectedAudience, setSelectedAudience] = useState(['ALL_CUSTOMERS'])
  const [smtpSettingsId, setSmtpSettingsId] = useState('6579631525a650b2cd0f6c53')
  const [errorMessage, setErrorMessage] = useState('')
  const [toast, setToast] = useState({ isOpen: false, message: '', color: '' })
  const history = useHistory()
  const [emailSteps, setEmailSteps] = useState([
    {
      id: 1,
      name: 'first email',
      followUpDays: 30,
    },
  ])

  // Centralized function to handle toast notifications
  const showToastMessage = (message, color) => {
    setToast({ isOpen: true, message, color })
  }

  const addEmailToRule = () => {
    const emailNumber = emailSteps.length + 1

    const emailName = GetOrdinalText(emailNumber) + ' email'
    const newEmailObject = {
      id: emailNumber,
      name: emailName,
      followUpDays: 0,
    }
    const newRules = [...emailSteps, newEmailObject]

    setEmailSteps(newRules)

    //
  }
  const handleEditClick = () => {
    setEditMode(true)
  }
  const handleRuleNameChange = async (ruleNameValue) => {
    setRuleName(ruleNameValue)
    try {
      const ruleExistsResponse = await CheckEmailRemarketingRuleExists(ruleNameValue)
      //if emailValidationMessage then seterror message as email already exists
      const validationMessage = ruleExistsResponse.ruleExists ? 'Rule Name Already In Use' : ''
      setErrorMessage(validationMessage)
    } catch (error) {
      showToastMessage('Error checking rule name', 'danger')
      console.error('Error checking rule name:', error)
    }
  }

  const handleSaveRuleName = () => {
    setEditMode(false)
  }

  const handleSaveRule = async () => {
    if (errorMessage) {
      showToastMessage(errorMessage, 'danger')
      return
    }

    setLoading(true)
    const remarketingInput = {
      smtpSettingsId: smtpSettingsId,
      ruleName: ruleName,
      audiences: selectedAudience,
      emailSteps: emailSteps,
    }

    try {
      const updateRemarketingResponse = await axios.post(`/emailRemarketing/rule`, remarketingInput)
      if (updateRemarketingResponse.status === 200) {
        showToastMessage('Marketing rule saved successfully!', 'success')
        history.goBack()
      } else {
        showToastMessage('Failed to save marketing rule', 'danger')
      }
    } catch (error) {
      showToastMessage('Error saving marketing rule', 'danger')
      console.error('Error saving marketing rule:', error)
    } finally {
      setLoading(false)
    }
  }

  const updateAudienceData = (audiences) => {
    setSelectedAudience(audiences)
  }

  const refreshEmailSteps = (steps) => {
    setEmailSteps(steps)
  }

  return (
    <IonPage id='main-content'>
      <CommonHeader title={'Rule'} loading={loading} saveButton={true} saveDisabled={!!errorMessage} handleSaveButton={handleSaveRule} backIcon={true} />
      <IonContent>
        <StandardContainer>
          <div style={{ padding: '0 1rem 1rem 1rem' }}>
            <>
              {editMode ? (
                <>
                  <div>
                    <IonRow style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <IonTextarea
                        style={{ fontSize: '1.2rem', lineHeight: '1.5rem', fontWeight: 'bold' }}
                        value={ruleName}
                        autoGrow={!errorMessage}
                        onIonChange={(e) => handleRuleNameChange(e.target.value)}
                      ></IonTextarea>

                      {!errorMessage && <IonIcon onClick={handleSaveRuleName} icon={checkmark} color='primary' style={{ fontSize: '2rem' }} />}
                    </IonRow>
                  </div>
                </>
              ) : (
                <>
                  <h2 style={{ fontWeight: 'bold' }}>
                    {ruleName}
                    <IonIcon color='primary' icon={pencil} onClick={handleEditClick} style={{ cursor: 'pointer', marginLeft: '1rem' }} />
                  </h2>
                </>
              )}
              {errorMessage && (
                <div>
                  <IonLabel style={{ color: colors.errorText, fontSize: '1rem' }}>{errorMessage}</IonLabel>
                </div>
              )}
              <IonList inset={true} style={{ margin: '1rem 0' }} mode='ios'>
                <ChooseAudience
                  updateAudienceData={updateAudienceData}
                  mode='add'
                  smtpSettingsId={smtpSettingsId}
                  ruleName={ruleName}
                  audiencesList={selectedAudience}
                />
              </IonList>

              <ListView emailSteps={emailSteps} refreshEmailSteps={refreshEmailSteps} mode={'add'} />
              <IonRow style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={addEmailToRule}>
                <IonIcon size='small' color='primary' icon={add} />
                <IonLabel color='primary'>Add an Email</IonLabel>
              </IonRow>
            </>
          </div>
        </StandardContainer>
      </IonContent>

      <IonToast
        position='top'
        isOpen={toast.isOpen}
        message={toast.message}
        duration={2000}
        color={toast.color}
        onDidDismiss={() => setToast({ isOpen: false, message: '', color: '' })}
      />
    </IonPage>
  )
}
