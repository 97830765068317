import React from 'react'
import AppointmentDateTime from '../../appointments/NewCalendarView/SubComponents/AppointmentDateTime'

// Styles
const containerStyle = {
  width: '100%',
  paddingTop: 16,
  paddingBottom: 16,
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  gap: 8,
  display: 'flex',
}

const headerStyle = {
  alignSelf: 'stretch',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  display: 'flex',
}

const headerTextContainerStyle = {
  alignSelf: 'stretch',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: 12,
  display: 'inline-flex',
}

const headerTextStyle = {
  flex: '1 1 0',
  color: '#525252',
  fontSize: 14,
  fontFamily: 'Figtree',
  fontWeight: '600',
  lineHeight: '20px',
  letterSpacing: 1.4,
  wordWrap: 'break-word',
}

export default function DateTimeSummary({ setStep, appointment }) {
  let updatedAppointmentData = { ...appointment, ...appointment?.dateTime }

  return (
    <div style={containerStyle}>
      <div style={headerStyle}>
        <div style={headerTextContainerStyle}>
          <div style={headerTextStyle}>DATE</div>
        </div>
      </div>
      <AppointmentDateTime setStep={setStep} mode={'create'} hideBorder={true} appointment={updatedAppointmentData} />
    </div>
  )
}
