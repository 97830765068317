import { IonCol, IonContent, IonItem, IonLabel, IonList, IonPage, IonSpinner, IonToggle } from '@ionic/react'
import { useContext, useEffect, useState } from 'react'
import CommonHeader from '../commonComponents/CommonHeader'
import StandardContainer from '../commonComponents/StandardContainer'
import { AuthContext } from '../auth'
import { useHistory, useLocation } from 'react-router'
import ConfigureEmailSettings from '../ConfigureEmailSettings'
import axios from 'axios'
import UploadLogoBusinessImage from './UploadLogoBusinessImage'
import RulesList from './RulesList'

export default function EmailRemarketingSettings() {
  const { businessData, locationId } = useContext(AuthContext)
  const [enableRemarketing, setEnableRemarketing] = useState(false)
  const [loadingRemarketingToggle, setLoadingRemarketingToggle] = useState(false)
  const [remarketingSettings, setRemarketingSettings] = useState(false)
  const [rulesList, setRulesList] = useState(false)
  const [loading, setLoading] = useState(true)
  const [smtpSettings, setSmtpSettings] = useState(false)
  const history = useHistory()
  const location = useLocation()

  const handleAddRuleClick = () => {
    history.push('/core/addEmailRemarketingRule') // Navigates to addRulePage when clicked
  }

  const toggleEnableRemarketing = async (value) => {
    setLoadingRemarketingToggle(true)
    try {
      let method = 'POST'
      let url = '/emailRemarketing/settings'

      if (remarketingSettings) {
        method = 'PATCH'
        // Adjust the URL for PATCH method based on the specific ID
        url = `/emailRemarketing/settings?id=${remarketingSettings._id}`
      }
      if (value !== enableRemarketing) {
        let settings = {
          remarketingEnabled: value,
        }
        // Perform the API call using the determined method and URL
        const updateRemarketingResponse = await axios({
          method, // Use dynamic method here
          url,
          data: settings,
        })

        if (updateRemarketingResponse.status == 200) {
          setEnableRemarketing(value)
          getRemarketingSettings()
          setLoadingRemarketingToggle(false)
          // Handle success
        } else {
          // Handle API error
          console.error('Failed to update smtp settings')
        }
      }
    } catch (error) {
      console.error('Error toggling:', error)
      // Handle other errors or exceptions here
    }
  }
  const getRulesAndSmtpSettings = async () => {
    try {
      const rulesListResponse = await axios.get('/emailRemarketing/rule/list')

      if (rulesListResponse.status === 200) {
        setRulesList(rulesListResponse?.data?.list)
        setSmtpSettings(rulesListResponse?.data?.smtpSettings)
      }
    } catch (error) {
      setRulesList(false)
    }
  }
  const getRemarketingSettings = async () => {
    try {
      const remarketingSettingsResponse = await axios.get('/emailRemarketing/settings/check')
      if (remarketingSettingsResponse.status === 200) {
        setLoading(false)
        setRemarketingSettings(remarketingSettingsResponse?.data)
        setEnableRemarketing(remarketingSettingsResponse?.data?.remarketingEnabled)
      }
    } catch (error) {}
  }
  const getRulesAndSettings = () => {
    setLoading(true)
    // if (businessData && businessData !== 'loading' && locationId && locationId !== 'loading') {
    //   if (location.pathname == '/core/emailRemarketing') {
    getRulesAndSmtpSettings()
    getRemarketingSettings()
    //   }
    // }
  }
  useEffect(() => {
    const unlisten = history.block((location) => {
      // if (location.pathname == '/core/emailRemarketing') {
      getRulesAndSettings()
      // }
      return true // Allow the navigation
    })
    return () => {
      unlisten() // Clean up the listener when the component unmounts
    }
  }, [history])

  useEffect(() => {
    getRulesAndSettings()
  }, [businessData, locationId])
  useEffect(() => {
    getRulesAndSettings()
  }, [])

  return (
    <StandardContainer>
      {/* standard Container */}
      <div style={{ padding: '0 1rem 1rem 1rem' }}>
        {loading && (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <IonSpinner name='dots' />
          </div>
        )}
        {!loading && (
          <>
            <h2 style={{ fontWeight: 'bold' }}>Email Remarketing Settings</h2>
            <IonList inset={true} style={{ margin: '1rem 0' }} mode='ios'>
              <IonItem>
                <IonCol size='9'>
                  <IonLabel>Enable Email Remarketing</IonLabel>
                </IonCol>
                <IonCol size='3' style={{ display: 'flex', justifyContent: 'end' }}>
                  {loadingRemarketingToggle && <IonSpinner name='lines' />}
                  {!loadingRemarketingToggle && (
                    <IonToggle
                      justify='space-between'
                      size='small'
                      checked={enableRemarketing}
                      onIonChange={(e) => {
                        toggleEnableRemarketing(e.detail.checked)
                      }}
                    />
                  )}
                </IonCol>
              </IonItem>
            </IonList>

            <ConfigureEmailSettings smtpSettings={smtpSettings} />
            <UploadLogoBusinessImage />
            <h4 style={{ fontWeight: 'bold' }}>Rules</h4>
            <IonLabel style={{ color: 'grey', lineHeight: '1.5rem' }}>Rules that define when and whom a reminder email should be sent</IonLabel>
            <RulesList loading={loading} rulesList={rulesList} handleAddRuleClick={handleAddRuleClick} />
          </>
        )}
      </div>
    </StandardContainer>
  )
}
