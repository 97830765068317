import { colors } from '../../theme/colors'

const modifiedScheduleStyles = {
  container: {
    padding: '20px',
  },
  gridContainer: {
    marginBottom: '80px',
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'center',
  },
  centerRow: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '10px',
  },
  card: {
    padding: '10px',
    border: '1px solid #e0e0e0', // You can replace this with colors.black30 if that's defined
    borderRadius: '10px',
  },
  label: {
    fontWeight: 'bold',
  },
  toggleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  toggleIcon: {
    cursor: 'pointer',
    position: 'absolute',
    right: '11px',
    zIndex: '10',
    marginLeft: '8px',
    color: colors.primaryBlue, // Replace with colors.primaryBlue
  },
  timeCard: {
    marginTop: '30px',
    padding: '10px',
    border: `1px solid ${colors.black30}`, // Replace with colors.black30
    borderBottom: 'none',
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '10px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  buttonContainer: {
    zIndex: '10',
    position: 'fixed',
    bottom: '0',
    left: '0',
    width: '100%',
    background: 'white',
    padding: '10px 20px 40px 20px',
    boxShadow: '0 -2px 8px rgba(0, 0, 0, 0.1)',
    display: 'flex',
    justifyContent: 'space-between',
  },
  cancelButton: {
    fontSize: '16px',
    fontWeight: '600',
    color: 'dark',
  },
  saveButton: {
    color: 'white',
    fontWeight: '600',
    justifyContent: 'center',
    alignItems: 'end',
    flexDirection: 'column',
    display: 'flex',
  },
  confirmationModalContent: {
    textAlign: 'center',
  },
  confirmationTitle: {
    marginBottom: '40px',
  },
  confirmationGrid: {
    fontWeight: '600',
  },
  confirmationRow: {
    marginTop: '10px',
    alignItems: 'center',
  },
  confirmationIcon: {
    display: 'inline-block',
    width: '12px',
    height: '12px',
    borderRadius: '50%',
    marginRight: '8px',
  },
  confirmationButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '30px',
  },
  confirmationButton: {
    margin: '10px',
  },
  startTimeStyle: {
    marginTop: 30,
    padding: 10,
    border: `1px solid ${colors.black30}`,
    borderBottom: 'none',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  endTimeStyle: {
    padding: 10,
    border: `1px solid ${colors.black30}`,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  saveButtonStyle: {
    color: 'white',
    fontWeight: '600',
    justifyContent: 'center',
    alignItems: 'end',
    flexDirection: 'column',
    display: 'flex',
  },
  actionButtonsContainer: {
    zIndex: 10,
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
    background: 'white',
    padding: '10px 20px 40px 20px',
    boxShadow: '0 -2px 8px rgba(0, 0, 0, 0.1)',
    display: 'flex',
    justifyContent: 'space-between',
  },
}

export default modifiedScheduleStyles
