import { IonCheckbox, IonContent, IonInput, IonItem, IonLabel, IonList, IonModal, IonRow, IonToggle, IonToast, IonCard, IonGrid } from '@ionic/react'
import { useContext, useEffect, useState } from 'react'
import CommonHeader from '../commonComponents/CommonHeader'
import StandardContainer from '../commonComponents/StandardContainer'
import { AuthContext } from '../auth'
import axios from 'axios'
import GetLocationDataBasedOnLocationId from '../Utils/GetLocationDataBasedOnLocationId'
import { fontSizes, fontWeights } from '../theme/typography'

export default function ChargeCustomer({ type, title }) {
  const chargesType = type === 'cancel' ? 'cancellationSettings' : 'noShowSettings'

  const { businessData, locationId, locationData, getBusinessData } = useContext(AuthContext)

  // Non-Subscriber States
  const [chargeNonSubscriber, setChargeNonSubscriber] = useState(false)
  const [chargeNonSubscriberType, setChargeNonSubscriberType] = useState('percentage')
  const [chargeNonSubscriberValue, setChargeNonSubscriberValue] = useState('')
  const [numberOfHoursNonSubscriber, setNumberOfHoursNonSubscriber] = useState('')

  // Subscriber States
  const [chargeSubscriber, setChargeSubscriber] = useState(false)
  const [chargeSubscriberType, setChargeSubscriberType] = useState('percentage')
  const [chargeSubscriberValue, setChargeSubscriberValue] = useState('')
  const [numberOfHoursSubscriber, setNumberOfHoursSubscriber] = useState('')

  const [error, setError] = useState('')
  const [modalOpen, setModalOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [chargesChanged, setChargesChanged] = useState(false)
  const [toast, setToast] = useState({ isOpen: false, message: '', color: '' })

  const selectedLocationData = GetLocationDataBasedOnLocationId(locationData, locationId)
  const appointmentSettings = selectedLocationData?.appointmentSettings?.[chargesType]

  const showToastMessage = (message, color = 'success') => {
    setToast({ isOpen: true, message, color })
  }

  const handleChargeType = (value, isSubscriber) => {
    setChargesChanged(true)
    if (isSubscriber) {
      setChargeSubscriberType(value)
    } else {
      setChargeNonSubscriberType(value)
    }
  }

  const handleChargeValue = (value, isSubscriber) => {
    setChargesChanged(true)

    // Validate if the value is a positive number
    if (!/^\d*\.?\d*$/.test(value) || parseFloat(value) < 0) {
      setError('Please enter a valid positive number')
      if (isSubscriber) {
        setChargeSubscriberValue(value)
      } else {
        setChargeNonSubscriberValue(value)
      }
      return
    }

    // Check if the charge type is percentage and value exceeds 100
    if ((isSubscriber ? chargeSubscriberType : chargeNonSubscriberType) === 'percentage' && parseFloat(value) > 100) {
      setError('Percentage charge value cannot be more than 100')
      if (isSubscriber) {
        setChargeSubscriberValue(value)
      } else {
        setChargeNonSubscriberValue(value)
      }
      return
    }

    // Clear any existing error message
    setError('')
    // Update the charge value state
    if (isSubscriber) {
      setChargeSubscriberValue(value)
    } else {
      setChargeNonSubscriberValue(value)
    }
  }

  const handleNumberOfHours = (value, isSubscriber) => {
    setChargesChanged(true)
    if (isSubscriber) {
      setNumberOfHoursSubscriber(value)
    } else {
      setNumberOfHoursNonSubscriber(value)
    }
  }

  const openModal = () => {
    setModalOpen(true)
  }

  const closeModal = () => {
    setModalOpen(false)
    setChargeSubscriberType('percentage')
    setChargeSubscriberValue('')
    setNumberOfHoursSubscriber('')
    setChargeNonSubscriberType('percentage')
    setChargeNonSubscriberValue('')
    setNumberOfHoursNonSubscriber('')
    setError('')
    setLoading(false)
  }

  const toggleChargeCustomer = (value, isSubscriber) => {
    setChargesChanged(true)
    if (isSubscriber) {
      setChargeSubscriber(value)
    } else {
      setChargeNonSubscriber(value)
    }
  }

  const handleSave = async () => {
    setLoading(true)
    try {
      const input = {
        appointmentSettings: {
          ...selectedLocationData?.appointmentSettings,
          [chargesType]: {
            subscriber: {
              charge: chargeSubscriber,
              type: chargeSubscriberType,
              value: parseFloat(chargeSubscriberValue),
              hoursBefore: parseInt(numberOfHoursSubscriber, 10), // Saving number of hours
            },
            nonSubscriber: {
              charge: chargeNonSubscriber,
              type: chargeNonSubscriberType,
              value: parseFloat(chargeNonSubscriberValue),
              hoursBefore: parseInt(numberOfHoursNonSubscriber, 10), // Saving number of hours
            },
          },
        },
      }

      const response = await axios.patch(`/location_v2/appointmentSettings`, input)
      if (response.status === 200) {
        setLoading(false)
        getBusinessData()
        closeModal()
        showToastMessage('Charges updated successfully.')
      }
    } catch (error) {
      setLoading(false)
      showToastMessage('Error saving charges. Please try again later.', 'danger')
    }
  }

  useEffect(() => {
    setChargeNonSubscriberType(appointmentSettings?.nonSubscriber?.type || 'percentage')
    setChargeNonSubscriberValue(appointmentSettings?.nonSubscriber?.value || '')
    setChargeNonSubscriber(appointmentSettings?.nonSubscriber?.charge || false)
    setNumberOfHoursNonSubscriber(appointmentSettings?.nonSubscriber?.hoursBefore || '')

    setChargeSubscriberType(appointmentSettings?.subscriber?.type || 'percentage')
    setChargeSubscriberValue(appointmentSettings?.subscriber?.value || '')
    setChargeSubscriber(appointmentSettings?.subscriber?.charge || false)
    setNumberOfHoursSubscriber(appointmentSettings?.subscriber?.hoursBefore || '')
  }, [modalOpen])

  return (
    <>
      <IonList inset={true} mode='ios'>
        <IonItem style={{ cursor: 'pointer' }} onClick={openModal} detail={true}>
          <IonGrid>
            <IonRow style={{ margin: '5px 0' }}>
              <IonLabel>{title}</IonLabel>
            </IonRow>
            <IonRow style={{ margin: '5px 0' }}>
              <IonLabel style={{ fontSize: '12px', color: 'grey' }}>
                {appointmentSettings?.nonSubscriber?.charge
                  ? `(Non-Subscriber: ${appointmentSettings?.nonSubscriber?.type} - ${appointmentSettings?.nonSubscriber?.type === 'fixed' ? '$' : ''}${
                      appointmentSettings?.nonSubscriber?.value
                    }${appointmentSettings?.nonSubscriber?.type === 'percentage' ? '%' : ''})`
                  : 'Non-Subscriber: Disabled'}
              </IonLabel>
            </IonRow>
            <IonRow style={{ margin: '5px 0' }}>
              <IonLabel style={{ fontSize: '12px', color: 'grey', marginTop: '0.3rem' }}>
                {appointmentSettings?.subscriber?.charge
                  ? `(Subscriber: ${appointmentSettings?.subscriber?.type} - ${appointmentSettings?.subscriber?.type === 'fixed' ? '$' : ''}${
                      appointmentSettings?.subscriber?.value
                    }${appointmentSettings?.subscriber?.type === 'percentage' ? '%' : ''})`
                  : 'Subscriber: Disabled'}
              </IonLabel>
            </IonRow>
            <IonRow>
              <IonLabel style={{ fontSize: '12px', marginTop: 10 }}>Hours Before</IonLabel>
            </IonRow>
            <IonRow style={{ margin: '5px 0' }}>
              <IonLabel style={{ fontSize: '12px', color: 'grey' }}>
                {appointmentSettings?.nonSubscriber?.charge
                  ? `(Non-Subscriber: ${appointmentSettings?.nonSubscriber?.hoursBefore || ''} )`
                  : 'Non-Subscriber: Disabled'}
              </IonLabel>
            </IonRow>
            <IonRow style={{ margin: '5px 0' }}>
              <IonLabel style={{ fontSize: '12px', color: 'grey', marginTop: '0.3rem' }}>
                {appointmentSettings?.subscriber?.charge ? `(Subscriber: ${appointmentSettings?.subscriber?.hoursBefore || ''} )` : 'Subscriber: Disabled'}
              </IonLabel>
            </IonRow>
          </IonGrid>
        </IonItem>
      </IonList>

      {/* Similar UI for displaying charges as in your original code */}
      <IonModal isOpen={modalOpen} onDidDismiss={closeModal}>
        <CommonHeader
          title={title}
          closeButton={true}
          saveButton={true}
          loading={loading}
          handleSaveButton={handleSave}
          saveDisabled={
            !chargesChanged ||
            error !== '' ||
            (chargeSubscriber && (!chargeSubscriberValue || (type === 'cancel' && !numberOfHoursSubscriber))) ||
            (chargeNonSubscriber && (!chargeNonSubscriberValue || (type === 'cancel' && !numberOfHoursNonSubscriber)))
          }
          closeModal={closeModal}
        />

        <IonContent className='regularBackground'>
          <StandardContainer>
            {/* Non Subscriber Section */}
            <IonCard>
              <div style={{ marginTop: 10 }}>
                <IonLabel style={{ padding: 20, fontSize: fontSizes.size18, fontWeight: fontWeights.weightBold }}>Non Subscriber</IonLabel>
              </div>
              <IonList inset={true} mode='ios'>
                <IonItem>
                  <IonLabel>Charge</IonLabel>
                  <IonToggle checked={chargeNonSubscriber} onIonChange={(e) => toggleChargeCustomer(e.detail.checked, false)} />
                </IonItem>

                {chargeNonSubscriber && (
                  <>
                    {/* Type selection */}
                    <IonList>
                      <IonItem onClick={() => handleChargeType(chargeNonSubscriberType === 'percentage' ? 'fixed' : 'percentage', false)}>
                        <IonCheckbox
                          slot='start'
                          checked={chargeNonSubscriberType === 'percentage'}
                          onIonChange={() => handleChargeType('percentage', false)}
                        />
                        <IonLabel>Percentage Charge</IonLabel>
                      </IonItem>
                      <IonItem onClick={() => handleChargeType(chargeNonSubscriberType === 'fixed' ? 'percentage' : 'fixed', false)}>
                        <IonCheckbox slot='start' checked={chargeNonSubscriberType === 'fixed'} onIonChange={() => handleChargeType('fixed', false)} />
                        <IonLabel>Fixed Price Charge</IonLabel>
                      </IonItem>
                    </IonList>

                    {/* Charge Value */}
                    <IonItem>
                      <IonLabel position='stacked'>{chargeNonSubscriberType === 'percentage' ? 'Percentage' : 'Charge Amount'}</IonLabel>
                      <IonInput
                        type='number'
                        value={chargeNonSubscriberValue}
                        onIonInput={(e) => handleChargeValue(e.detail.value, false)}
                        placeholder={chargeNonSubscriberType === 'percentage' ? '0%' : '$0.00'}
                      />
                    </IonItem>
                    <IonItem>
                      <IonLabel position='stacked'>Number of Hours</IonLabel>
                      <IonInput
                        type='number'
                        value={numberOfHoursNonSubscriber}
                        onIonInput={(e) => handleNumberOfHours(e.detail.value, false)}
                        placeholder='Enter number of hours'
                      />
                    </IonItem>
                  </>
                )}
              </IonList>
            </IonCard>

            {/* Subscriber Section */}
            <IonCard>
              <div style={{ marginTop: 10 }}>
                <IonLabel style={{ padding: 20, fontSize: fontSizes.size18, fontWeight: fontWeights.weightBold }}>Subscriber</IonLabel>
              </div>
              <IonList inset={true} mode='ios'>
                <IonItem>
                  <IonLabel>Charge</IonLabel>
                  <IonToggle checked={chargeSubscriber} onIonChange={(e) => toggleChargeCustomer(e.detail.checked, true)} />
                </IonItem>

                {chargeSubscriber && (
                  <>
                    {/* Type selection */}
                    <IonList>
                      <IonItem onClick={() => handleChargeType(chargeSubscriberType === 'percentage' ? 'fixed' : 'percentage', true)}>
                        <IonCheckbox slot='start' checked={chargeSubscriberType === 'percentage'} onIonChange={() => handleChargeType('percentage', true)} />
                        <IonLabel>Percentage Charge</IonLabel>
                      </IonItem>
                      <IonItem onClick={() => handleChargeType(chargeSubscriberType === 'fixed' ? 'percentage' : 'fixed', true)}>
                        <IonCheckbox slot='start' checked={chargeSubscriberType === 'fixed'} onIonChange={() => handleChargeType('fixed', true)} />
                        <IonLabel>Fixed Price Charge</IonLabel>
                      </IonItem>
                    </IonList>

                    <IonItem>
                      <IonLabel position='stacked'>{chargeSubscriberType === 'percentage' ? 'Percentage' : 'Charge Amount'}</IonLabel>
                      <IonInput
                        type='number'
                        value={chargeSubscriberValue}
                        onIonInput={(e) => handleChargeValue(e.detail.value, true)}
                        placeholder={chargeSubscriberType === 'percentage' ? '0%' : '$0.00'}
                      />
                    </IonItem>
                    <IonItem>
                      <IonLabel position='stacked'>Number of Hours</IonLabel>
                      <IonInput
                        type='number'
                        value={numberOfHoursSubscriber}
                        onIonInput={(e) => handleNumberOfHours(e.detail.value, true)}
                        placeholder='Enter number of hours'
                      />
                    </IonItem>
                  </>
                )}
              </IonList>
            </IonCard>

            {error && <div style={{ color: 'red', marginLeft: 16 }}>{error}</div>}
          </StandardContainer>
        </IonContent>

        <IonToast isOpen={toast.isOpen} message={toast.message} duration={2000} color={toast.color} />
      </IonModal>

      <IonToast
        position='top'
        isOpen={toast.isOpen}
        onDidDismiss={() => setToast({ ...toast, isOpen: false })}
        message={toast.message}
        color={toast.color}
        duration={2000}
      />
    </>
  )
}
