import { IonIcon, IonItem, IonLabel, IonList } from '@ionic/react'
import { personAddOutline } from 'ionicons/icons'
import React from 'react'

export default function AddCustomerOrEmployee({
  openCustomersModal,
  selectedCustomer,
  openEmployeesModal,
  selectedEmployee,
}) {
  return (
    <>
      <IonList mode='ios' inset={true}>
        <IonItem style={{ cursor: 'pointer' }} detail={true} onClick={openCustomersModal}>
          <IonLabel style={{ display: 'flex', alignItems: 'center' }}>
            <IonIcon style={{ marginRight: '1rem' }} icon={personAddOutline} />
            Add Customer
          </IonLabel>
          {selectedCustomer && (
            <IonLabel
              style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}
            >{`${selectedCustomer.firstName} ${selectedCustomer.lastName}`}</IonLabel>
          )}
        </IonItem>
      </IonList>
      <IonList mode='ios' inset={true}>
        <IonItem style={{ cursor: 'pointer' }} detail={true} onClick={openEmployeesModal}>
          <IonLabel style={{ display: 'flex', alignItems: 'center' }}>
            <IonIcon style={{ marginRight: '1rem' }} icon={personAddOutline} />
            Add Employee
          </IonLabel>
          {selectedEmployee && (
            <IonLabel
              style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}
            >{`${selectedEmployee.firstName} ${selectedEmployee.lastName}`}</IonLabel>
          )}
        </IonItem>
      </IonList>
    </>
  )
}
