import React from 'react'
import { reviewOrderStyles } from '../Styles/ReviewOrderStyles'
import ProfessionalInfo from '../../appointments/NewCalendarView/SubComponents/ProfessionalInfo'

export default function BarberSummary({ setStep, appointment, businessData }) {
  return (
    <div style={reviewOrderStyles.professionalcardContainerStyle}>
      <div style={reviewOrderStyles.professionalheaderContainerStyle}>
        <div style={reviewOrderStyles.professionaltextContainerStyle}>
          <div style={reviewOrderStyles.professionalheaderTextStyle}>Stylist</div>
        </div>
      </div>
      <ProfessionalInfo setStep={setStep} mode={'create'} appointment={appointment} businessData={businessData} />
    </div>
  )
}
