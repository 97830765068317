import { IonButton, IonCol, IonGrid, IonIcon, IonRow, IonSpinner } from '@ionic/react'
import { cardOutline } from 'ionicons/icons'
import { useEffect, useState } from 'react'
import AddCardToFile from './addCardToFile'
import useAxios from 'axios-hooks'
import axios from 'axios'
import StandardCenterCenter from '../commonComponents/StandardCenterCenter'

export default function CardOnFile({
  customerId,
  onCardUpdate = () => {},
  setIsCardOnFile = () => {},
  buttonText = 'Update Payment Method',
  watcherValue = false,
}) {
  const [card, setCard] = useState()
  const [loading, setLoading] = useState(false)

  const [showCardInput, setShowCardInput] = useState(false)

  const getCard = async () => {
    setLoading(true) // Set loading state to true
    try {
      const response = await axios.get(`/customer_v2/cardStripe?customerId=${customerId}`)
      setCard(response.data) // Update state with card data
      setIsCardOnFile(response?.data?.cardOnFile || false) // Set card on file state
    } catch (error) {
      console.error('Error loading card details:', error) // Log the error for debugging
    } finally {
      setLoading(false) // Ensure loading state is reset
    }
  }

  useEffect(() => {
    getCard()
  }, [watcherValue])

  function handleCardUpdate() {
    getCard()
    setShowCardInput(false)
    onCardUpdate()
  }

  return (
    <>
      <IonGrid>
        <IonRow>
          <IonCol size='auto'>
            <div
              style={{
                height: 50,
                width: 50,
                borderRadius: 25,
                marginLeft: 10,
                marginRight: 10,
                backgroundColor: '#323232',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <IonIcon icon={cardOutline} style={{ color: 'white' }} size='large' />
            </div>
          </IonCol>
          <IonCol>
            <div style={{ height: 50, display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
              {loading ? (
                <IonSpinner name='dots' />
              ) : (
                <>
                  {' '}
                  {!card?.cardOnFile ? (
                    <>Add a Default Card</>
                  ) : (
                    <>
                      <span style={{ fontWeight: '500', color: '#313131' }}>{capitalizeFirstLetter(card?.cardDetails?.cardBrand)}</span>
                      <span>Ending in {card?.cardDetails?.cardLast4}</span>
                    </>
                  )}
                </>
              )}
            </div>
          </IonCol>
          <IonCol size='auto'>
            <div style={{ height: 50, display: 'flex', alignItems: 'center' }}>
              <IonButton
                fill='clear'
                onClick={() => {
                  setShowCardInput(!showCardInput)
                }}
              >
                {card?.cardOnFile ? <> {showCardInput ? 'Hide' : 'Update'}</> : ''}
              </IonButton>
            </div>
          </IonCol>
        </IonRow>
      </IonGrid>

      {showCardInput || (!card?.cardOnFile && !loading) ? (
        <>
          <p style={{ marginLeft: 10, marginRight: 10 }}>{card?.cardOnFile ? 'Input new card details to update payment method.' : ''}</p>
          <AddCardToFile buttonText={buttonText} addCardToFileSuccess={handleCardUpdate} customerId={customerId} />
          <StandardCenterCenter>
            <img src='/assets/poweredByStripe_black.svg' alt='' style={{ height: 22, marginTop: 10, marginBottom: 20 }} />
          </StandardCenterCenter>
        </>
      ) : null}
    </>
  )
}

function capitalizeFirstLetter(str) {
  if (!str) {
    return ''
  }

  return str.charAt(0).toUpperCase() + str.slice(1)
}
